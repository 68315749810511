import * as Types from "../types/Types";
import axios from "axios";
import Axios from "axios";
import { showToast } from "../../../../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../../../../Const";
import {
  GetLeadsnput,
  GetPricequoteInput,
} from "../../../../_redux/action/LeadsAction";
import swal from "sweetalert";
export const SubmitApplicationImage = (data, i) => (dispatch) => {
  const formData = new FormData();
  const urlImg = `${BASE_URL}api/v1/auth/upload/`;

  formData.append("document", data.document);
  formData.append("doc_type", data.doc_type);
  formData.append("owner", data.owner);

  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(urlImg, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((resImg) => {
        if (resImg.data.status) {
          dispatch({ type: Types.IS_SUCCESS_IMAGE, payload: true });
          showToast("success", "Document Uploaded successfully");

          dispatch({
            type: Types.ADD_NEW_DOC,
            payload: {
              name: "document",
              value: resImg.data.data.document,
              index: i,
            },
          });

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        } else {
          showToast("error", resImg.data.message);
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        const message = JSON.parse(err.request.response).message;
        showToast("error", message);
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetDocInput =
  (name, value, index, e, application_docs = undefined) =>
  (dispatch) => {
    // dispatch({ type: Types.GET_DOC_INPUT, payload: formData });
    if (application_docs !== undefined) {
      const formData = {
        name: name,
        value: value,
        index,
      };
      dispatch({ type: Types.ADD_NEW_DOC, payload: { name, value, index } });

      if (name === "document") {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          formData.name = "document";
          formData.value = reader.result;
          dispatch({ type: Types.ADD_NEW_DOC, payload: formData });
          // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
        };
        reader.readAsDataURL(file);
      }
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };

    dispatch({ type: Types.ADD_NEW_DOC, payload: formData });
  };

export const GetApplicationProductInput =
  (name, value, index, application_products = undefined) =>
  (dispatch) => {
    if (application_products !== undefined) {
      dispatch({
        type: Types.GET_APPLICATION_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_APPLICATION_PRODUCT, payload: formData });
  };

export const GetApplicationList = (api) => (dispatch) => {
  const url = api;
  // const url = `${BASE_URL}api/v1/applications/application/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APPLICATION_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// --// --------------delete------------
export const ApplicationOwnerDelete = (id, a_id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/applications/application-business-owner/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("success", "Remove contact successfully");
        dispatch(GetAplicationDetails(a_id));
        dispatch({ type: Types.IS_APPLICATION_OWNER_DELETED, payload: true });
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_APPLICATION_OWNER_DELETED, payload: false });
};

//   ----------------------add lead--------------------

export const GetApplicationInput =
  (name, value, index, business_owners = undefined, contact = undefined) =>
  (dispatch) => {
    if (business_owners !== undefined) {
      dispatch({
        type: Types.GET_QUOTE_OWNER,
        payload: { name, value, index, contact },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.GET_APPLICATION_INPUT, payload: formData });
  };

export const SetApplicationStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_APPLICATION_FALSE, payload: false });
};

export const SetApplicationInputFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_APPLICATION_INPUT_FALSE, payload: false });
};

export const SubmitApplicationInput = (data) => (dispatch) => {
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var validation = false;
  // if (data.business_owners?.length > 1) {
  var Sum = 0;
  for (let index = 0; index < data.business_owners.length; index++) {
    Sum += parseInt(data.business_owners[index].ownership_perc);
  }
  if (Sum !== 100) {
    showToast("error", "Total Ownership Percentage must be  100 ");
    validation = true;
  }

  // }
  if (data.legal_type !== "PART" && !data.business_owners[0].owner_email) {
    showToast("error", "1st owner email shouldn't be empty");
    validation = true;
  }
  if (
    data.legal_type !== "PART" &&
    data.business_owners[0].is_main_principal === false
  ) {
    showToast("error", "1st owner is main principle should be yes");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty !");
    validation = true;
  }
  for (const business_owner of data.business_owners) {
    business_owner.owner_second_name = business_owner.owner_surname;
    business_owner.business_owner_contacts[0].email_address =
      business_owner.owner_email;
    if (!business_owner?.owner_first_name) {
      showToast("error", "Owner Frist name shouldn't be empty");
      validation = true;
    }
    if (!business_owner?.owner_title) {
      showToast("error", "Owner Title name shouldn't be empty");
      validation = true;
    }

    if (!business_owner?.owner_issue_date) {
      showToast("error", "Owner Issue date shouldn't be empty");
      validation = true;
    }
    if (!business_owner?.owner_expiry_date) {
      showToast("error", "Owner Expire date shouldn't be empty");
      validation = true;
    }
    if( (new Date(business_owner.owner_expiry_date).getFullYear() -
    new Date(business_owner.owner_issue_date).getFullYear()) *
    12 +
    (new Date(business_owner.owner_expiry_date).getMonth() -
      new Date(business_owner.owner_issue_date).getMonth()) <
    3){
      showToast("error", "The minimum gap between issue date and expiry date should be 3 months.");
      validation = true;
    }
    if (!business_owner?.owner_id_num) {
      showToast("error", "Owner ID number shouldn't be empty");
      validation = true;
    }

    if (data.legal_type === "PART" && !business_owner?.owner_email) {
      showToast("error", "Owner Email shouldn't be empty");
      validation = true;
    }
    if (
      data.legal_type === "PART" &&
      business_owner?.is_main_principal === false
    ) {
      showToast("error", "Is main principle should be yes");
      validation = true;
    }
    if (data.legal_type === "ST") {
      business_owner.is_director = false;
    }
    if (!business_owner.owner_surname) {
      showToast("error", "Owner Last name shouldn't be empty");
      validation = true;
    }
    if (!business_owner.contact_dob) {
      showToast("error", "Owner Date of birth shouldn't be empty");
      validation = true;
    }
    if (
      business_owner.owner_nationality === "" ||
      business_owner.owner_nationality === null
    ) {
      showToast("error", "Owner Nationality shouldn't be empty");
      validation = true;
    }

    if (
      new Date().getFullYear() -
        new Date(business_owner.contact_dob).getFullYear() <
      10
    ) {
      showToast("error", "Invalid owner DOB");
      validation = true;
    }
    if (
      data.legal_type === "LLP" ||
      data.legal_type === "L" ||
      data.legal_type === "PL"
    ) {
      if (
        parseInt(business_owner.ownership_perc) > 100 &&
        parseInt(business_owner.ownership_perc) < 10
      ) {
        showToast(
          "error",
          "Ownership Percentage must less than 100 and greater than 10"
        );
        validation = true;
      }
    }

    if (!parseInt(business_owner.owner_phone_no)) {
      showToast("error", "Owner Phone number shouldn't be empty");
      validation = true;
    }
    if (business_owner.owner_phone_no.length !== 10) {
      showToast("error", "Invalid Owner phone number");
      validation = true;
    }
    //  if (!business_owner.business_owner_contacts[0].country_code) {
    //   showToast("error", "Owner Country  shouldn't be empty");
    //  validation = true;
    // }
    if (!business_owner.business_owner_contacts[0].zip_code) {
      showToast("error", "Owner Zip code should not be empty");
      validation = true;
    }
    if (
      business_owner.business_owner_contacts[0].country_code === "" ||
      business_owner.business_owner_contacts[0].country_code === null
    ) {
      showToast("error", "Owner Country should not be empty");
      validation = true;
    }
    if (!business_owner.business_owner_contacts[0].street_line_1) {
      showToast("error", "Owner address1 should not be empty");
      validation = true;
    }
    if (!business_owner.business_owner_contacts[0].city) {
      showToast("error", "Owner town/city should not be empty");
      validation = true;
    }
    if (business_owner.business_owner_contacts[0].zip_code.length < 5) {
      showToast(
        "error",
        "Please enter at least 5 digit  zip code in owner contact"
      );
      validation = true;
    }
  }
  // for (const product of data.application_products) {
  //   if (!product.product) {
  //     showToast("error", "Product name shouldn't be empty");
  //     validation = true;
  //   }
  //   if (product.provider === "" || product.provider === null) {
  //     showToast("error", "Provider shouldn't be empty");
  //     validation = true;
  //   }
  //   if (!product?.monthly_price) {
  //     showToast("error", "product monthly price shouldn't be empty");
  //     validation = true;
  //   }
  //   // if (product.provider === "") {
  //   //   showToast("error", "Provider name shouldn't be empty");
  //   //   validation = true;
  //   // }
  // }
  for (const document of data.application_docs) {
    if (!document?.document) {
      showToast("error", "Image/PDF shouldn't be empty");
      validation = true;
    }
    if (!document.category) {
      showToast("error", "File name shouldn't be empty");
      validation = true;
    }
    if (!document.doc_type) {
      showToast("error", "Document Type shouldn't be empty");
      validation = true;
    }
    if (document.category === "PROOF_OF_ID" && document?.first_name === "") {
      showToast("error", "Document owner first name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.sur_name) {
      showToast("error", "Document owner  Sur name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.document_id) {
      showToast("error", "Document owner document ID shouldn't be empty");
      validation = true;
    } else if (data.category === "PROOF_OF_ID" && !data?.issuer_id) {
      showToast("error", "Document owner issuer ID shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.expiry_date) {
      showToast("error", "Document owner expiry date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.issue_date) {
      showToast("error", "Document owner Issue date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.country) {
      showToast("error", "Issuing country shouldn't be empty");
      validation = true;
    }
  }
  if (!data.lead_source) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
  }
  if (!data.legal_name) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (!data.application_type) {
    showToast("error", "Application type shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'")) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'")) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (!data.legal_type) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (
    data.legal_type === "LLP" ||
    data.legal_type === "L" ||
    data.legal_type === "PL"
  ) {
    if (!data.company_house_no) {
      showToast("error", "Company house number shouldn't be empty");
      validation = true;
    }
  }
  if (data.legal_type === "CH") {
    if (!data.charity_number) {
      showToast("error", "Charity number shouldn't be empty");
      validation = true;
    }
  }
  if (data.vat_enabled === 0) {
    if (!data.tax_id) {
      showToast("error", "Vat number shouldn't be empty");
      validation = true;
    }
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (data.industry_type === "" || data.industry_type === null) {
    showToast("error", "Industry type shouldn't be empty");
    validation = true;
  }
  if (data.mcc_code === "" || data.mcc_code === null) {
    showToast("error", "Mcc code shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", "Date of ownership shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (!data.desc_of_service) {
    showToast("error", "Description of goods  shouldn't be empty !");
    validation = true;
  }
  if (!data.mobile) {
    showToast("error", "Mobile number shouldn't be empty !");
    validation = true;
  }
  if (!data.s_individual_sales_representatives) {
    showToast("error", "Sales representative name shouldn't be empty !");
    validation = true;
  }
  if (!data.sales_partner) {
    showToast("error", "Sales partner name shouldn't be empty !");
    validation = true;
  }
  //  if (!data.s_individual_start_date) {
  //   showToast("error", "Site visite date  number shouldn't be empty !");
  //  validation = true;
  // }
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  //  if (data.telephone !== null && data.telephone.length !== 10  ) {
  //   showToast("error", "Telephone number is invalid !");
  //  validation = true;
  // }
  if (
    parseFloat(data.annual_turnover) < parseFloat(data.annual_card_turnover)
  ) {
    showToast(
      "error",
      "Annual turnover must be greater  than annual card turnover !"
    );
    validation = true;
  }
  //  else if (!data.store_number) {
  //   showToast("error", "Store number shouldn't be empty !");
  //  validation = true;
  // }
  if (!data.bank_account_no) {
    showToast("error", "Bank account shouldn't be empty !");
    validation = true;
  }
  if (data.bank_account_no.length !== 8) {
    showToast("error", "Please enter 8 digit account number !");
    validation = true;
  }
  if (!data.legal_postcode) {
    showToast("error", "Legal post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit legal post code !");
    validation = true;
  }
  if (data.legal_country === "" || data.legal_country === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (data.legal_address1 === "" || data.legal_address1 === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (data.legal_city === "" || data.legal_city === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_postcode) {
    showToast("error", "Trading postcode shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit trading post code!");
    validation = true;
  }
  if (data.trading_country === "" || data.trading_country === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (data.trading_address1 === "" || data.trading_address1 === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (data.trading_city === "" || data.trading_city === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (!data.home_postcode) {
    showToast("error", "Home postcode shouldn't be empty !");
    validation = true;
  }
  if (data.home_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit home post code!");
    validation = true;
  }
  if (data.home_country === "" || data.home_country === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  if (data.home_address1 === "" || data.home_address1 === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  if (data.home_city === "" || data.home_city === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (parseInt(data.sales_internet_perc) > 0 && data.website === "") {
    showToast("error", "For Ecom app website shouldn't be empty !");
    validation = true;
  }
  if (parseInt(data.sales_internet_perc) > 0 && data.website === null) {
    showToast("error", "For Ecom app website shouldn't be empty !");
    validation = true;
  }

  if (parseInt(data.perc_annual_upfront_of_turnover) > 100) {
    showToast("error", "Annual upfront turnover Percentage must less than 100");
    validation = true;
  }
  if (parseInt(data.deposit_perc_transaction_value) > 100) {
    showToast("error", "Deposite Percentage must less than 100");
    validation = true;
  }
  if (
    parseInt(data.sales_ftf_perc) +
      parseInt(data.sales_moto_perc) +
      parseInt(data.sales_internet_perc) !==
    100
  ) {
    showToast("error", "Card acceptence ratio must be 100");
    validation = true;
  }
  if (
    data.visa_credit_sr.includes(".") &&
    data.visa_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_credit_non_sr.includes(".") &&
    data.visa_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa Credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_sr.includes(".") &&
    data.master_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_non_sr.includes(".") &&
    data.master_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_sr.includes(".") &&
    data.visa_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_non_sr.includes(".") &&
    data.visa_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_sr.includes(".") &&
    data.master_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_non_sr.includes(".") &&
    data.master_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_sr.includes(".") &&
    data.international_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_non_sr.includes(".") &&
    data.international_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_sr.includes(".") &&
    data.visa_v_pay_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_non_sr.includes(".") &&
    data.visa_v_pay_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_sr.includes(".") &&
    data.uk_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_non_sr.includes(".") &&
    data.uk_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_sr.includes(".") &&
    data.visa_business_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_non_sr.includes(".") &&
    data.visa_business_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_sr.includes(".") &&
    data.visa_business_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_non_sr.includes(".") &&
    data.visa_business_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_sr.includes(".") &&
    data.visa_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_non_sr.includes(".") &&
    data.visa_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_sr.includes(".") &&
    data.visa_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_non_sr.includes(".") &&
    data.visa_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_sr.includes(".") &&
    data.master_business_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_non_sr.includes(".") &&
    data.master_business_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_sr.includes(".") &&
    data.master_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_non_sr.includes(".") &&
    data.master_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_sr.includes(".") &&
    data.master_fleet_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_non_sr.includes(".") &&
    data.master_fleet_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_sr.includes(".") &&
    data.master_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_non_sr.includes(".") &&
    data.master_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_sr.includes(".") &&
    data.master_pre_commercial_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_non_sr.includes(".") &&
    data.master_pre_commercial_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_sr.includes(".") &&
    data.non_eea_visa_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_non_sr.includes(".") &&
    data.non_eea_visa_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_sr.includes(".") &&
    data.non_eea_master_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_non_sr.includes(".") &&
    data.non_eea_master_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (validation) {
    return 0;
  }

  const formData = new FormData();
  formData.append(
    "document",
    data.application_docs.map((a) => a.document)
  );
  formData.append("doc_type", data.doc_type);
  formData.append("owner", data.owner);
  const url = `${BASE_URL}api/v1/applications/application/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    // Axios.put(url, data,formData , {
    Axios.put(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          showToast("success", "New application Updated successfully");

          // localStorage.setItem("leadId", res.data.data.id);
          dispatch({ type: Types.AFTER_SUCCESS_APPLICATION, payload: true });

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
          dispatch(SetApplicationDetails());
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};
export const SubmitNewApplicationInput = (data) => (dispatch) => {
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var validation = false;
  var Sum = 0;
  for (let index = 0; index < data.business_owners.length; index++) {
    Sum += parseInt(data.business_owners[index].ownership_perc);
  }
  if (Sum !== 100) {
    showToast("error", "Total Ownership Percentage must be  100 ");
    validation = true;
  }

  if (data.legal_type !== "PART" && !data.business_owners[0].owner_email) {
    showToast("error", "1st owner email shouldn't be empty");
    validation = true;
  }

  if (
    data.legal_type !== "PART" &&
    data.business_owners[0].is_main_principal === false
  ) {
    showToast("error", "1st owner is main principle should be yes");
    validation = true;
  }

  for (const business_owner of data.business_owners) {
    business_owner.owner_second_name = business_owner.owner_surname;
    business_owner.business_owner_contacts[0].email_address =
      business_owner.owner_email;
    if (!business_owner?.owner_first_name) {
      showToast("error", "Owner Frist name shouldn't be empty");
      validation = true;
    }
    if (!business_owner?.owner_title) {
      showToast("error", "Owner Title name shouldn't be empty");
      validation = true;
    }

    if (!business_owner?.owner_issue_date) {
      showToast("error", "Owner Issue date shouldn't be empty");
      validation = true;
    }
    if (!business_owner?.owner_expiry_date) {
      showToast("error", "Owner Expire date shouldn't be empty");
      validation = true;
    }
    if( (new Date(business_owner.owner_expiry_date).getFullYear() -
    new Date(business_owner.owner_issue_date).getFullYear()) *
    12 +
    (new Date(business_owner.owner_expiry_date).getMonth() -
      new Date(business_owner.owner_issue_date).getMonth()) <
    3){
      showToast("error", "The minimum gap between issue date and expiry date should be 3 months.");
      validation = true;
    }
    if (!business_owner?.owner_id_num) {
      showToast("error", "Owner ID number shouldn't be empty");
      validation = true;
    }

    if (data.legal_type === "PART" && !business_owner?.owner_email) {
      showToast("error", "Owner Email shouldn't be empty");
      validation = true;
    }
    if (
      data.legal_type === "PART" &&
      business_owner?.is_main_principal === false
    ) {
      showToast("error", "Is main principle should be yes");
      validation = true;
    }
    if (data.legal_type === "ST") {
      business_owner.is_director = false;
    }
    if (!business_owner.owner_surname) {
      showToast("error", "Owner Last name shouldn't be empty");
      validation = true;
    }
    if (!business_owner.contact_dob) {
      showToast("error", "Owner Date of birth shouldn't be empty");
      validation = true;
    }
    if (
      business_owner.owner_nationality === "" ||
      business_owner.owner_nationality === null
    ) {
      showToast("error", "Owner Nationality shouldn't be empty");
      validation = true;
    }

    if (
      new Date().getFullYear() -
        new Date(business_owner.contact_dob).getFullYear() <
      10
    ) {
      showToast("error", "Invalid owner DOB");
      validation = true;
    }
    if (
      data.legal_type === "LLP" ||
      data.legal_type === "L" ||
      data.legal_type === "PL"
    ) {
      if (
        parseInt(business_owner.ownership_perc) > 100 &&
        parseInt(business_owner.ownership_perc) < 10
      ) {
        showToast(
          "error",
          "Ownership Percentage must less than 100 and greater than 10"
        );
        validation = true;
      }
    }

    if (!parseInt(business_owner.owner_phone_no)) {
      showToast("error", "Owner Phone number shouldn't be empty");
      validation = true;
    }
    if (business_owner.owner_phone_no.length !== 10) {
      showToast("error", "Invalid Owner phone number");
      validation = true;
    }
    //  if (!business_owner.business_owner_contacts[0].country_code) {
    //   showToast("error", "Owner Country  shouldn't be empty");
    //  validation = true;
    // }
    if (!business_owner.business_owner_contacts[0].zip_code) {
      showToast("error", "Owner Zip code should not be empty");
      validation = true;
    }
    if (
      business_owner.business_owner_contacts[0].country_code === "" ||
      business_owner.business_owner_contacts[0].country_code === null
    ) {
      showToast("error", "Owner Country should not be empty");
      validation = true;
    }
    if (!business_owner.business_owner_contacts[0].street_line_1) {
      showToast("error", "Owner address1 should not be empty");
      validation = true;
    }
    if (!business_owner.business_owner_contacts[0].city) {
      showToast("error", "Owner town/city should not be empty");
      validation = true;
    }
    if (business_owner.business_owner_contacts[0].zip_code.length < 5) {
      showToast(
        "error",
        "Please enter at least 5 digit  zip code in owner contact"
      );
      validation = true;
    }
  }
  // for (const product of data.application_products) {
  //   if (!product.product) {
  //     showToast("error", "Product name shouldn't be empty");
  //     validation = true;
  //   }
  //   if (product.provider === "" || product.provider === null) {
  //     showToast("error", "Provider shouldn't be empty");
  //     validation = true;
  //   }
  //   if (!product?.monthly_price) {
  //     showToast("error", "product monthly price shouldn't be empty");
  //     validation = true;
  //   }
  //   // if (product.provider === "") {
  //   //   showToast("error", "Provider name shouldn't be empty");
  //   //   validation = true;
  //   // }
  // }
  for (const document of data.application_docs) {
    if (!document?.document) {
      showToast("error", "Image/PDF shouldn't be empty");
      validation = true;
    }
    if (!document.category) {
      showToast("error", "File name shouldn't be empty");
      validation = true;
    }
    if (!document.doc_type) {
      showToast("error", "Document Type shouldn't be empty");
      validation = true;
    }
    if (document.category === "PROOF_OF_ID" && document?.first_name === "") {
      showToast("error", "Document owner first name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.sur_name) {
      showToast("error", "Document owner  Sur name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.document_id) {
      showToast("error", "Document owner document ID shouldn't be empty");
      validation = true;
    } else if (data.category === "PROOF_OF_ID" && !data?.issuer_id) {
      showToast("error", "Document owner issuer ID shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.expiry_date) {
      showToast("error", "Document owner expiry date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.issue_date) {
      showToast("error", "Document owner Issue date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.country) {
      showToast("error", "Issuing country shouldn't be empty");
      validation = true;
    }
  }
  if (!data.lead_source) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
  }
  if (!data.legal_name) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (data.industry_type === "" || data.industry_type === null) {
    showToast("error", "Industry type shouldn't be empty");
    validation = true;
  }
  if (data.mcc_code === "" || data.mcc_code === null) {
    showToast("error", "Mcc code shouldn't be empty");
    validation = true;
  }
  if (!data.application_type) {
    showToast("error", "Application type shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'")) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'")) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (!data.legal_type) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (
    data.legal_type === "LLP" ||
    data.legal_type === "L" ||
    data.legal_type === "PL"
  ) {
    if (!data.company_house_no) {
      showToast("error", "Company house number shouldn't be empty");
      validation = true;
    }
  }
  if (data.legal_type === "CH") {
    if (!data.charity_number) {
      showToast("error", "Charity number shouldn't be empty");
      validation = true;
    }
  }
  if (data.vat_enabled === 0) {
    if (!data.tax_id) {
      showToast("error", "Vat number shouldn't be empty");
      validation = true;
    }
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", "Date of ownership shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (!data.desc_of_service) {
    showToast("error", "Description of goods  shouldn't be empty !");
    validation = true;
  }
  if (!data.mobile) {
    showToast("error", "Mobile number shouldn't be empty !");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty !");
    validation = true;
  }
  if (!data.s_individual_sales_representatives) {
    showToast("error", "Sales representative name shouldn't be empty !");
    validation = true;
  }
  if (!data.sales_partner) {
    showToast("error", "Sales partner name shouldn't be empty !");
    validation = true;
  }
  //  if (!data.s_individual_start_date) {
  //   showToast("error", "Site visite date  number shouldn't be empty !");
  //  validation = true;
  // }
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  //  if (data.telephone !== null && data.telephone.length !== 10  ) {
  //   showToast("error", "Telephone number is invalid !");
  //  validation = true;
  // }
  if (
    parseFloat(data.annual_turnover) < parseFloat(data.annual_card_turnover)
  ) {
    showToast(
      "error",
      "Annual turnover must be greater  than annual card turnover !"
    );
    validation = true;
  }
  //  else if (!data.store_number) {
  //   showToast("error", "Store number shouldn't be empty !");
  //  validation = true;
  // }
  if (!data.bank_account_no) {
    showToast("error", "Bank account shouldn't be empty !");
    validation = true;
  }
  if (data.bank_account_no.length !== 8) {
    showToast("error", "Please enter 8 digit account number !");
    validation = true;
  }
  if (!data.legal_postcode) {
    showToast("error", "Legal post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit legal post code !");
    validation = true;
  }
  if (data.legal_country === "" || data.legal_country === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (data.legal_address1 === "" || data.legal_address1 === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (data.legal_city === "" || data.legal_city === null) {
    showToast("error", "Legal country shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_postcode) {
    showToast("error", "Trading postcode shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit trading post code!");
    validation = true;
  }
  if (data.trading_country === "" || data.trading_country === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (data.trading_address1 === "" || data.trading_address1 === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (data.trading_city === "" || data.trading_city === null) {
    showToast("error", "Trading country shouldn't be empty !");
    validation = true;
  }
  if (!data.home_postcode) {
    showToast("error", "Home postcode shouldn't be empty !");
    validation = true;
  }
  if (data.home_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit home post code!");
    validation = true;
  }
  if (data.home_country === "" || data.home_country === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  if (data.home_address1 === "" || data.home_address1 === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  if (data.home_city === "" || data.home_city === null) {
    showToast("error", "Home country shouldn't be empty !");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (parseInt(data.sales_internet_perc) > 0 && data.website === "") {
    showToast("error", "For Ecom app website shouldn't be empty !");
    validation = true;
  }
  if (parseInt(data.sales_internet_perc) > 0 && data.website === null) {
    showToast("error", "For Ecom app website shouldn't be empty !");
    validation = true;
  }

  if (parseInt(data.perc_annual_upfront_of_turnover) > 100) {
    showToast("error", "Annual upfront turnover Percentage must less than 100");
    validation = true;
  }
  if (parseInt(data.deposit_perc_transaction_value) > 100) {
    showToast("error", "Deposite Percentage must less than 100");
    validation = true;
  }
  if (
    parseInt(data.sales_ftf_perc) +
      parseInt(data.sales_moto_perc) +
      parseInt(data.sales_internet_perc) !==
    100
  ) {
    showToast("error", "Card acceptence ratio must be 100");
    validation = true;
  }
  if (
    data.visa_credit_sr.includes(".") &&
    data.visa_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_credit_non_sr.includes(".") &&
    data.visa_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa Credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_sr.includes(".") &&
    data.master_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_non_sr.includes(".") &&
    data.master_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_sr.includes(".") &&
    data.visa_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_non_sr.includes(".") &&
    data.visa_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_sr.includes(".") &&
    data.master_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_non_sr.includes(".") &&
    data.master_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_sr.includes(".") &&
    data.international_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_non_sr.includes(".") &&
    data.international_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_sr.includes(".") &&
    data.visa_v_pay_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_non_sr.includes(".") &&
    data.visa_v_pay_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_sr.includes(".") &&
    data.uk_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_non_sr.includes(".") &&
    data.uk_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_sr.includes(".") &&
    data.visa_business_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_non_sr.includes(".") &&
    data.visa_business_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_sr.includes(".") &&
    data.visa_business_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_non_sr.includes(".") &&
    data.visa_business_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_sr.includes(".") &&
    data.visa_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_non_sr.includes(".") &&
    data.visa_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_sr.includes(".") &&
    data.visa_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_non_sr.includes(".") &&
    data.visa_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_sr.includes(".") &&
    data.master_business_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_non_sr.includes(".") &&
    data.master_business_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_sr.includes(".") &&
    data.master_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_non_sr.includes(".") &&
    data.master_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_sr.includes(".") &&
    data.master_fleet_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_non_sr.includes(".") &&
    data.master_fleet_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_sr.includes(".") &&
    data.master_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_non_sr.includes(".") &&
    data.master_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_sr.includes(".") &&
    data.master_pre_commercial_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_non_sr.includes(".") &&
    data.master_pre_commercial_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_sr.includes(".") &&
    data.non_eea_visa_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_non_sr.includes(".") &&
    data.non_eea_visa_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_sr.includes(".") &&
    data.non_eea_master_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_non_sr.includes(".") &&
    data.non_eea_master_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  non secure rate cann't exceed 3 digit after decimal"
    );
    validation = true;
  }
  if (validation) {
    return 0;
  }

  const formData = new FormData();
  formData.append(
    "document",
    data.application_docs.map((a) => a.document)
  );
  formData.append("doc_type", data.doc_type);
  formData.append("owner", data.owner);
  const url = `${BASE_URL}api/v1/applications/application/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    // Axios.put(url, data,formData , {
    Axios.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          dispatch({
            type: Types.GET_APPLICATION_INPUT,
            payload: { name: "id", value: res.data.data.id },
          });
          showToast("success", "New application Updated successfully");

          localStorage.setItem("appLication_id", res.data.data.id);
          dispatch({ type: Types.AFTER_SUCCESS_APPLICATION, payload: true });

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
          dispatch(SetApplicationDetails());
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const SetAddresstrue = (data) => (dispatch) => {
  dispatch({ type: Types.IS_ADDRESS_CHECKED, payload: data });
};
export const SetBankInfoTrue = (data) => (dispatch) => {
  dispatch({ type: Types.BANK_INFO_CHECKED, payload: data });
};

// update

export const GetAplicationDetails = (id) => (dispatch) => {
  let priceQId = localStorage.getItem("priceQId");
  const url = `${BASE_URL}api/v1/applications/application/${id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
         
          type: Types.SET_APPLICATION_UPDATED,
          payload: res.data.data,
        });
        localStorage.setItem("application_id", res.data.data.id);
        dispatch(
          GetLeadsnput(
            "terminal_products",
            res.data.data?.application_products?.filter(
              (item) => item?.product_type === "card_terminal"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "ecommerce_products",
            res.data.data?.application_products?.filter(
              (item) =>
                item?.product_type === "ecom" ||
                item?.product_type === "VT" ||
                item?.product_type === "ecom_VT"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "epos_products",
            res.data.data?.application_products?.filter(
              (item) => item?.product_type === "epos"
            )
          )
        );
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const SetApplicationUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/applications/application/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.SET_APPLICATION_UPDATED,
          payload: res.data.data,
        });
        localStorage.setItem("application_id", res.data.data.id);
      }
    });
  } catch (error) {}
};

export const SetApplicationDetails = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/applications/application/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APPLICATION_DETAILS,
          payload: res.data.data,
        });
        dispatch(
          GetLeadsnput(
            "terminal_products",
            res.data.data?.application_products?.filter(
              (item) => item?.product_type === "card_terminal"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "ecommerce_products",
            res.data.data?.application_products?.filter(
              (item) =>
                item?.product_type === "ecom" ||
                item?.product_type === "VT" ||
                item?.product_type === "ecom_VT"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "epos_products",
            res.data.data?.application_products?.filter(
              (item) => item?.product_type === "epos"
            )
          )
        );
      }
    });
  } catch (error) {}
};

// ===============================vat==============================
export const GetVatList = (name, company_house_no) => (dispatch) => {
  const url = `${BASE_URL}api/v1/applications/vat/?legal_name=${name}&company_no=${company_house_no}`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_VAT_LIST,
          payload: res.data.data,
        });
        console.log(res.data.data, "gfg");
        if (res.data.data) {
          dispatch(GetApplicationInput("tax_id", res.data.data.Number));
        }
        // else{
        //   dispatch(
        //     GetApplicationInput(
        //       "tax_id",
        //      ''
        //     )
        //   );
        // }

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// ================================submit bank---------------------------
export const GetBankApplicationnput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.SUBMIT_APPLICATION_TO_BANK, payload: formData });
};

export const SubmitBankapplication = (data, id) => (dispatch) => {
  let priceQId = localStorage.getItem("priceQId");
  data.application_id = parseInt(id);
  const formData = new FormData();
  formData.append("application_id", data.application_id);
  const url = `${BASE_URL}api/v1/applications/application-submit/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          dispatch({ type: Types.AFTER_SUBMIT_BANK, payload: true });
          swal(" Send to bank successfully!", {
            icon: "success",
          });
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          // showToast("error", res.message);
          swal("error", {
            icon: "error",
          });
        }
      })
      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).message;

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};
// ================================submit bank---------------------------
export const sendForRequest = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.SEND_SIGNING_REQUEST, payload: formData });
};

export const SubmitSiginingRequest = (data, id, email) => (dispatch) => {
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let leadId = localStorage.getItem("leadId");
  data.application_id = parseInt(id);
  data.application_email = email;

  if (!regEmail.test(data.application_email)) {
    showToast("error", "Enter a vaild email for signing");
    return 0;
  }
  const formData = new FormData();
  formData.append("application_id", data.application_id);
  const url = `${BASE_URL}api/v1/applications/send-singing/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.AFTER_SUCCESS_SIGNING, payload: true });
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          console.log("res.data", res.data.data);
          dispatch({
            type: Types.GET_APPLICATION_INPUT,
            payload: { name: "pdf_url", value: res.data.data },
          });
          dispatch({
            type: Types.SEND_SIGNING_REQUEST,
            payload: { name: "pdf_url", value: res.data.data },
          });
          showToast("success", "Send for sigining successfully!");
          // swal(" Send for sigining successfully!", {
          //   icon: "success",
          // });
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          // showToast("error", res.message);
          swal("error", {
            icon: "error",
          });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

        const erroeMsg = JSON.parse(err.request.response).message;

        // showToast("error", erroeMsg);
        swal(erroeMsg, {
          icon: "error",
        });
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
      });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};
export const SubmitRepresentativeSiginingRequest =
  (data, id, email) => (dispatch) => {
    var expression =
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let leadId = localStorage.getItem("leadId");
    data.application_id = parseInt(id);
    data.application_email = email;

    if (!regEmail.test(data.application_email)) {
      showToast("error", "Enter a vaild email for signing");
      return 0;
    }
    const formData = new FormData();
    formData.append("application_id", data.application_id);
    const url = `${BASE_URL}api/v1/applications/send-singing/`;
    dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
    try {
      Axios.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.data.status) {
            dispatch({
              type: Types.AFTER_SUCCESS_REPRESENTATIVE_SIGNING,
              payload: true,
            });
            dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

            // showToast("success", "Send to bank successfully");
            swal(" Send for sigining successfully!", {
              icon: "success",
            });
          } else {
            dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
            // showToast("error", res.message);
            swal("error", {
              icon: "error",
            });
          }
        })
        .catch((err) => {
          // const erroeMsg = JSON.parse(err.request.response).errors;
          const erroeMsg = JSON.parse(err.request.response).message;

          // showToast("error", erroeMsg);
          swal(erroeMsg, {
            icon: "error",
          });
          // for (let value of Object.values(erroeMsg)) {
          //   showToast("error", value[0]);
          // }
        });
    } catch (error) {
      showToast("error", "Something went wrong");
    }
    dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  };
export const SetBanksubmitStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_SUBMIT_FALSE, payload: false });
};

export const SetSigningStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_SIGINING_FALSE, payload: false });
};
export const SetPdfStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_PDF_FALSE, payload: false });
};
export const SetSigningStatusFalseTwo = () => (dispatch) => {
  dispatch({ type: Types.SET_SIGINING_FALSE_TWO, payload: false });
};

// ===============================company house no==============================
export const GetCompanyHouseList = (company_house_no) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/utility/${company_house_no}/legal-name-by-number/`;

  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        res.data.data.data.items.map((house) => {
          if (house.company_number === company_house_no) {
            dispatch(GetLeadsnput("legal_name", house?.title));
            dispatch(GetLeadsnput("incorporated_on", house?.date_of_creation));
            dispatch(
              GetLeadsnput("legal_postcode", house?.address?.postal_code)
            );
            dispatch(
              GetLeadsnput(
                "legal_address1",
                house?.address?.premises.concat(
                  house?.address?.address_line_1 &&
                    house?.address?.address_line_1
                )
              )
            );
            dispatch(GetLeadsnput("legal_city", house?.address?.locality));
          }
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
};
export const GetCompanyOfficersDetails = (company_house_no) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/utility/${company_house_no}/officers-by-company-number/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        console.log(res.data.data.data.items);
        dispatch({
          type: Types.GET_COMPANY_OFFICERS_DETAILS,
          payload: res.data.data.data.items,
        });

        const officeDetails = res.data.data.data.items[0];
        const nameParts = res.data.data.data.items[0]?.name.split(", ");
        const firstNameParts = nameParts[1].trim().split(" ");
        const fullName = [firstNameParts[0], ...firstNameParts.slice(1)].join(
          " "
        );
        const lastName = nameParts[0];
        // ------------leads----------------------
        dispatch(GetLeadsnput("last_name", lastName));
        dispatch(GetLeadsnput("first_name", fullName));
        dispatch(
          GetLeadsnput("home_postcode", officeDetails.address.postal_code)
        );
        dispatch(
          GetLeadsnput(
            "home_address1",
            officeDetails?.address?.premises.concat(
              officeDetails?.address?.address_line_1
            )
          )
        );
        //  dispatch(
        //       GetLeadsnput(
        //         "home_address1",
        //         officeDetails?.address?.premises +
        //           " " +
        //           officeDetails?.address?.address_line_1 &&
        //           officeDetails?.address?.address_line_1
        //       )
        //     );
        dispatch(
          GetLeadsnput("home_address2", officeDetails.address?.address_line_2)
        );
        dispatch(GetLeadsnput("home_county", officeDetails.address?.region));
        dispatch(GetLeadsnput("home_city", officeDetails.address?.locality));
        // // ------------quote----------------------
        // dispatch(
        //   GetPricequoteInput(
        //     "owner_first_name",
        //     fullName,
        //     0,
        //     "quote_owners",
        //     "contact"
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "owner_surname",
        //     lastName,
        //     0,
        //     "quote_owners",
        //     "contact"
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "zip_code",
        //     0,
        //     officeDetails.address.postal_code,
        //     0,
        //     "quote_owners",
        //     "contact"
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "street_line_1",
        //     0,
        //     officeDetails.address.address_line_1,
        //     0,
        //     "quote_owners",
        //     "contact"
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "city",
        //     0,
        //     officeDetails.address.locality,
        //     0,
        //     "quote_owners",
        //     "contact"
        //   )
        // );
        // dispatch(GetPricequoteInput("last_name", lastName));
        // dispatch(GetPricequoteInput("first_name", fullName));
        // dispatch(
        //   GetPricequoteInput("home_postcode", officeDetails.address.postal_code)
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "home_address1",
        //     officeDetails.address.address_line_1
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput(
        //     "home_address2",
        //     officeDetails.address?.address_line_2
        //   )
        // );
        // dispatch(
        //   GetPricequoteInput("home_county", officeDetails.address?.region)
        // );
        // dispatch(
        //   GetPricequoteInput("home_city", officeDetails.address?.locality)
        // );
        // // ------------application----------------------
        // dispatch(GetApplicationInput("last_name", lastName));
        // dispatch(GetApplicationInput("first_name", fullName));
        // dispatch(
        //   GetApplicationInput(
        //     "home_postcode",
        //     officeDetails.address.postal_code
        //   )
        // );
        // dispatch(
        //   GetApplicationInput(
        //     "home_address1",
        //     officeDetails.address.address_line_1
        //   )
        // );
        // dispatch(
        //   GetApplicationInput(
        //     "home_address2",
        //     officeDetails.address?.address_line_2
        //   )
        // );
        // dispatch(
        //   GetApplicationInput("home_county", officeDetails.address?.region)
        // );
        // dispatch(
        //   GetApplicationInput("home_city", officeDetails.address?.locality)
        // );

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
};

export const GetCompanyHouseDetails = (legal_name) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/utility/${legal_name}/legal-name-by-number/`;

  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_COMPANY_HOUSE_LIST,
          payload: res.data.data.data.items,
        });
        res.data.data.data.items.map((house) => {
          if (house.title === legal_name) {
            dispatch({
              type: Types.GET_COMPANY_HOUSE_DETAILS,
              payload: house,
            });
            var firstPart;
            var secondPart;
            var lastSpaceIndex;
            const add_details = house?.address;
            const full_add =
            add_details?.premises +
            "  " +
            (add_details?.address_line_1 ? add_details?.address_line_1 : "") +
            " " +
            (add_details?.address_line_2 ? add_details?.address_line_2 : "");

            if (full_add.length > 20) {
              firstPart = full_add.substring(0, 20);
              lastSpaceIndex = firstPart.lastIndexOf(" ");

              if (lastSpaceIndex !== -1) {
                secondPart = full_add.substring(lastSpaceIndex + 1);
                firstPart = full_add.substring(0, lastSpaceIndex);
              } else {
                secondPart = full_add.substring(20);
                firstPart = full_add.substring(0, 20);
              }
            }
            
            dispatch(GetLeadsnput("company_house_no", house?.company_number));
            dispatch(GetLeadsnput("incorporated_on", house?.date_of_creation));
            dispatch(
              GetLeadsnput("legal_postcode", house?.address?.postal_code)
            );
            dispatch(
              GetLeadsnput(
                "legal_address1",
                house?.address?.premises + house?.address?.address_line_1
              )
            );
            if (firstPart) {
              dispatch(GetLeadsnput("legal_address1", firstPart));
            } else {
              dispatch(
                GetLeadsnput(
                  "legal_address1",
                  add_details?.premises + add_details?.address_line_1
                )
              );
            }
            if (secondPart !== undefined ) {
              dispatch(GetLeadsnput("legal_address2", secondPart));
            }
            else {
              dispatch(
                GetLeadsnput(
                  "legal_address2",
                  add_details?.address_line_2
                )
              );
            }
            dispatch(GetLeadsnput("legal_city", house?.address?.locality));

            // =price quote----------
            dispatch(
              GetPricequoteInput("company_house_no", house?.company_number)
            );
            dispatch(
              GetPricequoteInput("incorporated_on", house?.date_of_creation)
            );
            dispatch(
              GetPricequoteInput("legal_postcode", house?.address?.postal_code)
            );
            if (secondPart !== undefined) {
              dispatch(GetPricequoteInput("legal_address2", secondPart));
            } else {
              dispatch(
                GetPricequoteInput("legal_address2", add_details?.address_line_2)
              );
            }
            if (firstPart) {
              dispatch(GetPricequoteInput("legal_address1", firstPart));
            } else {
              dispatch(
                GetPricequoteInput(
                  "legal_address1",
                  add_details?.premises + add_details?.address_line_1
                )
              );
            }
            dispatch(
              GetPricequoteInput("legal_city", house?.address?.locality)
            );
            // =Application----------
            dispatch(
              GetApplicationInput("company_house_no", house?.company_number)
            );
            dispatch(
              GetApplicationInput("incorporated_on", house?.date_of_creation)
            );
            dispatch(
              GetApplicationInput("legal_postcode", house?.address?.postal_code)
            );
            if (firstPart) {
              dispatch(GetApplicationInput("legal_address1", firstPart));
            } else {
              dispatch(
                GetApplicationInput(
                  "legal_address1",
                  add_details?.premises + " " + add_details?.address_line_1
                )
              );
            }
            if (secondPart !== undefined) {
              dispatch(GetApplicationInput("legal_address2", secondPart));
            } else {
              dispatch(
                GetApplicationInput("legal_address2", add_details?.address_line_2)
              );
            }
            dispatch(
              GetApplicationInput("legal_city", house?.address?.locality)
            );
          }
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
};
// ----------------product---------------
export const GetProductList = (id) => (dispatch) => {
  // let priceQId = localStorage.getItem("priceQId");
  const url = `${BASE_URL}api/v1/applications/application-terminal-product?application=${id}`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_PRODUCT_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetAllProductList = (url) => (dispatch) => {
  // const url = `${BASE_URL}/api/v1/applications/application-terminal-product/`;
  // const url = `${BASE_URL}api/v1/products/product/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_ALL_PRODUCT_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// ================================start pdf---------------------------
export const GetPdfInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_DOWNLOAD_PDF, payload: formData });
};

export const SubmitPdfUrl = (data, id) => (dispatch) => {
  data.application_id = parseInt(id);

  const url = `${BASE_URL}api/v1/applications/download-pdf/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_DOWNLOAD_PDF,
            payload: { name: "pdf_url", value: res.data.data },
          });

          dispatch({ type: Types.AFTER_SUCCESS_PDF, payload: true });
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("succcess", "Pdf download successfully!");
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          swal("error", {
            icon: "error",
          });
        }
      })

      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).message;

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// =================end pdf======================
// ================================start site visit pdf---------------------------
export const GetSiteVisitPdfInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_SITE_VISIT_DOWNLOAD_PDF, payload: formData });
};

export const SubmitSiteVisitPdfUrl = (data, id) => (dispatch) => {
  data.application_id = parseInt(id);

  const url = `${BASE_URL}api/v1/applications/site-visit-pdf/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data)
      .then(async (res) => {
        if (res.status) {
          console.log(res, "sdd");
          const newTab = window.open();
          newTab.document.write(await res.data);

          // dispatch({
          //   type: Types.GET_SITE_VISIT_DOWNLOAD_PDF,
          //   payload: { name: "pdf_url", value: res.data },
          // });

          // dispatch({ type: Types.AFTER_SUCCESS_PDF, payload: true });
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("succcess", "Pdf Create successfully!");
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          // swal("error", {
          //   icon: "error",
          // });
        }
      })

      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).message;

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// =================end site visit pdf======================

export const TerminalLoading = (is_ON) => {
  return {
    type: Types.IS_LOADING_TERMINAL,
    payload: is_ON,
  };
};

export const TerminalSuccess = (data) => {
  return {
    type: Types.SUCCESS_TERMINAL,
    payload: data,
  };
};
export const TerminalError = (error) => {
  return {
    type: Types.ERROR_TERMINAL,
    payload: error,
  };
};

export const TerminalUpdate = (updatedTerminals) => {
  return {
    type: Types.UPDATE_TERMINAL,
    payload: updatedTerminals,
  };
};
export const TerminalDelete = (uid) => {
  return {
    type: Types.DELETE_TERMINAL,
    payload: uid,
  };
};

export const PostDataDelete = (uid) => {
  return {
    type: Types.DELETE_POSTDATA,
    payload: uid,
  };
};
export const DeleteExistingTerminalFromAPI = (id) => {
  const url = `${BASE_URL}/api/v1/applications/application-terminal-product/${id}`;
  return (dispatch) => {
    Axios.delete(url)
      .then((success) => {
        showToast("success", "Terminal Deleted");
        dispatch(GetExistingTerminalData());
        dispatch(PostDataDelete(id));
      })
      .catch((error) => {
        dispatch(TerminalError(error.message));
        dispatch(TerminalLoading(false));
      });
  };
};

export const GetExistingTerminalData = (id) => {
  let priceQId = localStorage.getItem("priceQId");
  // const url = `${BASE_URL}/api/v1/applications/application-terminal-product/?application=${priceQId}`;
  const url = `${BASE_URL}/api/v1/applications/application-terminal-product/?application=${id}`;
  return (dispatch) => {
    Axios.get(url)
      .then((success) => {
        dispatch(
          TerminalSuccess(
            success.data.data.results === undefined
              ? []
              : success.data.data.results
          )
        );
        dispatch(TerminalLoading(false));
      })
      .catch((error) => {
        dispatch(TerminalError(error.message));
        dispatch(TerminalLoading(false));
      });
  };
};

// uid: data.Unique_id,
// id: TerminalData["id"],
// connection_type: TerminalData["connection_type"],
// contact_length: TerminalData["contact_length"],
// terminal_model: TerminalData["contact_length"],
// monthly_price: TerminalData["monthly_price"],
// application: 96,
// product: data.product,

export const PostAndUpdateApplicationProduct = (data) => {
  // if (data.provider.length === 0) {
  //   showToast("error", "Provider  shouldn't be empty");
  //   return 0;
  // } else if (data.monthly_price.length === 0) {
  //   showToast("error", "Price shouldn't be empty");
  //   return 0;
  // } else if (data.product.length === 0) {
  //   showToast("error", "Product  shouldn't be empty");
  //   return 0;
  // } else if (data.connection_type.length === 0) {
  //   showToast("error", "Connection Type  shouldn't be empty");
  //   return 0;
  // } else if (data.product_term.length === 0) {
  //   showToast("error", "Product Term shouldn't be empty");
  //   return 0;
  // }

  if (data.uid !== undefined) {
    var url = `${BASE_URL}/api/v1/applications/application-terminal-product/`;

    //that means it is new terminal
    return (dispatch) => {
      try {
        Axios.post(url, data)
          .then((res) => {
            dispatch(TerminalLoading(false));
            dispatch(TerminalDelete(data.uid));
            dispatch(GetExistingTerminalData());
            // showToast("success", "Products Added Successfully");
          })
          .catch((err) => {
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
              return 0;
            }
          });
      } catch (error) {
        showToast("error", "Something went wrong");
        return;
      }
    };
  } else {
    return (dispatch) => {
      var url = `${BASE_URL}/api/v1/applications/application-terminal-product/${data.id}/`;

      try {
        Axios.patch(url, data)
          .then((res) => {
            dispatch(TerminalLoading(false));
            // showToast("success", "Prodcuts Updated Successfully");
            dispatch(GetExistingTerminalData());
          })
          .catch((err) => {
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
              return 0;
            }
          });
      } catch (error) {
        showToast("error", "Something went wrong");
        return 0;
      }
    };
  }
};

export const AddTerminalForPost = (data) => {
  return {
    type: Types.ADD_NEW_TERMINAL,
    payload: data,
  };
};
// ----------------Contact---------------
export const GetContactList = () => (dispatch) => {
  let ownerId = localStorage.getItem("ownerId");
  const url = `${BASE_URL}api/v1/applications/application-business-owner-contact?business_owner=${ownerId}`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CONTACT_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// ----owner---------
export const GetContactInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CONTACT_INPUT, payload: formData });
};

export const SubmitContactInput = (data) => (dispatch) => {
  let ownerId = localStorage.getItem("ownerId");
  data.quote_owner = ownerId;

  const url = `${BASE_URL}api/v1/quotes/quote-owner-contact/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          showToast("success", "Contact added successfully");

          // localStorage.setItem("ownerId", res.data.data.id);

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// --------------------Pagination Start-----------------------
export const paginationLimit = (limit) => {
  return {
    type: Types.PAGINATION_lIMIT,
    payload: limit,
  };
};
export const setpaginationName = (name) => {
  return {
    type: Types.PAGINATION_NAME,
    payload: name,
  };
};
export const setLoadLeads = (isTrue) => {
  return {
    type: Types.IS_LOAD_LEADS,
    payload: isTrue,
  };
};

export const paginationPrevious = () => {
  return {
    type: Types.PAGINATION_PREVIOUS,
  };
};

export const paginationNext = () => {
  return {
    type: Types.PAGINATION_NEXT,
  };
};

export const paginationChangeCurrent = (current) => {
  return {
    type: Types.PAGINATION_CHANGE_CURRENT,
    payload: current,
  };
};

export const PaginationLoading = (is_ON) => {
  return {
    type: Types.PAGINATION_LOADING,
    payload: is_ON,
  };
};

export const PaginationSuccess = (data) => {
  return {
    type: Types.PAGINATION_SUCCESS,
    payload: data,
  };
};
export const PaginationError = (error) => {
  return {
    type: Types.PAGINATION_ERROR,
    payload: error,
  };
};
export const PaginationUrl = (url) => {
  return {
    type: Types.PAGINATION_URL,
    payload: url,
  };
};

export const GetPaginationData = (url) => {
  return (dispatch) => {
    url.length > 0 &&
      axios
        .get(url)
        .then((success) => {
          dispatch(
            PaginationSuccess(
              success.data.data === undefined ? [] : success.data.data
            )
          );
          dispatch(PaginationLoading(false));
        })
        .catch((error) => {
          dispatch(PaginationError(error.message));
          dispatch(PaginationLoading(false));
        });
    dispatch(setLoadLeads(true));
  };
};

// --------------------Pagination End-----------------------

// ===================doc update==================
export const SetDocUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/applications/application-document/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.SET_DOC_UPDATED,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

// -----------PriceQuote Terminal--------------

export const NewAppTerminalAdd = (updatedProducts) => {
  return {
    type: Types.TERMINAL_ADD_NEW_APP,
    payload: updatedProducts,
  };
};
export const NewAppTerminalDelete = (uid) => {
  return {
    type: Types.TERMINAL_DELETE_NEW_APP,
    payload: uid,
  };
};
export const NewAppTerminalSet = (data) => {
  return {
    type: Types.SET_TERMINAL_NEW_APP,
    payload: data,
  };
};
