import React from "react"; //Needed for nodejs v.16

import "./App.css";
import { useState } from "react";
import Login from "./modules/authentication/components/login/Login";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import Header from "./modules/navbar/component/Header";
import Dashboard from "./modules/home/compnent/Home";
import LeadStep from "./modules/allAplication/mainLead/components/addLead/LeadStep";


import PriceQuote from "./modules/allAplication/mainPriceQuote/components/priceQuoteTable/PriceQuoteList";
// ---------------------new application-----------------------------
import NewapplicationList from "./modules/allAplication/mainNewApplication/components/applicationTable/NewapplicationList";

import Page404 from "./modules/errorPages/Page404";
// import Files from "./modules/allAplication/mainLead/components/PriceQuote/Files";
import Leads from "./modules/allAplication/mainLead/components/leads/Leads";

import Home from "./modules/allAplication/mainNewApplication/components/Home";

import ErrorApplicationList from "./modules/allAplication/mainNewApplication/components/errorApplication/applicationTable/ErrorApplicationList";

import { useEffect } from "react";
import ApprovedApplication from "./modules/allAplication/mainNewApplication/components/approvedApplication/ApprovedApplication";
import Dechlineapplication from "./modules/allAplication/mainNewApplication/components/declineApplication/Dechlineapplication";
import BankqueriesApplication from "./modules/allAplication/mainNewApplication/components/bankqueriesApplication/BankqueriesApplication";
import Preview from "./modules/allAplication/mainLead/components/newApplication/Preview/Preview";



// import EposPartnerContainer from "./modules/userManagement/OnboardingExecutive/component/EposPartnerContainer";

import SalesPartnerTable from "./modules/userManagement/salesPartner/component/SalesPartnerTable";
import AllUserList from "./modules/userManagement/AllUserPage/applicationTable/AllUserList";
import PartnerManagerList from "./modules/userManagement/partnerManager/PartnerManagerList";

import EPosPartnerList from "./modules/userManagement/ePosPartner/EPosPartnerlist";
import AddUser from "./modules/userManagement/AllUserPage/AddUser";
import AddSalesPartner from "./modules/userManagement/salesPartner/component/AddSalesPartner";
import AddEposPartner from "./modules/userManagement/ePosPartner/AddEposPartner";
import AddPartnerManager from "./modules/userManagement/partnerManager/AddPartnerManager";
import ViewSalesPartner from "./modules/userManagement/salesPartner/component/ViewSalesPartner";


function App() {
  const history = useHistory();
  // const { useQuery } = require("react-query");
  const [bodyWidth, setBodyWidth] = useState(false);
  const [hideToolbar, setHideToolbar] = useState(false);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  return (
    <div>
      <Router>
        <div
          className={` ${
            !bodyWidth && !hideToolbar
              ? "main-content"
              : !hideToolbar
              ? "main-content-collapsed"
              : ""
          }`}
        >
          {!hideToolbar && <Header setBodyWidth={setBodyWidth} />}
          <div>
            <Switch>
              <Route exact path="/">
                <Login setHideToolbar={setHideToolbar} />
              </Route>
              {/* <Route exact path="/file">
                <Files />
              </Route> */}

              <Route exact path="/home">
                <Dashboard />
              </Route>
              <Route exact path="/addlead">
                <LeadStep />
              </Route>
              <Route exact path="/viewlead/:id">
                <LeadStep />
              </Route>
              <Route exact path="/leads">
                <Leads />
              </Route>

              <Route exact path="/pricequote">
                <PriceQuote />
              </Route>
              <Route exact path="/newapplication">
                <NewapplicationList />
              </Route>
              <Route exact path="/allApplication">
                <Home />
              </Route>
              <Route exact path="/allApplication/:id">
                <Preview />
              </Route>
             
              <Route exact path="/queries">
                <BankqueriesApplication />
              </Route>
              <Route exact path="/queries/:id">
                <Preview />
              </Route>
              <Route exact path="/approval">
                <ApprovedApplication />
              </Route>
              <Route exact path="/approval/:id">
                <Preview />
              </Route>
              <Route exact path="/decline">
                <Dechlineapplication />
              </Route>
              <Route exact path="/decline/:id">
                <Preview />
              </Route>
              <Route exact path="/errored_application_edit/:id">
                {/* <ErrorApplicationTop /> */}
                <LeadStep />
              </Route>
              <Route exact path="/errored_application">
                <ErrorApplicationList />
              </Route>
              <Route exact path="/All_User">
                <AllUserList/>
             
              </Route>
              <Route exact path="/edit_User/:id">
                <AddUser/>
               
              </Route>
              <Route exact path="/add_User">
                <AddUser/>
          
              </Route>
              <Route exact path="/edit_partner/:id">
                <AddSalesPartner/>
               
              </Route>
              <Route exact path="/view_sales_partner/:id">
                <ViewSalesPartner/>
          
              </Route>
              <Route exact path="/view_epos_partner/:id">
                <ViewSalesPartner/>
          
              </Route>
              <Route exact path="/view_partner_manager/:id">
                <ViewSalesPartner/>
          
              </Route>
              <Route exact path="/add_partner">
                <AddSalesPartner/>
          
              </Route>
              <Route exact path="/edit_epos_partner/:id">
                <AddEposPartner/>
               
              </Route>
              
              <Route exact path="/add_epos_partner">
                <AddEposPartner/>
          
              </Route>
              <Route exact path="/add_partner_manager">
                <AddPartnerManager/>
          
              </Route>
              <Route exact path="/edit_partner_manager/:id">
                <AddPartnerManager/>
               
              </Route>
              <Route exact path="/Onboarding_manager">
                <PartnerManagerList />
              </Route>
              <Route exact path="/epos_partner">
                <EPosPartnerList />
              </Route>
              {/* <Route exact path="/onboarding_executive">
                <EposPartnerContainer />
              </Route> */}
              <Route exact path="/partner_sales">
                <SalesPartnerTable />
              </Route>
              <Route path="/home/:id">
                <Preview />
              </Route>
              <Route exact path="*">
                <Page404 setHideToolbar={setHideToolbar} />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
