import React, { useRef, useState } from "react";
import "./Preview.css";
import image from "../../../../../../asstes/icon.bablu/new-application.svg";
import CustomarDetail from "./CustomarDetail";
import CustomarDetailEdit from "./CustomarDetailEdit";
import ApplicationDetails from "./ApplicationDetails";
import FinancialInformation from "./FinancialInformation";
import Document from "./Document";
import { useHistory } from "react-router-dom";
import ApplicationDetailEdit from "./ApplicationDetailEdit";
import FinancialInformationEdit from "./FinancialInformationEdit";

import BuisnessDetail from "./BuisnessDetail";
import { BiPaperPlane } from "react-icons/bi";
import BuisnessDetailEdit from "./BuisnessDetailEdit";

import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import {
  GetAplicationDetails,
  GetApplicationInput,
  GetBankApplicationnput,
  GetPdfInput,
  sendForRequest,
  SetApplicationStatusFalse,
  SetBanksubmitStatusFalse,
  SetPdfStatusFalse,
  SetSigningStatusFalse,
  SubmitApplicationInput,
  SubmitBankapplication,
  SubmitNewApplicationInput,
  SubmitPdfUrl,
  SubmitSiginingRequest,
} from "../_redux/action/NewapplicationAction";

import { useDispatch } from "react-redux";

import NewAppSchedule from "./NewAppSchedule";
import EditScheduleOfFees from "./EditScheduleOfFees";
import Pdf from "../PdfFile/Pdf";

import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import AplicationErrors from "./AplicationErrors";
import { useEffect } from "react";
import Documentedit from "./Documentedit";
import PreviewProductEit from "./PreviewProductEit";

import PreviewProduct from "./PreviewProduct";
import { BsDownload } from "react-icons/bs";
import { TbSend } from "react-icons/tb";
import NewPdf from "../PdfFile/NewPdf";
import PreviewSiteVisit from "./PreviewSiteVisit";
import PreviewSiteVisitEdit from "./PreviewSiteVisitEdit";
const Preview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [edit, setEdit] = useState(true);
  const [application, setApplication] = useState(true);
  const [financialInfo, setFinancialInfo] = useState(true);
  const [buisnessDetail, setBuisnessDetail] = useState(true);
  const [feesDetail, setFeesDetail] = useState(true);
  const [documentDetail, setDocumentDetail] = useState(true);
  const [siteVisit, setSiteVisit] = useState(true);
  const [product, setProduct] = useState(true);
  const [pdfTrue, setPdfTrue] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const cRef = useRef();
  const printPDF = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Paymentsave.uk",
    bodyClass: "dis",
  });
  const handePdf = () => {
    printPDF();
  };
  const data = {
    mcc: "2345",
    legalName: "Shafin Ahmed",
    postCode: "12345",
    clientGroup: "DevsStream",
    fundingCurrency: "20000",
    CardBundle: "112",
    newApplication: true,
    changeOfligalEntity: true,
  };

  const customerDetailEdit = () => {
    setEdit(!edit);
  };
  const applicationDetailEdit = () => {
    setApplication(!application);
  };
  const financialInfoEdit = () => {
    setFinancialInfo(!financialInfo);
  };
  const buisnessDetailEdit = () => {
    setBuisnessDetail(!buisnessDetail);
  };
  const feesDetailEdit = () => {
    setFeesDetail(!feesDetail);
  };
  const documentDetailEdit = () => {
    setDocumentDetail(!documentDetail);
  };
  const ProductEdit = () => {
    setProduct(!product);
  };
  const siteVisiteEdit = () => {
    setSiteVisit(!siteVisit);
  };

  // ---api call-----------------
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const leadInput = useSelector(
    (state) => state.leadInfo.leadInput
  );
  const submitApplicationInput = useSelector(
    (state) => state.applicationInfo.submitApplicationInput
  );
  const afterSubmitBank = useSelector(
    (state) => state.applicationInfo.afterSubmitBank
  );
  const afterSuccessAplication = useSelector(
    (state) => state.applicationInfo.afterSuccessAplication
  );
  const siginingInput = useSelector(
    (state) => state.applicationInfo.siginingInput
  );
  const pdfInput = useSelector((state) => state.applicationInfo.pdfInput);
  const afterSuccessPdf = useSelector(
    (state) => state.applicationInfo.afterSuccessPdf
  );
  const afterSuccessSigning = useSelector(
    (state) => state.applicationInfo.afterSuccessSigning
  );
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const handleSubmitAppplication = (data) => {
    // dispatch(GetApplicationInput("sales_partner", userData.id));
    dispatch(SubmitApplicationInput(data));
  };
  const handleSubmitNewAppplication = (data) => {
    // dispatch(GetApplicationInput("sales_partner", userData.id));
    dispatch(SubmitNewApplicationInput(data));
  };
  const handleSubmitToBank = (data) => {
    dispatch(GetBankApplicationnput("application_id", applicationInput.id));
    dispatch(SubmitBankapplication(data, applicationInput.id));
    // history.push(`/newapplication`)
  };
  useEffect(() => {
    if (afterSubmitBank) {
      history.push(`/newapplication`);
      dispatch(SetBanksubmitStatusFalse());
    }
  }, [afterSubmitBank]);
  useEffect(() => {
    if (afterSuccessAplication) {
      dispatch(GetAplicationDetails(applicationInput.id));
      dispatch(SetApplicationStatusFalse());
    }
  }, [afterSuccessAplication]);

  useEffect(() => {
    if (afterSuccessSigning && siginingInput.pdf_url) {
      const timeout = setTimeout(() => {
        // dispatch(SetSigningStatusFalse());
        // window.open(siginingInput.pdf_url, "_blank", "noopener,noreferrer");
      }, 2000);

      return () => {
        clearTimeout(timeout);
        // dispatch(SetSigningStatusFalse());
      };
    }
  }, [afterSuccessSigning, siginingInput.pdf_url]);
 

  useEffect(() => {
    if (afterSuccessPdf && pdfInput.pdf_url) {
      // fetch(pdfInput.pdf_url, {
      //   method: "GET",
      //   responseType: "blob",
      //   headers: {
      //     "Content-Type": "application/pdf",

      //     accept: "*/*",
      //     authorization: `Token ${localStorage.getItem("access_token")}`,
      //   },
      // })
      //   .then((response) => {
      //     console.log("Response headers:", response);
      //     return response.blob();
      //   })
      //   .then((blob) => {
      //     console.log("blob", blob);
      //     const url = window.URL.createObjectURL(new Blob([blob]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "sample.pdf");
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch((error) => console.error("Error downloading PDF: ", error));
      const timeout = setTimeout(() => {
        window.open(pdfInput.pdf_url, "_blank", "noopener,noreferrer");

        // 👇️ redirects to an external URL
        // window.open(pdfInput.pdf_url, "_blank", "noopener,noreferrer");
        dispatch(SetPdfStatusFalse());
      }, 3000);

      return () => {
        dispatch(SetPdfStatusFalse());
        clearTimeout(timeout);
      };
    }
    // dispatch(SetPdfStatusFalse());
  }, [afterSuccessPdf, pdfInput.pdf_url]);

  const handleDownloadEsignPdf = () => {
    const timeout = setTimeout(() => {
      // e.preventDefault();
      // 👇️ redirects to an external URL
      // window.open("https://ev.devsstream.com/media/applications/PS-000099.pdf", "_blank", "noopener,noreferrer");
      window.open(
        applicationInput.sign_back_pdf_url,
        "_blank",
        "noopener,noreferrer"
      );
    }, 2000);

    return () => {
      clearTimeout(timeout);
      dispatch(SetSigningStatusFalse());
    };
  };

  useEffect(() => {
    if (applicationInput.legal_type === "CH") {
      dispatch(GetApplicationInput("company_house_no", ""));
    }
    if (applicationInput.legal_type === "ST") {
      dispatch(GetApplicationInput("company_house_no", ""));
    }
    // if(applicationInput.sales_internet_perc === "100" || applicationInput.application_type === 2 ){
    //   dispatch(GetApplicationInput("application_products", []));
    // }
  }, []);
  useEffect(() => {
    if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
    if (applicationInput.mmsc === null || applicationInput.mmsc === "") {
      dispatch(GetApplicationInput("mmsc", 0));
    }
  }, []);

  useEffect(() => {
    dispatch(
      GetApplicationInput("application_products", [
        ...(leadInput?.terminal_products ?? []),
        ...(leadInput?.ecommerce_products ?? []),
        ...(leadInput?.epos_products ?? []),
      ])
    );
  }, [leadInput?.terminal_products,leadInput?.ecommerce_products,leadInput?.epos_products ]);

  if (pdfTrue === true) {
    return (
      <div
      // style={{ display: "block" }}
      >
        {/* <Pdf cRef={cRef} data={data} /> */}
        <NewPdf cRef={cRef} data={data} />
      </div>
    );
  }

  const handleSigingRequest = (data) => {
    dispatch(sendForRequest("application_id", applicationInput.id));
    dispatch(
      sendForRequest(
        "application_email",
        applicationInput.business_owners[0].owner_email
      )
    );
    dispatch(
      SubmitSiginingRequest(
        data,
        applicationInput.id,
        applicationInput.business_owners[0].owner_email
      )
    );
  };
  const handlePdfDownload = (data) => {
    dispatch(GetPdfInput("application_id", applicationInput.id));

    dispatch(SubmitPdfUrl(data, applicationInput.id));
  };

  

  return (
    <>
      <div id="printme" style={{ display: "none" }}>
        <Pdf cRef={cRef} data={data} />
        {/* <NewPdf cRef={cRef} data={data} /> */}
        {/* <FdglPdf cRef={cRef} data={data} /> */}
        {/* <LastPdf cRef={cRef} data={data} /> */}
      </div>
      <div className="main box-wrapper2 text-capitalize">
        {/* ------------heading box------------------ */}
        <div className="d-flex justify-content-between">
          <div className="heading-box ">
            <img src={image} alt="" />
            <h2>New Application</h2>
          </div>
          <div className="d-flex gap-2">
            {afterSuccessSigning || applicationInput.applicaiton_stage === 5 ? (
              <button
                style={{
                  backgroundColor: "rgb(235 87 87) ",
                  padding: "6px 10px",
                }}
                className="custom-btn   flex mx-2"
                onClick={() => handleSigingRequest(siginingInput)}
              >
                <TbSend /> Resend
              </button>
            ) : (
              <button
                style={{ backgroundColor: "#224eb2c9 ", padding: "6px 10px" }}
                className="custom-btn   flex mx-2"
                onClick={() => handleSigingRequest(siginingInput)}
              >
                <TbSend /> Request Signature
              </button>
            )}
            {applicationInput.applicaiton_stage === 6 && (
              <button
                style={{ backgroundColor: "#e95184c7 ", padding: "6px 10px" }}
                className="    custom-btn  flex mx-2 "
                onClick={() => handleDownloadEsignPdf()}
                // onClick={() => downloadPdf()}
              >
                <BsDownload /> E-Sign Pdf
              </button>
            )}
          </div>
        </div>

        {/* -------------heading box----------------- */}
        {/* -------------Customar detail start ------------------ */}
        {edit ? (
          <CustomarDetail customerDetailEdit={customerDetailEdit} />
        ) : (
          <CustomarDetailEdit customerDetailEdit={customerDetailEdit} />
        )}

        {/* -------------Customar detail end ------------------ */}

        {/* ----------------Buisness Detail----------------- */}
        {buisnessDetail ? (
          <BuisnessDetail buisnessDetailEdit={buisnessDetailEdit} />
        ) : (
          <BuisnessDetailEdit buisnessDetailEdit={buisnessDetailEdit} />
        )}

        {/* ----------------Buisness Detail----------------- */}
        {/* -------------Shedule Of fees start------------------- */}

        {/* -------------Shedule Of fees end------------------- */}
        {/* ------------------Application Details---------------- */}
        {application ? (
          <ApplicationDetails applicationDetailEdit={applicationDetailEdit} />
        ) : (
          <ApplicationDetailEdit
            applicationDetailEdit={applicationDetailEdit}
          />
        )}

        {/* ------------------Application Details---------------- */}
        {/* -------------Financial Information---------- */}
        {financialInfo ? (
          <FinancialInformation financialInfoEdit={financialInfoEdit} />
        ) : (
          <FinancialInformationEdit financialInfoEdit={financialInfoEdit} />
        )}
        {feesDetail ? (
          <NewAppSchedule feesDetailEdit={feesDetailEdit} />
        ) : (
          <EditScheduleOfFees feesDetailEdit={feesDetailEdit} />
        )}
        {siteVisit ? (
          <PreviewSiteVisit siteVisiteEdit={siteVisiteEdit} />
        ) : (
          <PreviewSiteVisitEdit siteVisiteEdit={siteVisiteEdit} />
        )}

        {documentDetail ? (
          <Document documentDetailEdit={documentDetailEdit} />
        ) : (
          <Documentedit documentDetailEdit={documentDetailEdit} />
        )}
        {product ? (
          <PreviewProduct ProductEdit={ProductEdit} />
        ) : (
          <PreviewProductEit ProductEdit={ProductEdit} />
        )}

        {/* -------------Financial Information---------- */}
        {/* -------------Document---------- */}
        {isLoadLeads && (
          <Backdrop
            open
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {/* <Document /> */}
        {/* -------------Document---------- */}

        {applicationInput.application_errors !== "" &&
          applicationInput.application_errors !== null && <AplicationErrors />}

        <div
          className="d-flex mt-4"
          style={{ justifyContent: "end", marginBottom: "50px" }}
        >
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={() => history.push(`/newapplication`)}
          >
            Cancel
          </button>
          {applicationInput.id ? (
            <button
              style={{ backgroundColor: "#325CC4", padding: "6px 10px" }}
              className="    custom-btn  flex mx-2 "
              onClick={() => {
                handleSubmitAppplication(applicationInput);
              }}
            >
              Update
            </button>
          ) : (
            <button
              style={{ backgroundColor: "#325CC4", padding: "6px 10px" }}
              className="    custom-btn  flex mx-2 "
              onClick={() => {
                handleSubmitNewAppplication(applicationInput);
              }}
            >
              Save
            </button>
          )}

          <button
            // disabled
            style={{ padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={handePdf}
          >
            Download Pdf
          </button>
          <button
            style={{ backgroundColor: "#bb86fc ", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={() => handlePdfDownload(pdfInput)}
            // onClick={() => downloadPdf()}
          >
            Download Full Form
          </button>
          {applicationInput.application_errors !== "" &&
          applicationInput.application_errors !== null ? (
            <button
              style={{
                backgroundColor: "red",
                padding: "6px 10px",
                color: "white",
              }}
              className="custom-btn  flex mx-2 "
              onClick={(e) => {
                handleSubmitToBank(submitApplicationInput, e);
              }}
            >
              <BiPaperPlane className="me-1" style={{ fontSize: "20px" }} />
              Resubmit Application
            </button>
          ) : (
            <button
              style={{
                backgroundColor: " #3399FF",
                padding: "6px 10px",
                color: "white",
              }}
              className="    custom-btn  flex mx-2 "
              onClick={() => {
                handleSubmitToBank(submitApplicationInput);
              }}
            >
              <BiPaperPlane className="me-1" style={{ fontSize: "20px" }} />
              Send To bank
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Preview;
