import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GetApplicationInput,
  SetBankInfoTrue,
} from "./_redux/action/NewapplicationAction";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { useEffect } from "react";
const FinanceInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(true);
  const [bankName1, setbankName] = useState("");
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
    if(applicationInput.bank_name === "SANTANDER"){
      dispatch(GetApplicationInput("bank_faster_payments", "0"));
    }
  };
  useEffect(() => {
   if(applicationInput.bank_name === "SANTANDER"){
    dispatch(GetApplicationInput("bank_faster_payments", "0"));
   }
  }, [bankName1])
  
  const fasterPayment = (
    <p>
      Faster Payment : <span className="required">*</span>
    </p>
  );
  const bankName = (
    <p>
      Bank Name <span className="required">*</span>
    </p>
  );
  const bankAccountName = (
    <p>
      Bank Account Name <span className="required">*</span>
    </p>
  );
  const accountNumber = (
    <p>
      Bank Account Number <span className="required">*</span>
    </p>
  );
  const sortCode = (
    <p>
      Sort Code <span className="required">*</span>
    </p>
  );

  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
    if(applicationInput.bank_name === "SANTANDER"){
      dispatch(GetApplicationInput("bank_faster_payments", "0"));
    }
  };
  const handleSelectVat = () => {
    setToggle(!toggle);
    dispatch(GetApplicationInput("vat_override", toggle));
  };

  const bankInfoChecked = useSelector(
    (state) => state.applicationInfo.bankInfoChecked
  );
  const handleChange = (e) => {
    dispatch(SetBankInfoTrue(!bankInfoChecked));
    handleaddrss(e);
  };
  const handleAutofil = (pCode, add1, add2, city) => {
    dispatch(GetApplicationInput("bank_name", pCode));
    dispatch(GetApplicationInput("bank_account_name", add1));
    dispatch(GetApplicationInput("bank_sort_code", add2));
    dispatch(GetApplicationInput("bank_account_number", city));
  };
  const handleaddrss = (e) => {
    if (e.target.checked) {
      handleAutofil(
        applicationInput.bank_name,
        applicationInput.bank_account_name,
        applicationInput.bank_sort_code,
        applicationInput.bank_account_number,
        applicationInput.legal_county,
        applicationInput.legal_country
      );
      dispatch(GetApplicationInput("site_and_trading_address_same", 1));
    } else {
      handleAutofil("", "", "", "", "", "");
      dispatch(GetApplicationInput("site_and_trading_address_same", 2));
    }
  };
  return (
    <div className="">
      <div className="info-box text-capitalize">
        <div className="d-flex heading">
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>

          <p>Financial Information</p>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="mb-form">
              <div className="row">
                <div className="col-4 col-lg-4">
                  <p> Will you be renting terminal proms from Elavon? :</p>
                </div>
                <div className="col-8 col-lg-8">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("renting_elavon_terminals", true)
                      }
                      size="small"
                      variant={
                        applicationInput.renting_elavon_terminals === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("renting_elavon_terminals", false)
                      }
                      size="small"
                      variant={
                        applicationInput.renting_elavon_terminals === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-form">
              <div className="row">
                <div className="col-4 col-lg-4">
                  <p> Faster Payment :</p>
                </div>
                <div className="col-8 col-lg-8">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("bank_faster_payments", "1")
                      }
                      size="small"
                      variant={
                        applicationInput.bank_faster_payments === "1"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("bank_faster_payments", "0")
                      }
                      size="small"
                      variant={
                        applicationInput.bank_faster_payments === "0"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-md-3">
                <p>Payment Method: </p>
              </div>
              <div className="col-lg-10 col-md-9">
                <div className="d-sm-flex gap-3  mb-form">
                  <Button
                    onClick={() => handleSelectButton("payment_method", "EBA")}
                    size="small"
                    variant={
                      applicationInput.payment_method === "EBA"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    EBA
                  </Button>
                  <Button
                    onClick={() => handleSelectButton("payment_method", "ALB")}
                    size="small"
                    variant={
                      applicationInput.payment_method === "ALB"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    ALB
                  </Button>
                  <Button
                    onClick={() =>
                      handleSelectButton("payment_method", "CHAIN")
                    }
                    size="small"
                    variant={
                      applicationInput.payment_method === "CHAIN"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Chain
                  </Button>
                  <Button
                    onClick={() => handleSelectButton("payment_method", "OT")}
                    size="small"
                    variant={
                      applicationInput.payment_method === "OT"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    OT
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-12"></div>

            <div className="row">
              <div className="col-md-3 col-lg-2">
                <p>Funding Frequency: </p>
              </div>
              <div className="col-lg-10 col-md-9">
                <div className="d-sm-flex gap-3  mb-form">
                  <Button
                    onClick={() =>
                      handleSelectButton("funding_frequesncy", "DAILY")
                    }
                    size="small"
                    variant={
                      applicationInput.funding_frequesncy === "DAILY"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Daily
                  </Button>
                  <Button
                    onClick={() =>
                      handleSelectButton("funding_frequesncy", "WEEKLY")
                    }
                    size="small"
                    variant={
                      applicationInput.funding_frequesncy === "WEEKLY"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Weekly
                  </Button>
                  <Button
                    onClick={() =>
                      handleSelectButton("funding_frequesncy", "MONTHLY")
                    }
                    size="small"
                    variant={
                      applicationInput.funding_frequesncy === "MONTHLY"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Monthly
                  </Button>
                </div>
              </div>
            </div>
            {applicationInput.funding_frequesncy === "WEEKLY" && (
              <div className="col-md-6">
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    label="Funding Day"
                    variant="outlined"
                    size="small"
                    name="funding_day"
                    value={applicationInput.funding_day}
                    onChange={(e) =>
                      handleChangeInput("funding_day", e.target.value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-2 col-md-3">
                <p>Vat Override: </p>
              </div>
              <div className="col-lg-10 col-md-9">
                <div className="d-sm-flex gap-3  mb-form">
                  <Button
                    // onClick={() => handleSelectButton("vat_override", true)}
                    onClick={() => handleSelectVat()}
                    size="small"
                    variant={
                      applicationInput.vat_override === true
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Yes / Rate
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-form">
                <div className="row">
                  <div className="col-2">
                    <p>Billing Frequency: </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("billing_frequesncy", "DAILY")
                        }
                        size="small"
                        variant={
                          applicationInput.billing_frequesncy === "DAILY"
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        Daily
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("billing_frequesncy", "WEEKLY")
                        }
                        size="small"
                        variant={
                          applicationInput.billing_frequesncy === "WEEKLY"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Weekly
                      </Button>

                      <Button
                        onClick={() =>
                          handleSelectButton("billing_frequesncy", "MONTHLY")
                        }
                        size="small"
                        variant={
                          applicationInput.billing_frequesncy === "MONTHLY"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Monthly
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {applicationInput.billing_frequesncy === "WEEKLY" && (
              <div className="col-md-6">
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Billing Day"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    variant="outlined"
                    size="small"
                    name="billing_day"
                    value={applicationInput.billing_day}
                    onChange={(e) =>
                      handleChangeInput("billing_day", e.target.value)
                    }
                  />
                </div>
              </div>
            )}
            {/* <div className="col-md-6"></div> */}
            <div className="col-md-6">
              <div className="mb-form">
                <div className="row">
                  <div className="col-4">
                    <p>Sattelment Method: </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_settlement_method", "GROSS")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_settlement_method === "GROSS"
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        Gross
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_settlement_method", "NET")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_settlement_method === "NET"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Net
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-form">
                <div className="row">
                  <div className="col-2">
                    <p>Account: </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_account_type", "ALL")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_account_type === "ALL"
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        All
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_account_type", "DEPOSIT")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_account_type === "DEPOSIT"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Deposite
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_account_type", "BILLING")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_account_type === "BILLING"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Billing
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("bank_account_type", "CHARGEBACK")
                        }
                        size="small"
                        variant={
                          applicationInput.bank_account_type === "CHARGEBACK"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Chargeback
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Billing Day"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  size="small"
                  name="billing_day"
                  value={applicationInput.billing_day}
                  onChange={(e) =>
                    handleChangeInput("billing_day", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  label="Funding Day"
                  variant="outlined"
                  size="small"
                  name="funding_day"
                  value={applicationInput.funding_day}
                  onChange={(e) =>
                    handleChangeInput("funding_day", e.target.value)
                  }
                />
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Delay Days"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  variant="outlined"
                  size="small"
                  name="delay_days"
                  value={applicationInput.delay_days}
                  onChange={(e) =>
                    handleChangeInput("delay_days", e.target.value)
                  }
                />
              </div>
            </div> */}
            <div className="d-flex heading mb-form">
              <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                  fill="#0D0D0D"
                />
              </svg>

              <p>Settelment account details</p>
            </div>
            <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label={bankName}
                  variant="outlined"
                  size="small"
                  name="bank_name"
                  value={applicationInput.bank_name}
                  onChange={(e) =>
                    {handleChangeInput("bank_name", e.target.value)
                    setbankName(e.target.value)
                  }
                  }
                />
              </div>
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  error={
                    applicationInput?.bank_sort_code?.length !== 6 &&
                    applicationInput?.bank_sort_code !== "" &&
                    true
                  }
                  label={sortCode}
                  variant="outlined"
                  size="small"
                  inputProps={{maxLength:6}}
                  name="bank_sort_code"
                  value={applicationInput.bank_sort_code}
                  onChange={(e) =>
                    handleChangeInput("bank_sort_code", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label={bankAccountName}
                  variant="outlined"
                  size="small"
                  name="bank_account_name"
                  value={applicationInput.bank_account_name}
                  onChange={(e) =>
                    handleChangeInput("bank_account_name", e.target.value)
                  }
                />
              </div>
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  error={
                    applicationInput?.bank_account_no !== "" &&
                    applicationInput?.bank_account_no?.length !== 8 &&
                    true
                  }
                  inputProps={{maxLength:8}}
                  type="number"
                  label={accountNumber}
                  variant="outlined"
                  size="small"
                  name="bank_account_no"
                  value={applicationInput.bank_account_no}
                  onChange={(e) =>
                    handleChangeInput("bank_account_no", e.target.value)
                  }
                />
              </div>
            </div>
            {/* <div className="mt-4">
          <div className="row">
              <div className="col-6">
              <div className="d-flex heading mb-form">
              <svg
                  width="24"
                  height="28"
                  viewBox="0 0 24 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                    fill="#0D0D0D"
                  />
                </svg> 

              <p>Direct debit account details</p>
             
            </div>
              </div>
              <div className="col-6">
              <Form.Group
                className="checkBox"
                controlId="formBasicCheckbox"
                style={{ marginTop: "8px" }}
              >
                <Form.Check
                 type="checkbox"
                 label="Same as Legal Address"
                //  checked={applicationInput.site_and_trading_address_same === 1 ? true : false}
                //  checked={isAddressChecked}
                //  onChange={(e) => handleChange(e)}
                  />
              </Form.Group>
              </div>
            </div>
         
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={bankName}
                variant="outlined"
                size="small"
                name="bank_name"
                value={applicationInput.bank_name}
                onChange={(e) => handleChangeInput("bank_name", e.target.value)}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={sortCode}
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                name="bank_sort_code"
                value={applicationInput.bank_sort_code}
                onChange={(e) =>
                  handleChangeInput("bank_sort_code", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={bankName}
                variant="outlined"
                size="small"
                name="bank_account_name"
                value={applicationInput.bank_account_name}
                onChange={(e) =>
                  handleChangeInput("bank_account_name", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={accountNumber}
                variant="outlined"
                size="small"
                type="number"
                name="bank_account_no"
                value={applicationInput.bank_account_no}
                onChange={(e) =>
                  handleChangeInput("bank_account_no", e.target.value)
                }
              />
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceInfo;
