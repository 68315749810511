import React from "react";

import { useState } from "react";

import ContactInfoBox from "./ContactInfoBox";

import { toast } from "react-toastify";



const ContactDetail = () => {


  const values = {
    someDate: "2018-05-24",
  };

  const [addContact, setAddContact] = useState([]);
  // const [ConCode, setConCode] = useState([]);

  const handleAddInfo = () => {
    setAddContact([...addContact, { id: new Date().getTime() }]);
    toast("Added Data SuccesFully!");
  };

  // const fill = countryCode.filter((op) => op.code === codeId && op.dial_code);
  // useEffect(() => {
  //   setConCode(fill);
  // }, [codeId]);

  return (
    <>
      <div className="">
        <div className="mt-4">
          <div className="row">
            <ContactInfoBox handleAddInfo={handleAddInfo} values={values} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetail;
