import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Backdrop from "@mui/material/Backdrop";

import Button from "@mui/material/Button";

const LoadingCheck = ({ is_loading, FilteredLeadList, isLoadLeads }) => {

  return (
    <>
      {is_loading && (
        <>
          {/* <div className="d-flex justify-content-center  mt-5 mb-5 ">
            <Box sx={{ display: "flex  " }}>
              <CircularProgress />
            </Box>
          </div> */}
          <Backdrop
            open
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}

      {!is_loading && isLoadLeads && FilteredLeadList.length <= 0 && (
        <>
          <tr>
            <td colspan="12">
              <div
                class="alert alert-success text-center mt-5 mb-5"
                role="alert"
              >
                Sorry ! No data found.
              </div>
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default LoadingCheck;
