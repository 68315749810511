import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Table from "react-bootstrap/Table";
import { Form, Col } from "react-bootstrap";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { GetApplicationInput } from "./_redux/action/NewapplicationAction";
import {
  GetIndustryList,
  GetPricequoteInput,
  GetSourceList,
} from "../../_redux/action/LeadsAction";
import { useEffect } from "react";
const ScheduleFeeTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();


 
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );

  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
    // if (name === "mmsc") dispatch(GetPricequoteInput("mmsc", value));
  };
  return (
    <div className="form-wrapper">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Table </p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-12 table-responsive mb-4 ">
            <Table striped className="table-body table-card">
              <thead className="table-head">
                {/* <tr>
                  <th style={{ position: "static", textAlign: "center" }}>
                    Card Type
                  </th>
                  <th style={{ textAlign: "center" }}>Secure Rate </th>
                  <th> </th>
                </tr> */}
              </thead>
              <tbody>
                <tr>
                  <td> (£) Auth Fees</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Control
                    
                        type="number"
                         placeholder="£"
                        onWheel={(e) => e.target.blur()}
                        name="auth_fees"
                        value={priceQuoteInput.auth_fees}
                        onChange={(e) => {
                          handleChangeInput("auth_fees", e.target.value);
                        }}
                      />
                    </Form.Group>
               
                  </td>
                  <td> </td>
                </tr>

                <tr>
                  <td>(£) MMSC</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Select
                        name="mmsc"
                        onWheel={(e) => e.target.blur()}
                        value={priceQuoteInput.mmsc}
                        onChange={(e) => {
                          handleChangeInput("mmsc", e.target.value);
                          // setType2(e.target.value);
                        }}
                      >
                          <option value="">--</option>
                        <option value="0">£ 0</option>
                        <option value="15">£ 15</option>
                        <option value="30">£ 30</option>
                      </Form.Select>
                    </Form.Group>
                  </td>
                  <td> </td>
                </tr>

                <tr>
                  <td>(£) Average transaction value</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Control
                        type="number"
                         placeholder="£"
                        name="atv"
                        onWheel={(e) => e.target.blur()}
                        value={priceQuoteInput.atv}
                        onChange={(e) => {
                          handleChangeInput("atv", e.target.value);
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td>(£) Single maximum transaction value</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Control
                        type="number"
                         placeholder="£"
                        onWheel={(e) => e.target.blur()}
                        name="smtv"
                        value={priceQuoteInput.smtv}
                        onChange={(e) => {
                          handleChangeInput(
                            "smtv",
                            e.target.value
                          );
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td>(£) Annual turnover</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Control
                       className={` ${ parseFloat(priceQuoteInput.annual_turnover) < parseFloat(priceQuoteInput.annual_card_turnover)&& priceQuoteInput.union_pay_sr !==""  ? "error_input" : " input"}`}
                        type="number"
                         placeholder="£"
                        onWheel={(e) => e.target.blur()}
                        name="annual_turnover"
                        value={priceQuoteInput.annual_turnover}
                        onChange={(e) => {
                          handleChangeInput("annual_turnover", e.target.value);
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td>(£) Annual Card turnover</td>
                  <td>
                    <Form.Group controlId="formBasicSelect">
                      <Form.Control
                        className={` ${ parseFloat(priceQuoteInput.annual_turnover) < parseFloat(priceQuoteInput.annual_card_turnover)&& priceQuoteInput.union_pay_sr !==""  ? "error_input" : " input"}`}
                        type="number"
                         placeholder="£"
                        onWheel={(e) => e.target.blur()}
                        name="annual_card_turnover"
                        value={priceQuoteInput.annual_card_turnover}
                        onChange={(e) => {
                          handleChangeInput(
                            "annual_card_turnover",
                            e.target.value
                          );
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td> </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="mt-4"></div>
    </div>
  );
};

export default ScheduleFeeTable;
