export const title = (
    <p>
      Title <span className="required">*</span>
    </p>
  );
  export const firstname = (
    <p>
      First Name <span className="required">*</span>
    </p>
  );
  export const lastName = (
    <p>
     Last Name <span className="required">*</span>
    </p>
  );
  export const idNumber = (
    <p>
     ID Number<span className="required">*</span>
    </p>
  );
  export const issuedate = (
    <p>
     Issue Date<span className="required">*</span>
    </p>
  );
  export const expeiryDate = (
    <p>
   Expiry Date <span className="required">*</span>
    </p>
  );
  export const nationality = (
    <p>
     Nationality<span className="required">*</span>
    </p>
  );
  export const Country = (
    <p>
     Country<span className="required">*</span>
    </p>
  );
  export const Date_birth = (
    <p>
      Date Of Birth <span className="required">*</span>
    </p>
  );
  export const PrimaryEmail = (
    <p>
      Email <span className="required">*</span>
    </p>
  );
  export const mobile_number = (
    <p>
      Phone Number <span className="required">*</span>
    </p>
  );
  export const PercentofOwnership = (
    <p>
      Percent of Ownership <span className="required">*</span>
    </p>
  );
  export const postCode = (
    <p>
      Postcode <span className="required">*</span>
    </p>
  );
  export const address1 = (
    <p>
      Address 1 <span className="required">*</span>
    </p>
  );
  export const city = (
    <p>
      Town / City <span className="required">*</span>
    </p>
  );
  export const provider = (
    <p>
      Provider <span className="required">*</span>
    </p>
  );
  export const searchProduct = (
    <p>Search Product <span className="required">*</span>
    </p>
  );
  export const connectionType = (
    <p>
    Connection Type <span className="required">*</span>
    </p>
  );
  export const contactLength = (
    <p>
      Contact Length <span className="required">*</span>
    </p>
  );
  export const TerminalModel = (
    <p>
   Terminal Model <span className="required">*</span>
    </p>
  );
  export const price = (
    <p>
     Monthly Price <span className="required">*</span>
    </p>
  );
  export const Quantity = (
    <p>
     Terminal Quantity <span className="required">*</span>
    </p>
  );