import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
// import { SetApplicationDetails } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useParams } from "react-router-dom";
import PreviewScheduleofFees from "./PreviewScheduleofFees";
import AppScheduleFees from "../AppScheduleFees";
const NewAppSchedule = ({ feesDetailEdit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  // ---api call-----------------
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);

  // useEffect(() => {
  //   dispatch(SetApplicationDetails(id));
  // }, []);
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100 table-responsive"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Schedule of Fees</h4>
        </div>
        <div className="button-box">
          <button className="   custom-btn  flex " onClick={feesDetailEdit}>
            <img src={pen} style={{ marginRight: "7px" }} alt="" />
            Edit
          </button>
        </div>
      </div>
      {/* -------------fees content----------------- */}

     {/* <PreviewScheduleofFees/> */}
     <AppScheduleFees/>
      {/* -------------fees content----------------- */}
    </div>
  );
};

export default NewAppSchedule;
