import React, { useEffect, useState, useRef } from "react";
import FilterPagination from "../Shared/FilterPagination";
import "./Inprogress.css";

import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { ApplicationStatus } from "../../allAplication/mainLead/components/commons/Dropdown";
import { GetAplicationDetails } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useDispatch } from "react-redux";
import eye from "../../../asstes/icon/eye.png";
import { FaEye } from "react-icons/fa";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import application from "../../../asstes/icon/appli.png";
import printer from "../../../asstes/icon/printer.png";
import { BASE_URL } from "../../../Const";
import { useReactToPrint } from "react-to-print";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import PageNumberCount from "../NewApplication/PageNumberCount";

const DechlineApplicationList = ({isLoading, setIsloading}) => {
  // isLoading, setIsloading
  const history = useHistory();
  const dispatch = useDispatch();
  const [secondLoader, setSecondLoader] = useState(true);
  const [totalCount, setTotalCount] = useState([]);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPage, setSeletedPage] = useState(0);
  const [search, setSearch] = useState("");
  const pageVisited = pageNumber * itemPerPage;
  const cRef = useRef();
  const [pdfData, setPdfData] = useState();
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "All Application List",
    bodyClass: "dis",
  });
  const getPrint = () => {
    HandlePrint();
  };
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#app-table", theme: "grid" });
    doc.text("All Application table", 20, 10);

    doc.save("All Application.pdf");
  };
  // ----------search query----------
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  const handleViewApplication = (data) => {
    dispatch(GetAplicationDetails(data.id));
    history.push(`/decline/${data.id}`);
  };
  // -------------arrow--------------
  const [partnerSpan, SetPartnerSpan] = useState(true);
  const [legalSpan, SetLegalSpan] = useState(true);
  const [loading, Setloading] = useState(false);
  const [tradingSpan, SetTradingSpan] = useState(true);
  const [submittedSpan, SetSubmittedSpan] = useState(true);
  const [midSpan, SetMidSpan] = useState(true);
  const [statusSpan, SetStatusSpan] = useState(true);
  // const [submittedSpan, SetSubmittedSpan] = useState(true);
  const [clientIdSpan, SetClientIdSpan] = useState(true);
  const [totalItems, setTotalItems] = useState([]);
  const [totalData, SetTotalData] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);

  // ----------main data state---------
  const [leads, setLeads] = useState([]);
  // const pageCounts = Math.ceil(leads.length / itemPerPage);

  const [query, setQuery] = useState({
    legal_name_Input: "",
    trading_name_Input: "",
    client_id_Input: "",
    bank_account_name_Input: "",
    boarding_id_Input: "",
    submitted_on: "",
    elavon_submission_status: "",
  });
  // const pageCounts = Math.ceil(totalItems?.count / itemPerPage);
  const pageCounts = Math.ceil(totalData / itemPerPage);
  const pageCountsNumber = Math.ceil(totalCount?.count / itemPerPage);

  const indexOfLastPost = pageNumber * itemPerPage;
  // const indexOfLastPost =
  // pageCounts < pageNumber ? itemPerPage : pageNumber * itemPerPage;
  const indexOfFirstPost = indexOfLastPost - itemPerPage;
  const [sort, setSort] = useState("ASC");

  const offset = pageCounts < pageNumber ? 0 : indexOfLastPost - itemPerPage;
  var options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const getNewApplication = (url) => {
    axios
      .get(url)
      .then((res) => {
        const newData = res?.data?.data?.results?.map((curr) => {
          const submitted_on = new Date(curr?.submitted_on);
          const submitted_date = `${submitted_on.getDate()}/${submitted_on.getMonth() + 1}/${submitted_on.getFullYear()}`;
          const submitted_time = `${submitted_on.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
          return {
            ...curr,
            submitted_on: `${submitted_date} at ${submitted_time}`,
            client_id: curr?.client_id === null ? "N/A" : curr?.client_id,
          };
        });
        SetTotalData(res?.data?.data?.count);
        setNextUrl(res?.data?.data?.next);
        setPrevUrl(res?.data?.data?.previous);
        setLeads(newData);
        setIsloading(false);
        setSecondLoader(false);
      })
      .catch((err) => {
        const message = JSON.parse(err?.request?.response)?.message;
        console.log(err.request.response);
        if (
          message === 'Invalid token.' ||
          JSON.parse(err.request.response).code === 401
        ) {
          localStorage.removeItem('is_logged_in');
          localStorage.removeItem('access_token');
          localStorage.removeItem('userData');
          history.push('/');
        }
      });
  };
  const getNewApplicationPdf = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setIsloading(true)
        const newData = res?.data?.data?.results?.map((curr) => ({
          ...curr,
          submitted_on: new Date(curr?.submitted_on).toLocaleDateString(
            "en-US",
            options
          ),
        }));
        setPdfData(newData);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  // console.log("leads", leads);
  // useEffect(() => {
  //   getNewApplication(
  //     `${BASE_URL}api/v1/applications/application/?application_status=1&limit=${itemPerPage}`
  //   );
  // }, []);
  useEffect(() => {
    getNewApplication(
      `${BASE_URL}api/v1/applications/application/?application_status=14&limit=${itemPerPage}&offset=${offset}&query=${search}`
    );
    //
  }, [search, itemPerPage, offset]);
  useEffect(() => {
    getNewApplicationPdf(
      `${BASE_URL}api/v1/applications/application/?query=${""}&application_status=14&limit=${totalData}`
    );
  }, [totalData]);

  useEffect(() => {
    if (pageCounts !== 0) {
      if (pageCounts <= pageNumber) {
        setPageNumber(pageCounts);
      }
    }
  }, [pageCounts]);
  useEffect(() => {
    setPageNumber(1);
  }, [itemPerPage]);

  const limit = itemPerPage;
  const total_item = totalData;
  const pageCount = Math.ceil(total_item / limit);
  const handlePaginationPrevious = () => {
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      client_id_Input: "",
      bank_account_name_Input: "",
      boarding_id_Input: "",
      submitted_on: "",
      elavon_submission_status: "",
    });
    getNewApplication(prevUrl);
    // setPageNumber(pageNumber - 1);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const downloadData = () => {
    const dataString = JSON.stringify(totalItems);
    const blob = new Blob([dataString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePaginationNext = () => {
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      client_id_Input: "",
      bank_account_name_Input: "",
      boarding_id_Input: "",
      submitted_on: "",
      elavon_submission_status: "",
    });
    getNewApplication(nextUrl);
    setPageNumber(pageNumber + 1);
  };

  const handleChangeCurrentPage = (val) => {
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      client_id_Input: "",
      bank_account_name_Input: "",
      boarding_id_Input: "",
      submitted_on: "",
      elavon_submission_status: "",
    });
    setPageNumber(val);
  };

  

  

  const handleFilterInput = (e) => {
    const InputName = e.target.name;
    const Inputval = e.target.value;
    setQuery((previous_values) => {
      return { ...previous_values, [InputName]: Inputval };
    });
  };

  const sortingData = (sortBy, stateSpan) => {
    if (sort === "ASC") {
      const sorted = [...leads]?.sort((a, b) =>
        a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase() ? 1 : -1
      );
      setTotalItems(sorted);
      setLeads(sorted);
      setSort("DSC");
      stateSpan(!true);
    } else if (sort === "DSC") {
      const sorted = [...leads]?.sort((a, b) =>
        a[sortBy]?.toLowerCase() < b[sortBy]?.toLowerCase() ? 1 : -1
      );
      setTotalItems(sorted);
      setLeads(sorted);
      setSort("ASC");
      stateSpan(!false);
    }
  };
  // display data
  // const displayData = leads
  const displayData = leads
    ?.filter((fill) => {
      if (query.legal_name_Input === "" || query.trading_name_Input === "") {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill?.legal_name
          ?.toLowerCase()
          .includes(query?.legal_name_Input.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill.client_id
          ?.toLowerCase()
          ?.includes(query?.client_id_Input?.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill?.trading_name
          ?.toLowerCase()
          ?.includes(query.trading_name_Input.toLowerCase())
      ) {
        return fill;
      }
    })

    .filter((fill) => {
      if (
        fill?.boarding_id
          ?.toLowerCase()
          ?.includes(query.boarding_id_Input.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill?.elavon_submission_status
          ?.toLowerCase()
          ?.includes(query.elavon_submission_status.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill?.submitted_on
          ?.toLowerCase()
          ?.includes(query.submitted_on.toLowerCase())
      ) {
        return fill;
      }
    });

  //
  if (loading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div
      className="open-sans border-t-[#E0E0E0]  border-t-[1px] "
      style={{ maxWidth: "100vw" }}
    >
      {/* <InprogressTop /> */}
      <div className="ap-top">
        <div className=" ap-top-box">
          <div className="ap-top-head">
            <div>
              <img src={application} alt="" />
            </div>
            <h2 className="open-sans">Decline  Application</h2>
          </div>
          <div
            className="print-div  cursor-pointer"
            // onClick={HandlePdf}
            onClick={() => getPrint()}
          >
            <img color="#004C1B" src={printer} alt="" />
          </div>
        </div>
      </div>
      <FilterPagination
        setItemPerPage={setItemPerPage}
        search={search}
        setSearch={setSearch}
        totalData={totalData}
      />
      {/* ----------------inprogress------------------- */}
      <div>
        <div style={{ marginTop: "16px", marginBottom: "48px" }}>
          <div className="progress-heady" style={{ overflowX: "auto" }}>
            <table class="table  application-table progress-table"   id="app-table">
              <thead style={{ borderRadius: "5px !important" }}>
                <tr
                  className="application-main-head progress-head"
                  // style={{ padding: ".5rem .75rem" }}
                >
                  <th
                    style={{ width: "135px" }}
                    className="head-data1 a_id custom-radius"
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        marginLeft: "3px",
                      }}
                    >
                      {" "}
                      Client ID & <br />
                      {"  "}{" "}
                      <span style={{ marginLeft: "5px" }}>{"  "} Ref No</span>
                    </span>{" "}
                    <svg
                      onClick={() => {
                        sortingData("client_id", SetClientIdSpan);
                      }}
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "14px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          clientIdSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>

                  <th
                    style={{ width: "130px", marginLeft: "2px" }}
                    className="head-data1"
                  >
                    <span style={{ fontWeight: "400" }}>Account Name</span>{" "}
                    <svg
                      onClick={() => sortingData("legal_name", SetLegalSpan)}
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "16px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          legalSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th
                    style={{ width: "141px", marginLeft: "3px" }}
                    className="head-data1"
                  >
                    <span style={{ fontWeight: "400" }}>Trading name</span>{" "}
                    <svg
                      onClick={() =>
                        sortingData("trading_name", SetTradingSpan)
                      }
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "16px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          tradingSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th style={{ width: "153px" }} className="head-data1">
                    <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                      MID ID
                    </span>{" "}
                    <svg
                      onClick={() => sortingData("min_id", SetMidSpan)}
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "14px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          midSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th style={{ width: "163px" }} className="head-data1">
                    <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                      MID Recived Date
                    </span>
                    <svg
                      // onClick={() => {
                      //   sortingData("client_id", SetClientIdSpan);
                      // }}
                      style={{
                        color: "#828282",
                        // marginLeft: "6px",
                        width: "15px",
                        height: "14px",
                        cursor: "pointer",
                        marginTop: "4px",
                        position: 'static !important',
                        transform: `${
                          clientIdSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th
                    style={{ width: "150px", marginLeft: "3px" }}
                    className="head-data1"
                  >
                    <span style={{ fontWeight: "400" }}>MID Opened Date</span>{" "}
                    <svg
                      // onClick={() => {
                      //   sortingData("client_id", SetClientIdSpan);
                      // }}
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "14px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          clientIdSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th
                    style={{ width: "150px", marginLeft: "3px" }}
                    className="head-data1"
                  >
                    <span style={{ fontWeight: "400" }}>Status</span>{" "}
                  </th>
                  <th style={{ width: "153px" }} className="head-data1">
                    <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                      Submitted On
                    </span>{" "}
                    <svg
                      onClick={() =>
                        sortingData("submitted_on", SetSubmittedSpan)
                      }
                      style={{
                        color: "#828282",
                        marginLeft: "9px",
                        width: "15px",
                        height: "16px",
                        cursor: "pointer",
                        marginTop: "4px",
                        transform: `${
                          submittedSpan ? "rotate(0deg)" : "rotate(180deg)"
                        }`,
                      }}
                      data-v-431cdece=""
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      role="img"
                      class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                      width="18"
                      aria-label="change column: 'Due Date' sorting"
                    >
                      <polygon
                        fill="var(--ci-primary-color, currentColor)"
                        points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                        class="ci-primary"
                      ></polygon>
                    </svg>
                  </th>
                  <th
                    style={{ width: "152px", textAlign: "center" }}
                    className="head-data1"
                  >
                    <div
                      className="d-flex"
                      style={{ alignItems: "center", marginLeft: "3px" }}
                    >
                      <div>
                        <span style={{ textAlign: "center" }}>Back </span>{" "}
                        <br />
                        <span>Office Stage</span>
                      </div>
                      {/* <span
                        style={{
                          color: "#828282",
                          marginLeft: "10px",
                          width: "10px",
                          height: "14px",
                          marginBottom: "8px",
                          cursor: "pointer",
                        }}
                      >
                        &#8593;
                      </span> */}
                    </div>
                  </th>

                  <th
                    style={{ width: "153px", textAlign: "center" }}
                    className="head-data1 "
                  >
                    <span style={{ fontWeight: "400" }}>Action</span>{" "}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr
                  style={{
                    background: "#D8E3E7",
                    textTransform: "capitalize",
                    borderBottom: "0.4px solid #BDBDBD",
                  }}
                  className="row-head"
                >
                  <th
                    style={{
                      borderRight: "0.4px solid #BDBDBD",
                      borderLeft: "0.4px solid #BDBDBD",
                    }}
                    className=" a_id"
                  >
                    <input
                      type="text"
                      style={{
                        width: "100px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="client_id_Input"
                      onChange={handleFilterInput}
                      value={query["client_id_Input"]}
                    />
                  </th>

                  <th
                    className=""
                    style={{ borderRight: "0.4px solid #BDBDBD" }}
                  >
                    <input
                      style={{
                        width: "104px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="legal_name_Input"
                      type="text"
                      onChange={handleFilterInput}
                      value={query["legal_name_Input"]}
                    />
                  </th>

                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      style={{
                        width: "118px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="trading_name_Input"
                      onChange={handleFilterInput}
                      type="text"
                      value={query["trading_name_Input"]}
                    />
                  </th>
                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      style={{
                        width: "118px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="boarding_id_Input"
                      onChange={handleFilterInput}
                      type="text"
                      value={query["boarding_id_Input"]}
                    />
                  </th>
                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      style={{
                        width: "118px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="trading_name_Input"
                      onChange={handleFilterInput}
                      type="text"
                      // value={query["trading_name_Input"]}
                    />
                  </th>
                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      style={{
                        width: "128px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      type="text"
                    />
                  </th>
                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      name="elavon_submission_status"
                      onChange={handleFilterInput}
                      type="text"
                      value={query["elavon_submission_status"]}
                      style={{
                        width: "128px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                    />
                  </th>
                  <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                    <input
                      style={{
                        width: "133px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      name="submitted_on"
                      onChange={handleFilterInput}
                      type="text"
                      value={query["submitted_on"]}
                    />
                  </th>
                  <th
                    style={{
                      width: "123px",
                      borderRight: "0.4px solid #BDBDBD",
                    }}
                    className=""
                  >
                    <input
                      style={{
                        width: "133px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      type="text"
                    />
                  </th>
                  <th
                    style={{
                      width: "132px",
                      borderRight: "0.4px solid #BDBDBD",
                    }}
                    className=""
                  >
                    <div className="w-100 d-flex justify-content-center">
                      <img
                        src={eye}
                        alt=""
                        style={{
                          width: "24px",
                          // margin: "0px 42px",
                          height: "18px",
                        }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              {totalData === 0 || displayData?.length === 0 ? (
                <tr>
                  <td style={{}} colspan="9">
                    <div className="not_found">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {displayData?.map((application, index) => (
                    <tr
                      className={` tr table-tr application-row`}
                      key={application?.id}
                      style={{
                        backgroundColor: `${
                          index % 2 === 0 ? "#EFEFF1" : "white"
                        }`,
                      }}
                    >
                      <th
                        className="a_id th border"
                        style={{ fontSize: "14px" }}
                      >
                        {application?.client_id}
                        <p style={{ fontSize: "12px", color: "gray" }}>
                          {application.application_reference}
                        </p>
                      </th>

                      <td className="th border">
                        {application?.legal_name?.length < 20
                          ? application?.legal_name
                          : `${application?.legal_name.slice(0, 20)}...`}
                      </td>
                      <td className="th border">
                        {application?.trading_name?.length < 20
                          ? application?.trading_name
                          : `${application?.trading_name.slice(0, 20)}...`}
                      </td>
                      <td className="th border">{application?.boarding_id}</td>
                      <td className="th border"></td>
                      <td className="th border"></td>
                      <td className="th border">
                        {application?.elavon_submission_status}
                      </td>
                      <td className="th border">
                        {application.submitted_on
                          ? application.submitted_on
                          : ""}
                      </td>
                      <td className="th border  text-center   border-[#BDBDBD] cursor-pointer">
                        <button
                           style={{minWidth:"120px",
                            backgroundColor: `${
                              application.buttonBg ? application.buttonBg : "#EF5350"
                            }`,
                            marginLeft: "-8px",
                          }}
                          className={` btn-big   text-center rounded-[4px] my-[3px]`}
                        >
                          {ApplicationStatus.map((option) => {
                            return (
                              <>
                                {option.value ===
                                  application.application_status && (
                                  <span key={option.value} value={option.value}>
                                    {option?.label}
                                  </span>
                                )}
                              </>
                            );
                          })}
                        </button>
                      </td>
                      <td
                        className="th border "
                        onClick={() => handleViewApplication(application)}
                      >
                        <div className="d-flex justify-content-center">
                          <Link to={"/decline"} className="route-link">
                            <FaEye
                              className="button-icon"
                              style={{
                                width: "12px",
                                height: "12px",
                                color: "black",
                              }}
                            />
                            <span
                              className="button-text"
                              style={{ marginLeft: "8px", color: "black" }}
                            >
                              view
                            </span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* -----------------pagination------------ */}
      <div className="row lead_pagination bg-transparent">
        <div className="col-12  d-flex justify-content-end pagination_container">
        <PageNumberCount currentPage={pageNumber} totalPages={pageCount} onPageChange={handleChangeCurrentPage}/>
          {/* <nav aria-label="...">
            <ul className="pagination pagination-sm">
              <li className="page-item ">
                {pageNumber < 2 ? (
                  <Link className="previous">
                    <MdOutlineArrowBackIos
                      style={{ width: "16px", height: "21px", color: "gray" }}
                    />
                  </Link>
                ) : (
                  <Link
                    onClick={handlePaginationPrevious}
                    className={` pagginationButtons `}
                    to="#"
                    tabindex="-1"
                  >
                    <MdOutlineArrowBackIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                )}
              </li>

              {[...Array(pageCount ? pageCount : 0)]?.map((elem, index) => (
                <li className="page-item">
                  <Link
                    onClick={() => handleChangeCurrentPage(index + 1)}
                    className={`${
                      index + 1 == pageNumber
                        ? "activess pagginationButtons"
                        : "pagginationButtons"
                    }`}
                    to="#"
                  >
                    <span>{index + 1}</span>
                  </Link>
                </li>
              ))}

              <li className="page-item">
                {nextUrl !== null ? (
                  <Link
                    onClick={handlePaginationNext}
                    className={`${
                      pageNumber < pageCount ? "pagginationButtons" : "previous"
                    } `}
                    to="#"
                  >
                    <MdArrowForwardIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                ) : (
                  <Link
                    // onClick={handlePaginationNext}
                    className={`${
                      pageNumber < pageCount ? "pagginationButtons" : "previous"
                    } `}
                    to="#"
                  >
                    <MdArrowForwardIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                )}
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
      {/* pdf */}
      <div style={{ display: "none" }}>
        <div className="progress-heady" style={{ overflowX: "auto" }}>
          <table
            class="table  application-table progress-table"
            id="content"
            ref={cRef}
          >
            <thead style={{ borderRadius: "5px !important" }}>
              <tr
                className="application-main-head progress-head"
                // style={{ padding: ".5rem .75rem" }}
              >
                <th
                  style={{ width: "100px" }}
                  className="head-data1 a_id custom-radius"
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginLeft: "3px",
                    }}
                  >
                    {" "}
                    Client ID & <br />
                    {"  "}{" "}
                    <span style={{ marginLeft: "5px" }}>{"  "} Ref No</span>
                  </span>{" "}
                </th>

                <th
                  style={{ width: "130px", marginLeft: "2px" }}
                  className="head-data1"
                >
                  <span style={{ fontWeight: "400" }}>Account Name</span>{" "}
                </th>
                <th
                  style={{ maxWidth: "120px", marginLeft: "3px" }}
                  className="head-data1"
                >
                  <span style={{ fontWeight: "400" }}>Trading name</span>{" "}
                </th>
                <th style={{ width: "153px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    MID ID
                  </span>{" "}
                </th>
                {/* <th style={{ width: "153px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    MID Opened Date
                  </span>{" "}
                </th>
                <th
                  style={{ width: "150px", marginLeft: "3px" }}
                  className="head-data1"
                >
                  <span style={{ fontWeight: "400" }}>MID Recived Date</span>{" "}
                </th> */}
                <th
                  style={{ width: "150px", marginLeft: "3px" }}
                  className="head-data1"
                >
                  <span style={{ fontWeight: "400" }}>Status</span>{" "}
                </th>
                <th style={{ width: "153px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    Submitted On
                  </span>{" "}
                </th>
                <th
                  style={{ width: "152px", textAlign: "center" }}
                  className="head-data1"
                >
                  <div
                    className="d-flex"
                    style={{ alignItems: "center", marginLeft: "3px" }}
                  >
                    <div>
                      <span style={{ textAlign: "center" }}>Back </span> <br />
                      <span>Office Stage</span>
                    </div>
                    {/* <span
                        style={{
                          color: "#828282",
                          marginLeft: "10px",
                          width: "10px",
                          height: "14px",
                          marginBottom: "8px",
                          cursor: "pointer",
                        }}
                      >
                        &#8593;
                      </span> */}
                  </div>
                </th>
              </tr>
            </thead>

            {pdfData?.length === 0 ? (
              <tr>
                <td style={{}} colspan="9">
                  <div className="not_found">
                    <h4 className="my-4">No Data Found</h4>
                  </div>
                </td>
              </tr>
            ) : (
              <tbody>
                {pdfData?.map((application, index) => (
                  <tr
                    className={` tr table-tr application-row`}
                    key={application?.id}
                    style={{
                      backgroundColor: `${
                        index % 2 === 0 ? "#EFEFF1" : "white"
                      }`,
                    }}
                  >
                    <th className="a_id th border" style={{ fontSize: "14px" }}>
                      {application?.client_id}
                      <p style={{ fontSize: "12px", color: "gray" }}>
                        {application.application_reference}
                      </p>
                    </th>

                    <td className="th border">
                      {/* {application?.legal_name?.length < 20
                        ? application?.legal_name
                        : `${application?.legal_name.slice(0, 20)}...`} */}
                      {application?.legal_name}
                    </td>
                    <td className="th border">
                      {application?.trading_name?.length < 20
                        ? application?.trading_name
                        : `${application?.trading_name.slice(0, 20)}...`}
                    </td>
                    <td className="th border">{application?.boarding_id}</td>
                    {/* <td className="th border"></td>
                    <td className="th border"></td> */}
                    <td className="th border">
                      {application?.elavon_submission_status}
                    </td>
                    <td className="th border">
                      {application.submitted_on ? application.submitted_on : ""}
                    </td>
                    <td className="th border  text-center   border-[#BDBDBD] cursor-pointer">
                      <button
                       style={{
                        backgroundColor: `${
                          application.buttonBg ? application.buttonBg : "#EF5350"
                        }`,
                        marginLeft: "-8px",
                      }}
                      className={` btn-big   text-center rounded-[4px] my-[3px]`}
                      >
                        {ApplicationStatus.map((option) => {
                          return (
                            <>
                              {option.value ===
                                application.application_status && (
                                <span key={option.value} value={option.value}>
                                  {option?.label}
                                </span>
                              )}
                            </>
                          );
                        })}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default DechlineApplicationList;
