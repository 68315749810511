import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Inprogress from "../InProgress/Inprogress";
import NewApplicationPage from "../NewApplication/NewApplicationPage";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const Home = () => {
  const [isLoading, setIsloading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  // if (isLoading) {
  //   return (
  //     <Backdrop
  //       open
  //       sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //     >
  //       <CircularProgress color="inherit" />
  //     </Backdrop>
  //   );
  // }
  return (
    <div>
      
      <div>
        <div className="breadcump">
        <p>
          {" "}
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            {" "}
            Home /
          </span>
          <span style={{ cursor: "not" }}> Application Table</span>
        </p>
      </div>
      <div className="px-1 px-md-4 box-wrapper2">
        {/* style={{overflowX:'hidden'}} */}
        {/* <NewApplication/> */}
        <NewApplicationPage  isLoading={isLoading} setIsloading={setIsloading} />
        <Inprogress isLoading={isLoading} setIsloading={setIsloading}   />
        {/* isLoading={isLoading} setIsloading={setIsloading} */}
      </div>
      </div>
      
    </div>
  );
};

export default Home;
