import * as Types from "../types/Types";

const initialState = {
 

  imageInput: {
    document: "",
    doc_type: 0,
    owner: "",
  },
  imageInputArray: [
    {
      document: "",
      doc_type: 0,
      owner: "",
    },
  ],
  docInput: {
    doc_url: "",
    created_at: "",
    updated_at: "",
    category: "PROOF_OF_ID",
    doc_type: "PASSPORT",
    issue_date: null,
    expiry_date: null,
    quote: "",
    doc: "",
    country: "",
  },

  productInput: {
    connection_type: "",
    product_term: 18,
    terminal_model: "",
    provider: "1",
    product: "",
    application: "",
    price: "",
    is_deleted: false,
  },

  quote_products: [
    {
      connection_type: "",
      product_term: 18,
      terminal_model: "",
      provider: "1",
      product: "",
      application: "",
      price: "",
      qty: 1,
      is_deleted: false,
    },
  ],

  ownerList: null,
  docList: null,
  isLoadPriceQuote: false,
  isSuccessImage: false,
  IsLoading: true,
  Doc_list: [],
  post_Doc: [
    {
      id: "",
      doc_url: "",
      category: "PROOF_OF_ID",
      doc_type: "",
      issue_date: null,
      expiry_date: null,
      quote: "",
      doc: "",
      issuer_id: "",
      country: 74,
      document_id: "",
      sur_name: "",
      first_name: "",
    },
  ],
  error: null,
  afterSuccessOwner: false,
  isQuoteOwnerDeleted: false,
  isSuccessDoc: false,
  isSuccessProduct: false,
  postCodeList:[],
  addressDetails:null
};

const PriceQuoteReducer = (state = initialState, action) => {
  const newState = { ...state };
  //Leads
  switch (action.type) {
  

    case Types.IS_LOAD_PRICE_QUOTE:
      return {
        ...state,
        isLoadPriceQuote: action.payload,
      };

    // ---------------delete-------------
    case Types.IS_QUOTE_OWNER_DELETED:
      return {
        ...state,
        isQuoteOwnerDeleted: action.payload,
      };

   

    case Types.GET_IMAGE_INPUT:
      const imageInput = { ...state.imageInput };
      imageInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        imageInput: imageInput,
      };
    case Types.IS_SUCCESS_IMAGE:
      return {
        ...state,
        isSuccessImage: action.payload,
        imageInput: initialState.imageInput,
      };

    case Types.ADD_NEW_IMAGE:
      // const setNewDocInput = [ ...state.imageInputArray ];
      const imageInputArray = [...state.imageInputArray];
      imageInputArray[action.payload.index] = {
        ...state.imageInputArray[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        imageInputArray: [...imageInputArray],
      };

    case Types.SET_NEW_IMAGE_INPUT:
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newImageInput = [...state.imageInputArray];

      return {
        ...state,

        imageInputArray: [
          ...newImageInput,
          {
            document: "",
            doc_type: 0,
            owner: userData.id,
          },
        ],
        // },
      };
    case Types.DELETE_IMAGE:
      const removeImageInput = { ...state.imageInputArray };
      const quote_image = state.imageInputArray.filter((item, index) => {
        return index != action.payload;
      });

      return {
        ...state,
        ...removeImageInput,
        imageInputArray: [...quote_image],
      };

    // ---------------doc start------------------
    case Types.GET_DOC_INPUT:
      const docInput = [...state.docInput];
      docInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        docInput: docInput,
      };

    case Types.GET_DOC_LIST:
      return {
        ...state,
        docList: action.payload,
      };

    case Types.ERROR_DOC:
      return {
        ...state,
        error: action.payload,
      };

    case Types.ADD_NEW_DOC:
      const post_Doc = [...state.post_Doc];
      post_Doc[action.payload.index] = {
        ...state.post_Doc[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        post_Doc: [...post_Doc],
      };

    case Types.SET_NEW_DOC_INPUT:
      const newDocInput = [...state.post_Doc];

      return {
        ...state,

        post_Doc: [
          ...newDocInput,
          {
            doc_url: "",

            category: "PROOF_OF_ID",
            doc_type: "",
            issue_date: null,
            expiry_date: null,
            quote: "",
            doc: "",
            country: 74,
            file_url: "",
            issuer_id: "",
            document_id: "",
            sur_name: "",
            first_name: "",
          },
        ],
        // },
      };

    case Types.DELETE_DOC:
      const removeDocInput = { ...state.post_Doc };
      const quote_doc = state.post_Doc.filter((item, index) => {
        return index != action.payload;
      });

      return {
        ...state,
        ...removeDocInput,
        post_Doc: [...quote_doc],
      };
    case Types.SUCCESS_DOC:
      return {
        ...state,
        // isSuccessDoc: action.payload,
        post_Doc: initialState.post_Doc,
      };

    // ---doc end------------
    // case Types. DELETE_DOC :
    // --------------owner
    case Types.GET_OWNER_INPUT:
      const ownerInput = { ...state.ownerInput };
      ownerInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        ownerInput: ownerInput,
      };
    case Types.GET_OWNER_LIST:
      return {
        ...state,
        ownerList: action.payload,
      };
    case Types.AFTER_SUCCESS_OWNER:
      return {
        ...state,
        afterSuccessOwner: action.payload,
      };
    case Types.SET_OWNER_FALSE:
      return {
        ...state,
        afterSuccessOwner: action.payload,
        ownerInput: initialState.ownerInput,
      };
    // ----------------product---------------
    case Types.GET_PRODUCT_INPUT:
      const productInput = [...state.productInput];
      productInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        productInput: productInput,
      };
    case Types.GET_QUOTE_PRODUCT:
      const setNewProduct = [...state.quote_products];
      const quote_products = [...state.quote_products];
      quote_products[action.payload.index] = {
        ...state.quote_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        quote_products: [...quote_products],
      };

    case Types.SET_QUOTE_PRODUCT:
      const newproductInput = [...state.quote_products];

      return {
        ...state,

        quote_products: [
          ...newproductInput,
          {
            connection_type: "",
            product_term: 18,
            terminal_model: "",
            provider: "1",
            product: "",
            application: "",
            price: "",
            qty: 1,
            is_deleted: false,
          },
        ],
        // },
      };

    case Types.REMOVE_QUOTE_PRODUCT:
      const remoProductInput = { ...state.quote_products };
      const price_quote_product = state.quote_products.filter((item, index) => {
        return index != action.payload;
      });

      return {
        ...state,
        ...remoProductInput,
        quote_products: [...price_quote_product],
      };
    case Types.SUCCESS_QUOTE_PRODUCT:
      return {
        ...state,
        isSuccessProduct: action.payload,
        quote_products: initialState.quote_products,
      };

    // ---doc end------------

    // ===post code start==================
    case Types.GET_POST_CODE_LIST:
      return {
        ...state,
        postCodeList: action.payload,
      };
    case Types.GET_POST_CODE_ADDRESS:
      return {
        ...state,
        addressDetails: action.payload,
      };
    default:
      break;
  }
  return newState;
};

// ------------------------------product------------------------

const productInitial = {
  IsLoading: true,
  product_list: [],
  post_data: [],
  error: null,
  afterSuccessProduct: false,
};

export const productReducer = (state = productInitial, action) => {
  switch (action.type) {
    case Types.IS_LOADING_PRODUCT:
      return {
        ...state,
        IsLoading: action.payload,
      };
    case Types.SUCCESS_PRODUCT:
      return {
        ...state,
        product_list: action.payload,
      };
    case Types.ADD_NEW_PRODUCT:
      return {
        ...state,
        post_data: [...state.post_data, action.payload],
      };
    // case Types.AFTER_SUCCESS_PRODUCT:
    //   return {
    //     ...state,
    //     afterSuccessProduct: action.payload,
    //     post_data: productInitial.post_data,
    //   };
    case Types.DELETE_PRODUCT:
      return {
        ...state,
        post_data: state.post_data.filter(
          (curr) => curr.uid !== action.payload
        ),
      };

    case Types.UPDATE_PRODUCT:
      return {
        ...state,
        post_data: action.payload,
      };
    case Types.ERROR_PRODUCT:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PriceQuoteReducer;

// ------------------------------Terminal------------------------

// const TerminalInitial = {
//   terminal: [],
// };

// export const TerminalStateReducer = (state = TerminalInitial, action) => {
//   switch (action.type) {
//     case Types.TERMINAL_ADD:
//       return {
//         ...state,
//         terminal: [...state.terminal, action.payload],
//       };

//     case Types.SET_TERMINAL:
//       return {
//         ...state,
//         terminal: action.payload,
//       };

//     case Types.TERMINAL_DELETE:
//       return {
//         ...state,
//         // post_data: state.terminal.filter(
//         //   (curr) => curr.uid !== action.payload
//         // ),
//         terminal: state.terminal.filter(
//           (item) => item.Unique_id !== action.payload
//         ),
//       };
//     default:
//       return state;
//   }
// };
