import React, { useEffect,useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import "./Leads.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { BASE_URL } from "../../../../../../Const";
// import {
//   GetPaginationData,
//   setpaginationName,
//   PaginationUrl,
//   SetPricequoteUpdatedData,
//   GetLeadsnput,
//   SetLeadsStatusFalse,
//   SetQuoteStatusFalse,
// } from "../../../mainLead/_redux/action/LeadsAction";

import ErrorOffsetPagination from "./ErrorOffsetPagination";
// import LoadingCheck from "../../../mainLead/components/commons/LoadingCheck";
import LoadingCheck from "../../../../mainLead/components/commons/LoadingCheck";
import SvgIcon from "../../../../mainLead/components/commons/SvgIcon";
import { LeadStatus } from "../../../../mainLead/components/commons/LeadStatus";
import {
  handleDescendingSort,
  SortArrayOfObjects,
} from "../../../../mainLead/components/commons/Utils";
import {
  GetAplicationDetails,
  SetApplicationDetails,
  SetSigningStatusFalse,
  SetSigningStatusFalseTwo,
} from "../../../../mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { FaEye } from "react-icons/fa";
import {
  GetLeadsnput,
  SetLeadsStatusFalse,
  setpaginationName,
  SetQuoteStatusFalse,
  PaginationUrl,
  GetPaginationData,
} from "../../../../mainLead/_redux/action/LeadsAction";
import {
  applicationStage,
  ApplicationStatus,
} from "../../../../mainLead/components/commons/Dropdown";
// import { PaginationUrl } from "../../../../mainLead/components/newApplication/_redux/action/NewapplicationAction";

const TableData = ({
  data,
  check,
  client_id,
  application_reference,
  account_name,
  trading_name,
  status,
  stage,
  create_on,
  notes,
  btn_class_name,
}) => {
  const [is_checked, set_is_checked] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const afterSuccessSigning = useSelector(
    (state) => state.applicationInfo.afterSuccessSigning
  );
  const afterSuccessSigningTwo = useSelector(
    (state) => state.applicationInfo.afterSuccessSigningTwo
  );

  useEffect(() => {
    set_is_checked(check);
  }, [check]);

  const handleApplicationView = (data) => {
    dispatch(GetAplicationDetails(data));

    dispatch(GetLeadsnput("step", 3));

    history.push(`/errored_application_edit/${data}`);
  };
  useEffect(() => {
    return () => {
      dispatch(SetQuoteStatusFalse());
      dispatch(SetLeadsStatusFalse());
    };
  }, []);
  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);

  useEffect(() => {
    if (afterSuccessSigning) {
      dispatch(SetSigningStatusFalse());
    }
  }, [afterSuccessSigning]);

  useEffect(() => {
    if (afterSuccessSigningTwo) {
      dispatch(SetSigningStatusFalseTwo());
    }
  }, [afterSuccessSigningTwo]);
  return (
    <>
      <tr className="lead_border">
        <td class="tbody_checkbox ">
          <div class="d-flex justify-content-around align-items-center">
            <input
              className="lead_checkbox"
              onClick={(e) => set_is_checked(!is_checked)}
              checked={is_checked}
              type="checkbox"
              aria-label=""
            />
            <span style={{ visibility: "hidden" }}>#</span>
          </div>
        </td>
        <td>
          <div
            className="lead_id_td"
            style={{ display: "contents", height: 0 }}
          >
            <p className="lead_table_data_p">{client_id}</p>
            <p
              className="lead_table_data_p"
              style={{ fontSize: "12px", color: "darkblue" }}
            >
              Ref. Id: {application_reference}
            </p>
          </div>
        </td>

        <td>
          <p className="lead_table_data_p">{account_name}</p>
        </td>
        <td>
          <p className="lead_table_data_p">{trading_name}</p>
        </td>

        <td className="status_btn_container">
          <div className=" d-flex justify-content-center align-items-center">
            <button className="lead_status_btn lead_status_btn_success" style={{ background: "#F5B041", color: "white" }}>
              <span className="lead_status_text lead_status_text_success" style={{  color: "white" }}>
                {status}
              </span>
            </button>
          </div>
        </td>
        <td className="status_btn_container">
          <div className=" d-flex justify-content-center align-items-center">
            <button
              className={`lead_status_btn lead_status_btn_${btn_class_name}`}
              style={{
                background: "#e93c2be8",
                color: "white",
                minWidth: "190px",
              }}
            >
              <span
                className={`lead_status_text lead_status_text_${btn_class_name}`}
              >
                {stage}
              </span>
            </button>
          </div>
        </td>

        <td>
          <p className="lead_table_data_p">{create_on}</p>
        </td>

        {/* <td>
          <p className="lead_table_data_p">{notes}</p>
        </td> */}

        <td onClick={() => handleApplicationView(data)}>
          <div className="d-flex justify-content-center">
            <Link to="#" className="view_a">
              Review
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};
const ErrorApplicationList = () => {
  const [SortingData, setSortingData] = useState({
    ascending: -1,
    sort_by: "",
  });

  const [search, setSearch] = useState("");
  const history = useHistory();
  const [is_all_checked, set_is_all_checked] = useState(0);
  const [FilterInputs, setFilterInputs] = useState({
    lead_id_input: "",
    account_name_input: "",
    trading_name_input: "",
    status_input: "",
    created_on_input: "",
    lead_notes_input: "",
  });

  const handleFilterInput = (e) => {
    const FilterInputName = e.target.name;
    const FilterInputval = e.target.value;
    setFilterInputs((previous_values) => {
      return { ...previous_values, [FilterInputName]: FilterInputval };
    });
  };

  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dispatch = useDispatch();

  // --------------api call-----------------
  const paginationName = "applicationList";
  const mid_url = `api/v1/applications/application/?applicaiton_stage=8`;
  const applicationList = useSelector((state) =>
    state.Pagination.data[paginationName].results != null
      ? state.Pagination.data[paginationName].results
      : []
  );
  const leadsList_url = useSelector((state) =>
    state.Pagination.url[paginationName] !== ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const is_loading = useSelector(
    (state) => state.Pagination.IsLoading[paginationName]
  );
  // const is_loading = useSelector((state) => (state.Pagination.IsLoading != null ? state.IsLoading : true));
  const URL = useSelector((state) =>
    state.Pagination.url[paginationName] != ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const isLoadLeads = useSelector(
    (state) => state.Pagination.isLoadLeads[paginationName]
  );
  const limit = useSelector((state) => state.Pagination.limit[paginationName]);


  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(SetLeadsStatusFalse());
    };
  }, []);

  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);
  // --------------------------export pdf-----------------------
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#leads-table", theme: "grid" });
    doc.text("Error application table", 20, 10);

    doc.save("Error application table.pdf");
  };
 // -print pdf------------
 const cRef = useRef();
 const HandlePrint = useReactToPrint({
   content: () => cRef.current,
   documentTitle: "Error Application List",
   bodyClass: "dis",
 });
 const getPrint = () => {
   HandlePrint();
 };
 const newFilterList = applicationList?.map((item) => ({
  ...item,
  client_id: item?.client_id === null ? "N/A" : item?.client_id,
}));
  const FilteredLeadList =
  newFilterList !== null && newFilterList.length > 0
      ? SortArrayOfObjects(
        newFilterList,
          SortingData.ascending,
          SortingData.sort_by
        )
          .filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val?.client_id &&
              val.client_id
                ?.toString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return val;
            } else if (
              val.application_reference &&
              val.application_reference
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return val;
            } else if (
              val.legal_name &&
              val.legal_name.toLowerCase().includes(search.toLowerCase())
            ) {
              return val;
            } else if (
              val.trading_name &&
              val.trading_name.toLowerCase().includes(search.toLowerCase())
            ) {
              return val;
            }
          })
          .filter((current) => {
            if (
              current?.client_id !== null &&
              current.client_id
                ?.toString()
                .toLowerCase()
                .includes(FilterInputs.lead_id_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.legal_name === null
                ? ""
                    .toString()
                    .toLowerCase()
                    .includes(FilterInputs.account_name_input.toLowerCase())
                : current.legal_name
                    .toString()
                    .toLowerCase()
                    .includes(FilterInputs.account_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.trading_name
                .toLowerCase()
                .includes(FilterInputs.trading_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.note
                .toLowerCase()
                .includes(FilterInputs.lead_notes_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            const status =
              LeadStatus[current.bank_account_type] === undefined
                ? ""
                : LeadStatus[current.bank_account_type].toLowerCase();
            if (status.includes(FilterInputs.status_input.toLowerCase())) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.legal_name
                .toLowerCase()
                .includes(FilterInputs.account_name_input.toLowerCase())
            ) {
              return current;
            }
          })

          .filter((current) => {
            const converted_date = new Date(current.created_at)
              .toLocaleDateString("en-US", options)
              .toLowerCase();
            if (FilterInputs.created_on_input === "") {
              return current;
            } else if (
              converted_date.includes(
                FilterInputs.created_on_input.toLowerCase()
              )
            ) {
              return current;
            }
          })
      : [];

  return (
    <>
      <div className="custom_breadcrumb">
        <p>
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            Home /
          </span>
          <span style={{ cursor: "not" }}> Errored Application</span>
        </p>
      </div>

      <div className="padding_lead box-wrapper2">
        <div className="row ">
          <p className="leads_heading">Errored Application Table</p>
          <div className="col-lg-6  col-sm-4 col-md-6 mb-3 ">
            <div className="form-group form-group-lead ">
              <div className="position-relative">
                <input
                  type="text"
                  className=""
                  id="lead_search"
                  placeholder="Search.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.8487 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.8487 16 8 16C3.58172 16 0 12.4183 0 8Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="export_add_container col-lg-6 c0l-sm-6 col-md-6 d-flex mb-3">
          <button className="btn_export" 
            // onClick={HandlePdf}
            onClick={() => getPrint()}
            >
              Export
            </button>
            {/* <button
              className="btn_price_quote d-flex justify-content-evenly align-items-center "
              onClick={() => {
                history.push(`/addlead`);
                dispatch(GetLeadsnput("step", 3));
              }}
            >
              <span className="me-2">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 0C8.5523 0 9 0.44772 9 1V7H15C15.5523 7 16 7.4477 16 8C16 8.5523 15.5523 9 15 9H9V15C9 15.5523 8.5523 16 8 16C7.4477 16 7 15.5523 7 15V9H1C0.44772 9 0 8.5523 0 8C0 7.4477 0.44772 7 1 7H7V1C7 0.44772 7.4477 0 8 0Z"
                    fill="white"
                  />
                </svg>
              </span>
              New Application
            </button> */}
          </div>

          <div className="lead_table_container table-responsive ">
            <table ref={cRef}
              className={`lead_table newApplicationTable_00 ${""}`}
              id="leads-table"
            >
              <thead className="lead_thead lead_thead1">
                <tr className="application-main-head">
                  <th className="checkBox_size">
                    <div className="head_data_container d-flex  ">
                      <input
                        className="lead_checkbox "
                        type="checkbox"
                        aria-label=""
                        checked={is_all_checked}
                        onChange={(e) => {
                          set_is_all_checked(!is_all_checked);
                        }}
                      />
                      <span className="spanHash">#</span>
                    </div>
                  </th>
                  <th className="lead_id_size">
                    <div className="lead_id_text head_data_container">
                      <span className="header_text_margin">Client ID</span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="client_id"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="account_name_size">
                    <div className="head_data_container account_name_text">
                      <span className="header_text_margin">Account Name</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="legal_name"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className=" trading_name_size ">
                    <div className="head_data_container trading_name_text">
                      <span className="header_text_margin">Trading Name</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="trading_name"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="status_size">
                    <div className="head_data_container  status_text">
                      <span>Status</span>
                    </div>
                  </th>
                  <th className="stage_size">
                    <div className="head_data_container  status_text">
                      <span>Stage</span>
                    </div>
                  </th>
                  <th className="create_on_size">
                    <div className="head_data_container">
                      <span className="header_text_margin">Create On</span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="created_at"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  {/* <th className=" notes_size ">
                    <div className="head_data_container trading_name_text">
                      <span className="header_text_margin">Notes</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="note"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th> */}
                  <th className="new_application_action_size">
                    <div className="application_action_div">
                      <span class="span_text">Action</span>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* 2nd thead */}
              <thead className="lead_thead ">
                <tr className="">
                  <th className="lead_border_without_right"></th>
                  <th className="lead_id_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="lead_id_input"
                      name="lead_id_input"
                    />
                  </th>
                  <th className="account_name_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="account_name_input"
                      name="account_name_input"
                    />
                  </th>

                  <th className="trading_name_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="trading_name_input"
                      name="trading_name_input"
                    />
                  </th>
                  <th className="status_size lead_border_bottom m-auto">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="status_input_for_application "
                      name="status_input"
                      style={{ margin: "auto!important" }}
                    />
                  </th>

                  <th className="status_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="status_input_for_application stage_input"
                      name="stage_input"
                      style={{ margin: "auto 18px!important" }}
                    />
                  </th>
                  <th className="create_on_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="create_on_input"
                      name="created_on_input"
                    />
                  </th>

                  {/* <th className="note_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="lead_notes_input"
                      name="lead_notes_input"
                    />
                  </th> */}

                  <th className="lead_border_without_left"></th>
                </tr>
              </thead>

              <tbody className="">
                <LoadingCheck
                  isLoadLeads={isLoadLeads}
                  is_loading={is_loading}
                  FilteredLeadList={FilteredLeadList}
                />

                {!is_loading &&
                  FilteredLeadList.length > 0 &&
                  FilteredLeadList.map((current, index) => {
                    return (
                      <TableData
                        search={search}
                        isLoadLeads={isLoadLeads}
                        applicationList={applicationList}
                        item={current}
                        options={options}
                        key={current.id}
                        check={is_all_checked}
                        client_id={current?.client_id}
                        application_reference={current.application_reference}
                        account_name={current.legal_name}
                        trading_name={current.trading_name}
                        status={ApplicationStatus.map((option) => {
                          return (
                            <>
                              {option.value === current.application_status && (
                                <span key={option.value} value={option.value}>
                                  {option?.label}
                                </span>
                              )}
                            </>
                          );
                        })}
                        stage={applicationStage.map((option) => {
                          return (
                            <>
                              {option.value === current.applicaiton_stage && (
                                <span key={option.value} value={option.value}>
                                  {option?.label}
                                </span>
                              )}
                            </>
                          );
                        })}
                        // stage={current.applicaiton_stage === 8 && <span>SUBMISSION_ERRORED</span>}
                        create_on={`${new Date(current?.created_at).getDate()}/${new Date(current?.created_at).getMonth() + 1}/${new Date(current?.created_at).getFullYear()} at ${new Date(current?.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                        // notes={current.note}
                        btn_class_name={current.btn_class_name}
                        data={current.id}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <ErrorOffsetPagination
          PaginationName={paginationName}
          URL_MID={mid_url}
          search={search}
        />
      </div>
    </>
  );
};

export default ErrorApplicationList;
