import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  GetCountryList,
  GetIndustryList,
  GetLeadsnput,
  GetPriceQuoteDetails,
  GetPricequoteInput,
  GetSalesPartnerList,
  GetSourceList,
} from "../../_redux/action/LeadsAction";
import Number from "./Number";
import {
  GetCompanyHouseDetails,
  GetCompanyHouseList,
  GetCompanyOfficersDetails,
} from "../newApplication/_redux/action/NewapplicationAction";
import { opportunityStage, Opportunity_Status } from "../commons/Dropdown";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { GetUserList } from "../../../../userManagement/_redux/action/UserAction";
import { BASE_URL } from "../../../../../Const";
const Summary = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const leadSource = (
    <p>
      Lead Source <span className="required">*</span>
    </p>
  );
  const legal_type = (
    <p>
      Legal Type <span className="required">*</span>
    </p>
  );
  const OpportunityStatus = (
    <p>
      Opportunity Status <span className="required">*</span>
    </p>
  );
  const OpportunityStage = (
    <p>
      Opportunity Stage <span className="required">*</span>
    </p>
  );
  const FirstName = (
    <p>
      First Name <span className="required">*</span>
    </p>
  );
  const PrimaryEmail = (
    <p>
      Primary Email <span className="required">*</span>
    </p>
  );
  const LegalName = (
    <p>
      Legal Name<span className="required">*</span>
    </p>
  );
  const TradingName = (
    <p>
      Trading Name<span className="required">*</span>
    </p>
  );

  const LastName = (
    <p>
      Last Name<span className="required">*</span>
    </p>
  );
  const industry_type = (
    <p>
      Industry Type<span className="required">*</span>
    </p>
  );
  const company_house_no = (
    <p>
      Company House No<span className="required">*</span>
    </p>
  );

  const LegalType = [
    {
      value: "ST",
      label: "Sole Trader",
    },
    {
      value: "CL",
      label: "Club",
    },

    {
      value: "CH",
      label: "Charity",
    },
    {
      value: "TR",
      label: "Trust",
    },
    {
      value: "PART",
      label: "Partnership",
    },
    {
      value: "LLP",
      label: "Limited Liability Partnership",
    },
    {
      value: "L",
      label: "Private Limited Company",
    },
    {
      value: "PL",
      label: "Public Limited Company",
    },
    {
      value: "OT",
      label: "Other",
    },
  ];

  const UserList = useSelector(
    (state) => state.userInfo.UserList
  );
  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const leadQualify = useSelector((state) => state.leadInfo.leadQualify);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const companyHouseList = useSelector(
    (state) => state.applicationInfo.companyHouseList
  );

  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  const salesPartnerList = useSelector(
    (state) => state.leadInfo.salesPartnerList
  );

  let leadId = localStorage.getItem("leadId");
  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
    if (name === "trading_name") {
      dispatch(GetPricequoteInput("company_name", value));
    }
    if (value === "CH" && name === "legal_type") {
      dispatch(GetPricequoteInput("company_house_no", ""));
      dispatch(GetPricequoteInput("incorporated_on", ""));
      dispatch(GetPricequoteInput("legal_address1", ""));
      dispatch(GetPricequoteInput("legal_city", ""));
      dispatch(GetPricequoteInput("legal_postcode", ""));
    }
    if (value === "ST" && name === "legal_type") {
      dispatch(GetPricequoteInput("company_house_no", ""));
      dispatch(GetPricequoteInput("incorporated_on", ""));
      dispatch(GetPricequoteInput("legal_address1", ""));
      dispatch(GetPricequoteInput("legal_city", ""));
      dispatch(GetPricequoteInput("legal_postcode", ""));
    } else if (
      priceQuoteInput.legal_type === "L" ||
      priceQuoteInput.legal_type === "PL" ||
      priceQuoteInput.legal_type === "CLLPH"
    ) {
      dispatch(GetPricequoteInput("charity_number", ""));
    }
  };

  useEffect(() => {
    dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/?role=2`));
  
    dispatch(GetSourceList(1));
    dispatch(GetSalesPartnerList(1));
    dispatch(GetIndustryList(1));
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", true));
    dispatch(GetLeadsnput("active_application", false));
  }, []);

  const getCountryIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === priceQuoteInput.mobile_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getCountryTelephoneIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === priceQuoteInput.phone_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };
  const getIndustryIndex = () => {
    var index = -1;
    IndustryList.map((opt) => {
      if (opt.id === priceQuoteInput.industry_type) {
        index = IndustryList.indexOf(opt);
      }
    });
    return index;
  };
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <div className="form-wrapper text-capitalize">
      {isLoadLeads && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Summary</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Client ID"
                variant="outlined"
                size="small"
                name="client_id"
                InputLabelProps={{ shrink: true }}
                value={priceQuoteInput?.client_id || ""}
              />
            </div>
            {!isLoadLeads &&
              sourceList &&
              sourceList !== null &&
              sourceList.length > 0 ? (
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={leadSource}
                    variant="outlined"
                    name="lead_source"
                    value={priceQuoteInput.lead_source}
                    onChange={(e) => {
                      handleChangeInput("lead_source", e.target.value);
                    }}
                  >
                    {sourceList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) :
                <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={leadSource}
                variant="outlined"
                name="lead_source"
                value={priceQuoteInput.lead_source}
                onChange={(e) => {
                  handleChangeInput("lead_source", e.target.value);
                }}
              >
                {sourceList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>}

            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={OpportunityStage}
                name="opportunity_stage"
                value={priceQuoteInput.opportunity_stage}
                onChange={(e) =>
                  handleChangeInput("opportunity_stage", e.target.value)
                }
              >
                {opportunityStage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <Autocomplete
                id="country-select-demo"
                size="small"
                options={companyHouseList}
                inputValue={priceQuoteInput.legal_name}
                autoHighlight
                onChange={(event, newValue) => {
                  handleChangeInput("legal_name", newValue === null ? "" : newValue.title);
                  
                  dispatch(GetCompanyHouseDetails(newValue === null ? "" : newValue.title));
                  // dispatch(GetCompanyOfficersDetails(newValue.company_number));
                }}
                
                // value={
                //   companyHouseList?.[leadInput.legal_name] || null
                // }
                getOptionLabel={(option) => option.title}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={LegalName}
                    error={
                      priceQuoteInput?.legal_name?.includes("'") ? true : false
                    }
                    onChange={(e) => {
                      handleChangeInput("legal_name", e.target.value);
                      dispatch(GetCompanyHouseDetails(e.target.value));
                    }}
                    value={
                      companyHouseList?.[priceQuoteInput.legal_name] || null
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </div>

            {!isLoadLeads &&
              IndustryList &&
              IndustryList !== null &&
              IndustryList.length > 0 && (
                <div className="mb-form" key={"0000000000"}>
                  {/* <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={industry_type}
                    name="industry_type"
                    value={priceQuoteInput.industry_type}
                    onChange={(e) => {
                      IndustryList.map((item) => {
                        if (item.id === e.target.value) {
                          dispatch(
                            GetPricequoteInput("mcc_code", item.mcc_iso)
                          );
                        }
                      });

                      handleChangeInput("industry_type", e.target.value);
                    }}
                  >
                    {IndustryList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <Autocomplete
                    id="country-select-demo"
                  size="small"
                    options={IndustryList}
                    autoHighlight
                    getOptionLabel={(option) => option.description}
                    value={IndustryList?.[getIndustryIndex()] || null}
               
                    onChange={(event, newValue) =>
                     {
                     
                      IndustryList.map((item) => {
                        if (item.id === newValue.id) {
                          dispatch(
                            GetPricequoteInput("mcc_code", item.mcc_iso)
                          );
                        }
                      });
                       handleChangeInput("industry_type", newValue.id)}
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Industry"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="In Corporated On"
                variant="outlined"
                size="small"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="incorporated_on"
                value={priceQuoteInput.incorporated_on}
                onChange={(e) =>
                  handleChangeInput("incorporated_on", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Current Ownership scince"
                variant="outlined"
                size="small"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="current_ownership_since"
                value={priceQuoteInput.current_ownership_since}
                onChange={(e) =>
                  handleChangeInput("current_ownership_since", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={FirstName}
                variant="outlined"
                size="small"
                name="first_name"
                value={priceQuoteInput.first_name}
                onChange={(e) =>
                  handleChangeInput("first_name", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={LastName}
                variant="outlined"
                size="small"
                name="last_name"
                value={priceQuoteInput.last_name}
                onChange={(e) => handleChangeInput("last_name", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Date of Birth"
                variant="outlined"
                type="date"
                size="small"
                error={
                  new Date().getFullYear() -
                    new Date(priceQuoteInput.dob).getFullYear() <
                    10 &&
                  priceQuoteInput.dob !== "" &&
                  true
                }
                InputLabelProps={{ shrink: true }}
                name="dob"
                value={priceQuoteInput.dob}
                onChange={(e) => handleChangeInput("dob", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                type="email"
                id="outlined-basic"
                error={
                  !regEmail.test(priceQuoteInput.email) &&
                  priceQuoteInput.email !== "" &&
                  true
                }
                label={PrimaryEmail}
                variant="outlined"
                size="small"
                name="email"
                value={priceQuoteInput.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                type="email"
                error={
                  !regEmail.test(priceQuoteInput.secondary_email) &&
                  priceQuoteInput.secondary_email !== "" &&
                  true
                }
                id="outlined-basic"
                label="Secondary Email"
                variant="outlined"
                size="small"
                name="secondary_email"
                value={priceQuoteInput.secondary_email}
                onChange={(e) =>
                  handleChangeInput("secondary_email", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Lead Note"
                variant="outlined"
                size="small"
                name="note"
                value={priceQuoteInput.note}
                onChange={(e) => handleChangeInput("note", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
             
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Sales Partner"
                    variant="outlined"
                    name="sales_partner"
                    value={priceQuoteInput.sales_partner}
                    onChange={(e) =>
                      handleChangeInput("sales_partner", e.target.value)
                    }
                  >
                    {salesPartnerList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.first_name} {option.last_name}
                      </MenuItem>
                    ))}
                  </TextField>
               
            </div>
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Partner Manager"
                variant="outlined"
                name="partner_manager"
                value={priceQuoteInput.partner_manager}
                onChange={(e) =>
                  handleChangeInput("partner_manager", e.target.value)
                }
              >
                {UserList?.results?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.first_name} {option?.last_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={OpportunityStatus}
                name="opportunity_status"
                value={priceQuoteInput.opportunity_status}
                onChange={(e) =>
                  handleChangeInput("opportunity_status", e.target.value)
                }
              >
                {Opportunity_Status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={legal_type}
                name="legal_type"
                value={priceQuoteInput.legal_type}
                onChange={(e) =>
                  handleChangeInput("legal_type", e.target.value)
                }
              >
                {LegalType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              {priceQuoteInput.legal_type === "L" ||
              priceQuoteInput.legal_type === "LLP" ? (
                <TextField
                  id="outlined-basic"
                  label={company_house_no}
                  variant="outlined"
                  size="small"
                  name="company_house_no"
                  value={priceQuoteInput.company_house_no}
                  onChange={(e) => {
                    handleChangeInput("company_house_no", e.target.value);
                    dispatch(GetCompanyHouseList(e.target.value));
                  }}
                />
              ) : (
                priceQuoteInput.legal_type === "PL" && (
                  <TextField
                    id="outlined-basic"
                    label={company_house_no}
                    variant="outlined"
                    size="small"
                    name="company_house_no"
                    value={priceQuoteInput.company_house_no}
                    onChange={(e) => {
                      handleChangeInput("company_house_no", e.target.value);
                      dispatch(GetCompanyHouseList(e.target.value));
                    }}
                  />
                )
              )}
              {priceQuoteInput.legal_type === "CH" && (
                <TextField
                  id="outlined-basic"
                  label="Charity no"
                  variant="outlined"
                  size="small"
                  name="charity_number"
                  value={priceQuoteInput.charity_number}
                  onChange={(e) =>
                    handleChangeInput("charity_number", e.target.value)
                  }
                />
              )}
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Website"
                error={
                  !regex.test(priceQuoteInput.website) &&
                  priceQuoteInput.website !== "" &&
                  true
                }
                variant="outlined"
                size="small"
                name="website"
                value={priceQuoteInput.website}
                onChange={(e) => handleChangeInput("website", e.target.value)}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={TradingName}
                error={priceQuoteInput?.trading_name?.includes("'") ? true : false}
                variant="outlined"
                size="small"
                name="trading_name"
                value={priceQuoteInput.trading_name}
                onChange={(e) =>
                  handleChangeInput("trading_name", e.target.value)
                }
              />
            </div>

            {/* <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={traddingAdd}
                variant="outlined"
                size="small"
                name="trading_address"
                value={priceQuoteInput.trading_address}
                onChange={(e) =>
                  handleChangeInput("trading_address", e.target.value)
                }
              />
            </div> */}
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Call Back Date"
                variant="outlined"
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                name="callback_date"
                value={priceQuoteInput.callback_date}
                onChange={(e) =>
                  handleChangeInput("callback_date", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              {!isLoadLeads &&
                IndustryList &&
                IndustryList !== null &&
                IndustryList.length > 0 && (
                  <TextField
                    // disabled
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="MCC Code"
                    name="mcc_code"
                    value={priceQuoteInput.industry_type}
                    onChange={(e) => {
                      IndustryList?.map(
                        (code) =>
                          priceQuoteInput.industry_type === code.id &&
                          dispatch(GetPricequoteInput("mcc_code", code.mcc_iso))
                      );
                    }}
                    inputProps={{ readOnly: true }}
                  >
                    {IndustryList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.mcc_iso}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
            </div>

            {/* <Number
              text={"Mobile Number"}
              colorBg={"white"}
              name="mobile"
              value={priceQuoteInput.mobile}
              onchangevalue={(e) => handleChangeInput("mobile", e.target.value)}
              onchangeFlag={(event, newValue) => {
                countryList.map(
                  (code) =>
                    code.phone_code === newValue &&
                    handleChangeInput("mobile_code", code.id)
                );
              }}
              flag_value={
                countryList?.[priceQuoteInput.mobile_code - 1]?.phone_code ||
                null
              }
              flag_name="mobile_code"
            />

            <Number
              text={"Telephone Number"}
              colorBg={"white"}
              name="telephone"
              value={priceQuoteInput.telephone}
              onchangevalue={(e) =>
                handleChangeInput("telephone", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map(
                  (code, i) =>
                    code.phone_code === newValue &&
                    handleChangeInput("phone_code", code.id)
                );
              }}
              flag_value={
                countryList?.[priceQuoteInput.phone_code - 1]?.phone_code ||
                null
              }
              flag_name="phone_code"
            /> */}

            <Number
              text={"Mobile Number"}
              colorBg={"white"}
              name="mobile"
              value={priceQuoteInput.mobile}
              onchangevalue={(e) => handleChangeInput("mobile", e.target.value)}
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetPricequoteInput("mobile_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryIndex()]?.phone_code || "GB 44"
              }
              flag_name="mobile_code"
            />
            <Number
              text={"Telephone Number"}
              colorBg={"white"}
              name="telephone"
              value={priceQuoteInput.telephone}
              onchangevalue={(e) =>
                handleChangeInput("telephone", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetPricequoteInput("phone_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryTelephoneIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                "GB 44"
              }
              flag_name="phone_code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
