import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
// import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  GetApplicationInput,
  SubmitApplicationInput,
  SubmitNewApplicationInput,
} from "../_redux/action/NewapplicationAction";
import { SiteOwnership } from "../../commons/Dropdown";
import ContactInfoBox from "../ContactInfoBox";
import PreviewContactDetailsEdit from "./PreviewContactDetailsEdit";

const CustomarDetailEdit = ({ customerDetailEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // useEffect(() => {
  //   dispatch(SetApplicationUpdatedData(id));
  // }, []);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
   
  };
  const handleChangeOwnerInput = (name, value, index, contact = undefined) => {
    dispatch(
      GetApplicationInput(name, value, index, "business_owners", contact)
    );
  };
  return (
    <div className="customar-detail">
      {/* -------------deatil head ------------------ */}
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Customer Details</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={customerDetailEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              customerDetailEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>
      {/* -------------deatil head ------------------ */}
      {/* ------------detail content------------ */}
      <PreviewContactDetailsEdit/>
      {/* {applicationInput?.business_owners?.map((item, index) => {
        return (
          <>
          <PreviewContactDetailsEdit/>
           
          </>
        );
      })} */}
      {/* ------------detail content------------ */}
    </div>
  );
};

export default CustomarDetailEdit;
