import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLeadsnput,
  GetSourceList,
  GetSalesPartnerList,
} from "../../_redux/action/LeadsAction";

const LeadInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const salesPartnerList = useSelector(
    (state) => state.leadInfo.salesPartnerList
  );
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const handleChangeInput = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";

    if (isLoggedIn === "false") {
      history.push("/");
    }
    // else if(typeof userData === "undefined") {
    //   history.push("/");
    // }
    dispatch(GetSalesPartnerList());
    
  }, []);
  const leadSource = (
    <p>
      Lead Source <span className="required">*</span>
    </p>
  );
  const salespartner = (
    <p>
      Sales Partner <span className="required">*</span>
    </p>
  );
  const Lead_Stage = (
    <p>
      Lead stage <span className="required">*</span>
    </p>
  );
  const legal_type = (
    <p>
      Legal Type <span className="required">*</span>
    </p>
  );
  const lead_status = (
    <p>
      Lead Status <span className="required">*</span>
    </p>
  );
  const LeadStatus = [
    {
      value: 0,
      label: "N/A",
    },
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Call back arranged",
    },
    {
      value: 3,
      label: "Future Opportunity",
    },
    {
      value: 4,
      label: "Basic Info Requested",
    },
    {
      value: 5,
      label: "Converted to Opportunity",
    },
    {
      value: 6,
      label: "Didn't Make Inquiry",
    },
    {
      value: 7,
      label: "Already Signed with Competitor",
    },
    {
      value: 8,
      label: "Wrong Details",
    },
    {
      value: 9,
      label: "Not Competitive",
    },
    {
      value: 10,
      label: "Not Compatible",
    },
  ];

  const LeadStage = [
    {
      value: 0,
      label: " New",
    },
    {
      value: 1,
      label: "No Answer",
    },
    {
      value: 2,
      label: "Made Contact",
    },
    {
      value: 3,
      label: "Lost",
    },
    {
      value: 4,
      label: "Appointment Set",
    },
  ];
  const LegalType = [
    {
      value: "ST",
      label: "Sole Trader",
    },
    {
      value: "CL",
      label: "Club",
    },
    {
      value: 2,
      label: "Call back arranged",
    },
    {
      value: "CH",
      label: "Charity",
    },
    {
      value: "TR",
      label: "Trust",
    },
    {
      value: "PART",
      label: "Partnership",
    },
    {
      value: "LLP",
      label: "Limited Liability Partnership",
    },
    {
      value: "L",
      label: "Private Limited Company",
    },
    {
      value: "PL",
      label: "Public Limited Company",
    },
    {
      value: "OT",
      label: "Other",
    },
  ];

  return (
    <div className="form-wrapper text-capitalize">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Lead Information</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            {!isLoadLeads &&
              sourceList &&
              sourceList !== null &&
              sourceList.length > 0 && (
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={leadSource}
                    variant="outlined"
                    name="lead_source"
                    value={leadInput.lead_source}
                    onChange={(e) => {
                      handleChangeInput("lead_source", e.target.value);
                    }}
                  >
                    {sourceList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={Lead_Stage}
                name="lead_stage"
                value={leadInput.lead_stage}
                onChange={(e) =>
                  handleChangeInput("lead_stage", e.target.value)
                }
              >
                {LeadStage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={salespartner}
                variant="outlined"
                name="sales_partner"
                value={leadInput.sales_partner}
                onChange={(e) =>
                  handleChangeInput("sales_partner", e.target.value)
                }
              >
                {salesPartnerList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.first_name} {option?.last_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={legal_type}
                name="legal_type"
                value={leadInput.legal_type}
                onChange={(e) =>
                  handleChangeInput("legal_type", e.target.value)
                }
              >
                {LegalType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={lead_status}
                name="lead_status"
                value={leadInput.lead_status}
                onChange={(e) =>
                  handleChangeInput("lead_status", e.target.value)
                }
              >
                {LeadStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Call Back Date"
                variant="outlined"
                type="date"
                // type="datetime-local"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="callback_date"
                value={leadInput.callback_date}
                onChange={(e) =>
                  handleChangeInput("callback_date", e.target.value)
                }
              />
            </div>
          </div>

          <div className="col-12">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                rows={4}
                label="Lead Note"
                multiline
                variant="outlined"
                size="small"
                name="note"
                value={leadInput.note}
                onChange={(e) => handleChangeInput("note", e.target.value)}
              />
            </div>
          </div>

          {/* <div className="mt-5 d-end">
            <div>
              <button className="success-btn">
                Convert to Price Quote
              </button>
            </div>
            <div>
              <button className="cancel-btn">Cancel</button>
            </div>
            <div>
              <button className="save-btn" onClick={()=>handleAddLeads(leadInput, userData.id)}>Save</button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LeadInfo;
