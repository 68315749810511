import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fastest_payment } from "../../commons/Dropdown";
import Button from "@mui/material/Button";
import {
  GetApplicationInput,
  SubmitApplicationInput,
  SubmitNewApplicationInput,
} from "../_redux/action/NewapplicationAction";
import FinanceInfo from "../FinanceInfo";
const FinancialInformationEdit = ({ financialInfoEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // useEffect(() => {
  //   dispatch(SetApplicationUpdatedData(id));
  // }, []);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };
  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
  };
  return (
    <>
 
    <div className="customar-detail">
    
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} className="small-img" alt="" />
          <h4 style={{ color: "white" }}>Financial Information</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={financialInfoEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              financialInfoEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      <FinanceInfo/>
      {/* <div className="detail-content">
        <div>
          <p>Bank Name</p>
          <input
            className="input"
            type="text"
            required
            placeholder="Estern Bank"
            name="bank_name"
            value={applicationInput.bank_name}
            onChange={(e) => {
              handleChangeInput("bank_name", e.target.value);
            }}
          />
        </div>
        <div>
          <p>Sort Code</p>
          <input
            className="input"
            type="text"
            required
            error={applicationInput?.bank_sort_code?.length !== 6 && applicationInput?.bank_sort_code !== ""    && true}
            placeholder="23456"
            name="bank_sort_code"
            value={applicationInput.bank_sort_code}
            onChange={(e) => {
              handleChangeInput("bank_sort_code", e.target.value);
            }}
          />
        </div>
        <div>
          <p>Account Name</p>
          <input
            className="input"
            type="text"
            required
            placeholder="Shafin Ahmed"
            name="bank_account_name"
            value={applicationInput.bank_account_name}
            onChange={(e) => {
              handleChangeInput("bank_account_name", e.target.value);
            }}
          />
        </div>

        <div>
          <p>Account Number</p>
          <input
            className="input"
            type="text"
            required
            error={  applicationInput?.bank_account_no !== "" && applicationInput?.bank_account_no?.length !== 8  && true}
            placeholder="67265872"
            name="bank_account_no"
            value={applicationInput.bank_account_no}
            onChange={(e) => {
              handleChangeInput("bank_account_no", e.target.value);
            }}
          />
        </div>
      
        <div>
          <p>Billing Day</p>
          <input
            className="input"
            type="text"
            required
            placeholder="23456"
            name="billing_day"
            value={applicationInput.billing_day}
            onChange={(e) => handleChangeInput("billing_day", e.target.value)}
          />
        </div>
        <div>
          <p>Funding Day</p>
          <input
            className="input"
            type="text"
            required
            placeholder="Estern Bank"
            name="funding_day"
            value={applicationInput.funding_day}
            onChange={(e) => handleChangeInput("funding_day", e.target.value)}
          />
        </div>
        <div>
          <p>Faster Payment</p>
          <select
            className="input"
            aria-label="Default select example"
            name="bank_faster_payments"
            value={applicationInput.bank_faster_payments}
            onChange={(e) =>
              handleChangeInput("bank_faster_payments", e.target.value)
            }
          >
            {fastest_payment.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Delay Days</p>
          <input
            className="input"
            type="text"
            required
            placeholder="30% "
            name="delay_days"
            value={applicationInput.delay_days}
            onChange={(e) => handleChangeInput("delay_days", e.target.value)}
          />
        </div>
        <div>
          <div>
            <p> Will you be renting terminal proms from Elavon? :</p>
          </div>
          <div>
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() =>
                  handleSelectButton("renting_elavon_terminals", true)
                }
                size="small"
                variant={
                  applicationInput.renting_elavon_terminals === true
                    ? "contained"
                    : "outlined"
                }
              >
                Yes
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("renting_elavon_terminals", false)
                }
                size="small"
                variant={
                  applicationInput.renting_elavon_terminals === false
                    ? "contained"
                    : "outlined"
                }
              >
                No
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Vat Override: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3  mb-form">
              <Button
                onClick={() => handleSelectButton("vat_override", true)}
                size="small"
                variant={
                  applicationInput.vat_override === true
                    ? "contained"
                    : "outlined"
                }
              >
                Yes / Rate
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Billing Frequency: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() =>
                  handleSelectButton("billing_frequesncy", "DAILY")
                }
                size="small"
                variant={
                  applicationInput.billing_frequesncy === "DAILY"
                    ? "contained"
                    : "outlined"
                }
                // variant="outlined"
              >
                Daily
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("billing_frequesncy", "WEEKLY")
                }
                size="small"
                variant={
                  applicationInput.billing_frequesncy === "WEEKLY"
                    ? "contained"
                    : "outlined"
                }
              >
                Weekly
              </Button>

              <Button
                onClick={() =>
                  handleSelectButton("billing_frequesncy", "MONTHLY")
                }
                size="small"
                variant={
                  applicationInput.billing_frequesncy === "MONTHLY"
                    ? "contained"
                    : "outlined"
                }
              >
                Monthly
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Account: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() => handleSelectButton("bank_account_type", "ALL")}
                size="small"
                variant={
                  applicationInput.bank_account_type === "ALL"
                    ? "contained"
                    : "outlined"
                }
                // variant="outlined"
              >
                All
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("bank_account_type", "DEPOSIT")
                }
                size="small"
                variant={
                  applicationInput.bank_account_type === "DEPOSIT"
                    ? "contained"
                    : "outlined"
                }
              >
                Deposite
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("bank_account_type", "BILLING")
                }
                size="small"
                variant={
                  applicationInput.bank_account_type === "BILLING"
                    ? "contained"
                    : "outlined"
                }
              >
                Billing
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("bank_account_type", "CHARGEBACK")
                }
                size="small"
                variant={
                  applicationInput.bank_account_type === "CHARGEBACK"
                    ? "contained"
                    : "outlined"
                }
              >
                Chargeback
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Sattelment Method: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() =>
                  handleSelectButton("bank_settlement_method", "GROSS")
                }
                size="small"
                variant={
                  applicationInput.bank_settlement_method === "GROSS"
                    ? "contained"
                    : "outlined"
                }
                // variant="outlined"
              >
                Gross
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("bank_settlement_method", "net")
                }
                size="small"
                variant={
                  applicationInput.bank_settlement_method === "net"
                    ? "contained"
                    : "outlined"
                }
              >
                Net
              </Button>
            </div>
          </div>
        </div>

        <div>
          <div>
            <p>Funding Frequency: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3  mb-form">
              <Button
                onClick={() =>
                  handleSelectButton("funding_frequesncy", "DAILY")
                }
                size="small"
                variant={
                  applicationInput.funding_frequesncy === "DAILY"
                    ? "contained"
                    : "outlined"
                }
              >
                Daily
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("funding_frequesncy", "WEEKLY")
                }
                size="small"
                variant={
                  applicationInput.funding_frequesncy === "WEEKLY"
                    ? "contained"
                    : "outlined"
                }
              >
                Weekly
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("funding_frequesncy", "MONTHLY")
                }
                size="small"
                variant={
                  applicationInput.funding_frequesncy === "MONTHLY"
                    ? "contained"
                    : "outlined"
                }
              >
                Monthly
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <p>Payment Method: </p>
          </div>
          <div>
            <div className="d-sm-flex gap-3  mb-form">
              <Button
                onClick={() => handleSelectButton("payment_method", "EBA")}
                size="small"
                variant={
                  applicationInput.payment_method === "EBA"
                    ? "contained"
                    : "outlined"
                }
              >
                EBA
              </Button>
              <Button
                onClick={() => handleSelectButton("payment_method", "ALB")}
                size="small"
                variant={
                  applicationInput.payment_method === "ALB"
                    ? "contained"
                    : "outlined"
                }
              >
                ALB
              </Button>
              <Button
                onClick={() => handleSelectButton("payment_method", "CHAIN")}
                size="small"
                variant={
                  applicationInput.payment_method === "CHAIN"
                    ? "contained"
                    : "outlined"
                }
              >
                Chain
              </Button>
              <Button
                onClick={() => handleSelectButton("payment_method", "OT")}
                size="small"
                variant={
                  applicationInput.payment_method === "OT"
                    ? "contained"
                    : "outlined"
                }
              >
                OT
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <p>Sattelment Method: </p>
          </div>
          <div className="col-8">
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() =>
                  handleSelectButton("bank_settlement_method", "GROSS")
                }
                size="small"
                variant={
                  applicationInput.bank_settlement_method === "GROSS"
                    ? "contained"
                    : "outlined"
                }
                // variant="outlined"
              >
                Gross
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("bank_settlement_method", "net")
                }
                size="small"
                variant={
                  applicationInput.bank_settlement_method === "net"
                    ? "contained"
                    : "outlined"
                }
              >
                Net
              </Button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    </>
  );
};

export default FinancialInformationEdit;
