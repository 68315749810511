import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import QueriesapplicationList from "../../../../home/InProgress/QueriesapplicationList";


// import Inprogress from "../InProgress/Inprogress";
// import NewApplication from "../NewApplication/NewApplication";
// import NewApplicationPage from "../NewApplication/NewApplicationPage";

const BankqueriesApplication = () => {
  const history = useHistory();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  return (
    <div>
      
      <div>
        <div className="breadcump">
        <p>
          {" "}
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            {" "}
            All Application /
          </span>
          <span style={{ cursor: "not" }}> Bank Queries Table</span>
        </p>
      </div>
      <div className="px-1 px-md-4 box-wrapper2">
        
     <QueriesapplicationList/>
      </div>
      </div>
      
    </div>
  );
};

export default BankqueriesApplication;
