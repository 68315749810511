import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetApplicationInput } from "./_redux/action/NewapplicationAction";
import {
  GetIndustryList,
  GetSalesPartnerList,
  GetSourceList,
} from "../../_redux/action/LeadsAction";
import { useEffect } from "react";
import {
  Application,
  applicationStage,
  ApplicationStatus,
} from "../commons/Dropdown";
import { GetUserList } from "../../../../userManagement/_redux/action/UserAction";
import { BASE_URL } from "../../../../../Const";

const SalesDetails = () => {
  const salesPartnerList = useSelector(
    (state) => state.leadInfo.salesPartnerList
  );
  const UserList = useSelector((state) => state.userInfo.UserList);
  const OpportunityStage = (
    <p>
      Application stage <span className="required">*</span>
    </p>
  );
  const partner = (
    <p>
      Sales Partner <span className="required">*</span>
    </p>
  );
  const Manager = (
    <p>
      Partner manager <span className="required">*</span>
    </p>
  );

  // ----------api call-------------

  const dispatch = useDispatch();

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
    // salesPartnerList?.map((item) => {
    //   item?.id === applicationInput?.sales_partner &&
    //     dispatch(
    //       GetApplicationInput(
    //         "s_individual_sales_representatives",
    //         item.first_name + " " + item.last_name
    //       )
    //     );
    // });
  };
  useEffect(() => {
    salesPartnerList?.map((item) => {
      item?.id === applicationInput?.sales_partner &&
        dispatch(
          GetApplicationInput(
            "s_individual_sales_representatives",
            item.first_name + " " + item.last_name
          )
        );
    });
  }, [applicationInput?.sales_partner]);
  return (
    <div className="">
      <div className="info-box text-capitalize site_visit">
        <div className="d-flex heading">
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>

          <p>Sales Details</p>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label={OpportunityStage}
                  name="applicaiton_stage"
                  value={applicationInput.applicaiton_stage}
                  onChange={(e) => {
                    handleChangeInput("applicaiton_stage", e.target.value);
                  }}
                >
                  {applicationStage.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="mb-form">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label={partner}
                  variant="outlined"
                  name="sales_partner"
                  value={applicationInput.sales_partner}
                  onChange={(e) => {
                    handleChangeInput("sales_partner", e.target.value);
                    // dispatch(GetSalesPartnerList(1));
                  }}
                >
                  {salesPartnerList?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.first_name} {option?.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="mb-form">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label={Manager}
                  variant="outlined"
                  name="partner_manager"
                  value={applicationInput.partner_manager}
                  onChange={(e) =>
                    handleChangeInput("partner_manager", e.target.value)
                  }
                >
                  {UserList?.results?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.first_name} {option?.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Call Back Date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  name="callback_date"
                  value={applicationInput.callback_date}
                  onChange={(e) => {
                    handleChangeInput("callback_date", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Est. Close Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="application_close_date"
                  value={applicationInput.application_close_date}
                  onChange={(e) => {
                    handleChangeInput("application_close_date", e.target.value);
                  }}
                />
              </div>
              {/* <div className="mb-form">
                <TextField
                  size="small"
                  id="outlined-select-currency"
                  select
                  label={OpportunityStatus}
                
                  name="application_status"
                  value={applicationInput.application_status}
                  onChange={(e) => {
                    handleChangeInput("application_status", e.target.value);
                  }}
                 
                >
                  {ApplicationStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="application Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="appliation_price_quote"
                  value={applicationInput.appliation_price_quote}
                  onChange={(e) => {
                    handleChangeInput("appliation_price_quote", e.target.value);
                  }}
                />
              </div>
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  rows={2}
                  label="Application notes"
                  variant="outlined"
                  multiline
                  size="small"
                  name="note"
                  value={applicationInput.note}
                  onChange={(e) => {
                    handleChangeInput("note", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDetails;
