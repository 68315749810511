import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../../asstes/icon.bablu/add-file.svg";

import { useEffect } from "react";

import cross from "../../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";

import { connectionLength } from "../../commons/Dropdown";
import Button from "@mui/material/Button";
import {
  GetEposProductInput,
  GetLeadsnput,
} from "../../../_redux/action/LeadsAction";
import {
  SET_EPOS_PRODUCT,
  REMOVE_EPOS_PRODUCT,
} from "../../../_redux/types/Types";

function EposProducts({ type_name, id, lead_product }) {
  const dispatch = useDispatch();

  const leadInput = useSelector((state) => state.leadInfo.leadInput);

  // useEffect(() => {
  //   dispatch(
  //     GetLeadsnput(
  //       "epos_products",
  //       lead_product?.filter((item) => item?.product_type === "epos")
  //     )
  //   );
  // }, []);
  const handleAddMore = () => {
    dispatch({ type: SET_EPOS_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_EPOS_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetEposProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(GetEposProductInput(name, value, index, "epos_products"));
    dispatch(GetEposProductInput(type_name, id, index, "epos_products"));
   
  };

  return (
    <>
      {leadInput?.epos_products?.length < 1 && (
        <div className="d-flex justify-content-between heading mb-form mt-4 ">
          <button className="fill_add_button" onClick={() => handleAddMore()}>
            <img
              width={14}
              style={{ marginRight: "14px", marginBottom: "3px" }}
              height={14}
              src={addFileIcon}
              alt=""
            />
            ADD PRODUCT
          </button>
        </div>
      )}

      <div
        className="container row select_div"
        style={{ marginBottom: "-15px " }}
      >
        {leadInput?.epos_products?.map((product, i) => {
          return (
            <>
              {product.is_deleted === false && (
                <div
                  className="col-12 col-md-6 mb-3 text-capitalize"
                  key={product.id}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "8px",
                      padding: "40px 15px",
                      margin: "15px 0px",
                      border: "0.5px solid rgb(224, 224, 224)",
                    }}
                  >
                    <div
                      // onClick={() => handleremoveDoc(i)}
                      onClick={() => {
                        product.id
                          ? handleremoveProductTwo(i)
                          : handleremoveProduct(i);
                      }}
                      className="cross d-flex"
                      style={{
                        border: "1px solid #FFCDD2",
                        width: "24px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={cross} alt="" />
                    </div>
                    <div className="row mb-form">
                      <div className="col-md-4 col-lg-3">
                        <p>EPOS Option: </p>
                      </div>
                      <div className="col-md-8 col-lg-9">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeInput("epos_option", "RETAIL", i)
                            }
                            size="small"
                            variant={
                              product.epos_option === "RETAIL"
                                ? "contained"
                                : "outlined"
                            }
                            // variant="outlined"
                          >
                            RETAIL
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeInput("epos_option", "HOSPITALITY", i)
                            }
                            size="small"
                            variant={
                              product.epos_option === "HOSPITALITY"
                                ? "contained"
                                : "outlined"
                            }
                          >
                            HOSPITALITY
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-form">
                      <div className="col-lg-12 mb-4">
                        <TextField
                          size="small"
                          id="outlined-select-currency"
                          // select
                          label="Epos provider"
                          name="epos_provider"
                          value={product.epos_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "epos_provider",
                              e.target.value,
                              i
                            );
                          }}
                        >
                          {/* {provider_list?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))} */}
                        </TextField>
                      </div>
                    </div>

                    <div className="">
                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <TextField
                            // disabled={product.provider === 0 && true}
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Contact Length"
                            name="contact_length"
                            value={product.contact_length}
                            onChange={(e) =>
                              handleChangeInput(
                                "contact_length",
                                e.target.value,
                                i
                              )
                            }
                          >
                            {connectionLength?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            //   select
                            label="One Of cost"
                            name="one_of_cost"
                            value={product.one_of_cost}
                            onChange={(e) => {
                              handleChangeInput(
                                "one_of_cost",
                                e.target.value,
                                i
                              );
                            }}
                          ></TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            label="Monthly price"
                            name="monthly_price"
                            InputLabelProps={{ shrink: true }}
                            value={product.monthly_price}
                            onChange={(e) =>
                              handleChangeInput(
                                "monthly_price",
                                e.target.value,
                                i
                              )
                            }
                          />
                        </div>
                        {/* <div className="col-lg-6 ">
                          <TextField
                            size="small"
                            type="number"
                            id="outlined-select-currency"
                            label="Quantity"
                            name="qty"
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            value={product.qty}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            error={product.qty < 1}
                            onChange={(e) =>
                              handleChangeInput("qty", e.target.value, i)
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
        {leadInput?.epos_products?.length > 0 && (
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{ marginBottom: "40px" }}
          >
            <button className="fill_add_button" onClick={() => handleAddMore()}>
              <img
                width={14}
                style={{ marginRight: "14px", marginBottom: "3px" }}
                height={14}
                src={addFileIcon}
                alt=""
              />
              ADD PRODUCT
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default EposProducts;
