import React from "react";
import TextField from "@mui/material/TextField";

import { useDispatch, useSelector } from "react-redux";

import {
  GetApplicationInput,
  GetSiteVisitPdfInput,
  SetPdfStatusFalse,
  SubmitSiteVisitPdfUrl,
} from "./_redux/action/NewapplicationAction";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import Button from "@mui/material/Button";

import { BsDownload } from "react-icons/bs";

const AppSiteVisite = () => {
  const dispatch = useDispatch();
  const specificLocation = (
    <p>
      Specific Location<span className="required">*</span>
    </p>
  );
  const SalesRepresentative = (
    <p>
      Sales Representative <span className="required">*</span>
    </p>
  );
  const SalesPartner = (
    <p>
      Sales Partner <span className="required">*</span>
    </p>
  );
  const legalName = (
    <p>
      Legal Name<span className="required">*</span>
    </p>
  );
  const tradingname = (
    <p>
      Trading Name<span className="required">*</span>
    </p>
  );
  useEffect(() => {
    if (!applicationInput.s_squire_meters) {
      dispatch(GetApplicationInput("s_squire_meters", "1000_PLUS"));
    }
  }, []);

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const siteVisitPdfInput = useSelector(
    (state) => state.applicationInfo.siteVisitPdfInput
  );

  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };

  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
  };

 

  const handlePdfDownload = (data) => {
    dispatch(GetSiteVisitPdfInput("application_id", applicationInput.id));

    dispatch(SubmitSiteVisitPdfUrl(data, applicationInput.id));
  };

  // useEffect(() => {
  //   if (afterSuccessPdf && siteVisitPdfInput.pdf_url) {
  //     const element = document.createElement("div");
  //     element.innerHTML = siteVisitPdfInput.pdf_url;

  //     html2pdf()
  //       .set({ html2canvas: { scale: 4 } })
  //       .from(element)
  //       .save("SiteVisit.pdf");
  //     dispatch(SetPdfStatusFalse());

  // }, [afterSuccessPdf, siteVisitPdfInput.pdf_url]);
  return (
    <div className="">
      {isLoadLeads && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="info-box text-capitalize site_visit">
        <div className="d-flex justify-content-between">
          <div className="d-flex heading">
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                fill="#0D0D0D"
              />
            </svg>

            <p>Site Visit</p>
          </div>
          <button
            style={{ backgroundColor: "#e95184c7 ", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={() => handlePdfDownload(siteVisitPdfInput)}
            // onClick={() => downloadPdf()}
          >
            <BsDownload /> Site Visit Pdf
          </button>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label={tradingname}
                      variant="outlined"
                      size="small"
                      name="trading_name"
                      value={applicationInput.trading_name}
                      onChange={(e) => {
                        handleChangeInput("trading_name", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-form">
                  <TextField
                    id="outlined-basic"
                    label={legalName}
                    variant="outlined"
                    size="small"
                    name="legal_name"
                    value={applicationInput.legal_name}
                    onChange={(e) => {
                      handleChangeInput("legal_name", e.target.value);
                    }}
                  />
                </div>
              </div>

              <hr />
              <div className="row ">
                <div className="col-md-4 col-lg-3">
                  <p>
                    Location Type: <span className="required">*</span>{" "}
                  </p>
                </div>
                <div className="col-md-8 col-lg-9">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("s_location_type", "SHOPPING_CENTRE")
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_type === "SHOPPING_CENTRE"
                          ? "contained"
                          : "outlined"
                      }
                      // variant="outlined"
                    >
                      Shopping center
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_location_type", "OFFICE_BUILDING")
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_type === "OFFICE_BUILDING"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Office Building
                    </Button>

                    <Button
                      onClick={() =>
                        handleSelectButton(
                          "s_location_type",
                          "INDUSTRIAL_ESTATE"
                        )
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_type === "INDUSTRIAL_ESTATE"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Industrial Estate
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_location_type", "HOME")
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_type === "HOME"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Home
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_location_type", "OTHER")
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_type === "OTHER"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Other
                    </Button>
                  </div>
                </div>
              </div>

              {applicationInput.s_location_type === "OTHER" && (
                <div className="mb-form mt-4">
                  <TextField
                    id="outlined-basic"
                    label={specificLocation}
                    variant="outlined"
                    size="small"
                    type="text"
                    name="s_specific_location"
                    value={applicationInput.s_specific_location}
                    onChange={(e) => {
                      handleChangeInput("s_specific_location", e.target.value);
                    }}
                  />
                </div>
              )}
              <hr />

              <div className="row ">
                <div className="col-md-4 col-lg-3">
                  <p>
                    Customer Lives Above the Premises:{" "}
                    <span className="required">*</span>
                  </p>
                </div>
                <div className="col-md-8 col-lg-9">
                  <div className="d-md-flex gap-3  mb-form">
                    <Button
                      onClick={() => handleSelectButton("s_customer_lives", 1)}
                      size="small"
                      variant={
                        applicationInput.s_customer_lives === 1
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => handleSelectButton("s_customer_lives", 2)}
                      size="small"
                      variant={
                        applicationInput.s_customer_lives === 2
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row ">
                <div className="col-md-4 col-lg-3">
                  <p>
                    Location Environment: <span className="required">*</span>
                  </p>
                </div>
                <div className="col-8 col-lg-9">
                  <div className="d-md-flex gap-3  mb-form">
                    <Button
                      onClick={() =>
                        handleSelectButton(
                          "s_location_environment",

                          "BUSINESS_DISTRICT"
                        )
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_environment ===
                        "BUSINESS_DISTRICT"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Business District
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton(
                          "s_location_environment",
                          "INDUSTRIAL_ESTATE"
                        )
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_environment ===
                        "INDUSTRIAL_ESTATE"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Industrial Estate
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton(
                          "s_location_environment",
                          "RESIDENTIAL"
                        )
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_environment ===
                        "RESIDENTIAL"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Residential
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_location_environment", "RETAIL")
                      }
                      size="small"
                      variant={
                        applicationInput.s_location_environment === "RETAIL"
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Retail
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mb-form">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p>
                      {" "}
                      Condition of Vicinity <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton(
                            "s_condition_of_vicinity",
                            "WELL_KEPT"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.s_condition_of_vicinity ===
                          "WELL_KEPT"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Well Kept
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton(
                            "s_condition_of_vicinity",
                            "REGENERATION"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.s_condition_of_vicinity ===
                          "REGENERATION"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Regeneration
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton(
                            "s_condition_of_vicinity",
                            "DETERIORATION"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.s_condition_of_vicinity ===
                          "DETERIORATION"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Deterioration
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mb-form">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p>
                      {" "}
                      Square Meters <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("s_squire_meters", "LESS_THAN_250")
                        }
                        size="small"
                        variant={
                          applicationInput.s_squire_meters === "LESS_THAN_250"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        250
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("s_squire_meters", "251_500")
                        }
                        size="small"
                        variant={
                          applicationInput.s_squire_meters === "251_500"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        251-500
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("s_squire_meters", "501_1000")
                        }
                        size="small"
                        variant={
                          applicationInput.s_squire_meters === "501_1000"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        501-1,000
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("s_squire_meters", "1000_PLUS")
                        }
                        size="small"
                        variant={
                          applicationInput.s_squire_meters === "1000_PLUS"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        1,000+
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mb-form">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p>
                      {" "}
                      Premises General Appearance{" "}
                      <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton(
                            "s_general_appearance",
                            "VERY_GOOD"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.s_general_appearance === "VERY_GOOD"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Very Good
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton(
                            "s_general_appearance",
                            "SATISFACTORY"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.s_general_appearance ===
                          "SATISFACTORY"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Satisfactory
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("s_general_appearance", "POOR")
                        }
                        size="small"
                        variant={
                          applicationInput.s_general_appearance === "POOR"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Poor
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="mb-form">
                <div className="row">
                  <div className="col-4 col-lg-3">
                    <p>
                      {" "}
                      Premises Ownership <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() => handleSelectButton("s_ownership", 1)}
                        size="small"
                        variant={
                          applicationInput.s_ownership === 1
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Merchant Owns
                      </Button>
                      <Button
                        onClick={() => handleSelectButton("s_ownership", 2)}
                        size="small"
                        variant={
                          applicationInput.s_ownership === 2
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Merchant Rents
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row ">
                <div className="col-4 col-lg-3">
                  <p>
                    Is Business Open & Operating{" "}
                    <span className="required">*</span>
                  </p>
                </div>
                <div className="col-8 col-lg-9">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_business_open", true)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_business_open === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_business_open", false)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_business_open === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              {applicationInput.s_is_business_open === false && (
                <div className="mb-form mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Start Date"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    name="s_business_start_date"
                    value={applicationInput.s_business_start_date}
                    onChange={(e) => {
                      handleChangeInput(
                        "s_business_start_date",
                        e.target.value
                      );
                    }}
                  />
                </div>
              )}
              <hr />
              <div className="row ">
                <div className="col-4 col-lg-3">
                  <p>
                    {" "}
                    Sufficient Stock for Purchase Volume{" "}
                    <span className="required">*</span>
                  </p>
                </div>
                <div className="col-8 col-lg-9">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_sufficient_stock", true)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_sufficient_stock === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_sufficient_stock", false)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_sufficient_stock === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              {applicationInput.s_is_sufficient_stock === false && (
                <div className="mb-form mt-4">
                  <TextField
                    id="outlined-basic"
                    rows={2}
                    label="Comment"
                    variant="outlined"
                    multiline
                    size="small"
                    name="s_sufficient_stock_comment"
                    value={applicationInput.s_sufficient_stock_comment}
                    onChange={(e) => {
                      handleChangeInput(
                        "s_sufficient_stock_comment",
                        e.target.value
                      );
                    }}
                  />
                </div>
              )}
              <hr />

              <div className="row ">
                <div className="col-4 col-lg-3">
                  <p>
                    {" "}
                    Does Stock Reflect Business Type
                    <span className="required">*</span>
                  </p>
                </div>
                <div className="col-8 col-lg-9">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_reflect_business_type", true)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_reflect_business_type === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_reflect_business_type", false)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_reflect_business_type === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              {applicationInput.s_is_reflect_business_type === false && (
                <div className="mb-form mt-4">
                  <TextField
                    id="outlined-basic"
                    rows={2}
                    label="Comment"
                    variant="outlined"
                    multiline
                    size="small"
                    name="s_reflect_comment"
                    value={applicationInput.s_reflect_comment}
                    onChange={(e) => {
                      handleChangeInput("s_reflect_comment", e.target.value);
                    }}
                  />
                </div>
              )}
              <hr />

              <div className="row ">
                <div className="col-4 col-lg-3">
                  <p>
                    Are Card Decals Visible?<span className="required">*</span>
                  </p>
                </div>
                <div className="col-8 col-lg-9">
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_card_decels_visible", true)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_card_decels_visible === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("s_is_card_decels_visible", false)
                      }
                      size="small"
                      variant={
                        applicationInput.s_is_card_decels_visible === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              {applicationInput.s_is_card_decels_visible === false && (
                <div className="row mt-4">
                  <div className="col-4 col-lg-3">
                    <p>
                      {" "}
                      Installed at Visit? <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("s_is_installed_at_visit", true)
                        }
                        size="small"
                        variant={
                          applicationInput.s_is_installed_at_visit === true
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("s_is_installed_at_visit", false)
                        }
                        size="small"
                        variant={
                          applicationInput.s_is_installed_at_visit === false
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <hr />
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="mb-form">
                   
                    <TextField
                      id="outlined-basic"
                      label={SalesRepresentative}
                      variant="outlined"
                      size="small"
                      name="s_individual_sales_representatives"
                      value={
                        applicationInput.s_individual_sales_representatives
                      }
                      onChange={(e) => {
                        handleChangeInput(
                          "s_individual_sales_representatives",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Name of the individual met at the premises"
                      variant="outlined"
                      size="small"
                      name="s_name_of_individual"
                      value={applicationInput.s_name_of_individual}
                      onChange={(e) => {
                        handleChangeInput(
                          "s_name_of_individual",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Date of the site visit"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      name="s_individual_start_date"
                      value={applicationInput.s_individual_start_date}
                      onChange={(e) => {
                        handleChangeInput(
                          "s_individual_start_date",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>

               
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Individual Date"
                      variant="outlined"
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      name="s_individual_date"
                      value={applicationInput.s_individual_date}
                      onChange={(e) => {
                        handleChangeInput("s_individual_date", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSiteVisite;
