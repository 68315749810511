import React, { useState } from "react";
import "./Document.css";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import newDocumenent from "../../../../../../asstes/icon.bablu/new document icon.svg";
import png from "../../../../../../asstes/icon.bablu/png 1.svg";
import pdf from "../../../../../../asstes/icon.bablu/pdf.png";
import frame from "../../../../../../asstes/icon.bablu/Frame.svg";
import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDocInput,
  SubmitApplicationInput,
} from "../_redux/action/NewapplicationAction";

import Modal from "react-bootstrap/Modal";
import { saveAs } from "file-saver";
import { Download } from "@mui/icons-material";
import PreviewPdf from "../../PriceQuote/PreviewPdf";
import { FaEye } from "react-icons/fa";
const INPUT = ({ id, documentTitle, name, file, doc, index }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [URL, setURL] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));
  const handleChangeInput = (name, value, index, e) => {
    dispatch(GetDocInput(name, value, index, e, "application_docs"));
  };
  const handleClose = () => setShow(false);
  const handleShow = (url) => {
    if (url.includes(".pdf")) {
      window.open(url, "_blank");
    } else {
      setShow(true);
      setPdfFile(url);
    }
  };
  const downloadImage = (image_url) => {
    saveAs(image_url, "document_image"); // Put your image url here.
  };
  return (
    <>
      <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body id="contained-modal-title-vcenter">
            {pdfFile && pdfFile?.includes("data:application/pdf") ? (
              <PreviewPdf pdf={pdfFile} />
            ) : (
              <>
                <img
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    width: "100%",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                  src={pdfFile}
                  alt=""
                />
                <div
                  className="d-flex justify-content-end mt-4"
                  onClick={() => downloadImage(pdfFile)}
                >
                  <button className="save-btn">
                    <Download />
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      <div key={id} style={{ margin: "40px 0px" }}>
        <div style={{ marginBottom: "25px" }} className="d-flex ">
          <img
            style={{ width: "18px", height: "24px", marginRight: "6px" }}
            src={newDocumenent}
            alt=""
          />
          <p style={{ marginLeft: "10px" }}>{documentTitle}<span className="required">*</span></p>
        </div>

        <div className="upload-box" style={{ cursor: "pointer" }}  onClick={() => handleShow(file)}>
          {URL === "" ? (
            file.includes(".pdf") || file.includes("/pdf") ? (
              <img
                src={pdf}
                alt=""
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "150px",
                  padding: "10px 15px",
                }}
              />
            ) : (
              <img
                src={file}
                alt=""
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "150px",
                  padding: "10px 15px",
                }}
              />
            )
          ) : URL.includes(".pdf") ? (
            <img
              src={pdf}
              alt=""
              style={{
                cursor: "pointer",
                width: "100%",
                height: "150px",
                padding: "10px 15px",
              }}
            />
          ) : (
            <img
              src={file}
              alt=""
              style={{
                cursor: "pointer",
                width: "100%",
                height: "150px",
                padding: "10px 15px",
              }}
            />
          )}
          
          <div className="hover-box">
            <FaEye className="doc_icon"/>
            {/* <img src={frame} alt="" /> */}
            <p>View File</p>
          </div>
        </div>
      </div>
    </>
  );
};
const Document = ({ documentDetailEdit }) => {
  const dispatch = useDispatch();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Document</h4>
        </div>
        <div className="button-box">
          
          <div className="button-box">
            <button
              className="   custom-btn  flex "
              onClick={documentDetailEdit}
            >
              <img src={pen} style={{ marginRight: "7px" }} alt="" />
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="document-box ">
        {applicationInput.application_docs.map(
          (document, index) =>
            document.is_deleted === false && (
              <INPUT
                id={document.id}
                name={document.category}
                documentTitle={document.category}
                file={document.document}
                doc={document}
                index={index}
              />
            )
        )}
      </div>
    </div>
  );
};
export default Document;
