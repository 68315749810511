import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { GetPricequoteInput } from "../../_redux/action/LeadsAction";
import { SetBankInfoTrue } from "../newApplication/_redux/action/NewapplicationAction";

const FinantialInfo = () => {
  const bankName = (
    <p>
      Bank Name <span className="required">*</span>
    </p>
  );
  const accountName = (
    <p>
      Bank account name <span className="required">*</span>
    </p>
  );
  const accNumber = (
    <p>
      bank account number <span className="required">*</span>
    </p>
  );
  const sortCode = (
    <p>
      Sort code <span className="required">*</span>
    </p>
  );
  const dispatch = useDispatch();
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  const bankInfoChecked = useSelector(
    (state) => state.applicationInfo.bankInfoChecked
  );
  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
  };
  const handleChange = (e) => {
    dispatch(SetBankInfoTrue(!bankInfoChecked));
    handleaddrss(e);
   
   
  };
  const handleAutofil = (pCode, add1, add2, city, ) => {
    dispatch(GetPricequoteInput("bank_name", pCode));
    dispatch(GetPricequoteInput("bank_account_name", add1));
    dispatch(GetPricequoteInput("bank_sort_code", add2));
    dispatch(GetPricequoteInput("bank_account_number", city));
   
  };
  const handleaddrss = (e) => {
 
    if (e.target.checked) {
      handleAutofil(
        priceQuoteInput.bank_name,
        priceQuoteInput.bank_account_name,
        priceQuoteInput.bank_sort_code,
        priceQuoteInput.bank_account_number,
        priceQuoteInput.legal_county,
        priceQuoteInput.legal_country
      );
      dispatch(GetPricequoteInput("site_and_trading_address_same",1 ));
    } else {
      handleAutofil("", "", "", "", "", "");
      dispatch(GetPricequoteInput("site_and_trading_address_same",2 ));
    }
  };

  return (
    <div className="info-box text-capitalize">
      <div className="mt-4">
        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex heading mb-form">
                <svg
                  width="24"
                  height="28"
                  viewBox="0 0 24 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                    fill="#0D0D0D"
                  />
                </svg>

                <p>Settelment account details</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={bankName}
                variant="outlined"
                size="small"
                name="bank_name"
                value={priceQuoteInput.bank_name}
                onChange={(e) => handleChangeInput("bank_name", e.target.value)}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={priceQuoteInput?.bank_sort_code?.length !== 6 && priceQuoteInput?.bank_sort_code !== ""    && true}
                label={sortCode}
                variant="outlined"
                size="small"
                type="number"
                inputProps={{maxLength:6}}
                onWheel={(e) => e.target.blur()}
                name="bank_sort_code"
                value={priceQuoteInput.bank_sort_code}
                onChange={(e) =>
                  handleChangeInput("bank_sort_code", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={accountName}
                variant="outlined"
                size="small"
                name="bank_account_name"
                value={priceQuoteInput.bank_account_name}
                onChange={(e) =>
                  handleChangeInput("bank_account_name", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={  priceQuoteInput?.bank_account_no !== "" && priceQuoteInput?.bank_account_no?.length !== 8  && true}
                label={accNumber}
                variant="outlined"
                size="small"
                inputProps={{maxLength:8}}
                type="number"
                name="bank_account_no"
                value={priceQuoteInput.bank_account_no}
                onChange={(e) =>
                  handleChangeInput("bank_account_no", e.target.value)
                }
              />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default FinantialInfo;
