import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import Summary from "./Summary";
import ContactInfo from "./ContactInfo";
import BusinessInfo from "./BusinessInfo";
import Address from "./Address";
import LeadInfo from "./LeadInfo";

import Pricing from "./Pricing";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLeadsnput,
  GetSourceList,
  SetLeadsStatusFalse,
} from "../../_redux/action/LeadsAction";
import { useHistory } from "react-router-dom";
import ServiceDetails from "./ServiceDetails";
export default function Leadtab({ next }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(
    localStorage.getItem("selectedTab") || "1"
  ); // retrieve the last selected tab from localStorage or default to "1"
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const handleChange = (event, newValue) => {
    localStorage.setItem("selectedTab", newValue); // save the new selected tab to localStorage
    setValue(newValue);
  };

  useEffect(() => {
    // if (leadInput.company_house_no) {
    //   dispatch(GetCompanyOfficersDetails(leadInput.company_house_no));
    // }
    if (leadInput.contact_full_name === null) {
      dispatch(GetLeadsnput("contact_full_name", ""));
    }

    return () => {
      dispatch(SetLeadsStatusFalse());
    };
  }, []);
  if (leadInput.contact_full_name === null) {
    dispatch(GetLeadsnput("contact_full_name", ""));
  }
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);

  // ================================fixed sticky navbar===============
  const [navbar, setNavbar] = React.useState(false);
  const [color, setColor] = React.useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 70) {
      setNavbar(true);
      setColor("black");
    } else {
      setNavbar(false);
      setColor("white");
    }
  };

  useEffect(() => {
   
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const [isFixed, setIsFixed] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        // set a threshold value to trigger the fixed header
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    dispatch(
      GetLeadsnput(
        "terminal_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "card_terminal"
        )
      )
    );
    dispatch(
      GetLeadsnput(
        "ecommerce_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "ecom"
        )
      )
    );
    dispatch(
      GetLeadsnput(
        "epos_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "epos"
        )
      )
    );
    dispatch(GetSourceList());
  }, []);
  return (
    <div className="tab-wrapper">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            //  className={`tabs-header ${isFixed ? 'fixed' : ''}`}
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className={navbar ? "scroll" : "navbar"}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="Summary" value="1" />
              <Tab label="Contact Information" value="2" />
              <Tab label="Business Information" value="3" />
              <Tab label="Address " value="4" />
              <Tab label="Lead Information" value="5" />
              <Tab label="Service Details" value="6" />
              <Tab label="Cost Analysis" value="7" />
            </Tabs>
          </Box>
          <></>

          <TabPanel value="1">
            <Summary />
          </TabPanel>
          <TabPanel value="2">
            <ContactInfo />
          </TabPanel>
          <TabPanel value="3">
            <BusinessInfo />
          </TabPanel>
          <TabPanel value="4">
            <Address />
          </TabPanel>
          <TabPanel value="5">
            <LeadInfo />
          </TabPanel>
          <TabPanel value="6">
            <ServiceDetails />
          </TabPanel>
          <TabPanel value="7">
            <Pricing next={next} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
