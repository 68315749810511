import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// import {
//   GetApplicationInput,
//   SetApplicationUpdatedData,
//   SubmitApplicationInput,
// } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GetApplicationInput, SubmitApplicationInput, SubmitNewApplicationInput } from "../_redux/action/NewapplicationAction";
import PreviewScheduleofFees from "./PreviewScheduleofFees";
import AppScheduleFees from "../AppScheduleFees";

const EditScheduleOfFees = ({ feesDetailEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // useEffect(() => {
  //   dispatch(SetApplicationUpdatedData(id));
  // }, []);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100 table-responsive"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Schedule of Fees</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={feesDetailEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              feesDetailEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      <AppScheduleFees/>
      {/* <PreviewScheduleofFees/> */}
    </div>
  );
};

export default EditScheduleOfFees;
