import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { GetCountryList } from "../../../allAplication/mainLead/_redux/action/LeadsAction";
import { user_role } from "../../../allAplication/mainLead/components/commons/Dropdown";
// import { FaCamera } from "react-icons/fa";
// import { Add } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router";
import { BASE_URL } from "../../../../Const";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { GetUserInput, GetUserList, SetUserStatusFalse, SubmitUserData, UpdateuserdData } from "../../_redux/action/UserAction";
const AddSalesPartner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const FirstName = (
    <p>
      First Name <span className="required">*</span>
    </p>
  );
  const password = (
    <p>
    Password <span className="required">*</span>
    </p>
  );
  const lastName = (
    <p>
      Last Name <span className="required">*</span>
    </p>
  );
  const email = (
    <p>
      Email <span className="required">*</span>
    </p>
  );
  const mobile = (
    <p>
      Mobile No <span className="required">*</span>
    </p>
  );
  const country = (
    <p>
      Country <span className="required">*</span>
    </p>
  );
  const role = (
    <p>
      Role <span className="required">*</span>
    </p>
  );
  const Residual_percentage = (
    <p>
      Residual percentage % <span className="required">*</span>
    </p>
  );
  const Upfront_Percentage = (
    <p>
      Upfront Percentage %<span className="required">*</span>
    </p>
  );
  const regexNumber = /^(\+?\d{1,3}[- ]?)?\d{10}$/
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.priceQuoteInfo.imageInput);
  const UserInput = useSelector((state) => state.userInfo.UserInput);
  const UserList = useSelector((state) => state.userInfo.UserList);
  const afterUserData = useSelector((state) => state.userInfo.afterUserData);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetUserInput(name, value, e));
    if (UserInput.role !== 1 && UserInput.role !== 3) {
      dispatch(GetUserInput("partner_manager", ""));
    }
  };
  const handleSubmit = (data) => {
    dispatch(SubmitUserData(data));
  };
  const handleEdit = (data) => {
    dispatch(UpdateuserdData(data));
  };
  useEffect(() => {
    dispatch(GetCountryList());
    dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/`));
  }, []);
  useEffect(() => {
    if (afterUserData) {
      history.push(`/partner_sales`);
      dispatch(SetUserStatusFalse());
    }
  }, [afterUserData]);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const partnerList = () => {
    let partner = [];
    UserList?.results?.map((item) => {
      if (item.role === 2) {
        partner.push(item);
      }
    });
    return partner;
  };
  return (
    <div className="info-box text-capitalize">
      <div className="d-flex mb-4 heading justify-content-between align-items-center mt-4">
        <div className="d-flex heading" style={{marginBottom:"50px"}}>
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>
          {UserInput.id? (
            <p> Edit Sales Partner</p>
          ) : (
             <p> Add Sales Partner</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        {/* <div className="d-flex custify-content_center">
          <div className="mb-form">
            <div className="profile_image">
              <img src={UserInput.image_url} alt="" />
              <FaCamera className="image_icon" />
              <input
                id="outlined-basic"
                type="file"
                accept="image/png, image/gif, image/jpeg, .pdf. image/*"
                label="image"
                size="small"
                name="image"
                // value={UserInput.image}
                onChange={(e) => {
                  imageInput.owner = userData.id;
                  imageInput.doc_type = 0;
                  imageInput.document = e.target.files[0];
                  dispatch(SubmitImage(imageInput));
                  handleChangeInput("fileName", e.target.files[0].name);
                  let reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onloadend = (e) => {
                    // setPdfFile(e.target.result);
                    handleChangeInput("image_url", e.target.result);
                  };
                }}
              />
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={FirstName}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                name="first_name"
                value={UserInput.first_name}
                onChange={(e) => {
                  handleChangeInput("first_name", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={lastName}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="last_name"
                value={UserInput.last_name}
                onChange={(e) => {
                  handleChangeInput("last_name", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={email}
                error={
                  !regEmail.test(UserInput.email) &&
                  UserInput.email !== null &&  UserInput.email !== "" &&
                  true
                }
                helperText={
                  !regEmail.test(UserInput.email) &&
                  UserInput.email !== null &&  UserInput.email !== "" ?
                  "Invalid email." : ""
                }
                autoComplete="userName" // Disable autocomplete for email field
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                name="email"
                value={UserInput.email}
                onChange={(e) => {
                  handleChangeInput("email", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              {/* <TextField
                id="outlined-basic"
                type="password"
                label="Password"
                variant="outlined"
                size="small"
                name="password"
                value={UserInput.password}
                onChange={(e) => {
                  handleChangeInput("password", e.target.value);
                }}
              /> */}
              <FormControl
                sx={{ m: 1, width: "100%" }}
                size="small"
                variant="outlined"
              >
                {UserInput.id ?   <InputLabel htmlFor="">password</InputLabel> :   <InputLabel htmlFor="">{password}</InputLabel>}
              
                <OutlinedInput
                  id=""
                  autoComplete="new-password" // Disable autocomplete for email field
                  name="password"
                  value={UserInput.password}
                  onChange={(e) => {
                    handleChangeInput("password", e.target.value);
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
             
                onWheel={(e) => e.target.blur()}
                label={mobile}
                error={
                  !regexNumber.test(UserInput.mobile) &&
                  UserInput.mobile !== null &&  UserInput.mobile !== ""  &&
                  true
                }
                helperText={  !regexNumber.test(UserInput.mobile) &&
                  UserInput.mobile !== null &&  UserInput.mobile !== ""  ? "Invalid number." : ""}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                name="mobile"
                value={UserInput.mobile}
                onChange={(e) => {
                  handleChangeInput("mobile", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <div className="d-flex gap-3">
                <p className="fw-bold">Gender : </p>
                <div key={"6878"} className="mb-3">
                  <Form.Check
                    inline
                    label="Male"
                    name="gender"
                    checked={UserInput.gender === 0 && true}
                    type="radio"
                    value={UserInput.gender}
                    id={`inline-${"radio"}-1`}
                    onChange={(e) => {
                      handleChangeInput("gender", 0);
                    }}
                  />
                  <Form.Check
                    inline
                    label="Female"
                    name="gender"
                    checked={UserInput.gender === 1 && true}
                    value={UserInput.gender}
                    type="radio"
                    id={`inline-${"radio"}-1`}
                    onChange={(e) => {
                      handleChangeInput("gender", 1);
                    }}
                  />
                  <Form.Check
                    inline
                    label="Other"
                    name="gender"
                    checked={UserInput.gender === 2 && true}
                    value={UserInput.gender}
                    type="radio"
                    id={`inline-${"radio"}-1`}
                    onChange={(e) => {
                      handleChangeInput("gender", 2);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={role}
                name="role"
                value={UserInput.role}
                onChange={(e) => handleChangeInput("role", e.target.value)}
              >
                {user_role.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          {(UserInput.role === 1 || UserInput.role === 3) && (
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Partner Manager"
                name="partner_manager"
                value={UserInput.partner_manager}
                onChange={(e) =>
                  handleChangeInput("partner_manager", e.target.value)
                }
              >
                {partnerList().map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <>
                      {option.role === 2 &&
                        option.first_name + option.last_name}
                    </>
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
            )}
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                // select
                label="Contact "
                name="contact_id"
                value={UserInput.contact_id}
                onChange={(e) =>
                  handleChangeInput("contact_id", e.target.value)
                }
              >
                {/* {UserList?.results?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.first_name} {option.last_name}
                  </MenuItem>
                ))} */}
              </TextField>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                // select
                label="Partner "
                name="partner_id"
                value={UserInput.partner_id}
                onChange={(e) =>
                  handleChangeInput("partner_id", e.target.value)
                }
              >
               
              </TextField>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                placeholder="%"
                label={Upfront_Percentage}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                name="upfront_percentage"
                value={UserInput.upfront_percentage}
                onChange={(e) => {
                  handleChangeInput("upfront_percentage", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                placeholder="%"
                label={Residual_percentage}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="residual_percentage"
                value={UserInput.residual_percentage}
                onChange={(e) => {
                  handleChangeInput("residual_percentage", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <Autocomplete
                id="country-select-demo"
                size="small"
                options={countryList}
                autoHighlight
                onChange={(event, newValue) =>
                  handleChangeInput("country", newValue.id)
                }
                value={countryList?.[UserInput.country - 1] || null}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} ({option.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={country}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
          <button
              style={{ backgroundColor: "#00000061", padding: "6px 10px" }}
              className="    custom-btn  flex mx-2 "
              onClick={() => {
               history.push(`/partner_sales`)
              }}
            >
              Back
            </button>
            <button
              style={{ backgroundColor: "#325CC4", padding: "6px 10px" }}
              className="    custom-btn  flex mx-2 "
              onClick={() => {
                UserInput.id ? handleEdit(UserInput) : handleSubmit(UserInput);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalesPartner;
