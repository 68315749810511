import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../asstes/icon.bablu/add-file.svg";

import { useEffect, useState } from "react";

import cross from "../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";

import { ecom_provider } from "../commons/Dropdown";
import Button from "@mui/material/Button";
import {
  GetleadInput,
  GetEcommerceProductInput,
  GetLeadsnput,
} from "../../_redux/action/LeadsAction";
import { BASE_URL } from "../../../../../Const";
import { GetAllProductList } from "../newApplication/_redux/action/NewapplicationAction";
import {
  REMOVE_ECOMMERCE_PRODUCT,
  SET_ECOMMERCE_PRODUCT,
} from "../../_redux/types/Types";

function EcommerceProduct({type_name,id,lead_product}) {
  console.log('type_name',type_name, id)
  const epos_name = (
    <p>
      Provide EPOS Name <span className="required">*</span>
    </p>
  );
  const website = (
    <p>
      Website URL <span className="required">*</span>
    </p>
  );
  const dispatch = useDispatch();
  const [elavonProduct, setelavonProduct] = useState([]);

  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const allProductList = useSelector(
    (state) => state.applicationInfo.allProductList
  );
  // useEffect(() => {
  //   fetch(`${BASE_URL}api/v1/products/product/?provider=0&crm_product_type=0`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Token ${localStorage.getItem("access_token")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setelavonProduct(data.data.results));
  // }, []);



  const handleAddMore = () => {
    dispatch({ type: SET_ECOMMERCE_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_ECOMMERCE_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetEcommerceProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(
      GetEcommerceProductInput(name, value, index, "ecommerce_products")
    );
    dispatch(GetEcommerceProductInput(type_name, id, index,' ecommerce_products'));
    // if (name === "getway_provider" && value === 0) {
    //   dispatch(
    //     GetEcommerceProductInput("product_term", 0, index, "ecommerce_products")
    //   );
    // } else if (name === "getway_provider" && value === 1) {
    //   dispatch(
    //     GetEcommerceProductInput("product_term", 18, index, "ecommerce_products")
    //   );
    // }
  };

  const handleProductFill = (value, index) => {
    console.log("value", value);
    dispatch(
      GetEcommerceProductInput("product", value.id, index, "ecommerce_products")
    );
    dispatch(
      GetEcommerceProductInput(
        "connection_type",
        value?.connection_type[0]?.id,
        index
      )
    );

    // dispatch(
    //   GetEcommerceProductInput(
    //     "product_term",
    //     value.product_term[0].months,
    //     i
    //   )
    // );
    dispatch(
      GetEcommerceProductInput("terminal_model", value?.model_name, index)
    );
    dispatch(GetEcommerceProductInput("price", value?.price, index));
    dispatch(GetEcommerceProductInput("lead_id", leadInput?.id, index));
  };
  return (
    <>
      {leadInput?.ecommerce_products?.length < 1 && (
        <div className="d-flex justify-content-between heading mb-form mt-4 ">
          <button className="fill_add_button" onClick={() => handleAddMore()}>
            <img
              width={14}
              style={{ marginRight: "14px", marginBottom: "3px" }}
              height={14}
              src={addFileIcon}
              alt=""
            />
            ADD PRODUCT
          </button>
        </div>
      )}

      <div
        className="container row select_div"
        style={{ marginBottom: "-15px " }}
      >
        {leadInput?.ecommerce_products?.map((product, i) => {
          return (
            <>
              {product.is_deleted === false && (
                <div
                  className="col-12 col-md-6 mb-3 text-capitalize"
                  key={product.id}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "8px",
                      padding: "40px 15px",
                      margin: "15px 0px",
                      border: "0.5px solid rgb(224, 224, 224)",
                    }}
                  >
                    <div
                      // onClick={() => handleremoveDoc(i)}
                      onClick={() => {
                        product.id
                          ? handleremoveProductTwo(i)
                          : handleremoveProduct(i);
                      }}
                      className="cross d-flex"
                      style={{
                        border: "1px solid #FFCDD2",
                        width: "24px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={cross} alt="" />
                    </div>

                    <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Service Type: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <div >
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "ecom",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "ecom"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              Ecomm
                            </Button>
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "VT",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "VT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              Virtual Terminal
                            </Button>
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "ecom_VT",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "ecom_VT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                             Ecom & Virtual Terminal
                            </Button>
                          </div>
                        </div>
                      </div>
                    <div className="mb-form ">
                      <TextField
                        id="outlined-basic"
                        label={website}
                        variant="outlined"
                        size="small"
                        type="text"
                        name="website_url"
                        value={product.website_url}
                        onChange={(e) => {
                          handleChangeInput("website_url", e.target.value, i);
                        }}
                      />
                    </div>
                    <div className="mb-form">
                      <div className="col-lg-12 mb-4">
                        <TextField
                          size="small"
                          id="outlined-select-currency"
                          select
                          label="Getway provider"
                          name="getway_provider"
                          value={product.getway_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "getway_provider",
                              e.target.value,
                              i
                            );
                          }}
                        >
                          {ecom_provider?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    {/* <div className="mb-form">
                      {product.provider === 1 ? (
                        <div className="col-lg-12 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Search product"
                            name="product"
                            value={product?.product}
                            onChange={(e) => {
                              allProductList?.map((item, index) => {
                                if (item.id === e.target.value) {
                                  dispatch(
                                    GetEcommerceProductInput(
                                      "connection_type",
                                      item?.connection_type[0]?.id,
                                      i
                                    )
                                  );

                                  dispatch(
                                    GetEcommerceProductInput(
                                      "terminal_model",
                                      item?.model_name,
                                      i
                                    )
                                  );
                                  dispatch(
                                    GetEcommerceProductInput("price", item?.price, i)
                                  );
                                  dispatch(
                                    GetEcommerceProductInput(
                                      "lead",
                                      leadInput?.id,
                                      i
                                    )
                                  );
                                }
                              });

                              handleChangeInput("product", e.target.value, i);
                            }}
                          >
                            {allProductList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      ) : (
                        product.provider === 0 && (
                          <div className="col-lg-12 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Search product"
                              name="product"
                              value={product?.product}
                              onChange={(e) => {
                                elavonProduct?.map((item, index) => {
                                  if (item.id === e.target.value) {
                                    dispatch(
                                      GetEcommerceProductInput(
                                        "connection_type",
                                        item?.connection_type[0]?.id,
                                        i
                                      )
                                    );

                                    dispatch(
                                      GetEcommerceProductInput(
                                        "terminal_model",
                                        item?.model_name,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetEcommerceProductInput(
                                        "price",
                                        item?.price,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetEcommerceProductInput(
                                        "lead",
                                        leadInput?.id,
                                        i
                                      )
                                    );
                                  }
                                });

                                handleChangeInput("product", e.target.value, i);
                              }}
                            >
                              {elavonProduct?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option?.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )
                      )}
                    </div> */}
                    {/* <div className="">
                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Connection Type"
                            name="connection_type"
                            value={product.connection_type}
                            onChange={(e) =>
                              handleChangeInput(
                                "connection_type",
                                e.target.value,
                                i
                              )
                            }
                          >
                            {product.provider === 0 &&
                              elavonProduct?.map((option) =>
                                option.id === product.product
                                  ? option.connection_type.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : null
                              )}
                            {product.provider === 1 &&
                              allProductList?.map((option) =>
                                option.id === product.product
                                  ? option.connection_type.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : null
                              )}
                          </TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            disabled={product.provider === 0 && true}
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Contact Length"
                            name="product_term"
                            value={product.product_term}
                            onChange={(e) =>
                              handleChangeInput(
                                "product_term",
                                e.target.value,
                                i
                              )
                            }
                          >
                            {connectionLength?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            //   select
                            label="Terminal model"
                            name="terminal_model"
                            value={
                              product.terminal_model
                                ? product.terminal_model
                                : ""
                            }
                            onChange={(e) => {
                              handleChangeInput(
                                "terminal_model",
                                e.target.value,
                                i
                              );
                            }}
                          ></TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            label="Monthly price"
                            name="price"
                            InputLabelProps={{ shrink: true }}
                            value={product.price}
                            onChange={(e) =>
                              handleChangeInput("price", e.target.value, i)
                            }
                          />
                        </div>
                        <div className="col-lg-6 ">
                          <TextField
                            size="small"
                            type="number"
                            id="outlined-select-currency"
                            label="Quantity"
                            name="qty"
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            value={product.qty}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            error={product.qty < 1}
                            onChange={(e) =>
                              handleChangeInput("qty", e.target.value, i)
                            }
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </>
          );
        })}
        {leadInput?.ecommerce_products?.length > 0 && (
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{ marginBottom: "40px" }}
          >
            <button className="fill_add_button" onClick={() => handleAddMore()}>
              <img
                width={14}
                style={{ marginRight: "14px", marginBottom: "3px" }}
                height={14}
                src={addFileIcon}
                alt=""
              />
              ADD PRODUCT
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default EcommerceProduct;
