import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from "react-redux";
import { LeadStatus } from "../../../allAplication/mainLead/components/commons/LeadStatus";
import { OpportunityStatus } from "../../../allAplication/mainLead/components/commons/Dropdown";
import { GetLeadsnput, GetPriceQuoteList, SetPricequoteUpdatedData } from "../../../allAplication/mainLead/_redux/action/LeadsAction";
import { useHistory } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
const UserQuoteList = () => {
  const dispatch =useDispatch()
  const history = useHistory()
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const priceQuoteList = useSelector((state) => state.leadInfo.priceQuoteList);
  // const toggleShowAllData = () => {
  //   setAllDataLoaded(!allDataLoaded);
  // };
  const handleNexteClick = (pageNo) => {
   
    dispatch(GetPriceQuoteList(priceQuoteList?.next));
  };
  const handlePreviousClick = (pageNo) => {
   
    dispatch(GetPriceQuoteList(priceQuoteList?.previous));
  };
  const handleView = (data) => {
    dispatch(SetPricequoteUpdatedData(data));
    dispatch(GetLeadsnput("step", 2));
    history.push(`/viewlead/${data}`);
  };
  return (
    <div className="product-card">
      <div className="product-card-header">
        <h4>Price Quote List</h4>
      </div>
      <div className="product-card-body">
      <Table className="product-table" responsive>
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Legal Name</th>
            <th>Trading Name</th>
            <th>Quote Status</th>
            <th  style={{textAlign:"center"}}>Action</th>
          </tr>
        </thead>
        {priceQuoteList?.results?.length < 1 && (
            <tr>
              <td colspan="12">
                <div style={{background:"#17479d1f"}}
                  class="alert alert-success text-center mt-5 mb-5"
                  role="alert"
                >
                  Sorry ! No data found.
                </div>
              </td>
            </tr>
          )}
        <tbody>
          {priceQuoteList?.results
            ?.slice(0, allDataLoaded ? priceQuoteList?.count : 3)
            .map((item) => (
              <tr key={item.id}>
                <td>{item.client_id}</td>
                <td>{item.legal_name}</td>
                <td>{item.trading_name}</td>
                <td>{ OpportunityStatus[item.opportunity_status] === undefined
                ? "Warning!!!Add New Status to leadStatus"
                : OpportunityStatus[item.opportunity_status].toString()}</td>
              <td  align="center"  onClick={() => handleView(item.id)} >
                    <BsEyeFill className="view_icon"/>
                  </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* {priceQuoteList?.count > 3 && ( */}
            <div className="load-more-button-container gap-2">
            <p>Total : <span>{priceQuoteList?.count}</span></p>
            <button
              className={` ${
                priceQuoteList?.previous ? "load-more-button" : "disable-button"
              }`}
              onClick={handlePreviousClick}
            >
              Prev
            </button>
            <button
              className={` ${
                priceQuoteList?.next ? "load-more-button" : "disable-button"
              }`}
              onClick={handleNexteClick}
            >
              Next
            </button>
            {/* <button className="load-more-button" onClick={toggleShowAllData}>
              {allDataLoaded ? "See Less" : "See More"}
            </button> */}
          </div>
        {/* // )} */}
      </div>
     
    </div>
  );
};

export default UserQuoteList;
