import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetCountryList, GetLeadsnput } from "../../_redux/action/LeadsAction";

const Number = ({
  text,
  placeholder,
  colorBg,
  value,
  name,
  onchangevalue,
  onchangeFlag,
  flag_value,
  flag_name,
}) => {
  const dispatch = useDispatch();
  const [codeId, setCodeId] = useState("GB");
  const [ide, setIde] = useState("+44");
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));
  };
  useEffect(() => {
    dispatch(GetCountryList(1));
  }, []);
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <div
      // className="col-12 mb-form d-flex  px-2 main-box-auc"
      className={` ${
        !value?.match(phoneno) && value !== "" && value !== null && value?.length !== 10
          ? "col-12 mb-form d-flex  px-2 main-box-error"
          : "col-12 mb-form d-flex  px-2 main-box-auc"
      }`}
      style={{
        zIndex: "2",
        borderRadius: "8px",
        position: "relative",
        paddingTop: "4px",
        paddingBottom: "4px",
      }}
    >
      <div
        style={{
          position: "absolute",
          background: `${colorBg}`,
          top: "-24%",
          left: "2%",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            color: "#1976d2",
            padding: "0px 4px 0px 2px",
          }}
        >
          {text}
        </p>
      </div>
      {codeId ? (
        <img
          loading="lazy"
          src={`https://flagcdn.com/w20/${codeId.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${codeId.toLowerCase()}.png 2x`}
         
          alt=""
          className="ms-lg-2 flag-img me-lg-4 ms-0 me-1 "
          style={{ marginTop: "8px", zIndex:1 }}
        />
      ) : (
        <img
          loading="lazy"
          src={`https://flagcdn.com/w20/gb.png`}
          srcSet={`https://flagcdn.com/w40/gb.png`}
          alt=""
          className="ms-2 me-4 flag-img"
          style={{ marginTop: "8px" }}
        />
      )}
      {!isLoadLeads &&
        countryList &&
        countryList !== null &&
        countryList.length > 0 && (
          <Autocomplete
            id="product_search"
            value={flag_value}
            onChange={onchangeFlag}
            inputValue={ide}
            onInputChange={(event, newInputValue) => {
              // setIde(newInputValue);
            
              // countryList.map(
              //   (op) => op.phone_code === newInputValue.split(" ")[1] && setCodeId(op.iso2)
              // );

            
              if (newInputValue !== "") {
                setIde(newInputValue);
                var cc = newInputValue?.split(" ")[0];
                var ph_code = newInputValue?.split(" ")[1];
                for (var i = 0; i < countryList.length; i++) {
                  if (
                    countryList[i]["iso2"] === cc &&
                    countryList[i]["phone_code"] === ph_code
                  ) {
                  
                    setIde(newInputValue);
                    setCodeId(countryList[i]["iso2"]);
                    dispatch(GetLeadsnput("flag_code", countryList[i]["iso2"]));
                   
                  }
                }
              } else setIde(newInputValue);
            }}
            options={countryList.map(
              (curr) => curr.iso2 + " " + curr.phone_code
            )}
            className="auc"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="standard"
                  // outline="none"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true, // <== added this
                  }}
                  error={value?.match(phoneno) && value !== "" && true}
                  // name={flag_name}
                  className="px-md-0 px-lg-2 inp-auc"
                  style={{ borderColor: "transparent !important" }}
                  sx={{
                    "& label.Mui-focused": {
                      color: "white",
                      borderColor: "transparent",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              );
            }}
          />
        )}

      <input
        error={value?.match(phoneno) && value !== "" && true}
        type="text"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onchangevalue}
        style={{
          border: "0",
          outline: "none",
          backgroundColor: "transparent",
          overflow: "hidden",
        }}
      />
    </div>
  );
};

export default Number;
