import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../asstes/icon.bablu/add-file.svg";
import pdf from "../../../../../asstes/icon.bablu/pdf.png";
import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

import { DELETE_DOC, SET_NEW_DOC_INPUT } from "./_redux/types/Types";
import cross from "../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import upload from "../../../../../asstes/psImg/upload.png";

import "react-toastify/dist/ReactToastify.css";
import { GetDocInput } from "./_redux/action/NewapplicationAction";
import {
  check_proof_of_application,
  check_proof_of_business,
  check_proof_of_home_add,
  check_proof_of_id,
  check_proof_of_other,
  PROOF_OF_BANK,
} from "../commons/Dropdown";
import PreviewPdf from "../PriceQuote/PreviewPdf";
import Modal from "react-bootstrap/Modal";
import { saveAs } from "file-saver";
import { Download } from "@mui/icons-material";

function Files({ next }) {
  const names = [
    {
      label: "PROOF OF ID",
      value: "PROOF_OF_ID",
    },
    {
      label: "PROOF OF BUSINESS",
      value: "PROOF_OF_BUSINESS",
    },
    {
      label: "PROOF OF BANK",
      value: "PROOF_OF_BANK",
    },
    {
      label: "PROOF OF ADDRESS",
      value: "PROOF_OF_ADDRESS",
    },
    {
      label: "APPLICATION DOCUMENTS",
      value: "APPLICATION_DOCUMENTS",
    },
    {
      label: "OTHER",
      value: "OTHER",
    },
  ];

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const handleClose = () => setShow(false);
  const handleShow = (url) => {
    if (url.includes(".pdf")) {
      window.open(url, "_blank");
    } else {
      setShow(true);
      setPdfFile(url);
    }
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const handleAddMore = () => {
    dispatch({ type: SET_NEW_DOC_INPUT, payload: false });
  };
  const handleremoveDoc = (i) => {
    dispatch({ type: DELETE_DOC, payload: i });
  };

  const handleremovedocument = (i) => {
    dispatch(GetDocInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index, e) => {
    dispatch(GetDocInput(name, value, index, e, "application_docs"));
    dispatch(GetDocInput("application", applicationInput.id, index));
  };
  const downloadImage = (image_url) => {
    saveAs(image_url, "document_image"); // Put your image url here.
  };

  return (
    <>
      <div className="form-wrapper mx-0 mx-md-4" style={{marginBottom:"100px"}}>
        <div className="d-flex justify-content-between heading mb-form ">
          <div className="d-flex file_text mx-0 mx-md-3">
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                fill="#0D0D0D"
              />
            </svg>
            <p style={{ marginBottom: "10px" }}>Upload Files </p>
          </div>
          {applicationInput?.application_docs?.length < 1 && (
            <div>
              <button
                className="fill_add_button"
                // onClick={() => addItem("<UploadersTwo/>")}
                onClick={() => handleAddMore()}
              >
                <img
                  width={14}
                  style={{ marginRight: "14px", marginBottom: "3px" }}
                  height={14}
                  src={addFileIcon}
                  alt=""
                />
                ADD FILE
              </button>
            </div>
          )}
        </div>
        <div
          className="container row select_div"
          style={{ marginBottom: "-15px " }}
        >
          {applicationInput?.application_docs?.map((doc, i) => {
            return (
              <>
                {doc.is_deleted === false && (
                  <div
                    className="col-12 col-md-6 mb-3"
                    key={doc.id}
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(250, 250, 250)",
                        borderRadius: "8px",
                        padding: "40px 15px",
                        margin: "15px 0px",
                        border: "0.5px solid rgb(224, 224, 224)",
                      }}
                    >
                      <div
                        // onClick={() => handleremoveDoc(i)}
                        onClick={() => {
                          doc.id ? handleremovedocument(i) : handleremoveDoc(i);
                        }}
                        className="cross d-flex"
                        style={{
                          border: "1px solid #FFCDD2",
                          width: "24px",
                          borderRadius: "50%",
                        }}
                      >
                        <img src={cross} alt="" />
                      </div>
                      <div className="row">
                        <div className="mb-form col-lg-12">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="File Name"
                            name="category"
                            value={doc.category}
                            onChange={(e) =>
                              handleChangeInput("category", e.target.value, i)
                            }
                          >
                            {names.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="mb-form col-lg-12">
                          {doc.category === "PROOF_OF_ID" ? (
                            <>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  select
                                  label="Document Type"
                                  name="doc_type"
                                  value={doc.doc_type}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "doc_type",
                                      e.target.value,
                                      i
                                    )
                                  }
                                  helperText={
                                    doc.doc_type === "PASSPORT"
                                      ? "N.B. Should be visible on all corners with MRZ Number & Signature. Should not be expired"
                                      : doc.doc_type ===
                                        "FULL_PAPER_DRIVING_LICENCE"
                                      ? "N.B. Should be visible on all corners. Should not be expired."
                                      : doc.doc_type ===
                                        "EU_RESIDENCE_CARD_FIVE_YEAR"
                                      ? "N.B. Validity should be at least 5 years."
                                      : doc.doc_type ===
                                          "SPANISH_RESIDENT_CARD" &&
                                        "N.B. This should be validet."
                                  }
                                >
                                  {check_proof_of_id.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  label="First Name "
                                  name="first_name"
                                  value={doc.first_name}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "first_name",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  label="Sur Name "
                                  name="sur_name"
                                  value={doc.sur_name}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "sur_name",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  label="Document Id"
                                  name="document_id"
                                  value={doc.document_id}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "document_id",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  label="Issuer Id"
                                  name="issuer_id"
                                  value={doc.issuer_id}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "issuer_id",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  id="outlined-select-currency"
                                  type="date"
                                  InputLabelProps={{ shrink: true }}
                                  label="Issue Date "
                                  name="issue_date"
                                  value={doc.issue_date}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "issue_date",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form ">
                                <TextField
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  id="outlined-select-currency"
                                  type="date"
                                  label="Expiry Date"
                                  name="expiry_date"
                                  value={doc.expiry_date}
                                  onChange={(e) =>
                                    handleChangeInput(
                                      "expiry_date",
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-form">
                                <Autocomplete
                                  id="country-select-demo"
                                  size="small"
                                  options={countryList}
                                  onChange={(event, newValue) =>
                                    handleChangeInput("country", newValue.id, i)
                                  }
                                  value={countryList?.[doc.country - 1] || null}
                                  autoHighlight
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      {option.name} ({option.iso2})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                      }}
                                      name="trading_country"
                                      // value={countryList?.[priceQuoteInput.trading_country] || null}
                                    />
                                  )}
                                />
                              </div>
                            </>
                          ) : doc.category === "PROOF_OF_BUSINESS" ? (
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Document Type"
                              name="doc_type"
                              value={doc.doc_type}
                              onChange={(e) =>
                                handleChangeInput("doc_type", e.target.value, i)
                              }
                              helperText={
                                doc.doc_type === "BUSINESS_UTILITY_BILL"
                                  ? "N.B. Under the Business Name (Trading Name for Sole Trade/Partnership business and Legal/Trading Name for Limited Company) and Trading address. Documents within 6 months. Should not be any invoice."
                                  : doc.doc_type === "BUSINESS_RATES_BILL"
                                  ? "This should be validate "
                                  : doc.doc_type === "BUSINESS_BANK_STATEMENTS"
                                  ? "This should be validate "
                                  : doc.doc_type === "HMRC_VAT_OR_TAX_DEMAND"
                                  ? "This should be validate "
                                  : doc.doc_type === "MARKET_TRADER_CERTIFICATE"
                                  ? "This should be validate "
                                  : doc.doc_type ===
                                      "BUSINESS_BANKING_COMMUNICATION" &&
                                    "This should be validate "
                              }
                            >
                              {check_proof_of_business.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : doc.category === "PROOF_OF_BANK" ? (
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Document Type"
                              name="doc_type"
                              value={doc.doc_type}
                              onChange={(e) =>
                                handleChangeInput("doc_type", e.target.value, i)
                              }
                              helperText={
                                doc.doc_type === "BUSINESS_BANK_STATEMENTS"
                                  ? "N.B Should be visible Bank Name, Account Name, Account Number, Sortcode. Documents within 6 months with Date."
                                  : doc.doc_type === "VOIDED_CHEQUE"
                                  ? "N.B. Should be visible Bank Name, Account Name, Account Number & Sortcode. "
                                  : doc.doc_type === "PAYING_IN_SLIP"
                                  ? "N.B. This should be validate "
                                  : doc.doc_type ===
                                    "BUSINESS_BANKING_COMMUNICATION_LETTER"
                                  ? "N.B. This should be validate "
                                  : doc.doc_type ===
                                      "BUSINESS_BANK_WELCOME_LETTER" &&
                                    "N.B. This should be validate "
                              }
                            >
                              {PROOF_OF_BANK.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : doc.category === "PROOF_OF_ADDRESS" ? (
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Document Type"
                              name="doc_type"
                              value={doc.doc_type}
                              onChange={(e) =>
                                handleChangeInput("doc_type", e.target.value, i)
                              }
                              helperText={
                                doc.doc_type === "FULL_PHOTO_DRIVING_LICENCE"
                                  ? "N.B. Should be visible on all corners. Should not be expired."
                                  : doc.doc_type ===
                                    "PROVISIONAL_PHOTO_DRIVING_LICENCE"
                                  ? "N.B. Should be visible on all corners. Should not be expired. "
                                  : doc.doc_type === "UTILITY_BILL"
                                  ? "N.B.  Under Merchant's Name and Home Address. Documents within 6 months with Date. Should not be any invoice."
                                  : doc.doc_type === "BANK_STATEMENT"
                                  ? "N.B. Under Merchant's Name & Home Address. Documents within 6 months with Date."
                                  : doc.doc_type ===
                                    "COUNCIL_TAX_CORRESPONDANCE"
                                  ? "N.B. This should be validate "
                                  : doc.doc_type === "MORTGAGE_STATEMENT" &&
                                    "N.B. This should be validate "
                              }
                            >
                              {check_proof_of_home_add.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : doc.category === "APPLICATION_DOCUMENTS" ? (
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Document Type"
                              name="doc_type"
                              value={doc.doc_type}
                              onChange={(e) =>
                                handleChangeInput("doc_type", e.target.value, i)
                              }
                            >
                              {check_proof_of_application.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            doc.category === "OTHER" && (
                              <TextField
                                size="small"
                                id="outlined-select-currency"
                                select
                                label="Document Type"
                                name="doc_type"
                                value={doc.doc_type}
                                onChange={(e) =>
                                  handleChangeInput(
                                    "doc_type",
                                    e.target.value,
                                    i
                                  )
                                }
                              >
                                {check_proof_of_other.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )
                          )}
                          {/* {check_proof_of_id.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          </TextField> */}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="">
                          <div className="">
                            <div className="file-uploader-mask d-flex justify-content-center align-items-center">
                              <img
                                className="file-uploader-icon"
                                src={upload}
                                alt="Upload-Icon"
                              />
                              <h5 className="drag-drop my-3">
                                {" "}
                                Drag & drop file here
                              </h5>
                              <p style={{ color: "red", fontSize: "12px" }}>
                                File format should be image or Pdf
                              </p>
                              <div className="file-input-display">
                                <div className="chooseFile">
                                  <p>ChooseFile</p>
                                </div>
                                {/* <p className="subText">Maximum Size: 5 MB</p> */}
                              </div>
                              <input
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  opacity: "0",
                                  position: "absolute",
                                }}
                                type="file"
                                accept="image/png, image/gif, image/jpeg, .pdf"
                                name="document"
                                onChange={(e) => {
                                  doc.owner = userData.id;

                                  handleChangeInput(
                                    "document",
                                    e.target.files[0],
                                    i,
                                    e
                                  );

                                  handleChangeInput(
                                    "fileName",
                                    e.target.files[0].name,
                                    i
                                  );

                                  let reader = new FileReader();
                                  reader.readAsDataURL(e.target.files[0]);
                                  reader.onloadend = (e) => {
                                    handleChangeInput(
                                      "docFile_url",
                                      e.target.result,
                                      i,
                                      e
                                    );
                                  };
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column align-items-center">
                              <>
                                {doc?.fileName ? (
                                  <small
                                    className="my-4"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {doc.fileName}
                                  </small>
                                ) : (
                                  <small style={{ marginTop: "10px" }}>
                                    {doc.document && (
                                    <img
                                      onClick={() => handleShow(doc.document)}
                                      src={
                                        doc.document.includes("pdf")
                                          ? pdf
                                          : doc.document
                                      }
                                      alt=""
                                      style={{
                                        height: "44px",
                                        width: "44px",
                                        borderRadius: "8px",
                                        objectFit: "cover",
                                        cursor:"pointer"
                                      }}
                                    />
                                    )}
                                  </small>
                                )}
                              </>
                              <div className="d-flex justify-content-center">
                                {doc.docFile_url &&
                                doc?.docFile_url?.includes("/pdf") ? (
                                  <img
                                    onClick={() => handleShow(doc.docFile_url)}
                                    src={pdf}
                                    alt=""
                                    style={{
                                      cursor: "pointer",
                                      height: "44px",
                                      width: "44px",
                                      borderRadius: "8px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  doc.docFile_url && (
                                    <img
                                      onClick={() =>
                                        handleShow(doc.docFile_url)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        height: "44px",
                                        width: "44px",
                                        borderRadius: "8px",
                                        objectFit: "cover",
                                      }}
                                      src={doc.docFile_url}
                                      alt=""
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}

          {applicationInput?.application_docs?.length > 0 && (
            <div className="col-md-6  d-flex align-items-center justify-content-center" >
              <button
                className="fill_add_button"
                // onClick={() => addItem("<UploadersTwo/>")}
                onClick={() => handleAddMore()}
              >
                <img
                  width={14}
                  style={{ marginRight: "14px", marginBottom: "3px" }}
                  height={14}
                  src={addFileIcon}
                  alt=""
                />
                ADD FILE
              </button>
            </div>
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body id="contained-modal-title-vcenter">
            {pdfFile && pdfFile?.includes("data:application/pdf") ? (
              <PreviewPdf pdf={pdfFile} />
            ) : (
              <>
                <img
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    width: "100%",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                  src={pdfFile}
                  alt=""
                />
                <div
                  className="d-flex justify-content-end mt-4"
                  onClick={() => downloadImage(pdfFile)}
                >
                  <button className="save-btn">
                    <Download />
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Files;
