import React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Number from "./Number";
import { useDispatch, useSelector } from "react-redux";
import {
  Application,
  internet_service_provider,
  LegalType,
  payment_service_provider,
} from "../commons/Dropdown";
import { GetPricequoteInput } from "../../_redux/action/LeadsAction";
import Button from "@mui/material/Button";
import { GetCompanyHouseDetails } from "../newApplication/_redux/action/NewapplicationAction";

const BusinessInformation = () => {
  const dispatch = useDispatch();
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
 
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const company_house_no = (
    <p>
      Company House No<span className="required">*</span>
    </p>
  );
  const AcquiringBank = (
    <p>
      Acquiring Bank <span className="required">*</span>
    </p>
  );
 

  const Acquiring_Bank = [
    {
      value: 0,
      label: "Elavon",
    },
  ];

  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const companyHouseList = useSelector(
    (state) => state.applicationInfo.companyHouseList
  );

  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
    dispatch(
      GetPricequoteInput("opportunity_name", priceQuoteInput.legal_name)
    );
  };

  const handleSelectButton = (name, i) => {
    dispatch(GetPricequoteInput(name, i));
  };
  const getCountryIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === priceQuoteInput.mobile_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getCountryTelephoneIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === priceQuoteInput.phone_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  
  const [binCheck, setbinCheck] = React.useState(!priceQuoteInput.security_bin_check);
  const [velocity, setVelocity] = React.useState(!priceQuoteInput.security_velocity_check);
  const [ipCheck, setIpCheck] = React.useState(!priceQuoteInput.security_ip_geo_check);
  const [maxAmount, setMaxAmount] = React.useState(!priceQuoteInput.security_max_amt_check);
  const [other, setOther] = React.useState(!priceQuoteInput.security_other_check);

  const handleSelectBinCheck = () => {
    setbinCheck(!binCheck);
    dispatch(GetPricequoteInput("security_bin_check", binCheck));
  };
  const handleSelectVelocityCheck = () => {
    setVelocity(!velocity);
    dispatch(GetPricequoteInput("security_velocity_check", velocity));
  };
  const handleSelectipGeo = () => {
    setIpCheck(!ipCheck);
    dispatch(GetPricequoteInput("security_ip_geo_check", ipCheck));
  };
  const handleSelectmaxAmount = () => {
    setMaxAmount(!maxAmount);
    dispatch(GetPricequoteInput("security_max_amt_check", maxAmount));
  };
  const handleSelectother = () => {
    setOther(!other);
    dispatch(GetPricequoteInput("security_other_check", other));
  };

  return (
    <>
      <div className="">
        <div className="mt-4">
          <div className="row">
            <div className="d-flex heading mb-4">
              <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                  fill="#0D0D0D"
                />
              </svg>

              <p>Business Information</p>
            </div>
            <div className="col-12 col-md-6">
              <div className="info-box text-capitalize">
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={AcquiringBank}
                    variant="outlined"
                    select
                    size="small"
                    name="acquiring_bank"
                    value={priceQuoteInput.acquiring_bank}
                    onChange={(e) =>
                      handleChangeInput("acquiring_bank", e.target.value)
                    }
                  >
                    {Acquiring_Bank.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Application Type"
                    name="application_type"
                    value={priceQuoteInput.application_type}
                    onChange={(e) =>
                      handleChangeInput("application_type", e.target.value)
                    }
                  >
                    {Application.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {priceQuoteInput.application_type === 4 ? (
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Cole From"
                      variant="outlined"
                      size="small"
                      name="cole_from"
                      value={priceQuoteInput.cole_from}
                      onChange={(e) => {
                        handleChangeInput("cole_from", e.target.value);
                      }}
                    />
                  </div>
                ) : priceQuoteInput.application_type === 2 ? (
                  <>
                  <div className="mb-form">
                    <div className="row ">
                      <div className="col-md-12 col-lg-12">
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#333333",
                          }}
                        >
                          Which of the following security checks do you
                          undertake?:{" "}
                        </p>
                      </div>
                      <div className="col-md-12 col-lg-12 mt-4">
                        <div className="d-md-flex gap-3 flex-md-column">
                          <Button
                            onClick={() => handleSelectBinCheck()}
                            size="small"
                            variant={
                              priceQuoteInput.security_bin_check === true
                                ? "contained"
                                : "outlined"
                            }
                            // variant="outlined"
                          >
                            BIN Checks
                          </Button>
                          <Button
                            onClick={() => handleSelectVelocityCheck()}
                            size="small"
                            variant={
                              priceQuoteInput.security_velocity_check ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Velocity checks
                          </Button>

                          <Button
                            onClick={() => handleSelectipGeo()}
                            size="small"
                            variant={
                              priceQuoteInput.security_ip_geo_check ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            IP/GEO checks
                          </Button>
                          <Button
                            onClick={() => handleSelectmaxAmount()}
                            size="small"
                            variant={
                              priceQuoteInput.security_max_amt_check ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Maximum Amount checks
                          </Button>
                          <Button
                            onClick={() => handleSelectother()}
                            size="small"
                            variant={
                              priceQuoteInput.security_other_check === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Other
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Payment Service Provider"
                      variant="outlined"
                      size="small"
                      select
                      name="payment_service_provider"
                      value={priceQuoteInput.payment_service_provider}
                      onChange={(e) => {
                        handleChangeInput(
                          "payment_service_provider",
                          e.target.value
                        );
                      }}
                    >
                      {payment_service_provider.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Internet Service Provider"
                      variant="outlined"
                      size="small"
                      select
                      name="internet_service_provider"
                      value={priceQuoteInput.internet_service_provider}
                      onChange={(e) => {
                        handleChangeInput(
                          "internet_service_provider",
                          e.target.value
                        );
                      }}
                    >
                      {internet_service_provider.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </>
                ) : priceQuoteInput.application_type === 5 ||
                  priceQuoteInput.application_type === 7 ? (
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Existing Mid"
                      variant="outlined"
                      size="small"
                      // name="cole_from"
                      // value={priceQuoteInput.cole_from}
                      // onChange={(e) => {
                      //   handleChangeInput("cole_from", e.target.value);
                      // }}
                    />
                  </div>
                ) : (
                  priceQuoteInput.application_type === 6 && (
                    <>
                      <div className="mb-form">
                        <TextField
                          id="outlined-basic"
                          label="Existing Mid"
                          variant="outlined"
                          size="small"
                          // name="cole_from"
                          // value={priceQuoteInput.cole_from}
                          // onChange={(e) => {
                          //   handleChangeInput("cole_from", e.target.value);
                          // }}
                        />
                      </div>
                      <div className="mb-form">
                        <TextField
                          id="outlined-basic"
                          label="Cole From"
                          variant="outlined"
                          size="small"
                          name="cole_from"
                          value={priceQuoteInput.cole_from}
                          onChange={(e) => {
                            handleChangeInput("cole_from", e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )
                )}

                {/* <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={OpportunityID}
                    variant="outlined"
                    size="small"
                    name="opportunity_id"
                    value={priceQuoteInput.opportunity_id}
                    onChange={(e) =>
                      handleChangeInput("opportunity_id", e.target.value)
                    }
                  />
                </div> */}
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Opportunity Name"
                    variant="outlined"
                    size="small"
                    name="opportunity_name"
                    value={priceQuoteInput.legal_name}
                    onChange={(e) =>
                      handleChangeInput("opportunity_name", e.target.value)
                    }
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    size="small"
                    error={
                      new Date().getFullYear() -
                        new Date(priceQuoteInput.dob).getFullYear() <
                        10 &&
                      priceQuoteInput.dob !== "" &&
                      true
                    }
                    InputLabelProps={{ shrink: true }}
                    name="dob"
                    value={priceQuoteInput.dob}
                    onChange={(e) => handleChangeInput("dob", e.target.value)}
                  />
                </div>
                <div className="mb-form">
                  <div className="mb-form">
                    <TextField
                      size="small"
                      id="outlined-select-currency"
                      label="Lead Source"
                      select
                      name="lead_source"
                      value={priceQuoteInput.lead_source}
                      onChange={(e) =>
                        handleChangeInput("lead_source", e.target.value)
                      }
                    >
                      {sourceList?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    options={companyHouseList}
                    inputValue={priceQuoteInput.legal_name}
                    autoHighlight
                    onChange={(event, newValue) => {
                      handleChangeInput("legal_name", newValue === null ? "" : newValue.title);
                      dispatch(GetCompanyHouseDetails(newValue === null ? "" : newValue.title));
                    }}
                    // value={
                    //   companyHouseList?.[leadInput.legal_name] || null
                    // }
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.title}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Legal Name"
                        onChange={(e) => {
                          handleChangeInput("legal_name", e.target.value);
                          dispatch(GetCompanyHouseDetails(e.target.value));
                        }}
                        value={
                          companyHouseList?.[priceQuoteInput.legal_name] || null
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Legal Type"
                    name="legal_type"
                    value={priceQuoteInput.legal_type}
                    onChange={(e) =>
                      handleChangeInput("legal_type", e.target.value)
                    }
                  >
                    {LegalType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {priceQuoteInput.legal_type === "CH" && (
                  <TextField
                    id="outlined-basic"
                    label="Charity no"
                    variant="outlined"
                    size="small"
                    name="charity_number"
                    value={priceQuoteInput.charity_number}
                    onChange={(e) =>
                      handleChangeInput("charity_number", e.target.value)
                    }
                  />
                )}
                <div className="mb-form">
                  {priceQuoteInput.legal_type === "L" ||
                  priceQuoteInput.legal_type === "LLP" ? (
                    <TextField
                      id="outlined-basic"
                      label={company_house_no}
                      variant="outlined"
                      size="small"
                      name="company_house_no"
                      value={priceQuoteInput.company_house_no}
                      onChange={(e) =>
                        handleChangeInput("company_house_no", e.target.value)
                      }
                    />
                  ) : (
                    priceQuoteInput.legal_type === "PL" && (
                      <TextField
                        id="outlined-basic"
                        label={company_house_no}
                        variant="outlined"
                        size="small"
                        name="company_house_no"
                        value={priceQuoteInput.company_house_no}
                        onChange={(e) =>
                          handleChangeInput("company_house_no", e.target.value)
                        }
                      />
                    )
                  )}
                </div>

                {/* -------------demo-------------------- */}

                {/* <Number text={"Phone Number"} /> */}
                {/* -----------------demo -----------------*/}
              </div>
            </div>
            {/* nother */}
            <div className=" col-md-6">
              <div className="info-box">
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Trading Name"
                    variant="outlined"
                    size="small"
                    name="trading_name"
                    value={priceQuoteInput.trading_name}
                    onChange={(e) =>
                      handleChangeInput("trading_name", e.target.value)
                    }
                  />
                </div>
                {/* <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Secondary Email"
                    variant="outlined"
                    size="small"
                  />
                </div> */}

                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Industry Type"
                    name="industry_type"
                    value={priceQuoteInput.industry_type}
                    onChange={(e) => {
                      handleChangeInput("industry_type", e.target.value);
                    }}
                  >
                    {IndustryList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="MCC Code"
                    name="mcc_code"
                    value={priceQuoteInput.industry_type}
                    inputProps={{ readOnly: true }}
                    onChange={(e) => {
                      handleChangeInput("mcc_code", e.target.value);
                    }}
                  >
                    {IndustryList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.mcc_iso}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* =============Using React Input=========  */}
                <Number
              text={"Mobile Number"}
              colorBg={"white"}
              name="mobile"
              value={priceQuoteInput.mobile}
              onchangevalue={(e) => handleChangeInput("mobile", e.target.value)}
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetPricequoteInput("mobile_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryIndex()]?.phone_code || "GB 44"
              }
              flag_name="mobile_code"
            />

<Number
              text={"Telephone Number"}
              colorBg={"white"}
              name="telephone"
              value={priceQuoteInput.telephone}
              onchangevalue={(e) =>
                handleChangeInput("telephone", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetPricequoteInput("phone_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryTelephoneIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                "GB 44"
              }
              flag_name="phone_code"
            />
                {/* =============Using React Input=========  */}

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Contact"
                    variant="outlined"
                    size="small"
                    name="contact"
                    value={
                      priceQuoteInput.first_name +
                      " " +
                      priceQuoteInput.last_name
                    }
                    onChange={(e) => {
                      handleChangeInput("contact", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    type="email"
                    error={
                      !regEmail.test(priceQuoteInput.email) &&
                      priceQuoteInput.email !== "" &&
                      true
                    }
                    id="outlined-basic"
                    label="Primary Email"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={priceQuoteInput.email}
                    onChange={(e) => {
                      handleChangeInput("email", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    type="email"
                    error={
                      !regEmail.test(priceQuoteInput.secondary_email) &&
                      priceQuoteInput.secondary_email !== "" &&
                      true
                    }
                    id="outlined-basic"
                    label="Secondary Email"
                    variant="outlined"
                    size="small"
                    name="secondary_email"
                    value={priceQuoteInput.secondary_email}
                    onChange={(e) => {
                      handleChangeInput("secondary_email", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    error={
                      !regex.test(priceQuoteInput.website) &&
                      priceQuoteInput.website !== "" &&
                      true
                    }
                    label="Website"
                    variant="outlined"
                    size="small"
                    name="website"
                    value={priceQuoteInput.website}
                    onChange={(e) => {
                      handleChangeInput("website", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInformation;
