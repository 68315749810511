import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GetuserDetails } from "../../_redux/action/UserAction";
import {
  GetCountryList,
  GetLeadtList,
  GetPriceQuoteList,
} from "../../../allAplication/mainLead/_redux/action/LeadsAction";
import { GetApplicationList } from "../../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { BASE_URL } from "../../../../Const";
import UserApplicationList from "./UserApplicationList";
import UserLeadList from "./UserLeadList";
import UserQuoteList from "./UserQuoteList";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
const ViewSalesPartner = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const UserDetails = useSelector((state) => state.userInfo.UserDetails);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);

  useEffect(() => {
    dispatch(GetuserDetails(id));
    dispatch(GetCountryList(1));
    dispatch(
      GetApplicationList(
        `${BASE_URL}api/v1/applications/application/?sales_partner=${id}&limit=3`
      )
    );
    dispatch(
      GetLeadtList(`${BASE_URL}api/v1/leads/lead/?sales_partner=${id}&limit=3`)
    );
    dispatch(
      GetPriceQuoteList(
        `${BASE_URL}api/v1/quotes/quote/?sales_partner=${id}&limit=3`
      )
    );
  }, []);

  return (
    <>
      {isLoadLeads && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="info-box text-capitalize">
        <div className="d-flex mb-4 heading justify-content-center align-items-center mt-4">
          <div className="d-flex heading">
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                fill="#0D0D0D"
              />
            </svg>
            {location.pathname.includes("view_epos_partner") ? (
              <p> E-Pos Partner Details</p>
            ) : location.pathname.includes("view_partner_manager") ? (
              <p> Partner Manager Details</p>
            ) : (
              <p> Sales Partner Details</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className=" mb-4  row details-container">
            <div class="col-md-6">
              {/* <h2>Sales Partner</h2> */}
              <div class="detail">
                <span class="label">Name:</span>
                <span class="value">
                  {" "}
                  {UserDetails?.first_name} {UserDetails?.last_name}{" "}
                </span>
              </div>
              <div class="detail">
                <span class="label">Mobile Number:</span>
                <span class="value">{UserDetails?.mobile}</span>
              </div>
              <div class="detail">
                <span class="label">Email:</span>
                <span class="value">{UserDetails?.email}</span>
              </div>

              <div class="detail">
                <span class="label">Gender:</span>
                <span class="value">
                  {UserDetails?.gender === 0
                    ? "Male"
                    : UserDetails?.gender === 1
                    ? "Female"
                    : "Other"}{" "}
                </span>
              </div>

              {/* <div class="detail">
              <span class="label">Country:</span>
              <span class="value">
                {countryList?.map((item) => {
                  return (
                    <span>
                      {item?.id === UserDetails?.country && item?.name}
                    </span>
                  );
                })}
              </span>

             
            </div> */}
            </div>
            <div className="col-md-6 ">
              {/* <h2>Sales Partner</h2> */}
              <div class="detail">
                <span class="label">Residual Percentage:</span>
                <span class="value">{UserDetails?.residual_percentage}</span>
              </div>
              <div class="detail">
                <span class="label">Upfront percentage :</span>
                <span class="value">{UserDetails?.upfront_percentage}</span>
              </div>
              <div class="detail">
                <span class="label">CRM Contact ID:</span>
                <span class="value">{UserDetails?.contact_id}</span>
              </div>
              {!location.pathname.includes("view_sales_partner") && (
                <>
                  <div class="detail">
                    <span class="label">Partner Number:</span>
                    <span class="value">{UserDetails?.partner_id}</span>
                  </div>
                </>
              )}
              {location.pathname.includes("view_sales_partner") && (
                <div class="detail">
                  <span class="label">Country:</span>
                  <span class="value">
                    {countryList?.map((item) => {
                      return (
                        <span>
                          {item?.id === UserDetails?.country && item?.name}
                        </span>
                      );
                    })}
                  </span>
                </div>
              )}
            </div>
            {!location.pathname.includes("view_sales_partner") && (
              <div className="col-md-12">
                <div class="detail ">
                  <span class="label">Country:</span>
                  <span class="value">
                    {countryList?.map((item) => {
                      return (
                        <span>
                          {item?.id === UserDetails?.country && item?.name}
                        </span>
                      );
                    })}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-12 ">
            <div className="list-box mb-4">
              <UserLeadList />
            </div>
            <div className="list-box mb-4">
              <UserQuoteList />
            </div>
            <div className="list-box">
              <UserApplicationList />
            </div>
          </div>
        </div>
        <button
          style={{  padding: "6px 10px" }}
          className="mt-4    custom-btn  flex mx-2 "
          onClick={() => {
            location.pathname.includes("view_sales_partner")
              ? history.push(`/partner_sales`)
              : location.pathname.includes("view_epos_partner")
              ? history.push(`/epos_partner`)
              : history.push(`/Onboarding_manager`);
          }}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default ViewSalesPartner;
