import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {
  GetCountryList,
  GetIndustryList,
} from "../../../_redux/action/LeadsAction";
import {
  GetApplicationInput,
  SubmitApplicationInput,
  SubmitNewApplicationInput,
} from "../_redux/action/NewapplicationAction";
import { ApplicationStatus, applicationStage, entity_code } from "../../commons/Dropdown";
import BusinessProfile from "../BusinessProfile";
import Address from "../Address";

const BuisnessDetailEdit = ({ buisnessDetailEdit }) => {
  const dispatch = useDispatch();

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  useEffect(() => {
    // dispatch(SetApplicationUpdatedData(id));
    dispatch(GetIndustryList(1));
    dispatch(GetCountryList(1));
    if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
  }, []);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };

  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
    if (applicationInput.new_to_card_proccessing === true) {
      dispatch(GetApplicationInput("previous_acquirer", ""));
    } else if (applicationInput.take_deposit === 2) {
      dispatch(GetApplicationInput("deposit_perc_transaction_value", ""));
      dispatch(GetApplicationInput("advance_supply_deposite_taken", ""));
      dispatch(GetApplicationInput("perc_annual_deposite_of_turnover", ""));
      dispatch(
        GetApplicationInput("time_btw_deposite_and_remaining_payment", "")
      );
      dispatch(GetApplicationInput("take_full_payment", ""));
    } else if (applicationInput.take_full_payment === false) {
      dispatch(GetApplicationInput("perc_annual_upfront_of_turnover", ""));
      dispatch(GetApplicationInput("advance_supply_full_payment", ""));
      dispatch(GetApplicationInput("deposite_comment", ""));
    }
  };
  const handleSelectSecuiredElavon = (name, i) => {
    dispatch(GetApplicationInput(name, i));
    if (applicationInput.secured_by_elavon === "SECURED_PRO") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    } else if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "19.99"));
      dispatch(GetApplicationInput("non_compliance_per_month", "N/A"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
  };
  const Acquiring_Bank = [
    {
      value: "0",
      label: "Elavon",
    },
  ];
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100 "
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Business Details</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={buisnessDetailEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              buisnessDetailEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      <div className="buissness-data">
        <BusinessProfile/>
        <Address/>
        {/* <div className="detail-content">
        <div>
            <p>Acquiring Bank </p>
            <select
              className="input"
              aria-label="Default select example"
              name="acquiring_bank"
              value={applicationInput.acquiring_bank}
              onChange={(e) =>
                handleChangeInput("acquiring_bank", e.target.value)
              }
            >
              {Acquiring_Bank.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>Stor Number </p>
            <input
              className="input"
              type="text"
              required
              placeholder="0.0"
              name="store_number"
              value={applicationInput.store_number}
              onChange={(e) => handleChangeInput("store_number", e.target.value)}
            />
          </div>
          <div>
            <p>Description of Goods </p>
            <input
              className="input"
              type="text"
              required
              placeholder="0.0"
              name="desc_of_service"
              value={applicationInput.desc_of_service}
              onChange={(e) => handleChangeInput("desc_of_service", e.target.value)}
            />
          </div>
          <div className="">
            <div className="">
              <p>Seasonal Sale (%): </p>
            </div>
            <div className="">
              <div className="d-sm-flex gap-3">
                <Button
                  onClick={() => handleSelectButton("seasonal_sales", true)}
                  size="small"
                  variant={
                    applicationInput.seasonal_sales === true
                      ? "contained"
                      : "outlined"
                  }
                  // variant="outlined"
                >
                  Yes
                </Button>
                <Button
                  onClick={() => handleSelectButton("seasonal_sales", false)}
                  size="small"
                  variant={
                    applicationInput.seasonal_sales === false
                      ? "contained"
                      : "outlined"
                  }
                >
                  No
                </Button>
              </div>
            </div>
          </div>

          {applicationInput.seasonal_sales === true && (
            <>
              <div>
                <p>Jan Feb Mar (%)</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="jan feb mar"
                  name="jan_to_mar"
                  value={applicationInput.jan_to_mar}
                  onChange={(e) => {
                    handleChangeInput("jan_to_mar", e.target.value);
                  }}
                />
              </div>

              <div>
                <p>Jul Aug Sep (%)</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="jul aug sep"
                  name="jul_to_sep"
                  value={applicationInput.jul_to_sep}
                  onChange={(e) => {
                    handleChangeInput("jul_to_sep", e.target.value);
                  }}
                />
              </div>

              <div>
                <p>Apr May Jun (%)</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Shafin"
                  name="apr_to_jun"
                  value={applicationInput.apr_to_jun}
                  onChange={(e) => {
                    handleChangeInput("apr_to_jun", e.target.value);
                  }}
                />
              </div>

              <div>
                <p>Oct Nov Dec (%)</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="Shafin"
                  name="oct_to_dec"
                  value={applicationInput.oct_to_dec}
                  onChange={(e) => {
                    handleChangeInput("oct_to_dec", e.target.value);
                  }}
                />
              </div>
            </>
          )}
          <div>
            <p>Legal Name</p>
            <input
              className="input"
              type="text"
              required
              placeholder="Shafin"
              name="legal_name"
              value={applicationInput.legal_name}
              onChange={(e) => handleChangeInput("legal_name", e.target.value)}
            />
          </div>
          <div>
            <p>Trading Name</p>
            <input
              className="input"
              type="text"
              required
              placeholder="Shafin T/A Shafin"
              name="trading_name"
              value={applicationInput.trading_name}
              onChange={(e) =>
                handleChangeInput("trading_name", e.target.value)
              }
            />
          </div>
          <div className="">
            <div className="">
              <p>Switching Contribution </p>
            </div>
            <div className="">
              <div className="d-sm-flex gap-3">
                <Button
                  onClick={() =>
                    handleSelectButton("switching_contribution", true)
                  }
                  size="small"
                  variant={
                    applicationInput.switching_contribution === true
                      ? "contained"
                      : "outlined"
                  }
                >
                  Yes
                </Button>
                <Button
                  onClick={() =>
                    handleSelectButton("switching_contribution", false)
                  }
                  size="small"
                  variant={
                    applicationInput.switching_contribution === false
                      ? "contained"
                      : "outlined"
                  }
                >
                  No
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div>
              <p>New to card processing :</p>
            </div>

            <div className="d-sm-flex gap-3">
              <Button
                onClick={() =>
                  handleSelectButton("new_to_card_proccessing", true)
                }
                size="small"
                variant={
                  applicationInput.new_to_card_proccessing === true
                    ? "contained"
                    : "outlined"
                }
              >
                Yes
              </Button>
              <Button
                onClick={() =>
                  handleSelectButton("new_to_card_proccessing", false)
                }
                size="small"
                variant={
                  applicationInput.new_to_card_proccessing === false
                    ? "contained"
                    : "outlined"
                }
              >
                No
              </Button>
            </div>
          </div>
          {applicationInput.new_to_card_proccessing === false && (
            <div>
              <p>who was your previous acquirer?</p>
              <input
                className="input"
                type="text"
                required
                placeholder="previous accure"
                name="previous_acquirer"
                value={applicationInput.previous_acquirer}
                onChange={(e) => {
                  handleChangeInput("previous_acquirer", e.target.value);
                }}
              />
            </div>
          )}

          <div>
            <div>
              <p> Do you want take deposite ?</p>
            </div>
            <div>
              <div className="d-sm-flex gap-3">
                <Button
                  onClick={() => handleSelectButton("take_deposit", 1)}
                  size="small"
                  variant={
                    applicationInput.take_deposit === 1
                      ? "contained"
                      : "outlined"
                  }
                >
                  Yes
                </Button>
                <Button
                  onClick={() => handleSelectButton("take_deposit", 2)}
                  size="small"
                  variant={
                    applicationInput.take_deposit === 2
                      ? "contained"
                      : "outlined"
                  }
                >
                  No
                </Button>
              </div>
            </div>
          </div>
          {applicationInput.take_deposit === 1 && (
            <>
              <div>
                <p> Size of deposite as a % of total transaction values </p>
                <input
                  className="input"
                  required
                  placeholder="Size of deposite as a % of total transaction values"
                  name="deposit_perc_transaction_value"
                  value={applicationInput.deposit_perc_transaction_value}
                  onChange={(e) => {
                    handleChangeInput(
                      "deposit_perc_transaction_value",
                      e.target.value
                    );
                  }}
                />
              </div>

              <p className="mb-2">
                How Far In Advance of the supply of goods/services are deposits
                taken?:
              </p>
              <div>
                <p> Days </p>
                <input
                  className="input"
                  required
                  placeholder="Size of deposite as a % of total transaction values"
                  name="advance_supply_deposite_taken"
                  value={applicationInput.advance_supply_deposite_taken}
                  onChange={(e) => {
                    handleChangeInput(
                      "advance_supply_deposite_taken",
                      e.target.value
                    );
                  }}
                />
              </div>
            
              <div>
                <p> % of (anticipated) turnover p.a. </p>
                <input
                  className="input"
                  required
                  placeholder="% of (anticipated) turnover p.a."
                  name="perc_annual_deposite_of_turnover"
                  value={applicationInput.perc_annual_deposite_of_turnover}
                  onChange={(e) => {
                    handleChangeInput(
                      "perc_annual_deposite_of_turnover",
                      e.target.value
                    );
                  }}
                />
              </div>

              <div>
                <p>
                  {" "}
                  Time between taking deposit and receiving remaining balance of
                  payment?{" "}
                </p>
                <input
                  className="input"
                  required
                  placeholder="% of (anticipated) turnover p.a."
                  name="time_btw_deposite_and_remaining_payment"
                  value={
                    applicationInput.time_btw_deposite_and_remaining_payment
                  }
                  onChange={(e) => {
                    handleChangeInput(
                      "time_btw_deposite_and_remaining_payment",
                      e.target.value
                    );
                  }}
                />
              </div>

              <div>
                <div>
                  <p> Do you ever take full payment ? :</p>
                </div>
                <div>
                  <div className="d-sm-flex gap-3">
                    <Button
                      onClick={() =>
                        handleSelectButton("take_full_payment", true)
                      }
                      size="small"
                      variant={
                        applicationInput.take_full_payment === true
                          ? "contained"
                          : "outlined"
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() =>
                        handleSelectButton("take_full_payment", false)
                      }
                      size="small"
                      variant={
                        applicationInput.take_full_payment === false
                          ? "contained"
                          : "outlined"
                      }
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
              {applicationInput.take_full_payment === true && (
                <>
                
                  <div>
                    <p> How far in advance of supply is the full </p>
                    <input
                      className="input"
                      type="number"
                      required
                      placeholder="Days"
                      name="advance_supply_full_payment"
                      value={applicationInput.advance_supply_full_payment}
                      onChange={(e) => {
                        handleChangeInput(
                          "advance_supply_full_payment",
                          e.target.value
                        );
                      }}
                    />
                  </div>

                  <div>
                    <p>
                      {" "}
                      What % of your annual turnover relates to upfront full
                      payments?{" "}
                    </p>
                    <input
                      className="input"
                      type="number"
                      required
                      placeholder="Days"
                      name="perc_annual_upfront_of_turnover"
                      value={applicationInput.perc_annual_upfront_of_turnover}
                      onChange={(e) => {
                        handleChangeInput(
                          "perc_annual_upfront_of_turnover",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      {" "}
                      If you wish to provide any further details, please insert
                      here:{" "}
                    </p>
                    <input
                      className="input"
                      type="number"
                      required
                      placeholder="Days"
                      name="deposite_note"
                      value={applicationInput.deposite_note}
                      onChange={(e) => {
                        handleChangeInput("deposite_note", e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div>
            <div>
              <p>Vat Details : </p>
            </div>
            <div>
              <div className="d-sm-flex gap-3">
                <Button
                  onClick={() => handleSelectButton("vat_enabled", 0)}
                  size="small"
                  variant={
                    applicationInput.vat_enabled === 0
                      ? "contained"
                      : "outlined"
                  }
                  // variant="outlined"
                >
                  Vat Number
                </Button>
                <Button
                  onClick={() => handleSelectButton("vat_enabled", 1)}
                  size="small"
                  variant={
                    applicationInput.vat_enabled === 1
                      ? "contained"
                      : "outlined"
                  }
                >
                  Vat Number Pending
                </Button>
                <Button
                  onClick={() => handleSelectButton("vat_enabled", 2)}
                  size="small"
                  variant={
                    applicationInput.vat_enabled === 2
                      ? "contained"
                      : "outlined"
                  }
                >
                  In Business Confirmation
                </Button>
              </div>
            </div>
          </div>
          {applicationInput.vat_enabled === 0 && (
            <>
              <div>
                <p>VAT Number</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="VAT Number"
                  name="tax_id"
                  value={applicationInput.tax_id}
                  onChange={(e) => handleChangeInput("tax_id", e.target.value)}
                />
              </div>

              <div>
                <p>VAT Amount</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="VAT Amount"
                  name="vat_amount"
                  value={applicationInput.vat_amount}
                  onChange={(e) =>
                    handleChangeInput("vat_amount", e.target.value)
                  }
                />
              </div>
            </>
          )}

         
          <div className="">
            <div>
              <p>AMEX MID : </p>
            </div>
            <div>
              <div className="d-sm-flex gap-3">
                <Button
                  onClick={() => handleSelectButton("amex_mid", "true")}
                  size="small"
                  variant={
                    applicationInput.amex_mid === "true"
                      ? "contained"
                      : "outlined"
                  }
                  // variant="outlined"
                >
                  Yes
                </Button>
                <Button
                  onClick={() => handleSelectButton("amex_mid", "false")}
                  size="small"
                  variant={
                    applicationInput.amex_mid === "false"
                      ? "contained"
                      : "outlined"
                  }
                >
                  No
                </Button>
              </div>
            </div>
          </div>
          {applicationInput.amex_mid === "true" && (
            <div>
              <div>
                <p>AMEX Type : </p>
              </div>
              <div>
                <div className="d-sm-flex gap-3">
                  <Button
                    onClick={() =>
                      handleSelectButton("amex_type", "Existing AMEX")
                    }
                    size="small"
                    variant={
                      applicationInput.amex_type === "Existing AMEX"
                        ? "contained"
                        : "outlined"
                    }
                    // variant="outlined"
                  >
                    Existing AMEX
                  </Button>
                  <Button
                    onClick={() => handleSelectButton("amex_type", "New AMEX")}
                    size="small"
                    variant={
                      applicationInput.amex_type === "New AMEX"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    New AMEX
                  </Button>
                </div>
              </div>
            </div>
          )}
          {applicationInput.amex_type === "Existing AMEX" &&
            applicationInput.amex_mid === true && (
              <div>
                <p>AMEX No</p>
                <input
                  className="input"
                  type="text"
                  required
                  placeholder="AMEX No"
                  name="amex_no"
                  value={applicationInput.amex_no}
                  onChange={(e) => handleChangeInput("amex_no", e.target.value)}
                />
              </div>
            )}

          <div>
            <div className="row ">
              <div className="col-4">
                <p>Secured by Elavon (select one): </p>
              </div>
              <div className="col-8">
                <div className="d-sm-flex gap-3">
                  <Button
                    onClick={() =>
                      handleSelectSecuiredElavon(
                        "secured_by_elavon",
                        "SECURED_PCI"
                      )
                    }
                    size="small"
                    variant={
                      applicationInput.secured_by_elavon === "SECURED_PCI"
                        ? "contained"
                        : "outlined"
                    }
                    // variant="outlined"
                  >
                    Secured PCI
                  </Button>
                  <Button
                    onClick={() =>
                      handleSelectSecuiredElavon(
                        "secured_by_elavon",
                        "SECURED_PRO"
                      )
                    }
                    size="small"
                    variant={
                      applicationInput.secured_by_elavon === "SECURED_PRO"
                        ? "contained"
                        : "outlined"
                    }
                  >
                    Secured Pro
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>Product (£ per month)</p>
            <input
              className="input"
              type="text"
              required
              placeholder="0.00"
              name="product_per_month_amt"
              value={applicationInput.product_per_month_amt}
              onChange={(e) => handleChangeInput("product_per_month_amt", e.target.value)}
            />
          </div>
          <div>
            <p>Non-Compliance (£ per month)</p>
            <input
              className="input"
              type="text"
              required
              placeholder="0.00"
              name="non_compliance_per_month"
              value={applicationInput.non_compliance_per_month}
              onChange={(e) => handleChangeInput("non_compliance_per_month", e.target.value)}
            />
          </div>
          <div>
            <p>Custom Value (£ per month)</p>
            <input
              className="input"
              type="text"
              required
              placeholder="0.00"
              name="customer_value_per_month"
              value={applicationInput.customer_value_per_month}
              onChange={(e) => handleChangeInput("customer_value_per_month", e.target.value)}
            />
          </div>
          {!isLoadLeads &&
            IndustryList &&
            IndustryList !== null &&
            IndustryList.length > 0 && (
              <div>
                <p>MCC Code</p>
                <select
                  className="input"
                  // class="form-select"
                  disabled
                  // aria-readonly
                  aria-label="Default select example"
                  name="industry_type"
                  value={applicationInput.industry_type}
                  onChange={(e) =>
                    handleChangeInput("mcc_code", e.target.value)
                  }
                >
                  {IndustryList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.mcc_iso}
                    </option>
                  ))}
                </select>
              </div>
            )}
         
          <div>
            <p>Business Status</p>
            <select
              className="input"
              aria-label="Default select example"
              name="application_status"
              value={applicationInput.application_status}
              onChange={(e) =>
                handleChangeInput("application_status", e.target.value)
              }
            >
              {ApplicationStatus.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            
          </div>
          {!isLoadLeads &&
            IndustryList &&
            IndustryList !== null &&
            IndustryList.length > 0 && (
              <div>
                <p>Industry Type</p>
                <select
                  className="input"
                  // class="form-select"
                  aria-label="Default select example"
                  name="industry_type"
                  value={applicationInput.industry_type}
                  onChange={(e) =>
                    handleChangeInput("industry_type", e.target.value)
                  }
                >
                  {IndustryList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </select>
              </div>
            )}
        
          <div>
            <p>Website</p>
            <input
              className="input"
              type="text"
              required
              placeholder="www.yoursite.com"
              name="website"
              value={applicationInput.website}
              onChange={(e) => handleChangeInput("website", e.target.value)}
            />
          </div>
        </div>
  
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <p
              style={{
                margin: "0 26px",
                borderBottom: "0.4px solid #979797",
                paddingBottom: "12px",
              }}
            >
              Legal Address Information
            </p>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="legal_address1"
                value={applicationInput.legal_address1}
                onChange={(e) =>
                  handleChangeInput("legal_address1", e.target.value)
                }
              />
            </div>
            <div>
              <p>Address 2</p>
              <input
                className="input"
                type="text"
                required
                name="legal_address2"
                value={applicationInput.legal_address2}
                onChange={(e) =>
                  handleChangeInput("legal_address2", e.target.value)
                }
              />
            </div>
            <div>
              <p>City/Town</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="legal_city"
                value={applicationInput.legal_city}
                onChange={(e) =>
                  handleChangeInput("legal_city", e.target.value)
                }
              />
            </div>
            <div>
              <p>Post Code</p>
              <input
                className="input"
                type="text"
                required
                placeholder="12345"
                name="legal_postcode"
                value={applicationInput.legal_postcode}
                onChange={(e) =>
                  handleChangeInput("legal_postcode", e.target.value)
                }
              />
            </div>
            <div>
              <p>County</p>
              <input
                className="input"
                type="text"
                required
                placeholder="United Kingdom"
                name="legal_county"
                value={applicationInput.legal_county}
                onChange={(e) =>
                  handleChangeInput("legal_county", e.target.value)
                }
              />
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country</p>
                  <select
                    className="input"
                    // class="form-select"
                    aria-label="Default select example"
                    name="legal_country"
                    value={applicationInput.legal_country}
                    onChange={(e) =>
                      handleChangeInput("legal_country", e.target.value)
                    }
                  >
                    {countryList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
        </div>
     
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <p
              style={{
                margin: "0 26px",
                borderBottom: "0.4px solid #979797",
                paddingBottom: "12px",
                marginBottom: "12px",
              }}
            >
              Trading Address Information
            </p>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="trading_address1"
                value={applicationInput.trading_address1}
                onChange={(e) =>
                  handleChangeInput("trading_address1", e.target.value)
                }
              />
            </div>
            <div>
              <p>Address 2</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="trading_address2"
                value={applicationInput.trading_address2}
                onChange={(e) =>
                  handleChangeInput("trading_address2", e.target.value)
                }
              />
            </div>
            <div>
              <p>City/Town</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="trading_city"
                value={applicationInput.trading_city}
                onChange={(e) =>
                  handleChangeInput("trading_city", e.target.value)
                }
              />
            </div>
            <div>
              <p>Post Code</p>
              <input
                className="input"
                type="text"
                required
                placeholder="12345"
                name="trading_postcode"
                value={applicationInput.trading_postcode}
                onChange={(e) =>
                  handleChangeInput("trading_postcode", e.target.value)
                }
              />
            </div>
            <div>
              <p>County</p>
              <input
                className="input"
                type="text"
                required
                placeholder="United Kingdom"
                name="trading_county"
                value={applicationInput.trading_county}
                onChange={(e) =>
                  handleChangeInput("trading_county", e.target.value)
                }
              />
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country</p>
                  <select
                    className="input"
                    // class="form-select"
                    aria-label="Default select example"
                    name="trading_country"
                    value={applicationInput.trading_country}
                    onChange={(e) =>
                      handleChangeInput("trading_country", e.target.value)
                    }
                  >
                    {countryList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
        </div>
      
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <p
              style={{
                margin: "0 26px",
                borderBottom: "0.4px solid #979797",
                paddingBottom: "12px",
                marginBottom: "12px",
              }}
            >
              Home Address Information
            </p>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="home_address1"
                value={applicationInput.home_address1}
                onChange={(e) =>
                  handleChangeInput("home_address1", e.target.value)
                }
              />
            </div>
            <div>
              <p>Address 2</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="home_address2"
                value={applicationInput.home_address2}
                onChange={(e) =>
                  handleChangeInput("home_address2", e.target.value)
                }
              />
            </div>
            <div>
              <p>City/Town</p>
              <input
                className="input"
                type="text"
                required
                placeholder="Lorim Ipsum"
                name="home_city"
                value={applicationInput.home_city}
                onChange={(e) =>
                  handleChangeInput("home_city", e.target.value)
                }
              />
            </div>
            <div>
              <p>Post Code</p>
              <input
                className="input"
                type="text"
                required
                placeholder="12345"
                name="home_postcode"
                value={applicationInput.home_postcode}
                onChange={(e) =>
                  handleChangeInput("home_postcode", e.target.value)
                }
              />
            </div>
            <div>
              <p>County</p>
              <input
                className="input"
                type="text"
                required
                placeholder="United Kingdom"
                name="home_county"
                value={applicationInput.home_county}
                onChange={(e) =>
                  handleChangeInput("home_county", e.target.value)
                }
              />
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country</p>
                  <select
                    className="input"
                    // class="form-select"
                    aria-label="Default select example"
                    name="home_country"
                    value={applicationInput.home_country}
                    onChange={(e) =>
                      handleChangeInput("trading_country", e.target.value)
                    }
                  >
                    {countryList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
        </div> */}
    
      </div>
    </div>
  );
};

export default BuisnessDetailEdit;
