import React from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
function PageNumberCount({ currentPage, totalPages, onPageChange }) {
  const getPageNumbers = () => {
    const maxVisiblePages = 5;
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxVisiblePages) {
      if (currentPage <= Math.ceil(maxVisiblePages / 2)) {
        endPage = maxVisiblePages;
      } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
        startPage = totalPages - maxVisiblePages + 1;
      } else {
        startPage = currentPage - Math.floor(maxVisiblePages / 2);
        endPage = currentPage + Math.floor(maxVisiblePages / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.map((page) => (
      <li
        key={page}
        className={`page-item${currentPage === page ? " active" : ""}`}
        onClick={() => handlePageChange(page)}
      >
        <a className="page-link" href="#">
          {page}
        </a>
      </li>
    ));
  };
  // const maxPageNumbers = 5;
  // const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  // const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
  // const pageNumbers = Array(endPage - startPage + 1)
  //   .fill()
  //   .map((_, index) => startPage + index);
  return (
    <>
      <nav aria-label="Pagination">
        <ul className="pagination">
          <li className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <MdOutlineArrowBackIos
                style={{ width: "16px", height: "21px" }}
              />
            </a>
          </li>
          {renderPageNumbers()}
          <li
            className={`page-item${
              currentPage === totalPages ? " disabled" : ""
            }`}
          >
            <a
              className="page-link"
              href="#"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <MdArrowForwardIos style={{ width: "16px", height: "21px" }} />
            </a>
          </li>
        </ul>
      </nav>
{/* 
      <div>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        {startPage > 1 && (
          <button onClick={() => handlePageChange(1)}>1</button>
        )}
        {startPage > 2 && <span>..</span>}
        {pageNumbers.map((page) => (
          <button key={page} onClick={() => handlePageChange(page)}>
            {page}
          </button>
        ))}
        {endPage < totalPages - 1 && <span>..</span>}
        {endPage < totalPages && (
          <button onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        )}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div> */}
    </>
  );
}

export default PageNumberCount;
