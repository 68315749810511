import ApplicationTop from "../../../../home/NewApplication/ApplicationTop";

export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const GET_INDUSTRY_LIST = "GET_INDUSTRY_LIST";
export const IS_LOAD_LEADS = "IS_LOAD_LEADS";
export const GET_LEADS_INPUT = "GET_LEADS_INPUT";
export const AFTER_SUCCESS_LEADS = "AFTER_SUCCESS_LEADS"
export const SET_FALSE = "SET_FALSE"
export const AFTER_SUCCESS_UPDATE = "AFTER_SUCCESS_UPDATE"
export const SET_FALSE_UPDATE_LEADS= "SET_FALSE_UPDATE_LEADS"
export const LEADS_PAGINATION = "LEADS_PAGINATION"
export const GET_LEADS_DETAILS = "GET_LEADS_DETAILS"
export const LEADS_DETAILS_PAGINATION = "LEADS_DETAILS_PAGINATION"

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";

export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE";
export const GET_SALES_PARTNER = "GET_SALES_PARTNER";
export const IS_ADDRESS_CHECKED = "IS_ADDRESS_CHECKED";

export const GET_QUALIFY_LEAD = "GET_QUALIFY_LEAD";

export const GET_PRDUCT_LIST = "GET_PRDUCT_LIST";
// ------------------Pagination-------------------------------------
export const PAGINATION_NAME = "PAGINATION_NAME"
export const PAGINATION_lIMIT = "PAGINATION_lIMIT"
export const PAGINATION_NEXT = "PAGINATION_NEXT"
export const PAGINATION_PREVIOUS = "PAGINATION_PREVIOUS"
export const PAGINATION_CHANGE_CURRENT = "PAGINATION_CHANGE_CURRENT"
export const GET_PAGINATION_DATA = "GET_PAGINATION_DATA"
export const PAGINATION_LOADING = "PAGINATION_LOADING";
export const PAGINATION_SUCCESS = "PAGINATION_SUCCESS";
export const PAGINATION_ERROR = "PAGINATION_ERROR";
export const PAGINATION_URL = "PAGINATION_URL";

export const SET_LEADS_UPDATED = "SET_LEADS_UPDATED"

// -------------price quote---------------
export const GET_PRICE_QUOTE_LIST = "GET_PRICE_QUOTE_LIST";
export const GET_PRICE_QUOTE_DETAILS = "GET_PRICE_QUOTE_DETAILS";
export const PRICE_QUOTE_PAGINATION = "PRICE_QUOTE_PAGINATION"
export const SET_PRICE_QUOTE_FALSE = "SET_PRICE_QUOTE_FALSE"
export const IS_LOAD_PRICE_QUOTE = "IS_LOAD_PRICE_QUOTE";
export const GET_PRICE_QUOTE_INPUT = "GET_PRICE_QUOTE_INPUT";
export const AFTER_SUCCESS_PRICE_QUOTE = "AFTER_SUCCESS_PRICE_QUOTE"
export const SET_PRICEQUOTE_UPDATED = "SET_PRICEQUOTE_UPDATED";
export const GET_QUALIFY_APPLICATION = "GET_QUALIFY_APPLICATION";
export const AFTER_SUCCESS_APPLICATION_QUALIFY = "AFTER_SUCCESS_APPLICATION_QUALIFY"
export const SET_FALSE_APPLICATION_QUALIFY = "SET_FALSE_APPLICATION_QUALIFY"

export const REMOVE_QUOTE_OWNER = "REMOVE_QUOTE_OWNER"
export const SET_PRICE_QUOTE_OWNER_INPUT = "SET_PRICE_QUOTE_OWNER_INPUT"
export const GET_PRICE_QUOTE_OWNER = "GET_PRICE_QUOTE_OWNER"

export const GET_PRICE_QUOTE_OWNER_CONTACT = "GET_PRICE_QUOTE_OWNER_CONTACT"
export const SET_QUOTE_OWNER_CONTACT_INPUT = "SET_QUOTE_OWNER_CONTACT_INPUT"
export const UPDATE_QUOTE_OWNER_CONTACT = "UPDATE_QUOTE_OWNER_CONTACT"

// ---------------------qualify---------------------------
export const GET_QUALIFY_LIST = "GET_QUALIFY_LIST"
export const AFTER_SUCCESS_QUALIFY = "AFTER_SUCCESS_QUALIFY"
export const QUALIFY_STATUS = "QUALIFY_STATUS"
export const SET_FALSE_LEAD_QUALIFY = "SET_FALSE_LEAD_QUALIFY"
export const SET_FALSE_QUALIFY = "SET_FALSE_QUALIFY"

// ---------------------quote qualify---------------------------
export const AFTER_SUCCESS_QUOTE_QUALIFY = "AFTER_SUCCESS_QUOTE_QUALIFY"
export const QUOTE_QUALIFY_CHECK = "QUOTE_QUALIFY_CHECK"
export const SET_FALSE_QUOTE_QUALIFY = "SET_FALSE_QUOTE_QUALIFY"
export const SET_FALSE_QUOTE_AFTER_QUALIFY = "SET_FALSE_QUOTE_AFTER_QUALIFY"
// -ApplicationTop
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";

export const ACTIVE_STEP = "ACTIVE_STEP";



// ===============lead product===================================


export const GET_LEAD_PRODUCT = "GET_LEAD_PRODUCT";
export const SET_LEAD_PRODUCT = "SET_LEAD_PRODUCT";
export const REMOVE_LEAD_PRODUCT = "REMOVE_LEAD_PRODUCT";
export const IS_LEAD_PRODUCT_DELETED = "IS_LEAD_PRODUCT_DELETED";

export const GET_CARD_TERMINAL_PRODUCT = "GET_CARD_TERMINAL_PRODUCT";
export const SET_CARD_TERMINAL_PRODUCT = "SET_CARD_TERMINAL_PRODUCT";
export const REMOVE_CARD_TERMINAL_PRODUCT = "REMOVE_CARD_TERMINAL_PRODUCT";
export const IS_CARD_TERMINAL_PRODUCT_DELETED = "IS_CARD_TERMINAL_PRODUCT_DELETED";

export const GET_ECOMMERCE_PRODUCT = "GET_ECOMMERCE_PRODUCT";
export const SET_ECOMMERCE_PRODUCT = "SET_ECOMMERCE_PRODUCT";
export const REMOVE_ECOMMERCE_PRODUCT = "REMOVE_ECOMMERCE_PRODUCT";
export const IS_ECOMMERCE_PRODUCT_DELETED = "IS_ECOMMERCE_PRODUCT_DELETED";

export const GET_EPOS_PRODUCT = "GET_EPOS_PRODUCT";
export const SET_EPOS_PRODUCT = "SET_EPOS_PRODUCT";
export const REMOVE_EPOS_PRODUCT = "REMOVE_EPOS_PRODUCT";
export const IS_EPOS_PRODUCT_DELETED = "IS_EPOS_PRODUCT_DELETED";
// ===============quote product===================================

export const GET_ALL_PRODUCT_LIST = "GET_ALL_PRODUCT_LIST";
export const GET_QUOTE_PRODUCT = "GET_QUOTE_PRODUCT";
export const SET_QUOTE_PRODUCT = "SET_QUOTE_PRODUCT";
export const REMOVE_QUOTE_PRODUCT = "REMOVE_QUOTE_PRODUCT";
export const IS_QUOTE_PRODUCT_DELETED = "IS_QUOTE_PRODUCT_DELETED";

// ================doc-------------------


export const DELETE_QUOTE_DOC = "DELETE_DOC";
export const SET_NEW_QUOTE_DOC_INPUT = "SET_NEW_DOC_INPUT";
export const ADD_NEW_QUOTE_DOC = "ADD_NEW_QUOTE_DOC";
export const SUCCESS_QUOTE_DOC = "SUCCESS_DOC";
export const SET_QUOTE_DOC_UPDATED = "SET_DOC_UPDATED";