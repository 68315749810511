import React from "react";
import { Add } from "@material-ui/icons";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// import Number from "../addLead/Number";
import { useDispatch, useSelector } from "react-redux";

import {
  GetPostcodeDetails,
  GetPostCodeList,
  GetPostCodeList2,
  QuoteOwnerDelete,
  SetFalseObjectDelete,
} from "./_redux/action/PriceQuoteAction";
import { TitleChoice } from "../commons/Dropdown";
import {
  GetCountryList,
  GetPriceQuoteDetails,
  GetPricequoteInput,
} from "../../_redux/action/LeadsAction";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";
import {
  SET_PRICE_QUOTE_OWNER_INPUT,
  REMOVE_QUOTE_OWNER,
} from "../../_redux/types/Types";
import { ImCross } from "react-icons/im";
import Button from "@mui/material/Button";
import Number from "./Number";
import ContactPostcode from "../newApplication/ContactPostcode";

const ContactInfoBox = (props) => {
  const dispatch = useDispatch();
  const [openHome, setOpenHome] = useState(false);
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );

  const countryList = useSelector((state) => state.leadInfo.countryList);

  const isQuoteOwnerDeleted = useSelector(
    (state) => state.priceQuoteInfo.isQuoteOwnerDeleted
  );
  const postCodeList = useSelector(
    (state) => state.priceQuoteInfo.postCodeList
  );
  const addressDetails = useSelector(
    (state) => state.priceQuoteInfo.addressDetails
  );
  useEffect(() => {
    dispatch(GetCountryList(1));
  }, []);
  let leadId = localStorage.getItem("leadId");
  const countryName = () => {
    let country_id = null;
    countryList.map((item) => {
      if (item.name === addressDetails?.CountryName) {
        country_id = item.id;
      }
    });
    return country_id;
  };
  const handleChangeTextInputOwner = (
    name,
    value,
    index,
    contact = undefined
  ) => {
    dispatch(GetPricequoteInput(name, value, index, "quote_owners", contact));

    if (name === "owner_surname") {
      dispatch(
        GetPricequoteInput(
          "owner_second_name",
          value,
          index,
          "quote_owners",
          contact
        )
      );
    }
    dispatch(
      GetPricequoteInput(
        "quote",
        priceQuoteInput.id,
        index,
        "quote_owners",
        contact
      )
    );

    if (
      priceQuoteInput.legal_type === "L" ||
      priceQuoteInput.legal_type === "PL" ||
      priceQuoteInput.legal_type === "LLP" ||
      priceQuoteInput.legal_type === "ST"
    ) {
      if (index === 0) {
        dispatch(
          GetPricequoteInput(
            "is_main_principal",
            true,
            index,

            "quote_owners",
            contact
          )
        );
      } else {
        dispatch(
          GetPricequoteInput(
            "is_main_principal",
            false,
            index,

            "quote_owners",
            contact
          )
        );
      }
    } else if (priceQuoteInput.legal_type === "PART") {
      dispatch(
        GetPricequoteInput(
          "is_main_principal",
          true,
          index,

          "quote_owners",
          contact
        )
      );
    }
  };
  useEffect(() => {
    if (
      priceQuoteInput.legal_type === "L" ||
      priceQuoteInput.legal_type === "PL" ||
      priceQuoteInput.legal_type === "LLP" ||
      priceQuoteInput.legal_type === "ST"
    ) {
      for (
        let index = 0;
        index < priceQuoteInput?.quote_owners?.length;
        index++
      ) {
        if (index === 0) {
          dispatch(
            GetPricequoteInput(
              "is_main_principal",
              true,
              index,

              "quote_owners",
              "contact"
            )
          );
        } else {
          dispatch(
            GetPricequoteInput(
              "is_main_principal",
              false,
              index,

              "quote_owners",
              "contact"
            )
          );
        }
      }
    } else if (priceQuoteInput.legal_type === "PART") {
      for (
        let index = 0;
        index < priceQuoteInput?.quote_owners?.length;
        index++
      ) {
        dispatch(
          GetPricequoteInput(
            "is_main_principal",
            true,
            index,

            "quote_owners",
            "contact"
          )
        );
      }
    }
  }, []);
  const handleAddMore = () => {
    dispatch({ type: SET_PRICE_QUOTE_OWNER_INPUT, payload: false });
  };

  const handleremoveOwner = (i) => {
    dispatch({ type: REMOVE_QUOTE_OWNER, payload: i });
  };

  const handleDelete = (id) => {
    dispatch(QuoteOwnerDelete(id, priceQuoteInput.id));
  };
  useEffect(() => {
    if (isQuoteOwnerDeleted) {
      dispatch(GetPriceQuoteDetails(leadId));
      dispatch(SetFalseObjectDelete());
    }
  }, [isQuoteOwnerDeleted]);
  const handleMainPrincipal = (name, value, index, contact = undefined) => {
    dispatch(GetPricequoteInput(name, value, index, "quote_owners", contact));
    if (
      priceQuoteInput.legal_type === "L" ||
      priceQuoteInput.legal_type === "PL" ||
      priceQuoteInput.legal_type === "LLP" ||
      priceQuoteInput.legal_type === "ST"
    ) {
      if (index === 0) {
        dispatch(
          GetPricequoteInput(
            "is_main_principal",
            true,
            index,

            "quote_owners",
            contact
          )
        );
      } else {
        dispatch(
          GetPricequoteInput(
            "is_main_principal",
            false,
            index,

            "quote_owners",
            contact
          )
        );
      }
    } else if (priceQuoteInput.legal_type === "PART") {
      dispatch(
        GetPricequoteInput(
          "is_main_principal",
          true,
          index,

          "quote_owners",
          contact
        )
      );
    }
  };
  const handleSelect = (name, i) => {
    dispatch(GetPricequoteInput(name, i));
  };

  const getCountryIndex = () => {
    var index = -1;
    priceQuoteInput?.quote_owners?.map((x, i) => {
      countryList.map((opt) => {
        if (opt.id === x.owner_phone_code) {
          index = countryList.indexOf(opt);
        }
      });
    });
    return index;
  };

  return (
    <div className="info-box text-capitalize row">
      <div class="d-flex justify-content-between  mb-4">
        <div className="d-flex heading mb-4">
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>
          <p>Contact Details</p>
        </div>
        {priceQuoteInput?.quote_owners?.length < 1 && (
          <div className="add-btn2 " onClick={handleAddMore}>
            <Add />
            ADD
          </div>
        )}
        {/* <div className="add-btn2" onClick={handleAddMore}>
          <Add />
          ADD
        </div> */}
      </div>
      {priceQuoteInput?.quote_owners?.map((x, i) => {
        return (
          <div className="col-md-6" key={x.id}>
            <div className="inner-box" style={{ backgroundColor: "#FAFAFA" }}>
              {/* remove */}
              <div className="d-flex justify-content-between mb-4">
                <p className="close_container">{i + 1}</p>
                {x?.id ? (
                  <div
                    className="close_container"
                    onClick={() => handleDelete(x.id)}
                  >
                    <ImCross
                      style={{ color: "lightcoral", cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <div
                    className="close_container"
                    onClick={() => handleremoveOwner(i)}
                  >
                    <ImCross
                      style={{ color: "lightcoral", cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="mb-form col-12 col-lg-6 ">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Title"
                    name="owner_title"
                    value={x.owner_title}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "owner_title",
                        e.target.value,
                        i
                      )
                    }
                  >
                    {TitleChoice.map((option) => (
                      <MenuItem
                        className="m-2"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-12 col-lg-6  mb-4 mb-lg-0 mb-form">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    name="owner_first_name"
                    value={x.owner_first_name}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "owner_first_name",
                        e.target.value,
                        i
                      )
                    }
                  />
                </div>
                <div className="col-12 col-lg-12  mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    name="owner_surname"
                    value={x.owner_surname}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "owner_surname",
                        e.target.value,
                        i
                      )
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Date of Birth"
                      variant="outlined"
                      type="date"
                      error={
                        new Date().getFullYear() -
                          new Date(x.contact_dob).getFullYear() <
                          10 &&
                        x.contact_dob !== "" &&
                        true
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      name="contact_dob"
                      value={x.contact_dob}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "contact_dob",
                          e.target.value,
                          i
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Company Name"
                      variant="outlined"
                      size="small"
                      name="company_name"
                      value={priceQuoteInput.trading_name}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "company_name",
                          e.target.value,
                          i
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="mb-form">
                    <TextField
                      type="email"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      name="owner_email"
                      value={x.owner_email}
                      onChange={(e) => {
                        handleChangeTextInputOwner(
                          "owner_email",
                          e.target.value,
                          i
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <Number
                    text={"Phone Number"}
                    colorBg={"#FAFAFA"}
                    name="owner_phone_no"
                    value={x.owner_phone_no}
                    onchangevalue={(e) =>
                      handleChangeTextInputOwner(
                        "owner_phone_no",
                        e.target.value,
                        i
                      )
                    }
                    // -------phone code------------
                    flag_name="owner_phone_code"
                    flag_value={
                      countryList?.[getCountryIndex()]?.iso2 +
                        " " +
                        countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                    }
                    onchangeFlag={(event, newValue) => {
                      countryList?.map(
                        (code) =>
                          //  code.phone_code === newValue &&
                          code.phone_code === newValue.split(" ")[1] &&
                          code.iso2 === newValue.split(" ")[0] &&
                          handleChangeTextInputOwner(
                            "owner_phone_code",
                            code.id,
                            i
                          )
                      );
                    }}
                  />
                </div>
                <div className="mb-form row">
                  <div className="col-4">
                    <p>Responsible Party :</p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleChangeTextInputOwner(
                            "is_responsible_party",
                            true,
                            i
                          )
                        }
                        size="small"
                        variant={
                          priceQuoteInput?.quote_owners[i]
                            ?.is_responsible_party === true
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeTextInputOwner(
                            "is_responsible_party",
                            false,
                            i
                          )
                        }
                        size="small"
                        variant={
                          priceQuoteInput?.quote_owners[i]
                            ?.is_responsible_party === false
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="ID Number"
                    variant="outlined"
                    size="small"
                    name="owner_id_num"
                    value={x.owner_id_num || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "owner_id_num",
                        e.target.value,
                        i
                      )
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Issue Date"
                      variant="outlined"
                      error={
                        x.owner_issue_date !== "" &&
                        x.owner_issue_date !== null &&
                        (new Date(x.owner_expiry_date).getFullYear() -
                        new Date(x.owner_issue_date).getFullYear()) *
                        12 +
                        (new Date(x.owner_expiry_date).getMonth() -
                          new Date(x.owner_issue_date).getMonth()) <
                        3
                          ? true
                          : false
                      }
                      type="date"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      name="owner_issue_date"
                      value={x.owner_issue_date}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "owner_issue_date",
                          e.target.value,
                          i
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      type="date"
                      id="outlined-basic"
                      label="Expiry Date"
                      error={
                        x.owner_expiry_date !== "" &&
                        x.owner_expiry_date !== null &&
                        (new Date(x.owner_expiry_date).getFullYear() -
                        new Date(x.owner_issue_date).getFullYear()) *
                        12 +
                        (new Date(x.owner_expiry_date).getMonth() -
                          new Date(x.owner_issue_date).getMonth()) <
                        3
                          ? true
                          : false
                      }
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      name="owner_expiry_date"
                      value={x.owner_expiry_date}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "owner_expiry_date",
                          e.target.value,
                          i
                        )
                      }
                    />
                  </div>
                </div>

                <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    options={countryList}
                    onChange={(event, newValue) =>
                      handleChangeTextInputOwner(
                        "owner_nationality",
                        newValue.id,
                        i
                      )
                    }
                    value={countryList?.[x.owner_nationality - 1] || null}
                    autoHighlight
                    getOptionLabel={(option) => option.nationality}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nationality} ({option.iso2})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nationality"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        name="owner_nationality"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="inner-box" style={{ backgroundColor: "#FAFAFA" }}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>Is main principal? : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_main_principal", true, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]
                                ?.is_main_principal === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_main_principal", false, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]
                                ?.is_main_principal === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <TextField
                      size="small"
                      id="outlined-select-currency"
                      select
                      label="Is main principal?"
                      name="is_main_principal"
                      value={x.is_main_principal}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "is_main_principal",
                          e.target.value,
                          i
                        )
                      }
                    >
                      {true_false.map((option) => (
                        <MenuItem
                          className="m-2"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </div>
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>Is beneficial Owner? : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal(
                                "is_beneficial_owner",
                                true,
                                i
                              )
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]
                                ?.is_beneficial_owner === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal(
                                "is_beneficial_owner",
                                false,
                                i
                              )
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]
                                ?.is_beneficial_owner === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                        {/* <div className="d-sm-flex gap-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group1"
                            type="checkbox"
                            checked={
                              priceQuoteInput.quote_owners[i].is_beneficial_owner === true 
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleMainPrincipal("is_beneficial_owner", true, i)
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group1"
                            type="checkbox"
                            checked={
                              priceQuoteInput.quote_owners[i].is_beneficial_owner === false
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              // handleMainPrincipal(false, e.target.value,i)
                              handleMainPrincipal("is_beneficial_owner", false, i)
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                    {/* <TextField
                      size="small"
                      id="outlined-select-currency"
                      select
                      label="Is beneficial Owner?"
                      name="is_beneficial_owner"
                      value={x.is_beneficial_owner}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "is_beneficial_owner",
                          e.target.value,
                          i
                        )
                      }
                    >
                      {true_false.map((option) => (
                        <MenuItem
                          className="m-2"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </div>
                  {/*  */}
                </div>
                <div className="col-md-12">
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>Is signatory? : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_signatory", true, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]?.is_signatory ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                            // variant="outlined"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_signatory", false, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]?.is_signatory ===
                              false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <TextField
                      size="small"
                      id="outlined-select-currency"
                      select
                      label="Is signatory?"
                      name="is_signatory"
                      value={x.is_signatory}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "is_signatory",
                          e.target.value,
                          i
                        )
                      }
                    >
                      {true_false.map((option) => (
                        <MenuItem
                          className="m-2"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>Is director : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_director", true, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]?.is_director ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_director", false, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput?.quote_owners[i]?.is_director ===
                              false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <TextField
                      size="small"
                      id="outlined-select-currency"
                      select
                      label="Is director?"
                      name="is_director"
                      value={x.is_director}
                      onChange={(e) =>
                        handleChangeTextInputOwner(
                          "is_director",
                          e.target.value,
                          i
                        )
                      }
                    >
                      {true_false.map((option) => (
                        <MenuItem
                          className="m-2"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </div>
                </div>
                <div className="mb-form">
                  {/* <div className="row">
                      <div className="col-4">
                        <p>Is Owner? : </p>
                      </div>
                      <div className="col-8">
                      <div className="d-sm-flex gap-3">
                        <Button
                          onClick={() => handleMainPrincipal("is_owner",true,i)}
                          size="small"
                          variant={
                            priceQuoteInput?.quote_owners[i]?.is_owner === true
                              ? "contained"
                              : "outlined"
                          }
                         
                         
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() => handleMainPrincipal("is_owner",false, i)}
                          size="small"
                          variant={
                            priceQuoteInput?.quote_owners[i]?.is_owner === false
                              ? "contained"
                              : "outlined"
                          }
                      
                        >
                          No
                        </Button>
                      </div>
                      </div>
                    </div> */}
                  {priceQuoteInput.legal_type === "LLP" ||
                  priceQuoteInput.legal_type === "L" ||
                  priceQuoteInput.legal_type === "PL" ? (
                    <></>
                  ) : (
                    <div className="row mb-form">
                      <div className="col-4">
                        <p>Is Owner? : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_owner", true, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput.quote_owners[i].is_owner === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_owner", false, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput.quote_owners[i].is_owner === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {priceQuoteInput.legal_type === "PART" && (
                    <div className="row mb-form">
                      <div className="col-4">
                        <p>Is Partnership? : </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_partnership", true, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput.quote_owners[i].is_partnership ===
                              true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleMainPrincipal("is_partnership", false, i)
                            }
                            size="small"
                            variant={
                              priceQuoteInput.quote_owners[i].is_partnership ===
                              false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 col-lg-12 mb-form ">
                  <TextField
                    id="outlined-basic"
                    label="Percent of Ownership"
                    error={
                      x.ownership_perc > 100 && x.ownership_perc !== "" && true
                    }
                    variant="outlined"
                    size="small"
                    name="ownership_perc"
                    value={x.ownership_perc}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "ownership_perc",
                        e.target.value,
                        i
                      )
                    }
                  />
                </div>
                <div className="col-12">
                  {/* <Number
                    text={"Phone Number"}
                    colorBg={"#FAFAFA"}
                    name="owner_phone_no"
                    value={x.owner_phone_no}
                    onChange={(e) =>
                      handleChangeTextInputOwner(
                        "owner_phone_no",
                        e.target.value,
                        i
                      )
                    }
                  /> */}
                </div>

                {x.quote_owner_contacts?.map((contact, index) => {
                  return (
                    <div className="col-12">
                      <h5>Home Address</h5>
                      <div className="mb-form mt-4">
                        {/* <TextField
                          id="outlined-basic"
                          error={
                            contact.zip_code < 5 &&
                            contact.zip_code !== "" &&
                            true
                          }
                          label="Postcode"
                          variant="outlined"
                          size="small"
                          name="zip_code"
                          value={contact.zip_code}
                          onChange={(e) =>
                            handleChangeTextInputOwner(
                              "zip_code",
                              e.target.value,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                        /> */}
                        <ContactPostcode
                          name="zip_code"
                          value={contact.zip_code}
                          details="quote_owner_postcode"
                          index={i}
                          business_owner_contacts={"quote_owner_contacts"}
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="Address 1"
                          variant="outlined"
                          size="small"
                          name="street_line_1"
                          value={contact.street_line_1}
                          onChange={(e) =>
                            handleChangeTextInputOwner(
                              "street_line_1",
                              e.target.value,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="Address 2"
                          variant="outlined"
                          size="small"
                          name="locality"
                          InputLabelProps={{ shrink: true }}
                          value={contact.locality}
                          onChange={(e) =>
                            handleChangeTextInputOwner(
                              "locality",
                              e.target.value,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="Town/City"
                          variant="outlined"
                          size="small"
                          name="city"
                          value={contact.city}
                          onChange={(e) =>
                            handleChangeTextInputOwner(
                              "city",
                              e.target.value,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="County"
                          variant="outlined"
                          size="small"
                          name="county_code"
                          value={contact.county_code}
                          onChange={(e) =>
                            handleChangeTextInputOwner(
                              "county_code",
                              e.target.value,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form">
                        <Autocomplete
                          id="country-select-demo"
                          size="small"
                          options={countryList}
                          onChange={(event, newValue) =>
                            handleChangeTextInputOwner(
                              "country_code",
                              newValue.id,
                              i,
                              "quote_owner_contacts"
                            )
                          }
                          // value={
                          //   addressDetails
                          //     ? countryList?.[countryName()-1] || null
                          //     :  countryList?.[contact.country_code - 1] || null
                          // }
                          value={
                            countryList?.[contact.country_code - 1] || null
                          }
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.name} ({option.iso2})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              name="country_code"
                              // value={countryList?.[leadInput.country_code] || null}
                            />
                          )}
                        />
                      </div>

                      {/* <div className="d-end">
                        <button
                          className="add-btn my-1"
                          onClick={() => {
                            handleOwnerAdd(ownerInput);
                            // handleContactAdd(contactInput)
                          }}
                        >
                          Check KYC
                        </button>
                        <button
                          className="add-btn ms-2 my-1"
                          onClick={() => toast("Canceled Checking")}
                        >
                          Cancel
                        </button>
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {priceQuoteInput?.quote_owners?.length > 0 && (
        <div className=" col-md-6 d-flex align-items-center justify-content-center">
          <div className="add-btn2 " onClick={handleAddMore}>
            <Add />
            ADD
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactInfoBox;
