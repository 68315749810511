export const LeadStatus = {
  0: "N/A",
  1: "New",
  2: "Call back arranged",
  3: "Future Oppurtunity",
  4: "Basic Info Requested",
  5: "Converted to Opportunity",
  6: "Didn't Make Inquiry",
  7: "Already Signed with Competitor",
  8: "Wrong Details",
  9: "Not Competitive",
  10: "Not Compatible",
};
export const AppStatus = {
  0: "Call Back Arranged",
  1: "Agreed in Principle",
  2: "Awaiting Docs",
  3: "Docs Received",
  4: "Future Opportunity",
  5:"Already Signed with Competitor",
  6: "Not Competitive",
  7: "Not Compatible",
  // 8: "Wrong Details",
  // 9: "Not Competitive",
  // 10: "Not Compatible",
};
