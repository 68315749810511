export const SET_PRICEQUOTE_UPDATED = "SET_PRICEQUOTE_UPDATED";
export const IS_LOAD_PRICE_QUOTE = "IS_LOAD_PRICE_QUOTE";
export const GET_PRICE_QUOTE_LIST = "GET_PRICE_QUOTE_LIST";
export const GET_PRICE_QUOTE_DETAILS = "GET_PRICE_QUOTE_DETAILS";
export const PRICE_QUOTE_PAGINATION = "PRICE_QUOTE_PAGINATION";
// export const SET_PRICE_QUOTE_FALSE = "SET_PRICE_QUOTE_FALSE";

export const GET_PRICE_QUOTE_INPUT = "GET_PRICE_QUOTE_INPUT";
// export const AFTER_SUCCESS_PRICE_QUOTE = "AFTER_SUCCESS_PRICE_QUOTE";
export const IS_QUOTE_OWNER_DELETED = "IS_QUOTE_OWNER_DELETED";
// ---------------------Product Types--------------------------
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const IS_LOADING_PRODUCT = "IS_LOADING_PRODUCT";
export const SUCCESS_PRODUCT = "SUCCESS_PRODUCT";
export const ERROR_PRODUCT = "ERROR_PRODUCT";
export const ADD_NEW_PRODUCT = "ADDDD_NEW_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const AFTER_SUCCESS_PRODUCT = "AFTER_SUCCESS_PRODUCT";
// ======================document======================
export const GET_IMAGE_INPUT = "GET_IMAGE_INPUT";
export const IS_SUCCESS_IMAGE = "IS_SUCCESS_IMAGE";
export const SET_NEW_IMAGE_INPUT = "SET_NEW_IMAGE_INPUT";
export const ADD_NEW_IMAGE = "ADD_NEW_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE"
export const SUCCESS_DOC = "SUCCESS_DOC";
// ---------------------qualify---------------------------
export const AFTER_SUCCESS_APPLICATION_QUALIFY = "AFTER_SUCCESS_APPLICATION_QUALIFY"
export const SET_FALSE_APPLICATION_QUALIFY = "SET_FALSE_APPLICATION_QUALIFY"
export const GET_QUALIFY_APPLICATION = "GET_QUALIFY_APPLICATION";

// ------------------------docs---------------------
export const GET_DOC_LIST = "GET_DOC_LIST"
export const GET_DOC_INPUT = "GET_DOC_INPUT"
export const ADD_NEW_DOC = "ADD_NEW_DOC"
export const DELETE_DOC = "DELETE_DOC"
export const SET_NEW_DOC_INPUT = "SET_NEW_DOC_INPUT"
export const ERROR_DOC = "ERROR_DOC"



// ------owner------------------
export const GET_OWNER_INPUT = "GET_OWNER_INPUT"
export const GET_OWNER_LIST = "GET_OWNER_LIST"
export const SET_OWNER_UPDATED = "SET_OWNER_UPDATED"
export const GET_OWNER_DETAILS = "GET_OWNER_DETAILS"
export const AFTER_SUCCESS_OWNER = "AFTER_SUCCESS_OWNER"
export const SET_OWNER_FALSE = "SET_OWNER_FALSE"


// ----------terminal----------------
export const TERMINAL_ADD = "TERMINAL_ADD"
export const TERMINAL_DELETE = "TERMINAL_DELETE"
export const SET_TERMINAL = "SET_TERMINAL"
// ===============product===================================

export const GET_QUOTE_PRODUCT = "GET_QUOTE_PRODUCT";
export const GET_PRODUCT_INPUT = "GET_PRODUCT_INPUT";
export const SET_QUOTE_PRODUCT = "SET_QUOTE_PRODUCT";
export const REMOVE_QUOTE_PRODUCT = "REMOVE_QUOTE_PRODUCT";
export const IS_QUOTE_PRODUCT_DELETED = "IS_QUOTE_PRODUCT_DELETED";
export const SUCCESS_QUOTE_PRODUCT = "SUCCESS_QUOTE_PRODUCT";


// =================post code================
export const GET_POST_CODE_LIST = "GET_POST_CODE_LIST";
export const GET_POST_CODE_ADDRESS = "GET_POST_CODE_ADDRESS";
export const SET_POST_CODE = "SET_POST_CODE";
export const REMOVE_POST_CODE = "REMOVE_POST_CODE";
export const IS_POST_CODE_DELETED = "IS_POST_CODE_DELETED";
export const SUCCESS_POST_CODE = "SUCCESS_POST_CODE";


