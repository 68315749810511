import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";


// import { SetApplicationDetails } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useParams } from "react-router-dom";


const CustomarDetail = ({ customerDetailEdit }) => {
  const { id } = useParams();
  const moment = require("moment");
  
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  // ---api call-----------------
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const applicationDetails = useSelector(
    (state) => state.applicationInfo.applicationDetails
  );
  const countryList = useSelector((state) => state.leadInfo.countryList);

  // useEffect(() => {
  //   // dispatch(SetApplicationUpdatedData(id));

  //   dispatch(GetCountryList(1));
  // }, []);
 

  return (
    <div className="customar-detail">
      {/* -------------deatil head ------------------ */}
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Customer Details</h4>
        </div>
        <div className="button-box">
          <button className="  custom-btn  flex " onClick={customerDetailEdit}>
            <img
              width={19}
              height={17}
              src={pen}
              style={{ marginRight: "7px" }}
              alt=""
            />
            <span style={{ fontSize: "14px" }}>Edit</span>
          </button>
        </div>
      </div>
      {/* -------------deatil head ------------------ */}
      {/* ------------detail content------------ */}
      {/* {!isLoadLeads &&
        applicationDetails &&
        applicationDetails !== null &&
        applicationDetails.length > 0 && ( */}
      {applicationInput?.business_owners?.map((item, index) => {
        return (
          <>
          {/* <p className="close_container">{index+1}</p> */}
          <div className="detail-content" key={applicationInput?.id}>
            <div>
              <p>Client ID</p>
              <span>{applicationInput?.client_id || "N/A"}</span>
            </div>
            <div>
              <p>Title<span className="required">*</span> </p>
              <span>{item.owner_title}</span>
             
              
            </div>
            <div>
              <p>First Name <span className="required">*</span></p>
              <span> {item?.owner_first_name}</span>
            </div>
            <div>
              <p>Last Name <span className="required">*</span></p>
              <span>{item?.owner_surname}</span>
            </div>
            <div>
              <p>Date of Birth <span className="required">*</span></p>
              <span>
                {item?.contact_dob &&
                 new Date(item?.contact_dob).getDate()}/{new Date(item?.contact_dob).getMonth() + 1}/{new Date(item?.contact_dob).getFullYear()
                  // moment(item?.contact_dob).format("DD MMMM YYYY")
                  }
              </span>
            </div>
            <div>
              <p>Email Address <span className="required">*</span></p>
              <span>{item?.owner_email}</span>
            </div>
            <div>
              <p>Mobile Number<span className="required">*</span></p>
              <span>{item?.owner_phone_no}</span>
            </div>
            <div>
              <p>Ownership Type</p>
              {item?.s_ownership === 1 ? (
                <span>Merchant Rents</span>
              ) : (
                <span>Merchant Rents</span>
              )}
            </div>
            <div>
              <p>Ownership Percentage <span className="required">*</span></p>
              <span>{item?.ownership_perc}</span>
            </div>
            <div>
              <p>Owner ID Number <span className="required">*</span></p>
              <span>{item?.owner_id_num}</span>
            </div>
            <div>
              <p>Issue Date <span className="required">*</span></p>

              <span>
                {item?.owner_issue_date &&
                new Date(item?.owner_issue_date).getDate()}/{new Date(item?.owner_issue_date).getMonth() + 1}/{new Date(item?.owner_issue_date).getFullYear()} 
              </span>
            </div>
            <div>
              <p>Expiry Date <span className="required">*</span></p>
              <span>
                {item?.owner_expiry_date &&
                new Date(item?.owner_expiry_date).getDate()}/{new Date(item?.owner_expiry_date).getMonth() + 1}/{new Date(item?.owner_expiry_date).getFullYear()}
              </span>
            </div>
            <div>
              <p>Is beneficial Owner?<span className="required">*</span></p>
              <span>{item?.is_beneficial_owner === true ? "Yes" : "No"}</span>
            </div>
            <div>
              <p>Is signatory? <span className="required">*</span></p>
              <span>{item?.is_signatory === true ? "Yes" : "No"}</span>
            </div>
            <div>
              <p>Is Owner? <span className="required">*</span></p>
              <span>{item?.is_owner === true ? "Yes" : "No"}</span>
            </div>
            <div>
              <p>Is Partnership? <span className="required">*</span></p>
              <span>{item?.is_partnership === true ? "Yes" : "No"}</span>
            </div>
            <div>
              <p>Is director? <span className="required">*</span></p>
              <span>{item?.is_director === true ? "Yes" : "No"}</span>
            </div>

            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Owner Nationality <span className="required">*</span></p>
                  {countryList?.map((option) => {
                    return (
                      <>
                        {option.id === item.owner_nationality && (
                          <span key={option.id} value={option.id}>
                            {option.nationality}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            
          </div>
          <div style={{ backgroundColor: "#f5f5f5" }}>
              <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
                <h4
                  style={{
                    // margin: "0 26px",
                    borderBottom: "0.4px solid #979797",
                    paddingBottom: "12px",
                    fontWeight:"bold"
                   
                  }}
                >
                  Private Residential Address
                </h4>
              </div>
              {/* <h5>Private Residential Address</h5> */}
              <div className="detail-content">
                <div>
                  <p>Postcode <span className="required">*</span></p>
                  <span>{item?.business_owner_contacts[0]?.zip_code}</span>
                </div>
                <div>
                  <p>Address 1<span className="required">*</span></p>
                  <span>{item?.business_owner_contacts[0]?.street_line_1}</span>
                </div>
                <div>
                  <p>Address 2</p>
                  <span>{item?.business_owner_contacts[0]?.locality}</span>
                </div>
                <div>
                  <p>Town / City<span className="required">*</span></p>
                  <span>{item?.business_owner_contacts[0]?.city}</span>
                </div>
                <div>
                  <p>County</p>
                  <span>{item?.business_owner_contacts[0]?.city}</span>
                </div>
                <div>
                  <p>Country<span className="required">*</span></p>
                  {countryList?.map((option) => {
                    return (
                      <>
                        {option.id ===
                          item?.business_owner_contacts[0]?.country_code && (
                          <span key={option.id} value={option.id}>
                            {option.name}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
          
        );
      })}

      {/* <div className="detail-content" key={applicationInput?.id}>
        <div>
          <p>Client ID</p>
          <span>{applicationInput?.client_id}</span>
        </div>
        <div>
          <p>First Name</p>
          <span>{applicationInput?.marchent_fullname?.split(" ")[0]}</span>
        </div>
        <div>
          <p>Last Name</p>
          <span>{applicationInput?.marchent_fullname?.split(" ")[1]}</span>
        </div>
        <div>
          <p>Email Address</p>
          <span>{applicationInput?.email}</span>
        </div>
        <div>
          <p>Mobile Number</p>
          <span>{applicationInput?.mobile}</span>
        </div>
        <div>
          <p>Ownership TYPE</p>
          {applicationInput?.s_ownership === 1 ? (
            <span>Merchant Rents</span>
          ) : (
            <span>Lorim Ipsum</span>
          )}
        </div>
        <div>
          <p>Ownership Percentage</p>
          {applicationInput?.business_owner?.map((item, index) => {
            return <span>{item.ownership_perc}</span>;
          })}
       
        </div>
        <div>
          <p>Role</p>
          <span>Lorim Ipsum</span>
        </div>
      </div> */}

      {/* )} */}
      {/* ------------detail content------------ */}
    </div>
  );
};

export default CustomarDetail;
