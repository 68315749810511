import axios from "axios";
import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";
export const InputTextValue = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.INPUT_LOGIN_VALUE, payload: formData });
};

export const SetSigninStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_LOGIN_FALSE, payload: false });
};

export const SignInData = (postData) => async (dispatch) => {
  if (postData.email.length === 0) {
    showToast("error", "Email shouldn't be empty !");
    return 0;
  } else if (postData.password.length === 0) {
    showToast("error", "Password shouldn't be empty");
    return 0;
  }

  const url = `${BASE_URL}api/v1/auth/login/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });
  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          showToast("success", res.data.message);
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          // Store data's to local storage
          if(postData.remember === true){
            localStorage.setItem("is_remember_me", true);
          }
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
     
          dispatch({ type: Types.AFTER_LOGIN_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        const message = JSON.parse(err.request.response).message;
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        // showToast("error", message);
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(message, {
          icon: "error",
        });
      });
  } catch (error) {}
};
