import * as Types from "../types/Types";
import * as Leads from "../../../../../mainLead/_redux/types/Types";
import * as user from "../../../../../../userManagement/_redux/types/Types";

import Axios from "axios";

import { BASE_URL } from "../../../../../../../Const";
import { showToast } from "../../../../../../../utils/ToastHelper";
import {
  GetLeadsnput,
  GetPriceQuoteDetails,
  GetPricequoteInput,
} from "../../../../_redux/action/LeadsAction";
import { GetApplicationInput } from "../../../newApplication/_redux/action/NewapplicationAction";

export const GetPriceQuoteQualify = () => (dispatch) => {
  let leadId = localStorage.getItem("leadId");
  const url = `${BASE_URL}api/v1/quotes/quote/${leadId}/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          // type: Types.GET_PRICE_QUOTE_DETAILS,
          type: Types.SET_PRICEQUOTE_UPDATED,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};
// // -----------PriceQuote Terminal--------------

// export const PQTerminalAdd = (updatedProducts) => {
//   return {
//     type: Types.TERMINAL_ADD,
//     payload: updatedProducts,
//   };
// };
// export const PQTerminalDelete = (uid) => {
//   return {
//     type: Types.TERMINAL_DELETE,
//     payload: uid,
//   };
// };
// export const PQTerminalSet = (data) => {
//   return {
//     type: Types.SET_TERMINAL,
//     payload: data,
//   };
// };

// ---------------------Product Api Start---------------

export const ProductLoading = (is_ON) => {
  return {
    type: Types.IS_LOADING_PRODUCT,
    payload: is_ON,
  };
};

export const ProductSuccess = (data) => {
  return {
    type: Types.SUCCESS_PRODUCT,
    payload: data,
  };
};
export const ProductError = (error) => {
  return {
    type: Types.ERROR_PRODUCT,
    payload: error,
  };
};

export const ProductUpdate = (updatedProducts) => {
  return {
    type: Types.UPDATE_PRODUCT,
    payload: updatedProducts,
  };
};
export const ProductDelete = (uid) => {
  return {
    type: Types.DELETE_PRODUCT,
    payload: uid,
  };
};

export const GetProductData = () => {
  const url = `${BASE_URL}api/v1/products/product/`;
  return (dispatch) => {
    Axios.get(url)
      .then((success) => {
        dispatch(
          ProductSuccess(
            success.data.data.results === undefined
              ? []
              : success.data.data.results
          )
        );
        dispatch(ProductLoading(false));
      })
      .catch((error) => {
        dispatch(ProductError(error.message));
        dispatch(ProductLoading(false));
      });
  };
};

export const PostQuoteProduct = (data) => {
  if (data.provider.length === 0) {
    showToast("error", "Provider  shouldn't be empty");
    return 0;
  } else if (data.price.length === 0) {
    showToast("error", "Price shouldn't be empty");
    return 0;
  } else if (data.product.length === 0) {
    showToast("error", "Product name shouldn't be empty");
    return 0;
  } else if (data.connection_type.length === 0) {
    showToast("error", "Connection Type  shouldn't be empty");
    return 0;
  } else if (data.product_term.length === 0) {
    showToast("error", "Product Term shouldn't be empty");
    return 0;
  }
  const url = `${BASE_URL}api/v1/quotes/product/`;
  return (dispatch) => {
    try {
      Axios.post(url, data)
        .then((res) => {
          dispatch(ProductLoading(false));
          showToast("success", "Prodcuts Added Successfully");
          dispatch({ type: Types.SUCCESS_PRODUCT, payload: true });
        })
        .catch((err) => {
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
          }
        });
    } catch (error) {
      showToast("error", "Something went wrong");
    }
  };
};

export const AddProduct = (data) => {
  return {
    type: Types.ADD_NEW_PRODUCT,
    payload: data,
  };
};

export const PostProductData = () => {
  const url = `${BASE_URL}api/v1/products/product/`;
  return (dispatch) => {
    Axios.get(url)
      .then((success) => {
        dispatch(
          ProductSuccess(
            success.data.data.results === undefined
              ? []
              : success.data.data.results
          )
        );
        dispatch(ProductLoading(false));
      })
      .catch((error) => {
        dispatch(ProductError(error.message));
        dispatch(ProductLoading(false));
      });
  };
};
// ---------------------Product Api End---------------
// ===========================document api start-------------------------------------
export const GetImageInput =
  (name, value, e, i, imageInputArray = undefined) =>
  (dispatch) => {
    //  if(imageInputArray !== undefined){
    const formData = {
      name: name,
      value: value,
      i,
    };
    dispatch({ type: Types.ADD_NEW_IMAGE, payload: formData });
    // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });

    if (name === "doc_type") {
      let reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        formData.name = "image_url";
        formData.value = reader.result;
        dispatch({ type: Types.ADD_NEW_IMAGE, payload: formData });
        // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
      };
      reader.readAsDataURL(file);
    }
    //  }
  };
export const SubmitImage = (data, i) => (dispatch) => {
  const formData = new FormData();
  const urlImg = `${BASE_URL}api/v1/auth/upload/`;

  formData.append("document", data.document);
  formData.append("doc_type", data.doc_type);
  formData.append("owner", data.owner);

  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.post(urlImg, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((resImg) => {
        if (resImg.data.status) {
          dispatch({ type: Types.IS_SUCCESS_IMAGE, payload: true });
          showToast("success", "Document Uploaded successfully");

          dispatch({
            type: Types.ADD_NEW_DOC,
            payload: { name: "doc", value: resImg.data.data.id, index: i },
          });
          dispatch({
            type: Types.ADD_NEW_DOC,
            payload: {
              name: "file_url",
              value: resImg.data.data.document_url,
              index: i,
            },
          });
          dispatch({
            type: Leads.ADD_NEW_QUOTE_DOC,
            payload: {
              name: "file_url",
              value: resImg.data.data.document_url,
              index: i,
            },
          });
          dispatch({
            type: Leads.ADD_NEW_QUOTE_DOC,
            payload: { name: "doc", value: resImg.data.data.id, index: i },
          });
          dispatch({
            type: user.GET_USER_INPUT,
            payload: { name: "image", value: resImg.data.data.id },
          });

          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        } else {
          showToast("error", resImg.data.message);
          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        const message = JSON.parse(err.request.response).message;
        showToast("error", message);
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};

// ------------------doc-----------------------------
export const GetDocInput =
  (name, value, index, e, application_docs = undefined) =>
  (dispatch) => {
    if (application_docs !== undefined) {
      dispatch({ type: Types.ADD_NEW_DOC, payload: { name, value, index } });
      if (name === "doc_type") {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          formData.name = "image_url";
          formData.value = reader.result;
          dispatch({ type: Types.ADD_NEW_DOC, payload: formData });
          // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
        };
        reader.readAsDataURL(file);
      }
      return;
    }

    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.ADD_NEW_DOC, payload: formData });
  };
export const submitDoc = (data, resolve, reject) => (dispatch) => {
  console.log(data.doc, reject, "  return 0");
  if (!data?.doc) {
    showToast("error", "File shouldn't be empty");
    return 0;
  } else if (!data?.category) {
    showToast("error", "File name shouldn't be empty");
    return 0;
  } else if (!data?.doc_type) {
    showToast("error", "Document Type shouldn't be empty");
    return 0;
  }
  if (data.category === "PROOF_OF_ID" && !data?.first_name) {
    showToast("error", "Document owner first name shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.sur_name) {
    showToast("error", "Document owner sur name shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.document_id) {
    showToast("error", "Document owner document ID shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.issuer_id) {
    showToast("error", "Document owner issuer ID shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.issue_date) {
    showToast("error", "Document owner issue date shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.expiry_date) {
    showToast("error", "Document owner expiry date shouldn't be empty");
    return 0;
  } else if (data.category === "PROOF_OF_ID" && !data?.country) {
    showToast("error", "Issuing country shouldn't be empty");
    return 0;
  }

  // }
  const url = `${BASE_URL}api/v1/quotes/docs/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });

  try {
    Axios.post(url, data)
      .then((res) => {
        resolve();
        dispatch({ type: Types.SUCCESS_DOC, payload: true });
        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        showToast("success", "Doc Added Successfully");
      })
      .catch((err) => {
        reject(err);
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          // showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
};

export const AddDoc = (data) => {
  return {
    type: Types.ADD_NEW_DOC,
    payload: data,
  };
};
export const SetDocumentStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SUCCESS_DOC, payload: false });
};
// ----owner---------

// --product---------------
export const GetQuoteProductInput =
  (name, value, index, quote_products = undefined) =>
  (dispatch) => {
    if (quote_products !== undefined) {
      dispatch({
        type: Types.GET_QUOTE_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_QUOTE_PRODUCT, payload: formData });
  };
export const submitProduct = (data, resolve, reject) => (dispatch) => {
  const url = `${BASE_URL}api/v1/quotes/product/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });

  try {
    Axios.post(url, data)
      .then((res) => {
        resolve();
        dispatch({ type: Types.SUCCESS_QUOTE_PRODUCT, payload: true });
        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        showToast("success", "Product Added Successfully");
      })
      .catch((err) => {
        reject(err);
        const errorMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(errorMsg)) {
        //   showToast("error", value[0]);
        // }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
};

export const SetProductStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SUCCESS_QUOTE_PRODUCT, payload: false });
};
// --------------delete------------
export const QuoteOwnerDelete = (id, qId) => (dispatch) => {
  const url = `${BASE_URL}api/v1/quotes/quote-owner/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        // showToast("error", res.data.message);
        showToast("success", "Owner remove successfully");
        dispatch({ type: Types.IS_QUOTE_OWNER_DELETED, payload: true });
        dispatch(GetPriceQuoteDetails(qId));
      }
    });
  } catch (error) {}
};

export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_QUOTE_OWNER_DELETED, payload: false });
};

export const GetOwnerInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_OWNER_INPUT, payload: formData });
};

export const SubmitOwnerInput = (data) => (dispatch) => {
  let leadId = localStorage.getItem("leadId");
  data.quote = leadId;

  const url = `${BASE_URL}api/v1/quotes/quote-owner/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });

          showToast("success", "Owner added successfully");

          localStorage.setItem("ownerId", res.data.data.id);
          dispatch({ type: Types.AFTER_SUCCESS_OWNER, payload: true });
          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};

export const SubmitOwnerUpdate = (data) => (dispatch) => {
  const url = `${BASE_URL}api/v1/quotes/quote-owner/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });

          showToast("success", "Owner Update successfully");

          localStorage.setItem("ownerId", res.data.data.id);

          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          showToast("error", value[0]);
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};
export const GetOwnerDetails = () => (dispatch) => {
  let ownerId = localStorage.getItem("ownerId");
  const url = `${BASE_URL}api/v1/quotes/quote-owner/${ownerId}/`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          // type: Types.GET_PRICE_QUOTE_DETAILS,
          type: Types.SET_OWNER_UPDATED,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};
export const GetOwnerList = () => (dispatch) => {
  let priceQId = localStorage.getItem("priceQId");
  const url = `${BASE_URL}api/v1/applications/application-business-owner?application=${priceQId}`;
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          // type: Types.GET_PRICE_QUOTE_DETAILS,
          type: Types.GET_OWNER_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: true });
};

export const GetCountryList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/auth/country/`;
  dispatch({ type: Leads.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Leads.GET_COUNTRY_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Leads.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Leads.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Leads.IS_LOAD_LEADS, payload: true });
};
// ----------------------------popst code------------------
export const GetPostCodeList = (postCode, text, des) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/utility/${postCode}/address-by-postcode/`;
  // const url = `${BASE_URL}api/v1/leads/utility/${company_house_no}/legal-name-by-number/`;

  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_POST_CODE_LIST,
          payload: res.data.data.data.Items,
        });

        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
  }
};
export const GetPostCodeList2 = (postCode, text, des) => (dispatch) => {
  let text_des = text + " " + des;
  console.log("text_des", text_des);
  const url = `${BASE_URL}api/v1/leads/utility/${text_des}/address-by-postcode/`;

  try {
    Axios.get(url, {
      params: {
        // add the parameters here
        container: postCode,
      },
    }).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_POST_CODE_LIST,
          payload: res.data.data.data.Items,
        });

        dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
  }
};

export const GetPostcodeDetails =
  (post_code_id, name, index, contact = undefined) =>
  (dispatch) => {
    const url = `${BASE_URL}api/v1/leads/utility/${post_code_id}/address-details/`;
    console.log(name,'name')
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_POST_CODE_ADDRESS,
            payload: res.data.data.data,
          });
          var add1;
          var add2;
          var firstPart;
          var secondPart;
          var lastSpaceIndex;
          const add_details = res.data.data.data;
          const full_add =
            add_details?.Line1 +
            " " +
            add_details?.Line2 +
            " " +
            add_details?.Line3 +
            " " +
            add_details?.Line4 +
            " " +
            add_details?.Line5;

          // if (full_add?.length > 20) {
          //   add1 = full_add.slice(0, 20);
          //   add2 = full_add.slice(20);
          // }
          if (full_add.length > 40) {
            firstPart = full_add.substring(0, 40);
            lastSpaceIndex = firstPart.lastIndexOf(" ");

            if (lastSpaceIndex !== -1) {
              secondPart = full_add.substring(lastSpaceIndex + 1);
              firstPart = full_add.substring(0, lastSpaceIndex);
            } else {
              secondPart = full_add.substring(40);
              firstPart = full_add.substring(0, 40);
            }
          }
         
          // if (res.data.data.data.Error) {
          //   dispatch(GetLeadsnput("trading_city", "Invalid Id"));
          //   dispatch(GetLeadsnput("trading_address1", "Invalid Id"));
          //   dispatch(GetLeadsnput("trading_address2", "Invalid Id"));
          // }
          // -------------------lead-------------------
          else if (name === "lead_legal_postcode") {
            console.log('hello')
            dispatch(
              GetLeadsnput("legal_postcode", res.data.data.data.PostalCode)
            );
            dispatch(GetLeadsnput("legal_city", res.data.data.data.City));
            if (firstPart) {
              dispatch(GetLeadsnput("legal_address1", firstPart));
            } else {
              dispatch(GetLeadsnput("legal_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetLeadsnput("legal_address2", secondPart));
            } else {
              dispatch(GetLeadsnput("legal_address2", add_details.Line2));
            }
          } else if (name === "lead_trading_postcode") {
            dispatch(
              GetLeadsnput("trading_postcode", res.data.data.data.PostalCode)
            );
            dispatch(GetLeadsnput("trading_city", res.data.data.data.City));
            if (firstPart) {
              dispatch(GetLeadsnput("trading_address1", firstPart));
            } else {
              dispatch(GetLeadsnput("trading_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetLeadsnput("trading_address2", secondPart));
            } else {
              dispatch(GetLeadsnput("trading_address2", add_details.Line2));
            }
          } else if (name === "lead_home_postcode") {
            dispatch(
              GetLeadsnput("home_postcode", res.data.data.data.PostalCode)
            );
            dispatch(GetLeadsnput("home_city", res.data.data.data.City));
            // dispatch(GetLeadsnput("home_address1", res.data.data.data.Line1));
            // dispatch(GetLeadsnput("home_address2", res.data.data.data.Line2));
            if (firstPart) {
              dispatch(GetLeadsnput("home_address1", firstPart));
            } else {
              dispatch(GetLeadsnput("home_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetLeadsnput("home_address2", secondPart));
            } else {
              dispatch(GetLeadsnput("home_address2", add_details.Line2));
            }
          }
          // -------------------quote-------------------
          else if (name === "quote_legal_postcode") {
            dispatch(
              GetPricequoteInput(
                "legal_postcode",
                res.data.data.data.PostalCode
              )
            );
            dispatch(GetPricequoteInput("legal_city", res.data.data.data.City));
            if (firstPart) {
              dispatch(GetPricequoteInput("legal_address1", firstPart));
            } else {
              dispatch(GetPricequoteInput("legal_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetPricequoteInput("legal_address2", secondPart));
            } else {
              dispatch(GetPricequoteInput("legal_address2", add_details.Line2));
            }
          } else if (name === "quote_trading_postcode") {
            dispatch(
              GetPricequoteInput(
                "trading_postcode",
                res.data.data.data.PostalCode
              )
            );
            dispatch(
              GetPricequoteInput("trading_city", res.data.data.data.City)
            );
            if (firstPart) {
              dispatch(GetPricequoteInput("trading_address1", firstPart));
            } else {
              dispatch(
                GetPricequoteInput("trading_address1", add_details.Line1)
              );
            }
            if (secondPart) {
              dispatch(GetPricequoteInput("trading_address2", secondPart));
            } else {
              dispatch(
                GetPricequoteInput("trading_address2", add_details.Line2)
              );
            }
          } else if (name === "quote_home_postcode") {
            dispatch(
              GetPricequoteInput("home_postcode", res.data.data.data.PostalCode)
            );
            dispatch(GetPricequoteInput("home_city", res.data.data.data.City));
            if (firstPart) {
              dispatch(GetPricequoteInput("home_address1", firstPart));
            } else {
              dispatch(GetPricequoteInput("home_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetPricequoteInput("home_address2", secondPart));
            } else {
              dispatch(GetPricequoteInput("home_address2", add_details.Line2));
            }
          }
          // -------------------application-------------------
          else if (name === "application_legal_postcode") {
            dispatch(
              GetApplicationInput(
                "legal_postcode",
                res.data.data.data.PostalCode
              )
            );
            dispatch(
              GetApplicationInput("legal_city", res.data.data.data.City)
            );
            if (firstPart) {
              dispatch(GetApplicationInput("legal_address1", firstPart));
            } else {
              dispatch(
                GetApplicationInput("legal_address1", add_details.Line1)
              );
            }
            if (secondPart) {
              dispatch(GetApplicationInput("legal_address2", secondPart));
            } else {
              dispatch(
                GetApplicationInput("legal_address2", add_details.Line2)
              );
            }
          } else if (name === "application_trading_postcode") {
            dispatch(
              GetApplicationInput(
                "trading_postcode",
                res.data.data.data.PostalCode
              )
            );
            dispatch(
              GetApplicationInput("trading_city", res.data.data.data.City)
            );
            if (firstPart) {
              dispatch(GetApplicationInput("trading_address1", firstPart));
            } else {
              dispatch(
                GetApplicationInput("trading_address1", add_details.Line1)
              );
            }
            if (secondPart) {
              dispatch(GetApplicationInput("trading_address2", secondPart));
            } else {
              dispatch(
                GetApplicationInput("trading_address2", add_details.Line2)
              );
            }
          } else if (name === "application_home_postcode") {
            dispatch(
              GetApplicationInput(
                "home_postcode",
                res.data.data.data.PostalCode
              )
            );
            dispatch(GetApplicationInput("home_city", res.data.data.data.City));
            if (firstPart) {
              dispatch(GetApplicationInput("home_address1", firstPart));
            } else {
              dispatch(GetApplicationInput("home_address1", add_details.Line1));
            }
            if (secondPart) {
              dispatch(GetApplicationInput("home_address2", secondPart));
            } else {
              dispatch(GetApplicationInput("home_address2", add_details.Line2));
            }
          }

          // =================owner==============
          else if (name === "app_owner_postcode") {
            dispatch(
              GetApplicationInput(
                "zip_code",
                res.data.data.data.PostalCode,
                index,
                "business_owners",
                contact
              )
            );
            if (firstPart) {
              dispatch(
                GetApplicationInput(
                  "street_line_1",
                  firstPart,
                  index,
                  "business_owners",
                  contact
                )
              );
            } else {
              dispatch(
                GetApplicationInput(
                  "street_line_1",
                  res.data.data.data.Line1,
                  index,
                  "business_owners",
                  contact
                )
              );
            }
            if (secondPart) {
              dispatch(
                GetApplicationInput(
                  "locality",
                  secondPart,
                  index,
                  "business_owners",
                  contact
                )
              );
            } else {
              dispatch(
                GetApplicationInput(
                  "locality",
                  res.data.data.data.Line2,
                  index,
                  "business_owners",
                  contact
                )
              );
            }

            dispatch(
              GetApplicationInput(
                "city",
                res.data.data.data.City,
                index,
                "business_owners",
                contact
              )
            );
          } else if (name === "quote_owner_postcode") {
            dispatch(
              GetPricequoteInput(
                "zip_code",
                res.data.data.data.PostalCode,
                index,
                "business_owners",
                contact
              )
            );
            if (firstPart) {
              dispatch(
                GetPricequoteInput(
                  "street_line_1",
                  firstPart,
                  index,
                  "business_owners",
                  contact
                )
              );
            } else {
              dispatch(
                GetPricequoteInput(
                  "street_line_1",
                  res.data.data.data.Line1,
                  index,
                  "business_owners",
                  contact
                )
              );
            }
            if (secondPart) {
              dispatch(
                GetPricequoteInput(
                  "locality",
                  secondPart,
                  index,
                  "business_owners",
                  contact
                )
              );
            } else {
              dispatch(
                GetPricequoteInput(
                  "locality",
                  res.data.data.data.Line2,
                  index,
                  "business_owners",
                  contact
                )
              );
            }
            dispatch(
              GetPricequoteInput(
                "city",
                res.data.data.data.City,
                index,
                "business_owners",
                contact
              )
            );
          }

          dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_PRICE_QUOTE, payload: false });
    }
  };
