import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../../asstes/icon.bablu/add-file.svg";

import { useEffect, useState } from "react";

import cross from "../../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";


import Button from "@mui/material/Button";

import {
  GetLeadsnput,
  GetTerminalProductInput,
} from "../../../_redux/action/LeadsAction";
import {
  REMOVE_CARD_TERMINAL_PRODUCT,
  SET_CARD_TERMINAL_PRODUCT,
} from "../../../_redux/types/Types";
import { GetAllProductList } from "../_redux/action/NewapplicationAction";
import { Quantity, TerminalModel, connectionType, contactLength, price, provider, searchProduct } from "../../commons/MendatoryName";
import { BASE_URL } from "../../../../../../Const";
import { provider_list,connectionLength } from "../../commons/Dropdown";

function CardMachinProduct({ type_name, id, lead_product }) {
  const epos_name = (
    <p>
      Provide EPOS Name <span className="required">*</span>
    </p>
  );
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const dispatch = useDispatch();
  const [elavonProduct, setelavonProduct] = useState([]);

  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const allProductList = useSelector(
    (state) => state.applicationInfo.allProductList
  );
  useEffect(() => {
    fetch(`${BASE_URL}api/v1/products/product/?provider=0`, {
      method: "GET",
      headers: {
        authorization: `Token ${localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setelavonProduct(data.data.results));
  }, []);

  useEffect(() => {
    dispatch(
      GetAllProductList(
        `${BASE_URL}api/v1/products/product/?provider=1&crm_product_type=0`
      )
    );
    dispatch(
      GetLeadsnput(
        "terminal_products",
        lead_product?.filter((item) => item?.product_type === "card_terminal")
      )
    );
  }, []);

  const handleAddMore = () => {
    dispatch({ type: SET_CARD_TERMINAL_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_CARD_TERMINAL_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetTerminalProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(GetTerminalProductInput(name, value, index, "terminal_products"));
    if (name === "provider" && value === 0) {
      dispatch(
        GetTerminalProductInput("contact_length", 0, index, "terminal_products")
      );
    } else if (name === "provider" && value === 1) {
      dispatch(
        GetTerminalProductInput("contact_length", 18, index, "terminal_products")
      );
    }
  };

  // const handleProductFill = (value, index) => {
  //   console.log("value", value);
  //   dispatch(
  //     GetTerminalProductInput("product", value.id, index, "terminal_products")
  //   );
  //   dispatch(
  //     GetTerminalProductInput(
  //       "connection_type",
  //       value?.connection_type[0]?.id,
  //       index
  //     )
  //   );

  //   dispatch(
  //     GetTerminalProductInput("terminal_model", value?.model_name, index)
  //   );
  //   dispatch(GetTerminalProductInput("price", value?.price, index));
  //   dispatch(GetTerminalProductInput("lead_id", leadInput?.id, index));
  // };
  return (
    <>
      {leadInput?.terminal_products?.length < 1 && (
        <div className="d-flex justify-content-between heading mb-form mt-4 ">
          <button className="fill_add_button" onClick={() => handleAddMore()}>
            <img
              width={14}
              style={{ marginRight: "14px", marginBottom: "3px" }}
              height={14}
              src={addFileIcon}
              alt=""
            />
            ADD PRODUCT
          </button>
        </div>
      )}

      <div
        className="container row select_div"
        style={{ marginBottom: "-15px " }}
      >
        {leadInput?.terminal_products?.map((product, i) => {
          return (
            <>
              {product.is_deleted === false && (
                <div
                  className="col-12 col-md-6 mb-3 text-capitalize"
                  key={product.id}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "8px",
                      padding: "40px 15px",
                      margin: "15px 0px",
                      border: "0.5px solid rgb(224, 224, 224)",
                    }}
                  >
                    <div
                      // onClick={() => handleremoveDoc(i)}
                      onClick={() => {
                        product.id
                          ? handleremoveProductTwo(i)
                          : handleremoveProduct(i);
                      }}
                      className="cross d-flex"
                      style={{
                        border: "1px solid #FFCDD2",
                        width: "24px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={cross} alt="" />
                    </div>
                    <>
                      <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Terminal Option: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "MR", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "MR"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              MONTHLY RENTAL
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "terminal_option",
                                  "OUTRIGHT",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.terminal_option === "OUTRIGHT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              OUTRIGHT
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "ET", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "ET"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              ELAVON TERMINAL
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "FT", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "FT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              FREE TERMINAL
                            </Button>
                          </>
                        </div>
                      </div>
                      <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Integration Availability: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <div>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "integration_availability",
                                  "INTEGRATED",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.integration_availability ===
                                "INTEGRATED"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              INTEGRATED
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "integration_availability",
                                  "STAND ALONE",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.integration_availability ===
                                "STAND ALONE"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              STAND ALONE
                            </Button>
                          </div>
                        </div>
                      </div>
                      {product.integration_availability === "INTEGRATED" && (
                        <div className="mb-form ">
                          <TextField
                            id="outlined-basic"
                            label={epos_name}
                            variant="outlined"
                            size="small"
                            type="text"
                            name="epos_name"
                            value={product.epos_name}
                            onChange={(e) => {
                              handleChangeInput("epos_name", e.target.value, i);
                            }}
                          />
                        </div>
                      )}
                    </>
                    <div className="mb-form">
                        <div className="col-lg-12 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label={provider}
                            name="provider"
                            value={product.provider}
                            onChange={(e) => {
                              handleChangeInput("provider", e.target.value, i);
                            }}
                          >
                            {provider_list?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                      <div className="mb-form">
                        {product.provider === 1 ? (
                          <div className="col-lg-12 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label={searchProduct}
                              name="product"
                              value={product.product}
                              onChange={(e) => {
                                allProductList?.map((item, index) => {
                                  if (item.id === e.target.value) {
                                    dispatch(
                                      GetTerminalProductInput(
                                        "connection_type",
                                        item?.connection_type[0]?.name,
                                        i
                                      )
                                    );

                                    // dispatch(
                                    //   GetTerminalProductInput(
                                    //     "contact_length",
                                    //     item.product_term[0].months,
                                    //     i
                                    //   )
                                    // );
                                    dispatch(
                                      GetTerminalProductInput(
                                        "terminal_model",
                                        item.model_name,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetTerminalProductInput(
                                        "manufecturer_name",
                                        item.manufacturer.name,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetTerminalProductInput(
                                        "monthly_price",
                                        item.price,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetTerminalProductInput(
                                        "application",
                                        applicationInput.id,
                                        i
                                      )
                                    );
                                  }
                                });

                                handleChangeInput("product", e.target.value, i);
                              }}
                            >
                              {allProductList?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        ) : (
                          product.provider === 0 && (
                            <div className="col-lg-12 mb-4">
                              <TextField
                                size="small"
                                id="outlined-select-currency"
                                select
                                label={searchProduct}
                                name="product"
                                value={product.product}
                                onChange={(e) => {
                                  elavonProduct?.map((item, index) => {
                                    if (item?.id === e.target.value) {
                                      dispatch(
                                        GetTerminalProductInput(
                                          "connection_type",
                                          item?.connection_type[0]?.name,
                                          i
                                        )
                                      );

                                      dispatch(
                                        GetTerminalProductInput(
                                          "terminal_model",
                                          item.model_name,
                                          i
                                        )
                                      );
                                      dispatch(
                                        GetTerminalProductInput(
                                          "manufecturer_name",
                                          item.manufacturer.name,
                                          i
                                        )
                                      );
                                      dispatch(
                                        GetTerminalProductInput(
                                          "monthly_price",
                                          item.price,
                                          i
                                        )
                                      );
                                      dispatch(
                                        GetTerminalProductInput(
                                          "application",
                                          applicationInput.id,
                                          i
                                        )
                                      );
                                    }
                                  });

                                  handleChangeInput(
                                    "product",
                                    e.target.value,
                                    i
                                  );
                                }}
                              >
                                {elavonProduct?.map((option) => (
                                  <MenuItem key={option?.id} value={option?.id}>
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          )
                        )}
                      </div>
                      <div className="">
                        <div className="row">
                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label={connectionType}
                              name="connection_type"
                              value={product.connection_type}
                              onChange={(e) =>
                                handleChangeInput(
                                  "connection_type",
                                  e.target.value,
                                  i
                                )
                              }
                            >
                              {product.provider === 1 &&
                                allProductList?.map((option) =>
                                  option.id === product.product
                                    ? option?.connection_type?.map((item) => (
                                        <MenuItem
                                          key={item.id}
                                          value={item?.name}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      ))
                                    : null
                                )}
                              {product?.provider === 0 &&
                                elavonProduct?.map((option) =>
                                  option?.id === product?.product
                                    ? option?.connection_type.map((item) => (
                                        <MenuItem
                                          key={item.id}
                                          value={item?.name}
                                        >
                                          {item?.name}
                                        </MenuItem>
                                      ))
                                    : null
                                )}
                            </TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              disabled={product.provider === 0 && true}
                              size="small"
                              id="outlined-select-currency"
                              select
                              label={contactLength}
                              name="contact_length"
                              value={product.contact_length}
                              onChange={(e) =>
                                handleChangeInput(
                                  "contact_length",
                                  e.target.value,
                                  i
                                )
                              }
                            >
                              {connectionLength?.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              //   select
                              label={TerminalModel}
                              name="terminal_model"
                              value={product.terminal_model}
                            ></TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              label={price}
                              name="monthly_price"
                              value={product.monthly_price}
                              onChange={(e) =>
                                handleChangeInput(
                                  "monthly_price",
                                  e.target.value,
                                  i
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-12 ">
                            <TextField
                              size="small"
                              type="number"
                              id="outlined-select-currency"
                              label={Quantity}
                              InputLabelProps={{ shrink: true }}
                              name="qty"
                              value={product.qty}
                              onWheel={(e) => e.target.blur()}
                              InputProps={{
                                inputProps: { min: 1 },
                              }}
                              error={product.qty < 1}
                              onChange={(e) =>
                                handleChangeInput("qty", e.target.value, i)
                              }
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
        {leadInput?.terminal_products?.length > 0 && (
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{ marginBottom: "40px" }}
          >
            <button className="fill_add_button" onClick={() => handleAddMore()}>
              <img
                width={14}
                style={{ marginRight: "14px", marginBottom: "3px" }}
                height={14}
                src={addFileIcon}
                alt=""
              />
              ADD PRODUCT
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default CardMachinProduct;
