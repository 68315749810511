export const boolian_value = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "2",
  },
];

export const opportunityStage = [
  {
    value: 0,
    label: " New",
  },
  {
    value: 1,
    label: "QUOTED",
  },
  {
    value: 2,
    label: "Contract Sent",
  },
  {
    value: 3,
    label: "Contract Returned",
  },
  {
    value: 4,
    label: "Sent for Processing",
  },
  {
    value: 5,
    label: "Lost",
  },
];
export const Opportunity_Status = [
  {
    value: 0,
    label: "Call Back Arranged",
  },
  {
    value: 1,
    label: "Agreed in Principle",
  },
  {
    value: 2,
    label: "Awaiting Docs",
  },
  {
    value: 3,
    label: "Docs Received",
  },
  {
    value: 4,
    label: "Future Opportunity",
  },
  {
    value: 5,
    label: "Already Signed with Competitor",
  },
  {
    value: 6,
    label: "Not Competitive",
  },
  {
    value: 7,
    label: "Not Compatible",
  },
];
export const OpportunityStatus = {
  0: "Call Back Arranged",

  1: "Agreed in Principle",

  2: "Awaiting Docs",

  3: "Docs Received",

  4: "Future Opportunity",

  5: "Already Signed with Competitor",

  6: "Not Competitive",

  7: "Not Compatible",
};
export const Application = [
  {
    value: 1,
    label: "New Application",
  },
  {
    value: 2,
    label: "New Ecom Ap",
  },
  {
    value: 3,
    label: "New VT App",
  },
  {
    value: 4,
    label: "Cole",
  },
  {
    value: 5,
    label: "Additional Location",
  },
  {
    value: 6,
    label: "Cole + Additional Location",
  },
  {
    value: 7,
    label: "Additional Location + Ecomm",
  },
  {
    value: 8,
    label: "Cole (Old)",
  },
  {
    value: 9,
    label: "Multiple Outlet",
  },
];
export const customer_lives = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "2",
  },
];

export const SiteLocationType = [
  {
    label: "Shopping Center",
    value: "ShoppingCenter",
  },
  {
    label: "Shop",
    value: "Shop",
  },
];

export const SiteLocationEnvironment = [
  {
    label: "Industrial Estate",
    value: "Industrial_Estate",
  },
];

export const SiteIndividualPosition = [
  {
    label: "Director",
    value: "1",
  },
];

export const SiteVicinityType = [
  {
    label: "Regeneration",
    value: "Regeneration",
  },
];

export const SiteSquireMeter = [
  {
    label: "1000+",
    value: "1000+",
  },
];

export const SiteGeneralAppearance = [
  {
    label: "Very Good",
    value: "very_good",
  },
];
export const SiteOwnership = [
  {
    label: "Merchant Rents",
    value: 2,
  },
  {
    label: "Merchant Owns",
    value: 1,
  },
];
// -lead summary----------------

export const LeadStatus = [
  {
    value: 0,
    label: "N/A",
  },
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Call back arranged",
  },
  {
    value: 3,
    label: "Future Opportunity",
  },
  {
    value: 4,
    label: "Basic Info Requested",
  },
  {
    value: 5,
    label: "Converted to Opportunity",
  },
  {
    value: 6,
    label: "Didn't Make Inquiry",
  },
  {
    value: 7,
    label: "Already Signed with Competitor",
  },
  {
    value: 8,
    label: "Wrong Details",
  },
  {
    value: 9,
    label: "Not Competitive",
  },
  {
    value: 10,
    label: "Not Compatible",
  },
];

export const LeadStage = [
  {
    value: 0,
    label: "New",
  },
  {
    value: 1,
    label: "No Answer",
  },
  {
    value: 2,
    label: "Made Contact",
  },
  {
    value: 3,
    label: "Lost",
  },
  {
    value: 4,
    label: "Appointment Set",
  },
];
export const LegalType = [
  {
    value: "ST",
    label: "Sole Trader",
  },
  {
    value: "CL",
    label: "Club",
  },

  {
    value: "CH",
    label: "Charity",
  },
  {
    value: "TR",
    label: "Trust",
  },
  {
    value: "PART",
    label: "Partnership",
  },
  {
    value: "LLP",
    label: "Limited Liability Partnership",
  },
  {
    value: "L",
    label: "Private Limited Company",
  },
  {
    value: "PL",
    label: "Public Limited Company",
  },
  {
    value: "OT",
    label: "Other",
  },
];
export const leadType = [
  {
    label: "Hot",
    value: 0,
  },
  {
    label: "Cold",
    value: 1,
  },
  {
    label: "Warm",
    value: 2,
  },
];
// business profile
export const entity_code = [
  {
    value: "N/A",
    label: "N/A",
  },
  {
    value: "53265",
    label: "53265",
  },
  {
    value: "53268",
    label: "53268",
  },
  {
    value: "53266",
    label: "53266",
  },
  {
    value: "53269",
    label: "53269",
  },
  {
    value: "53267",
    label: "53267",
  },
  {
    value: "53270",
    label: "53270",
  },
  {
    value: "52495",
    label: "52495",
  },
];

export const seasonal_sale = [
  {
    value: true,
    label: "yes",
  },
  {
    value: false,
    label: "No",
  },
];
export const fastest_payment = [
  {
    value: "1",
    label: "YES",
  },
  {
    value: "0",
    label: "NO",
  },
];

export const true_false = [
  {
    value: true,
    label: "yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const Applications_type = [
  {
    value: 1,
    label: "New Application",
  },
  {
    value: 2,
    label: "New Ecom Ap",
  },
  {
    value: "3",
    label: "New VT App",
  },
  {
    value: "4",
    label: "Cole",
  },
  {
    value: "5",
    label: "Additional Outlet",
  },
];
export const user_role = [
  {
    value: 0,
    label: "Lead Owner",
  },
  {
    value: 1,
    label: "Sales  Partner",
  },
  {
    value: 2,
    label: "Partner Manager",
  },
  {
    value: 3,
    label: "Epos Partner",
  },
  {
    value: 4,
    label: "Onboarding Manager",
  },
  {
    value: 5,
    label: "Onboarding Executive",
  },
];

export const TitleChoice = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "MRs.",
    label: "MRs.",
  },
  {
    value: "Ms.",
    label: "Ms.",
  },
  {
    value: "Miss.",
    label: "Miss.",
  },
  {
    value: "Sr.",
    label: "Sr.",
  },
  {
    value: "Jr.",
    label: "Jr.",
  },
  {
    value: "Master",
    label: "Master",
  },
];
export const payment_service_provider = [
  {
    value: "CARDSTREAM",
    label: "CARDSTREAM",
  },
  {
    value: "OPAYO",
    label: "OPAYO",
  },
];
export const ecom_provider = [
  {
    value: 0,
    label: "CARDSTREAM",
  },
  {
    value: 1,
    label: "OPAYO",
  },
];
export const internet_service_provider = [
  {
    value: "BT_BUSINESS",
    label: "BT BUSINESS ",
  },
];

export const applicationStage = [
  {
    value: 1,
    label: "Application received",
  },
  {
    value: 2,
    label: "PS Query",
  },
  {
    value: 3,
    label: "Waiting to fill up the form",
  },
  {
    value: 4,
    label: "Waiting to send for esign",
  },
  {
    value: 5,
    label: "Sent for esign",
  },
  {
    value: 6,
    label: "Application sign back",
  },
  {
    value: 7,
    label: "Sent to bank",
  },
  {
    value: 8,
    label: "Submission Errored",
  },
];

export const ApplicationStatus = [
  {
    value: 0,
    label: "New application",
  },
  {
    value: 1,
    label: "Sent to Bank",
  },
  {
    value: 2,
    label: "Bank Query",
  },
  {
    value: 3,
    label: "Auto Withdraw",
  },
  {
    value: 4,
    label: "Query Resolved",
  },
  {
    value: 5,
    label: "Approved",
  },
  {
    value: 6,
    label: "Terminal Ordered",
  },
  {
    value: 7,
    label: "Dispatched",
  },
  {
    value: 8,
    label: "Terminal Delivered",
  },
  {
    value: 9,
    label: "Not Live",
  },
  {
    value: 10,
    label: "Live",
  },
  {
    value: 11,
    label: "Transacting",
  },
  {
    value: 12,
    label: "Not Transacting",
  },
  {
    value: 13,
    label: "Cancelled",
  },
  {
    value: 14,
    label: "Declined",
  },
  {
    value: 15,
    label: "Closed",
  },
  {
    value: 16,
    label: "Fraud Closed",
  },
];
export const connectionType = [
  {
    value: "WIFI",
    label: "WIFI",
  },
  {
    value: "DESKTOP",
    label: "DESKTOP",
  },
  {
    value: "GPRS/3G/4G",
    label: "GPRS/3G/4G",
  },
  {
    value: "WiFi+GPRS",
    label: "WiFi+GPRS",
  },
  {
    value: "BT",
    label: "BT",
  },
  {
    value: "WiFi+GPRS+BT",
    label: "WiFi+GPRS+BT",
  },
  {
    value: "IWL258",
    label: "IWL258",
  },
  {
    value: "Countertop/Desktop",
    label: "Countertop/Desktop",
  },
  {
    value: "WIFI/CHARGING BASE 2",
    label: "WIFI/CHARGING BASE 2",
  },
  {
    value: "BLUETOOTH 2",
    label: "BLUETOOTH 2",
  },
];

export const connectionLength = [
  {
    value: 48,
    label: 48,
  },
  {
    value: 36,
    label: 36,
  },
  {
    value: 24,
    label: 24,
  },
  {
    value: 12,
    label: 12,
  },
  {
    value: 18,
    label: 18,
  },
  {
    value: 0,
    label: 0,
  },
];

export const provider_list = [
  {
    value: 0,
    label: "Elavon",
  },
  {
    value: 1,
    label: "Third party",
  },
];

export const documentType = [
  {
    label: "PROOF OF ID",
    value: "PROOF_OF_ID",
  },
  {
    label: "PROOF OF BUSINESS",
    value: "PROOF_OF_BUSINESS",
  },
  {
    label: "PROOF OF BANK",
    value: "PROOF_OF_BANK",
  },
  {
    label: "PROOF OF ADDRESS",
    value: "PROOF_OF_ADDRESS",
  },
  {
    label: "APPLICATION DOCUMENTS",
    value: "APPLICATION_DOCUMENTS",
  },
  {
    label: "OTHER",
    value: "OTHER",
  },
];
export const check_proof_of_id = [
  {
    label: "PASSPORT",
    value: "PASSPORT",
  },
  {
    label: "FULL PAPER DRIVING LICENCE",
    value: "FULL_PAPER_DRIVING_LICENCE",
  },
  {
    label: "GOVERNMENT ISSUED ID",
    value: "GOVERNMENT_ISSUED_ID",
  },
  {
    label: "EU RESIDENCE CARD FIVE YEAR",
    value: "EU_RESIDENCE_CARD_FIVE_YEAR",
  },
  {
    label: "SPANISH RESIDENT CARD",
    value: "SPANISH_RESIDENT_CARD",
  },
  {
    label: "KARTA POBYTUT",
    value: "KARTA_POBYTU",
  },
  {
    label: "FIREARMS CERTIFICATE",
    value: "FIREARMS_CERTIFICATE",
  },

  {
    label: "OTHER",
    value: "OTHER",
  },
];

export const check_proof_of_business = [
  {
    label: "BUSINESS BANK STATEMENTS",
    value: "BUSINESS_BANK_STATEMENTS",
  },
  {
    label: "BUSINESS UTILITY BILL",
    value: "BUSINESS_UTILITY_BILL",
  },
  {
    label: "BUSINESS RATES BILL",
    value: "BUSINESS_RATES_BILL",
  },
  {
    label: "BUSINESS BANKING COMMUNICATION",
    value: "BUSINESS_BANKING_COMMUNICATION",
  },
  {
    label: "HMRC VAT OR TAX DEMAND",
    value: "HMRC_VAT_OR_TAX_DEMAND",
  },
  {
    label: "MARKET TRADER CERTIFICATE",
    value: "MARKET_TRADER_CERTIFICATE",
  },
  {
    label: "OTHER",
    value: "OTHER",
  },
];
export const check_proof_of_home_add = [
  {
    label: "COUNCIL TAX CORRESPONDENCE",
    value: "COUNCIL_TAX_CORRESPONDENCE",
  },
  {
    label: " UTILITY BILL",
    value: "UTILITY_BILL",
  },
  {
    label: "MORTGAGE_STATEMENT",
    value: "MORTGAGE_STATEMENT",
  },
  {
    label: "FULL PHOTO DRIVING LICENCE",
    value: "FULL_PHOTO_DRIVING_LICENCE",
  },
  {
    label: "PROVISIONAL PHOTO DRIVING LICENCE",
    value: "PROVISIONAL_PHOTO_DRIVING_LICENCE",
  },
  {
    label: "BANK STATEMENT",
    value: "BANK_STATEMENT",
  },
  {
    label: "OTHER",
    value: "OTHER",
  },
];
export const PROOF_OF_BANK = [
  {
    label: "BUSINESS BANK STATEMENTS",
    value: "BUSINESS_BANK_STATEMENTS",
  },
  {
    label: "PAYING IN SLIP",
    value: "PAYING_IN_SLIP",
  },
  {
    label: "BUSINESS BANKING COMMUNICATION LETTER",
    value: "BUSINESS_BANKING_COMMUNICATION_LETTER",
  },

  {
    label: "VOIDED CHEQUE",
    value: "VOIDED_CHEQUE",
  },
  {
    label: "BUSINESS BANK WELCOME LETTER",
    value: "BUSINESS_BANK_WELCOME_LETTER",
  },
  {
    label: "OTHER",
    value: "OTHER",
  },
];
export const check_proof_of_other = [
  {
    label: " OTHER ",
    value: "OTHER",
  },
];
export const check_proof_of_application = [
  {
    label: "APPLICATION_DOCUMENT ",
    value: "APPLICATION_DOCUMENT",
  },
];
