import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
// import { SetApplicationDetails } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useParams } from "react-router-dom";
import {
  GetCountryList,
  GetIndustryList,
} from "../../../_redux/action/LeadsAction";
// import { GetCountryList, GetIndustryList } from "../../allAplication/mainLead/_redux/action/LeadsAction";

const BuisnessDetail = ({ buisnessDetailEdit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  // ---api call-----------------
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const applicationDetails = useSelector(
    (state) => state.applicationInfo.applicationDetails
  );
  // useEffect(() => {
  //   // dispatch(SetApplicationDetails(id));
  //   dispatch(GetIndustryList(1));
  //   dispatch(GetCountryList(1));
  // }, []);
  return (
    <div
      key={applicationInput?.id}
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100 "
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Business Profile</h4>
        </div>
        <div className="button-box">
          <button className="   custom-btn  flex " onClick={buisnessDetailEdit}>
            <img src={pen} style={{ marginRight: "7px" }} alt="" />
            Edit
          </button>
        </div>
      </div>

      {/* buissness content */}
      <div className="buissness-data">
        <div className="detail-content">
          <div>
            <p>Store Number <span className="required">*</span></p>
            <span>{applicationInput?.store_number}</span>
          </div>

          <div>
            <p>Description of Goods<span className="required">*</span></p>
            <span>{applicationInput?.desc_of_service}</span>
          </div>
          <div>
            <p>Seasonal sale<span className="required">*</span></p>
            {applicationInput?.seasonal_sales === true ? (
              <span>Yes</span>
            ) : (
              <span>False</span>
            )}
          </div>
          {applicationInput?.seasonal_sales === true && (
            <>
              <div>
                <p>Jan Feb Mar</p>
                <span>{applicationInput?.jan_to_mar}</span>
              </div>
              <div>
                <p>Apr May June</p>
                <span>{applicationInput?.apr_to_jun}</span>
              </div>
              <div>
                <p>Jul Aug Sep</p>
                <span>{applicationInput?.jul_to_sep}</span>
              </div>
              <div>
                <p>Oct Nov Dec</p>
                <span>{applicationInput?.oct_to_dec}</span>
              </div>
            </>
          )}

          <div>
            <p>Legal Name<span className="required">*</span></p>
            <span>{applicationInput?.legal_name}</span>
          </div>
          <div>
            <p>Trading Name<span className="required">*</span></p>
            <span>{applicationInput?.trading_name}</span>
          </div>
          <div>
            <p>Switching contribution<span className="required">*</span></p>
            <span>
              {" "}
              {applicationInput?.switching_contribution === true ? (
                <span>Yes</span>
              ) : (
                <span>False</span>
              )}
            </span>
          </div>

          <div>
            <p>New to card processing<span className="required">*</span></p>
            <span>
              {" "}
              {applicationInput?.new_to_card_proccessing === true ? (
                <span>Yes</span>
              ) : (
                <span>False</span>
              )}
            </span>
          </div>
          {applicationInput?.new_to_card_proccessing === false && (
            <div>
              <p>Previous Acquirer</p>
              <span> {applicationInput?.previous_acquirer}</span>
            </div>
          )}
          <div>
            <p>Do You Want Take Deposite<span className="required">*</span></p>
            <span>
              {" "}
              {applicationInput?.take_deposit === 1 ? (
                <span>Yes</span>
              ) : (
                <span>False</span>
              )}
            </span>
          </div>
          {/* ------------------take dposite------------------ */}
          {applicationInput?.take_deposit === 1 && (
            <>
              <div>
                <p>% of total transection value</p>
                <span> {applicationInput?.deposit_perc_transaction_value}</span>
              </div>

              <div>
                <p>Advance supply of goods/service</p>
                <span> {applicationInput?.advance_supply_deposite_taken}</span>
              </div>
              <div>
                <p>% of annual deposite of turnover</p>
                <span>
                  {" "}
                  {applicationInput?.perc_annual_deposite_of_turnover}
                </span>
              </div>
              <div>
                <p>Time between deposite and remaining payment</p>
                <span>
                  {" "}
                  {applicationInput?.time_btw_deposite_and_remaining_payment}
                </span>
              </div>
              <div>
                <p>Do You ever take full payment</p>
                <span>
                  {" "}
                  {applicationInput?.take_full_payment === true ? (
                    <span>Yes</span>
                  ) : (
                    <span>False</span>
                  )}
                </span>
              </div>
              {applicationInput?.take_full_payment === true && (
                <>
                  <div>
                    <p>How Far In Advance Of Supply Is The Full</p>
                    <span>
                      {" "}
                      {applicationInput?.advance_supply_full_payment}
                    </span>
                  </div>
                  <div>
                    <p>% of annual turnover upfront</p>
                    <span>
                      {" "}
                      {applicationInput?.perc_annual_upfront_of_turnover}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
          {/* ------------------take dposite end------------------ */}
          <div>
            <p>Vat Details </p>
            <span>
              {" "}
              {applicationInput?.vat_enabled === 0 ? (
                <span>Vat number</span>
              ) : applicationInput?.vat_enabled === 1 ? (
                <span>Vat number pending</span>
              ) : (
                <span>In business confirmation</span>
              )}
            </span>
          </div>
          {applicationInput.vat_enabled === 0 && (
            <>
              <div>
                <p>Vat number</p>
                <span> {applicationInput?.tax_id}</span>
              </div>
              <div>
                <p>Vat amount</p>
                <span> {applicationInput?.vat_amount}</span>
              </div>
            </>
          )}

          <div>
            <p>AMEX MID</p>
            <span>
              {" "}
              {applicationInput?.amex_mid === true ? (
                <span>Yes</span>
              ) : (
                <span>False</span>
              )}
            </span>
          </div>
          {applicationInput.amex_mid === true && (
            <div>
              <p>AMEX MID</p>
              <span>
                {" "}
                {applicationInput?.amex_type === "Existing AMEX" ? (
                  <span>Existing AMEX</span>
                ) : (
                  <span>New AMEX</span>
                )}
              </span>
            </div>
          )}
          <div>
            <p>Cash back</p>
            <span>
              {" "}
              {applicationInput?.cashback === true ? (
                <span>Yes</span>
              ) : (
                <span>False</span>
              )}
            </span>
          </div>
          {applicationInput.cashback === true && (
            <div>
              <p>Cashback amount</p>
              <span>{applicationInput?.avg_cashback_amount}</span>
            </div>
          )}
          <div>
            <p>Secured by Elavon (select one)<span className="required">*</span>:</p>
            <span>
              {" "}
              {applicationInput?.secured_by_elavon === "SECURED_PCI" ? (
                <span> Secured PCI</span>
              ) : (
                <span> Secured Pro</span>
              )}
            </span>
          </div>
          <div>
            <p>Product (£ per month)<span className="required">*</span></p>
            <span> {applicationInput?.product_per_month_amt}</span>
          </div>
          <div>
            <p>Non-Compliance (£ per month)<span className="required">*</span></p>
            <span> {applicationInput?.non_compliance_per_month}</span>
          </div>
          <div>
            <p>Custom Value (£ per month)<span className="required">*</span></p>
            <span> {applicationInput?.customer_value_per_month}</span>
          </div>
          {!isLoadLeads &&
            IndustryList &&
            IndustryList !== null &&
            IndustryList.length > 0 && (
              <div>
                <p>MCC Code<span className="required">*</span></p>
                {IndustryList?.map((option) => {
                  return (
                    <>
                      {option.id === applicationInput?.industry_type && (
                        <span key={option.id} value={option.id}>
                          {option?.mcc_iso}
                        </span>
                      )}
                    </>
                  );
                })}
              </div>
            )}

          {!isLoadLeads &&
            IndustryList &&
            IndustryList !== null &&
            IndustryList.length > 0 && (
              <div>
                <p>Industry Type<span className="required">*</span></p>
                {IndustryList?.map((option) => {
                  return (
                    <>
                      {option.id === applicationInput?.industry_type && (
                        <span key={option.id} value={option.id}>
                          {option?.description}
                        </span>
                      )}
                    </>
                  );
                })}
              </div>
            )}

        
        </div>
        {/* -----------------------legal address-------------------- */}
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <h4
              style={{
                borderBottom: "0.4px solid #979797",
                paddingBottom: "12px",
                fontWeight:"bold"
              }}
            >
              Legal Address Information
            </h4>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1<span className="required">*</span></p>
              <span> {applicationInput?.legal_address1}</span>
            </div>
            <div>
              <p>Address 2</p>
              <span> {applicationInput?.legal_address2}</span>
            </div>
            <div>
              <p>City/Town<span className="required">*</span></p>
              <span> {applicationInput?.legal_city}</span>
            </div>
            <div>
              <p>Post Code<span className="required">*</span></p>
              <span> {applicationInput?.legal_postcode}</span>
            </div>
            <div>
              <p>County</p>
              <span> {applicationInput?.legal_county}</span>
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country<span className="required">*</span></p>
                  {countryList.map((option) => {
                    return (
                      <>
                        {option.id === applicationInput?.legal_country && (
                          <span key={option.id} value={option.id}>
                            {option?.name}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
        {/* ------------------legal address -----------------*/}
        {/* --------------Trading Address----------------------- */}
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <p
              style={{
                borderBottom: "0.4px solid #979797",
                paddingBottom: "12px",
                fontWeight:"bold"
              }}
            >
              Trading Address Information
            </p>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1<span className="required">*</span></p>
              <span>{applicationInput?.trading_address1}</span>
            </div>
            <div>
              <p>Address 2</p>
              <span>{applicationInput?.trading_address2}</span>
            </div>
            <div>
              <p>City/Town<span className="required">*</span></p>
              <span>{applicationInput?.trading_city}</span>
            </div>
            <div>
              <p>Post Code<span className="required">*</span></p>
              <span>{applicationInput?.trading_postcode}</span>
            </div>
            <div>
              <p>County</p>
              <span>{applicationInput?.trading_county}</span>
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country<span className="required">*</span></p>
                  {countryList.map((option) => {
                    return (
                      <>
                        {option.id === applicationInput?.trading_country && (
                          <span key={option.id} value={option.id}>
                            {option?.name}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
        {/* --------------Trading Address----------------------- */}
        {/* --------------Home Address----------------------- */}
        <div style={{ backgroundColor: "#f5f5f5" }}>
          <div style={{ backgroundColor: "#f5f5f5", margin: "0 26px" }}>
            <h4
              style={{
                borderBottom: "0.4px solid #979797",
                    paddingBottom: "12px",
                    fontWeight:"bold"
              }}
            >
              Home Address Information
            </h4>
          </div>
          <div className="detail-content">
            <div>
              <p>Address 1<span className="required">*</span></p>
              <span>{applicationInput?.home_address1}</span>
            </div>
            <div>
              <p>Address 2</p>
              <span>{applicationInput?.home_address2}</span>
            </div>
            <div>
              <p>City/Town<span className="required">*</span></p>
              <span>{applicationInput?.home_city}</span>
            </div>
            <div>
              <p>Post Code<span className="required">*</span></p>
              <span>{applicationInput?.home_postcode}</span>
            </div>
            <div>
              <p>County</p>
              <span>{applicationInput?.home_county}</span>
            </div>
            {!isLoadLeads &&
              countryList &&
              countryList !== null &&
              countryList.length > 0 && (
                <div>
                  <p>Country<span className="required">*</span></p>
                  {countryList.map((option) => {
                    return (
                      <>
                        {option.id === applicationInput?.home_country && (
                          <span key={option.id} value={option.id}>
                            {option?.name}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
        {/* --------------Home Address----------------------- */}
      </div>
    </div>
  );
};

export default BuisnessDetail;
