import * as Types from "../types/Types";
const initialState = {
  UserInput: {
    first_name: null,
    last_name: null,
    email: null,
    mobile: null,
    password: null,
    image: null,
    gender: 0,
    country: 74,
    role: "",
    partner_manager: null,
    // legal_name: null,
    // trading_name: null,
    upfront_percentage: "",
    residual_percentage: "",
    partner_id: "",
    contact_id: "",
    is_active: true,
    is_superuser: true,
    files: [],
  },

  UserList: null,

  userPagination: null,

  UserDetails: null,
  isUserDeleted: null,
  countryList: null,
  isLoadUser: false,
  afterUserData: false,
};
const UserReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_USER_INPUT:
      const UserInput = { ...state.UserInput };
      UserInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        UserInput: UserInput,
      };

    case Types.IS_LOAD_USER:
      return {
        ...state,
        isLoadUser: action.payload,
      };
    case Types.AFTER_USER_DATA:
      return {
        ...state,
        afterUserData: action.payload,
      };
    case Types.GET_USER_LIST:
      return {
        ...state,
        UserList: action.payload,
      };

    case Types.GET_USER_DETAILS:
      return {
        ...state,
        UserDetails: action.payload,
      };
    case Types.SET_USER_FALSE:
      return {
        ...state,
        afterUserData: action.payload,
        UserInput: initialState.UserInput,
      };
    case Types.IS_USER_DELETED:
      return {
        ...state,
        isUserDeleted: action.payload,
      };

    case Types.SET_USER_UPDATED:
      const setUserInput = { ...state.UserInput };
      setUserInput.id = action.payload.id;
      setUserInput.first_name = action.payload.first_name;
      setUserInput.last_name = action.payload.last_name;
      setUserInput.email = action.payload.email;
      setUserInput.upfront_percentage = action.payload.upfront_percentage;
      setUserInput.residual_percentage = action.payload.residual_percentage;
      setUserInput.role = action.payload.role;
      setUserInput.partner_manager = action.payload.partner_manager;
      setUserInput.gender = action.payload.gender;

      setUserInput.partner_id = action.payload.partner_id;
      setUserInput.contact_id = action.payload.contact_id;

      setUserInput.country = action.payload.country;
      setUserInput.image = action.payload.image;
      setUserInput.image_url = action.payload.image_url;
      setUserInput.mobile = action.payload.mobile;
      setUserInput.password = action.payload.password;
      return {
        ...state,
        UserInput: setUserInput,
      };

    default:
      break;
  }
  return newState;
};

export default UserReducer;
