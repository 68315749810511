import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";

import { useDispatch, useSelector } from "react-redux";

import {
  GetLeadsnput,
  SubmitLeadsInput,
  UpdateLeads,
  SetLeadsStatusFalse,
  QualifyLeadList,
  SetQualifyStatusFalse,
  SetLeadqualifyStatusFalse,
  SubmitConvertPriceQuote,
  GetPriceQuoteDetails,
  UpdateConvertPriceQuote,
} from "../../_redux/action/LeadsAction";

import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Form, InputGroup } from "react-bootstrap";
import { BsCurrencyPound } from "react-icons/bs";
import { BASE_URL } from "../../../../../Const";
import { GetAllProductList } from "../newApplication/_redux/action/NewapplicationAction";
const Pricing = ({ next }) => {
  const options = [
    { value: "apple", label: "Apple" },
    { value: "banana", label: "Banana" },
    { value: "cherry", label: "Cherry" },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const allProductList = useSelector(
    (state) => state.applicationInfo.allProductList
  );
  const afterSuccessLeads = useSelector(
    (state) => state.leadInfo.afterSuccessLeads
  );
  const afterSuccessqualify = useSelector(
    (state) => state.leadInfo.afterSuccessqualify
  );
  const leadQualifyCheck = useSelector(
    (state) => state.leadInfo.leadQualifyCheck
  );
  const afterSuccessUpdate = useSelector(
    (state) => state.leadInfo.afterSuccessUpdate
  );
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  // const leadQualify = useSelector((state) => state.leadInfo.leadQualify);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  useEffect(() => {
    dispatch(GetAllProductList(`${BASE_URL}api/v1/products/product/`));
  }, []);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));

    let visa_devit_cost =
      (parseFloat(leadInput.visa_debit_cr) * leadInput.visa_debit_ts) / 100;

    //  --------payment save cost---------
    dispatch(GetLeadsnput("visa_debit_pc", visa_devit_cost_ps.toFixed(2)));
    dispatch(
      GetLeadsnput("mastercard_debit_pc", master_devit_cost_ps.toFixed(2))
    );
    dispatch(GetLeadsnput("visa_credit_pc", visa_credit_cost_ps.toFixed(2)));
    dispatch(
      GetLeadsnput("mastercard_credit_pc", master_credit_cost_ps.toFixed(2))
    );
    dispatch(
      GetLeadsnput("visa_business_debit_pc", visa_buss_debit_ps.toFixed(2))
    );
    dispatch(
      GetLeadsnput("visa_business_credit_pc", visa_buss_credit_ps.toFixed(2))
    );
    // dispatch(GetLeadsnput("card_acceptance_fee_pc", card_acc_ps.toFixed(2)));
    // dispatch(GetLeadsnput("card_transaction_fee_pc", card_trans_ps.toFixed(2)));
    dispatch(GetLeadsnput("authorization_fee_pc", auth_fee_ps.toFixed(2)));

    //  --------curr cost---------

    // dispatch(GetLeadsnput("visa_debit_cc",  visa_devit_cost.toFixed(2)));
    dispatch(GetLeadsnput("mastercard_debit_cc", master_devit_cost.toFixed(2)));
    dispatch(GetLeadsnput("visa_credit_cc", visa_credit_cost.toFixed(2)));
    dispatch(
      GetLeadsnput("mastercard_credit_cc", master_credit_cost.toFixed(2))
    );
    dispatch(
      GetLeadsnput("visa_business_debit_cc", visa_buss_debit.toFixed(2))
    );
    dispatch(
      GetLeadsnput("visa_business_credit_cc", visa_buss_credit.toFixed(2))
    );
    // dispatch(GetLeadsnput("card_acceptance_fee_cc", card_acc.toFixed(2)));
    // dispatch(GetLeadsnput("card_transaction_fee_cc", card_trans.toFixed(2)));
    dispatch(GetLeadsnput("authorization_fee_cc", auth_fee.toFixed(2)));
  };

  let visa_devit_cost =
    (parseFloat(leadInput.visa_debit_cr) * leadInput.visa_debit_ts) / 100;
  let master_devit_cost =
    (parseFloat(leadInput.mastercard_debit_cr) *
      leadInput.mastercard_debit_ts) /
    100;
  let visa_credit_cost =
    (parseFloat(leadInput.visa_credit_cr) * leadInput.visa_credit_ts) / 100;

  let master_credit_cost =
    (parseFloat(leadInput.mastercard_credit_cr) *
      leadInput.mastercard_credit_ts) /
    100;
  let visa_buss_debit =
    (parseFloat(leadInput.visa_business_debit_cr) *
      leadInput.visa_business_debit_ts) /
    100;
  let visa_buss_credit =
    (parseFloat(leadInput.visa_business_credit_cr) *
      leadInput.visa_business_credit_ts) /
    100;
  let card_acc =
    (parseFloat(leadInput.card_acceptance_fee_cr) *
      leadInput.card_acceptance_fee_ts) /
    100;
  let card_trans =
    (parseFloat(leadInput.card_transaction_fee_cr) *
      leadInput.card_transaction_fee_ts) /
    100;
  let auth_fee =
    (parseFloat(leadInput.authorization_fee_cr) *
      leadInput.authorization_fee_tr_no) /
    100;

  // ======payment save==================
  let visa_devit_cost_ps =
    (parseFloat(leadInput.visa_debit_pr) * leadInput.visa_debit_ts) / 100;
  let master_devit_cost_ps =
    (parseFloat(leadInput.mastercard_debit_pr) *
      leadInput.mastercard_debit_ts) /
    100;
  let visa_credit_cost_ps =
    (parseFloat(leadInput.visa_credit_pr) * leadInput.visa_credit_ts) / 100;

  let master_credit_cost_ps =
    (parseFloat(leadInput.mastercard_credit_pr) *
      leadInput.mastercard_credit_ts) /
    100;
  let visa_buss_debit_ps =
    (parseFloat(leadInput.visa_business_debit_pr) *
      leadInput.visa_business_debit_ts) /
    100;
  let visa_buss_credit_ps =
    (parseFloat(leadInput.visa_business_credit_pr) *
      leadInput.visa_business_credit_ts) /
    100;

  let auth_fee_ps =
    (parseFloat(leadInput.authorization_fee_pr) *
      leadInput.authorization_fee_tr_no) /
    100;
  // let card_acc_ps =
  //   (parseFloat(leadInput.card_acceptance_fee_pr) *
  //     leadInput.card_acceptance_fee_ts) /
  //   100;
  // let card_trans_ps =
  //   (parseFloat(leadInput.card_transaction_fee_pr) *
  //     leadInput.card_transaction_fee_ts) /
  //   100;
  let per_trans_pcharge_cc =
    parseFloat(leadInput.per_transactional_charge_cr) *
    leadInput.per_transactional_charge_tr_no;

  let per_trans_pcharge_pc =
    parseFloat(leadInput.per_transactional_charge_pr) *
    leadInput.per_transactional_charge_tr_no;

  let portal_reporting_cc = parseFloat(leadInput.portal_reporting_fee_cr) * 1;
  let portal_reporting_pc = parseFloat(leadInput.portal_reporting_fee_pr) * 1;

  let Pci_dss_fee_cc = parseFloat(leadInput.pci_dss_fee_cr) * 1;
  let Pci_dss_fee_pc = parseFloat(leadInput.pci_dss_fee_pr) * 1;

  let terminal_rental_cc =
    parseFloat(leadInput.terminal_rental_fee_cr) *
    parseFloat(leadInput.num_of_terminals) *
    1;
  let terminal_rental_pc =
    parseFloat(leadInput.terminal_rental_fee_pr) *
    parseFloat(leadInput.num_of_terminals) *
    1;
    let master_buss =
    (parseFloat(leadInput.mastercard_business_cr) *
      leadInput.mastercard_business_ts) /
    100;
  let master_corporet =
    (parseFloat(leadInput.mastercard_corporate_cr) *
      leadInput.mastercard_corporate_ts) /
    100;

    let master_buss_ps =
    (parseFloat(leadInput.mastercard_business_pr) *
      leadInput.mastercard_business_ts) /
    100;
  let master_cor_ps =
    (parseFloat(leadInput.mastercard_corporate_pr) *
      leadInput.mastercard_corporate_ts) /
    100;
    useEffect(() => {
      //  --------payment save cost---------
      dispatch(GetLeadsnput("visa_debit_pc", visa_devit_cost_ps.toFixed(3)));
      dispatch(
        GetLeadsnput("mastercard_debit_pc", master_devit_cost_ps.toFixed(3))
      );
      dispatch(GetLeadsnput("visa_credit_pc", visa_credit_cost_ps.toFixed(3)));
      dispatch(
        GetLeadsnput("mastercard_credit_pc", master_credit_cost_ps.toFixed(3))
      );
      dispatch(
        GetLeadsnput("visa_business_debit_pc", visa_buss_debit_ps.toFixed(3))
      );
     
      // dispatch(GetLeadsnput("card_acceptance_fee_pc", card_acc_ps.toFixed(3)));
      // dispatch(GetLeadsnput("card_transaction_fee_pc", card_trans_ps.toFixed(3)));
      dispatch(GetLeadsnput("authorization_fee_pc", auth_fee_ps.toFixed(3)));

       // dispatch(GetLeadsnput("mastercard_business_pc", master_buss_ps.toFixed(3)));
      // dispatch(GetLeadsnput("mastercard_business_pc", master_cor_ps.toFixed(3)));
      // dispatch(
      //   GetLeadsnput("mastercard_corporate_pc", visa_buss_credit_ps.toFixed(3))
      // );
  
      //  --------curr cost---------
  
      // dispatch(GetLeadsnput("visa_debit_cc",  visa_devit_cost.toFixed(3)));
      dispatch(GetLeadsnput("mastercard_debit_cc", master_devit_cost.toFixed(3)));
      dispatch(GetLeadsnput("visa_credit_cc", visa_credit_cost.toFixed(3)));
      dispatch(
        GetLeadsnput("mastercard_credit_cc", master_credit_cost.toFixed(3))
      );
      dispatch(
        GetLeadsnput("visa_business_debit_cc", visa_buss_debit.toFixed(3))
      );
      dispatch(
        GetLeadsnput("visa_business_credit_cc", visa_buss_credit.toFixed(3))
      );
      
      dispatch(GetLeadsnput("authorization_fee_cc", auth_fee.toFixed(3)));
      // dispatch(GetLeadsnput("mastercard_business_cc", master_buss.toFixed(3)));
      // dispatch(
      //   GetLeadsnput("mastercard_corporate_cc", master_corporet.toFixed(3))
      // );
    }, [
      auth_fee,
      ,
      visa_buss_credit,
      visa_buss_debit,
      master_credit_cost,
      visa_credit_cost,
      master_devit_cost,
      auth_fee_ps,
      visa_buss_credit_ps,
      visa_devit_cost_ps,
      master_devit_cost_ps,
      visa_credit_cost_ps,
      master_credit_cost_ps,
      visa_buss_debit_ps,
      // master_buss_ps,
      // master_cor_ps,
       // master_corporet,
      // master_buss,
     
    ]);
  useEffect(() => {
    dispatch(
      GetLeadsnput(
        "per_transactional_charge_cc",
        per_trans_pcharge_cc.toFixed(2)
      )
    );
    dispatch(
      GetLeadsnput(
        "per_transactional_charge_pc",
        per_trans_pcharge_pc.toFixed(2)
      )
    );
    dispatch(
      GetLeadsnput("portal_reporting_fee_cc", portal_reporting_cc.toFixed(2))
    );
    dispatch(
      GetLeadsnput("portal_reporting_fee_pc", portal_reporting_pc.toFixed(2))
    );
    dispatch(GetLeadsnput("pci_dss_fee_cc", Pci_dss_fee_cc.toFixed(2)));
    dispatch(GetLeadsnput("pci_dss_fee_pc", Pci_dss_fee_pc.toFixed(2)));
    dispatch(
      GetLeadsnput("terminal_rental_fee_cc", terminal_rental_cc.toFixed(2))
    );
    dispatch(
      GetLeadsnput("terminal_rental_fee_pc", terminal_rental_pc.toFixed(2))
    );
  }, [
    per_trans_pcharge_cc,
    per_trans_pcharge_pc,
    portal_reporting_pc,
    portal_reporting_cc,
    Pci_dss_fee_cc,
    Pci_dss_fee_pc,
    terminal_rental_cc,
    terminal_rental_pc,
  ]);

  useEffect(() => {
    dispatch(GetLeadsnput("visa_debit_cc", visa_devit_cost.toFixed(2)));
  }, [visa_devit_cost]);

  const totalCurrentCost = () => {
    var totalCurrentCost = 0;
    totalCurrentCost = (
      totalCurrentCost +
      parseFloat(leadInput?.visa_debit_cc) +
      parseFloat(leadInput?.visa_credit_cc) +
      parseFloat(leadInput?.mastercard_debit_cc) +
      parseFloat(leadInput?.mastercard_credit_cc) +
      parseFloat(leadInput?.visa_business_debit_cc) +
      parseFloat(leadInput?.visa_business_credit_cc) +
      // parseFloat(leadInput?.card_acceptance_fee_cc) +
      parseFloat(leadInput?.card_transaction_fee_cc) +
      parseFloat(leadInput?.authorization_fee_cc) +
      parseFloat(leadInput?.per_transactional_charge_cc) +
      parseFloat(leadInput?.portal_reporting_fee_cc) +
      parseFloat(leadInput?.pci_dss_fee_cc) +
      parseFloat(leadInput?.terminal_rental_fee_cc)
    ).toFixed(2);

    return totalCurrentCost;
  };

  const totalPaymentsaveCost = () => {
    let totalPaymentsaveCost = 0;
    totalPaymentsaveCost = (
      totalPaymentsaveCost +
      parseFloat(leadInput?.visa_debit_pc) +
      parseFloat(leadInput?.visa_credit_pc) +
      parseFloat(leadInput?.mastercard_debit_pc) +
      parseFloat(leadInput?.mastercard_credit_pc) +
      parseFloat(leadInput?.visa_business_debit_pc) +
      parseFloat(leadInput?.visa_business_credit_pc) +
      parseFloat(leadInput?.card_acceptance_fee_pc) +
      parseFloat(leadInput?.card_transaction_fee_pc) +
      parseFloat(leadInput?.authorization_fee_pc) +
      parseFloat(leadInput?.per_transactional_charge_pc) +
      parseFloat(leadInput?.portal_reporting_fee_pc) +
      parseFloat(leadInput?.pci_dss_fee_pc) +
      parseFloat(leadInput?.terminal_rental_fee_pc)
    ).toFixed(2);

    return totalPaymentsaveCost;
  };

  const handleAddLeads = (data, user) => {
    dispatch(GetLeadsnput("user", user));

    dispatch(SubmitLeadsInput(data));
  };

  const handleConvertTopriceQuote = (data, user) => {
    dispatch(GetLeadsnput("user", user));
    if (leadInput.id) {
      dispatch(UpdateConvertPriceQuote(data));
    } else {
      dispatch(SubmitConvertPriceQuote(data));
    }
  };
  const handleEditLead = (data) => {
    dispatch(UpdateLeads(data));
  };
  // ----------------leads---------------------

  // -------------------qualify----------------
  let leadId = localStorage.getItem("leadId");

  useEffect(() => {
    if (afterSuccessqualify === true) {
      dispatch(QualifyLeadList(leadId));
      // next();

      dispatch(SetQualifyStatusFalse());
    }
  }, [afterSuccessqualify]);
  useEffect(() => {
    if (leadQualifyCheck === true) {
      next();

      dispatch(SetLeadqualifyStatusFalse());
      dispatch(GetPriceQuoteDetails(priceQuoteInput.lead_qualify_id));
      localStorage.removeItem("selectedTab")
    }
  }, [leadQualifyCheck]);

  useEffect(() => {
    if (afterSuccessLeads === true) {
      history.push(`/leads`);
    }
  }, [afterSuccessLeads]);

  const handleCancel = () => {
    // dispatch(SetLeadsStatusFalse());
  };

  // useEffect(() => {
  //  dispatch(GetLeadsnput("lead_products", [...leadInput?.terminal_products, ...leadInput?.ecommerce_products, ...leadInput?.epos_products]))
  // }, [])
  useEffect(() => {
    dispatch(
      GetLeadsnput("lead_products", [
        ...(leadInput?.terminal_products ?? []),
        ...(leadInput?.ecommerce_products ?? []),
        ...(leadInput?.epos_products ?? []),
      ])
    );
    if(leadInput.callback_date === ""){
      dispatch(GetLeadsnput("callback_date" , null))
    }
    if(leadInput.appointment_date === ""){
      dispatch(GetLeadsnput("appointment_date" , null))
    }
  }, []);

  return (
    <div className="info-box text-capitalize">
      {isLoadLeads && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Cost Analysis</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-12 table-responsive mb-4 ">
            <Table striped className="table-body table-card">
              <thead className="table-head">
                <tr>
                  <th
                    style={{
                      position: "static",
                      color: "rgb(51, 51, 51) !important",
                    }}
                  >
                    Card Type
                  </th>
                  <th> Current Rate %</th>
                  <th> Paymentsave Rate %</th>
                  <th> (£) Total Sale </th>
                  <th>Number of Transactions</th>
                  <th>(£) Current Cost</th>
                  <th>(£) Paymentsave Cost</th>
                </tr>
              </thead>
              <tbody>
                {/* -------------------visa debit----------------- */}
                <tr>
                  <td>Visa Debit</td>
                  <td>
                    <input
                      // className="input"
                      className={` ${
                        leadInput.visa_debit_cr < 0 &&
                        leadInput.visa_debit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_debit_cr"
                      value={leadInput.visa_debit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        handleChangeInput("visa_debit_cr", e.target.value);
                      }}
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.visa_debit_pr < 0 &&
                        leadInput.visa_debit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_debit_pr"
                      value={leadInput.visa_debit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_pr", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_debit_ts < 0 &&
                        leadInput.visa_debit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_debit_ts"
                      value={parseInt(leadInput.visa_debit_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_ts", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_debit_tr_no < 0 &&
                        leadInput.visa_debit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="visa_debit_tr_no"
                      value={parseInt(leadInput.visa_debit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_tr_no", e.target.value)
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.visa_debit_cc < 0 &&
                        leadInput.visa_debit_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_debit_cc"
                      // value={leadInput.visa_debit_cc}
                      value={visa_devit_cost.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_cc", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_debit_pc < 0 &&
                        leadInput.visa_debit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_debit_pc"
                      value={visa_devit_cost_ps.toFixed(2)}
                      // value={leadInput.visa_debit_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_pc", e.target.value)
                      }
                    />
                  </td>
                </tr>
                {/* -------------------Mastercard debit----------------- */}
                <tr>
                  <td>Mastercard Debit</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_cr < 0 &&
                        leadInput.mastercard_debit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="mastercard_debit_cr"
                      value={leadInput.mastercard_debit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("mastercard_debit_cr", e.target.value)
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_pr < 0 &&
                        leadInput.mastercard_debit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="mastercard_debit_pr"
                      value={leadInput.mastercard_debit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("mastercard_debit_pr", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_ts < 0 &&
                        leadInput.mastercard_debit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_debit_ts"
                      value={parseInt(leadInput.mastercard_debit_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("mastercard_debit_ts", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_tr_no < 0 &&
                        leadInput.mastercard_debit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="mastercard_debit_tr_no"
                      value={parseInt(leadInput.mastercard_debit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_debit_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_cc < 0 &&
                        leadInput.mastercard_debit_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_debit_cc"
                      // value={leadInput.mastercard_debit_cc}
                      value={master_devit_cost.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("mastercard_debit_cc", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_debit_pc < 0 &&
                        leadInput.mastercard_debit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_debit_pc"
                      value={master_devit_cost_ps.toFixed(2)}
                      // value={leadInput.mastercard_debit_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("mastercard_debit_pc", e.target.value)
                      }
                    />
                  </td>
                </tr>
                {/* -------------------visa Credit----------------- */}
                <tr>
                  <td>Visa Credit</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_cr < 0 &&
                        leadInput.visa_credit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_credit_cr"
                      value={leadInput.visa_credit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_cr", e.target.value)
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_pr < 0 &&
                        leadInput.visa_credit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_credit_pr"
                      value={leadInput.visa_credit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_pr", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_ts < 0 &&
                        leadInput.visa_credit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_credit_ts"
                      value={parseInt(leadInput.visa_credit_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_ts", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_tr_no < 0 &&
                        leadInput.visa_credit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="visa_credit_tr_no"
                      value={parseInt(leadInput.visa_credit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_tr_no", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_cc < 0 &&
                        leadInput.visa_credit_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_credit_cc"
                      // value={leadInput.visa_credit_cc}
                      value={visa_credit_cost.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_cc", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_credit_pc < 0 &&
                        leadInput.visa_credit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_credit_pc"
                      value={visa_credit_cost_ps.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_pc", e.target.value)
                      }
                    />
                  </td>
                </tr>

                {/* ----------------------master credit---------------------- */}
                <tr>
                  <td>Mastercard Credit</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_credit_cr < 0 &&
                        leadInput.mastercard_credit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="mastercard_credit_cr"
                      value={leadInput.mastercard_credit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_credit_pr < 0 &&
                        leadInput.mastercard_credit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="mastercard_credit_pr"
                      value={leadInput.mastercard_credit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_credit_ts < 0 &&
                        leadInput.mastercard_credit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_credit_ts"
                      value={parseInt(leadInput.mastercard_credit_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_credit_tr_no < 0 &&
                        leadInput.mastercard_credit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="mastercard_credit_tr_no"
                      value={parseInt(leadInput.mastercard_credit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.master_credit_cost < 0 &&
                        leadInput.master_credit_cost !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_credit_cc"
                      // value={leadInput.mastercard_credit_cc}
                      value={master_credit_cost.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.mastercard_credit_pc < 0 &&
                        leadInput.mastercard_credit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="mastercard_credit_pc"
                      value={master_credit_cost_ps.toFixed(2)}
                      // onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "mastercard_credit_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>

                {/* -------------------------visa business debit-------------- */}
                <tr>
                  <td>Visa Business Debit</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_cr < 0 &&
                        leadInput.visa_business_debit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_business_debit_cr"
                      value={leadInput.visa_business_debit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_pr < 0 &&
                        leadInput.visa_business_debit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_business_debit_pr"
                      value={leadInput.visa_business_debit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_ts < 0 &&
                        leadInput.visa_business_debit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_debit_ts"
                      value={parseInt(leadInput.visa_business_debit_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_tr_no < 0 &&
                        leadInput.visa_business_debit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="visa_business_debit_tr_no"
                      value={parseInt(leadInput.visa_business_debit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_cc < 0 &&
                        leadInput.visa_business_debit_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_debit_cc"
                      value={visa_buss_debit.toFixed(2)}
                      // value={leadInput.visa_business_debit_cc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_debit_pc < 0 &&
                        leadInput.visa_business_debit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_debit_pc"
                      value={visa_buss_debit_ps.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  {/* ------------------------------visa business credit---------------------------- */}
                  <td>Visa Business Credit</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_cr < 0 &&
                        leadInput.visa_business_credit_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_business_credit_cr"
                      value={leadInput.visa_business_credit_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_pr < 0 &&
                        leadInput.visa_business_credit_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="visa_business_credit_pr"
                      value={leadInput.visa_business_credit_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_ts < 0 &&
                        leadInput.visa_business_credit_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_credit_ts"
                      value={leadInput.visa_business_credit_ts}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_tr_no < 0 &&
                        leadInput.visa_business_credit_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_credit_tr_no"
                      disabled
                      value={parseInt(leadInput.visa_business_credit_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_cc < 0 &&
                        leadInput.visa_business_credit_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_credit_cc"
                      value={visa_buss_credit.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.visa_business_credit_pc < 0 &&
                        leadInput.visa_business_credit_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="visa_business_credit_pc"
                      value={visa_buss_credit_ps.toFixed(2)}
                      // value={leadInput.visa_business_credit_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Authorization Fees</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_cr < 0 &&
                        leadInput.authorization_fee_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="authorization_fee_cr"
                      value={leadInput.authorization_fee_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_pr < 0 &&
                        leadInput.authorization_fee_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="authorization_fee_pr"
                      value={leadInput.authorization_fee_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_ts < 0 &&
                        leadInput.authorization_fee_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="authorization_fee_ts"
                      value={parseInt(leadInput.authorization_fee_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_tr_no < 0 &&
                        leadInput.authorization_fee_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="authorization_fee_tr_no"
                      value={parseInt(leadInput.authorization_fee_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_cc < 0 &&
                        leadInput.authorization_fee_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="authorization_fee_cc"
                      value={auth_fee.toFixed(2)}
                      // value={leadInput.authorization_fee_cc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.authorization_fee_pc < 0 &&
                        leadInput.authorization_fee_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="authorization_fee_pc"
                      value={auth_fee_ps.toFixed(2)}
                      // value={leadInput.authorization_fee_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "authorization_fee_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                {/* ------------------------------per transectional charge---------------------------- */}
                <tr>
                  <td>Per Transectional Charge</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_cr < 0 &&
                        leadInput.per_transactional_charge_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="per_transactional_charge_cr"
                      value={leadInput.per_transactional_charge_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_pr < 0 &&
                        leadInput.per_transactional_charge_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="per_transactional_charge_pr"
                      value={leadInput.per_transactional_charge_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_ts < 0 &&
                        leadInput.per_transactional_charge_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      disabled
                      placeholder="£"
                      name="per_transactional_charge_ts"
                      value={parseInt(leadInput.per_transactional_charge_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_tr_no < 0 &&
                        leadInput.per_transactional_charge_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="per_transactional_charge_tr_no"
                      value={parseInt(leadInput.per_transactional_charge_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_cc < 0 &&
                        leadInput.per_transactional_charge_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      readOnly
                      placeholder="£"
                      name="per_transactional_charge_cc"
                      value={leadInput.per_transactional_charge_cc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.per_transactional_charge_pc < 0 &&
                        leadInput.per_transactional_charge_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="per_transactional_charge_pc"
                      readOnly
                      value={leadInput.per_transactional_charge_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "per_transactional_charge_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                {/* -----------------------------portal repoarting--------------------------- */}
                <tr>
                  <td>Portal Reporting</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_cr < 0 &&
                        leadInput.portal_reporting_fee_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="portal_reporting_fee_cr"
                      value={leadInput.portal_reporting_fee_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_cr",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_pr < 0 &&
                        leadInput.portal_reporting_fee_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="portal_reporting_fee_pr"
                      value={leadInput.portal_reporting_fee_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_pr",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_ts < 0 &&
                        leadInput.portal_reporting_fee_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      disabled
                      type="number"
                      placeholder="£"
                      name="portal_reporting_fee_ts"
                      value={parseInt(leadInput.portal_reporting_fee_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_ts",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_tr_no < 0 &&
                        leadInput.portal_reporting_fee_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="portal_reporting_fee_tr_no"
                      value={parseInt(leadInput.portal_reporting_fee_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_cc < 0 &&
                        leadInput.portal_reporting_fee_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="portal_reporting_fee_cc"
                      value={leadInput.portal_reporting_fee_cc}
                      // value={card_trans.toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.portal_reporting_fee_pc < 0 &&
                        leadInput.portal_reporting_fee_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="portal_reporting_fee_pc"
                      readOnly
                      value={leadInput.portal_reporting_fee_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "portal_reporting_fee_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                {/* -----------------------------pci dds fee--------------------------- */}
                <tr>
                  <td>PCI DSS Fees</td>
                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_cr < 0 &&
                        leadInput.pci_dss_fee_cr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="pci_dss_fee_cr"
                      value={leadInput.pci_dss_fee_cr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_cr", e.target.value)
                      }
                    />
                  </td>

                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_pr < 0 &&
                        leadInput.pci_dss_fee_pr !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="%"
                      name="pci_dss_fee_pr"
                      value={leadInput.pci_dss_fee_pr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_pr", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_ts < 0 &&
                        leadInput.pci_dss_fee_ts !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      disabled
                      type="number"
                      placeholder="£"
                      name="pci_dss_fee_ts"
                      value={parseInt(leadInput.pci_dss_fee_ts)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_ts", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_tr_no < 0 &&
                        leadInput.pci_dss_fee_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      disabled
                      name="pci_dss_fee_tr_no"
                      value={parseInt(leadInput.pci_dss_fee_tr_no)}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_tr_no", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_cc < 0 &&
                        leadInput.pci_dss_fee_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="pci_dss_fee_cc"
                      readOnly
                      value={leadInput.pci_dss_fee_cc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_cc", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      className={` ${
                        leadInput.pci_dss_fee_pc < 0 &&
                        leadInput.pci_dss_fee_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="pci_dss_fee_pc"
                      readOnly
                      value={leadInput.pci_dss_fee_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("pci_dss_fee_pc", e.target.value)
                      }
                    />
                  </td>
                </tr>
                {/* -----------------------------Rental terminal--------------------------- */}
                <tr>
                  <td>Terminal Rental</td>
                  <td>
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Select
                        name="terminal_provider_pervious"
                        value={leadInput.terminal_provider_pervious}
                        onChange={(e) =>
                          handleChangeInput(
                            "terminal_provider_pervious",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select terminal...</option>
                        {allProductList?.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            // title="Select Terminal"
                          >
                            {option.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      controlId="exampleForm.SelectCustom"
                      className="mt-2"
                    >
                      <InputGroup>
                        <InputGroup.Text>
                          <BsCurrencyPound /> {/* insert the icon component */}
                        </InputGroup.Text>
                        <Form.Control
                          className={` ${
                            leadInput.terminal_rental_fee_cr < 0 &&
                            leadInput.terminal_rental_fee_cr !== ""
                              ? "error_input"
                              : " input"
                          }`}
                          type="number"
                          placeholder="£"
                          name="terminal_rental_fee_cr"
                          value={parseInt(leadInput.terminal_rental_fee_cr)}
                          // value={leadInput.terminal_rental_fee_cr}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) =>
                            handleChangeInput(
                              "terminal_rental_fee_cr",
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </td>

                  <td>
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Select
                        name="terminal_provider_current"
                        value={leadInput.terminal_provider_current}
                        onChange={(e) =>
                          handleChangeInput(
                            "terminal_provider_current",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Terminals...</option>
                        {allProductList?.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            // title="Select Terminal"
                          >
                            {option.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      controlId="exampleForm.SelectCustom"
                      className="mt-2"
                    >
                      <InputGroup>
                        <InputGroup.Text>
                          <BsCurrencyPound /> {/* insert the icon component */}
                        </InputGroup.Text>
                        <Form.Control
                          className={` ${
                            leadInput.terminal_rental_fee_pr < 0 &&
                            leadInput.terminal_rental_fee_pr !== ""
                              ? "error_input"
                              : " input"
                          }`}
                          type="number"
                          placeholder="£"
                          name="terminal_rental_fee_pr"
                          // value={card_trans_ps.toFixed(2)}
                          value={leadInput.terminal_rental_fee_pr}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) =>
                            handleChangeInput(
                              "terminal_rental_fee_pr",
                              e.target.value
                            )
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                  </td>
                  <td>
                    {/* <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Number of Terminals...</Form.Label>
                    </Form.Group> */}
                    <input
                      className={`mt-3 ${
                        leadInput.num_of_terminals < 0 &&
                        leadInput.num_of_terminals !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="Number of Terminals"
                      name="num_of_terminals"
                      // value={card_trans_ps.toFixed(2)}
                      value={leadInput.num_of_terminals}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("num_of_terminals", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <div></div>
                    <input
                      className={`mt-5 ${
                        leadInput.terminal_rental_fee_tr_no < 0 &&
                        leadInput.terminal_rental_fee_tr_no !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      disabled
                      placeholder="£"
                      name="terminal_rental_fee_tr_no"
                      value={leadInput.terminal_rental_fee_tr_no}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "terminal_rental_fee_tr_no",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <div></div>
                    <input
                      className={`mt-5 ${
                        leadInput.terminal_rental_fee_cc < 0 &&
                        leadInput.terminal_rental_fee_cc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="terminal_rental_fee_cc"
                      readOnly
                      value={leadInput.terminal_rental_fee_cc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "terminal_rental_fee_cc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <div></div>
                    <input
                      className={`mt-5 ${
                        leadInput.terminal_rental_fee_pc < 0 &&
                        leadInput.terminal_rental_fee_pc !== ""
                          ? "error_input"
                          : " input"
                      }`}
                      type="number"
                      placeholder="£"
                      name="terminal_rental_fee_pc"
                      readOnly
                      value={leadInput.terminal_rental_fee_pc}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput(
                          "terminal_rental_fee_pc",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                {/* ------------------------------Authorization Fee--------------------------- */}

                <tr>
                  <td>{""}</td>
                  <td>{""}</td>
                  <td>{""}</td>
                  <td>{""}</td>
                  <td>
                    <p className="total-text">Total:</p>
                  </td>
                  <td>
                    <div className="total-price">
                      <p>
                        {" "}
                        £ {/* {isNaN() === true ? 0 : totalCurrentCost() } */}
                        {totalCurrentCost()}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="total-price">
                      <p>£ {totalPaymentsaveCost()}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="mt-5 d-end">
            {leadInput?.id ? (
              <button
                //  style={{display:"none"}}
                className="success-btn"
                onClick={() => {
                  handleConvertTopriceQuote(leadInput, userData.id);

                  // next();
                }}
              >
                Convert to Price Quote
              </button>
            ) : (
              <button
                className="success-btn"
                onClick={() => {
                  handleConvertTopriceQuote(leadInput, userData.id);

                  // next();
                }}
              >
                Convert to Price Quote
              </button>
            )}

            <div>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            </div>

            {leadInput?.id ? (
              <>
                <button
                  style={{ marginRight: "15px" }}
                  className="save-btn mr-4"
                  onClick={() => handleEditLead(leadInput)}
                >
                  Update
                </button>
              </>
            ) : (
              <button
                className="save-btn"
                onClick={() => handleAddLeads(leadInput, userData.id)}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
