import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import pen from '../../../../../../asstes/icon.bablu/pen.svg'
import { useEffect } from "react";
// import {
//   GetApplicationInput,
//   SetApplicationUpdatedData,
//   SubmitApplicationInput,
// } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GetApplicationInput, SubmitApplicationInput } from "../_redux/action/NewapplicationAction";
import PreviewScheduleofFees from "./PreviewScheduleofFees";
import ApplicationProduct from "../ApplicationProduct";
import NewApplicationProduct from "../appProducts/NewApplicationProduct";
const PreviewProduct = ({ ProductEdit }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const applicationInput = useSelector(
      (state) => state.applicationInfo.applicationInput
    );
    // useEffect(() => {
    //   dispatch(SetApplicationUpdatedData(id));
    // }, []);
  
    const handleChangeInput = (name, value, e) => {
      dispatch(GetApplicationInput(name, value, e));
    };
    const handleSubmitAppplication = (data) => {
      dispatch(SubmitApplicationInput(data));
    };
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail mb-4 w-100 table-responsive"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Product</h4>
        </div>
        <div className="button-box">
          <button className="   custom-btn  flex " onClick={ProductEdit}>
            <img src={pen} style={{ marginRight: "7px" }} alt="" />
            Edit
          </button>
        </div>
      </div>
      {/* -------------fees content----------------- */}

     <NewApplicationProduct/>
     {/* <ApplicationProduct/> */}
      {/* -------------fees content----------------- */}
    </div>
  );
};

export default PreviewProduct;
