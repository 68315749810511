import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import SalesDetails from "./SalesDetails";

import Address from "./Address";
import FinanceInfo from "./FinanceInfo";
import BusinessProfile from "./BusinessProfile";

import AppSiteVisite from "./AppSiteVisite";
import SignConfirmation from "./SignConfirmation";

import ContactDetail from "./ContactDetail";

import Files from "./Files";
import Preview from "./Preview/Preview";
import AppScheduleFees from "./AppScheduleFees";
import ApplicationProduct from "./ApplicationProduct";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import NewApplicationProduct from "./appProducts/NewApplicationProduct";
export default function ApplicationTabs() {
  const history = useHistory();
  const { id } = useParams();
  const [value, setValue] = React.useState(
    localStorage.getItem("selectedTab") || "1"
  ); // retrieve the last selected tab from localStorage or default to "1"

  const handleChange = (event, newValue) => {
    localStorage.setItem("selectedTab", newValue); // save the new selected tab to localStorage
    setValue(newValue);
  };
  localStorage.setItem("appId", id);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);

  // ================================fixed sticky navbar===============
  const [navbar, setNavbar] = React.useState(false);
  const [color, setColor] = React.useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 110) {
      setNavbar(true);
    
    } else {
      setNavbar(false);
     
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);
  return (
    <div className="tab-wrapper">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className={navbar ? "scroll" : "navbar"}
          >
            <TabList
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="Summary" value="1" />
              <Tab label="Sales Details" value="2" />
              <Tab label="Product Details" value="3" />
              <Tab label="Schedule of Fees " value="4" />
              <Tab label="Business Profile " value="5" />
              <Tab label="Address " value="6" />
              <Tab label="Financial Information " value="7" />
              <Tab label="Document" value="8" />
              <Tab label="Site Visit" value="9" />
              {/* <Tab label="Sign & Confirm " value="10" /> */}
              <Tab label="Preview" value="11" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ContactDetail />
          </TabPanel>
          <TabPanel value="2">
            <SalesDetails />
          </TabPanel>
          <TabPanel value="3">
            {/* <ApplicationProduct /> */}
            <NewApplicationProduct/>
          </TabPanel>
          <TabPanel value="4">
            {/* <ScheduleFees /> */}
            <AppScheduleFees />
          </TabPanel>
          <TabPanel value="5">
            <BusinessProfile />
          </TabPanel>
          <TabPanel value="6">
            <Address />
          </TabPanel>
          <TabPanel value="7">
            <FinanceInfo />
          </TabPanel>
          <TabPanel value="8">
            <Files />
          </TabPanel>
          <TabPanel value="9">
            {/* <SiteVisit /> */}
            <AppSiteVisite />
          </TabPanel>
          {/* <TabPanel value="10">
            <SignConfirmation />
          </TabPanel> */}
          <TabPanel value="11">
            <Preview />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
