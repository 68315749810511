import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
// import pen from "../../../asstes/icon.bablu/pen.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// import {
//   GetApplicationInput,
//   SetApplicationUpdatedData,
//   SubmitApplicationInput,
// } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";


import {
  GetApplicationInput,
  SubmitApplicationInput,
  SubmitNewApplicationInput,
} from "../_redux/action/NewapplicationAction";
import PreviewContactDetails from "../PreviewContactDetails";
const ApplicationDetailEdit = ({ applicationDetailEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // useEffect(() => {
  //   dispatch(SetApplicationUpdatedData(id));
  // }, []);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };
  const Acquiring_Bank = [
    {
      value: "0",
      label: "Elavon",
    },
  ];
  return (
    <div className="customar-detail">
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} className="small-img" alt="" />
          <h4 style={{ color: "white" }}>Application Details</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={applicationDetailEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              applicationDetailEdit();
            }}
          >
            {/*  */}
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      {/* input  */}
      <PreviewContactDetails/>
      {/* <div className="detail-content">
        <div>
          <p>Acquiring Bank</p>
          <select
            className="input"
            aria-label="Default select example"
            name="acquiring_Bank"
            value={applicationInput.acquiring_Bank}
            onChange={(e) =>
              handleChangeInput("acquiring_Bank", e.target.value)
            }
          >
            {Acquiring_Bank.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Application Type</p>
          <select
            className="input"
            aria-label="Default select example"
            name="application_type"
            value={applicationInput.application_type}
            onChange={(e) =>
              handleChangeInput("application_type", e.target.value)
            }
          >
            {Application.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
       
        </div>
        <div>
          <p>Application Status</p>
          <select
            className="input"
            aria-label="Default select example"
            name="application_status"
            value={applicationInput.application_status}
            onChange={(e) =>
              handleChangeInput("application_status", e.target.value)
            }
          >
            {ApplicationStatus.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Application Stage</p>
          <select
            className="input"
            aria-label="Default select example"
            name="application_stage"
            value={applicationInput.application_stage}
            onChange={(e) =>
              handleChangeInput("application_stage", e.target.value)
            }
          >
            {applicationStage.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Faster Payment</p>
          <select
            className="input"
            aria-label="Default select example"
            name="bank_faster_payments"
            value={applicationInput.bank_faster_payments}
            onChange={(e) =>
              handleChangeInput("bank_faster_payments", e.target.value)
            }
          >
            {fastest_payment.map((item) => (
              <option key={item.id} value={item.id}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Est. Close Datek</p>
          <input
            className="input"
            type="date"
            placeholder="2022-10-12"
            name="application_close_date"
            value={applicationInput.application_close_date}
            onChange={(e) => {
              handleChangeInput("application_close_date", e.target.value);
            }}
          />
        </div>
        <div>
          <p>Call Back Date</p>
          <input
            className="input"
            type="date"
            placeholder="2022-10-12"
            name="callback_date"
            value={applicationInput.callback_date}
            onChange={(e) => {
              handleChangeInput("callback_date", e.target.value);
            }}
          />
        </div>
       
      </div> */}
    </div>
  );
};

export default ApplicationDetailEdit;
