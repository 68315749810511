import * as Types from "../types/Types";
import * as App from "../../../mainLead/components/newApplication/_redux/types/Types";
import axios from "axios";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../../Const";
import Store from "../../../../../redux/store/Store";

export const GetLeadtList = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/leads/lead/`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_LEADS_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      } else {
      }
    });
  } catch (error) {
  
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetIndustryList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/auth/industry/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_INDUSTRY_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
      //   if (!res.ok) {
      //     if ([401, 403].includes(res.status) && auth?.token) {
      //         // auto logout if 401 Unauthorized or 403 Forbidden res returned from api
      //         localStorage.removeItem('accessToken');
      //         // setAuth(null);
      //         history.push('/');
      //     }

      //     const error = (data && data.message) || res.statusText;
      //     // alertActions.error(error);
      //     return Promise.reject(error);
      // }
      else {
       
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetCountryList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/auth/country/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_COUNTRY_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetSourceList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/source/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_LEAD_SOURCE,
          payload: res.data.data.results,
        });
        dispatch({
          type: Types.LEADS_PAGINATION,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetSalesPartnerList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/auth/user/?role=1`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_SALES_PARTNER,
          payload: res.data.data.results,
        });
        dispatch({
          type: Types.LEADS_PAGINATION,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

//   ----------------------add lead--------------------

export const GetLeadsnput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_LEADS_INPUT, payload: formData });
};

export const SubmitLeadsInput = (data) => (dispatch) => {
  // delete data.ecommerce_products;
  // delete data.terminal_products;
  // delete data.epos_products;
  
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var validation = false;
  for (const product of data.lead_products) {
    if (!product.lead_id) delete product.lead_id;
    // if (!product.one_of_cost) delete product.one_of_cost;
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_provider === null || product?.terminal_provider === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_option === null || product?.terminal_option === "" )) {
      showToast("error", "Terminal option shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.integration_availability === null || product?.integration_availability === "" )) {
      showToast("error", "Integration availablity  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && product?.integration_availability === "INTEGRATED" && ( product?.epos_name === "" || product?.epos_name === null)) {
      showToast("error", "Epos name  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.product === "" ||  product?.product === null) ) {
      showToast("error", "Product  shouldn't be empty");
      validation = true;
    }
    // ====ecom product===========
    if (data.ecom_service === true &&  (product?.product_type === null || product?.product_type === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true && (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && ( product?.getway_provider === "" || product?.getway_provider === null)) {
      showToast("error", "Getway provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true &&  (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && (product?.website_url === "" ||  product?.website_url === null) ) {
      showToast("error", "Website url  shouldn't be empty");
      validation = true;
    }

    // =======epos=============
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_option === null || product?.epos_option === "" )) {
      showToast("error", "Epos option shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_provider === null || product?.epos_provider === "" )) {
      showToast("error", "Epos provider shouldn't be empty");
      validation = true;
    }
    // if (data.epos_service === true && product.product_type ==="epos" && (product?.one_of_cost === null || product?.one_of_cost === "" )) {
    //   showToast("error", "One of cost shouldn't be empty");
    //   validation = true;
    // }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.price === null || product?.price === "" )) {
      showToast("error", "Monthly shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.product_term === null || product?.product_term === "" )) {
      showToast("error", "Contact length be empty");
      validation = true;
    }
  }
  if (data?.lead_source === "" || data?.lead_source === null ) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
    // return 0;
  }
  if (data.legal_type === "" || data?.legal_type === null ) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (data.legal_name === "" || data?.legal_name === null) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (data.industry_type === "" || data?.industry_type === null) {
    showToast("error", "industry type shouldn't be empty");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty");
    validation = true;
  }
  if (!data.incorporated_on) {
    showToast("error", "In Corporated on shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", " Current Ownership Since shouldn't be empty");
    validation = true;
  }
  if (new Date().getFullYear() - new Date(data.dob).getFullYear() < 10) {
    showToast("error", "Invalid DOB");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  if (data.sales_partner === "") {
    showToast("error", "Sales partner shouldn't be empty!");
    validation = true;
  }
  if (data.lead_status === "") {
    showToast("error", "Lead status  shouldn't be empty!");
    validation = true;
  }
  if (data.lead_stage === "") {
    showToast("error", "Lead stage  shouldn't be empty!");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (data.legal_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit legal address post code !"
    );
    validation = true;
  }
  if (!data.legal_address1.length) {
    showToast("error", "Legal address1 shouldn't be empty !");
    validation = true;
  }
  if (!data.legal_city.length) {
    showToast("error", "Legal city shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_address1.length) {
    showToast("error", "Trading address1 shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit trading address post code!"
    );
    validation = true;
  }
  if (!data.trading_city.length) {
    showToast("error", "Trading city shouldn't be empty!");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (!data.visa_debit_cr) {
    showToast("error", "Visa debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_cr) < 0) {
    showToast("error", "Visa debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_debit_pr) {
    showToast("error", "Visa debit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_pr) < 0) {
    showToast("error", "Visa debit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_debit_ts === "") {
    showToast("error", "Visa debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_ts) < 0) {
    showToast("error", "Visa debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_cr) {
    showToast("error", "Mastercard debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_cr) < 0) {
    showToast("error", "Mastercard debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_pr) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_pr) < 0) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_debit_ts === "") {
    showToast("error", "Mastercard debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_ts) < 0) {
    showToast("error", "Mastercard debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_cr) {
    showToast("error", "Visa credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_cr) < 0) {
    showToast("error", "Visa credit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_pr) < 0) {
    showToast("error", "Visa credit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_credit_ts === "") {
    showToast("error", "Visa credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_ts) < 0) {
    showToast("error", "Visa credit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_business_debit_cr) {
    showToast("error", "Visa business debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_cr) < 0) {
    showToast(
      "error",
      "Visa business debit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_debit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_pr) < 0) {
    showToast(
      "error",
      "Visa business debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_debit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_ts) < 0) {
    showToast(
      "error",
      "Visa business debit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_cr) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_cr) < 0) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_pr) < 0) {
    showToast(
      "error",
      "Visa business credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_credit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_ts) < 0) {
    showToast(
      "error",
      "Visa business credit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_cr) {
    showToast("error", "Card acceptence fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_cr) < 0) {
    showToast(
      "error",
      "Card acceptence fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_pr) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_pr) < 0) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_acceptance_fee_ts === "") {
    showToast("error", "Card acceptence total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_ts) < 0) {
    showToast(
      "error",
      "Card acceptence fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_cr) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_cr) < 0) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_pr) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_pr) < 0) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_transaction_fee_ts === "") {
    showToast("error", "Card transection total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_ts) < 0) {
    showToast(
      "error",
      "Card transection fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_cr) {
    showToast("error", "Authorization fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.authorization_fee_cr) < 0) {
    showToast(
      "error",
      "Authorization fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_pr) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_pr) < 0) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.authorization_fee_tr_no === "") {
    showToast(
      "error",
      "Authorization fee number of transection  shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_tr_no) < 0) {
    showToast(
      "error",
      "Authorization fee  number of transection  shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_cr) {
    showToast("error", "Mastercard credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_cr) < 0) {
    showToast(
      "error",
      "Mastercard credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_pr) < 0) {
    showToast(
      "error",
      "Mastercard credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_credit_ts === "") {
    showToast("error", "Mastercard credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_ts) < 0) {
    showToast("error", "Mastercard credit total sale shouldn't be negative !");
    validation = true;
  }

  if (validation) {
    return 0;
  }

  const url = `${BASE_URL}api/v1/leads/lead/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          dispatch({ type: Types.AFTER_SUCCESS_LEADS, payload: true });
          showToast("success", "Leads create successfully");

          localStorage.setItem("leadId", res.data.data.id);

          // dispatch(GetLeadtList());
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const SubmitConvertPriceQuote = (data) => (dispatch) => {
  // delete data.ecommerce_products;
  // delete data.terminal_products;
  // delete data.epos_products;
  
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var validation = false;
  for (const product of data.lead_products) {
    if (!product.lead_id) delete product.lead_id;
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_provider === null || product?.terminal_provider === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_option === null || product?.terminal_option === "" )) {
      showToast("error", "Terminal option shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.integration_availability === null || product?.integration_availability === "" )) {
      showToast("error", "Integration availablity  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && product?.integration_availability === "INTEGRATED" && ( product?.epos_name === "" || product?.epos_name === null)) {
      showToast("error", "Epos name  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.product === "" ||  product?.product === null) ) {
      showToast("error", "Product  shouldn't be empty");
      validation = true;
    }
    // ====ecom product===========
    if (data.ecom_service === true &&  (product?.product_type === null || product?.product_type === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true && (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && ( product?.getway_provider === "" || product?.getway_provider === null)) {
      showToast("error", "Getway provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true &&  (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && (product?.website_url === "" ||  product?.website_url === null) ) {
      showToast("error", "Website url  shouldn't be empty");
      validation = true;
    }

    // =======epos=============
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_option === null || product?.epos_option === "" )) {
      showToast("error", "Epos option shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_provider === null || product?.epos_provider === "" )) {
      showToast("error", "Epos provider shouldn't be empty");
      validation = true;
    }
    // if (data.epos_service === true && product.product_type ==="epos" && (product?.one_of_cost === null || product?.one_of_cost === "" )) {
    //   showToast("error", "One of cost shouldn't be empty");
    //   validation = true;
    // }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.price === null || product?.price === "" )) {
      showToast("error", "Monthly shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.product_term === null || product?.product_term === "" )) {
      showToast("error", "Contact length be empty");
      validation = true;
    }
  }
  if (data?.lead_source === "" || data?.lead_source === null ) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
    // return 0;
  }
  if (data.legal_type === "" || data?.legal_type === null ) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (data.legal_name === "" || data?.legal_name === null) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (data.industry_type === "" || data?.industry_type === null) {
    showToast("error", "industry type shouldn't be empty");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty");
    validation = true;
  }
  if (!data.incorporated_on) {
    showToast("error", "In Corporated on shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", " Current Ownership Since shouldn't be empty");
    validation = true;
  }
  if (new Date().getFullYear() - new Date(data.dob).getFullYear() < 10) {
    showToast("error", "Invalid DOB");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  if (data.sales_partner === "") {
    showToast("error", "Sales partner shouldn't be empty!");
    validation = true;
  }
  if (data.lead_status === "") {
    showToast("error", "Lead status  shouldn't be empty!");
    validation = true;
  }
  if (data.lead_stage === "") {
    showToast("error", "Lead stage  shouldn't be empty!");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (data.legal_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit legal address post code !"
    );
    validation = true;
  }
  if (!data.legal_address1.length) {
    showToast("error", "Legal address1 shouldn't be empty !");
    validation = true;
  }
  if (!data.legal_city.length) {
    showToast("error", "Legal city shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_address1.length) {
    showToast("error", "Trading address1 shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit trading address post code!"
    );
    validation = true;
  }
  if (!data.trading_city.length) {
    showToast("error", "Trading city shouldn't be empty!");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (!data.visa_debit_cr) {
    showToast("error", "Visa debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_cr) < 0) {
    showToast("error", "Visa debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_debit_pr) {
    showToast("error", "Visa debit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_pr) < 0) {
    showToast("error", "Visa debit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_debit_ts === "") {
    showToast("error", "Visa debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_ts) < 0) {
    showToast("error", "Visa debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_cr) {
    showToast("error", "Mastercard debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_cr) < 0) {
    showToast("error", "Mastercard debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_pr) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_pr) < 0) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_debit_ts === "") {
    showToast("error", "Mastercard debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_ts) < 0) {
    showToast("error", "Mastercard debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_cr) {
    showToast("error", "Visa credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_cr) < 0) {
    showToast("error", "Visa credit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_pr) < 0) {
    showToast("error", "Visa credit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_credit_ts === "") {
    showToast("error", "Visa credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_ts) < 0) {
    showToast("error", "Visa credit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_business_debit_cr) {
    showToast("error", "Visa business debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_cr) < 0) {
    showToast(
      "error",
      "Visa business debit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_debit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_pr) < 0) {
    showToast(
      "error",
      "Visa business debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_debit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_ts) < 0) {
    showToast(
      "error",
      "Visa business debit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_cr) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_cr) < 0) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_pr) < 0) {
    showToast(
      "error",
      "Visa business credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_credit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_ts) < 0) {
    showToast(
      "error",
      "Visa business credit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_cr) {
    showToast("error", "Card acceptence fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_cr) < 0) {
    showToast(
      "error",
      "Card acceptence fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_pr) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_pr) < 0) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_acceptance_fee_ts === "") {
    showToast("error", "Card acceptence total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_ts) < 0) {
    showToast(
      "error",
      "Card acceptence fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_cr) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_cr) < 0) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_pr) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_pr) < 0) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_transaction_fee_ts === "") {
    showToast("error", "Card transection total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_ts) < 0) {
    showToast(
      "error",
      "Card transection fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_cr) {
    showToast("error", "Authorization fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.authorization_fee_cr) < 0) {
    showToast(
      "error",
      "Authorization fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_pr) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_pr) < 0) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.authorization_fee_tr_no === "") {
    showToast(
      "error",
      "Authorization fee number of transection  shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_tr_no) < 0) {
    showToast(
      "error",
      "Authorization fee  number of transection  shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_cr) {
    showToast("error", "Mastercard credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_cr) < 0) {
    showToast(
      "error",
      "Mastercard credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_pr) < 0) {
    showToast(
      "error",
      "Mastercard credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_credit_ts === "") {
    showToast("error", "Mastercard credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_ts) < 0) {
    showToast("error", "Mastercard credit total sale shouldn't be negative !");
    validation = true;
  }

  if (validation) {
    return 0;
  }

  const url = `${BASE_URL}api/v1/leads/lead/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          // showToast("success", "Leads Qualify successfully");

          dispatch({ type: Types.AFTER_SUCCESS_QUALIFY, payload: true });
          localStorage.setItem("leadId", res.data.data.id);
          dispatch({
            type: Types.GET_PRICE_QUOTE_INPUT,
            payload: { name: "qualify_id", value: res.data.data.id },
          });


          // dispatch(GetLeadtList());
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};
export const UpdateConvertPriceQuote = (data) => (dispatch) => {
  delete data?.ecommerce_products;
  delete data?.terminal_products;
  delete data?.epos_products;

  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var validation = false;
  for (const product of data.lead_products) {
    if (!product.lead_id) delete product.lead_id;
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_provider === null || product?.terminal_provider === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_option === null || product?.terminal_option === "" )) {
      showToast("error", "Terminal option shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.integration_availability === null || product?.integration_availability === "" )) {
      showToast("error", "Integration availablity  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && product?.integration_availability === "INTEGRATED" && ( product?.epos_name === "" || product?.epos_name === null)) {
      showToast("error", "Epos name  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.product === "" ||  product?.product === null) ) {
      showToast("error", "Product  shouldn't be empty");
      validation = true;
    }
    // ====ecom product===========
    if (data.ecom_service === true &&  (product?.product_type === null || product?.product_type === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true && (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && ( product?.getway_provider === "" || product?.getway_provider === null)) {
      showToast("error", "Getway provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true &&  (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && (product?.website_url === "" ||  product?.website_url === null) ) {
      showToast("error", "Website url  shouldn't be empty");
      validation = true;
    }

    // =======epos=============
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_option === null || product?.epos_option === "" )) {
      showToast("error", "Epos option shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_provider === null || product?.epos_provider === "" )) {
      showToast("error", "Epos provider shouldn't be empty");
      validation = true;
    }
    // if (data.epos_service === true && product.product_type ==="epos" && (product?.one_of_cost === null || product?.one_of_cost === "" )) {
    //   showToast("error", "One of cost shouldn't be empty");
    //   validation = true;
    // }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.price === null || product?.price === "" )) {
      showToast("error", "Monthly shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.product_term === null || product?.product_term === "" )) {
      showToast("error", "Contact length be empty");
      validation = true;
    }
  }
  if (data?.lead_source === "" || data?.lead_source === null ) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
    // return 0;
  }
  if (data.legal_type === "" || data?.legal_type === null ) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (data.legal_name === "" || data?.legal_name === null) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (data.industry_type === "" || data?.industry_type === null) {
    showToast("error", "industry type shouldn't be empty");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty");
    validation = true;
  }
  if (!data.incorporated_on) {
    showToast("error", "In Corporated on shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", " Current Ownership Since shouldn't be empty");
    validation = true;
  }
  if (new Date().getFullYear() - new Date(data.dob).getFullYear() < 10) {
    showToast("error", "Invalid DOB");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  if (data.sales_partner === "") {
    showToast("error", "Sales partner shouldn't be empty!");
    validation = true;
  }
  if (data.lead_status === "") {
    showToast("error", "Lead status  shouldn't be empty!");
    validation = true;
  }
  if (data.lead_stage === "") {
    showToast("error", "Lead stage  shouldn't be empty!");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (data.legal_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit legal address post code !"
    );
    validation = true;
  }
  if (!data.legal_address1.length) {
    showToast("error", "Legal address1 shouldn't be empty !");
    validation = true;
  }
  if (!data.legal_city.length) {
    showToast("error", "Legal city shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_address1.length) {
    showToast("error", "Trading address1 shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit trading address post code!"
    );
    validation = true;
  }
  if (!data.trading_city.length) {
    showToast("error", "Trading city shouldn't be empty!");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (!data.visa_debit_cr) {
    showToast("error", "Visa debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_cr) < 0) {
    showToast("error", "Visa debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_debit_pr) {
    showToast("error", "Visa debit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_pr) < 0) {
    showToast("error", "Visa debit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_debit_ts === "") {
    showToast("error", "Visa debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_ts) < 0) {
    showToast("error", "Visa debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_cr) {
    showToast("error", "Mastercard debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_cr) < 0) {
    showToast("error", "Mastercard debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_pr) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_pr) < 0) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_debit_ts === "") {
    showToast("error", "Mastercard debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_ts) < 0) {
    showToast("error", "Mastercard debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_cr) {
    showToast("error", "Visa credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_cr) < 0) {
    showToast("error", "Visa credit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_pr) < 0) {
    showToast("error", "Visa credit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_credit_ts === "") {
    showToast("error", "Visa credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_ts) < 0) {
    showToast("error", "Visa credit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_business_debit_cr) {
    showToast("error", "Visa business debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_cr) < 0) {
    showToast(
      "error",
      "Visa business debit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_debit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_pr) < 0) {
    showToast(
      "error",
      "Visa business debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_debit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_ts) < 0) {
    showToast(
      "error",
      "Visa business debit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_cr) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_cr) < 0) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_pr) < 0) {
    showToast(
      "error",
      "Visa business credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_credit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_ts) < 0) {
    showToast(
      "error",
      "Visa business credit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_cr) {
    showToast("error", "Card acceptence fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_cr) < 0) {
    showToast(
      "error",
      "Card acceptence fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_pr) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_pr) < 0) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_acceptance_fee_ts === "") {
    showToast("error", "Card acceptence total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_ts) < 0) {
    showToast(
      "error",
      "Card acceptence fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_cr) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_cr) < 0) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_pr) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_pr) < 0) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_transaction_fee_ts === "") {
    showToast("error", "Card transection total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_ts) < 0) {
    showToast(
      "error",
      "Card transection fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_cr) {
    showToast("error", "Authorization fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.authorization_fee_cr) < 0) {
    showToast(
      "error",
      "Authorization fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_pr) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_pr) < 0) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.authorization_fee_tr_no === "") {
    showToast(
      "error",
      "Authorization fee number of transection  shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_tr_no) < 0) {
    showToast(
      "error",
      "Authorization fee  number of transection  shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_cr) {
    showToast("error", "Mastercard credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_cr) < 0) {
    showToast(
      "error",
      "Mastercard credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_pr) < 0) {
    showToast(
      "error",
      "Mastercard credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_credit_ts === "") {
    showToast("error", "Mastercard credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_ts) < 0) {
    showToast("error", "Mastercard credit total sale shouldn't be negative !");
    validation = true;
  }

  if (validation) {
    return 0;
  }

  const url = `${BASE_URL}api/v1/leads/lead/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          // showToast("success", "Leads Qualify successfully");

          dispatch({ type: Types.AFTER_SUCCESS_QUALIFY, payload: true });
          localStorage.setItem("leadId", res.data.data.id);
          dispatch({
            type: Types.GET_PRICE_QUOTE_INPUT,
            payload: { name: "qualify_id", value: res.data.data.id },
          });

          // dispatch(GetLeadtList());
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};
export const QualifyLeadList = (id) => (dispatch) => {
  let leadId = localStorage.getItem("leadId");
  const url = `${BASE_URL}api/v1/leads/lead/${leadId}/qualify/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_PRICE_QUOTE_INPUT,
            payload: { name: "lead_qualify_id", value: res.data.data.id },
          });
          localStorage.setItem("quote_id", res.data.data.id); 
          showToast("success", "Leads Qualify successfully");

          dispatch({ type: Types.QUALIFY_STATUS, payload: true });
          dispatch({
            type: Types.GET_QUALIFY_LEAD,
            payload: res.data.data,
          });

       
          // payload: { name: "qualify_id", value: res.data.data.id },

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
      
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// --------------------Pagination Start-----------------------
export const paginationLimit = (limit) => {
  return {
    type: Types.PAGINATION_lIMIT,
    payload: limit,
  };
};
export const setpaginationName = (name) => {
  return {
    type: Types.PAGINATION_NAME,
    payload: name,
  };
};
export const setLoadLeads = (isTrue) => {
  return {
    type: Types.IS_LOAD_LEADS,
    payload: isTrue,
  };
};

export const paginationPrevious = () => {
  return {
    type: Types.PAGINATION_PREVIOUS,
  };
};

export const paginationNext = () => {
  return {
    type: Types.PAGINATION_NEXT,
  };
};

export const paginationChangeCurrent = (current) => {
  return {
    type: Types.PAGINATION_CHANGE_CURRENT,
    payload: current,
  };
};

export const PaginationLoading = (is_ON) => {
  return {
    type: Types.PAGINATION_LOADING,
    payload: is_ON,
  };
};

export const PaginationSuccess = (data) => {
  return {
    type: Types.PAGINATION_SUCCESS,
    payload: data,
  };
};
export const PaginationError = (error) => {
  return {
    type: Types.PAGINATION_ERROR,
    payload: error,
  };
};
export const PaginationUrl = (url) => {
  return {
    type: Types.PAGINATION_URL,
    payload: url,
  };
};

export const GetPaginationData = (url) => {

  return (dispatch) => {
    dispatch(PaginationLoading(true));
    url.length > 0 &&
      axios
        .get(url)
        .then((success) => {
          dispatch(
            PaginationSuccess(
              success.data.data === undefined ? [] : success.data.data
            )
          );
          dispatch(PaginationLoading(false));
        })
        .catch((error) => {
          dispatch(PaginationError(error.message));
          dispatch(PaginationLoading(false));
        });
    dispatch(setLoadLeads(true));
  };
};

// --------------------Pagination End-----------------------

export const SetLeadUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/leads/lead/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.SET_LEADS_UPDATED,
          payload: res.data.data,
        });
        dispatch(
          GetLeadsnput(
            "terminal_products",
            res.data.data?.lead_products?.filter(
              (item) => item?.product_type === "card_terminal"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "ecommerce_products",
            res.data.data?.lead_products?.filter(
              (item) =>
                item?.product_type === "ecom" ||
                item?.product_type === "VT" ||
                item?.product_type === "ecom_VT"
            )
          )
        );
        dispatch(
          GetLeadsnput(
            "epos_products",
            res.data.data?.lead_products?.filter(
              (item) => item?.product_type === "epos"
            )
          )
        );
      }
    });
  } catch (error) {}
};

export const SetLeadsStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE, payload: false });
};

export const SetQualifyStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE_QUALIFY, payload: false });
};
export const SetLeadqualifyStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE_LEAD_QUALIFY, payload: false });
};
export const SetQuoteStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_PRICE_QUOTE_FALSE, payload: false });
};

export const SetQuoteQualifyStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE_QUOTE_QUALIFY, payload: false });
};
export const setAfterquotequalifyFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE_QUOTE_AFTER_QUALIFY, payload: false });
};

export const SetAddresstrue = (data) => (dispatch) => {
  dispatch({ type: Types.IS_ADDRESS_CHECKED, payload: data });
};

export const SetLeadsStatusUpdateFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_FALSE_UPDATE_LEADS, payload: false });
};

export const UpdateLeads = (data) => (dispatch) => {
  // delete data.ecommerce_products;
  // delete data.terminal_products;
  // delete data.epos_products;
 
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var validation = false;
  for (const product of data.lead_products) {
    if (!product.lead_id) delete product.lead_id;
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_provider === null || product?.terminal_provider === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.terminal_option === null || product?.terminal_option === "" )) {
      showToast("error", "Terminal option shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.integration_availability === null || product?.integration_availability === "" )) {
      showToast("error", "Integration availablity  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && product?.integration_availability === "INTEGRATED" && ( product?.epos_name === "" || product?.epos_name === null)) {
      showToast("error", "Epos name  shouldn't be empty");
      validation = true;
    }
    if (data.card_machine_service === true && product.product_type ==="card_terminal" && (product?.product === "" ||  product?.product === null) ) {
      showToast("error", "Product  shouldn't be empty");
      validation = true;
    }
    // ====ecom product===========
    if (data.ecom_service === true &&  (product?.product_type === null || product?.product_type === "" )) {
      showToast("error", "Terminal provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true && (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && ( product?.getway_provider === "" || product?.getway_provider === null)) {
      showToast("error", "Getway provider shouldn't be empty");
      validation = true;
    }
    if (data.ecom_service === true &&  (product.product_type ==="ecom" || product?.product_type === "VT" || product?.product_type === "ecom_VT") && (product?.website_url === "" ||  product?.website_url === null) ) {
      showToast("error", "Website url  shouldn't be empty");
      validation = true;
    }

    // =======epos=============
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_option === null || product?.epos_option === "" )) {
      showToast("error", "Epos option shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.epos_provider === null || product?.epos_provider === "" )) {
      showToast("error", "Epos provider shouldn't be empty");
      validation = true;
    }
    // if (data.epos_service === true && product.product_type ==="epos" && (product?.one_of_cost === null || product?.one_of_cost === "" )) {
    //   showToast("error", "One of cost shouldn't be empty");
    //   validation = true;
    // }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.price === null || product?.price === "" )) {
      showToast("error", "Monthly shouldn't be empty");
      validation = true;
    }
    if (data.epos_service === true && product.product_type ==="epos" && (product?.product_term === null || product?.product_term === "" )) {
      showToast("error", "Contact length be empty");
      validation = true;
    }
  }


  if (data?.lead_source === "" || data?.lead_source === null ) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
    // return 0;
  }
  if (data.legal_type === "" || data?.legal_type === null ) {
    showToast("error", "Legal type shouldn't be empty");
    validation = true;
  }
  if (data.legal_name === "" || data?.legal_name === null) {
    showToast("error", "Legal name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (data.industry_type === "" || data?.industry_type === null) {
    showToast("error", "industry type shouldn't be empty");
    validation = true;
  }
  if (!data.dob) {
    showToast("error", "Date of birth shouldn't be empty");
    validation = true;
  }
  if (!data.incorporated_on) {
    showToast("error", "In Corporated on shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", " Current Ownership Since shouldn't be empty");
    validation = true;
  }
  if (new Date().getFullYear() - new Date(data.dob).getFullYear() < 10) {
    showToast("error", "Invalid DOB");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  if (data.sales_partner === "") {
    showToast("error", "Sales partner shouldn't be empty!");
    validation = true;
  }
  if (data.lead_status === "") {
    showToast("error", "Lead status  shouldn't be empty!");
    validation = true;
  }
  if (data.lead_stage === "") {
    showToast("error", "Lead stage  shouldn't be empty!");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (data.legal_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit legal address post code !"
    );
    validation = true;
  }
  if (!data.legal_address1.length) {
    showToast("error", "Legal address1 shouldn't be empty !");
    validation = true;
  }
  if (!data.legal_city.length) {
    showToast("error", "Legal city shouldn't be empty !");
    validation = true;
  }
  if (!data.trading_address1.length) {
    showToast("error", "Trading address1 shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length === "") {
    showToast("error", "Trading post code shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast(
      "error",
      "Please enter at least 5 digit trading address post code!"
    );
    validation = true;
  }
  if (!data.trading_city.length) {
    showToast("error", "Trading city shouldn't be empty!");
    validation = true;
  }
  //  if (data.website.match(regex) !== true) {
  //  if (data.website.match(regex) !== true) {
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (!data.visa_debit_cr) {
    showToast("error", "Visa debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_cr) < 0) {
    showToast("error", "Visa debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_debit_pr) {
    showToast("error", "Visa debit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_pr) < 0) {
    showToast("error", "Visa debit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_debit_ts === "") {
    showToast("error", "Visa debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_debit_ts) < 0) {
    showToast("error", "Visa debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_cr) {
    showToast("error", "Mastercard debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_cr) < 0) {
    showToast("error", "Mastercard debit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.mastercard_debit_pr) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_pr) < 0) {
    showToast(
      "error",
      "Mastercard debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_debit_ts === "") {
    showToast("error", "Mastercard debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_debit_ts) < 0) {
    showToast("error", "Mastercard debit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_cr) {
    showToast("error", "Visa credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_cr) < 0) {
    showToast("error", "Visa credit current rate shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_pr) < 0) {
    showToast("error", "Visa credit paymentsave rate shouldn't be negative !");
    validation = true;
  }
  if (data.visa_credit_ts === "") {
    showToast("error", "Visa credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_credit_ts) < 0) {
    showToast("error", "Visa credit total sale shouldn't be negative !");
    validation = true;
  }
  if (!data.visa_business_debit_cr) {
    showToast("error", "Visa business debit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_cr) < 0) {
    showToast(
      "error",
      "Visa business debit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_debit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_pr) < 0) {
    showToast(
      "error",
      "Visa business debit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_debit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_debit_ts) < 0) {
    showToast(
      "error",
      "Visa business debit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_cr) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_cr) < 0) {
    showToast(
      "error",
      "Visa business credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.visa_business_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_pr) < 0) {
    showToast(
      "error",
      "Visa business credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.visa_business_credit_ts === "") {
    showToast("error", "Visa business debit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.visa_business_credit_ts) < 0) {
    showToast(
      "error",
      "Visa business credit total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_cr) {
    showToast("error", "Card acceptence fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_cr) < 0) {
    showToast(
      "error",
      "Card acceptence fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_acceptance_fee_pr) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_pr) < 0) {
    showToast(
      "error",
      "Card acceptence fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_acceptance_fee_ts === "") {
    showToast("error", "Card acceptence total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_acceptance_fee_ts) < 0) {
    showToast(
      "error",
      "Card acceptence fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_cr) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_cr) < 0) {
    showToast(
      "error",
      "Card transection fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.card_transaction_fee_pr) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_pr) < 0) {
    showToast(
      "error",
      "Card transection fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.card_transaction_fee_ts === "") {
    showToast("error", "Card transection total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.card_transaction_fee_ts) < 0) {
    showToast(
      "error",
      "Card transection fee total sale shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_cr) {
    showToast("error", "Authorization fee current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.authorization_fee_cr) < 0) {
    showToast(
      "error",
      "Authorization fee current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.authorization_fee_pr) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_pr) < 0) {
    showToast(
      "error",
      "Authorization fee paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.authorization_fee_tr_no === "") {
    showToast(
      "error",
      "Authorization fee number of transection  shouldn't be empty !"
    );
    validation = true;
  }
  if (parseFloat(data.authorization_fee_tr_no) < 0) {
    showToast(
      "error",
      "Authorization fee  number of transection  shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_cr) {
    showToast("error", "Mastercard credit current rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_cr) < 0) {
    showToast(
      "error",
      "Mastercard credit current rate shouldn't be negative !"
    );
    validation = true;
  }
  if (!data.mastercard_credit_pr) {
    showToast("error", "Visa credit paymentsave rate shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_pr) < 0) {
    showToast(
      "error",
      "Mastercard credit paymentsave rate shouldn't be negative !"
    );
    validation = true;
  }
  if (data.mastercard_credit_ts === "") {
    showToast("error", "Mastercard credit total sale shouldn't be empty !");
    validation = true;
  }
  if (parseFloat(data.mastercard_credit_ts) < 0) {
    showToast("error", "Mastercard credit total sale shouldn't be negative !");
    validation = true;
  }

  if (validation) {
    return 0;
  }

  const formData = new FormData();
  const url = `${BASE_URL}api/v1/leads/lead/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.AFTER_SUCCESS_LEADS, payload: true });

          showToast("success", "Leads Update successfully");

          // dispatch(GetLeadsnput("id", undefined));
          // dispatch(GetLeadtList());
        } else {
          showToast("error", res.data.message);
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// ----------------------price quote--------------

export const GetPriceQuoteList = (api) => (dispatch) => {
  const url = api;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_PRICE_QUOTE_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const GetPriceQuoteDetails = (id) => (dispatch) => {
  // let leadId = localStorage.getItem("leadId");
  const url = `${BASE_URL}api/v1/quotes/quote/${id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          // type: Types.GET_PRICE_QUOTE_DETAILS,
          type: Types.SET_PRICEQUOTE_UPDATED,
          payload: res.data.data,
        });
        localStorage.setItem("quote_id", res.data.data.id); 
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const SetPricequoteUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/quotes/quote/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.SET_PRICEQUOTE_UPDATED,
          payload: res.data.data,
        });
        localStorage.setItem("quote_id", res.data.data.id); 
      }
    });
  } catch (error) {}
};

export const GetPricequoteInput =
  (name, value, index, quote_owners = undefined, contact = undefined) =>
  (dispatch) => {
    if (quote_owners !== undefined) {
      dispatch({
        type: Types.GET_PRICE_QUOTE_OWNER,
        payload: { name, value, index, contact },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.GET_PRICE_QUOTE_INPUT, payload: formData });

    // if (name === "doc_type") {
    //   let reader = new FileReader();
    //   const file = e.target.files[0];
    //   reader.onloadend = () => {
    //     formData.name = "image_url";
    //     formData.value = reader.result;
    //     dispatch({ type: Types.GET_PRICE_QUOTE_INPUT, payload: formData });
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

export const SubmitPriceQuoteInput = (data) => (dispatch) => {
 
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var validation = false;
  var Sum = 0;
  for (let index = 0; index < data.quote_owners.length; index++) {
    Sum += parseInt(data.quote_owners[index].ownership_perc);
  }
  if (Sum !== 100) {
    showToast("error", "Total Ownership Percentage must be  100 ");
    validation = true;
  }
  for (const document of data.quote_docs) {
    if (!document?.doc) {
      showToast("error", "Image/PDF shouldn't be empty");
      validation = true;
    }
    if (!document.category) {
      showToast("error", "File name shouldn't be empty");
      validation = true;
    }
    if (!document.doc_type) {
      showToast("error", "Document Type shouldn't be empty");
      validation = true;
    }
    if (document.category === "PROOF_OF_ID" && document?.first_name === "") {
      showToast("error", "Document owner first name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.sur_name) {
      showToast("error", "Document owner  Sur name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.document_id) {
      showToast("error", "Document owner document Id shouldn't be empty");
      validation = true;
    } else if (data.category === "PROOF_OF_ID" && !data?.issuer_id) {
      showToast("error", "Document owner issuer Id shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.expiry_date) {
      showToast("error", "Document owner expiry date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.issue_date) {
      showToast("error", "Document owner Issue date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.country) {
      showToast("error", "Issuing country shouldn't be empty");
      validation = true;
    }
  }
  if (data.legal_type !=="PART" && !data.quote_owners[0].owner_email) {
    showToast("error", "1st owner email shouldn't be empty");
    validation = true;
  }
  for (const business_owner of data.quote_owners) {
    business_owner.owner_second_name = business_owner.owner_surname;
    if (!business_owner?.owner_first_name) {
      showToast("error", "Frist name shouldn't be empty");
      validation = true;
    }
    if (data.legal_type ==="PART" && !business_owner?.owner_email) {
      showToast("error", "Owner Email shouldn't be empty");
      validation = true;
    }
    if (!business_owner.owner_surname) {
      showToast("error", "Last name shouldn't be empty");
      validation = true;
    }
    if (!business_owner.contact_dob) {
      showToast("error", "Owner Date of birth shouldn't be empty");
      validation = true;
    }
    if (
      new Date().getFullYear() -
        new Date(business_owner.contact_dob).getFullYear() <
      10
    ) {
      showToast("error", "Invalid owner DOB");
      validation = true;
    }
    if (!business_owner.ownership_perc) {
      showToast("error", "Owner Percentage shouldn't be empty");
      validation = true;
    }
    if (parseInt(business_owner.ownership_perc) > 100) {
      showToast("error", "Owner Percentage must less than or equal 100");
      validation = true;
    }
    if (!business_owner.owner_phone_no) {
      showToast("error", "Owner Phone number shouldn't be empty");
      validation = true;
    }
    if (business_owner.owner_phone_no.length !== 10) {
      showToast("error", "Invalid owner phone number");
      validation = true;
    }
    if( (new Date(business_owner.owner_expiry_date).getFullYear() -
    new Date(business_owner.owner_issue_date).getFullYear()) *
    12 +
    (new Date(business_owner.owner_expiry_date).getMonth() -
      new Date(business_owner.owner_issue_date).getMonth()) <
    3){
      showToast("error", "The minimum gap between issue date and expiry date should be 3 months.");
      validation = true;
    }
    if (!business_owner.quote_owner_contacts[0].city) {
      showToast("error", "Owner City should not be empty");
      validation = true;
    }
    //  if (!business_owner.business_owner_contacts[0].country_code) {
    //   showToast("error", "Owner Country  shouldn't be empty");
    //   validation = true
    // }
    if (!business_owner.quote_owner_contacts[0].zip_code) {
      showToast("error", "Owner Zip code should not be empty");
      validation = true;
    }
    if (!business_owner.quote_owner_contacts[0].street_line_1) {
      showToast("error", "Owner address1 should not be empty");
      validation = true;
    }
    if (business_owner.quote_owner_contacts[0].zip_code.length < 5) {
      showToast(
        "error",
        "Please enter at least 5 digit  zip code in owner contact"
      );
      validation = true;
    }
  }
  if (!data.legal_name) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", "Date of ownership shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (!data.mobile) {
    showToast("error", "Mobile number shouldn't be empty !");
    validation = true;
  }
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (!data.annual_card_turnover) {
    showToast("error", "Annual card turnover shouldn't be empty !");
    validation = true;
  }
  if (data.annual_card_turnover.length > 14) {
    showToast(
      "error",
      "Ensure that there are no more than 14 digits before the decimal point in annual card turnover !"
    );
    validation = true;
  }
  if (
    parseFloat(data.annual_turnover) < parseFloat(data.annual_card_turnover)
  ) {
    showToast(
      "error",
      "Annual turnover must be greater  than annual card turnover !"
    );
    validation = true;
  }
  if (!data.bank_account_name) {
    showToast("error", "Bank account name shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_sort_code) {
    showToast("error", "Sort code shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_name) {
    showToast("error", "Bank account name shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_account_no) {
    showToast("error", "Bank account number shouldn't be empty !");
    validation = true;
  }
  if (data.bank_account_no.length !== 8) {
    showToast("error", "Please enter 8 digit account number !");
    validation = true;
  }
  if (!data.legal_postcode) {
    showToast("error", "post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit legal post code !");
    validation = true;
  }
  if (!data.trading_postcode) {
    showToast("error", "Trading postcode shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit trading post code!");
    validation = true;
  }
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (
    data.visa_credit_sr.includes(".") &&
    data.visa_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_credit_non_sr.includes(".") &&
    data.visa_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa Credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_sr.includes(".") &&
    data.master_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_non_sr.includes(".") &&
    data.master_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_sr.includes(".") &&
    data.visa_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_non_sr.includes(".") &&
    data.visa_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_sr.includes(".") &&
    data.master_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_non_sr.includes(".") &&
    data.master_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_sr.includes(".") &&
    data.international_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_non_sr.includes(".") &&
    data.international_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_sr.includes(".") &&
    data.visa_v_pay_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_non_sr.includes(".") &&
    data.visa_v_pay_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_sr.includes(".") &&
    data.uk_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_non_sr.includes(".") &&
    data.uk_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_sr.includes(".") &&
    data.visa_business_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_non_sr.includes(".") &&
    data.visa_business_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_sr.includes(".") &&
    data.visa_business_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_non_sr.includes(".") &&
    data.visa_business_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_sr.includes(".") &&
    data.visa_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_non_sr.includes(".") &&
    data.visa_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_sr.includes(".") &&
    data.visa_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_non_sr.includes(".") &&
    data.visa_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_sr.includes(".") &&
    data.master_business_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_non_sr.includes(".") &&
    data.master_business_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_sr.includes(".") &&
    data.master_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_non_sr.includes(".") &&
    data.master_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_sr.includes(".") &&
    data.master_fleet_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_non_sr.includes(".") &&
    data.master_fleet_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_sr.includes(".") &&
    data.master_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_non_sr.includes(".") &&
    data.master_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_sr.includes(".") &&
    data.master_pre_commercial_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_non_sr.includes(".") &&
    data.master_pre_commercial_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_sr.includes(".") &&
    data.non_eea_visa_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_non_sr.includes(".") &&
    data.non_eea_visa_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_sr.includes(".") &&
    data.non_eea_master_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_non_sr.includes(".") &&
    data.non_eea_master_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }

  if (validation) {
    return 0;
  }

  const url = `${BASE_URL}api/v1/quotes/quote/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.AFTER_SUCCESS_PRICE_QUOTE, payload: true });
          // dispatch({ type: Types.SET_PRICEQUOTE_UPDATED, payload: false });
          showToast("success", "Price quote update  successfully");

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });

          // localStorage.setItem("quoteId", res.data.data.id);
          // dispatch(GetLeadtList());
          //
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors.date);
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        //   dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const QualifyApplicationList = () => (dispatch) => {
  let priceQId = localStorage.getItem("priceQId");
  const url = `${BASE_URL}api/v1/quotes/quote/${priceQId}/convert/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.get(url)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: App.GET_APPLICATION_INPUT,
            payload: { name: "quote_qualify_id", value: res.data.data.id },
          });
          localStorage.setItem("application_id", res.data.data.id); 
          dispatch({
            type: Types.GET_QUALIFY_APPLICATION,
            payload: res.data.data.results,
          });
          dispatch({ type: Types.QUOTE_QUALIFY_CHECK, payload: true });
          showToast("success", "Price Quote qualify successfully");

          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    const erroeMsg = JSON.parse(error.request.response).errors;
    for (let value of Object.values(erroeMsg)) {
      showToast("error", value[0]);
    }
    dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

export const SubmitConvertApplication = (data) => (dispatch) => {
 
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  var validation = false;
  var Sum = 0;
  for (let index = 0; index < data.quote_owners.length; index++) {
    Sum += parseInt(data.quote_owners[index].ownership_perc);
  }
  if (Sum !== 100) {
    showToast("error", "Total Ownership Percentage must be  100 ");
    validation = true;
  }
  for (const document of data.quote_docs) {
    if (!document?.doc) {
      showToast("error", "Image/PDF shouldn't be empty");
      validation = true;
    }
    if (!document.category) {
      showToast("error", "File name shouldn't be empty");
      validation = true;
    }
    if (!document.doc_type) {
      showToast("error", "Document Type shouldn't be empty");
      validation = true;
    }
    if (document.category === "PROOF_OF_ID" && document?.first_name === "") {
      showToast("error", "Document owner first name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.sur_name) {
      showToast("error", "Document owner  Sur name shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.document_id) {
      showToast("error", "Document owner document Id shouldn't be empty");
      validation = true;
    } else if (data.category === "PROOF_OF_ID" && !data?.issuer_id) {
      showToast("error", "Document owner issuer Id shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.expiry_date) {
      showToast("error", "Document owner expiry date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.issue_date) {
      showToast("error", "Document owner Issue date shouldn't be empty");
      validation = true;
    } else if (document.category === "PROOF_OF_ID" && !document?.country) {
      showToast("error", "Issuing country shouldn't be empty");
      validation = true;
    }
  }
  if (data.legal_type !=="PART" && !data.quote_owners[0].owner_email) {
    showToast("error", "1st owner email shouldn't be empty");
    validation = true;
  }
  for (const business_owner of data.quote_owners) {
    business_owner.owner_second_name = business_owner.owner_surname;
    if (!business_owner?.owner_first_name) {
      showToast("error", "Frist name shouldn't be empty");
      validation = true;
    }
    if (data.legal_type ==="PART" && !business_owner?.owner_email) {
      showToast("error", "Owner Email shouldn't be empty");
      validation = true;
    }
    if (!business_owner.owner_surname) {
      showToast("error", "Last name shouldn't be empty");
      validation = true;
    }
    if (!business_owner.contact_dob) {
      showToast("error", "Owner Date of birth shouldn't be empty");
      validation = true;
    }
    if (
      new Date().getFullYear() -
        new Date(business_owner.contact_dob).getFullYear() <
      10
    ) {
      showToast("error", "Invalid owner DOB");
      validation = true;
    }
    if( (new Date(business_owner.owner_expiry_date).getFullYear() -
    new Date(business_owner.owner_issue_date).getFullYear()) *
    12 +
    (new Date(business_owner.owner_expiry_date).getMonth() -
      new Date(business_owner.owner_issue_date).getMonth()) <
    3){
      showToast("error", "The minimum gap between issue date and expiry date should be 3 months.");
      validation = true;
    }
    if (!business_owner.ownership_perc) {
      showToast("error", "Owner Percentage shouldn't be empty");
      validation = true;
    }
    if (parseInt(business_owner.ownership_perc) > 100) {
      showToast("error", "Owner Percentage must less than or equal 100");
      validation = true;
    }
    if (!business_owner.owner_phone_no) {
      showToast("error", "Owner Phone number shouldn't be empty");
      validation = true;
    }
    if (business_owner.owner_phone_no.length !== 10) {
      showToast("error", "Invalid owner phone number");
      validation = true;
    }
    if (!business_owner.quote_owner_contacts[0].city) {
      showToast("error", "Owner City should not be empty");
      validation = true;
    }
    //  if (!business_owner.business_owner_contacts[0].country_code) {
    //   showToast("error", "Owner Country  shouldn't be empty");
    //   validation = true
    // }
    if (!business_owner.quote_owner_contacts[0].zip_code) {
      showToast("error", "Owner Zip code should not be empty");
      validation = true;
    }
    if (!business_owner.quote_owner_contacts[0].street_line_1) {
      showToast("error", "Owner address1 should not be empty");
      validation = true;
    }
    if (business_owner.quote_owner_contacts[0].zip_code.length < 5) {
      showToast(
        "error",
        "Please enter at least 5 digit  zip code in owner contact"
      );
      validation = true;
    }
  }
  if (!data.legal_name) {
    showToast("error", "Lead source shouldn't be empty");
    validation = true;
  }
  if (!data.trading_name) {
    showToast("error", "Trading name shouldn't be empty");
    validation = true;
  }
  if (data?.legal_name?.includes("'") ) {
    showToast("error", "Legal name including ' is not allowed");
    validation = true;
  }
  if (data?.trading_name?.includes("'") ) {
    showToast("error", "Trading name including ' is not allowed");
    validation = true;
  }
  if (!data.first_name) {
    showToast("error", "Frist name shouldn't be empty");
    validation = true;
  }
  if (!data.last_name) {
    showToast("error", "Last name shouldn't be empty");
    validation = true;
  }
  if (!data.current_ownership_since) {
    showToast("error", "Date of ownership shouldn't be empty");
    validation = true;
  }
  if (!data.email) {
    showToast("error", "Primary email  shouldn't be empty");
    validation = true;
  }
  if (!regEmail.test(data.email)) {
    showToast("error", "Enter a vaild email ");
    validation = true;
  }
  if (
    !regEmail.test(data.secondary_email) &&
    data.secondary_email !== "" &&
    data.secondary_email !== null
  ) {
    showToast("error", "Enter a vaild secondery email ");
    validation = true;
  }
  //  if (data.mobile.substring(0, 1) === "0" || data.mobile.length !== 10) {
  if (!data.mobile) {
    showToast("error", "Mobile number shouldn't be empty !");
    validation = true;
  }
  if (data.mobile.length !== 10) {
    showToast("error", "Mobile number is invalid !");
    validation = true;
  }
  if (data.telephone.length !== 10 && data.telephone !== "") {
    showToast("error", "Telephone number is invalid !");
    validation = true;
  }
  if (!data.annual_card_turnover) {
    showToast("error", "Annual card turnover shouldn't be empty !");
    validation = true;
  }
  if (data.annual_card_turnover.length > 14) {
    showToast(
      "error",
      "Ensure that there are no more than 14 digits before the decimal point in annual card turnover !"
    );
    validation = true;
  }
  if (
    parseFloat(data.annual_turnover) < parseFloat(data.annual_card_turnover)
  ) {
    showToast(
      "error",
      "Annual turnover must be greater  than annual card turnover !"
    );
    validation = true;
  }
  if (!data.bank_account_name) {
    showToast("error", "Bank account name shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_sort_code) {
    showToast("error", "Sort code shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_name) {
    showToast("error", "Bank account name shouldn't be empty !");
    validation = true;
  }
  if (!data.bank_account_no) {
    showToast("error", "Bank account number shouldn't be empty !");
    validation = true;
  }
  if (data.bank_account_no.length !== 8) {
    showToast("error", "Please enter 8 digit account number !");
    validation = true;
  }
  if (!data.legal_postcode) {
    showToast("error", "post code shouldn't be empty !");
    validation = true;
  }
  if (data.legal_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit legal post code !");
    validation = true;
  }
  if (!data.trading_postcode) {
    showToast("error", "Trading postcode shouldn't be empty !");
    validation = true;
  }
  if (data.trading_postcode.length < 5) {
    showToast("error", "Please enter at least 5 digit trading post code!");
    validation = true;
  }
  if (
    !regex.test(data.website) &&
    data.website !== "" &&
    data.website !== null
  ) {
    showToast("error", "Please enter a valid website!");
    validation = true;
  }
  if (
    data.visa_credit_sr.includes(".") &&
    data.visa_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_credit_non_sr.includes(".") &&
    data.visa_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa Credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_sr.includes(".") &&
    data.master_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_credit_non_sr.includes(".") &&
    data.master_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_sr.includes(".") &&
    data.visa_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_debit_non_sr.includes(".") &&
    data.visa_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_sr.includes(".") &&
    data.master_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_debit_non_sr.includes(".") &&
    data.master_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_sr.includes(".") &&
    data.international_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.international_maestro_non_sr.includes(".") &&
    data.international_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "International mestro non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_sr.includes(".") &&
    data.visa_v_pay_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_v_pay_non_sr.includes(".") &&
    data.visa_v_pay_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa v pay non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_sr.includes(".") &&
    data.uk_maestro_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.uk_maestro_non_sr.includes(".") &&
    data.uk_maestro_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "UK mestro non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_sr.includes(".") &&
    data.visa_business_credit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_credit_non_sr.includes(".") &&
    data.visa_business_credit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_sr.includes(".") &&
    data.visa_business_debit_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_business_debit_non_sr.includes(".") &&
    data.visa_business_debit_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa business debit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_sr.includes(".") &&
    data.visa_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_purchasing_non_sr.includes(".") &&
    data.visa_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa purching non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_sr.includes(".") &&
    data.visa_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.visa_corporate_non_sr.includes(".") &&
    data.visa_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Visa corporate credit non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_sr.includes(".") &&
    data.master_business_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_business_non_sr.includes(".") &&
    data.master_business_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master business  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_sr.includes(".") &&
    data.master_purchasing_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_purchasing_non_sr.includes(".") &&
    data.master_purchasing_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master purching non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_sr.includes(".") &&
    data.master_fleet_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_fleet_non_sr.includes(".") &&
    data.master_fleet_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master fleet non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_sr.includes(".") &&
    data.master_corporate_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_corporate_non_sr.includes(".") &&
    data.master_corporate_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master Corporate  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_sr.includes(".") &&
    data.master_pre_commercial_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.master_pre_commercial_non_sr.includes(".") &&
    data.master_pre_commercial_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Master pre commercial non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_sr.includes(".") &&
    data.non_eea_visa_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_visa_non_sr.includes(".") &&
    data.non_eea_visa_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE visa non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_sr.includes(".") &&
    data.non_eea_master_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }
  if (
    data.non_eea_master_non_sr.includes(".") &&
    data.non_eea_master_non_sr.split(".")[1].length > 3
  ) {
    showToast(
      "error",
      "Non EEE master  non secure rate cann't exceed 2digit after decimal"
    );
    validation = true;
  }

  if (validation) {
    return 0;
  }
  const url = `${BASE_URL}api/v1/quotes/quote/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
  try {
    Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          // showToast("success", "Price Quote qualify successfully");
          dispatch({ type: Types.AFTER_SUCCESS_QUOTE_QUALIFY, payload: true });
          localStorage.setItem("priceQId", res.data.data.id);
          dispatch({
            type: Types.GET_PRICE_QUOTE_INPUT,
            payload: { name: "app_qualify_id", value: res.data.data.id },
          });
        } else {
          dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
          showToast("error", res.data.errors);
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LEADS, payload: false });
        // const erroeMsg = JSON.parse(err.request.response).errors;
        // for (let value of Object.values(erroeMsg)) {
        //   showToast("error", value[0]);
        // }
        if (err.response === undefined) {
          showToast("error", "Internal Server Error");
        } else {
          const erroeMsg = JSON.parse(err.request.response).errors;
          const Msg = JSON.parse(err.request.response).message;

          showToast("error", Msg);
          const dataArray = Object.entries(erroeMsg).map(
            ([key, value]) => `${key}: ${JSON.stringify(value)}`
          );
          dataArray.map((item, index) => showToast("error", item));
        }
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_LEADS, payload: true });
};

// ===========================product doc-
export const GetDocInput =
  (name, value, index, e, quote_docs = undefined) =>
  (dispatch) => {
    // dispatch({ type: Types.GET_DOC_INPUT, payload: formData });
    if (quote_docs !== undefined) {
      const formData = {
        name: name,
        value: value,
        index,
      };
      dispatch({
        type: Types.ADD_NEW_QUOTE_DOC,
        payload: { name, value, index },
      });

      if (name === "document") {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          formData.name = "document";
          formData.value = reader.result;
          dispatch({ type: Types.ADD_NEW_QUOTE_DOC, payload: formData });
          // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
        };
        reader.readAsDataURL(file);
      }
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };

    dispatch({ type: Types.ADD_NEW_QUOTE_DOC, payload: formData });
  };

export const GetQuoteProductInput =
  (name, value, index, quote_products = undefined) =>
  (dispatch) => {
    if (quote_products !== undefined) {
      dispatch({
        type: Types.GET_QUOTE_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_QUOTE_PRODUCT, payload: formData });
  };
export const GetTerminalProductInput =
  (name, value, index, terminal_products = undefined) =>
  (dispatch) => {
    if (terminal_products !== undefined) {
      dispatch({
        type: Types.GET_CARD_TERMINAL_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_CARD_TERMINAL_PRODUCT, payload: formData });
  };
export const GetEcommerceProductInput =
  (name, value, index, ecommerce_products = undefined) =>
  (dispatch) => {
    if (ecommerce_products !== undefined) {
      dispatch({
        type: Types.GET_ECOMMERCE_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_ECOMMERCE_PRODUCT, payload: formData });
  };
export const GetEposProductInput =
  (name, value, index, epos_products = undefined) =>
  (dispatch) => {
    if (epos_products !== undefined) {
      dispatch({
        type: Types.GET_EPOS_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_EPOS_PRODUCT, payload: formData });
  };
export const GetLeadProductInput =
  (name, value, index, lead_products = undefined) =>
  (dispatch) => {
    if (lead_products !== undefined) {
      dispatch({
        type: Types.GET_LEAD_PRODUCT,
        payload: { name, value, index },
      });
      return;
    }
    const formData = {
      name: name,
      value: value,
      index,
    };
    dispatch({ type: Types.GET_LEAD_PRODUCT, payload: formData });
  };
