import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import { GetPricequoteInput } from "../../_redux/action/LeadsAction";
import { opportunityStage, Opportunity_Status } from "../commons/Dropdown";

const SalesDetails = () => {
  const dispatch = useDispatch();
  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  const OpportunityStatus = (
    <p>
      Opportunity Status <span className="required">*</span>
    </p>
  );
  const OpportunityStage = (
    <p>
      Opportunity Stage <span className="required">*</span>
    </p>
  );

 

  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
  };

  return (
    <div className="info-box text-capitalize">
      <div className="mt-4">
        <div className="row">
        <div className="d-flex heading mb-form">
              <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                  fill="#0D0D0D"
                />
              </svg>

              <p>Sales Details</p>
            </div>
          <div className="col-md-6">
           
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={OpportunityStage}
                name="opportunity_stage"
                value={priceQuoteInput.opportunity_stage}
                onChange={(e) =>
                  handleChangeInput("opportunity_stage", e.target.value)
                }
              >
                {opportunityStage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={OpportunityStatus}
                name="opportunity_status"
                value={priceQuoteInput.opportunity_status}
                onChange={(e) =>
                  handleChangeInput("opportunity_status", e.target.value)
                }
              >
                {Opportunity_Status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Opportunity Notes"
                variant="outlined"
                size="small"
                name="note"
                value={priceQuoteInput.note}
                onChange={(e) => handleChangeInput("note", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
          <div className="mb-form">
              <TextField
                type="date"
                id="outlined-basic"
                label="Call Back Date"
                variant="outlined"
                size="small"
                name="callback_date"
                InputLabelProps={{ shrink: true, }}
                value={priceQuoteInput?.callback_date}
                // value={(new Date(priceQuoteInput?.callback_date).toISOString().slice(0, 10))}
                onChange={(e) =>
                  handleChangeInput("callback_date", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                type="date"
                id="outlined-basic"
                label="Price Quote Date"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true, }}
                name="price_quote_date"
                value={priceQuoteInput?.price_quote_date}
                // value={(new Date(priceQuoteInput?.price_quote_date).toISOString().slice(0, 10))}
                onChange={(e) =>
                  handleChangeInput("price_quote_date", e.target.value)
                }
              />
            </div>

            <div className="mb-form">
              <TextField
                type="date"
                id="outlined-basic"
                label="Est. Close Date"
                variant="outlined"
                size="small"
                name="est_close_date"
                InputLabelProps={{ shrink: true, }}
                value={priceQuoteInput?.est_close_date}
                // value={(new Date(priceQuoteInput?.est_close_date).toISOString().slice(0, 10))}
                onChange={(e) =>
                  handleChangeInput("est_close_date", e.target.value)
                }
              />
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default SalesDetails;
