import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";

import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";



import { useParams } from "react-router-dom";
import {

  applicationStage,
  ApplicationStatus,
  Applications_type,
  LegalType,
} from "../../commons/Dropdown";

const ApplicationDetails = ({ applicationDetailEdit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  // ---api call-----------------
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const applicationDetails = useSelector(
    (state) => state.applicationInfo.applicationDetails
  );
  // useEffect(() => {
  //   dispatch(SetApplicationDetails(id));
  // }, []);
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Application Details</h4>
        </div>
        <div className="button-box">
          <button
            className="   custom-btn  flex "
            onClick={applicationDetailEdit}
          >
            <img src={pen} style={{ marginRight: "7px" }} alt="" />
            Edit
          </button>
        </div>
      </div>

      <div className="detail-content">
        <div>
          <p>Acquiring Bank<span className="required">*</span></p>
          {applicationInput?.acquiring_bank === 0 && <span>Elavon</span>}
        </div>
        <div>
          <p>Application Type<span className="required">*</span></p>
          {Applications_type.map((option) => {
            return (
              <>
                {option.value === applicationInput?.application_type && (
                  <span key={option.value} value={option.value}>
                    {option?.label}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Application Status<span className="required">*</span></p>
          {ApplicationStatus.map((option) => {
            return (
              <>
                {option.value === applicationInput?.application_status && (
                  <span key={option.value} value={option.value}>
                    {option?.label}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Application Stage<span className="required">*</span></p>
          {applicationStage.map((option) => {
            return (
              <>
                {option.value === applicationInput?.applicaiton_stage && (
                  <span key={option.value} value={option.value}>
                    {option?.label}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Lead Source<span className="required">*</span></p>
          {sourceList?.map((option) => {
            return (
              <>
                {option?.id === applicationInput?.lead_source && (
                  <span key={option.id} id={option.id}>
                    {option?.name}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Legal Type<span className="required">*</span></p>
          {LegalType?.map((option) => {
            return (
              <>
                {option?.value === applicationInput?.legal_type && (
                  <span key={option.value} id={option.value}>
                    {option?.label}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Legal Name<span className="required">*</span></p>

          <span className="extra-color">{applicationInput.legal_name}</span>
        </div>
        {applicationInput.legal_type === "CH" && (
          <div>
            <p>Charity no<span className="required">*</span></p>
            {LegalType?.map((option) => {
              return (
                <>
                  <span className="extra-color">
                    {applicationInput.charity_number}
                  </span>
                </>
              );
            })}
          </div>
        )}
        {(applicationInput.legal_type === "L" ||
          applicationInput.legal_type === "LLP" ||
          applicationInput.legal_type === "PL") && (
          <div>
            <p>Company House no<span className="required">*</span></p>

            <span className="extra-color">
              {applicationInput.company_house_no}
            </span>
          </div>
        )}
        <div>
          <p>In Corporated On</p>

          <span className="extra-color">
            {applicationInput.incorporated_on}
          </span>
        </div>

        <div>
          <p>Ownership science<span className="required">*</span></p>

          <span className="extra-color">
            {applicationInput.current_ownership_since}
          </span>
        </div>
        <div>
          <p>Mobile Number<span className="required">*</span></p>

          <span className="extra-color">{applicationInput.mobile}</span>
        </div>
        <div>
          <p>Telephone Number</p>

          <span className="extra-color">{applicationInput.telephone}</span>
        </div>
        <div>
          <p>Primary Email<span className="required">*</span></p>

          <span className="extra-color">{applicationInput.email}</span>
        </div>
        <div>
          <p>Secondary Email</p>

          <span className="extra-color">
            {applicationInput.secondary_email}
          </span>
        </div>
        <div>
          <p>Faster Payment<span className="required">*</span></p>
          {applicationInput?.bank_faster_payments === "1" ? (
            <span className="extra-color">Yes</span>
          ) : (
            <span className="extra-color">No</span>
          )}
        </div>

        <div>
          <p>Call Back Date</p>
          <span className="extra-color">{applicationInput?.callback_date}</span>
        </div>
        <div>
          <p>Est. Close Date</p>
          <span className="extra-color">
            {applicationInput?.application_close_date}
          </span>
        </div>
        <div>
          <p>Industry Type<span className="required">*</span></p>
          {IndustryList?.map((option) => {
            return (
              <>
                {option.id === applicationInput?.industry_type && (
                  <span key={option.id} value={option.id}>
                    {option?.description}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>MCC Code<span className="required">*</span></p>
          {IndustryList?.map((option) => {
            return (
              <>
                {option.id === applicationInput?.industry_type && (
                  <span key={option.id} value={option.id}>
                    {option?.mcc_iso}
                  </span>
                )}
              </>
            );
          })}
        </div>
        <div>
          <p>Website</p>
          <span className="site">
            <a href="www.devsstream.com" target="_blank">
              {applicationInput?.website}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetails;
