import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../../../Const";
import { GetPostcodeDetails } from "../PriceQuote/_redux/action/PriceQuoteAction";
import { GetApplicationInput } from "./_redux/action/NewapplicationAction";

function ApplicationPostcode({name,value,details}) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
  const token = `Token ${userData.token}`;
 
  const handleInputChange = (event) => {
    const { value } = event.target;
    dispatch(GetApplicationInput(name, value));
    setInputValue(value.trim());

    if (value === "") {
      setSuggestions([]);
      return;
    }

    const url = `${BASE_URL}api/v1/leads/utility/${value}/address-by-postcode/`;
    //   "http://eboard.demo.devsstream.com/api/v1/lead/utility/" +
    //   "rm7" +
    //   "/address-by-postcode/";
    // const token = "Token 26791875807aebdc6ded9c585f18db723dd1c819";

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const Newdata = data.data.data.Items;

        const newSuggestions = Newdata?.map((suggestion) => suggestion);
        setSuggestions(newSuggestions);
      })
      .catch((error) => console.log(error));
  };

  const handleSuggestionClick = (selectedValue) => {
    setInputValue(selectedValue.Text);
    console.log(selectedValue);
    setSuggestions([]);
    const type = "Postcode";
    if (selectedValue.Type === "Postcode") {
      findAddress(selectedValue, selectedValue.Id);
    } else 
    // if (selectedValue.Type === "Address") 
    {
      setSuggestions([]);
      dispatch(
        GetPostcodeDetails(selectedValue.Id,details)
      );
    }
  };
  const handleWindowClick = (e) => {
    if (!e.target.closest("#suggestion-list-container")) {
      setSuggestions([]);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleWindowClick);
  
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);
  const findAddress = (text, container) => {
    const Text = text.Text + " " + text.Description;
    const Container = container;

    const url = `${BASE_URL}api/v1/leads/utility/${Text}/address-by-postcode/?container=${Container}`;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data.data.Items.length === 1 &&
          typeof data.data.data.Items[0].Error !== "undefined"
        ) {
        } else {
          if (data.data.data.Items.length === 0) {
          } else {
            setSuggestions(data.data.data.Items);
          }
        }
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="col-12  d-flex  px-2 main-box-auc"
        style={{
          zIndex: "2",
          borderRadius: "8px",
          position: "relative",
          paddingTop: "7px",
          paddingBottom: "6px",
        }}
      >
        <div
          style={{
            position: "absolute",
            background: "white",
            top: "-24%",
            left: "2%",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              color: "#00000099",
              padding: "0px 4px 0px 2px",
            }}
          >
            Postcode <span className="required">*</span>
          </p>
        </div>
        <input
          style={{
            border: "0",
            outline: "none",
            backgroundColor: "transparent",
            overflow: "hidden",
          }}
          type="text"
          value={value}
          onChange={handleInputChange}
          autoComplete="off"
        />
      </div>
      {suggestions.length > 0 && (
        <div className="suggestion" id="suggestion-list-container">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion_item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.Text}
              {suggestion.Description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ApplicationPostcode;
