import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fastest_payment } from "../../commons/Dropdown";
import Button from "@mui/material/Button";
import {
  GetApplicationInput,
  SubmitApplicationInput,
  SubmitNewApplicationInput,
} from "../_redux/action/NewapplicationAction";
import AppSiteVisite from "../AppSiteVisite";
const PreviewSiteVisitEdit = ({ siteVisiteEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // useEffect(() => {
  //   dispatch(SetApplicationUpdatedData(id));
  // }, []);

  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };
  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
  };
  return (
    <div className="customar-detail">
      {/* -------------deatil head ------------------ */}
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} className="small-img" alt="" />
          <h4 style={{ color: "white" }}>Site visit</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={siteVisiteEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              siteVisiteEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      {/* input section  */}
      <div className="">
        <AppSiteVisite/>
      </div>
     
    </div>
  );
};

export default PreviewSiteVisitEdit;
