import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import ApplicationTop from "./ApplicationTop";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import "./Application.css";
import eye from "../../../asstes/icon/eye.png";
import { FaEye } from "react-icons/fa";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import FilterPagination from "../Shared/FilterPagination";
import { useEffect } from "react";
import {
  GetAplicationDetails,
  // GetApplicationList,
} from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useDispatch } from "react-redux";
import { applicationStage } from "../../allAplication/mainLead/components/commons/Dropdown";
import { BASE_URL } from "../../../Const";
import jsPDF from "jspdf";
import axios from "axios";
import { showToast } from "../../../utils/ToastHelper";
import application from "../../../asstes/icon/appli.png";
import printer from "../../../asstes/icon/printer.png";
import { useReactToPrint } from "react-to-print";
import psImg from "../../../asstes/psImg/Payment-Save.svg";
import PageNumberCount from "./PageNumberCount";
const NewApplicationPage = ({ isLoading, setIsloading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [secondLoader, setSecondLoader] = useState(true);
  const inputRef = useRef(null);
  const cRef = useRef();
  const [pdfData, setPdfData] = useState();
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "New Application List",
    bodyClass: "dis",
  });
  const getPrint = () => {
    HandlePrint();
  };
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#leads-table", theme: "grid" });
    doc.text("New application table", 20, 10);

    doc.save("New Application.pdf");
  };
  localStorage.setItem("appItemPage", 10);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [totalData, SetTotalData] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  useEffect(() => {
    if (inputRef.current && inputRef.current.value.length > 0) {
      inputRef.current.focus();
      console.log("calling");
    }
  }, [search]);

  // -------------arrow--------------
  const [partnerSpan, SetPartnerSpan] = useState(true);
  const [legalSpan, SetLegalSpan] = useState(true);
  const [tradingSpan, SetTradingSpan] = useState(true);
  const [clientIdSpan, SetClientIdSpan] = useState(true);
  const [applicationSpan, SetApplicationSpan] = useState(true);
  const [createdAtSpan, SetCreatedAtSpan] = useState(true);
  const [noteSpan, SetNoteSpan] = useState(true);
  const [totalItems, setTotalItems] = useState([]);
  // ----------main data state---------
  const [leads, setLeads] = useState([]);
  const pageCounts = Math.ceil(totalData / itemPerPage);

  // ----------search query----------
  const [query, setQuery] = useState({
    legal_name_Input: "",
    trading_name_Input: "",
    application_stage_input: "",
    client_id_Input: "",
    created_at: "",
  });

  const indexOfLastPost = pageNumber * itemPerPage;
  const indexOfFirstPost = indexOfLastPost - itemPerPage;
  const offset = pageCounts < pageNumber ? 0 : indexOfLastPost - itemPerPage;
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  // sorting
  const [sort, setSort] = useState("ASC");

  var options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const getNewApplication = (url) => {
    axios
      .get(url)
      .then((res) => {
        const newData = res?.data?.data?.results?.map((curr) => {
          const created_at = new Date(curr?.created_at);
          const submitted_date = `${created_at.getDate()}/${
            created_at.getMonth() + 1
          }/${created_at.getFullYear()}`;
          const submitted_time = `${created_at.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}`;
          return {
            ...curr,
            created_at: `${submitted_date} at ${submitted_time}`,
            client_id: curr?.client_id === null ? "N/A" : curr?.client_id,
          };
        });
        SetTotalData(res?.data?.data?.count);
        setNextUrl(res?.data?.data?.next);
        setPrevUrl(res?.data?.data?.previous);
        setLeads(newData);
        setIsloading(false);
        setSecondLoader(false);
      })
      .catch((err) => {
        const message = JSON.parse(err?.request?.response)?.message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // localStorage.removeItem('is_logged_in');
          // localStorage.removeItem('access_token');
          // localStorage.removeItem('userData');
          history.push("/");
        }
      });
  };
  // const getNewApplication = (url) => {
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       setIsloading(true);
  //       const newData = res?.data?.data?.results?.map((curr) => ({
  //         ...curr,
  //         created_at: new Date(curr?.created_at).toLocaleDateString(
  //           "en-US",
  //           options
  //         ),
  //         client_id: curr?.client_id === null ? "N/A" : curr?.client_id,
  //       }));
  //       SetTotalData(res?.data?.data?.count);
  //       setNextUrl(res?.data?.data?.next);
  //       setPrevUrl(res?.data?.data?.previous);
  //       setLeads(newData);
  //       setSecondLoader(false);
  //       setIsloading(false);
  //       console.log("leads", newData);
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err.request.response).message;
  //       console.log(err.request.response);
  //       if (
  //         message === "Invalid token." ||
  //         JSON.parse(err.request.response).code === 401
  //       ) {
  //         localStorage.removeItem("is_logged_in");
  //         localStorage.removeItem("access_token");
  //         localStorage.removeItem("userData");
  //         showToast("success", "Invalid Token");
  //         history.push("/");
  //       }
  //     });
  // };
  const getNewApplicationPdf = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setIsloading(true)
        const newData = res?.data?.data?.results?.map((curr) => ({
          ...curr,
          created_at: new Date(curr?.created_at).toLocaleDateString(
            "en-US",
            options
          ),
          client_id: curr?.client_id === null ? "N/A" : curr?.client_id,
        }));
        setPdfData(newData);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // localStorage.removeItem("is_logged_in");
          // localStorage.removeItem("access_token");
          // localStorage.removeItem("userData");
          showToast("success", "Invalid Token");
          history.push("/login");
        }
      });
  };

  // useEffect(() => {
  //   getNewApplication(
  //     `${BASE_URL}api/v1/applications/application/?application_status=0&limit=${itemPerPage}`
  //   );
  // }, []);
  // useEffect(() => {
  //   getNewApplication(
  //     `${BASE_URL}api/v1/applications/application/?application_status=0&limit=${itemPerPage}&query=${search}`
  //   );
  // }, [search, pageCounts, itemPerPage, pageNumber]);
  useEffect(() => {
    setSecondLoader(true);
    getNewApplication(
      `${BASE_URL}api/v1/applications/application/?query=${search}&application_status=0&limit=${itemPerPage}&offset=${offset}`
    );
  }, [search, itemPerPage, offset]);
  useEffect(() => {
    getNewApplicationPdf(
      `${BASE_URL}api/v1/applications/application/?query=${""}&application_status=0&limit=${totalData}`
    );
  }, [totalData]);
  // gh

  useEffect(() => {
    if (pageCounts !== 0) {
      if (pageCounts <= pageNumber) {
        setPageNumber(pageCounts);
      }
    }
  }, [pageCounts]);

  useEffect(() => {
    setPageNumber(1);
  }, [itemPerPage]);

  const limit = itemPerPage;
  const total_item = totalData;
  const pageCount = Math.ceil(total_item / limit);
  const handlePaginationPrevious = () => {
    setIsloading(true);
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      application_stage_input: "",
      client_id_Input: "",
      created_at: "",
    });
    getNewApplication(prevUrl);
    // setPageNumber(pageNumber - 1);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const handlePaginationNext = () => {
    setIsloading(true);
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      client_id_Input: "",
      created_at: "",
      application_stage_input: "",
    });
    getNewApplication(nextUrl);
    setPageNumber(pageNumber + 1);
  };

  const handleChangeCurrentPage = (val) => {
    setIsloading(true);
    setQuery({
      legal_name_Input: "",
      trading_name_Input: "",
      client_id_Input: "",
      created_at: "",
      application_stage_input: "",
    });
    setPageNumber(val);
  };
  console.log("clicked", pageNumber);

  if (isLoading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  // const NewApplicationPagination = ({totalData}) => {

  //   const limit = itemPerPage;
  //   const total_item = totalData;
  //   const pageCount = Math.ceil(total_item / limit);

  //   const handlePaginationPrevious = () => {
  //     setPageNumber(pageNumber - 1);
  //   };

  //   const handlePaginationNext = () => {
  //     setPageNumber(pageNumber + 1);
  //   };

  //   const handleChangeCurrentPage = (val) => {
  //     setPageNumber(val);
  //   };

  //   useEffect(() => {
  //     if (pageCount !== 0) {
  //       if (pageCount < pageNumber) {
  //         setPageNumber(pageCount);
  //       }
  //     }
  //   }, [pageCount]);

  //   if(isLoading){
  //     return <BiLoader/>
  //   }

  //   return (
  //     <>
  //       <div className="row lead_pagination bg-transparent">
  //         <div className="col-12  d-flex justify-content-end pagination_container">
  //           <nav aria-label="...">
  //             <ul className="pagination pagination-sm">
  //               <li className="page-item ">
  //                 {pageNumber < 2 ? (
  //                   <Link className="previous">
  //                     <MdOutlineArrowBackIos
  //                       style={{ width: "16px", height: "21px", color: "gray" }}
  //                     />
  //                   </Link>
  //                 ) : (
  //                   <Link
  //                     onClick={handlePaginationPrevious}
  //                     className={` pagginationButtons `}
  //                     to="#"
  //                     tabindex="-1"
  //                   >
  //                     <MdOutlineArrowBackIos
  //                       style={{ width: "16px", height: "21px" }}
  //                     />
  //                   </Link>
  //                 )}
  //               </li>

  //               {[...Array(pageCount ? pageCount : 0)]?.map((elem, index) => (
  //                 <li className="page-item">
  //                   <Link
  //                     onClick={() => handleChangeCurrentPage(index + 1)}
  //                     className={`${
  //                       index + 1 == pageNumber
  //                         ? "activess pagginationButtons"
  //                         : "pagginationButtons"
  //                     }`}
  //                     to="#"
  //                   >
  //                     <span>{index + 1}</span>
  //                   </Link>
  //                 </li>
  //               ))}

  //               <li className="page-item">
  //                 <Link
  //                   onClick={handlePaginationNext}
  //                   className={`${
  //                     pageNumber < pageCount ? "pagginationButtons" : "previous"
  //                   } `}
  //                   to="#"
  //                 >
  //                   <MdArrowForwardIos
  //                     style={{ width: "16px", height: "21px" }}
  //                   />
  //                 </Link>
  //               </li>
  //             </ul>
  //           </nav>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // ----------------asending dsending -------------------
  console.log("leads", leads);
  // const sortingData = (sortBy, stateSpan) => {
  //   if (sortBy === "created_at") {
  //     const monthMap = {
  //       January: 1,
  //       February: 2,
  //       March: 3,
  //       April: 4,
  //       May: 5,
  //       June: 6,
  //       July: 7,
  //       August: 8,
  //       September: 9,
  //       October: 10,
  //       November: 11,
  //       December: 12,
  //     };
  //     if (sort === "ASC") {
  //       const sortedData = [...leads].sort((a, b) => {
  //         const aDateParts = a.created_at.split(" ");
  //         const bDateParts = b.created_at.split(" ");

  //         const aMonth = monthMap[aDateParts[0]];
  //         const bMonth = monthMap[bDateParts[0]];

  //         if (aMonth !== bMonth) {
  //           return aMonth - bMonth;
  //         } else {
  //           const aDay = parseInt(aDateParts[1].replace(",", ""), 10);
  //           const bDay = parseInt(bDateParts[1].replace(",", ""), 10);
  //           return aDay - bDay;
  //         }
  //       });
  //       setLeads(sortedData);
  //       setSort("DSC");
  //       stateSpan(!true);
  //     } else if (sort === "DSC") {
  //       const sortedData = [...leads].sort((a, b) => {
  //         const aDateParts = a.created_at.split(" ");
  //         const bDateParts = b.created_at.split(" ");

  //         const aMonth = monthMap[aDateParts[0]];
  //         const bMonth = monthMap[bDateParts[0]];

  //         if (aMonth !== bMonth) {
  //           return bMonth - aMonth;
  //         } else {
  //           const aDay = parseInt(aDateParts[1].replace(",", ""), 10);
  //           const bDay = parseInt(bDateParts[1].replace(",", ""), 10);
  //           return bDay - aDay;
  //         }
  //       });
  //       setLeads(sortedData);
  //       setSort("ASC");
  //       stateSpan(!false);
  //     }
  //   } else {
  //     if (sort === "ASC") {
  //       const sorted = [...leads].sort((a, b) =>
  //         a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1
  //       );
  //       setLeads(sorted);
  //       setSort("DSC");
  //       stateSpan(!true);
  //     } else if (sort === "DSC") {
  //       const sorted = [...leads].sort((a, b) =>
  //         a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1
  //       );
  //       setLeads(sorted);
  //       setSort("ASC");
  //       stateSpan(!false);
  //     }
  //   }
  // };
  const sortingData = (sortBy, stateSpan) => {
    if (sort === "ASC") {
      const sorted = [...leads]?.sort((a, b) =>
        a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase() ? 1 : -1
      );
      setTotalItems(sorted);
      setLeads(sorted);
      setSort("DSC");
      stateSpan(!true);
    } else if (sort === "DSC") {
      const sorted = [...leads]?.sort((a, b) =>
        a[sortBy]?.toLowerCase() < b[sortBy]?.toLowerCase() ? 1 : -1
      );
      setTotalItems(sorted);
      setLeads(sorted);
      setSort("ASC");
      stateSpan(!false);
    }
  };
  const handleFilterInput = (e) => {
    const InputName = e.target.name;
    const Inputval = e.target.value;
    setQuery((previous_values) => {
      return { ...previous_values, [InputName]: Inputval };
    });
  };

  const handleApplicationView = (data) => {
    dispatch(GetAplicationDetails(data));

    history.push(`/home/${data}`);
  };

  // ----------dispay data--------------
  // || fill.trading_name.toLowerCase().includes(query) || fill?.recievedDate?.toLowerCase().includes(query) || fill?.note?.toLowerCase().includes(query)

  const displayData = leads
    ?.filter((fill) => {
      if (
        query.legal_name_Input === "" ||
        query.trading_name_Input === "" ||
        query?.application_stage_input === "" ||
        fill?.client_id === null
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill.legal_name
          ?.toLowerCase()
          .includes(query.legal_name_Input.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill.trading_name
          ?.toLowerCase()
          .includes(query.trading_name_Input.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill.client_id
          ?.toLowerCase()
          .includes(query.client_id_Input.toLowerCase())
      ) {
        return fill;
      }
    })
    .filter((fill) => {
      if (
        fill.created_at?.toLowerCase().includes(query.created_at.toLowerCase())
      ) {
        return fill;
      }
    });

  if (isLoading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className="open-sans " style={{ maxWidth: "100vw" }}>
      <div className="ap-top">
        <div className=" ap-top-box">
          <div className="ap-top-head">
            <div>
              <img src={application} alt="" />
            </div>
            <h2 className="open-sans">New Application</h2>
          </div>
          <div
            onClick={() => getPrint()}
            // onClick={HandlePdf}
            className="print-div  cursor-pointer"
          >
            <img color="#004C1B" src={printer} alt="" />
          </div>
        </div>
      </div>
      <div className="pagination-main">
        {/* ---------------filter and pagination------------ */}
        <div className="filter ">
          <p>Search By -</p>
          <input
            type="text"
            placeholder="Text Here.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            ref={inputRef}
          />
        </div>
        <div className=" item-per-page">
          <p style={{ paddingRight: "3px" }}>Item per page:</p>
          <select
            name=""
            id=""
            style={{ border: "1px solid #BDBDBD" }}
            value={itemPerPage}
            onChange={(e) => {
              console.log("perPage", e.target.value);
              setItemPerPage(e.target.value);
            }}
          >
            <option className="text-[14px]" value="10" selected>
              10
            </option>

            <option className="text-[14px]" value="20">
              20
            </option>
            <option className="text-[14px]" value="30">
              30
            </option>
            <option className="text-[14px]" value={totalData}>
              All
            </option>
          </select>
        </div>
        {/* ---------------filter and pagination------------ */}
      </div>
      {/* ---------------main table start----------------- */}

      <div style={{ marginTop: "16px", marginBottom: "48px" }}>
        <div style={{ overflowX: "auto" }}>
          <table class="table application-table" id="leads-table">
            <thead style={{ borderRadius: "5px !important" }}>
              <tr
                className="application-main-head"
                style={{ padding: "1rem .75rem" }}
              >
                <th
                  style={{ width: "115px", padding: "1rem .75rem" }}
                  className="head-data1 a_id custom-radius"
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginLeft: "3px",
                    }}
                  >
                    {" "}
                    Client Id
                  </span>{" "}
                  <svg
                    onClick={() => {
                      sortingData("client_id", SetClientIdSpan);
                    }}
                    style={{
                      color: "#828282",
                      marginLeft: "18px",
                      width: "15px",
                      height: "14px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        clientIdSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>

                <th style={{ width: "152px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    Account Name
                  </span>{" "}
                  <svg
                    onClick={() => sortingData("legal_name", SetLegalSpan)}
                    style={{
                      color: "#828282",
                      marginLeft: "22px",
                      width: "15px",
                      height: "16px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        legalSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>

                <th style={{ width: "160px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "2px" }}>
                    Trading Name
                  </span>{" "}
                  <svg
                    onClick={() => sortingData("trading_name", SetTradingSpan)}
                    style={{
                      color: "#828282",
                      marginLeft: "22px",
                      width: "15px",
                      height: "16px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        tradingSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>
                <th style={{ width: "160px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "2px" }}>
                    Application Stage
                  </span>{" "}
                  <svg
                    onClick={() =>
                      sortingData("trading_name", SetApplicationSpan)
                    }
                    style={{
                      color: "#828282",
                      marginLeft: "22px",
                      width: "15px",
                      height: "16px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        applicationSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>
                <th style={{ width: "162px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    Create On
                  </span>{" "}
                  <svg
                    onClick={() => sortingData("created_at", SetCreatedAtSpan)}
                    style={{
                      color: "#828282",
                      marginLeft: "40px",
                      width: "15px",
                      height: "16px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        createdAtSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>
                <th style={{ width: "235px" , textAlign:"center"}} className="head-data1">
                  <span
                    style={{
                      fontWeight: "400",
                      marginLeft: "3px",
                      // marginRight: "118px",
                    }}
                  >
                    Note
                  </span>{" "}
                  {/* <span
                    style={{
                      color: "#828282",
                      width: "10px",
                      height: "14px",
                      marginBottom: "8px",
                      cursor: "pointer",
                    }}
                  >
                    &#8593;
                  </span> */}
                  <svg
                    onClick={() => sortingData("sof_notes", SetNoteSpan)}
                    style={{
                      color: "#828282",
                      // marginLeft: "40px",
                      width: "15px",
                      height: "16px",
                      cursor: "pointer",
                      marginTop: "4px",
                      transform: `${
                        noteSpan ? "rotate(0deg)" : "rotate(180deg)"
                      }`,
                    }}
                    data-v-431cdece=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    role="img"
                    class="c-icon c-icon-custom-size icon-transition position-absolute arrow-position transparent"
                    width="18"
                    aria-label="change column: 'Due Date' sorting"
                  >
                    <polygon
                      fill="var(--ci-primary-color, currentColor)"
                      points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
                      class="ci-primary"
                    ></polygon>
                  </svg>
                </th>
                <th
                  className="custom-radius-last-head"
                  style={{
                    textAlign: "center",
                    marginBottom: "3px",
                    borderBottom: ".4px solid #BDBDBD",
                  }}
                >
                  <span className="" style={{ fontWeight: "400" }}>
                    Action
                  </span>
                </th>
              </tr>
            </thead>
            <thead>
              <tr
                style={{
                  background: "#D8E3E7",
                  textTransform: "capitalize",
                  borderBottom: "0.4px solid #BDBDBD",
                }}
                className="row-head"
              >
                <th
                  style={{
                    borderRight: "0.4px solid #BDBDBD",
                    borderLeft: "0.4px solid #BDBDBD",
                  }}
                  className=" a_id"
                >
                  <input
                    type="text"
                    style={{
                      width: "87px",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    name="client_id_Input"
                    onChange={handleFilterInput}
                    value={query["client_id_Input"]}
                  />
                </th>
                {/* <th className="" style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    style={{
                      width: "116px",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    type="text"
                  />
                </th> */}

                <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    style={{
                      width: "125px",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    type="text"
                    name="legal_name_Input"
                    onChange={handleFilterInput}
                    value={query["legal_name_Input"]}
                  />
                </th>
                <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    style={{
                      width: "136px",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    type="text"
                    name="trading_name_Input"
                    onChange={handleFilterInput}
                    value={query["trading_name_Input"]}
                  />
                </th>
                <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    style={{
                      width: "100%",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    type="text"
                    name="application_stage_input"
                    onChange={handleFilterInput}
                    value={query["application_stage_input"]}
                  />
                </th>
                <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    name="created_at"
                    onChange={handleFilterInput}
                    value={query["created_at"]}
                  />
                </th>
                <th style={{ borderRight: "0.4px solid #BDBDBD" }}>
                  <input
                    style={{
                      width: "100%",
                      height: "24px",
                      borderRadius: "4px",
                    }}
                    type="text"
                  />
                </th>
                <th
                  style={{
                    width: "132px",
                    borderRight: "0.4px solid #BDBDBD",
                  }}
                  className=""
                >
                  <div className="w-100 d-flex justify-content-center">
                    <img
                      src={eye}
                      alt=""
                      style={{
                        width: "24px",
                        // margin: "0px 42px",
                        height: "18px",
                      }}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            {leads?.length === 0 ? (
              <tr>
                <td colspan="9">
                  <div className="not_found">
                    <h4 className="my-4">No Data Found</h4>
                  </div>
                </td>
              </tr>
            ) : secondLoader ? (
              <>
                <Backdrop
                  open
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            ) : (
              <tbody>
                {displayData?.map((application, index) => (
                  <tr
                    className={` tr table-tr application-row`}
                    key={application.id}
                    style={{
                      backgroundColor: `${
                        index % 2 === 0 ? "#EFEFF1" : "white"
                      }`,
                    }}
                  >
                    <th className="a_id th border" style={{ fontSize: "14px" }}>
                      {application?.client_id}
                    </th>

                    {/* <td className="th border">{application.bank_account_name}</td> */}
                    <td className="th border">
                      {application?.legal_name?.length < 20
                        ? application?.legal_name
                        : `${application?.legal_name.slice(0, 20)}...`}
                    </td>
                    <td className="th border">
                      {application?.trading_name?.length < 20
                        ? application?.trading_name
                        : `${application?.trading_name.slice(0, 20)}...`}
                    </td>
                    <td className="th border">
                      {applicationStage.map((option) => {
                        return (
                          <>
                            {option.value === application.applicaiton_stage && (
                              <span key={option.value} value={option.value}>
                                {option?.label === "Application sign back" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{
                                      minWidth: "190px",
                                      background: "purple",
                                    }}
                                  >
                                    <span className="lead_status_text ">
                                      Application sign back
                                    </span>
                                  </button>
                                ) : option?.label === "Application received" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{ minWidth: "190px" }}
                                  >
                                    <span className="lead_status_text ">
                                      Application received
                                    </span>
                                  </button>
                                ) : option?.label === "PS Query" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{
                                      minWidth: "190px",
                                      background: "#126047d1",
                                    }}
                                  >
                                    <span className="lead_status_text ">
                                      PS Queryr
                                    </span>
                                  </button>
                                ) : option?.label ===
                                  "Waiting to fill up the form" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{
                                      minWidth: "190px",
                                      background: "#e77110",
                                    }}
                                  >
                                    <span className="lead_status_text ">
                                      Waiting to fill up the form
                                    </span>
                                  </button>
                                ) : option?.label === "Sent for esign" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{
                                      minWidth: "190px",
                                      background: "rgb(50, 92, 196)",
                                    }}
                                  >
                                    <span className="lead_status_text ">
                                      Sent for esign
                                    </span>
                                  </button>
                                ) : option?.label === "Sent to bank" ? (
                                  <button
                                    className="lead_status_btn "
                                    style={{
                                      minWidth: "190px",
                                      background: "rgb(235, 87, 87)",
                                    }}
                                  >
                                    <span className="lead_status_text ">
                                      Sent to bank
                                    </span>
                                  </button>
                                ) : (
                                  option?.label === "SUBMISSION ERRORED" && (
                                    <button
                                      className="lead_status_btn "
                                      style={{
                                        minWidth: "190px",
                                        background: "#ff5252",
                                      }}
                                    >
                                      <span className="lead_status_text ">
                                        SUBMISSION ERRORED
                                      </span>
                                    </button>
                                  )
                                )}
                                {/* {option?.label} */}
                              </span>
                            )}
                          </>
                        );
                      })}
                    </td>
                    <td className="th border">
                      {application.created_at
                        ? application.created_at
                        : "15 May 2020,  9:30 am"}
                    </td>
                    <td className="th border">{application.sof_notes}</td>
                    <td
                      className="th border "
                      onClick={() => handleApplicationView(application.id)}
                    >
                      <div className="d-flex justify-content-center">
                        <Link to={"/home"} className="route-link">
                          <FaEye
                            className="button-icon"
                            style={{
                              width: "12px",
                              height: "12px",
                              color: "black",
                            }}
                          />
                          <span
                            className="button-text"
                            style={{ marginLeft: "8px", color: "black" }}
                          >
                            view
                          </span>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* ---------------main table end----------------- */}
      <div className="row lead_pagination bg-transparent">
        <div className="col-12  d-flex justify-content-end pagination_container">
          <PageNumberCount
            currentPage={pageNumber}
            totalPages={pageCount}
            onPageChange={handleChangeCurrentPage}
          />
          {/* <nav aria-label="...">
            <ul className="pagination pagination-sm">
              <li className="page-item ">
                {pageNumber < 2 ? (
                  <Link className="previous">
                    <MdOutlineArrowBackIos
                      style={{ width: "16px", height: "21px", color: "gray" }}
                    />
                  </Link>
                ) : (
                  <Link
                    onClick={handlePaginationPrevious}
                    className={` pagginationButtons `}
                    to="#"
                    tabindex="-1"
                  >
                    <MdOutlineArrowBackIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                )}
              </li>

              {[...Array(pageCount ? pageCount : 0)]?.map((elem, index) => (
                <li className="page-item">
                  <Link
                    onClick={() => handleChangeCurrentPage(index + 1)}
                    className={`${
                      index + 1 === pageNumber
                        ? "activess pagginationButtons"
                        : "pagginationButtons"
                    }`}
                    to="#"
                  >
                    <span>{index + 1}</span>
                  </Link>
                </li>
              ))}

              <li className="page-item">
                {nextUrl !== null ? (
                  <Link
                    onClick={handlePaginationNext}
                    className={`${
                      pageNumber < pageCount ? "pagginationButtons" : "previous"
                    } `}
                    to="#"
                  >
                    <MdArrowForwardIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                ) : (
                  <Link
                    // onClick={handlePaginationNext}
                    className={`${
                      pageNumber < pageCount ? "pagginationButtons" : "previous"
                    } `}
                    to="#"
                  >
                    <MdArrowForwardIos
                      style={{ width: "16px", height: "21px" }}
                    />
                  </Link>
                )}
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
      {/* pdf---- */}
      <div style={{ display: "none" }}>
        <div style={{ overflowX: "auto" }}>
          <table class="table application-table " ref={cRef}>
            <thead style={{ borderRadius: "5px !important" }}>
              <tr
                className="application-main-head"
                style={{ padding: "1rem .75rem" }}
              >
                <th
                  style={{ width: "115px", padding: "1rem .75rem" }}
                  className="head-data1 a_id custom-radius"
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginLeft: "3px",
                    }}
                  >
                    {" "}
                    Client Id
                  </span>{" "}
                </th>

                <th style={{ width: "152px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    Account Name
                  </span>{" "}
                </th>

                <th style={{ width: "160px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "2px" }}>
                    Trading Name
                  </span>{" "}
                </th>
                <th style={{ width: "160px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "2px" }}>
                    Application Stage
                  </span>{" "}
                </th>
                <th style={{ width: "162px" }} className="head-data1">
                  <span style={{ fontWeight: "400", marginLeft: "3px" }}>
                    Create On
                  </span>{" "}
                </th>

                <th
                  className="custom-radius-last-head"
                  style={{
                    textAlign: "center",
                    marginBottom: "3px",
                    borderBottom: ".4px solid #BDBDBD",
                  }}
                >
                  <span className="" style={{ fontWeight: "400" }}>
                    Note
                  </span>
                </th>
              </tr>
            </thead>

            {pdfData?.length === 0 ? (
              <tr>
                <td colspan="9">
                  <div className="not_found">
                    <h4 className="my-4">No Data Found</h4>
                  </div>
                </td>
              </tr>
            ) : (
              <tbody>
                {pdfData?.map((application, index) => (
                  <tr
                    className={` tr table-tr application-row`}
                    key={application.id}
                    style={{
                      backgroundColor: `${
                        index % 2 === 0 ? "#EFEFF1" : "white"
                      }`,
                    }}
                  >
                    <th className="a_id th border" style={{ fontSize: "14px" }}>
                      {application?.client_id}
                    </th>

                    {/* <td className="th border">{application.bank_account_name}</td> */}
                    <td className="th border">
                      {/* {application?.legal_name?.length < 20
                        ? application?.legal_name
                        : `${application?.legal_name.slice(0, 20)}...`} */}
                      {application?.legal_name}
                    </td>
                    <td className="th border">
                      {/* {application?.trading_name?.length < 20
                        ? application?.trading_name
                        : `${application?.trading_name.slice(0, 20)}...`} */}
                      {application?.trading_name}
                    </td>
                    <td className="th border">
                      {applicationStage.map((option) => {
                        return (
                          <>
                            {option.value === application.applicaiton_stage && (
                              <span key={option.value} value={option.value}>
                                {option?.label}
                              </span>
                            )}
                          </>
                        );
                      })}
                    </td>
                    <td className="th border">
                      {application.created_at
                        ? application.created_at
                        : "15 May 2020,  9:30 am"}
                    </td>
                    <td className="th border">{application.sof_notes}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default NewApplicationPage;
