import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import pen from "../../../../../../asstes/icon.bablu/pen.svg";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
// import { SetApplicationDetails } from "../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { useParams } from "react-router-dom";

const FinancialInformation = ({ financialInfoEdit }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  // ---api call-----------------
  const isLoadLeads = useSelector((state) => state.applicationInfo.isLoadLeads);
  const applicationDetails = useSelector(
    (state) => state.applicationInfo.applicationDetails
  );
  // useEffect(() => {
  //   dispatch(SetApplicationDetails(id));
  // }, []);
  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100 text-capitalize"
    >
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Financial Information</h4>
        </div>
        <div className="button-box">
          <button className="   custom-btn  flex " onClick={financialInfoEdit}>
            <img src={pen} style={{ marginRight: "7px" }} alt="" />
            Edit
          </button>
        </div>
      </div>

      <div className="detail-content">
        <div>
          <p> Renting Terminal Proms From Elavon?<span className="required">*</span></p>
          {applicationInput?.renting_elavon_terminals === true ? (
            <span className="extra-color">Yes</span>
          ) : (
            <span className="extra-color">No</span>
          )}
        </div>
        <div>
          <p>Faster Payment<span className="required">*</span></p>
          {applicationInput?.bank_faster_payments === "1" ? (
            <span className="extra-color">Yes</span>
          ) : (
            <span className="extra-color">No</span>
          )}
        </div>

        <div>
          <p>Payment Method<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.payment_method}
          </span>
        </div>
        <div>
          <p>Funding Frequency<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.funding_frequesncy}
          </span>
        </div>
        {applicationInput.funding_frequesncy === "WEEKLY" && (
          <div>
            <p>Funding Day<span className="required">*</span></p>
            <span className="extra-color">{applicationInput?.funding_day}</span>
          </div>
        )}
        <div>
          <p>Vat Override</p>
          {/* <span className="extra-color">{applicationInput?.bank_sort_code}</span> */}

          {applicationInput?.vat_override === true ? (
            <span className="extra-color">Yes</span>
          ) : (
            <span className="extra-color">No</span>
          )}
        </div>

        <div>
          <p>Billing Frequency<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.billing_frequesncy}
          </span>
        </div>
        {applicationInput.billing_frequesncy === "WEEKLY" && (
          <div>
            <p>Billing Days<span className="required">*</span></p>
            <span className="extra-color">{applicationInput?.billing_day}</span>
          </div>
        )}


        <div>
          <p>Settelment Method<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.bank_settlement_method}
          </span>
        </div>
        <div>
          <p>Account<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.bank_account_type}
          </span>
        </div>
        <div>
          <p>Bank Name<span className="required">*</span></p>
          <span className="extra-color">{applicationInput?.bank_name}</span>
        </div>
        <div>
          <p>Sort Code<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.bank_sort_code}
          </span>
        </div>
        <div>
          <p>Bank Account Name<span className="required">*</span></p>
          <span className="extra-color">
            {applicationInput?.bank_account_name}
          </span>
        </div>
        <div>
          <p>Bank Account Number</p>
          <span className="extra-color">
            {applicationInput?.bank_account_no}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FinancialInformation;
