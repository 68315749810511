import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../../Const";
import {
  paginationChangeCurrent,
  paginationLimit,
  paginationNext,
  paginationPrevious,
  GetPaginationData,
  setpaginationName,
  setLoadLeads,
  PaginationLoading,
} from "../../../mainLead/_redux/action/LeadsAction";
// import {
//   paginationChangeCurrent,
//   paginationLimit,
//   paginationNext,
//   paginationPrevious,
//   GetPaginationData,
//   setpaginationName,
//   setLoadLeads,
//   PaginationLoading,
// } from "../../_redux/action/LeadsAction";

const OffsetPagination = ({ PaginationName, URL_MID, search }) => {
  const dispatch = useDispatch();
  const [Is_initial, setIs_initial] = useState(true);

  const limit = useSelector((state) => state.Pagination.limit[PaginationName]);
  const total_item = useSelector((state) =>
    state.Pagination.data[PaginationName]?.count != null
      ? state.Pagination.data[PaginationName]?.count
      : 0
  );
  const items_data = useSelector((state) =>
    state.Pagination.data[PaginationName] != null
      ? state.Pagination.data[PaginationName]
      : {}
  );
  const pageNumber = useSelector(
    (state) => state.Pagination.pageNumber[PaginationName]
  );

  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  useEffect(() => {
    dispatch(setpaginationName(PaginationName));
    dispatch(
      GetPaginationData(`${BASE_URL}${URL_MID}&limit=${limit}&offset=${offset}`)
    );
    setIs_initial(false);
  }, []);

  const onLimitChange = (e) => {
    let limitVal = parseInt(e.target.value);
    dispatch(setLoadLeads(0));
    dispatch(PaginationLoading(1));
    dispatch(paginationLimit(limitVal));
  };

  const handlePaginationPrevious = () => {
    dispatch(setLoadLeads(0));
    dispatch(PaginationLoading(1));
    dispatch(paginationPrevious());
    dispatch(GetPaginationData(items_data.previous));
  };

  const handlePaginationNext = () => {
    dispatch(setLoadLeads(0));
    dispatch(PaginationLoading(1));
    dispatch(paginationNext());
    dispatch(GetPaginationData(items_data.next));
  };

  const handleChangeCurrentPage = (val) => {
    dispatch(setLoadLeads(0));
    dispatch(PaginationLoading(1));
    dispatch(paginationChangeCurrent(val));
  };

  useEffect(() => {
    if (!Is_initial) {
      if (pageCount !== 0) {
        if (pageCount < pageNumber) {
          dispatch(paginationChangeCurrent(pageCount));
        }
      }
    }
  }, [pageCount]);

  useEffect(() => {
    if (!Is_initial) {
      dispatch(
        GetPaginationData(
          `${BASE_URL}${URL_MID}&limit=${limit}&offset=${offset}&query=${search}`
        )
      );
    }
  }, [limit, offset, search]);

  return (
    <>
      <div className="row lead_pagination bg-transparent">
        <div className="col-12  col-lg-6 col-md-6 col-sm-6    col-xs-12 pagination_text ">
          <span>Show: </span>
          <select value={limit} onChange={onLimitChange}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value={total_item}>All</option>
          </select>
          <span className="ms-1">
            {page_show_start} - {this_page_items_show} of {total_item}
          </span>
        </div>

        <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
          <nav aria-label="...">
            <ul className="pagination pagination-sm">
              <li className="page-item ">
                <Link
                  onClick={handlePaginationPrevious}
                  className={` ${
                    items_data.previous === null ? "disabled" : ""
                  } page-link pagination_texts pagination_btn font_size_for_small me-2`}
                  to="#"
                  tabindex="-1"
                >
                  Previous
                </Link>
              </li>

              {[...Array(pageCount === null ? 1 : pageCount)].map((elem, index) => (
                <li className="page-item">
                  <Link
                    onClick={() => handleChangeCurrentPage(index + 1)}
                    className={`${
                      index + 1 == pageNumber ? "is_active_page" : ""
                    } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts`}
                    to="#"
                  >
                    <span>{index + 1}</span>
                  </Link>
                </li>
              ))}
              {/* {[...Array(pageCount === null ? 1 : pageCount)].map(
                (elem, index) => {
                  const totalPages = pageCount === null ? 1 : pageCount;
                  const maxPagesToShow = 5;
                  const middlePage = Math.ceil(maxPagesToShow / 2);
                  let isStartEllipsisVisible = pageNumber > middlePage;
                  let isEndEllipsisVisible =
                    pageNumber < totalPages - middlePage + 1;
                  let isPageVisible =
                    index + 1 === pageNumber ||
                    index + 1 <= maxPagesToShow ||
                    (isStartEllipsisVisible &&
                      index + 1 > pageNumber - middlePage &&
                      index + 1 < pageNumber) ||
                    (isEndEllipsisVisible &&
                      index + 1 > pageNumber &&
                      index + 1 < pageNumber + middlePage);

                  if (totalPages > maxPagesToShow) {
                    if (
                      !isStartEllipsisVisible &&
                      index + 1 === maxPagesToShow + 1
                    ) {
                      isEndEllipsisVisible = true;
                    } else if (
                      !isEndEllipsisVisible &&
                      index + 1 === totalPages - middlePage
                    ) {
                      isStartEllipsisVisible = true;
                    } else if (
                      isStartEllipsisVisible &&
                      index + 1 === middlePage &&
                      pageNumber > middlePage
                    ) {
                      isPageVisible = true;
                    } else if (
                      isEndEllipsisVisible &&
                      index + 1 === totalPages &&
                      pageNumber < totalPages - middlePage + 1
                    ) {
                      isPageVisible = true;
                    }
                  }

                  return isPageVisible ? (
                    <li className="page-item">
                      <Link
                        onClick={() => handleChangeCurrentPage(index + 1)}
                        className={`${
                          index + 1 === pageNumber ? "is_active_page" : ""
                        } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts`}
                        to="#"
                      >
                        <span>{index + 1}</span>
                      </Link>
                    </li>
                  ) : isStartEllipsisVisible && index === middlePage - 1 ? (
                    <li className="page-item">
                      <Link
                        onClick={() =>
                          handleChangeCurrentPage(pageNumber - middlePage)
                        }
                        className="page-link pagination_texts"
                        to="#"
                      >
                        ...
                      </Link>
                    </li>
                  ) : isEndEllipsisVisible &&
                    index === totalPages - middlePage ? (
                    <li className="page-item">
                      <Link
                        onClick={() =>
                          handleChangeCurrentPage(pageNumber + middlePage)
                        }
                        className="page-link pagination_texts"
                        to="#"
                      >
                        ...
                      </Link>
                    </li>
                  ) : null;
                }
              )} */}

              <li className="page-item">
                <Link
                  onClick={handlePaginationNext}
                  className={`${
                    items_data.next === null ? "disabled" : ""
                  } page-link pagination_btn font_size_for_small pagination_texts ms-3`}
                  to="#"
                >
                  Next
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default OffsetPagination;
