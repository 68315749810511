import React from "react";
import "./FilterPagination.css";

const FilterPagination = ({setItemPerPage, search, setSearch,totalData}) => {
  // const getPageNumbers = () => {
  //   const maxVisiblePages = 5;
  //   const pageNumbers = [];
  //   let startPage = 1;
  //   let endPage = totalPages;

  //   if (totalPages > maxVisiblePages) {
  //     if (currentPage <= Math.ceil(maxVisiblePages / 2)) {
  //       endPage = maxVisiblePages;
  //     } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
  //       startPage = totalPages - maxVisiblePages + 1;
  //     } else {
  //       startPage = currentPage - Math.floor(maxVisiblePages / 2);
  //       endPage = currentPage + Math.floor(maxVisiblePages / 2);
  //     }
  //   }

  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(i);
  //   }

  //   return pageNumbers;
  // };

  // const handlePageChange = (page) => {
  //   onPageChange(page);
  // };

  // const renderPageNumbers = () => {
  //   const pageNumbers = getPageNumbers();

  //   return pageNumbers.map((page) => (
  //     <li
  //       key={page}
  //       className={`page-item${currentPage === page ? " active" : ""}`}
  //       onClick={() => handlePageChange(page)}
  //     >
  //       <a className="page-link" href="#">
  //         {page}
  //       </a>
  //     </li>
  //   ));
  // };
  
  return (
    <div className="pagination-main">
      {/* ---------------filter and pagination------------ */}
      <div className="filter ">
        <p >Search By - </p>
        <input
          type="text"
          placeholder="Text Here..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className=" item-per-page">
        <p style={{paddingRight:'3px'}}>
          Item per page:
        </p>
        <select
          name=""
          id=""
          style={{ border: "1px solid #BDBDBD" }}
          onChange={(e)=>setItemPerPage(e.target.value)}
        >
          
          <option className="text-[14px]" value="10" selected>
            10
          </option>
          
          <option className="text-[14px]" value="20">
            20
          </option>
          <option className="text-[14px]" value="30">
            30
          </option>
          <option className="text-[14px]" value={totalData}>
         All
          </option>
        </select>
      </div>
      {/* ---------------filter and pagination------------ */}
    </div>
  );
};

export default FilterPagination;
