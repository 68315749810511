import React, { useEffect,useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";

import ErrorOffsetPagination from "./AllUserOffsetPagination";

import LoadingCheck from "../../../allAplication/mainLead/components/commons/LoadingCheck";
import SvgIcon from "../../../allAplication/mainLead/components/commons/SvgIcon";
import { LeadStatus } from "../../../allAplication/mainLead/components/commons/LeadStatus";
import { SortArrayOfObjects } from "../../../allAplication/mainLead/components/commons/Utils";
import {
  GetAplicationDetails,
  SetSigningStatusFalse,
  SetSigningStatusFalseTwo,
} from "../../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { FaEye } from "react-icons/fa";
import {
  GetLeadsnput,
  SetLeadsStatusFalse,
  SetQuoteStatusFalse,
} from "../../../allAplication/mainLead/_redux/action/LeadsAction";

import {
  applicationStage,
  user_role,
} from "../../../allAplication/mainLead/components/commons/Dropdown";
import {
  GetUserList,
  GetuserDetails,
  SetUserUpdatedData,
  Userdeleted,
} from "../../_redux/action/UserAction";
import { BASE_URL } from "../../../../Const";
import {  BiEdit } from "react-icons/bi";
import {  BsEye } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
// import { PaginationUrl } from "../../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "react-bootstrap/Button";
const TableData = ({
  data,
  check,
  selectedUser,
  FilteredLeadList,
  setSelectedUser,
  partner_id,
  contact_id,
  role,
  email,
  mobile,
  first_name,
  last_name,
  residual_percentage,
  upfront_percentage,
  create_on,
  notes,
  btn_class_name,
  current,
}) => {
  const [is_checked, set_is_checked] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const afterSuccessSigning = useSelector(
    (state) => state.applicationInfo.afterSuccessSigning
  );
  const afterSuccessSigningTwo = useSelector(
    (state) => state.applicationInfo.afterSuccessSigningTwo
  );

  useEffect(() => {
    set_is_checked(check);
  }, [check]);

 
  useEffect(() => {
    return () => {
      dispatch(SetQuoteStatusFalse());
      dispatch(SetLeadsStatusFalse());
    };
  }, []);
  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);
  const handleView = (data) => {
    dispatch(GetuserDetails(data));

    history.push(`/view_sales_partner/${data}`);
  };
  const handleEdit = (data) => {
    dispatch(SetUserUpdatedData(data));

    history.push(`/edit_partner/${data}`);
  };
  const handleDeleteUser = (data) => {
 
    dispatch(Userdeleted(data));
  };
  useEffect(() => {
    if (afterSuccessSigning) {
      dispatch(SetSigningStatusFalse());
    }
  }, [afterSuccessSigning]);

  useEffect(() => {
    if (afterSuccessSigningTwo) {
      dispatch(SetSigningStatusFalseTwo());
    }
  }, [afterSuccessSigningTwo]);
  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "allSelect") {
        setSelectedUser(FilteredLeadList);
      } else {
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  return (
    <>
    <tr className="lead_border">
      <td class="tbody_checkbox ">
        <div class="d-flex justify-content-around align-items-center">
          <input
            className="lead_checkbox"
            // onClick={(e) => set_is_checked(!is_checked)}
            // checked={is_checked}
            type="checkbox"
            // aria-label=""
            name={current.first_name}
            checked={
              selectedUser &&
              selectedUser?.some((item) => item?.id === current.id)
            }
            onChange={(e) => handleChange(e, current)}
          />
          <span style={{ visibility: "hidden" }}>#</span>
        </div>
      </td>

      <td>
        <div className="d-flex gap-1">
          <p className="lead_table_data_p">{first_name}</p>
          <p className="lead_table_data_p">{last_name}</p>
        </div>
      </td>

      <td>
        <div className="">
          <p className="lead_table_data_p">{email}</p>
          <p className="lead_table_data_p">{mobile}</p>
        </div>
      </td>

      <td className="status_btn_container">
        <div className=" d-flex justify-content-center align-items-center">
          {role === "Lead Owner" ? (
            <button
              className="lead_status_btn "
              style={{ minWidth: "190px" }}
            >
              <span className="lead_status_text ">Lead Owner</span>
            </button>
          ) : role === "Sales  Partner" ? (
            <button
              className="lead_status_btn "
              style={{ minWidth: "190px" }}
            >
              <span className="lead_status_text ">Sales Partner</span>
            </button>
          ) : (
            ""
          )}
          <button className="lead_status_btn " style={{ minWidth: "190px" }}>
            <span className="lead_status_text ">{role}</span>
          </button>
        </div>
      </td>
      <td className="status_btn_container">
        <div className=" d-flex justify-content-center align-items-center">
          <button className="lead_status_btn lead_status_btn_success">
            <span className="lead_status_text lead_status_text_success">
              {residual_percentage}
            </span>
          </button>
        </div>
      </td>
      <td className="status_btn_container">
        <div className=" d-flex justify-content-center align-items-center">
          <button
            className={`lead_status_btn lead_status_btn_${btn_class_name}`}
            style={{ background: "#ff00001f", color: "red" }}
          >
            <span
              className={`lead_status_text lead_status_text_${btn_class_name}`}
            >
              {upfront_percentage}
            </span>
          </button>
        </div>
      </td>

      <td>
        <div
          className="lead_id_td"
          style={{ display: "contents", height: 0 }}
        >
          <p className="lead_table_data_p">{partner_id}</p>
        </div>
      </td>
      <td>
        <div
          className="lead_id_td"
          style={{ display: "contents", height: 0 }}
        >
          <p className="lead_table_data_p">{contact_id}</p>
        </div>
      </td>

      <td>
        <div
          className="d-flex gap-2 align-items-center justify-content-center"
          style={{ fontSize: "19px" }}
        >
          <div
            className="d-flex justify-content-center view_btn"
            onClick={() => handleView(data)}
          >
            <BsEye />
          </div>
          <div
            className="d-flex justify-content-center edit_btn"
            onClick={() => handleEdit(data)}
          >
            <BiEdit />
          </div>
          <div
            className="d-flex justify-content-center delete_btn"
            onClick={() => handleDeleteUser(data)}
          >
            <AiOutlineDelete />
          </div>
        </div>
      </td>
    </tr>
  </>
  );
};
const AllUserList = () => {
  const [selectedUser, setSelectedUser] = React.useState([]);
  const UserList = useSelector((state) => state.userInfo.UserList);
  useEffect(() => {
    dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/`));
  }, []);
  const [SortingData, setSortingData] = useState({
    ascending: -1,
    sort_by: "",
  });

  const [search, setSearch] = useState("");
  const history = useHistory();
  const [is_all_checked, set_is_all_checked] = useState(0);
  const [FilterInputs, setFilterInputs] = useState({
    partner_id_input: "",
    contact_id_input: "",
    first_name_input: "",
    last_name_input: "",
    role_input: "",
    email_input: "",
    mobile_input: "",
    created_on_input: "",
    lead_notes_input: "",
  });

  const handleFilterInput = (e) => {
    const FilterInputName = e.target.name;
    const FilterInputval = e.target.value;
    setFilterInputs((previous_values) => {
      return { ...previous_values, [FilterInputName]: FilterInputval };
    });
  };

  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dispatch = useDispatch();

  // --------------api call-----------------
  const paginationName = "applicationList";
  const mid_url = `api/v1/auth/user/?role=1`;
  const applicationList = useSelector((state) =>
    state.Pagination.data[paginationName].results != null
      ? state.Pagination.data[paginationName].results
      : []
  );
  const leadsList_url = useSelector((state) =>
    state.Pagination.url[paginationName] !== ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const is_loading = useSelector(
    (state) => state.Pagination.IsLoading[paginationName]
  );
  // const is_loading = useSelector((state) => (state.Pagination.IsLoading != null ? state.IsLoading : true));
  const URL = useSelector((state) =>
    state.Pagination.url[paginationName] != ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const isLoadLeads = useSelector(
    (state) => state.Pagination.isLoadLeads[paginationName]
  );
  const limit = useSelector((state) => state.Pagination.limit[paginationName]);
  // useEffect(() => {
  //   dispatch(setpaginationName(paginationName));
  //   dispatch(PaginationUrl(`${BASE_URL}${mid_url}&limit=${limit}&offset=0`));
  // }, []);
  // useEffect(() => {
  //   dispatch(GetPaginationData(leadsList_url));
  // }, [leadsList_url]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(SetLeadsStatusFalse());
    };
  }, []);

  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);
  // --------------------------export pdf-----------------------
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#leads-table", theme: "grid" });
    doc.text("lead table", 20, 10);

    doc.save("sales partner table.pdf");
  };
 // -print pdf------------
 const cRef = useRef();
 const HandlePrint = useReactToPrint({
   content: () => cRef.current,
   documentTitle: "Sales Partner List",
   bodyClass: "dis",
 });
 const getPrint = () => {
   HandlePrint();
 };
  const FilteredLeadList =
    applicationList !== null && applicationList.length > 0
      ? SortArrayOfObjects(
          applicationList,
          SortingData.ascending,
          SortingData.sort_by
        )
          .filter((current) => {
            if (
              current?.partner_id !== null &&
              current.partner_id
                ?.toString()
                .toLowerCase()
                .includes(FilterInputs.partner_id_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current?.contact_id !== null &&
              current.contact_id
                ?.toString()
                .toLowerCase()
                .includes(FilterInputs.contact_id_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.first_name === null
                ? ""
                    .toString()
                    .toLowerCase()
                    .includes(FilterInputs.first_name_input.toLowerCase())
                : current.first_name
                    .toString()
                    .toLowerCase()
                    .includes(FilterInputs.first_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.last_name
                .toLowerCase()
                .includes(FilterInputs.last_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current?.email
                ?.toLowerCase()
                ?.includes(FilterInputs?.email_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current?.mobile
                ?.toLowerCase()
                ?.includes(FilterInputs?.mobile_input.toLowerCase())
            ) {
              return current;
            }
          })

          .filter((current) => {
            const converted_date = new Date(current.created_at)
              .toLocaleDateString("en-US", options)
              .toLowerCase();
            if (FilterInputs.created_on_input === "") {
              return current;
            } else if (
              converted_date.includes(
                FilterInputs.created_on_input.toLowerCase()
              )
            ) {
              return current;
            }
          })
      : [];
      const handleChange = (e, data) => {
        const { name, checked } = e.target;
        if (checked) {
          if (name === "allSelect") {
            setSelectedUser(FilteredLeadList);
          } else {
            setSelectedUser([...selectedUser, data]);
          }
        } else {
          if (name === "allSelect") {
            setSelectedUser([]);
          } else {
            let tempuser = selectedUser.filter((item) => item.id !== data.id);
            setSelectedUser(tempuser);
          }
        }
      };
      const handleDeleteAll = () => {
        if (selectedUser) {
          selectedUser.map((item) => {
            return dispatch(Userdeleted(item.id));
          });
        }
      };
  return (
    <>
      <div className="custom_breadcrumb">
        <p>
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            Home /
          </span>
          <span style={{ cursor: "not" }}> sales Partner</span>
        </p>
      </div>

      <div className="padding_lead box-wrapper2">
        <div className="row ">
          <p className="leads_heading">Sales Partner </p>
          <div className="col-lg-6  col-sm-4 col-md-6 mb-3 ">
            <div className="form-group form-group-lead ">
              <div className="position-relative">
                <input
                  type="text"
                  className=""
                  id="lead_search"
                  placeholder="Search.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.8487 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.8487 16 8 16C3.58172 16 0 12.4183 0 8Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="export_add_container col-lg-6 c0l-sm-6 col-md-6 d-flex mb-3">
            <button className="btn_export"
            //  onClick={HandlePdf}
             onClick={() => getPrint()}
            >
              Export
            </button>
            <button
              className="btn_price_quote d-flex justify-content-evenly align-items-center "
              onClick={() => {
                history.push(`/add_partner`);
              }}
            >
              <span className="me-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C8.5523 0 9 0.44772 9 1V7H15C15.5523 7 16 7.4477 16 8C16 8.5523 15.5523 9 15 9H9V15C9 15.5523 8.5523 16 8 16C7.4477 16 7 15.5523 7 15V9H1C0.44772 9 0 8.5523 0 8C0 7.4477 0.44772 7 1 7H7V1C7 0.44772 7.4477 0 8 0Z"
                    fill="white"
                  />
                </svg>
              </span>
              Add Sales Partner
            </button>
            {selectedUser.length > 0 && (
              <Button style={{borderRadius:"4px", marginLeft:"10px"}}
                onClick={() => handleDeleteAll()}
                variant="outline-danger"
              >
                <DeleteIcon /> Delete All
              </Button>
            )}
          </div>

          <div className="lead_table_container table-responsive ">
          <table ref={cRef}
              className={`lead_table newApplicationTable_00 ${""}`}
              id="leads-table"
            >
              <thead className="lead_thead lead_thead1">
                <tr className="application-main-head">
                  <th className="checkBox_size">
                    <div className="head_data_container d-flex  ">
                      <input
                        className="lead_checkbox "
                        type="checkbox"
                        name="allSelect"
                        aria-label=""
                        checked={
                          selectedUser?.length === FilteredLeadList?.length
                        }
                        onChange={(e) => handleChange(e, FilteredLeadList)}
                        // checked={is_all_checked}
                        // onChange={(e) => {
                        //   set_is_all_checked(!is_all_checked);
                        // }}
                      />
                      <span className="spanHash">#</span>
                    </div>
                  </th>

                  <th className="account_name_size">
                    <div className="head_data_container account_name_text">
                      <span className="header_text_margin"> Name</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="first_name"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>

                  <th className=" trading_name_size ">
                    <div className="head_data_container trading_name_text">
                      <span className="header_text_margin">Contact Info</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="email"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="status_size">
                    <div className="head_data_container  status_text">
                      <span>Role</span>
                    </div>
                  </th>
                  <th className="stage_size">
                    <div className="head_data_container  status_text">
                      <span>Residual Percentage</span>
                    </div>
                  </th>
                  <th className="create_on_size">
                    <div className="head_data_container justify-content-center">
                      <span className="header_text_margin">
                        Upfront Percentage
                      </span>
                      {/* <span className=" span_updown">
                        <SvgIcon
                          name="created_at"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span> */}
                    </div>
                  </th>
                  <th className="lead_id_size">
                    <div className="lead_id_text head_data_container justify-content-center">
                      <span className="header_text_margin">Partner </span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="partner_id"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="lead_id_size">
                    <div className="lead_id_text head_data_container justify-content-center">
                      <span className="header_text_margin">Contact </span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="partner_id"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="new_application_action_size">
                    <div className="application_action_div">
                      <span class="span_text">Action</span>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* 2nd thead */}
              <thead className="lead_thead ">
                <tr className="">
                  <th className="lead_border_without_right"></th>
                  <th className="lead_id_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="lead_id_input"
                      name="partner_id_input"
                    />
                  </th>

                  <th className="trading_name_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="trading_name_input"
                      name="email_input"
                    />
                  </th>
                  <th className="status_size lead_border_bottom m-auto">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="status_input_for_application "
                      name="status_input"
                      style={{ margin: "auto!important" }}
                    />
                  </th>

                  <th className="status_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="status_input_for_application "
                      name="stage_input"
                      style={{ margin: "auto 18px!important" }}
                    />
                  </th>
                  <th className="create_on_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="status_input_for_application "
                      name="created_on_input"
                    />
                  </th>

                  <th className="lead_id_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="lead_id_input"
                      name="contact_id_input"
                    />
                  </th>
                  <th className="account_name_size lead_border_bottom">
                    <input
                      type="text"
                      onChange={handleFilterInput}
                      className="account_name_input"
                      name="first_name_input"
                    />
                  </th>

                  <th className="lead_border_without_left"></th>
                </tr>
              </thead>

              <tbody className="">
                <LoadingCheck
                  isLoadLeads={isLoadLeads}
                  is_loading={is_loading}
                  FilteredLeadList={FilteredLeadList}
                />

                {!is_loading &&
                  FilteredLeadList.length > 0 &&
                  FilteredLeadList.map((current, index) => {
                    return (
                      <TableData
                        search={search}
                        isLoadLeads={isLoadLeads}
                        applicationList={applicationList}
                        item={current}
                        options={options}
                        key={current.id}
                        check={is_all_checked}
                        selectedUser={selectedUser}
                        partner_id={current?.partner_id}
                        // contact_id={current?.contact_id}
                        contact_id={current.contact_id}
                       
                        email={current?.email}
                        mobile={current?.mobile}
                        // role={current?.role}
                        first_name={current.first_name}
                        last_name={current.last_name}
                        residual_percentage={current.residual_percentage}
                        upfront_percentage={current.upfront_percentage}
                        role={user_role.map((option) => {
                          return (
                            <>
                              {option.value === current.role && (
                                <span key={option.value} value={option.value}>
                                  {option?.label}
                                </span>
                              )}
                            </>
                          );
                        })}
                        // stage={current.applicaiton_stage === 8 && <span>SUBMISSION_ERRORED</span>}
                        create_on={new Date(
                          current.created_at
                        ).toLocaleDateString("en-US", options)}
                        // notes={current.note}
                        btn_class_name={current.btn_class_name}
                        data={current.id}
                        FilteredLeadList={FilteredLeadList}
                        setSelectedUser={setSelectedUser}
                        current={current}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <ErrorOffsetPagination
          PaginationName={paginationName}
          URL_MID={mid_url}
          search={search}
        />
      </div>
    </>
  );
};

export default AllUserList;
