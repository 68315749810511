import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../../asstes/icon.bablu/add-file.svg";

import { useEffect, useState } from "react";

import cross from "../../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";


import { ecom_provider } from "../../commons/Dropdown";
import Button from "@mui/material/Button";
import { GetEcommerceProductInput, GetLeadsnput } from "../../../_redux/action/LeadsAction";
import { SET_ECOMMERCE_PRODUCT ,REMOVE_ECOMMERCE_PRODUCT} from "../../../_redux/types/Types";




function EcomProduct({type_name,id,lead_product}) {
  console.log('type_name',type_name, id)
  const epos_name = (
    <p>
      Provide EPOS Name <span className="required">*</span>
    </p>
  );
  const website = (
    <p>
      Website URL <span className="required">*</span>
    </p>
  );
  const dispatch = useDispatch();
 

  const leadInput = useSelector((state) => state.leadInfo.leadInput);
 

  // useEffect(() => {
  
  //   dispatch(
  //     GetLeadsnput(
  //       "ecommerce_products",
  //       lead_product?.filter(
  //         (item) => item?.product_type === "ecom" || item?.product_type === "VT" || item?.product_type === "ecom_VT" 
  //       )
  //     )
  //   );
   
  // }, []);

  const handleAddMore = () => {
    dispatch({ type: SET_ECOMMERCE_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_ECOMMERCE_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetEcommerceProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(
      GetEcommerceProductInput(name, value, index, "ecommerce_products")
    );
    dispatch(GetEcommerceProductInput(type_name, id, index,' ecommerce_products'));
    if (name === "ecom" || name === "ecom_VT") {
      dispatch(
        GetEcommerceProductInput("website_url", "", index, "ecommerce_products")
      );
    }
  };

  
  return (
    <>
      {leadInput?.ecommerce_products?.length < 1 && (
        <div className="d-flex justify-content-between heading mb-form mt-4 ">
          <button className="fill_add_button" onClick={() => handleAddMore()}>
            <img
              width={14}
              style={{ marginRight: "14px", marginBottom: "3px" }}
              height={14}
              src={addFileIcon}
              alt=""
            />
            ADD PRODUCT
          </button>
        </div>
      )}

      <div
        className="container row select_div"
        style={{ marginBottom: "-15px " }}
      >
        {leadInput?.ecommerce_products?.map((product, i) => {
          return (
            <>
              {product.is_deleted === false && (
                <div
                  className="col-12 col-md-6 mb-3 text-capitalize"
                  key={product.id}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "8px",
                      padding: "40px 15px",
                      margin: "15px 0px",
                      border: "0.5px solid rgb(224, 224, 224)",
                    }}
                  >
                    <div
                      // onClick={() => handleremoveDoc(i)}
                      onClick={() => {
                        product.id
                          ? handleremoveProductTwo(i)
                          : handleremoveProduct(i);
                      }}
                      className="cross d-flex"
                      style={{
                        border: "1px solid #FFCDD2",
                        width: "24px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={cross} alt="" />
                    </div>

                    <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Service Type: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <div >
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "ecom",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "ecom"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              Ecomm
                            </Button>
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "VT",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "VT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              Virtual Terminal
                            </Button>
                            <Button className='m-2'
                              onClick={() =>
                                handleChangeInput(
                                  "product_type",
                                  "ecom_VT",i
                                )
                              }
                              size="small"
                              variant={
                                product.product_type ===
                                "ecom_VT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                             Ecom & Virtual Terminal
                            </Button>
                          </div>
                        </div>
                      </div>
                    <div className="mb-form ">
                      <TextField
                        id="outlined-basic"
                        label={website}
                        variant="outlined"
                        size="small"
                        type="text"
                        name="website_url"
                        value={product.website_url}
                        onChange={(e) => {
                          handleChangeInput("website_url", e.target.value, i);
                        }}
                      />
                    </div>
                    <div className="mb-form">
                      <div className="col-lg-12 mb-4">
                        <TextField
                          size="small"
                          id="outlined-select-currency"
                          select
                          label="Getway provider"
                          name="getway_provider"
                          value={product.getway_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "getway_provider",
                              e.target.value,
                              i
                            );
                          }}
                        >
                          {ecom_provider?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    
                  </div>
                </div>
              )}
            </>
          );
        })}
        {leadInput?.ecommerce_products?.length > 0 && (
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{ marginBottom: "40px" }}
          >
            <button className="fill_add_button" onClick={() => handleAddMore()}>
              <img
                width={14}
                style={{ marginRight: "14px", marginBottom: "3px" }}
                height={14}
                src={addFileIcon}
                alt=""
              />
              ADD PRODUCT
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default EcomProduct;
