import React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLeadsnput,
} from "../../_redux/action/LeadsAction";
import Number from "./Number";
const ContactInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));
  };
  const FirstName = (
    <p>
      First Name <span className="required">*</span>
    </p>
  );
  const PrimaryEmail = (
    <p>
      Primary Email <span className="required">*</span>
    </p>
  );

  const LastName = (
    <p>
      Last Name<span className="required">*</span>
    </p>
  );
  const mobile_number = (
    <p>
      Mobile Number <span className="required">*</span>
    </p>
  );
  const Date_birth = (
    <p>
      Date Of Birth <span className="required">*</span>
    </p>
  );
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
    // else if(typeof userData === "undefined") {
    //   history.push("/");
    // }
  }, []);
  const getCountryIndex = () => {
  
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === leadInput.mobile_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getCountryTelephoneIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === leadInput.phone_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

let regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div className="info-box text-capitalize">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Contact Information</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={FirstName}
                variant="outlined"
                size="small"
                name="first_name"
                value={leadInput.first_name}
                onChange={(e) => handleChangeInput("first_name", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  new Date().getFullYear() -
                    new Date(leadInput.dob).getFullYear() <
                    10 &&
                  leadInput.dob !== "" &&
                  true
                }
                label={Date_birth}
                variant="outlined"
                type="date"
                size="small"
                InputLabelProps={{ shrink: true,  }}
                name="dob"
                value={leadInput.dob}
                onChange={(e) => handleChangeInput("dob", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  !regEmail.test(leadInput.email) &&
                  leadInput.email !== "" &&
                  true
                }
                label={PrimaryEmail}
                variant="outlined"
                size="small"
                name="email"
                value={leadInput.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  !regEmail.test(leadInput.secondary_email) &&
                  leadInput.secondary_email !== "" && leadInput.secondary_email !== null &&
                  true
                }
                label="Secondary Email"
                variant="outlined"
                size="small"
                name="secondary_email"
                value={leadInput.secondary_email}
                onChange={(e) => handleChangeInput("secondary_email", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={LastName}
                variant="outlined"
                size="small"
                name="last_name"
                value={leadInput.last_name}
                onChange={(e) => handleChangeInput("last_name", e.target.value)}
              />
            </div>
            <Number
              text={mobile_number}
              placeholder={"Mobile Number"}
              colorBg={"white"}
              name="mobile"
              value={leadInput.mobile}
              onchangevalue={(e) => handleChangeInput("mobile", e.target.value)}
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetLeadsnput("mobile_code", code.id));
                });
              }}
              flag_value={
                // countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                countryList?.[getCountryIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryIndex()]?.phone_code || "GB 44"
              }
              flag_name="mobile_code"
            />
            <Number
              text={"Telephone Number"}
              placeholder={"Telephone Number"}
              colorBg={"white"}
              name="telephone"
              value={leadInput.telephone}
              onchangevalue={(e) =>
                handleChangeInput("telephone", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetLeadsnput("phone_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryTelephoneIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                "GB 44"
              }
              flag_name="phone_code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
