import { combineReducers } from "redux";
import LeadsReducer, {
  PaginationReducer,
} from "../../modules/allAplication/mainLead/_redux/reducer/LeadsReducer";

import LogInReducer from "../../modules/authentication/_redux/reducer/LogInReducer";
import PriceQuoteReducer, {
  productReducer,
} from "../../modules/allAplication/mainLead/components/PriceQuote/_redux/reducer/PriceQuoteReducer";
import NewApplicationReducer from "../../modules/allAplication/mainLead/components/newApplication/_redux/reducer/NewApplicationReducer";
import UserReducer from "../../modules/userManagement/_redux/reducer/UserReducer";

// combine all of the reducers here
const rootReducer = combineReducers({
  logInInfo: LogInReducer,
  leadInfo: LeadsReducer,
  priceQuoteInfo: PriceQuoteReducer,
  userInfo: UserReducer,
  Pagination: PaginationReducer,
  Product: productReducer,

  applicationInfo: NewApplicationReducer,
});

export default rootReducer;
