import React from "react";
import { useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import Inprogress from "../../../home/InProgress/Inprogress";
import NewApplicationPage from "../../../home/NewApplication/NewApplicationPage";
// import Inprogress from "../InProgress/Inprogress";
// import NewApplication from "../NewApplication/NewApplication";
// import NewApplicationPage from "../NewApplication/NewApplicationPage";

const Home = () => {
  const history = useHistory();
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);
  return (
    <div>
      
      <div>
        <div className="breadcump">
        <p>
          {" "}
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            {" "}
            All Application /
          </span>
          <span style={{ cursor: "not" }}> Application Table</span>
        </p>
      </div>
      <div className="px-1 px-md-4 box-wrapper2">
        {/* style={{overflowX:'hidden'}} */}
        {/* <NewApplication/> */}
        {/* <NewApplicationPage /> */}
        <Inprogress isLoading={isLoading} setIsloading={setIsloading} />
      </div>
      </div>
      
    </div>
  );
};

export default Home;
