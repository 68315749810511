import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEcommerceProductInput, GetEposProductInput, GetLeadsnput, GetTerminalProductInput } from "../../_redux/action/LeadsAction";
import Button from "@mui/material/Button";

// import {
//   REMOVE_CARD_TERMINAL_PRODUCT,
//   REMOVE_ECOMMERCE_PRODUCT,
//   REMOVE_EPOS_PRODUCT,
//   REMOVE_LEAD_PRODUCT,
//   SET_LEAD_PRODUCT,
// } from "../../_redux/types/Types";
import CardTypeProduct from "./CardTypeProduct";
import EcommerceProduct from "./EcommerceProduct";
import EposProduct from "./EposProduct";
import { useEffect } from "react";
const ServiceDetails = () => {
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const [card, setCard] = useState(!leadInput.card_machine_service);
  const [ecom, setEcom] = useState(!leadInput.ecom_service);
  const [epos, setEpos] = useState(!leadInput.epos_service);
  const epos_name = (
    <p>
      Provide EPOS Name <span className="required">*</span>
    </p>
  );
  const website = (
    <p>
      Website URL <span className="required">*</span>
    </p>
  );
  const dispatch = useDispatch();
  // const leadInput = useSelector((state) => state.leadInfo.leadInput);

  const handleChangeInput = (name) => {
    if (name === "card_machine_service") {
      setCard(!card);
      dispatch(GetLeadsnput("card_machine_service", card));
      if (card === false) {
        for (let i = 0; i < leadInput?.terminal_products?.length; i++) {
          dispatch(
            GetTerminalProductInput("is_deleted", true, i, "terminal_products")
          );
        }
      }
    }
    if (name === "ecom_service") {
      setEcom(!ecom);
      dispatch(GetLeadsnput("ecom_service", ecom));
      if (ecom === false) {
        for (let i = 0; i < leadInput?.ecommerce_products?.length; i++) {
          dispatch(
            GetEcommerceProductInput(
              "is_deleted",
              true,
              i,
              "ecommerce_products"
            )
          );
        }
      }
    }
    if (name === "epos_service") {
      setEpos(!epos);
      dispatch(GetLeadsnput("epos_service", epos));
      if (epos === false) {
        for (let i = 0; i < leadInput?.epos_products?.length; i++) {
          dispatch(GetEposProductInput("is_deleted", true, i, "epos_products"));
        }
      }
    }

   
  };
 
  return (
    <div className="form-wrapper text-capitalize ">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Service Details</p>
      </div>
      <div className="row mt-4 site_visit">
        <div className="row mb-form">
          <div className="col-md-4 col-lg-3">
            <p>Product Type: </p>
          </div>
          <div className="col-md-8 col-lg-9">
            <div className="d-sm-flex gap-3">
              <Button
                onClick={() => handleChangeInput("card_machine_service")}
                size="small"
                // variant={
                //   leadInput.card_machine_service.includes("CARD_TERMINAL")
                //     ? "contained"
                //     : "outlined"
                // }
                variant={
                  leadInput.card_machine_service === true
                    ? "contained"
                    : "outlined"
                }
              >
                Card Terminal
              </Button>
              <Button
                onClick={() => handleChangeInput("ecom_service")}
                size="small"
                // variant={
                //   leadInput.ecom_service.includes(
                //     true
                //   )
                //     ? "contained"
                //     : "outlined"
                // }
                variant={
                  leadInput.ecom_service === true ? "contained" : "outlined"
                }
              >
                E-Commerce or Virtual terminal
              </Button>

              <Button
                onClick={() => handleChangeInput("epos_service")}
                size="small"
                // variant={
                //   leadInput.epos_service.includes("EPOS")
                //     ? "contained"
                //     : "outlined"
                // }
                variant={
                  leadInput.epos_service === true ? "contained" : "outlined"
                }
              >
                EPOS
              </Button>
            </div>
          </div>
        </div>
      
        {leadInput.card_machine_service === true && (
          <>
            <div className="mt-4 mb-4 product-card">
              <h4>Card Terminal</h4>
              <CardTypeProduct type_name="lead_id" id={leadInput.id}  lead_product={leadInput?.lead_products}/>
            </div>
          </>
        )}
        {leadInput.ecom_service === true && (
          <>
            <div className="mt-4 mb-4 product-card">
              <h4>E COMMERCE OR VIRTUAL TERMINAL</h4>
              <EcommerceProduct type_name="lead_id" id={leadInput.id}  lead_product={leadInput?.lead_products}/>
            </div>
          </>
        )}
        {leadInput.epos_service === true && (
          <>
            <div className="mt-4 mb-4 product-card">
              <h4>EPOS</h4>
              <EposProduct
                type_name="lead_id"
                id={leadInput.id}
                lead_product={leadInput?.lead_products}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceDetails;
