import React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  GetIndustryList,
  GetLeadsnput,
  GetSourceList,
  GetSalesPartnerList,
  SetLeadUpdatedData,
} from "../../_redux/action/LeadsAction";
import {
  LeadStatus,
  LegalType,
  LeadStage,
  leadType,
} from "../commons/Dropdown";
import Number from "./Number";

import {
  GetCompanyHouseDetails,
  GetCompanyHouseList,
  GetCompanyOfficersDetails,
} from "../newApplication/_redux/action/NewapplicationAction";
import moment from 'moment';
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Autocomplete from "@mui/material/Autocomplete";
import { GetUserList } from "../../../../userManagement/_redux/action/UserAction";
import { BASE_URL } from "../../../../../Const";

const Summary = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const leadSource = (
    <p>
      Lead Source <span className="required">*</span>
    </p>
  );
  const lead_status = (
    <p>
      Lead Status <span className="required">*</span>
    </p>
  );
  const call_date = (
    <p>
      Call Back Date <span className="required">*</span>
    </p>
  );
  const mobile_number = (
    <p>
      Mobile Number <span className="required">*</span>
    </p>
  );
  const salespartner = (
    <p>
      Sales Partner <span className="required">*</span>
    </p>
  );
  const Lead_Stage = (
    <p>
      Lead stage <span className="required">*</span>
    </p>
  );
  const MobileNumber = (
    <p>
      Mobile Number <span className="required">*</span>
    </p>
  );
  const Date_birth = (
    <p>
      Date Of Birth <span className="required">*</span>
    </p>
  );
  const lead_type = (
    <p>
      Lead Type <span className="required">*</span>
    </p>
  );
  const legal_type = (
    <p>
      Legal Type <span className="required">*</span>
    </p>
  );
  const FirstName = (
    <p>
      First Name <span className="required">*</span>
    </p>
  );
  const PrimaryEmail = (
    <p>
      Primary Email <span className="required">*</span>
    </p>
  );
  const LegalName = (
    <p>
      Legal Name<span className="required">*</span>
    </p>
  );
  const TradingName = (
    <p>
      Trading Name<span className="required">*</span>
    </p>
  );
  const LastName = (
    <p>
      Last Name<span className="required">*</span>
    </p>
  );
  const industry_type = (
    <p>
      Industry Type<span className="required">*</span>
    </p>
  );
  const company_house_no = (
    <p>
      Company House No<span className="required">*</span>
    </p>
  );
  const in_corporated_on = (
    <p>
      In Corporated On<span className="required">*</span>
    </p>
  );
  const ownershipScince = (
    <p>
      Current Ownership Since<span className="required">*</span>
    </p>
  );

  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const salesPartnerList = useSelector(
    (state) => state.leadInfo.salesPartnerList
  );
  const UserList = useSelector((state) => state.userInfo.UserList);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const companyHouseList = useSelector(
    (state) => state.applicationInfo.companyHouseList
  );
  const companyOfficerDetails = useSelector(
    (state) => state.applicationInfo.companyOfficerDetails
  );

  const handleChangeInput = (name, value, e) => {
    let newValue;

    // Check if the input value is an integer
    if (/^\d+$/.test(value)) {
      newValue = parseInt(value);
    }
    // Check if the input value is an email address
    else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      newValue = value;
    }
    // Otherwise, convert any text to uppercase
    else {
      newValue = value.toUpperCase();
    }

    dispatch(GetLeadsnput(name, newValue, e));

    if (value === "CH" && name === "legal_type") {
      dispatch(GetLeadsnput("company_house_no", ""));
      dispatch(GetLeadsnput("contact_full_name", ""));
      dispatch(GetLeadsnput("incorporated_on", ""));
    }
    if (value === "ST" && name === "legal_type") {
      dispatch(GetLeadsnput("company_house_no", ""));
      dispatch(GetLeadsnput("contact_full_name", ""));
      dispatch(GetLeadsnput("incorporated_on", ""));
    }
    if (
      leadInput.legal_type === "L" ||
      leadInput.legal_type === "PL" ||
      leadInput.legal_type === "LLP"
    ) {
      dispatch(GetLeadsnput("charity_number", ""));
    }
  };
  const handleChangeNumber = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));
  };

  const getCountryIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === leadInput.mobile_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getCountryTelephoneIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === leadInput.phone_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  useEffect(() => {
    if (leadInput.company_house_no) {
      dispatch(GetCompanyOfficersDetails(leadInput.company_house_no));
    }
  }, [leadInput.company_house_no]);
  useEffect(() => {
    if (leadInput.contact_full_name === null) {
      dispatch(GetLeadsnput("contact_full_name", ""));
    }
  }, []);
  useEffect(() => {
    if (leadInput.lead_stage !== 4) {
      dispatch(GetLeadsnput("appointment_date", ""));
    }
    if (leadInput.lead_status !== 2) {
      dispatch(GetLeadsnput("callback_date", ""));
    }
  }, [leadInput.lead_stage, leadInput.lead_status]);
  useEffect(() => {
    dispatch(GetIndustryList(1));
    // dispatch(GetSourceList(1));
    dispatch(GetSalesPartnerList(1));
    dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/?role=2`));
    dispatch(GetLeadsnput("active_lead", true));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
    // else if(typeof userData === "undefined") {
    //   history.push("/");
    // }
  }, []);

  const getIndustryIndex = () => {
    var index = -1;
    IndustryList.map((opt) => {
      if (opt.id === leadInput.industry_type) {
        index = IndustryList.indexOf(opt);
      }
    });
    return index;
  };
  //  ================validation-------------------
  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const handleCompanyOwner = (name, value, e) => {
    dispatch(GetLeadsnput("contact_full_name", value));
    if (name === "contact_full_name") {
      const nameParts = value?.split(", ");
      const firstNameParts = nameParts[1].trim().split(" ");
      const fullName = [firstNameParts[0], ...firstNameParts.slice(1)].join(
        " "
      );
      const lastName = nameParts[0];
      // ------------leads----------------------

      dispatch(GetLeadsnput("last_name", lastName));
      dispatch(GetLeadsnput("first_name", fullName));
    }
    // if (value) {
    //   const nameParts = value?.name.split(", ");
    //   const firstNameParts = nameParts[1].trim().split(" ");
    //   const fullName = [firstNameParts[0], ...firstNameParts.slice(1)].join(
    //     " "
    //   );
    //   const lastName = nameParts[0];
    //   // ------------leads----------------------
    //   handleChangeInput("contact_full_name", value.name);
    //   dispatch(GetLeadsnput("last_name", lastName));
    //   dispatch(GetLeadsnput("first_name", fullName));
    //   dispatch(GetLeadsnput("home_postcode", value.address.postal_code));
    //   dispatch(GetLeadsnput("home_address1", value.address.address_line_1));
    //   dispatch(GetLeadsnput("home_address2", value.address?.address_line_2));
    //   dispatch(GetLeadsnput("home_county", value.address?.region));
    //   dispatch(GetLeadsnput("home_city", value.address?.locality));
    // } else {
    //   // ------------leads----------------------
    //   handleChangeInput("contact_full_name", "");
    //   dispatch(GetLeadsnput("last_name", ""));
    //   dispatch(GetLeadsnput("first_name", ""));
    //   dispatch(GetLeadsnput("home_postcode", ""));
    //   dispatch(GetLeadsnput("home_address1", ""));
    //   dispatch(GetLeadsnput("home_address2", ""));
    //   dispatch(GetLeadsnput("home_county", ""));
    //   dispatch(GetLeadsnput("home_city", ""));
    // }
  };

  return (
    <div className="info-box text-capitalize">
      {isLoadLeads && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Summary</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            {!isLoadLeads &&
              sourceList &&
              sourceList !== null &&
              sourceList.length > 0 && (
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={leadSource}
                    variant="outlined"
                    name="lead_source"
                    value={leadInput.lead_source}
                    onChange={(e) => {
                      handleChangeInput("lead_source", e.target.value);
                    }}
                  >
                    {sourceList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}

            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={lead_status}
                name="lead_status"
                value={leadInput.lead_status}
                onChange={(e) =>
                  handleChangeInput("lead_status", e.target.value)
                }
                // helperText="Please select your currency"
              >
                {LeadStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {leadInput.lead_status == 2 && (
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label={call_date}
                  variant="outlined"
                  type="datetime-local"
                  // type="date"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  name="callback_date"
                  value={moment(leadInput.callback_date).format("YYYY-MM-DDTHH:mm")}
                  // value={leadInput.callback_date}
                  onChange={(e) =>
                    handleChangeInput("callback_date", e.target.value)
                  }
                />
              </div>
            )}
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={lead_type}
                name="lead_type"
                value={leadInput.lead_type}
                onChange={(e) => handleChangeInput("lead_type", e.target.value)}
              >
                {leadType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={legal_type}
                name="legal_type"
                value={leadInput.legal_type}
                onChange={(e) =>
                  handleChangeInput("legal_type", e.target.value)
                }
              >
                {LegalType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {leadInput.legal_type === "CH" && (
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Charity no"
                  variant="outlined"
                  size="small"
                  name="charity_number"
                  value={leadInput.charity_number}
                  onChange={(e) =>
                    handleChangeInput("charity_number", e.target.value)
                  }
                />
              </div>
            )}
            {leadInput.legal_type === "L" ||
            leadInput.legal_type === "LLP" ||
            leadInput.legal_type === "PL" ? (
              <>
                <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    error={true}
                    // error={leadInput?.legal_name?.includes("'") ? true : false}
                    options={companyHouseList}
                    autoHighlight
                    // value={leadInput.legal_name}
                    inputValue={leadInput.legal_name}
                    onChange={(event, newValue) => {
                      handleChangeInput(
                        "legal_name",
                        newValue === null ? "" : newValue.title
                      );
                      dispatch(GetCompanyHouseDetails(newValue.title));
                      dispatch(
                        GetCompanyOfficersDetails(newValue.company_number)
                      );
                    }}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.title}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          leadInput?.legal_name?.includes("'") ? true : false
                        }
                        label={LegalName}
                        onChange={(e) => {
                          handleChangeInput("legal_name", e.target.value);
                          dispatch(GetCompanyHouseDetails(e.target.value));
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </div>
                {/* {companyOfficerDetails?.length > 0 && */}
                {/* <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    error={true}
                    
                    options={companyOfficerDetails}
                    autoHighlight
                    inputValue={leadInput?.contact_full_name}
                    onChange={(event, newValue) => {
                      handleCompanyOwner(event, newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Contact Person"
                        onChange={(e) => {
                          handleChangeInput(
                            "contact_full_name",
                            e.target.value
                          );
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </div> */}
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="Contact full name"
                    name="contact_full_name"
                    value={leadInput.contact_full_name}
                    onChange={(e) =>
                      handleCompanyOwner("contact_full_name", e.target.value)
                    }
                  >
                    {companyOfficerDetails.map((option) => (
                      <MenuItem key={option.value} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            ) : (
              <TextField
                id="outlined-basic"
                label={LegalName}
                variant="outlined"
                size="small"
                name="legal_name"
                value={leadInput.legal_name}
                onChange={(e) => {
                  handleChangeInput("legal_name", e.target.value);
                  // dispatch(GetCompanyHouseList(e.target.value));
                }}
              />
            )}

            <div className="mb-form">
              {leadInput.legal_type === "L" ||
              leadInput.legal_type === "LLP" ? (
                <TextField
                  id="outlined-basic"
                  label={company_house_no}
                  variant="outlined"
                  size="small"
                  name="company_house_no"
                  value={leadInput.company_house_no}
                  onChange={(e) => {
                    handleChangeInput("company_house_no", e.target.value);
                    // dispatch(GetCompanyHouseList(e.target.value));
                  }}
                />
              ) : (
                leadInput.legal_type === "PL" && (
                  <TextField
                    id="outlined-basic"
                    label={company_house_no}
                    variant="outlined"
                    size="small"
                    name="company_house_no"
                    value={leadInput.company_house_no}
                    onChange={(e) => {
                      handleChangeInput("company_house_no", e.target.value);
                      dispatch(GetCompanyHouseList(e.target.value));
                    }}
                  />
                )
              )}
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={in_corporated_on}
                format="dd/MM/yyyy"
                variant="outlined"
                size="small"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="incorporated_on"
                value={leadInput.incorporated_on}
                // value={leadInput.company_house_no? companyHouseList?.date_of_creation : leadInput.incorporated_on}
                onChange={(e) =>
                  handleChangeInput("incorporated_on", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={ownershipScince}
                variant="outlined"
                size="small"
                type="date"
                format="dd/MM/yyyy"
                InputLabelProps={{ shrink: true }}
                name="current_ownership_since"
                value={leadInput.current_ownership_since}
                onChange={(e) =>
                  handleChangeInput("current_ownership_since", e.target.value)
                }
              />
            </div>
            {leadInput.legal_type !== "L" &&
              leadInput.legal_type !== "LLP" &&
              leadInput.legal_type !== "PL" && (
                <>
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label={FirstName}
                      variant="outlined"
                      size="small"
                      name="first_name"
                      value={leadInput.first_name}
                      onChange={(e) =>
                        handleChangeInput("first_name", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label={LastName}
                      variant="outlined"
                      size="small"
                      name="last_name"
                      value={leadInput.last_name}
                      onChange={(e) =>
                        handleChangeInput("last_name", e.target.value)
                      }
                    />
                  </div>
                </>
              )}

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  new Date().getFullYear() -
                    new Date(leadInput.dob).getFullYear() <
                    10 &&
                  leadInput.dob !== "" &&
                  true
                }
                label={Date_birth}
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="dob"
                value={leadInput.dob}
                onChange={(e) => handleChangeInput("dob", e.target.value)}
              />
            </div>
            {/* <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Call Back Date"
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="callback_date"
                value={leadInput.callback_date}
                onChange={(e) =>
                  handleChangeInput("callback_date", e.target.value)
                }
              />
            </div> */}

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                rows={2}
                multiline
                label="Lead Note"
                variant="outlined"
                size="small"
                name="note"
                value={leadInput.note}
                onChange={(e) => handleChangeInput("note", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={Lead_Stage}
                name="lead_stage"
                value={leadInput.lead_stage}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleChangeInput("lead_stage", e.target.value)
                }
              >
                {LeadStage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {leadInput.lead_stage === 4 && (
              <div className="mb-form">
                <TextField
                  type="datetime-local"
                  id="outlined-basic"
                  label="Appointment date"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  name="appointment_date"
                  value={moment(leadInput.appointment_date).format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) =>
                    handleChangeInput("appointment_date", e.target.value)
                  }
                />
              </div>
            )}
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={salespartner}
                variant="outlined"
                name="sales_partner"
                value={leadInput.sales_partner}
                onChange={(e) =>
                  handleChangeInput("sales_partner", e.target.value)
                }
              >
                {salesPartnerList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.first_name} {option?.last_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Partner Manager"
                variant="outlined"
                name="partner_manager"
                value={leadInput.partner_manager}
                onChange={(e) =>
                  handleChangeInput("partner_manager", e.target.value)
                }
              >
                {UserList?.results?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.first_name} {option?.last_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {!isLoadLeads &&
              IndustryList &&
              IndustryList !== null &&
              IndustryList.length > 0 && (
                <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    options={IndustryList}
                    autoHighlight
                    getOptionLabel={(option) => option.description}
                    value={IndustryList?.[getIndustryIndex()] || null}
                    onChange={(event, newValue) => {
                      IndustryList.map((item) => {
                        if (item.id === newValue.id) {
                          dispatch(GetLeadsnput("mcc_code", item.mcc_iso));
                        }
                      });
                      handleChangeInput("industry_type", newValue.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Industry"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              )}
            <div className="mb-form">
              <TextField
                // disabled
                size="small"
                id="outlined-select-currency"
                select
                label="MCC Code"
                name="mcc_code"
                value={leadInput?.industry_type}
                onChange={(e) => {
                  IndustryList?.map(
                    (code) =>
                      leadInput?.industry_type === code?.id &&
                      dispatch(GetLeadsnput("mcc_code", code?.mcc_iso))
                  );
                }}
                inputProps={{ readOnly: true }}
              >
                {IndustryList?.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.mcc_iso}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  !regex.test(leadInput.website) &&
                  leadInput.website !== "" &&
                  leadInput.website !== null
                    ? true
                    : false
                }
                // {!regex.test(data.website) && error }
                label="Website"
                variant="outlined"
                size="small"
                name="website"
                value={leadInput.website}
                onChange={(e) => handleChangeInput("website", e.target.value)}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={TradingName}
                error={leadInput?.trading_name?.includes("'") ? true : false}
                variant="outlined"
                size="small"
                name="trading_name"
                value={leadInput.trading_name}
                onChange={(e) =>
                  handleChangeInput("trading_name", e.target.value)
                }
              />
            </div>
            {/* <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={traddingAdd}
                variant="outlined"
                size="small"
                name="trading_address"
                value={leadInput.trading_address}
                onChange={(e) =>
                  handleChangeInput("trading_address", e.target.value)
                }
              />
            </div> */}

            <div className="mb-form">
              <TextField
                type="email"
                id="outlined-basic"
                error={
                  !regEmail.test(leadInput.email) &&
                  leadInput.email !== "" &&
                  true
                }
                label={PrimaryEmail}
                variant="outlined"
                size="small"
                name="email"
                value={leadInput.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}
              />
            </div>
            <div className="mb-form">
              <TextField
                error={
                  !regEmail.test(leadInput.secondary_email) &&
                  leadInput.secondary_email !== "" &&
                  leadInput.secondary_email !== null &&
                  true
                }
                id="outlined-basic"
                label="Secondary Email"
                variant="outlined"
                size="small"
                name="secondary_email"
                value={leadInput.secondary_email}
                onChange={(e) =>
                  handleChangeInput("secondary_email", e.target.value)
                }
              />
            </div>
            <Number
              text={mobile_number}
              placeholder={"Mobile Number"}
              colorBg={"white"}
              name="mobile"
              value={leadInput.mobile}
              onchangevalue={(e) =>
                handleChangeNumber("mobile", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetLeadsnput("mobile_code", code.id));
                });
              }}
              flag_value={
                // countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                countryList?.[getCountryIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryIndex()]?.phone_code || "GB 44"
              }
              flag_name="mobile_code"
            />
            <Number
              text={"Telephone Number"}
              placeholder={"Telephone Number"}
              colorBg={"white"}
              name="telephone"
              value={leadInput.telephone}
              onchangevalue={(e) =>
                handleChangeNumber("telephone", e.target.value)
              }
              onchangeFlag={(event, newValue) => {
                countryList.map((code) => {
                  code.phone_code === newValue.split(" ")[1] &&
                    code.iso2 === newValue.split(" ")[0] &&
                    dispatch(GetLeadsnput("phone_code", code.id));
                });
              }}
              flag_value={
                countryList?.[getCountryTelephoneIndex()]?.iso2 +
                  " " +
                  countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                "GB 44"
              }
              flag_name="phone_code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
