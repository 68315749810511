import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./Css/document.css";
import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GetApplicationInput,
  SetAddresstrue,
} from "./_redux/action/NewapplicationAction";
import { GetCountryList } from "../../_redux/action/LeadsAction";
import { useEffect, useState } from "react";
import { Country, address1, city } from "../commons/MendatoryName";

import Autocomplete from "@mui/material/Autocomplete";

import ApplicationPostcode from "./ApplicationPostcode";
const Address = () => {
  const [openHome, setOpenHome] = useState(false);
  const dispatch = useDispatch();
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const postCodeList = useSelector(
    (state) => state.priceQuoteInfo.postCodeList
  );
  const addressDetails = useSelector(
    (state) => state.priceQuoteInfo.addressDetails
  );

  // ------------------------api call----------------------
  // useEffect(() => {
  //   dispatch(GetCountryList(1));

  // }, []);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));
  };
  const isAddressChecked = useSelector(
    (state) => state.applicationInfo.isAddressChecked
  );
  const handleChange = (e) => {
    dispatch(SetAddresstrue(!isAddressChecked));
    handleaddrss(e);
  };

  const handleAutofil = (pCode, add1, add2, city, county, country) => {
    dispatch(GetApplicationInput("trading_postcode", pCode));
    dispatch(GetApplicationInput("trading_address1", add1));
    dispatch(GetApplicationInput("trading_address2", add2));
    dispatch(GetApplicationInput("trading_city", city));
    dispatch(GetApplicationInput("trading_county", county));
    dispatch(GetApplicationInput("trading_country", country));
  };
  const handleaddrss = (e) => {
    if (e.target.checked) {
      handleAutofil(
        applicationInput.legal_postcode,
        applicationInput.legal_address1,
        applicationInput.legal_address2,
        applicationInput.legal_city,
        applicationInput.legal_county,
        applicationInput.legal_country
      );
      dispatch(GetApplicationInput("site_and_trading_address_same", 1));
    } else {
      handleAutofil("", "", "", "", "", "");
      dispatch(GetApplicationInput("site_and_trading_address_same", 0));
    }
  };
  useEffect(() => {
    dispatch(GetCountryList(1));
  }, []);

  const countryName = () => {
    let country_id = null;
    countryList.map((item) => {
      if (item.name === addressDetails?.CountryName) {
        country_id = item.id;
      }
    });
    return country_id;
  };
  return (
    <div className="form-wrapper text-capitalize">
      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex heading mb-form">
              <svg
                width="22"
                height="29"
                viewBox="0 0 22 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0007 3.33464C13.934 3.33464 16.334 5.73464 16.334 8.66797C16.334 11.468 13.534 16.0013 11.0007 19.2013C8.46732 15.868 5.66732 11.468 5.66732 8.66797C5.66732 5.73464 8.06732 3.33464 11.0007 3.33464ZM11.0007 0.667969C6.60065 0.667969 3.00065 4.26797 3.00065 8.66797C3.00065 14.668 11.0007 23.3346 11.0007 23.3346C11.0007 23.3346 19.0007 14.5346 19.0007 8.66797C19.0007 4.26797 15.4007 0.667969 11.0007 0.667969ZM11.0007 6.0013C9.53398 6.0013 8.33398 7.2013 8.33398 8.66797C8.33398 10.1346 9.53398 11.3346 11.0007 11.3346C12.4673 11.3346 13.6673 10.1346 13.6673 8.66797C13.6673 7.2013 12.4673 6.0013 11.0007 6.0013ZM21.6673 23.3346C21.6673 26.268 16.8673 28.668 11.0007 28.668C5.13398 28.668 0.333984 26.268 0.333984 23.3346C0.333984 21.6013 1.93398 20.1346 4.46732 19.068L5.26732 20.268C3.93398 20.9346 3.00065 21.7346 3.00065 22.668C3.00065 24.5346 6.60065 26.0013 11.0007 26.0013C15.4007 26.0013 19.0007 24.5346 19.0007 22.668C19.0007 21.7346 18.0673 20.9346 16.6007 20.268L17.4007 19.068C20.0673 20.1346 21.6673 21.6013 21.6673 23.3346Z"
                  fill="black"
                />
              </svg>

              <p>Legal Address</p>
            </div>
            <div className="mb-form">
              <ApplicationPostcode
                name="legal_postcode"
                value={applicationInput.legal_postcode}
                details="application_legal_postcode"
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={address1}
                variant="outlined"
                helperText={"count :" + applicationInput.legal_address1?.length}
                size="small"
                name="legal_address1"
                value={applicationInput.legal_address1}
                onChange={(e) => {
                  handleChangeInput("legal_address1", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="address 2"
                variant="outlined"
                helperText={
                  applicationInput.legal_address2
                    ? "count :" + applicationInput.legal_address2?.length
                    : "count : 0"
                }
                size="small"
                name="legal_address2"
                value={applicationInput.legal_address2}
                onChange={(e) => {
                  handleChangeInput("legal_address2", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={city}
                variant="outlined"
                size="small"
                name="legal_city"
                value={applicationInput.legal_city}
                onChange={(e) => {
                  handleChangeInput("legal_city", e.target.value);
                }}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="County"
                variant="outlined"
                size="small"
                name="legal_county"
                value={applicationInput.legal_county}
                onChange={(e) => {
                  handleChangeInput("legal_county", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <Autocomplete
                id="country-select-demo"
                size="small"
                options={countryList}
                autoHighlight
                onChange={(event, newValue) =>
                  handleChangeInput(
                    "legal_country",
                    newValue === null ? "" : newValue.id
                  )
                }
                value={
                  countryList?.[applicationInput.legal_country - 1] || null
                }
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} ({option.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Country}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-6">
                <div className="d-flex heading mb-form">
                  <svg
                    width="22"
                    height="29"
                    viewBox="0 0 22 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0007 3.33464C13.934 3.33464 16.334 5.73464 16.334 8.66797C16.334 11.468 13.534 16.0013 11.0007 19.2013C8.46732 15.868 5.66732 11.468 5.66732 8.66797C5.66732 5.73464 8.06732 3.33464 11.0007 3.33464ZM11.0007 0.667969C6.60065 0.667969 3.00065 4.26797 3.00065 8.66797C3.00065 14.668 11.0007 23.3346 11.0007 23.3346C11.0007 23.3346 19.0007 14.5346 19.0007 8.66797C19.0007 4.26797 15.4007 0.667969 11.0007 0.667969ZM11.0007 6.0013C9.53398 6.0013 8.33398 7.2013 8.33398 8.66797C8.33398 10.1346 9.53398 11.3346 11.0007 11.3346C12.4673 11.3346 13.6673 10.1346 13.6673 8.66797C13.6673 7.2013 12.4673 6.0013 11.0007 6.0013ZM21.6673 23.3346C21.6673 26.268 16.8673 28.668 11.0007 28.668C5.13398 28.668 0.333984 26.268 0.333984 23.3346C0.333984 21.6013 1.93398 20.1346 4.46732 19.068L5.26732 20.268C3.93398 20.9346 3.00065 21.7346 3.00065 22.668C3.00065 24.5346 6.60065 26.0013 11.0007 26.0013C15.4007 26.0013 19.0007 24.5346 19.0007 22.668C19.0007 21.7346 18.0673 20.9346 16.6007 20.268L17.4007 19.068C20.0673 20.1346 21.6673 21.6013 21.6673 23.3346Z"
                      fill="black"
                    />
                  </svg>

                  <p>Trading Address</p>
                </div>
              </div>
              <div className="col-6">
                <Form.Group
                  className="checkBox"
                  controlId="formBasicCheckbox"
                  style={{ marginTop: "8px" }}
                >
                  <Form.Check
                    type="checkbox"
                    label="Same as Legal Address"
                    checked={
                      applicationInput.site_and_trading_address_same === 1
                        ? true
                        : false
                    }
                    //  checked={isAddressChecked}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="mb-form">
              <ApplicationPostcode
                name="trading_postcode"
                value={applicationInput.trading_postcode}
                details="application_trading_postcode"
              />
              {/* <Autocomplete
                id="country-select-demo"
                size="small"
                error={true}
                options={postCodeList}
                autoHighlight
                inputValue={applicationInput.trading_postcode}
                onChange={(event, newValue) => {
                
                  handleChangeInput("trading_postcode", newValue.Text);
                  if (newValue.Type === "Address") {
                    dispatch(GetPostcodeDetails(newValue.Id,"application_trading_postcode"))
                  } else if (newValue.Type === "Postcode") {
                    dispatch(
                      GetPostCodeList2(
                        newValue.Id,
                        newValue.Text,
                        newValue.Description
                      )
                    );
                  }

                  // dispatch(GetPostcodeDetails(newValue.Id));
                }}
                getOptionLabel={(option) => option.Text}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                   {option.Text} {" "} {option.Description}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      applicationInput?.trading_postcode?.includes("'") ? true : false
                    }
                    label="Postcode"
                    onChange={(e) => {
                      console.log("e", e);
                      handleChangeInput("trading_postcode", e.target.value);
                      dispatch(GetPostCodeList(e.target.value));
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              /> */}
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={address1}
                variant="outlined"
                size="small"
                helperText={
                  "count :" + applicationInput.trading_address1?.length
                }
                name="trading_address1"
                value={applicationInput.trading_address1}
                onChange={(e) => {
                  handleChangeInput("trading_address1", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="address 2"
                helperText={
                  applicationInput.trading_address2
                    ? "count :" + applicationInput.trading_address2?.length
                    : "count : 0"
                }
                variant="outlined"
                size="small"
                name="trading_address2"
                value={applicationInput.trading_address2}
                onChange={(e) => {
                  handleChangeInput("trading_address2", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={city}
                variant="outlined"
                size="small"
                name="trading_city"
                value={applicationInput.trading_city}
                onChange={(e) => {
                  handleChangeInput("trading_city", e.target.value);
                }}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="County"
                variant="outlined"
                size="small"
                name="trading_county"
                value={applicationInput.trading_county}
                onChange={(e) => {
                  handleChangeInput("trading_county", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <Autocomplete
                id="country-select-demo"
                size="small"
                options={countryList}
                onChange={(event, newValue) =>
                  handleChangeInput(
                    "trading_country",
                    newValue === null ? "" : newValue.id
                  )
                }
                value={
                  countryList?.[applicationInput.trading_country - 1] || null
                }
                // value={
                //   addressDetails
                //     ? countryList?.[countryName()-1] || null
                //     : countryList?.[applicationInput.trading_country - 1] || null
                // }

                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} ({option.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Country}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    name="trading_country"
                  />
                )}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div className="d-flex heading mb-form">
                  <svg
                    width="22"
                    height="29"
                    viewBox="0 0 22 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0007 3.33464C13.934 3.33464 16.334 5.73464 16.334 8.66797C16.334 11.468 13.534 16.0013 11.0007 19.2013C8.46732 15.868 5.66732 11.468 5.66732 8.66797C5.66732 5.73464 8.06732 3.33464 11.0007 3.33464ZM11.0007 0.667969C6.60065 0.667969 3.00065 4.26797 3.00065 8.66797C3.00065 14.668 11.0007 23.3346 11.0007 23.3346C11.0007 23.3346 19.0007 14.5346 19.0007 8.66797C19.0007 4.26797 15.4007 0.667969 11.0007 0.667969ZM11.0007 6.0013C9.53398 6.0013 8.33398 7.2013 8.33398 8.66797C8.33398 10.1346 9.53398 11.3346 11.0007 11.3346C12.4673 11.3346 13.6673 10.1346 13.6673 8.66797C13.6673 7.2013 12.4673 6.0013 11.0007 6.0013ZM21.6673 23.3346C21.6673 26.268 16.8673 28.668 11.0007 28.668C5.13398 28.668 0.333984 26.268 0.333984 23.3346C0.333984 21.6013 1.93398 20.1346 4.46732 19.068L5.26732 20.268C3.93398 20.9346 3.00065 21.7346 3.00065 22.668C3.00065 24.5346 6.60065 26.0013 11.0007 26.0013C15.4007 26.0013 19.0007 24.5346 19.0007 22.668C19.0007 21.7346 18.0673 20.9346 16.6007 20.268L17.4007 19.068C20.0673 20.1346 21.6673 21.6013 21.6673 23.3346Z"
                      fill="black"
                    />
                  </svg>

                  <p>Home Address</p>
                </div>
              </div>
            </div>
            <div className="mb-form">
              <ApplicationPostcode
                name="home_postcode"
                value={applicationInput.home_postcode}
                details="application_home_postcode"
              />
              {/* <Autocomplete
                id="country-select-demo"
                size="small"
                error={true}
                open={openHome}
                options={postCodeList}
                autoHighlight
                inputValue={applicationInput.home_postcode}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  handleChangeInput("home_postcode", newValue.Text);
                  if (newValue.Type === "Address") {
                    dispatch(GetPostcodeDetails(newValue.Id, "application_home_postcode"))
                    setOpenHome(false)
                  } else if (newValue.Type === "Postcode") {
                    setOpenHome(true)
                    dispatch(GetApplicationInput("home_postcode", ""))
                    dispatch(
                      GetPostCodeList2(
                        newValue.Id,
                        newValue.Text,
                        newValue.Description
                      )
                    );
                  }

                 
                }}
                getOptionLabel={(option) => option.Text}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                  {option.Text} {" "} {option.Description}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      applicationInput?.home_postcode?.includes("'") ? true : false
                    }
                    label="Postcode"
                    onChange={(e) => {
                      setOpenHome(e.target.value !== "");
                      handleChangeInput("home_postcode", e.target.value);
                      dispatch(GetPostCodeList(e.target.value));
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              /> */}
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={address1}
                variant="outlined"
                helperText={"count :" + applicationInput.home_address1?.length}
                size="small"
                name="home_address1"
                value={applicationInput.home_address1}
                onChange={(e) => {
                  handleChangeInput("home_address1", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="address 2"
                helperText={
                  applicationInput.home_address2
                    ? "count :" + applicationInput.home_address2?.length
                    : "count : 0"
                }
                variant="outlined"
                size="small"
                name="home_address2"
                value={applicationInput.home_address2}
                onChange={(e) => {
                  handleChangeInput("home_address2", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={city}
                variant="outlined"
                size="small"
                name="home_city"
                value={applicationInput.home_city}
                onChange={(e) => {
                  handleChangeInput("home_city", e.target.value);
                }}
              />
            </div>

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="County"
                variant="outlined"
                size="small"
                name="home_county"
                value={applicationInput.home_county}
                onChange={(e) => {
                  handleChangeInput("home_county", e.target.value);
                }}
              />
            </div>
            <div className="mb-form">
              <Autocomplete
                id="country-select-demo"
                size="small"
                options={countryList}
                onChange={(event, newValue) =>
                  handleChangeInput(
                    "home_country",
                    newValue === null ? "" : newValue.id
                  )
                }
                value={countryList?.[applicationInput.home_country - 1] || null}
                // value={
                //   addressDetails
                //     ? countryList?.[countryName()-1] || null
                //     : countryList?.[applicationInput.home_country - 1] || null
                // }
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} ({option.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Country}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    name="trading_country"
                    // value={countryList?.[applicationInput.trading_country] || null}
                  />
                )}
              />
            </div>
          </div>
          {/* <CheckAdd/> */}
        </div>
      </div>
    </div>
  );
};

export default Address;
