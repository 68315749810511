import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetLeadsnput } from "../../_redux/action/LeadsAction";

const BusinessInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetLeadsnput(name, value, e));
    // dispatch(GetLeadsnput("mcc_code", value));
    
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
    // else if(typeof userData === "undefined") {
    //   history.push("/");
    // }
  }, []);

  const LegalName = (
    <p>
      Legal Name<span className="required">*</span>
    </p>
  );
  const TradingName = (
    <p>
      Trading Name<span className="required">*</span>
    </p>
  );
  const legal_type = (
    <p>
      Legal Type <span className="required">*</span>
    </p>
  );
  const industry_type = (
    <p>
      Industry Type<span className="required">*</span>
    </p>
  );
  const LegalType = [
    {
      value: "ST",
      label: "Sole Trader",
    },
    {
      value: "CL",
      label: "Club",
    },
    {
      value: 2,
      label: "Call back arranged",
    },
    {
      value: "CH",
      label: "Charity",
    },
    {
      value: "TR",
      label: "Trust",
    },
    {
      value: "PART",
      label: "Partnership",
    },
    {
      value: "LLP",
      label: "Limited Liability Partnership",
    },
    {
      value: "L",
      label: "Private Limited Company",
    },
    {
      value: "PL",
      label: "Public Limited Company",
    },
    {
      value: "OT",
      label: "Other",
    },
  ];
  var expression =
  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
var regex = new RegExp(expression);

  return (
    <div className="form-wrapper text-capitalize">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Business Information</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label={legal_type}
                name="legal_type"
                value={leadInput.legal_type}
                onChange={(e) =>
                  handleChangeInput("legal_type", e.target.value)
                }
              >
                {LegalType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {leadInput.legal_type === "CH" && (
              <div className="mb-form">
                <TextField
                  id="outlined-basic"
                  label="Charity no"
                  variant="outlined"
                  size="small"
                  name="charity_number"
                  value={leadInput.charity_number}
                  onChange={(e) =>
                    handleChangeInput("charity_number", e.target.value)
                  }
                />
              </div>
            )}

            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={TradingName}
                variant="outlined"
                size="small"
                name="trading_name"
                value={leadInput.trading_name}
                onChange={(e) =>
                  handleChangeInput("trading_name", e.target.value)
                }
              />
            </div>
            <div className="mb-form">
              {leadInput.legal_type === "L" ||
              leadInput.legal_type === "LLP" ? (
                <TextField
                  id="outlined-basic"
                  label="Company House No"
                  variant="outlined"
                  size="small"
                  name="company_house_no"
                  value={leadInput.company_house_no}
                  onChange={(e) =>
                    handleChangeInput("company_house_no", e.target.value)
                  }
                />
              ) : (
                leadInput.legal_type === "PL" && (
                  <TextField
                    id="outlined-basic"
                    label="Company House No"
                    variant="outlined"
                    size="small"
                    name="company_house_no"
                    value={leadInput.company_house_no}
                    onChange={(e) =>
                      handleChangeInput("company_house_no", e.target.value)
                    }
                  />
                )
              )}
            </div>
            {!isLoadLeads &&
              IndustryList &&
              IndustryList !== null &&
              IndustryList.length > 0 && (
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={industry_type}
                    name="industry_type"
                    value={leadInput.industry_type}
                    onChange={(e) => {
                      IndustryList.map((item) => {
                        if (item.id === e.target.value) {
                          dispatch(GetLeadsnput("mcc_code", item.mcc_iso));
                        }
                      });

                      handleChangeInput("industry_type", e.target.value);
                    }}
                  >
                    {IndustryList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
          </div>
          <div className="col-md-6">
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                label={LegalName}
                variant="outlined"
                size="small"
                name="legal_name"
                value={leadInput.legal_name}
                onChange={(e) =>
                  handleChangeInput("legal_name", e.target.value)
                }
              />
            </div>
            {/* <div className="mb-form">
              <TextField
                id="outlined-basic"
                label="Trading Address"
                variant="outlined"
                size="small"
                name="trading_address"
                value={leadInput.trading_address}
                onChange={(e) => handleChangeInput("trading_address", e.target.value)}
              />
            </div> */}
            <div className="mb-form">
              {!isLoadLeads &&
                IndustryList &&
                IndustryList !== null &&
                IndustryList.length > 0 && (
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label="MCC Code"
                    name="mcc_code"
                    value={leadInput.industry_type}
                    onChange={(e) =>
                      handleChangeInput("mcc_code", e.target.value)
                    }
                    inputProps={{ readOnly: true }}
                  >
                    {IndustryList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.mcc_iso}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
            </div>
            <div className="mb-form">
              <TextField
                id="outlined-basic"
                error={
                  !regex.test(leadInput.website) &&
                  leadInput.website !== "" &&  leadInput.website !== null &&
                  true
                }
                label="Website"
                variant="outlined"
                size="small"
                name="website"
                value={leadInput.website}
                onChange={(e) => handleChangeInput("website", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
