import React from "react";

import Table from "react-bootstrap/Table";

import { useDispatch, useSelector } from "react-redux";

import { GetPricequoteInput } from "../../_redux/action/LeadsAction";
import ScheduleFeeTable from "./ScheduleFeeTable";

import QuoteProducts from "./QuoteProducts";
import QuoteProductDetails from "./QuoteProductDetails";

const NewSchedule = () => {
  const dispatch = useDispatch();

  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  const handleChangeInput = (name, value, e) => {
    dispatch(GetPricequoteInput(name, value, e));
    if (name === "visa_credit_sr") {
      
      dispatch(
        GetPricequoteInput(
          "visa_credit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_credit_sr") {
      dispatch(
        GetPricequoteInput(
          "master_credit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_debit_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_debit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_debit_sr") {
      dispatch(
        GetPricequoteInput(
          "master_debit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_v_pay_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_v_pay_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "uk_maestro_sr") {
      dispatch(
        GetPricequoteInput(
          "uk_maestro_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "international_maestro_sr") {
      dispatch(
        GetPricequoteInput(
          "international_maestro_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_business_credit_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_business_credit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_business_debit_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_business_debit_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_purchasing_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_purchasing_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "visa_corporate_sr") {
      dispatch(
        GetPricequoteInput(
          "visa_corporate_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_business_sr") {
      dispatch(
        GetPricequoteInput(
          "master_business_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_purchasing_sr") {
      dispatch(
        GetPricequoteInput(
          "master_purchasing_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_fleet_sr") {
      dispatch(
        GetPricequoteInput(
          "master_fleet_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_corporate_sr") {
      dispatch(
        GetPricequoteInput(
          "master_corporate_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "master_pre_commercial_sr") {
      dispatch(
        GetPricequoteInput(
          "master_pre_commercial_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "non_eea_visa_sr") {
      dispatch(
        GetPricequoteInput(
          "non_eea_visa_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    } else if (name === "non_eea_master_sr") {
      dispatch(
        GetPricequoteInput(
          "non_eea_master_non_sr",
          (parseFloat(value) + 0.5).toFixed(3)
        )
      );
    }
  };
 
  return (
    <div className="info-box text-capitalize">
      <div className="d-flex heading">
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
            fill="#0D0D0D"
          />
        </svg>

        <p>Schedule Of Fees</p>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-12 table-responsive mb-4 ">
            <Table striped className="table-body table-card quote-table">
              <thead className="table-head">
                <tr>
                  <th style={{ position: "static", color: "#333333" }}>
                    Card Type
                  </th>
                  <th>Secure Rate(%) + (£) Per Transaction</th>
                  <th>Non-Secure Rate (%) + (£) Per Transaction </th>
                  {/* <th>Total Sale </th>
                  <th>Number of Transactions</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Visa Credit</td>
                  <td>
                    <input
                       className={` ${ priceQuoteInput.visa_credit_sr.includes(".") &&
                       priceQuoteInput.visa_credit_sr.split(".")[1].length > 3 && priceQuoteInput.visa_credit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      name="visa_credit_sr"
                      value={priceQuoteInput.visa_credit_sr}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_credit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_credit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_credit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_credit_sr_per_tr_fee"
                      value={priceQuoteInput.visa_credit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_credit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_credit_non_sr.includes(".") &&
                       priceQuoteInput.visa_credit_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_credit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_credit_non_sr"
                      value={priceQuoteInput.visa_credit_non_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_credit_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_credit_per_non_sr_tr_fee.includes(".") &&
                       priceQuoteInput.visa_credit_per_non_sr_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_credit_per_non_sr_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_credit_per_non_sr_tr_fee"
                      value={priceQuoteInput.visa_credit_per_non_sr_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_credit_per_non_sr_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Credit</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_credit_sr.includes(".") &&
                       priceQuoteInput.master_credit_sr.split(".")[1].length > 3 && priceQuoteInput.master_credit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_credit_sr"
                      value={priceQuoteInput.master_credit_sr}
                      onChange={(e) =>
                        handleChangeInput("master_credit_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_credit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_credit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_credit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_credit_sr_per_tr_fee"
                      value={priceQuoteInput.master_credit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_credit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_credit_non_sr.includes(".") &&
                       priceQuoteInput.master_credit_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_credit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_credit_non_sr"
                      value={priceQuoteInput.master_credit_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_credit_non_sr",
                          e.target.value
                        )
                      }
                    />

                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_credit_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_credit_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_credit_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_credit_non_sr_per_tr_fee"
                      value={priceQuoteInput.master_credit_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_credit_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa Debit</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_debit_sr.includes(".") &&
                       priceQuoteInput.visa_debit_sr.split(".")[1].length > 3 && priceQuoteInput.visa_debit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_debit_sr"
                      value={priceQuoteInput.visa_debit_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_debit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_debit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_debit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_debit_sr_per_tr_fee"
                      value={priceQuoteInput.visa_debit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_debit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_debit_non_sr.includes(".") &&
                       priceQuoteInput.visa_debit_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_debit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_debit_non_sr"
                      value={priceQuoteInput.visa_debit_non_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_debit_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_debit_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_debit_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_debit_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_debit_non_sr_per_tr_fee"
                      value={priceQuoteInput.visa_debit_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_debit_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Debit</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_debit_sr.includes(".") &&
                       priceQuoteInput.master_debit_sr.split(".")[1].length > 3 && priceQuoteInput.master_debit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_debit_sr"
                      value={priceQuoteInput.master_debit_sr}
                      onChange={(e) =>
                        handleChangeInput("master_debit_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_debit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_debit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_debit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_debit_sr_per_tr_fee"
                      value={priceQuoteInput.master_debit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_debit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_debit_non_sr.includes(".") &&
                       priceQuoteInput.master_debit_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_debit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_debit_non_sr"
                      value={priceQuoteInput.master_debit_non_sr}
                      onChange={(e) =>
                        handleChangeInput("master_debit_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_debit_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_debit_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_debit_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_debit_non_sr_per_tr_fee"
                      value={priceQuoteInput.master_debit_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_debit_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa V-Pay</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_v_pay_sr.includes(".") &&
                       priceQuoteInput.visa_v_pay_sr.split(".")[1].length > 3 && priceQuoteInput.visa_v_pay_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_v_pay_sr"
                      value={priceQuoteInput.visa_v_pay_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_v_pay_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_v_pay_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_v_pay_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_v_pay_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_v_pay_sr_per_tr_fee"
                      value={priceQuoteInput.visa_v_pay_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_v_pay_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_v_pay_non_sr.includes(".") &&
                       priceQuoteInput.visa_v_pay_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_v_pay_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_v_pay_non_sr"
                      value={priceQuoteInput.visa_v_pay_non_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_v_pay_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_v_pay_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_v_pay_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_v_pay_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_v_pay_non_sr_per_tr_fee"
                      value={priceQuoteInput.visa_v_pay_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_v_pay_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>UK Maestro</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.uk_maestro_sr.includes(".") &&
                       priceQuoteInput.uk_maestro_sr.split(".")[1].length > 3 && priceQuoteInput.uk_maestro_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="uk_maestro_sr"
                      value={priceQuoteInput.uk_maestro_sr}
                      onChange={(e) =>
                        handleChangeInput("uk_maestro_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.uk_maestro_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.uk_maestro_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.uk_maestro_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="uk_maestro_sr_per_tr_fee"
                      value={priceQuoteInput.uk_maestro_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "uk_maestro_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.uk_maestro_non_sr.includes(".") &&
                       priceQuoteInput.uk_maestro_non_sr.split(".")[1].length > 3 && priceQuoteInput.uk_maestro_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="uk_maestro_non_sr"
                      value={priceQuoteInput.uk_maestro_non_sr}
                      onChange={(e) =>
                        handleChangeInput("uk_maestro_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.uk_maestro_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.uk_maestro_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.uk_maestro_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="uk_maestro_non_sr_per_tr_fee"
                      value={priceQuoteInput.uk_maestro_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "uk_maestro_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>International Maestro</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.international_maestro_sr.includes(".") &&
                       priceQuoteInput.international_maestro_sr.split(".")[1].length > 3 && priceQuoteInput.international_maestro_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="international_maestro_sr"
                      value={priceQuoteInput.international_maestro_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "international_maestro_sr",
                          e.target.value
                        )
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.international_maestro_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.international_maestro_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.international_maestro_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="international_maestro_sr_per_tr_fee"
                      value={
                        priceQuoteInput.international_maestro_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "international_maestro_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.international_maestro_non_sr.includes(".") &&
                       priceQuoteInput.international_maestro_non_sr.split(".")[1].length > 3 && priceQuoteInput.international_maestro_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="international_maestro_non_sr"
                      value={priceQuoteInput.international_maestro_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "international_maestro_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.international_maestro_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.international_maestro_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.international_maestro_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="international_maestro_non_sr_per_tr_fee"
                      value={
                        priceQuoteInput.international_maestro_non_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "international_maestro_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa Business Credit</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_business_credit_sr.includes(".") &&
                       priceQuoteInput.visa_business_credit_sr.split(".")[1].length > 3 && priceQuoteInput.visa_business_credit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_credit_sr"
                      value={priceQuoteInput.visa_business_credit_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_sr",
                          e.target.value
                        )
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_business_credit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_business_credit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_business_credit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_credit_sr_per_tr_fee"
                      value={priceQuoteInput.visa_business_credit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_business_credit_non_sr.includes(".") &&
                       priceQuoteInput.visa_business_credit_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_business_credit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_credit_non_sr"
                      value={priceQuoteInput.visa_business_credit_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_business_credit_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_business_credit_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_business_credit_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_credit_non_sr_per_tr_fee"
                      value={
                        priceQuoteInput.visa_business_credit_non_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_credit_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa Business Debit</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_business_debit_sr.includes(".") &&
                       priceQuoteInput.visa_business_debit_sr.split(".")[1].length > 3 && priceQuoteInput.visa_business_debit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_debit_sr"
                      value={priceQuoteInput.visa_business_debit_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_sr",
                          e.target.value
                        )
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_business_debit_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_business_debit_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_business_debit_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_debit_sr_per_tr_fee"
                      value={priceQuoteInput.visa_business_debit_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>

                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_business_debit_non_sr.includes(".") &&
                       priceQuoteInput.visa_business_debit_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_business_debit_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_debit_non_sr"
                      value={priceQuoteInput.visa_business_debit_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_business_debit_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_business_debit_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_business_debit_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_business_debit_non_sr_per_tr_fee"
                      value={
                        priceQuoteInput.visa_business_debit_non_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_business_debit_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa Purchasing</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_purchasing_sr.includes(".") &&
                       priceQuoteInput.visa_purchasing_sr.split(".")[1].length > 3 && priceQuoteInput.visa_purchasing_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_purchasing_sr"
                      value={priceQuoteInput.visa_purchasing_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_purchasing_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_purchasing_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_purchasing_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_purchasing_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_purchasing_sr_per_tr_fee"
                      value={priceQuoteInput.visa_purchasing_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_purchasing_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_purchasing_non_sr.includes(".") &&
                       priceQuoteInput.visa_purchasing_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_purchasing_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_purchasing_non_sr"
                      value={priceQuoteInput.visa_purchasing_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_purchasing_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_purchasing_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_purchasing_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_purchasing_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_purchasing_non_sr_per_tr_fee"
                      value={priceQuoteInput.visa_purchasing_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_purchasing_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Visa Corporate</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_corporate_sr.includes(".") &&
                       priceQuoteInput.visa_corporate_sr.split(".")[1].length > 3 && priceQuoteInput.visa_corporate_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_corporate_sr"
                      value={priceQuoteInput.visa_corporate_sr}
                      onChange={(e) =>
                        handleChangeInput("visa_corporate_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_corporate_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_corporate_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_corporate_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_corporate_sr_per_tr_fee"
                      value={priceQuoteInput.visa_corporate_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_corporate_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_corporate_non_sr.includes(".") &&
                       priceQuoteInput.visa_corporate_non_sr.split(".")[1].length > 3 && priceQuoteInput.visa_corporate_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_corporate_non_sr"
                      value={priceQuoteInput.visa_corporate_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_corporate_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.visa_corporat_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.visa_corporat_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.visa_corporat_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="visa_corporat_non_sr_per_tr_fee"
                      value={priceQuoteInput.visa_corporat_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "visa_corporat_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Business</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.visa_credit_sr.includes(".") &&
                       priceQuoteInput.visa_credit_sr.split(".")[1].length > 3 && priceQuoteInput.visa_credit_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_business_sr"
                      value={priceQuoteInput.master_business_sr}
                      onChange={(e) =>
                        handleChangeInput("master_business_sr", e.target.value)
                      }
                    />{" "}
                    <input
                     className={` ${ priceQuoteInput.master_business_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_business_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_business_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_business_sr_per_tr_fee"
                      value={priceQuoteInput.master_business_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_business_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_business_non_sr.includes(".") &&
                       priceQuoteInput.master_business_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_business_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_business_non_sr"
                      value={priceQuoteInput.master_business_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_business_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_business_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_business_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_business_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_business_non_sr_per_tr_fee"
                      value={priceQuoteInput.master_business_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_business_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Purchasing</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_purchasing_sr.includes(".") &&
                       priceQuoteInput.master_purchasing_sr.split(".")[1].length > 3 && priceQuoteInput.master_purchasing_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_purchasing_sr"
                      value={priceQuoteInput.master_purchasing_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_purchasing_sr",
                          e.target.value
                        )
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_purchasing_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_purchasing_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_purchasing_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_purchasing_sr_per_tr_fee"
                      value={priceQuoteInput.master_purchasing_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_purchasing_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_purchasing_non_sr.includes(".") &&
                       priceQuoteInput.master_purchasing_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_purchasing_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_purchasing_non_sr"
                      value={priceQuoteInput.master_purchasing_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_purchasing_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_purchasing_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_purchasing_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_purchasing_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_purchasing_non_sr_per_tr_fee"
                      value={
                        priceQuoteInput.master_purchasing_non_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "master_purchasing_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Fleet</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_fleet_sr.includes(".") &&
                       priceQuoteInput.master_fleet_sr.split(".")[1].length > 3 && priceQuoteInput.master_fleet_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_fleet_sr"
                      value={priceQuoteInput.master_fleet_sr}
                      onChange={(e) =>
                        handleChangeInput("master_fleet_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_fleet_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_fleet_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_fleet_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_fleet_sr_per_tr_fee"
                      value={priceQuoteInput.master_fleet_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_fleet_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_fleet_non_sr.includes(".") &&
                       priceQuoteInput.master_fleet_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_fleet_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_fleet_non_sr"
                      value={priceQuoteInput.master_fleet_non_sr}
                      onChange={(e) =>
                        handleChangeInput("master_fleet_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_fleet_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_fleet_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_fleet_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_fleet_non_sr_per_tr_fee"
                      value={priceQuoteInput.master_fleet_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_fleet_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Corporate</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_corporate_sr.includes(".") &&
                       priceQuoteInput.master_corporate_sr.split(".")[1].length > 3 && priceQuoteInput.master_corporate_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_corporate_sr"
                      value={priceQuoteInput.master_corporate_sr}
                      onChange={(e) =>
                        handleChangeInput("master_corporate_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_corporate_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_corporate_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_corporate_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_corporate_sr_per_tr_fee"
                      value={priceQuoteInput.master_corporate_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_corporate_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_corporate_non_sr.includes(".") &&
                       priceQuoteInput.master_corporate_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_corporate_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_corporate_non_sr"
                      value={priceQuoteInput.master_corporate_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_corporate_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_corporate_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_corporate_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_corporate_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_corporate_non_sr_per_tr_fee"
                      value={priceQuoteInput.master_corporate_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_corporate_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mastercard Prepaid Commerciale</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_pre_commercial_sr.includes(".") &&
                       priceQuoteInput.master_pre_commercial_sr.split(".")[1].length > 3 && priceQuoteInput.master_pre_commercial_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_pre_commercial_sr"
                      value={priceQuoteInput.master_pre_commercial_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_pre_commercial_sr",
                          e.target.value
                        )
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_pre_commercial_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_pre_commercial_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_pre_commercial_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_pre_commercial_sr_per_tr_fee"
                      value={
                        priceQuoteInput.master_pre_commercial_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "master_pre_commercial_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.master_pre_commercial_non_sr.includes(".") &&
                       priceQuoteInput.master_pre_commercial_non_sr.split(".")[1].length > 3 && priceQuoteInput.master_pre_commercial_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_pre_commercial_non_sr"
                      value={priceQuoteInput.master_pre_commercial_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "master_pre_commercial_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.master_pre_commercial_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.master_pre_commercial_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.master_pre_commercial_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="master_pre_commercial_non_sr_per_tr_fee"
                      value={
                        priceQuoteInput.master_pre_commercial_non_sr_per_tr_fee
                      }
                      onChange={(e) =>
                        handleChangeInput(
                          "master_pre_commercial_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>All Non-EEA VISA</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.non_eea_visa_sr.includes(".") &&
                       priceQuoteInput.non_eea_visa_sr.split(".")[1].length > 3 && priceQuoteInput.non_eea_visa_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_visa_sr"
                      value={priceQuoteInput.non_eea_visa_sr}
                      onChange={(e) =>
                        handleChangeInput("non_eea_visa_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.non_eea_visa_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.non_eea_visa_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.non_eea_visa_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_visa_sr_per_tr_fee"
                      value={priceQuoteInput.non_eea_visa_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "non_eea_visa_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.non_eea_visa_non_sr.includes(".") &&
                       priceQuoteInput.non_eea_visa_non_sr.split(".")[1].length > 3 && priceQuoteInput.non_eea_visa_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_visa_non_sr"
                      value={priceQuoteInput.non_eea_visa_non_sr}
                      onChange={(e) =>
                        handleChangeInput("non_eea_visa_non_sr", e.target.value)
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.non_eea_visa_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.non_eea_visa_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.non_eea_visa_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_visa_non_sr_per_tr_fee"
                      value={priceQuoteInput.non_eea_visa_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "non_eea_visa_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>All Non-EEA Mastercard</td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.non_eea_master_sr.includes(".") &&
                       priceQuoteInput.non_eea_master_sr.split(".")[1].length > 3 && priceQuoteInput.non_eea_master_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_master_sr"
                      value={priceQuoteInput.non_eea_master_sr}
                      onChange={(e) =>
                        handleChangeInput("non_eea_master_sr", e.target.value)
                      }
                    />{" "}
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.non_eea_master_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.non_eea_master_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.non_eea_master_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_master_sr_per_tr_fee"
                      value={priceQuoteInput.non_eea_master_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "non_eea_master_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                     className={` ${ priceQuoteInput.non_eea_master_non_sr.includes(".") &&
                       priceQuoteInput.non_eea_master_non_sr.split(".")[1].length > 3 && priceQuoteInput.non_eea_master_non_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_master_non_sr"
                      value={priceQuoteInput.non_eea_master_non_sr}
                      onChange={(e) =>
                        handleChangeInput(
                          "non_eea_master_non_sr",
                          e.target.value
                        )
                      }
                    />
                    {"  "}
                    <input
                     className={` ${ priceQuoteInput.non_eea_master_non_sr_per_tr_fee.includes(".") &&
                       priceQuoteInput.non_eea_master_non_sr_per_tr_fee.split(".")[1].length > 3 && priceQuoteInput.non_eea_master_non_sr_per_tr_fee !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="non_eea_master_non_sr_per_tr_fee"
                      value={priceQuoteInput.non_eea_master_non_sr_per_tr_fee}
                      onChange={(e) =>
                        handleChangeInput(
                          "non_eea_master_non_sr_per_tr_fee",
                          e.target.value
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>* Diners</td>

                  <td colSpan={2}>
                    <input
                      style={{ width: "60%" }}
                     className={` ${ priceQuoteInput.diners_sr.includes(".") &&
                       priceQuoteInput.diners_sr.split(".")[1].length > 3 && priceQuoteInput.diners_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="diners_sr"
                      value={priceQuoteInput.diners_sr}
                      onChange={(e) =>
                        handleChangeInput("diners_sr", e.target.value)
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>** JCB</td>
                  <td colSpan={2}>
                    <input
                      style={{ width: "60%" }}
                     className={` ${ priceQuoteInput.jcb_sr.includes(".") &&
                       priceQuoteInput.jcb_sr.split(".")[1].length > 3 && priceQuoteInput.jcb_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="jcb_sr"
                      value={priceQuoteInput.jcb_sr}
                      onChange={(e) =>
                        handleChangeInput("jcb_sr", e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>*** Union Pay</td>
                  <td colSpan={2}>
                    <input
                      style={{ width: "60%" }}
                     className={` ${ priceQuoteInput.union_pay_sr.includes(".") &&
                       priceQuoteInput.union_pay_sr.split(".")[1].length > 3 && priceQuoteInput.union_pay_sr !==""  ? "error_input" : " input"}`}
                      type="number"
                      placeholder="£"
                      onWheel={(e) => e.target.blur()}
                      name="union_pay_sr"
                      value={priceQuoteInput.union_pay_sr}
                      onChange={(e) =>
                        handleChangeInput("union_pay_sr", e.target.value)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <ScheduleFeeTable />
          <QuoteProducts/>

          {/* <QuoteProductDetails /> */}
        </div>
      </div>

      <div className="mt-4"></div>
    </div>
  );
};

export default NewSchedule;
