import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Summary from "./Summary";

import SalesDetails from "./SalesDetails";

import ContactDetail from "./ContactDetail";
import NewSchedule from "./NewSchedule";
import Address from "./Address";
import BusinessInformation from "./BusinessInformation";
import FinantialInfo from "./FinantialInfo";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import QuoteDocument from "./QuoteDocument";
import './main.css'
export default function PriceQuoteTabs({ next }) {
  const history = useHistory();
  const [value, setValue] = React.useState(
    localStorage.getItem("selectedTab") || "1"
  ); // retrieve the last selected tab from localStorage or default to "1"

  const handleChange = (event, newValue) => {
    localStorage.setItem("selectedTab", newValue); // save the new selected tab to localStorage
    setValue(newValue);
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
  }, []);

  const [navbar, setNavbar] = React.useState(false);
  const [color, setColor] = React.useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
      setColor("black");
    } else {
      setNavbar(false);
      setColor("white");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);
  return (
    <div className="tab-wrapper">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className={navbar ? "scroll" : "navbar"}
            expand="lg"
            // style={{
            //   position: "fixed",
            //   backgroundColor: "white",
            // }}
          >
            <TabList
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label="Summary" value="1" />
              <Tab label="Contact Details" value="2" />
              <Tab label="Business Information" value="3" />
              <Tab label="Schedule of Fees" value="4" />
              <Tab label="Sales Details" value="5" />
              <Tab label="Finantial Information" value="6" />
              <Tab label="Address " value="7" />
              <Tab label="Files " value="8" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Summary />
          </TabPanel>
          <TabPanel value="2">
            <ContactDetail />
          </TabPanel>
          <TabPanel value="3">
            <BusinessInformation />
          </TabPanel>
          <TabPanel value="4">
            {/* <Schedule /> */}
            <NewSchedule />
          </TabPanel>
          <TabPanel value="5">
            <SalesDetails />
          </TabPanel>
          <TabPanel value="6">
            <FinantialInfo />
          </TabPanel>
          <TabPanel value="7">
            <Address />
          </TabPanel>
          <TabPanel value="8">
            {/* <Files next={next} /> */}
            <QuoteDocument next={next} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
