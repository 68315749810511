import * as Types from "../types/Types";
import moment from "moment";
const initialState = {
  leadInput: {
    lead_products: [
      {
        connection_type: "",
        // contact_length: 18,
        terminal_model: "",
        provider: "",
        qty: 1,
        // country: 74,
        product: "",
        lead_id: "",
        monthly_price: "",
        product_term: 18,
        is_deleted: false,
        terminal_provider: "",
        epos_provider: "",
        getway_provider: "",
        terminal_option: "",
        integration_availability: "",
        epos_option: "",
        website_url: "",
        // product_type: "",
      },
    ],
    terminal_products: [
      {
        connection_type: "",
        // contact_length: 18,
        terminal_model: "",
        monthly_price: "",
        qty: 1,
        epos_name: "",
        contact_length: "",
        application: "",
        product: "",
        lead_id: "",
        price: "",
        product_term: 18,
        is_deleted: false,
        product_type: "",
        epos_provider: "",
        epos_option: "",
        terminal_option: "",
        integration_availability: "",
        terminal_provider: "",

        // "product_term": "string",
        // "terminal_model": "string",
        // "qty": 65535,
        // "website_url": "string",
        // "price": "031710",
        // "provider": 0,
        // "terminal_option": "MR",
        // "integration_availability": "INTEGRATED",
        // "epos_option": "RETAIL",
        // "is_deleted": true,
        // "lead_id": 0,
        // "product": 0,
        // "connection_type": 0
      },
    ],
    ecommerce_products: [
      {
        provider: "",
        product_type: "",
        lead_id: "",
        is_deleted: false,
        website_url: "",
        getway_provider: "",
        application: "",
      },
    ],
    epos_products: [
      {
        provider: "",
        product_type: "epos",
        lead_id: "",
        is_deleted: false,
        epos_option: "",
        epos_provider: "",
        one_of_cost: "",
        contact_length: "",
        application: "",
        monthly_price: "",
      },
    ],
    card_machine_service: false,
    ecom_service: false,
    epos_service: false,
    lead_qualify_id: "",
    flag_code: "GB",
    qualify_id: "",
    source: "",
    id: "",
    legal_type: "",
    lead_stage: null,
    lead_status: "",
    sales_partner: "",
    partner_manager: "",
    callback_date: null,
    legal_name: "",
    contact_full_name: "",
    trading_name: "",
    current_ownership_since: null,
    company_house_no: "",
    trading_address: "",
    mcc_code: "",
    website: "",
    site_and_trading_address_same: 0,
    note: "",
    first_name: "",
    last_name: "",
    dob: null,
    mobile: "",
    email: "",
    telephone: "",
    secondary_email: "",

    legal_postcode: "",
    legal_address1: "",
    legal_address2: "",
    legal_city: "",
    legal_county: "",
    legal_country: 74,
    trading_postcode: "",
    trading_address1: "",
    trading_address2: "",
    trading_city: "",
    trading_county: "",
    country_name: "",
    trading_country: 74,
    home_postcode: "",
    home_address1: "",
    home_address2: "",
    home_city: "",
    home_county: "",
    home_country: 74,
    is_closed: false,
    user: "",
    industry_type: "",
    lead_type: 2,
    appointment_date: null,

    visa_debit_cr: "0",
    visa_debit_pr: "0.45",
    visa_debit_ts: "0.0",
    // visa_debit_tr_no: "",
    visa_debit_cc: "0",
    visa_debit_pc: "0",
    mastercard_debit_cr: "0",
    mastercard_debit_pr: "0.48",
    mastercard_debit_ts: "0.0",
    // mastercard_debit_tr_no: "",
    mastercard_debit_cc: "0",
    mastercard_debit_pc: "0",

    visa_credit_cr: "0",
    visa_credit_pr: "0.70",
    visa_credit_ts: "0.0",
    // visa_credit_tr_no: "",
    visa_credit_cc: "0",
    visa_credit_pc: "0",

    mastercard_credit_cr: "0",
    mastercard_credit_pr: "0.70",
    mastercard_credit_ts: "0.0",
    // mastercard_credit_tr_no: "",
    mastercard_credit_cc: "0",
    mastercard_credit_pc: "0",

    visa_business_debit_cr: "0",
    visa_business_debit_pr: "1.50",
    visa_business_debit_ts: "0.0",
    // visa_business_debit_tr_no: "",
    visa_business_debit_cc: "0.0",
    visa_business_debit_pc: "0.0",

    visa_business_credit_cr: "0.0",
    visa_business_credit_pr: "2.10",
    visa_business_credit_ts: "0.0",
    // visa_business_credit_tr_no: "",
    visa_business_credit_cc: "0.0",
    visa_business_credit_pc: "0.0",

    card_acceptance_fee_cr: "0.0",
    card_acceptance_fee_pr: "0.0",
    card_acceptance_fee_ts: "0.0",
    // card_acceptance_fee_tr_no: "",
    card_acceptance_fee_cc: "0.0",
    card_acceptance_fee_pc: "0.0",

    card_transaction_fee_cr: "0.0",
    card_transaction_fee_pr: "0.0",
    card_transaction_fee_ts: "0.0",
    // card_transaction_fee_tr_no: "",
    card_transaction_fee_cc: "0.0",
    card_transaction_fee_pc: "0.0",

    authorization_fee_cr: "0.0",
    authorization_fee_pr: "0.0",
    // authorization_fee_ts: "",
    authorization_fee_tr_no: "0.0",
    authorization_fee_cc: "0.0",
    authorization_fee_pc: "0.0",

    per_transactional_charge_cr: 0.0,
    per_transactional_charge_pr: 0.0,
    per_transactional_charge_ts: 0.0,
    per_transactional_charge_tr_no: 0.0,
    per_transactional_charge_cc: 0.0,
    per_transactional_charge_pc: 0.0,
    portal_reporting_fee_cr: 0.0,
    portal_reporting_fee_pr: 0.0,
    portal_reporting_fee_ts: 0.0,
    portal_reporting_fee_tr_no: 0.0,
    portal_reporting_fee_cc: 0.0,
    portal_reporting_fee_pc: 0.0,
    pci_dss_fee_cr: 0.0,
    pci_dss_fee_pr: 0.0,
    pci_dss_fee_ts: 0.0,
    pci_dss_fee_tr_no: 0.0,
    pci_dss_fee_cc: 0.0,
    pci_dss_fee_pc: 0.0,
    terminal_rental_fee_cr: 0.0,
    terminal_rental_fee_pr: 0.0,
    terminal_rental_fee_ts: 0.0,
    terminal_rental_fee_tr_no: 0.0,
    terminal_rental_fee_cc: 0.0,
    terminal_rental_fee_pc: 0.0,
    num_of_terminals: 0,
    terminal_provider_pervious: "",
    terminal_provider_current: "",

    mmsc: 0,
    mobile_code: 74,
    phone_code: 74,
    incorporated_on: null,
    charity_number: "",
    step: "1",
    active_lead: false,
    active_quote: false,
    active_application: false,

    // -new- product
    product_type: [],
    terminal_option: "",
    integration_availability: "",
    epos_name: "",
    website_url: "",
    epos_option: "",
  },
  // -new- product
  priceQuoteInput: {
    quote_docs: [
      {
        id: "",
        category: "PROOF_OF_ID",
        doc_type: "",
        doc: "",
        doc_url: "",
        quote: "",
        is_deleted: false,
        country: 74,
        document_id: "",
        sur_name: "",
        first_name: "",
        issue_date: null,
        expiry_date: null,
        issuer_id: "",
      },
    ],
    quote_products: [
      {
        connection_type: "",
        // contact_length: 18,
        terminal_model: "",
        provider: "",
        qty: 1,
        // country: 74,
        product: "",
        quote: "",
        monthly_price: "",
        product_term: 18,
        is_deleted: false,
      },
    ],
    quote_owners: [
      {
        quote_owner_contacts: [
          {
            email_address: "",
            zip_code: "",
            street_line_1: "",
            city: "",
            locality: "",
            county_code: "",
            // quote_owner: 0,
            country_code: 74,
          },
        ],
        id: "",
        internet_service_provider: "",
        payment_service_provider: "",
        security_bin_check: true,
        security_velocity_check: true,
        security_ip_geo_check: true,
        security_max_amt_check: true,
        security_other_check: true,

        is_main_principal: false,
        is_beneficial_owner: false,
        is_signatory: false,
        is_responsible_party: true,
        is_director: false,
        is_owner: false,
        owner_title: "",
        owner_first_name: "",
        owner_second_name: "demo",
        owner_surname: "dfdf",
        contact_dob: null,
        owner_email: "",
        ownership_perc: "",
        owner_phone_no: "",
        // owner_alt_phone_no: "",
        owner_fax: "",

        // owner_date_started: "",
        address_date_from: "2022-10-10",
        quote: 0,
        owner_phone_code: 74,
        // owner_alt_phone_code: "",
        owner_fax_code: "",
        owner_nationality: 74,
        owner_alt_nationality: 74,
        owner_id_num: null,
      },
    ],
    card_machine_service: false,
    ecom_service: false,
    epos_service: false,
    lead_qualify_id: "",
    is_closed: false,
    qualify_id: "",
    app_qualify_id: "",

    // source: "",
    lead_source: "",
    incorporated_on: null,
    client_id: "",
    id: undefined,
    legal_type: "",
    existing_mid: "",
    lead_stage: "",
    lead_status: "",
    sales_partner: "",
    partner_manager: "",
    callback_date: "",
    site_and_trading_address_same: 0,
    legal_name: "",
    trading_name: "",
    current_ownership_since: "",
    company_house_no: "",
    trading_address: "",
    mcc_code: "",
    website: "",
    note: "",
    first_name: "",
    last_name: "",
    dob: null,
    mobile: "",
    email: "",
    telephone: "",
    secondary_email: "",
    auth_fees: "",
    mmsc: 0,
    // -new-
    product_type: [],
    terminal_option: "",
    integration_availability: "",
    epos_name: "",
    website_url: "",
    epos_option: "",

    visa_credit_sr: "0.70",
    visa_credit_non_sr: "",
    visa_credit_sr_per_tr_fee: "",
    visa_credit_per_non_sr_tr_fee: "",

    master_credit_sr: "0.70",
    master_credit_non_sr: "",
    master_credit_sr_per_tr_fee: "",
    master_credit_non_sr_per_tr_fee: "",

    visa_debit_sr: "0.45",
    visa_debit_non_sr: "",
    visa_debit_sr_per_tr_fee: "",
    visa_debit_non_sr_per_tr_fee: "",

    master_debit_sr: ".48",
    master_debit_non_sr: "",
    master_debit_sr_per_tr_fee: "",
    master_debit_non_sr_per_tr_fee: "",

    visa_business_debit_sr: "1.50",
    visa_business_debit_non_sr: "",
    visa_business_debit_sr_per_tr_fee: "",
    visa_business_debit_non_sr_per_tr_fee: "",

    visa_purchasing_sr: "2.50",
    visa_purchasing_non_sr: "2.769",
    visa_purchasing_sr_per_tr_fee: "",
    visa_purchasing_non_sr_per_tr_fee: "",

    visa_corporate_sr: "2.50",
    visa_corporat_non_sr_per_tr_fee: "",
    visa_corporate_sr_per_tr_fee: "2.769",
    visa_corporate_non_sr: "",

    master_purchasing_sr: "2.50",
    master_purchasing_sr_per_tr_fee: "2.975",
    master_purchasing_non_sr: "",
    master_purchasing_non_sr_per_tr_fee: "",

    master_fleet_sr: "2.50",
    master_fleet_sr_per_tr_fee: "2.984",
    master_fleet_non_sr: "",
    master_fleet_non_sr_per_tr_fee: "",

    master_corporate_sr: "2.50",
    master_corporate_non_sr: "",
    master_corporate_sr_per_tr_fee: "2.984",
    master_corporate_non_sr_per_tr_fee: "",

    master_pre_commercial_sr: "2.50",
    master_pre_commercial_sr_per_tr_fee: "2.805",
    master_pre_commercial_non_sr: "",
    master_pre_commercial_non_sr_per_tr_fee: "",

    non_eea_visa_sr: "3.00",
    non_eea_visa_non_sr: "",
    non_eea_visa_non_sr_per_tr_fee: "",
    non_eea_visa_sr_per_tr_fee: "3.312",

    non_eea_master_sr: "3.00",
    non_eea_master_sr_per_tr_fee: "3.312",
    non_eea_master_non_sr: "",
    non_eea_master_non_sr_per_tr_fee: "",

    visa_v_pay_sr: ".45",
    visa_v_pay_non_sr_per_tr_fee: "",
    visa_v_pay_sr_per_tr_fee: "",
    visa_v_pay_non_sr: "",

    uk_maestro_sr_per_tr_fee: "",
    uk_maestro_non_sr_per_tr_fee: "",
    uk_maestro_sr: "0.48",
    uk_maestro_non_sr: "",

    international_maestro_sr: "1.00",
    international_maestro_non_sr: "",
    international_maestro_sr_per_tr_fee: "",
    international_maestro_non_sr_per_tr_fee: "",

    visa_business_credit_sr: "",
    visa_business_credit_non_sr: "",
    visa_business_credit_non_sr_per_tr_fee: "",
    visa_business_credit_sr_per_tr_fee: "",

    master_business_sr: "2.10",
    master_business_non_sr: "",
    master_business_sr_per_tr_fee: "",
    master_business_non_sr_per_tr_fee: "",

    diners_sr: "2.85",
    jcb_sr: "2.85",
    union_pay_sr: "2.85",

    atv: "",
    smtv: "",
    annual_card_turnover: "",
    annual_turnover: "",
    opportunity_stage: "",
    opportunity_status: "",
    price_quote_date: new Date(),
    est_close_date: new Date(),
    bank_name: "",
    bank_sort_code: "",
    bank_account_name: "",
    bank_account_no: "",
    // lead: "",
    user: "",
    industry_type: "",
    attachments: [],

    acquiring_bank: "",

    application_type: "",
    opportunity_id: "",
    opportunity_name: "",
    legal_postcode: "",
    legal_address1: "",
    legal_address2: "",
    legal_city: "",
    legal_county: "",
    legal_country: 74,
    trading_postcode: "",
    trading_address1: "",
    trading_address2: "",
    trading_city: "",
    trading_county: "",
    trading_country: 74,
    home_postcode: "",
    home_address1: "",
    home_address2: "",
    home_city: "",
    home_county: "",
    home_country: 74,

    cole_from: "",
    mobile_code: 74,
    phone_code: 74,

    security_check: "N/A",
    payment_service_provider: "",
    internet_service_provider: "",

    dinners: "2.86",
    jcb: "2.85",
    union_pay: "2.85",
    charity_number: "",
  },

  leadsList: null,
  IndustryList: null,
  countryList: null,
  sourceList: null,
  leadQualify: null,
  productList: null,
  salesPartnerList: null,
  priceQuoteList: null,
  applicationList: null,
  priceQuoteDetails: null,
  isLoadLeads: false,
  afterSuccessLeads: false,
  afterSuccessqualify: false,
  leadQualifyCheck: false,
  afterSuccessUpdate: false,
  aftersuccessQuote: false,
  afterSuccessQuotequalify: false,
  leadsPagination: null,
  priceQuotePagination: null,
  isAddressChecked: false,
  ApplicationQualify: null,
  afterSuccessAplication: false,
  afterSuccessApplicationqualify: false,
  activeStep: false,
};
export const secondLevelReducer = (state, action) => {
  return {
    ...state,
    thirdLevel: {
      ...state.thirdLevel,
      property1: action.data,
    },
  };
};

const LeadsReducer = (state = initialState, action) => {
  const newState = { ...state };
  //Leads
  switch (action.type) {
    case Types.AFTER_SUCCESS_LEADS:
      return {
        ...state,
        afterSuccessLeads: action.payload,
      };
    case Types.SET_FALSE:
      return {
        ...state,
        afterSuccessLeads: action.payload,
        leadInput: initialState.leadInput,
      };
    case Types.AFTER_SUCCESS_UPDATE:
      return {
        ...state,
        afterSuccessUpdate: action.payload,
      };
    case Types.SET_FALSE_UPDATE_LEADS:
      return {
        ...state,
        afterSuccessUpdate: action.payload,
        leadInput: initialState.leadInput,
      };

    // --------------qualify-------------------
    case Types.GET_QUALIFY_LEAD:
      return {
        ...state,
        leadQualify: action.payload,
      };
    // case Types.GET_QUALIFY_LIST:
    //   return {
    //     ...state,
    //     qualifyList: action.payload,
    //   };
    case Types.AFTER_SUCCESS_QUALIFY:
      return {
        ...state,
        afterSuccessqualify: action.payload,
      };
    case Types.QUALIFY_STATUS:
      return {
        ...state,
        leadQualifyCheck: action.payload,
      };
    case Types.SET_FALSE_QUALIFY:
      return {
        ...state,
        afterSuccessqualify: action.payload,
        leadInput: initialState.leadInput,
      };
    case Types.SET_FALSE_LEAD_QUALIFY:
      return {
        ...state,
        leadQualifyCheck: action.payload,
      };

    // -----------------------end qualify----------------------
    case Types.ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };

    case Types.GET_LEADS_LIST:
      return {
        ...state,
        leadsList: action.payload,
      };
    case Types.IS_ADDRESS_CHECKED:
      return {
        ...state,
        isAddressChecked: action.payload,
      };
    case Types.GET_INDUSTRY_LIST:
      return {
        ...state,
        IndustryList: action.payload,
      };

    case Types.GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case Types.GET_LEAD_SOURCE:
      return {
        ...state,
        sourceList: action.payload,
      };
    case Types.GET_SALES_PARTNER:
      return {
        ...state,
        salesPartnerList: action.payload,
      };

    case Types.IS_LOAD_LEADS:
      return {
        ...state,
        isLoadLeads: action.payload,
      };

    case Types.GET_LEADS_INPUT:
      const leadInput = { ...state.leadInput };
      leadInput[action.payload.name] = action.payload.value;
      if ("visa_debit_cr" === action.payload.name) {
        leadInput["visa_debit_cc"] = (
          (parseFloat(action.payload.value) * leadInput.visa_debit_ts) /
          100
        ).toFixed(2);
      } else if ("visa_debit_ts" === action.payload.name) {
        leadInput["visa_debit_cc"] = (
          (parseFloat(action.payload.value) * leadInput.visa_debit_cr) /
          100
        ).toFixed(2);
      }
      return {
        ...state,
        leadInput: leadInput,
      };

    //  ==================lead products================

    case Types.GET_LEAD_PRODUCT:
      const setLeadProductInput = { ...state.leadInput };
      const lead_products = [...state.leadInput.lead_products];

      lead_products[action.payload.index] = {
        ...state.leadInput.lead_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        leadInput: {
          ...setLeadProductInput,
          lead_products,
        },
      };

    case Types.SET_LEAD_PRODUCT:
      const setLeadProduct = { ...state.leadInput };
      const newLeadProduct = [...state.leadInput.lead_products];

      return {
        ...state,
        leadInput: {
          ...setLeadProduct,
          lead_products: [
            ...newLeadProduct,
            {
              connection_type: "",
              // contact_length: 18,
              terminal_model: "",
              provider: "",
              qty: 1,
              product: "",
              lead_id: "",
              monthly_price: "",
              is_deleted: false,
              product_term: 18,
            },
          ],
        },
      };
    case Types.REMOVE_LEAD_PRODUCT:
      const removeLeadProduct = { ...state.leadInput };

      const updateLeadProducts = state.leadInput.lead_products.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        leadInput: {
          ...removeLeadProduct,
          lead_products: [
            ...updateLeadProducts,
            // removeoptions.splice(1)
          ],
        },
      };
    //  ==================lead products================

    case Types.GET_EPOS_PRODUCT:
      const setEposProductInput = { ...state.leadInput };
      const epos_products = [...state.leadInput.epos_products];

      epos_products[action.payload.index] = {
        ...state.leadInput.epos_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        leadInput: {
          ...setEposProductInput,
          epos_products,
        },
      };

    case Types.SET_EPOS_PRODUCT:
      const setEposProduct = { ...state.leadInput };
      const newEposProduct = [...state.leadInput.epos_products];

      return {
        ...state,
        leadInput: {
          ...setEposProduct,
          epos_products: [
            ...newEposProduct,
            {
              product_type: "epos",
              epos_provider: "",
              lead_id: "",
              is_deleted: false,
              epos_option: "",
              one_of_cost: "",
              contact_length: "",
              application: "",
              monthly_price: "",
            },
          ],
        },
      };
    case Types.REMOVE_EPOS_PRODUCT:
      const removEposProduct = { ...state.leadInput };

      const updateEposProducts = state.leadInput.epos_products.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        leadInput: {
          ...removEposProduct,
          epos_products: [
            ...updateEposProducts,
            // removeoptions.splice(1)
          ],
        },
      };
    //  ==================lead products================

    case Types.GET_ECOMMERCE_PRODUCT:
      const setEcommerceProductInput = { ...state.leadInput };
      const ecommerce_products = [...state.leadInput.ecommerce_products];

      ecommerce_products[action.payload.index] = {
        ...state.leadInput.ecommerce_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        leadInput: {
          ...setEcommerceProductInput,
          ecommerce_products,
        },
      };

    case Types.SET_ECOMMERCE_PRODUCT:
      const setecommerceProduct = { ...state.leadInput };
      const newEcommerceProduct = [...state.leadInput.ecommerce_products];

      return {
        ...state,
        leadInput: {
          ...setecommerceProduct,
          ecommerce_products: [
            ...newEcommerceProduct,
            {
              product_type: "",
              lead_id: "",
              is_deleted: false,
              website_url: "",
              getway_provider: "",

              application: "",
            },
          ],
        },
      };
    case Types.REMOVE_ECOMMERCE_PRODUCT:
      const removeEcommerceProduct = { ...state.leadInput };

      const updateEcommerceProducts = state.leadInput.ecommerce_products.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        leadInput: {
          ...removeEcommerceProduct,
          ecommerce_products: [
            ...updateEcommerceProducts,
            // removeoptions.splice(1)
          ],
        },
      };
    //  ==================lead products================

    case Types.GET_CARD_TERMINAL_PRODUCT:
      const setTerminalProductInput = { ...state.leadInput };
      const terminal_products = [...state.leadInput.terminal_products];

      terminal_products[action.payload.index] = {
        ...state.leadInput.terminal_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        leadInput: {
          ...setTerminalProductInput,
          terminal_products,
        },
      };

    case Types.SET_CARD_TERMINAL_PRODUCT:
      const setTerminalProduct = { ...state.leadInput };
      const newTerminalProduct = [...state.leadInput.terminal_products];

      return {
        ...state,
        leadInput: {
          ...setTerminalProduct,
          terminal_products: [
            ...newTerminalProduct,
            {
              connection_type: "",
              product_type: "card_terminal",
              terminal_model: "",
              provider: "",
              qty: 1,
              country: 74,
              product: "",
              lead_id: "",
              price: "",
              product_term: 18,
              is_deleted: false,
              terminal_provider: "",
              epos_provider: "",
              epos_option: "",
              epos_name: "",
              terminal_option: "",
              integration_availability: "",
              contact_length: "",
              application: "",
              monthly_price: "",
            },
          ],
        },
      };
    case Types.REMOVE_CARD_TERMINAL_PRODUCT:
      const removeTerminalProduct = { ...state.leadInput };

      const updateTerminalProducts = state.leadInput.terminal_products.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        leadInput: {
          ...removeTerminalProduct,
          terminal_products: [
            ...updateTerminalProducts,
            // removeoptions.splice(1)
          ],
        },
      };

    case Types.SET_LEADS_UPDATED:
      const setLeadsInput = { ...state.leadInput };
      setLeadsInput.lead_source = action.payload.lead_source;
      setLeadsInput.id = action.payload.id;
      setLeadsInput.lead = action.payload.lead;
      setLeadsInput.flag_code = action.payload.flag_code;
      setLeadsInput.epos_service = action.payload.epos_service;
      setLeadsInput.ecom_service = action.payload.ecom_service;
      setLeadsInput.card_machine_service = action.payload.card_machine_service;
      setLeadsInput.lead_products = action.payload.lead_products || [];

      setLeadsInput.charity_number = action.payload.charity_number;
      setLeadsInput.legal_type = action.payload.legal_type;
      setLeadsInput.lead_stage = action.payload.lead_stage;
      setLeadsInput.lead_status = action.payload.lead_status;
      setLeadsInput.sales_partner = action.payload.sales_partner;
      setLeadsInput.partner_manager = action.payload.partner_manager;
      setLeadsInput.callback_date = action.payload.callback_date || null;
      setLeadsInput.legal_name = action.payload.legal_name;
      setLeadsInput.contact_full_name = action.payload.contact_full_name;
      setLeadsInput.trading_name = action.payload.trading_name;
      setLeadsInput.current_ownership_since =
        action.payload.current_ownership_since;
      setLeadsInput.company_house_no = action.payload.company_house_no;
      setLeadsInput.trading_address = action.payload.trading_address;
      setLeadsInput.mcc_code = action.payload.mcc_code;
      setLeadsInput.website = action.payload.website;
      setLeadsInput.note = action.payload.note;
      setLeadsInput.first_name = action.payload.first_name;
      setLeadsInput.last_name = action.payload.last_name;
      setLeadsInput.dob = action.payload.dob;
      setLeadsInput.mobile = action.payload.mobile;
      setLeadsInput.email = action.payload.email;
      setLeadsInput.telephone = action.payload.telephone;
      setLeadsInput.secondary_email = action.payload.secondary_email;
      setLeadsInput.legal_postcode = action.payload.legal_postcode;
      setLeadsInput.legal_address1 = action.payload.legal_address1;
      setLeadsInput.legal_address2 = action.payload.legal_address2;
      setLeadsInput.legal_city = action.payload.legal_city;
      setLeadsInput.legal_county = action.payload.legal_county;
      setLeadsInput.legal_country = action.payload.legal_country;
      setLeadsInput.trading_postcode = action.payload.trading_postcode;
      setLeadsInput.trading_address1 = action.payload.trading_address1;
      setLeadsInput.trading_address2 = action.payload.trading_address2;
      setLeadsInput.trading_city = action.payload.trading_city;
      setLeadsInput.home_postcode = action.payload.home_postcode;
      setLeadsInput.home_address1 = action.payload.home_address1;
      setLeadsInput.home_address2 = action.payload.home_address2;
      setLeadsInput.home_city = action.payload.home_city;
      setLeadsInput.home_county = action.payload.home_county;
      setLeadsInput.home_country = action.payload.home_country;

      setLeadsInput.secondary_email = action.payload.secondary_email;
      setLeadsInput.trading_county = action.payload.trading_county;
      setLeadsInput.trading_country = action.payload.trading_country;
      setLeadsInput.industry_type = action.payload.industry_type;
      setLeadsInput.lead_type = action.payload.lead_type;
      setLeadsInput.appointment_date = action.payload.appointment_date || null;
      setLeadsInput.user = action.payload.user;
      setLeadsInput.id = action.payload.id;
      setLeadsInput.incorporated_on = action.payload.incorporated_on;

      setLeadsInput.visa_debit_pr = action.payload.visa_debit_pr;
      setLeadsInput.visa_debit_cr = action.payload.visa_debit_cr;
      setLeadsInput.visa_debit_ts = action.payload.visa_debit_ts;
      // setLeadsInput.visa_debit_tr_no = action.payload.visa_debit_tr_no;
      setLeadsInput.visa_debit_cc = action.payload.visa_debit_cc;
      setLeadsInput.visa_debit_pc = action.payload.visa_debit_pc;

      setLeadsInput.mastercard_debit_cr = action.payload.mastercard_debit_cr;
      setLeadsInput.mastercard_debit_pr = action.payload.mastercard_debit_pr;
      setLeadsInput.mastercard_debit_ts = action.payload.mastercard_debit_ts;
      // setLeadsInput.mastercard_debit_tr_no =
      // action.payload.mastercard_debit_tr_no;
      setLeadsInput.mastercard_debit_cc = action.payload.mastercard_debit_cc;
      setLeadsInput.mastercard_debit_pc = action.payload.mastercard_debit_pc;

      setLeadsInput.visa_credit_cr = action.payload.visa_credit_cr;
      setLeadsInput.visa_credit_pr = action.payload.visa_credit_pr;
      setLeadsInput.visa_credit_ts = action.payload.visa_credit_ts;
      // // setLeadsInput.visa_credit_tr_no = action.payload.visa_credit_tr_no;
      setLeadsInput.visa_credit_cc = action.payload.visa_credit_cc;
      setLeadsInput.visa_credit_pc = action.payload.visa_credit_pc;

      setLeadsInput.mastercard_credit_cr = action.payload.mastercard_credit_cr;
      setLeadsInput.mastercard_credit_pr = action.payload.mastercard_credit_pr;
      setLeadsInput.mastercard_credit_ts = action.payload.mastercard_credit_ts;
      // setLeadsInput.mastercard_credit_tr_no =
      // action.payload.mastercard_credit_tr_no;
      setLeadsInput.mastercard_credit_cc = action.payload.mastercard_credit_cc;
      setLeadsInput.mastercard_credit_pc = action.payload.mastercard_credit_pc;

      setLeadsInput.visa_business_debit_cr =
        action.payload.visa_business_debit_cr;
      setLeadsInput.visa_business_debit_pr =
        action.payload.visa_business_debit_pr;
      setLeadsInput.visa_business_debit_ts =
        action.payload.visa_business_debit_ts;
      // setLeadsInput.visa_business_debit_tr_no =
      // action.payload.visa_business_debit_tr_no;
      setLeadsInput.visa_business_debit_cc =
        action.payload.visa_business_debit_cc;
      setLeadsInput.visa_business_debit_pc =
        action.payload.visa_business_debit_pc;

      setLeadsInput.visa_business_credit_cr =
        action.payload.visa_business_credit_cr;
      setLeadsInput.visa_business_credit_pr =
        action.payload.visa_business_credit_pr;
      setLeadsInput.visa_business_credit_ts =
        action.payload.visa_business_credit_ts;
      // setLeadsInput.visa_business_credit_tr_no =
      // action.payload.visa_business_credit_tr_no;
      setLeadsInput.visa_business_credit_cc =
        action.payload.visa_business_credit_cc;
      setLeadsInput.visa_business_credit_pc =
        action.payload.visa_business_credit_pc;

      setLeadsInput.card_acceptance_fee_cr =
        action.payload.card_acceptance_fee_cr;
      setLeadsInput.card_acceptance_fee_pr =
        action.payload.card_acceptance_fee_pr;
      setLeadsInput.card_acceptance_fee_ts =
        action.payload.card_acceptance_fee_ts;
      // setLeadsInput.card_acceptance_fee_tr_no =
      // action.payload.card_acceptance_fee_tr_no;
      setLeadsInput.card_acceptance_fee_cc =
        action.payload.card_acceptance_fee_cc;
      setLeadsInput.card_acceptance_fee_pc =
        action.payload.card_acceptance_fee_pc;

      setLeadsInput.card_transaction_fee_cr =
        action.payload.card_transaction_fee_cr;
      setLeadsInput.card_transaction_fee_pr =
        action.payload.card_transaction_fee_pr;
      setLeadsInput.card_transaction_fee_ts =
        action.payload.card_transaction_fee_ts;
      // setLeadsInput.card_transaction_fee_tr_no =
      // action.payload.card_transaction_fee_tr_no;
      setLeadsInput.card_transaction_fee_cc =
        action.payload.card_transaction_fee_cc;
      setLeadsInput.card_transaction_fee_pc =
        action.payload.card_transaction_fee_pc;

      setLeadsInput.authorization_fee_cr = action.payload.authorization_fee_cr;
      setLeadsInput.authorization_fee_pr = action.payload.authorization_fee_pr;
      // setLeadsInput.authorization_fee_ts = action.payload.authorization_fee_ts;
      setLeadsInput.authorization_fee_tr_no =
        action.payload.authorization_fee_tr_no;
      setLeadsInput.authorization_fee_cc = action.payload.authorization_fee_cc;
      setLeadsInput.authorization_fee_pc = action.payload.authorization_fee_pc;

      setLeadsInput.per_transactional_charge_cr =
        action.payload.per_transactional_charge_cr;
      setLeadsInput.per_transactional_charge_pr =
        action.payload.per_transactional_charge_pr;
      setLeadsInput.per_transactional_charge_ts =
        action.payload.per_transactional_charge_ts;
      setLeadsInput.per_transactional_charge_tr_no =
        action.payload.per_transactional_charge_tr_no;
      setLeadsInput.per_transactional_charge_cc =
        action.payload.per_transactional_charge_cc;
      setLeadsInput.per_transactional_charge_pc =
        action.payload.per_transactional_charge_pc;

      setLeadsInput.portal_reporting_fee_cr =
        action.payload.portal_reporting_fee_cr;
      setLeadsInput.portal_reporting_fee_pr =
        action.payload.portal_reporting_fee_pr;
      setLeadsInput.portal_reporting_fee_ts =
        action.payload.portal_reporting_fee_ts;
      setLeadsInput.portal_reporting_fee_tr_no =
        action.payload.portal_reporting_fee_tr_no;
      setLeadsInput.portal_reporting_fee_cc =
        action.payload.portal_reporting_fee_cc;
      setLeadsInput.portal_reporting_fee_pc =
        action.payload.portal_reporting_fee_pc;

      setLeadsInput.pci_dss_fee_cr = action.payload.pci_dss_fee_cr;
      setLeadsInput.pci_dss_fee_pr = action.payload.pci_dss_fee_pr;
      setLeadsInput.pci_dss_fee_ts = action.payload.pci_dss_fee_ts;
      setLeadsInput.pci_dss_fee_tr_no = action.payload.pci_dss_fee_tr_no;
      setLeadsInput.pci_dss_fee_cc = action.payload.pci_dss_fee_cc;
      setLeadsInput.pci_dss_fee_pc = action.payload.pci_dss_fee_pc;

      setLeadsInput.terminal_rental_fee_cr =
        action.payload.terminal_rental_fee_cr;
      setLeadsInput.terminal_rental_fee_pr =
        action.payload.terminal_rental_fee_pr;
      setLeadsInput.terminal_rental_fee_ts =
        action.payload.terminal_rental_fee_ts;
      setLeadsInput.terminal_rental_fee_tr_no =
        action.payload.terminal_rental_fee_tr_no;
      setLeadsInput.terminal_rental_fee_cc =
        action.payload.terminal_rental_fee_cc;
      setLeadsInput.terminal_rental_fee_pc =
        action.payload.terminal_rental_fee_pc;
      setLeadsInput.num_of_terminals = action.payload.num_of_terminals;
      setLeadsInput.terminal_provider_current =
        action.payload.terminal_provider_current;
      setLeadsInput.terminal_provider_pervious =
        action.payload.terminal_provider_pervious;
      setLeadsInput.site_and_trading_address_same =
        action.payload.site_and_trading_address_same;

      return {
        ...state,
        leadInput: setLeadsInput,
      };

    // ---------------priceQuote------------------
    case Types.GET_PRICE_QUOTE_INPUT:
      const priceQuoteInput = { ...state.priceQuoteInput };
      priceQuoteInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        priceQuoteInput: priceQuoteInput,
      };
    case Types.GET_PRICE_QUOTE_OWNER:
      const setPriceQuoteOwnerInput = { ...state.priceQuoteInput };
      const quote_owners = [...state.priceQuoteInput.quote_owners];
      if (action.payload.contact === "quote_owner_contacts") {
        quote_owners[action.payload.index] = {
          ...state.priceQuoteInput.quote_owners[action.payload.index],
          [action.payload.contact]: [
            {
              ...quote_owners[action.payload.index][action.payload.contact][0],
              [action.payload.name]: action.payload.value,
            },
          ],
        };
      } else {
        quote_owners[action.payload.index] = {
          ...state.priceQuoteInput.quote_owners[action.payload.index],
          [action.payload.name]: action.payload.value,
        };
      }
      return {
        ...state,
        priceQuoteInput: { ...setPriceQuoteOwnerInput, quote_owners },
      };

    case Types.SET_PRICE_QUOTE_OWNER_INPUT:
      const setQuoteOwnerInput = { ...state.priceQuoteInput };
      const newOwner = [...state.priceQuoteInput.quote_owners];
      let leadId = localStorage.getItem("leadId");
      return {
        ...state,
        priceQuoteInput: {
          ...setQuoteOwnerInput,
          quote_owners: [
            ...newOwner,
            {
              quote_owner_contacts: [
                {
                  email_address: "g@g.com",
                  zip_code: "",
                  street_line_1: "",
                  city: "",
                  county_code: "",
                  locality: "",
                  country_code: 74,
                },
              ],
              // id: "",
              is_main_principal: false,
              is_beneficial_owner: false,
              is_signatory: false,
              is_responsible_party: true,
              is_director: false,
              is_owner: false,
              owner_title: "",
              owner_first_name: "",
              owner_second_name: "demo",
              owner_surname: "",
              contact_dob: null,
              owner_email: "",
              ownership_perc: "",
              owner_phone_no: "",
              // owner_alt_phone_no: "",
              owner_fax: "",

              // owner_d?ate_started: "",
              address_date_from: "2022-10-10",
              quote: "",
              owner_phone_code: 74,
              // owner_alt_phone_code: "",
              owner_fax_code: "",
              owner_nationality: 74,
              owner_alt_nationality: "",
              owner_id_num: null,
            },
          ],
        },
      };

    case Types.REMOVE_QUOTE_OWNER:
      const RemoveOwnerInput = { ...state.priceQuoteInput };

      const updatedquote_owners = state.priceQuoteInput.quote_owners.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        priceQuoteInput: {
          ...RemoveOwnerInput,
          quote_owners: [
            ...updatedquote_owners,
            // removeoptions.splice(1)
          ],
        },
      };

    // -----------------------contact------------------
    case Types.UPDATE_QUOTE_OWNER_CONTACT:
      const updatePriceQuoteOwnerContactInput = { ...state.priceQuoteInput };
      const quote_owners_update = [
        ...state.priceQuoteInput.quote_owners[action.payload.index],
      ];
      const quote_owners_contact_update = [
        ...state.priceQuoteInput.quote_owners[action.payload.index]
          .quote_owner_contacts[0],
      ];
      return {
        ...state,
        priceQuoteInput: {
          ...updatePriceQuoteOwnerContactInput,
          quote_owners: [
            ...quote_owners_update,
            {
              quote_owner_contacts: [
                ...quote_owners_contact_update,
                {
                  email_address: "g@g.com",
                  zip_code: "",
                  street_line_1: "",
                  city: "",
                  county_code: "",
                  quote_owner: 0,
                  country_code: 74,
                  locality: "",
                },
              ],
              id: "",
              is_main_principal: false,
              is_beneficial_owner: false,
              is_signatory: false,
              is_responsible_party: true,
              is_director: false,
              is_owner: false,
              owner_title: "",
              owner_first_name: "",
              owner_second_name: "demo",
              owner_surname: "string",
              contact_dob: null,
              owner_email: "",
              ownership_perc: "",
              owner_phone_no: "",
              // owner_alt_phone_no: "",
              owner_fax: "",

              // owner_date_started: "",
              address_date_from: "2022-10-10",
              quote: "",
              owner_phone_code: 74,
              // owner_alt_phone_code: "",
              owner_fax_code: "",
              owner_nationality: 74,
              owner_alt_nationality: "",
              owner_id_num: null,
            },
          ],
        },
      };

    case Types.GET_PRICE_QUOTE_OWNER_CONTACT:
      const setPriceQuoteOwnerContactInput = { ...state.priceQuoteInput };
      const r_quote_owners = [...state.priceQuoteInput.quote_owners];
      r_quote_owners[action.payload.index] = {
        ...state.priceQuoteInput.quote_owners[action.payload.index],
        quote_owner_contacts: [
          {
            email_address: action.payload.name,
            zip_code: "",
            street_line_1: "sdfdsf",
            city: "",
            county_code: "",
            quote_owner: 0,
            country_code: 74,
            locality: "",
          },
        ],
        id: "",
        is_main_principal: false,
        is_beneficial_owner: false,
        is_signatory: false,
        is_responsible_party: true,
        is_director: false,
        is_owner: false,
        owner_title: "",
        owner_first_name: "",
        owner_second_name: "demo",
        owner_surname: "",
        contact_dob: null,
        owner_email: "",
        ownership_perc: "",
        owner_phone_no: "",
        // owner_alt_phone_no: "",
        owner_fax: "",

        // owner_date_started: "",
        address_date_from: "2022-10-10",
        // quote: parseInt(leadId),
        owner_phone_code: 74,
        // owner_alt_phone_code: "",
        owner_fax_code: "",
        owner_nationality: 74,
        owner_alt_nationality: "",
        owner_id_num: null,
      };

      return {
        ...state,
        priceQuoteInput: {
          ...setPriceQuoteOwnerContactInput,
          quote_owners: r_quote_owners,
        },
      };

    case Types.SET_QUOTE_OWNER_CONTACT_INPUT:
      const setQuoteOwnerContactInput = { ...state.priceQuoteInput };
      const quote_owner = [...state.priceQuoteInput.quote_owners];
      const quote_owners2 = [
        ...state.priceQuoteInput.quote_owners[action.payload.index]
          .quote_owner_contacts,
      ];
      const newOwnerContact = [
        ...state.priceQuoteInput.quote_owners[action.payload.index]
          .quote_owner_contacts,
      ];

      return {
        ...state,
        priceQuoteInput: {
          ...setQuoteOwnerContactInput,

          quote_owner_contacts: [
            ...newOwnerContact,
            {
              email_address: "g@g.com",
              zip_code: "",
              street_line_1: "",
              city: "",
              county_code: "",
              quote_owner: 0,
              country_code: 0,
              locality: "",
            },
          ],
        },
      };

    case Types.GET_PRICE_QUOTE_LIST:
      return {
        ...state,
        priceQuoteList: action.payload,
      };
    case Types.GET_PRICE_QUOTE_DETAILS:
      return {
        ...state,
        priceQuoteDetails: action.payload,
      };
    case Types.AFTER_SUCCESS_PRICE_QUOTE:
      return {
        ...state,
        aftersuccessQuote: action.payload,
      };
    case Types.SET_PRICE_QUOTE_FALSE:
      return {
        ...state,
        aftersuccessQuote: action.payload,
      };

    // -application----------?
    case Types.GET_APPLICATION_LIST:
      return {
        ...state,
        applicationList: action.payload,
      };
    // --------------qualify-------------------

    case Types.GET_QUALIFY_APPLICATION:
      return {
        ...state,
        ApplicationQualify: action.payload,
      };
    case Types.AFTER_SUCCESS_APPLICATION_QUALIFY:
      return {
        ...state,
        afterSuccessApplicationqualify: action.payload,
      };
    case Types.SET_FALSE_APPLICATION_QUALIFY:
      return {
        ...state,
        afterSuccessApplicationqualify: action.payload,
        priceQuoteInput: initialState.priceQuoteInput,
      };

    // -----------------------end qualify----------------------
    // -------------quote-qualify-------------------
    case Types.AFTER_SUCCESS_QUOTE_QUALIFY:
      return {
        ...state,
        afterSuccessQuotequalify: action.payload,
      };
    case Types.SET_FALSE_QUOTE_QUALIFY:
      return {
        ...state,
        afterSuccessQuotequalify: action.payload,
        // priceQuoteInput: initialState.leadInput,
      };
    case Types.QUOTE_QUALIFY_CHECK:
      return {
        ...state,
        checkQuoteQualify: action.payload,
      };
    case Types.SET_FALSE_QUOTE_AFTER_QUALIFY:
      return {
        ...state,
        checkQuoteQualify: action.payload,
        // priceQuoteInput: initialState.leadInput,
      };

    // -----------------------end qualify----------------------

    // ------------------------------quote product and document-------------------
    case Types.ADD_NEW_QUOTE_DOC:
      const setNewDocInput = { ...state.priceQuoteInput };
      const quote_docs = [...state.priceQuoteInput.quote_docs];

      quote_docs[action.payload.index] = {
        ...state.priceQuoteInput.quote_docs[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        priceQuoteInput: { ...setNewDocInput, quote_docs },
      };

    case Types.SET_NEW_QUOTE_DOC_INPUT:
      const setApplicationDocInput = { ...state.priceQuoteInput };
      const newDoc = [...state.priceQuoteInput.quote_docs];

      return {
        ...state,
        priceQuoteInput: {
          ...setApplicationDocInput,
          quote_docs: [
            ...newDoc,
            {
              // id: "",
              category: "PROOF_OF_ID",
              doc_type: "",
              doc: "",
              quote: "",
              is_deleted: false,
              country: 74,
              document_id: "",
              doc_url: "",
              sur_name: "",
              first_name: "",
              issue_date: null,
              expiry_date: null,
              issuer_id: "",
            },
          ],
        },
      };

    case Types.DELETE_QUOTE_DOC:
      const removeDoc = { ...state.priceQuoteInput };
      const removeoptions = [...state.priceQuoteInput.quote_docs];
      const Update_quote = state.priceQuoteInput.quote_docs.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        priceQuoteInput: {
          ...removeDoc,
          quote_docs: [...Update_quote],
        },
      };
    // ============================application product====================
    case Types.GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        allProductList: action.payload,
      };

    case Types.GET_QUOTE_PRODUCT:
      const setApplicationProductInput = { ...state.priceQuoteInput };
      const quote_products = [...state.priceQuoteInput.quote_products];

      quote_products[action.payload.index] = {
        ...state.priceQuoteInput.quote_products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        priceQuoteInput: {
          ...setApplicationProductInput,
          quote_products,
        },
      };

    case Types.SET_QUOTE_PRODUCT:
      const setApplicationProduct = { ...state.priceQuoteInput };
      const newProduct = [...state.priceQuoteInput.quote_products];

      return {
        ...state,
        priceQuoteInput: {
          ...setApplicationProduct,
          quote_products: [
            ...newProduct,
            {
              connection_type: "",
              // contact_length: 18,
              terminal_model: "",
              provider: "",
              qty: 1,
              product: "",
              application: "",
              monthly_price: "",
              is_deleted: false,
              product_term: 18,
            },
          ],
        },
      };
    case Types.REMOVE_QUOTE_PRODUCT:
      const removeProduct = { ...state.priceQuoteInput };

      const updateProducts = state.priceQuoteInput.quote_products.filter(
        (item, index) => {
          return index != action.payload;
        }
      );

      return {
        ...state,
        priceQuoteInput: {
          ...removeProduct,
          quote_products: [
            ...updateProducts,
            // removeoptions.splice(1)
          ],
        },
      };

    case Types.SET_PRICEQUOTE_UPDATED:
      const setPriceQuoteInput = { ...state.priceQuoteInput };
      setPriceQuoteInput.quote_owners = action.payload.quote_owners || [];
      setPriceQuoteInput.quote_owners.quote_owner_contacts =
        action.payload.quote_owners.quote_owner_contacts || [];

      setPriceQuoteInput.quote_docs = action.payload.quote_docs || [];
      setPriceQuoteInput.quote_products = action.payload.quote_products || [];
      setPriceQuoteInput.charity_number = action.payload.charity_number;
      setPriceQuoteInput.security_check = action.payload.security_check;
      setPriceQuoteInput.lead_source = action.payload.lead_source;
      setPriceQuoteInput.lead = action.payload.lead;
      setPriceQuoteInput.client_id = action.payload.client_id;
      setPriceQuoteInput.legal_type = action.payload.legal_type;
      setPriceQuoteInput.internet_service_provider =
        action.payload.internet_service_provider;
      setPriceQuoteInput.payment_service_provider =
        action.payload.payment_service_provider;
      setPriceQuoteInput.existing_mid = action.payload.existing_mid;
      setPriceQuoteInput.lead_stage = action.payload.lead_stage;
      setPriceQuoteInput.lead_status = action.payload.lead_status;
      setPriceQuoteInput.sales_partner = action.payload.sales_partner;
      setPriceQuoteInput.partner_manager = action.payload.partner_manager;
      setPriceQuoteInput.callback_date = action.payload.callback_date;
      setPriceQuoteInput.legal_name = action.payload.legal_name;
      setPriceQuoteInput.trading_name = action.payload.trading_name;
      setPriceQuoteInput.current_ownership_since =
        action.payload.current_ownership_since;
      setPriceQuoteInput.company_house_no = action.payload.company_house_no;
      setPriceQuoteInput.trading_address = action.payload.trading_address;
      setPriceQuoteInput.mcc_code = action.payload.mcc_code;
      setPriceQuoteInput.website = action.payload.website;
      setPriceQuoteInput.note = action.payload.note;
      setPriceQuoteInput.first_name = action.payload.first_name;
      setPriceQuoteInput.last_name = action.payload.last_name;
      setPriceQuoteInput.dob = action.payload.dob;
      setPriceQuoteInput.mobile = action.payload.mobile;
      setPriceQuoteInput.email = action.payload.email;
      setPriceQuoteInput.telephone = action.payload.telephone;
      setPriceQuoteInput.secondary_email = action.payload.secondary_email;
      setPriceQuoteInput.incorporated_on = action.payload.incorporated_on;
      setPriceQuoteInput.legal_postcode = action.payload.legal_postcode;
      setPriceQuoteInput.legal_address1 = action.payload.legal_address1;
      setPriceQuoteInput.legal_address2 = action.payload.legal_address2;
      setPriceQuoteInput.legal_city = action.payload.legal_city;
      setPriceQuoteInput.legal_county = action.payload.legal_county;
      setPriceQuoteInput.legal_country = action.payload.legal_country;

      setPriceQuoteInput.trading_postcode = action.payload.trading_postcode;
      setPriceQuoteInput.trading_city = action.payload.trading_city;
      setPriceQuoteInput.trading_address1 = action.payload.trading_address1;
      setPriceQuoteInput.trading_address2 = action.payload.trading_address2;
      setPriceQuoteInput.trading_county = action.payload.trading_county;
      setPriceQuoteInput.trading_country = action.payload.trading_country;

      setPriceQuoteInput.industry_type = action.payload.industry_type;
      setPriceQuoteInput.user = action.payload.user;
      setPriceQuoteInput.id = action.payload.id;

      setPriceQuoteInput.home_postcode = action.payload.home_postcode;
      setPriceQuoteInput.home_address1 = action.payload.home_address1;
      setPriceQuoteInput.home_address2 = action.payload.home_address2;
      setPriceQuoteInput.home_city = action.payload.home_city;
      setPriceQuoteInput.home_county = action.payload.home_county;
      setPriceQuoteInput.home_country = action.payload.home_country;

      setPriceQuoteInput.visa_debit_sr = action.payload.visa_debit_sr;
      setPriceQuoteInput.visa_debit_non_sr = action.payload.visa_debit_non_sr;
      setPriceQuoteInput.visa_debit_sr_per_tr_fee =
        action.payload.visa_debit_sr_per_tr_fee;
      setPriceQuoteInput.visa_debit_non_sr_per_tr_fee =
        action.payload.visa_debit_non_sr_per_tr_fee;

      setPriceQuoteInput.master_debit_sr = action.payload.master_debit_sr;
      setPriceQuoteInput.master_debit_non_sr =
        action.payload.master_debit_non_sr;
      setPriceQuoteInput.master_debit_sr_per_tr_fee =
        action.payload.master_debit_sr_per_tr_fee;
      setPriceQuoteInput.master_debit_non_sr_per_tr_fee =
        action.payload.master_debit_non_sr_per_tr_fee;

      setPriceQuoteInput.master_credit_sr = action.payload.master_credit_sr;
      setPriceQuoteInput.master_credit_non_sr =
        action.payload.master_credit_non_sr;
      setPriceQuoteInput.master_credit_sr_per_tr_fee =
        action.payload.master_credit_sr_per_tr_fee;
      setPriceQuoteInput.master_credit_non_sr_per_tr_fee =
        action.payload.master_credit_non_sr_per_tr_fee;

      setPriceQuoteInput.visa_business_debit_sr =
        action.payload.visa_business_debit_sr;
      setPriceQuoteInput.visa_business_debit_non_sr =
        action.payload.visa_business_debit_non_sr;
      setPriceQuoteInput.visa_business_debit_sr_per_tr_fee =
        action.payload.visa_business_debit_sr_per_tr_fee;
      setPriceQuoteInput.visa_business_debit_non_sr_per_tr_fee =
        action.payload.visa_business_debit_non_sr_per_tr_fee;

      setPriceQuoteInput.visa_credit_sr = action.payload.visa_credit_sr;
      setPriceQuoteInput.visa_credit_non_sr = action.payload.visa_credit_non_sr;
      setPriceQuoteInput.visa_credit_sr_per_tr_fee =
        action.payload.visa_credit_sr_per_tr_fee;
      setPriceQuoteInput.visa_credit_per_non_sr_tr_fee =
        action.payload.visa_credit_per_non_sr_tr_fee;

      setPriceQuoteInput.visa_purchasing_sr = action.payload.visa_purchasing_sr;
      setPriceQuoteInput.visa_purchasing_non_sr =
        action.payload.visa_purchasing_non_sr;
      setPriceQuoteInput.visa_purchasing_sr_per_tr_fee =
        action.payload.visa_purchasing_sr_per_tr_fee;
      setPriceQuoteInput.visa_purchasing_non_sr_per_tr_fee =
        action.payload.visa_purchasing_non_sr_per_tr_fee;

      setPriceQuoteInput.visa_corporate_sr = action.payload.visa_corporate_sr;
      setPriceQuoteInput.visa_corporate_sr_per_tr_fee =
        action.payload.visa_corporate_sr_per_tr_fee;
      setPriceQuoteInput.visa_corporate_non_sr =
        action.payload.visa_corporate_non_sr;
      setPriceQuoteInput.visa_corporat_non_sr_per_tr_fee =
        action.payload.visa_corporat_non_sr_per_tr_fee;

      setPriceQuoteInput.master_purchasing_sr =
        action.payload.master_purchasing_sr;
      setPriceQuoteInput.master_purchasing_sr_per_tr_fee =
        action.payload.master_purchasing_sr_per_tr_fee;
      setPriceQuoteInput.master_purchasing_non_sr =
        action.payload.master_purchasing_non_sr;
      setPriceQuoteInput.master_purchasing_non_sr_per_tr_fee =
        action.payload.master_purchasing_non_sr_per_tr_fee;

      setPriceQuoteInput.master_fleet_sr = action.payload.master_fleet_sr;
      setPriceQuoteInput.master_fleet_sr_per_tr_fee =
        action.payload.master_fleet_sr_per_tr_fee;

      setPriceQuoteInput.master_fleet_non_sr =
        action.payload.master_fleet_non_sr;
      setPriceQuoteInput.master_fleet_non_sr_per_tr_fee =
        action.payload.master_fleet_non_sr_per_tr_fee;

      setPriceQuoteInput.master_corporate_sr =
        action.payload.master_corporate_sr;
      setPriceQuoteInput.master_corporate_non_sr =
        action.payload.master_corporate_non_sr;
      setPriceQuoteInput.master_corporate_sr_per_tr_fee =
        action.payload.master_corporate_sr_per_tr_fee;
      setPriceQuoteInput.master_corporate_non_sr_per_tr_fee =
        action.payload.master_corporate_non_sr_per_tr_fee;

      setPriceQuoteInput.master_pre_commercial_sr =
        action.payload.master_pre_commercial_sr;
      setPriceQuoteInput.master_pre_commercial_sr_per_tr_fee =
        action.payload.master_pre_commercial_sr_per_tr_fee;
      setPriceQuoteInput.master_pre_commercial_non_sr =
        action.payload.master_pre_commercial_non_sr;
      setPriceQuoteInput.master_pre_commercial_non_sr_per_tr_fee =
        action.payload.master_pre_commercial_non_sr_per_tr_fee;

      setPriceQuoteInput.non_eea_visa_sr = action.payload.non_eea_visa_sr;
      setPriceQuoteInput.non_eea_visa_sr_per_tr_fee =
        action.payload.non_eea_visa_sr_per_tr_fee;

      setPriceQuoteInput.non_eea_visa_non_sr =
        action.payload.non_eea_visa_non_sr;
      setPriceQuoteInput.non_eea_visa_non_sr_per_tr_fee =
        action.payload.non_eea_visa_non_sr_per_tr_fee;

      setPriceQuoteInput.non_eea_master_sr = action.payload.non_eea_master_sr;
      setPriceQuoteInput.non_eea_master_sr_per_tr_fee =
        action.payload.non_eea_master_sr_per_tr_fee;
      setPriceQuoteInput.non_eea_master_non_sr =
        action.payload.non_eea_master_non_sr;
      setPriceQuoteInput.non_eea_master_non_sr_per_tr_fee =
        action.payload.non_eea_master_non_sr_per_tr_fee;

      setPriceQuoteInput.diners_sr = action.payload.diners_sr;
      setPriceQuoteInput.jcb_sr = action.payload.jcb_sr;
      setPriceQuoteInput.union_pay_sr = action.payload.union_pay_sr;

      setPriceQuoteInput.master_business_sr = action.payload.master_business_sr;
      setPriceQuoteInput.master_business_non_sr =
        action.payload.master_business_non_sr;
      setPriceQuoteInput.master_business_sr_per_tr_fee =
        action.payload.master_business_sr_per_tr_fee;
      setPriceQuoteInput.master_business_non_sr_per_tr_fee =
        action.payload.master_business_non_sr_per_tr_fee;

      setPriceQuoteInput.visa_business_credit_sr =
        action.payload.visa_business_credit_sr;
      setPriceQuoteInput.visa_business_credit_non_sr =
        action.payload.visa_business_credit_non_sr;
      setPriceQuoteInput.visa_business_credit_sr_per_tr_fee =
        action.payload.visa_business_credit_sr_per_tr_fee;
      setPriceQuoteInput.visa_business_credit_non_sr_per_tr_fee =
        action.payload.visa_business_credit_non_sr_per_tr_fee;

      setPriceQuoteInput.visa_v_pay_sr = action.payload.visa_v_pay_sr;
      setPriceQuoteInput.visa_v_pay_non_sr = action.payload.visa_v_pay_non_sr;
      setPriceQuoteInput.visa_v_pay_sr_per_tr_fee =
        action.payload.visa_v_pay_sr_per_tr_fee;
      setPriceQuoteInput.visa_v_pay_non_sr_per_tr_fee =
        action.payload.visa_v_pay_non_sr_per_tr_fee;

      setPriceQuoteInput.uk_maestro_sr_per_tr_fee =
        action.payload.uk_maestro_sr_per_tr_fee;
      setPriceQuoteInput.uk_maestro_sr = action.payload.uk_maestro_sr;
      setPriceQuoteInput.uk_maestro_non_sr = action.payload.uk_maestro_non_sr;
      setPriceQuoteInput.uk_maestro_non_sr_per_tr_fee =
        action.payload.uk_maestro_non_sr_per_tr_fee;

      setPriceQuoteInput.international_maestro_sr =
        action.payload.international_maestro_sr;
      setPriceQuoteInput.international_maestro_non_sr =
        action.payload.international_maestro_non_sr;
      setPriceQuoteInput.international_maestro_sr_per_tr_fee =
        action.payload.international_maestro_sr_per_tr_fee;
      setPriceQuoteInput.international_maestro_non_sr_per_tr_fee =
        action.payload.international_maestro_non_sr_per_tr_fee;

      setPriceQuoteInput.mmsc = action.payload.mmsc;
      setPriceQuoteInput.auth_fees = action.payload.auth_fees;

      setPriceQuoteInput.acquiring_bank = action.payload.acquiring_bank;
      setPriceQuoteInput.annual_card_turnover =
        action.payload.annual_card_turnover;
      setPriceQuoteInput.annual_turnover = action.payload.annual_turnover;
      setPriceQuoteInput.atv = action.payload.atv;
      setPriceQuoteInput.smtv = action.payload.smtv;
      setPriceQuoteInput.bank_name = action.payload.bank_name;
      setPriceQuoteInput.bank_sort_code = action.payload.bank_sort_code;

      setPriceQuoteInput.opportunity_name = action.payload.opportunity_name;
      setPriceQuoteInput.opportunity_stage = action.payload.opportunity_stage;
      setPriceQuoteInput.opportunity_id = action.payload.opportunity_id;
      setPriceQuoteInput.lead_source = action.payload.lead_source;
      setPriceQuoteInput.application_type = action.payload.application_type;
      setPriceQuoteInput.price_quote_date = action.payload.price_quote_date;

      setPriceQuoteInput.est_close_date = action.payload.est_close_date;
      setPriceQuoteInput.opportunity_status = action.payload.opportunity_status;
      setPriceQuoteInput.phone_code = action.payload.phone_code;
      setPriceQuoteInput.mobile_code = action.payload.mobile_code;
      setPriceQuoteInput.bank_account_name = action.payload.bank_account_name;
      setPriceQuoteInput.bank_account_no = action.payload.bank_account_no;

      setPriceQuoteInput.security_other_check =
        action.payload.security_other_check;
      setPriceQuoteInput.security_max_amt_check =
        action.payload.security_max_amt_check;
      setPriceQuoteInput.security_ip_geo_check =
        action.payload.security_ip_geo_check;
      setPriceQuoteInput.security_velocity_check =
        action.payload.security_velocity_check;
      setPriceQuoteInput.security_bin_check = action.payload.security_bin_check;
      setPriceQuoteInput.payment_service_provider =
        action.payload.payment_service_provider;
      setPriceQuoteInput.internet_service_provider =
        action.payload.internet_service_provider;
      setPriceQuoteInput.site_and_trading_address_same =
        action.payload.site_and_trading_address_same;
      return {
        ...state,
        priceQuoteInput: setPriceQuoteInput,
      };

    default:
      break;
  }
  return newState;
};

// --------------------rakib--------------------

const PaginationInitial = {
  name: "",
  limit: {
    lead: 20,
    priceQuote: 20,
    applicationList: 20,
  },
  IsLoading: {
    lead: true,
    priceQuote: true,
    applicationList: true,
  },
  data: {
    lead: [],
    priceQuote: [],
    applicationList: [],
  },
  error: {
    lead: null,
    priceQuote: null,
    applicationList: null,
  },
  isLoadLeads: {
    lead: false,
    priceQuote: false,
    applicationList: false,
  },
  url: {
    lead: "",
    priceQuote: "",
    applicationList: "",
  },
  pageNumber: {
    lead: 1,
    priceQuote: 1,
    applicationList: 1,
  },
};

export const PaginationReducer = (state = PaginationInitial, action) => {
  switch (action.type) {
    case Types.PAGINATION_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case Types.PAGINATION_lIMIT:
      return {
        ...state,
        limit: {
          ...state.limit,
          [state.name]: action.payload,
        },
      };

    case Types.IS_LOAD_LEADS:
      return {
        ...state,
        isLoadLeads: {
          ...state.isLoadLeads,
          [state.name]: action.payload,
        },
      };

    case Types.PAGINATION_LOADING:
      return {
        ...state,
        IsLoading: {
          ...state.IsLoading,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_URL:
      return {
        ...state,
        url: {
          ...state.url,
          [state.name]: action.payload,
        },
      };
    case Types.PAGINATION_NEXT:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]: state.pageNumber[state.name] + 1,
        },
      };
    case Types.PAGINATION_PREVIOUS:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]:
            state.pageNumber[state.name] > 1
              ? state.pageNumber[state.name] - 1
              : state.pageNumber[state.name],
        },
      };

    case Types.PAGINATION_CHANGE_CURRENT:
      return {
        ...state,
        pageNumber: {
          ...state.pageNumber,
          [state.name]: action.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

// -----------OFFSET PAGINATION-----------------

export default LeadsReducer;
