import React from "react";

export const SortArrayOfObjects = (givenArr, is_ascending, sort_by) => {
  if (givenArr === null) {
    return;
  }
  let copiedLeadsList = [...givenArr];
  let sortedArr = [];
  const object_sort_by = (object, sort_by) => {
    return object[sort_by] == null ? "" : object[sort_by];
  };
  if (is_ascending === 1) {
    sortedArr =
      copiedLeadsList.length !== 0 &&
      copiedLeadsList.sort((object1, object2) => object_sort_by(object1, sort_by).localeCompare(object_sort_by(object2, sort_by)));
  } else if (is_ascending === 0) {
    sortedArr =
      copiedLeadsList.length !== 0 &&
      copiedLeadsList.sort((object1, object2) => object_sort_by(object2, sort_by).localeCompare(object_sort_by(object1, sort_by)));
  } else if (is_ascending === -1) {
    return givenArr;
  }
  return sortedArr;
};

export const handleAscendingSort = (name, SortingData, setSortingData) => {
  SortingData.ascending === 1
    ? setSortingData(() => {
        return {
          ascending: -1,
          sort_by: name,
        };
      })
    : setSortingData(() => {
        return {
          ascending: 1,
          sort_by: name,
        };
      });
};
export const handleDescendingSort = (name, SortingData, setSortingData) => {
  SortingData.ascending === 0
    ? setSortingData(() => {
        return {
          ascending: -1,
          sort_by: name,
        };
      })
    : setSortingData(() => {
        return {
          ascending: 0,
          sort_by: name,
        };
      });
};
