import React, { useState } from "react";
import "./Document.css";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";

import { useDispatch, useSelector } from "react-redux";


const AplicationErrors = () => {
 
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
 

  return (
    <div
      style={{ marginTop: "40px", marginBottom: "40px" }}
      className="buisness-detail customar-detail w-100"
    >
      <div
        className="customar-detail-head w-100 fees-box"
        style={{ background: "red" }}
      >
        <div className="head-first">
          <img src={detailIcon} alt="" />
          <h4 style={{ color: "white" }}>Errors</h4>
        </div>
        
      </div>
      <div className="row m-2">
        {applicationInput.application_errors !== null && applicationInput?.application_errors?.split(",").map((err, i) => {
          return (
            <div className="" key={i} style={{textAlign:"justify", padding:"8px 20px"}}>
                <h5 style={{color:"red"}}>
                {i}. 
                <span style={{color:"gray"}}> {" "}{err}</span>
                </h5>
              
            </div>
          );
          // {err}
        })}
      </div>
    </div>
  );
};
export default AplicationErrors;
