import { useState } from "react";
import { handleAscendingSort, handleDescendingSort } from "./Utils";

const SvgIcon = ({name,setSortingData,SortingData }) => {
  const [fillArrow, SetfillArrow] = useState({
    up: 0,
    down: 0,
  });
  return (
    <>
      <div className="updown">
        <svg
          onClick={() => {
            fillArrow.up === 0
              ? SetfillArrow({
                  up: 1,
                  down: 0,
                })
              : SetfillArrow({
                  up: 0,
                  down: 0,
                });

            return handleAscendingSort(name,SortingData,setSortingData);
          }}
          className={`${fillArrow.up ? "arrow_is_active" : ""} pointer`}
          width="10"
          height="8"
          viewBox="0 0 10 6"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 6L5 0L10 6H0Z" fill="currentColor" />
        </svg>

        <svg
          onClick={() => {
            fillArrow.down === 1
              ? SetfillArrow({
                  up: 0,
                  down: 0,
                })
              : SetfillArrow({
                  up: 0,
                  down: 1,
                });
            return handleDescendingSort(name,SortingData,setSortingData);
          }}
          className={`${fillArrow.down ? "arrow_is_active" : ""} pointer`}
          width="10"
          height="8"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 0L5 6L0 0H10Z" fill="currentColor" />
        </svg>
      </div>
    </>
  );
};

export default SvgIcon