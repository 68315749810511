import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";


import swal from "sweetalert";
import { BASE_URL } from "../../../../Const";




export const GetUserList = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/auth/user/?is_=true`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_USER_LIST,
      
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_USER, payload: true });
};

export const NextUserListList = (nextUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const url = nextUrl
  // dispatch({ type: Types.IS_LOAD_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_USER_LIST,
          payload: res.data.data,
        });

        
      }
    });
  } catch (error) {

  }

};

export const PreviousUserList = (prevUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const url = `${BASE_URL}api/v1/products/admin/order/?offset=${}&limit=100`;
  const url = prevUrl
  dispatch({ type: Types.IS_LOAD_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_USER_LIST,
          payload: res.data.data,
        });

        
        
        dispatch({ type: Types.IS_LOAD_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_USER, payload: true });
};
export const GetUserInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_USER_INPUT, payload: formData });
};
export const SetUserStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_USER_FALSE, payload: false });
};
export const SubmitUserData = (data) => async (dispatch) => {
  var expression =
  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
var regex = new RegExp(expression);
let regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // const newData = { ...data };
  if (!data.password) delete data.password;
console.log('data', data)
  if (!data.first_name ) {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (!data.last_name ) {
    showToast("error", "Last Name shouldn't be empty !");
    return 0;
  }
 
   else if (!data.mobile ) {
      showToast("error", "Mobile no level shouldn't be empty !");
      return 0;
    } 
    else if (data.role === "" || data.role === null ) {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }
   else if (!data.email ) {
      showToast("error", "Email shouldn't be empty !");
      return 0;
    } 
    if (!regEmail.test(data.email)) {
      showToast("error", "Enter a vaild email ");
      return 0;
    }
   else if (!data.password ) {
      showToast("error", "Password shouldn't be empty !");
      return 0;
    } 
    else if (!data.upfront_percentage ) {
      showToast("error", "Upfront Percentage shouldn't be empty !");
      return 0;
    }
    else if (!data.residual_percentage ) {
      showToast("error", "Residual Percentage shouldn't be empty !");
      return 0;
    }


  const url = `${BASE_URL}api/v1/auth/user/`;
  dispatch({ type: Types.IS_LOAD_USER, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_USER, payload: false });
          showToast("success", " User  added successfully");
          // dispatch(GetUserList());
          dispatch({ type: Types.AFTER_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateuserdData = (data) => async (dispatch) => {
  const newData = { ...data };
  if (!newData.password) delete newData.password;
console.log('newData', newData, data)
 
  if (!newData.first_name ) {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (!newData.last_name ) {
    showToast("error", "Last Name shouldn't be empty !");
    return 0;
  }
 
   else if (!newData.mobile ) {
      showToast("error", "Mobile no level shouldn't be empty !");
      return 0;
    } 
    else if (newData.role === "" || newData.role === null ) {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }
   else if (!newData.email ) {
      showToast("error", "Email shouldn't be empty !");
      return 0;
    } 
    else if (!newData.upfront_percentage ) {
      showToast("error", "Upfront Percentage shouldn't be empty !");
      return 0;
    }
    else if (!data.residual_percentage ) {
      showToast("error", "Residual Percentage shouldn't be empty !");
      return 0;
    }

  const url = `${BASE_URL}api/v1/auth/user/${newData.id}/`;
  dispatch({ type: Types.IS_LOAD_USER, payload: true });

  try {
    await Axios.patch(url, newData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_USER, payload: false });
          showToast("success", " User updated successfully");
          // dispatch(GetVerifiedUserList());
          dispatch({ type: Types.AFTER_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetUserUpdatedData = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/auth/user/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.SET_USER_UPDATED,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
export const GetuserDetails = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/auth/user/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_USER_DETAILS,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
// export const GetVerifiedDetails = (id) => (dispatch) => {
//     const url = `${BASE_URL}api/v1/auth/user/${id}/`;
//     try {
//       Axios.get(url).then((res) => {
//         if (res.data.status) {
//           dispatch({
//             type: Types.GET_VERIFIED_USER_DETAILS,
//             payload: res.data.data,
//           });
//         }
//       });
//     } catch (error) {}
//   };
// --// --------------delete------------
export const Userdeleted = (id, api) => (dispatch) => {
    const url = `${BASE_URL}api/v1/auth/user/${id}/`;
  
    try {
      Axios.delete(url).then((res) => {
        if (res.data.status) {
          showToast("error", 'User Deleted successfully');
          dispatch({ type: Types.IS_USER_DELETED, payload: true });
          dispatch(GetUserList(api))
        }
      });
    } catch (error) {}
  };
  export const SetFalseObjectDelete = () => (dispatch) => {
    dispatch({ type: Types.IS_USER_DELETED, payload: false });
  };
  
