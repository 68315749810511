import React from "react";
import { useSelector } from "react-redux";
import "./Pdf.css";
import paymentSave from "./images/Payment-Save.svg";
import direct_debit from "./images/direct_debit.png";
import elavon_transparent from "./images/elavon_transparent.png";
const LastPdf = () => {
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const countryList = useSelector((state) => state.leadInfo.countryList);

  const currentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");

  const dateFunction = (dateVal) => {
    const date = dateVal?.split("-");
    let val = `${date[2]}/${date[1]}/${date[0]}`;
    return val;
  };

  const ThirdPrtyproductTypeCount = () => {
    let arr = [];
    applicationInput.application_products.map((item) => {
      if (item.provider === 1) {
        arr.push(item);
      }
    });
    return arr;
  };
  const ElavonProductTypeCount = () => {
    let arr = [];
    applicationInput.application_products.map((item) => {
      if (item.provider === 0) {
        arr.push(item);
      }
    });
    return arr;
  };
  return (
    <div className="pdf">
      <form className="normal uk " id="content">
        {/* New Page 1 */}

        <div className="footer page-break">
          <p style={{ color: "#000" }}>
            <strong>
              Elavon Financial Services DAC <br /> Registered in Ireland with
              Companies Registration Office. The liability of the member is
              limited. <br /> United Kingdom branch registered in England and
              Wales under the number BR022122. <br />
              Elavon Financial Services DAC, trading as Elavon Merchant Services
              is authorised and regulated by the Central Bank of Ireland.
              Authorised by the Prudential Regulation <br /> Authority and with
              deemed variation of permission. Subject to regulation by the
              Financial Conduct Authority and limited regulation by the
              Prudential Regulation Authority.
              <br />
              Details of the Temporary Permissions Regime, which allows
              EEA-based firms to operate in the UK for a limited period while
              seeking full authorisation, are available on the <br /> Financial
              Conduct Authority's website.
            </strong>
          </p>{" "}
          <img src={elavon_transparent} width={125} height="auto" alt="Elavon" />
        </div>
        {/* New page 1 End */}
        {/* New Page 2nd */}
        <section style={{}}>
          <div style={{ display: "flex", justifyContent: "space-between",marginTop:'30px' }}>
            <div>
              <h3 style={{ fontSize: "24px", margin: 0 }}>
                Services Agreement
              </h3>
              <p>
                <strong>The services and devices are provided by:</strong>
              </p>
            </div>
            <div>
              <img
                src={paymentSave}
                alt="Payment-Save"
                width="150px"
                height="auto"
              />
            </div>
          </div>
          <div>
            <div
              className="pdf_row"
              style={{
                borderTop: "1px solid #777374",
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                {" "}
                <text>Service Provider: (“we” or “us”)</text>
              </div>
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Paymentsave(UK) Limited</text>
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Help Desk:</text>
                </div>
              </div>
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">01634 540 453 </text>
                </div>
              </div>
            </div>
          </div>
          {/* Key Information */}
          <div>
            <div style={{ margin: "25px 0" }}>
              <h6 style={{ fontSize: "20px", margin: 0 }}>Key information</h6>
            </div>
          </div>
          <div>
            <div
              className="pdf_row"
              style={{
                borderTop: "1px solid #777374",
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                {" "}
                <text>Merchant (“you”, “your”) Business Name</text>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Trading Name (if different)</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Address Line 1</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Address Line 1</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Town/City</text>
                </div>
              </div>
              <div className="cols-1 blue">
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
              <div className="cols-2" style={{ padding: "10px 5px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">County:</text>
                </div>
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Postcode</text>
                </div>
              </div>
              <div className="cols-1 blue">
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
              <div className="cols-2" style={{ padding: "10px 5px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Country:</text>
                </div>
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">United Kingdom</text>
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Business Phone number</text>
                </div>
              </div>
              <div className="cols-1 blue">
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
              <div className="cols-2" style={{ padding: "10px 5px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Years in Business:</text>
                </div>
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Business URL</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Email Address</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Company Registration Number</text>
                </div>
              </div>
              <div className="cols-1 blue">
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
              <div className="cols-2" style={{ padding: "10px 5px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">VAT No:</text>
                </div>
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Contact Name</text>
                </div>
              </div>
              <div className="cols-1 blue">
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
              <div className="cols-2" style={{ padding: "10px 5px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Position</text>
                </div>
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Contact Phone Number</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1">Installation Address</text>
                </div>
              </div>
              <div className="cols-5 blue" style={{ padding: "10px" }}>
                <div className="pdf_input">
                  <text id="sfnc2_s2_q1" />
                </div>
              </div>
            </div>
          </div>
          {/* Key Information End */}
          {/* Description of Equipment start */}
          <div>
            <div style={{ margin: "25px 0" }}>
              <h6 style={{ fontSize: "20px", margin: 0 }}>
                Description of Equipment
              </h6>
            </div>
          </div>
          <div>
            <div
              className="pdf_row"
              style={{
                borderTop: "1px solid #777374",
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div
                className="cols-2"
                style={{ padding: "10px", fontWeight: 600 }}
              >
                {" "}
                <text>Serial Number</text>
              </div>
              <div
                className="cols-3"
                style={{ padding: "10px", fontWeight: 600 }}
              >
                {" "}
                <text>Equipment Model</text>
              </div>
              <div
                className="cols-3"
                style={{ padding: "10px", fontWeight: 600 }}
              >
                {" "}
                <text>Manufacturer</text>
              </div>
              <div
                className="cols-2"
                style={{ padding: "10px", fontWeight: 600 }}
              >
                {" "}
                <text>Quantity</text>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-3 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
              <div className="cols-2 blue" style={{ padding: "10px" }}>
                {" "}
                <text />
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-10" style={{ padding: "10px" }}>
                <p>Minimum Term: _________________ (months)</p>
                <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                  <li>
                    - If this Agreement ends before the end of the Minimum Term,
                    the Termination Payment in accordance with clause 8.2 may be
                    payable.
                  </li>
                  <li>
                    - At the end of the Minimum Term this Agreement will
                    automatically renew and continue in accordance with clause
                    1.
                  </li>
                  <li>
                    - All charges related to this Agreement shall be paid in
                    accordance with clause 4.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="pdf_row"
              style={{
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <p>
                  On-site Installation Payment: <strong>£</strong>{" "}
                  ______________ exc. VAT
                </p>
              </div>
              <div className="cols-5" style={{ padding: "10px" }}>
                <p>
                  Total Monthly Payment: <strong>£</strong> _______________ exc.
                  VAT
                </p>
              </div>
            </div>
          </div>
          {/* Description of Equipment end */}
          {/* Signature Start */}
          <div style={{ marginTop: "25px" }}>
            <div
              className="pdf_row"
              style={{
                borderTop: "1px solid #777374",
                borderLeft: "1px solid #777374",
                borderRight: "1px solid #777374",
                display: "flex",
              }}
            >
              <div className="cols-5" style={{ padding: "10px" }}>
                <h5 style={{ margin: 0, fontSize: "18px" }}>
                  Signature of Merchant: <text />{" "}
                </h5>
                <p>
                  <strong>Signed for &amp; on behalf of the Merchant</strong>
                </p>
                <p>
                  <strong>Print Name: </strong> <text />{" "}
                </p>
                <p>
                  <strong>Position in Company:</strong> <text />{" "}
                </p>
                <h5 style={{ margin: 0, fontSize: "18px" }}>
                  Position in Company: <text />{" "}
                </h5>
              </div>
              <div className="cols-5" style={{ padding: "10px" }}>
                <h5 style={{ margin: 0, fontSize: "18px" }}>
                  Signature of the Service Provider: <text />{" "}
                </h5>
                <p>
                  <strong>
                    Signed for and on Behalf of the Service Provider
                  </strong>
                </p>
                <div className="pdf_input">
                  {" "}
                  <text />{" "}
                </div>
              </div>
            </div>
          </div>
          {/* Signature end */}
        </section>
        {/* Terms and Conditions */}
        <section>
          <div style={{ margin: "25px 0" }}>
            <h3 style={{ margin: 0, fontSize: "25px", textAlign: "center" }}>
              Terms and Conditions
            </h3>
          </div>
          <table style={{ textAlign: "justify" }}>
            <tbody>
              <tr>
                <td style={{ padding: "0 10px 0 0", width: "50%" }}>
                  <p>
                    <strong>1. Term</strong>
                  </p>
                  <p>
                    <strong>1.1</strong> The Agreement will take effect on the
                    date on which you receive the Equipment and will continue
                    for the Minimum Term. Thereafter the Agreement will continue
                    month to month (the “Renewal Period”) unless and until
                    terminated in accordance with clause 8.
                  </p>
                  <p>
                    <strong>2. Services</strong>
                  </p>
                  <p>
                    <strong> 2.1</strong> We will provide the Services and
                    otherwise perform our obligations under the Agreement: (a)
                    in good faith and in a timely and professional manner by
                    using at all times appropriately experienced, knowledgeable,
                    qualified and trained staff exercising reasonable care and
                    skill; (b) in accordance with all applicable provisions of
                    the Agreement and all laws, rules and regulations regarding
                    the provision of the Services.
                  </p>
                  <p>
                    <strong>2.2</strong> The Services shall include a Call
                    Centre Helpdesk during the hours 09:00hrs to 18:00hrs Monday
                    to Sunday and Bank Holidays (excluding Christmas Day)] for
                    the duration of the Agreement, the operation of a Terminal
                    Management System for the monitoring of the Equipment to
                    facilitate application and configuration downloads and
                    status monitoring (where/when applicable). Deployment and
                    Replacement Services and Installation Services where/if
                    requested.
                  </p>
                  <p>
                    <strong>2.3</strong> The Equipment is provided at no
                    additional cost or charge other than the Charges related to
                    the Services for the purpose of carrying out Transactions
                    and in consideration of your commitment for the Minimum
                    Term. We will supply the Equipment identified on page one of
                    the Agreement, which shall include all accessories and also
                    any additions or replacements made to the Equipment)
                    pursuant to the terms and conditions of the Agreement. We
                    retain ownership of all Equipment and may supply Equipment
                    of a different model than those stated on Page 1 of the
                    Agreement; provided that the model(s) supplied are of equal
                    specification and configuration.
                  </p>
                  <p>
                    <strong>2.4</strong> We will configure the Equipment to work
                    with your chosen Acquirer services provider at the time the
                    Equipment is supplied to you, and therefore, it may not be
                    compatible with other Acquirer systems. We have no expertise
                    or obligation to: (i) conform the Equipment with that of any
                    other Acquirer; or (ii) modify or replace the Equipment to
                    ensure its compliance with industry or security standards.
                    In the event that you move to an alternative Acquirer
                    (whether it be at your own will or as a result of your
                    Acquirer withdrawing its facilities and closing your
                    account), you will continue to be bound by the Agreement
                    irrespective of whether the Equipment can be transferred to
                    the new Acquirer's systems.
                  </p>
                  <p>
                    <strong>2.5</strong> If the Equipment fails due to a
                    manufacturing, mechanical or electronic defect we will
                    provide an equivalent replacement within 24 hours, this is a
                    target response and we do not guarantee replacement within
                    this period. You shall at all times remain responsible for
                    the Charges under the Agreement. If you fail to take
                    delivery of the replacement Equipment on the confirmed
                    delivery date we may charge you a reasonable re-delivery
                    fee, as advised at the relevant time.
                  </p>
                  <p>
                    <strong>2.6</strong> We may, if we consider it necessary to
                    do so, and for such period or periods as it may deem
                    expedient, replace the Equipment with alternative Equipment
                    of the same or similar type as the original Equipment. The
                    Equipment so substituted shall then be subject to the terms
                    and conditions of the Agreement. The Equipment has been
                    supplied to you in conjunction with the Equipment
                    configuration requested by you. If you request changes to
                    the Equipment for the provision of additional facilities and
                    we incur any related costs from the Manufacturer or other
                    third party, we will pass such costs to you and collect them
                    by direct debit.
                  </p>
                  <p>
                    <strong> 2.7</strong> All ownership and copyright interest
                    in and to all computer software, related documentation,
                    technology, know-how and processes embodied in or provided
                    in connection with the Equipment (collectively, the
                    "Software") shall remain in our name or our licensors, and
                    we hereby grant to you a nonexclusive license to use the
                    Software in conjunction with its operation of the Equipment.
                  </p>
                  <p>
                    <strong>3. Site preparation</strong>
                  </p>
                  <p>
                    <strong>3.1</strong> You must prepare the site(s) for the
                    Equipment in accordance with our instructions.
                  </p>
                  <p>
                    <strong>3.2</strong> If you arrange for the installation of
                    the Equipment yourself, you must do so in accordance with
                    our instructions.
                  </p>
                  <p>
                    <strong>3.3</strong> If you ask us to arrange for the
                    installation of the Equipment (for which an Installation
                    Payment is payable), you must take delivery of the Equipment
                    on the confirmed installation date. Failure to do so may
                    result in a re-delivery fee.
                  </p>
                </td>
                <td style={{ padding: "0 0 0 10px", width: "50%" }}>
                  <p>
                    <strong>3.4</strong> You are responsible for (a) all
                    necessary infrastructure (power and telecommunications
                    links, internet access) required to operate the Equipment;
                    and (b) making your own assessment of any Bluetooth or other
                    technological constraints on the site which may impact the
                    operation of the Equipment.
                  </p>
                  <p>
                    <strong>3.5</strong> We do not make or give any
                    representation, stipulation or undertaking regarding the
                    suitability of the Services for your business.
                  </p>
                  <p>
                    <strong>4. Charges</strong>
                  </p>
                  <p>
                    <strong>4.1</strong> You must pay the Charges in advance and
                    in the amounts and with the frequency as specified on page
                    one of this Agreement.
                  </p>
                  <p>
                    <strong>4.2</strong> The Charges will be fixed during the
                    Minimum Term, but thereafter may be subject to change by us
                    at any time following thirty (30) days' written notice.
                  </p>
                  <p>
                    <strong>4.3</strong> All sums payable under the Agreement
                    are exclusive of value added tax and any other taxes,
                    charges or duties to which the supply of goods or services
                    may be subject or give rise.
                  </p>
                  <p>
                    <strong>4.4</strong> If any Charges are not paid in full
                    when due, we may charge interest on such Charges at a rate
                    of 2% per month from the due date to the date of payment,
                    such interest to be compounded monthly and to run from day
                    to day, whether before or after any judgment.
                  </p>
                  <p>
                    {" "}
                    <strong>4.5</strong> The following Default Charges will
                    apply to the Services:
                  </p>
                  <p>
                    a) Administrative charge of £20 for each direct debit,
                    cheque or other method of payment that is returned unpaid
                    for any reason
                  </p>
                  <p>
                    {" "}
                    b) Administrative charge of £5 for each missed payment and
                    we contact you to request payment
                  </p>
                  <p>
                    <strong>4.6</strong> You may not withhold payment of any sum
                    by reason of any set-off of any claim or dispute with us
                    whether relating to the quality or performance of the
                    Services or otherwise.
                  </p>
                  <p>
                    <strong>5. Your obligations</strong>
                  </p>
                  <p>
                    You must: (a) obtain all permits and comply with all laws,
                    rules and regulations relating to your use of Services; (b)
                    ensure that the Equipment are operated by competent and
                    qualified personnel in accordance with all relevant
                    operating instructions; (c) maintain the Equipment in good
                    operating condition (fair wear and tear accepted); (d) keep
                    the Equipment adequately insured for the cost of its repair
                    and full replacement value during the Minimum Term and each
                    Renewal Period until the termination of the Agreement; (e)
                    not permit any physical alteration or modification of the
                    Equipment or change the site of the Equipment, in each case
                    without our prior written consent; (f) not create, incur,
                    assume or allow to exist any consensually or judicially
                    imposed liens or encumbrances on, or part with possession
                    of, or sublease the Equipment without our prior written
                    consent; (g) not tamper with labels or stencils on the
                    Equipment evidencing our ownership; and (h) permit us or our
                    representatives to enter your premises during normal
                    business hours or otherwise for purposes of inspecting,
                    testing or repairing the Equipment.
                  </p>
                  <p>
                    <strong>6. Warranties and limits of liability</strong>
                  </p>
                  <p>
                    <strong>6.1</strong> Subject to clause 6.3, the total
                    liability of either party under or in connection with the
                    Agreement in respect of the year in which the liability
                    arises, whether arising in negligence, contract or
                    otherwise, will in no event exceed the lesser of either: (i)
                    the aggregate amount of the Charges paid and payable by you
                    to us under the Agreement during the twelve (12) months
                    immediately prior to the date on which the cause of action
                    first arose; or (ii) £25,000.
                  </p>
                  <p>
                    <strong>6.2</strong> Subject to clause 6.3, neither party
                    will be liable (whether in negligence, contract or
                    otherwise) for any loss of profits, income, revenue,
                    business, reputation or goodwill, loss of data, opportunity
                    or any type of special, indirect or consequential loss, even
                    if such loss was reasonably foreseeable or such party has
                    been advised of the possibility of incurring the same.
                  </p>
                  <p>
                    <strong>6.3</strong> Nothing in the Agreement excludes or
                    limits the liability of either party for: (a) death or
                    personal injury caused by its negligence; (b) breach of any
                    obligation regarding title implied by Section 7 of the
                    Supply of Goods and Services Act 1982; (c) fraud or
                    fraudulent misrepresentation; or (d) any other liability
                    which cannot be excluded by law.
                  </p>
                  <p>
                    <strong>6.4</strong> Except as expressly provided in the
                    Agreement, all conditions, warranties or other terms which
                    might have effect between the parties or be implied or
                    incorporated into the Agreement or any collateral contract,
                    whether by statute, common law or otherwise, are hereby
                    excluded.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 10px 0 0", width: "50%" }}>
                  <p>
                    <strong>7. Indemnity</strong>
                  </p>
                  <p>
                    You will fully indemnify and hold us harmless from and
                    against any and all losses, liabilities, damages and
                    expenses (including legal fees) which we incur as a result
                    of or in connection with: (a) any breach by you of any of
                    your obligations under the Agreement, except to the extent
                    any losses, liabilities, damages or expenses result from our
                    negligence or willful misconduct; (b) the enforcement of any
                    provision of the Agreement or recovery of any payment due
                    under the Agreement by us; (c) any damage to and/or
                    malfunction of the Equipment whilst in your possession other
                    any defect listed under clause 2.5; (d) our collection or
                    recovery of the Equipment.
                  </p>
                  <p>8. Suspension and termination</p>
                  <p>
                    <strong>8.1</strong> We may at any time immediately suspend
                    your right to access or use any part or all of the Services,
                    without liability to you and in addition to any other rights
                    and remedies we may have, if any Charges are overdue and
                    outstanding by you. Once the overdue Charges have been paid,
                    we may arrange for the Services to be reconnected, and a
                    reconnection fee may be payable by you.
                  </p>
                  <p>
                    <strong>8.2</strong> If you terminate the Agreement before
                    the end of the Minimum Term and this is not due to reasons
                    related to fault or misconduct of us, you must pay the
                    Termination Payment as calculated and outlined in this
                    Agreement. During the Renewal Term this Agreement may be
                    terminated by either party upon serving thirty (30) days
                    written notice to the other.
                  </p>
                  <p>
                    <strong>8.3</strong> We may terminate the Agreement with
                    immediate effect at any time by written notice to you if:
                    (a) you fail to pay any Charges within 10 days of the due
                    date; (b) you commit a material breach of any term of the
                    Agreement and such breach is not capable of remedy or, if
                    capable of remedy, you fail to remedy the breach within a
                    period of thirty (30) days of being notified in writing to
                    do so; (c) a bankruptcy order is made against you or you
                    petition for your own bankruptcy or (in Scotland) a petition
                    is presented for the sequestration of your estate or you
                    become apparently insolvent or grant a trust deed for
                    creditors; (d) you make any composition scheme or
                    arrangement with creditors or call a meeting of creditors or
                    otherwise are unable to pay your debts when due; (e) (if you
                    are a limited company) an application is made for the
                    appointment of an administrator or if a receiver is
                    appointed in respect of your assets or any part thereof or
                    if you enter into liquidation except a voluntary liquidation
                    for the purpose of amalgamation or reconstruction on terms
                    previously approved in writing by us; or (f) (if you are a
                    partnership) the partnership is dissolved.
                  </p>
                  <p>
                    <strong>8.4</strong> The expiry or termination of the
                    Agreement for any reason will not prejudice or affect the
                    accrued rights, remedies, obligations or liabilities of the
                    parties existing at termination.
                  </p>
                  <p>
                    <strong>8.5</strong> Upon the expiry or termination of the
                    Agreement for any reason: (a) any and all amounts
                    outstanding and any unpaid amounts due and owed under the
                    Agreement will become immediately due and payable; (b) you
                    must at your expense de-install and return to us or our
                    agents (as notified to you) at an address nominated by us no
                    later than the tenth (10th) business day after the date of
                    expiry or termination of the Agreement (with insurance and
                    freight pre-paid) all of the Equipment (together with any
                    instruction manuals, technical specifications, operating
                    software and service, maintenance and repairs records),
                    clean, with all markings removed and all missing parts
                    replaced in good condition (fair wear and tear excepted) and
                    operating order and free from cracks, dents, scratches and
                    stains; and (c) neither party will have any further right or
                    obligation with respect to the other party except as set out
                    in this clause 8 and in the following additional clauses
                    which will continue with full force and effect: clause 6
                    (Warranties and limits of liability), clause 7 (Indemnity)
                    and clause 10 (General).
                  </p>
                  <p>
                    <strong>8.6</strong> If you fail to return the Equipment in
                    accordance with clause 8.5(b), we will charge you an amount
                    equal to the cost of any repairs or improvements to the
                    Equipment, to restore them to good working condition or the
                    full replacement value of each item of Equipment, as
                    appropriate.
                  </p>
                  <p>
                    <strong>9. Assignment</strong>
                  </p>
                  <p>
                    <strong>9.1</strong> You must not assign or transfer the
                    Agreement without our prior written consent. We reserve the
                    right to charge an administrative fee for processing your
                    assignment request. For the purposes of the Agreement, any
                    transfer of voting control of you or your parent, and the
                    merger of you into another entity, will be considered an
                    assignment or transfer of the Agreement.
                  </p>
                  <p>
                    <strong>9.2</strong> We may assign our rights under the
                    Agreement to FDR Limited; any payments due under this
                    Agreement that are made to FDR Limited shall satisfy and
                    discharge your obligation to make payment to us in respect
                    of that payment. We will remain liable to you for our
                    obligations in this Agreement notwithstanding the assignment
                    of our rights to FDR Limited.
                  </p>
                </td>
                <td style={{ padding: "0 0 0 10px", width: "50%" }}>
                  <p>
                    <strong>10. General</strong>
                  </p>
                  <p>
                    <strong>10.1</strong> The Agreement may only be varied by a
                    written agreement signed by all parties.
                  </p>
                  <p>
                    <strong>10.2</strong> All notices must be in writing, and
                    will be given: (a) if sent by post or email, when received,
                    and (b) if sent by courier, when delivered; if to you at the
                    address identified on page one of the Agreement, and if to
                    us at the address specified on page one of the Agreement for
                    the attention of Paymentsave(UK) Limited. You agree to the
                    receipt of arrears and default notices via email to the
                    specified email address on page one of the Agreement. You
                    must immediately notify us of any changes in your postal or
                    e-mail address. Acknowledgement of the receipt of notices by
                    either party must be sent by email.
                  </p>
                  <p>
                    <strong>10.3</strong> Our waiver of any breach of the
                    Agreement by you will not operate as a waiver of subsequent
                    or other earlier breaches of the same or a different kind.
                    No failure or delay by us to exercise any of our rights
                    under the Agreement will constitute a waiver of such rights.
                  </p>
                  <p>
                    <strong>10.4</strong> No third party will have any rights
                    under, or be able to enforce, the Agreement.
                  </p>
                  <p>
                    <strong>10.5</strong> If any provision of the Agreement is
                    found to be invalid, unenforceable or illegal, then such
                    provision will be severed and the remainder of the Agreement
                    will continue with full force and effect.
                  </p>
                  <p>
                    <strong>10.6</strong> The Agreement constitutes the entire
                    agreement between the parties and supersedes all previous
                    agreements between the parties relating to its subject
                    matter. Each party acknowledges that it has not relied on
                    any oral or written representations made to it (whether made
                    negligently or innocently) other than as expressly set out
                    in the Agreement. Nothing in this clause 10.6 will limit or
                    exclude any liability for fraud.
                  </p>
                  <p>
                    <strong>10.7</strong> The Agreement is governed by English
                    law. The parties irrevocably submit to the exclusive
                    jurisdiction of the courts of England regarding any dispute
                    or claim arising out of or in connection with the Agreement
                    or its subject matter or formation (including
                    non-contractual disputes or claims).
                  </p>
                  <p>
                    <strong>11. Definitions</strong>
                  </p>
                  <p>
                    In the Agreement the following terms have the following
                    meanings:
                  </p>
                  <p>
                    <strong>Acquirer</strong> means the entity which processes
                    your Transactions.
                  </p>
                  <p>
                    <strong>Agreement</strong> means this services agreement.{" "}
                  </p>
                  <p>
                    <strong>BINs</strong> mean “bank identification numbers”;
                    these are the range of card numbers for a financial
                    institution.{" "}
                  </p>
                  <p>
                    <strong>Call Centre Help Desk</strong> means support for
                    point of sale terminals.{" "}
                  </p>
                  <p>
                    <strong>Charges</strong> means the Monthly Payment and if
                    applicable the Installation Payment.
                  </p>
                  <p>
                    <strong>Deployment &amp; Replacement Services</strong> means
                    the shipping of the Equipment to the merchant location and
                    replacement services that fall under the warranty.
                  </p>
                  <p>
                    <strong>Equipment</strong> means the point of sale devices
                    described in the front sheet and provided as part of the
                    Services including all accessories and any replacements.
                  </p>
                  <p>
                    <strong>Installation Payment</strong> means the one-off
                    payment due for the Installation Services{" "}
                  </p>
                  <p>
                    <strong>Installation Services</strong> means the use of an
                    engineer to setup the terminal on the merchant’s premises
                    for the first time (where applicable).
                  </p>
                  <p>
                    <strong>Minimum Term</strong> means the minimum period
                    (usually in months) for which the Services will be provided
                    as set out on page one of this Agreement.{" "}
                  </p>
                  <p>
                    <strong>Monthly Payment</strong> means the payments
                    calculated and due each month for the Services.
                  </p>
                  <p>
                    <strong>Services</strong> means the Call Centre Helpdesk,
                    Deployment &amp; Replacement and if applicable the
                    Installation Services.
                  </p>
                  <p>
                    <strong>Renewal Term</strong> means the month to month
                    period after the Minimum Term during which the Services are
                    provided under the terms of this Agreement
                  </p>
                  <p>
                    <strong>Terminal Management System</strong> means the
                    terminal estate management system that allows for the
                    configuration of terminal parameters, BINS, card processing
                    rules and remote software downloads.
                  </p>
                  <p>
                    <strong>Termination Payment</strong> means an amount equal
                    to all the remaining Monthly Payments for the remainder of
                    the Term and any Charges due and unpaid at the date of
                    termination.
                  </p>
                  <p>
                    <strong>Transaction</strong> means the purchase or lease by
                    a cardholder of goods and/or services from you (including a
                    credit or refund) by use of a card and/or any cardholder
                    obtaining a cash advance from you.
                  </p>
                  <p>
                    <strong>We, Our, Us</strong> means Paymentsave(UK) Limited
                    and any persons to whom our rights have been assigned or to
                    whom our rights and responsibilities under the Agreement
                    have passed by assignment or transfer.
                  </p>
                  <p>
                    <strong>You, Your</strong> means the merchant which enters
                    into the Agreement.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "0 10px 0 0", width: "50%" }}>
                  <h6 style={{ fontSize: "20px", margin: "15px 0" }}>
                    HOW WE WILL USE INFORMATION
                  </h6>
                  <p>
                    In order to complete the application process we will require
                    you to provide us with certain information which is
                    considered personal data under applicable data protection
                    laws ("Data Protection Laws"). For the purposes of
                    applicable Data Protection Laws, we will be considered a
                    data controller of this personal data. We will process
                    personal data for the following purposes to: (i) process
                    this Agreement; (ii) assess applications for credit and
                    other products and services that Hirer requires; (iii)
                    manage Hirer's account and provide services to Hirer; (iv)
                    conduct, monitor and analyse our business; (v) contact your
                    (unless you tells us that you prefers not to be contacted)
                    about products and services including those of carefully
                    selected third parties which may interest you; (vi) recover
                    debts, verify identity and prevent fraud and money
                    laundering; and (vii) comply with applicable law and
                    regulations. We (or our agents) may sometimes use a credit
                    scoring or other automated decision making system when
                    processing the information, including personal data,
                    provided by applicant or otherwise obtained (including
                    information provided by third parties, about previous
                    conduct/payment arrears and from official public records).
                    Where used, automated decision making systems help us to
                    make fair and reasonable decisions as to whether to contract
                    with an applicant, considering the financial security and
                    status of the applicant. The methods and logic applied to
                    any automated decision making system will be tested and
                    updated regularly to ensure they remain fair, effective and
                    unbiased. Should the results of any credit scoring or other
                    automated decision be too low, We are unlikely to conclude a
                    contract with applicant. Please write to us at the address
                    specified on page one of this Agreement should you like to
                    request that we reconsider a decision made solely by
                    automated decision making. The provision of personal data by
                    you is necessary in order for us to fulfil its legal and
                    contractual obligations with you and for the purposes
                    identified above where in the legitimate interests of us.
                  </p>
                  <p>
                    We may disclose and transfer personal data to other
                    companies within our group, other persons who provide a
                    service to us (including the manufacturer of the Equipment,
                    the third party delivery company used to deliver the
                    Equipment to you and our collections agents where
                    necessary), or to our employees, agents, associated
                    companies, insurers or anyone to whom we proposes to
                    transfer any of its rights and/or responsibilities under
                    this Agreement, each of whom may also use the personal data
                    in the ways described herein. We may also disclose any
                    information, including personal data that Hirer provides to:
                    (i) anyone to whom you authorises us to give such
                    information; and (ii) comply with any legal or regulatory
                    requirements on us or any company within our group.
                  </p>
                  <p>
                    In order to use and disclose personal data for the purposes
                    set out above we may transfer the personal data to its
                    affiliated companies outside the European Economic Area,
                    including the United States of America, which may not have
                    laws comparable to those in the European Union to protect
                    such personal data. Such transfers are made in accordance
                    within our binding corporate rules (available at
                    <a href="https://www.firstdata.com/en_gb/privacy/binding-corporate-rules.html">
                      https://www.firstdata.com/en_gb/privacy/binding-corporate-rules.html
                    </a>
                    ) or, should binding corporate rules not exist, in
                    accordance with the relevant model contractual clauses
                    approved by the European Commission.
                  </p>
                  <p>
                    Us or our agents may monitor or record telephone calls or
                    other forms of communication from Hirer or Hirer’s employees
                    or agents to ensure instructions are carried out correctly
                    and to help improve the quality of the service.
                  </p>
                  <p>
                    We will store the personal data for the duration of the
                    relationship with Hirer and for as long as is required in
                    order to comply with Owner’s legal obligations.
                  </p>
                  <p>
                    <strong>Credit Reference and Fraud Prevention </strong>
                  </p>
                  <p>
                    We may use and share your information with third parties
                    (including First Data (as our funder), credit reference
                    agencies (whether directly or through First Data), our
                    associated companies, and any person to whom we may assign
                    our rights under this Agreement) to help us and them assess
                    financial and insurance risks, recover debt, develop
                    customer relationships, services and systems, prevent and
                    detect crime.
                  </p>
                </td>
                <td style={{ padding: "0 0 0 10px", width: "50%" }}>
                  <p>
                    Your information includes information about your
                    transactions. From time to time we may change the way we use
                    your Information. Where we believe you may not reasonably
                    expect such a change we shall write to you. If you would
                    like a copy of the information we hold about you please
                    write to us. For more information about the way in which
                    First Data will use your personal data, see
                    www.firstdata.com/privacy.
                  </p>
                  <p>
                    A link between you and anyone with whom you have a joint
                    account or similar financial association will be recorded at
                    credit reference agencies, creating a "financial
                    association".
                  </p>
                  <p>
                    All parties' information will be taken into account in
                    future applications until one of you successfully files a
                    "notice of disassociation" at the credit reference agencies.{" "}
                  </p>
                  <p>
                    When making credit decisions and when managing your account
                    with Us, we or our agents may make searches at credit
                    reference agencies, including electoral register
                    information. These will be recorded by the credit reference
                    agencies and may be used by other credit providers to take
                    decisions about you and your financial associates. We or our
                    agents may also disclose information including how you have
                    run your account to the credit reference agencies for the
                    purpose of obtaining credit assessments on your as a
                    Business or if you are an individual then in your personal
                    capacity. If false or inaccurate information is provided
                    and/or fraud is identified, details will be passed to fraud
                    prevention agencies by us or our agents to prevent fraud and
                    money laundering.{" "}
                  </p>
                  <p>
                    Any Personal Data held about you as a proprietor, director
                    or other authorised officer of the business to which this
                    Agreement relates will be collected, stored and deleted only
                    for the purpose of us (or any organisations that process
                    data on our behalf) carrying out our obligations under this
                    agreement and for the general conduct of this Agreement.{" "}
                  </p>
                  <p>
                    <strong>Personal Data of Other Persons</strong>
                  </p>
                  <p>
                    {" "}
                    By including personal data about other persons in this
                    Agreement you confirm that We: (i) has provided adequate
                    notices to, and obtained valid consent from, such persons,
                    in each case, to the extent necessary for you to process
                    their personal data for the purposes and in the ways
                    described herein; (ii) shall not, by act or omission, cause
                    us to violate any Data Protection Laws, notices provided to,
                    or consents obtained from, such persons as result of
                    processing their personal data; and (iii) shall not include
                    within such personal data any sensitive personal data,
                    except to the extent expressly requested by us in writing.
                    Where information about other persons is provided in or in
                    connection with this Agreement, you and each such person
                    understands that an "association" will be created at the
                    credit reference agencies, which will link their financial
                    records. Credit searches and other information which is
                    provided to us and/or the credit reference agencies, about
                    you and those with whom you are linked financially may be
                    used by us and other companies if credit decisions are made
                    about you, or other members of your household. Rights to
                    personal data{" "}
                  </p>
                  <p>
                    Individuals have a right to : (i) request access to and
                    rectification or erasure of their personal data; (ii) obtain
                    restriction of processing or to object to processing of
                    their personal data; and (iii) the right to data
                    portability. If you or other persons wish to exercise this
                    right they should apply in writing to us at the address
                    specified on page one of this Agreement to the attention of
                    The Data Protection Officer. Individuals also have the right
                    to lodge a complaint about the processing of their personal
                    data with their local data protection authority.{" "}
                  </p>
                  <p>
                    <strong>Marketing/Communication </strong>
                  </p>
                  <p>
                    We may use your information to contact you from time to time
                    about the services or products and/or services or products
                    from companies within our group and/or from other carefully
                    selected third parties. If you wants us to contact you for
                    these purposes by the following means then tick the relevant
                    box: By telephone <input type="checkbox" /> By post/email{" "}
                    <input type="checkbox" /> SMS <input type="checkbox" />.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {/* Terms and codition end */}
        {/* Form */}
        <section>
          <div
            className="row blue no-border signature"
            style={{ paddingBottom: "10px",marginTop:'800px' }}
          >
            <div style={{ border: "1px dashed" }} />
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "50%", padding: "10px 10px 10px 0" }}>
                    <p style={{ fontSize: "14px" }}>
                      Please fill in the whole form including official use box
                      using a ball point pen and send it to:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      textAlign: "end",
                      padding: "0 0 0 10px",
                    }}
                  >
                    <img
                      src={direct_debit}
                      width={125}
                      height="auto"
                      alt="Elavon"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%", padding: "0 10px 0 0" }}>
                    <div style={{ border: "1px solid", padding: "10px" }}>
                      <h5 style={{ marginBottom: "10px", marginTop: 0 }}>
                        FDR Limited
                      </h5>
                      <h5 style={{ marginBottom: "10px", marginTop: 0 }}>
                        Janus House
                      </h5>
                      <h5 style={{ marginBottom: "10px", marginTop: 0 }}>
                        Endeavour Drive
                      </h5>
                      <h5 style={{ marginBottom: "10px", marginTop: 0 }}>
                        Basildon
                      </h5>
                      <h5 style={{ marginBottom: "10px", marginTop: 0 }}>
                        Essex SS14 3WF
                      </h5>
                    </div>
                    <div>
                      <p style={{ fontSize: "18px" }}>
                        Name(s) of Account Holder(s)
                      </p>
                      <div className="accoutn_holder">
                      <input
                        type="text"
                        value={applicationInput?.bank_account_name}
                        style={{ margin: "0 0 10px 13px" }}
                      />
                      {/* <input type="text" style={{ margin: "0 0 10px 13px" }} /> */}
                    </div>
                    </div>
                    <div>
                      <p style={{ fontSize: "18px" }}>
                        Bank/Building Society account number
                      </p>
                      <div
                      className="accoutn_bank"
                      style={{ display: "flex", margin: "0 0 10px 13px" }}
                    >
                      {applicationInput.bank_account_no && (
                        <>
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[0]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[1]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[2]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[3]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[4]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[5]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[6]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[7]}
                          />
                        </>
                      )}
                    </div>
                      <div>
                        <p style={{ fontSize: "18px" }}>Branch Sort Code</p>
                        <div className="accoutn_bank" style={{ display: "flex" }}>
                        {applicationInput?.bank_sort_code && (
                          <>
                            
                        <input type="text" value={applicationInput?.bank_sort_code[0]} />
                        <input type="text" value={applicationInput?.bank_sort_code[1]} />
                        <input type="text" value={applicationInput?.bank_sort_code[2]} />
                        <input type="text" value={applicationInput?.bank_sort_code[3]} />
                        <input type="text" value={applicationInput?.bank_sort_code[4]} />
                        <input type="text" value={applicationInput?.bank_sort_code[5]} />
                          </>
                        )}
                        {/* <input type="text"  />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" /> */}
                      </div>
                      </div>
                      <div>
                        <small>
                          Name and full postal address of your Bank or Building
                          Society
                        </small>
                        <div style={{ border: "1px solid", margin: "10px 0" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0 10px",
                            }}
                          >
                            <h5 style={{ margin: "10px 0" }}>
                              To: The Manager
                            </h5>
                            <h5 style={{ margin: "10px 0" }}>
                              Bank/Building Society
                            </h5>
                          </div>
                          <div
                            className="accoutn_holder"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <input type="text" style={{ width: "95.5%" }} />
                          </div>
                          <div>
                            <h5 style={{ padding: "0 10px", margin: "10px 0" }}>
                              Address
                            </h5>
                            <div
                              className="accoutn_holder"
                              style={{ borderBottom: "1px solid" }}
                            >
                              <input type="text" style={{ width: "95.5%" }} />
                            </div>
                          </div>
                          <div>
                            <h5
                              style={{ textAlign: "center", margin: "10px 0" }}
                            >
                              Post Code
                            </h5>
                            <div className="accoutn_holder">
                              <input type="text" style={{ width: "95.5%" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5
                          style={{
                            margin: "0 0 5px 0",
                            fontWeight: 600,
                            fontSize: "18px",
                          }}
                        >
                          Reference Number
                        </h5>
                        <div
                          className="accoutn_bank"
                          style={{ display: "flex" }}
                        >
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              width: "15px",
                              padding: "5px",
                              height: "25px",
                              border: "1px solid",
                              background: "transparent",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                      padding: "0 0 0 10px",
                    }}
                  >
                    <div>
                      <h4
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          padding: "0 50px",
                        }}
                      >
                        Instruction to your Bank or Building Society to pay by
                        Direct Debit
                      </h4>
                      <div className="number" style={{ textAlign: "center" }}>
                        <h5 style={{ textAlign: "center" }}>
                          Service User Number
                        </h5>
                        <div
                      className="number"
                      style={{ display: "flex",justifyContent:'center', gap: "4px" }}
                    >
                      <input type="text" defaultValue={9} />
                      <input type="text" defaultValue={7} />
                      <input type="text" defaultValue={7} />
                      <input type="text" defaultValue={3} />
                      <input type="text" defaultValue={0} />
                      <input type="text" defaultValue={4} />
                    </div>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid",
                        padding: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <h5>FOR FDR LIMITED OFFICIAL USE ONLY</h5>
                      <p>
                        This is not part of the instruction to your Bank or
                        Building Society
                      </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                      <h4>Instruction to your Bank or Building Society</h4>
                      <h5 style={{ lineHeight: "20px" }}>
                        Please pay FDR Limited Direct Debits from the account{" "}
                        <br />
                        detailed in this Instruction subject to the safeguards
                        assured by <br />
                        the Direct Debit Guarantee. I understand this
                        Instruction may <br />
                        remain with FDR Limited and, if so, details will be
                        passed <br />
                        electronically to my Bank/Building Society.
                      </h5>
                    </div>
                    <div style={{ border: "1px solid", marginTop: "15px" }}>
                      <div>
                        <h5
                          style={{
                            padding: "0 0 10px 10px",
                            margin: "10px 0",
                            borderBottom: "1px solid",
                          }}
                        >
                          Signature(s)
                        </h5>
                        <div
                          className="accoutn_holder"
                          style={{ borderBottom: "1px solid" }}
                        >
                          <input
                            type="text"
                            style={{
                              width: "95.5%",
                              background: "transparent",
                            }}
                          />
                        </div>
                        <h5
                          style={{ padding: "0 0 10px 10px", margin: "10px 0" }}
                        >
                          Date (s)
                        </h5>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{marginTop:'500px'}}>

            </div>
            <h4 className="scissors_div" >
            <span
              style={{
                position: "relative",
                bottom: "-36px",
                left: "-96px",
                fontSize: "25px",
              }}
            >
              ✂
            </span>{" "}
            Banks and Building Societies may not accept Direct Debit
            Instructions from some types of accounts{" "}
          </h4>
          <h3 className="below_scissor_div " style={{ textAlign: "center" }}>
            This guarantee should be detached and retained by the Payer.
          </h3>
            <div style={{ border: "1px solid" }}>
              <h3 style={{ textAlign: "center" }}>
              The Direct Debit Guarantee
              </h3>
              <div
            style={{
              backgroundColor: "#fff",
              padding: "20px 10px 10px 10px",
              margin: "60px 0px 0px 0px",
            }}
          >
           
            <ul style={{ paddingLeft: "18px" }}>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                If there are any changes to the amount, date or frequency of your Direct Debit FDR Limited will notify you at least 14 working days in advance of your account being debited or as otherwise agreed. If you request FDR Limited to collect a payment, confirmation of the amount and date will be given to you at the time of the request.
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                If an error is made in the payment of your Direct Debit, by FDR Limited or your bank or building society, you are entitled to a full immediate refund of the amount paid from your bank or building society <br />
                - If you receive a refund you are not entitled to, you must pay it back when FDR Limited asks you to
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.
                </h5>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
            >
              
              <div>
                <img
                  src={direct_debit}
                  width={125}
                  height="auto"
                  alt="Elavon"
                />
              </div>
            </div>
          </div>
              
            </div>
          </div>
        </section>
        {/* New Page 2nd End */}
        {/* <div class="footer">
      <p>Elavon Financial Services DAC. Registered in Ireland with Companies Registration Office. The liability of the
        member is limited. United Kingdom branch registered in England and Wales under the number BR022122.<br>
        Elavon Financial Services DAC, trading as Elavon Merchant Services, is deemed authorised and regulated by the
        Financial Conduct Authority. Details of the Temporary Permissions Regime, which allows EEA-based firms to
        operate in the UK for a limited period while seeking full authorisation, are available on the Financial Conduct
        Authority’s website.</p> <img src="./main/logo.png" width="125"
        height="auto" alt="Elavon">
    </div> */}
        <div className="footer">
          <p style={{ color: "#000" }}>
            <strong>
              Elavon Financial Services DAC <br /> Registered in Ireland with
              Companies Registration Office. The liability of the member is
              limited. <br /> United Kingdom branch registered in England and
              Wales under the number BR022122. <br />
              Elavon Financial Services DAC, trading as Elavon Merchant Services
              is authorised and regulated by the Central Bank of Ireland.
              Authorised by the Prudential Regulation <br /> Authority and with
              deemed variation of permission. Subject to regulation by the
              Financial Conduct Authority and limited regulation by the
              Prudential Regulation Authority.
              <br />
              Details of the Temporary Permissions Regime, which allows
              EEA-based firms to operate in the UK for a limited period while
              seeking full authorisation, are available on the <br /> Financial
              Conduct Authority's website.
            </strong>
          </p>{" "}
          <img src={elavon_transparent} width={125} height="auto" alt="Elavon" />
        </div>
      </form>
    </div>
  );
};

export default LastPdf;
