import React from "react";

import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";

import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import {
  GetApplicationInput,
  GetVatList,
} from "./_redux/action/NewapplicationAction";


import { useEffect } from "react";
import { entity_code } from "../commons/Dropdown";
const BusinessProfile = () => {
  const dispatch = useDispatch();
  const AcquiringBank = (
    <p style={{ fontWeight: "normal" }}>
      Acquiring Bank <span className="required">*</span>
    </p>
  );
  const storeNumber = (
    <p style={{ fontWeight: "normal" }}>
      Store Number<span className="required">*</span>
    </p>
  );
  const descrip_goods = (
    <p style={{ fontWeight: "normal" }}>
      Description of goods or services<span className="required">*</span>
    </p>
  );
  const vatNumber = (
    <p style={{ fontWeight: "normal" }}>
      VAT Number<span className="required">*</span>
    </p>
  );

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const vatList = useSelector((state) => state.applicationInfo.vatList);

  // ------------------------api call----------------------
  const Acquiring_Bank = [
    {
      value: "0",
      label: "Elavon",
    },
  ];
  useEffect(() => {
    if (
      applicationInput.vat_enabled === 0 &&
     ( applicationInput.legal_name !== null ||  applicationInput.legal_name !== "") &&
(      applicationInput.company_house_no !== null||  applicationInput.legal_name !== "")
    ) {
      dispatch(
        GetVatList(
          applicationInput.legal_name,
          applicationInput.company_house_no
        )
      );
    }

   
  }, [applicationInput.vat_enabled]);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetApplicationInput(name, value, e));

  };

  const handleSelectButton = (name, i) => {
    dispatch(GetApplicationInput(name, i));
    if (applicationInput.new_to_card_proccessing === true) {
      dispatch(GetApplicationInput("previous_acquirer", ""));
    }
    if (applicationInput.take_deposit === 0) {
      dispatch(GetApplicationInput("deposit_perc_transaction_value", ""));
      dispatch(GetApplicationInput("advance_supply_deposite_taken", ""));
      dispatch(GetApplicationInput("perc_annual_deposite_of_turnover", ""));
      dispatch(
        GetApplicationInput("time_btw_deposite_and_remaining_payment", "")
      );
      dispatch(GetApplicationInput("take_full_payment", false));
    }
    if (applicationInput.take_full_payment === false) {
      dispatch(GetApplicationInput("perc_annual_upfront_of_turnover", ""));
      dispatch(GetApplicationInput("advance_supply_full_payment", ""));
      dispatch(GetApplicationInput("deposite_comment", ""));
    }
  };
  const handleSelectVat = (name, i) => {
    dispatch(GetApplicationInput(name, i));

    if (
      applicationInput.vat_enabled === 1 ||
      applicationInput.vat_enabled === 2
    ) {
      dispatch(GetApplicationInput("tax_id", ""));
    }
  };
  const handleSelectSecuiredElavon = (name, i) => {
    dispatch(GetApplicationInput(name, i));
    if (applicationInput.secured_by_elavon === "SECURED_PRO") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    } else if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "19.99"));
      dispatch(GetApplicationInput("non_compliance_per_month", "N/A"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
  };
  useEffect(() => {
    if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
    if (applicationInput.amex_mid === null) {
      dispatch(GetApplicationInput("amex_mid", "false"));
    }
  }, []);

  // ===============entity code=====================

  return (
    <div className="">
      <div className="info-box text-capitalize">
        <div className="d-flex heading">
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>

          <p>Business Profile</p>
        </div>

        <div className="mt-4">
          <div className="row">
            <div className="col-md-6">
              <div className="inner-box">
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={AcquiringBank}
                    variant="outlined"
                    size="small"
                    select
                    name="acquiring_bank"
                    value={applicationInput.acquiring_bank}
                    onChange={(e) => {
                      handleChangeInput("acquiring_bank", e.target.value);
                    }}
                  >
                    {Acquiring_Bank.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label={storeNumber}
                    onWheel={(e) => e.target.blur()}
                    variant="outlined"
                    size="small"
                    name="store_number"
                    value={applicationInput.store_number}
                    onChange={(e) => {
                      handleChangeInput("store_number", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    rows={2}
                    multiline
                    label={descrip_goods}
                    variant="outlined"
                    // helperText={applicationInput?.desc_of_service?.length}
                    size="small"
                    inputProps={{
                      maxlength: 300,
                    }}
                    name="desc_of_service"
                    value={applicationInput.desc_of_service}
                    onChange={(e) => {
                      handleChangeInput("desc_of_service", e.target.value);
                    }}
                  />
                
                </div>
                <div className="mb-form">
                  <div className="row">
                    <div className="col-4">
                      <p>Switching Contribution </p>
                    </div>
                    <div className="col-8">
                      <div className="d-sm-flex gap-3">
                        <Button
                          onClick={() =>
                            handleSelectButton("switching_contribution", true)
                          }
                          size="small"
                          variant={
                            applicationInput.switching_contribution === true
                              ? "contained"
                              : "outlined"
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() =>
                            handleSelectButton("switching_contribution", false)
                          }
                          size="small"
                          variant={
                            applicationInput.switching_contribution === false
                              ? "contained"
                              : "outlined"
                          }
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-box">
                <div className="row">
                  <div className="col-4 col-lg-4">
                    <p>New to card processing :</p>
                  </div>
                  <div className="col-8 col-lg-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectButton("new_to_card_proccessing", true)
                        }
                        size="small"
                        variant={
                          applicationInput.new_to_card_proccessing === true
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectButton("new_to_card_proccessing", false)
                        }
                        size="small"
                        variant={
                          applicationInput.new_to_card_proccessing === false
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>

                {applicationInput.new_to_card_proccessing === false && (
                  <div className="mb-form mt-4">
                    <TextField
                      id="outlined-basic"
                      label="who was your previous acquirer?"
                      variant="outlined"
                      size="small"
                      name="previous_acquirer"
                      value={applicationInput.previous_acquirer}
                      onChange={(e) => {
                        handleChangeInput("previous_acquirer", e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>

              {/* ---------------------take deposite---------------------- */}
              <div className="inner-box">
                <div className="mb-form">
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <p> Do you want take deposite ?</p>
                    </div>
                    <div className="col-8 col-lg-4">
                      <div className="d-sm-flex gap-3">
                        <Button
                          onClick={() => handleSelectButton("take_deposit", 1)}
                          size="small"
                          variant={
                            applicationInput.take_deposit === 1
                              ? "contained"
                              : "outlined"
                          }
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() => handleSelectButton("take_deposit", 0)}
                          size="small"
                          variant={
                            applicationInput.take_deposit === 0
                              ? "contained"
                              : "outlined"
                          }
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                  {applicationInput.take_deposit === 1 && (
                    <>
                      <div className="mb-form mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Size of deposite as a % of total transaction values"
                          variant="outlined"
                          size="small"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="deposit_perc_transaction_value"
                          value={
                            applicationInput.deposit_perc_transaction_value
                          }
                          onChange={(e) => {
                            handleChangeInput(
                              "deposit_perc_transaction_value",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <p className="mb-2">
                        How Far In Advance of the supply of goods/services are
                        deposits taken?:
                      </p>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              label="Days"
                              variant="outlined"
                              size="small"
                              name="advance_supply_deposite_taken"
                              value={
                                applicationInput.advance_supply_deposite_taken
                              }
                              onChange={(e) => {
                                handleChangeInput(
                                  "advance_supply_deposite_taken",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              label="Weeks"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="week"
                              value={applicationInput.week}
                              onChange={(e) => {
                                handleChangeInput("week", e.target.value);
                              }}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              label="Months"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="month"
                              value={applicationInput.month}
                              onChange={(e) => {
                                handleChangeInput("month", e.target.value);
                              }}
                            />
                          </div>
                        </div> */}
                      </div>

                      <div className="mb-form">
                        <p className="mb-2">
                          What % of your annual turnover relates to deposits?
                        </p>
                        <TextField
                          id="outlined-basic"
                          label="% of (anticipated) turnover p.a."
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          variant="outlined"
                          size="small"
                          name="perc_annual_deposite_of_turnover"
                          value={
                            applicationInput.perc_annual_deposite_of_turnover
                          }
                          onChange={(e) => {
                            handleChangeInput(
                              "perc_annual_deposite_of_turnover",
                              e.target.value
                            );
                          }}
                        />
                      </div>

                      <p className="mb-2">
                        Time between taking deposit and receiving remaining
                        balance of payment? :
                      </p>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              label="Days"
                              variant="outlined"
                              size="small"
                              name="time_btw_deposite_and_remaining_payment"
                              value={
                                applicationInput.time_btw_deposite_and_remaining_payment
                              }
                              onChange={(e) => {
                                handleChangeInput(
                                  "time_btw_deposite_and_remaining_payment",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              label="Weeks"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="week"
                              value={applicationInput.deposite_week}
                              onChange={(e) => {
                                handleChangeInput("deposite_week", e.target.value);
                              }}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              label="Months"
                              variant="outlined"
                              size="small"
                              type="number"
                              name="month"
                              value={applicationInput.deposite_month}
                              onChange={(e) => {
                                handleChangeInput("deposite_month", e.target.value);
                              }}
                            />
                          </div>
                        </div> */}
                      </div>

                      <div className="row">
                        <div className="col-4 col-lg-4">
                          <p> Do you ever take full payment ? :</p>
                        </div>
                        <div className="col-8 col-lg-8">
                          <div className="d-sm-flex gap-3">
                            <Button
                              onClick={() =>
                                handleSelectButton("take_full_payment", true)
                              }
                              size="small"
                              variant={
                                applicationInput.take_full_payment === true
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              Yes
                            </Button>
                            <Button
                              onClick={() =>
                                handleSelectButton("take_full_payment", false)
                              }
                              size="small"
                              variant={
                                applicationInput.take_full_payment === false
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              No
                            </Button>
                          </div>
                        </div>
                      </div>
                      {applicationInput.take_full_payment === true && (
                        <>
                          <p className="mb-2 mt-4">
                            How far in advance of supply is the full :
                          </p>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-form">
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  label="Days"
                                  variant="outlined"
                                  size="small"
                                  name="advance_supply_full_payment"
                                  value={
                                    applicationInput.advance_supply_full_payment
                                  }
                                  onChange={(e) => {
                                    handleChangeInput(
                                      "advance_supply_full_payment",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-6">
                              <div className="mb-form">
                                <TextField
                                  id="outlined-basic"
                                  label="Weeks"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name="week"
                                  value={applicationInput.supply_week}
                                  onChange={(e) => {
                                    handleChangeInput("supply_week", e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="mb-form">
                                <TextField
                                  id="outlined-basic"
                                  label="Months"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name="month"
                                  value={applicationInput.supply_month}
                                  onChange={(e) => {
                                    handleChangeInput("supply_month", e.target.value);
                                  }}
                                />
                              </div>
                            </div> */}
                          </div>

                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              label="What % of your annual turnover  relates to upfront full payments?  "
                              variant="outlined"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              size="small"
                              name="perc_annual_upfront_of_turnover"
                              value={
                                applicationInput.perc_annual_upfront_of_turnover
                              }
                              onChange={(e) => {
                                handleChangeInput(
                                  "perc_annual_upfront_of_turnover",
                                  e.target.value
                                );
                              }}
                            />
                          </div>

                          <div className="mb-form">
                            <TextField
                              id="outlined-basic"
                              rows={2}
                              label="If you wish to provide any further details, please insert here:"
                              variant="outlined"
                              multiline
                              size="small"
                              name="deposite_note"
                              value={applicationInput.deposite_note}
                              onChange={(e) => {
                                handleChangeInput(
                                  "deposite_note",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              {/* ---------------------take deposite end---------------------- */}
            </div>
            <div className="col-md-6">
              <div className="inner-box">
                {/* <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    select
                    label="VAT"
                    name="vat_enabled"
                    value={applicationInput.vat_enabled}
                    onChange={(e) => {
                      handleChangeInput("vat_enabled", e.target.value);
                    }}
                  >
                    {seasonal_sale.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div> */}
                <div className="mb-form">
                  <div className="row">
                    <div className="col-lg-3">
                      <p>Vat Details : </p>
                    </div>
                    <div className="col-lg-9 mt-2">
                      <div className="d-sm-flex gap-3 justify-content-between">
                        <Button
                          onClick={() => handleSelectVat("vat_enabled", 0)}
                          size="small"
                          variant={
                            applicationInput.vat_enabled === 0
                              ? "contained"
                              : "outlined"
                          }
                          // variant="outlined"
                        >
                          Vat Number
                        </Button>
                        <Button
                          onClick={() => handleSelectVat("vat_enabled", 1)}
                          size="small"
                          variant={
                            applicationInput.vat_enabled === 1
                              ? "contained"
                              : "outlined"
                          }
                        >
                          Vat Number Pending
                        </Button>
                        <Button
                          onClick={() => handleSelectVat("vat_enabled", 2)}
                          size="small"
                          variant={
                            applicationInput.vat_enabled === 2
                              ? "contained"
                              : "outlined"
                          }
                        >
                          In Business Confirmation
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {applicationInput.vat_enabled === 0 && (
                  <>
                    <div className="mb-form">
                      <TextField
                        id="outlined-basic"
                        label={vatNumber}
                        variant="outlined"
                        size="small"
                        name="tax_id"
                        value={applicationInput.tax_id}
                        onChange={(e) => {
                          handleChangeInput("tax_id", e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-form">
                      <TextField
                        id="outlined-basic"
                        label="VAT Amount"
                        variant="outlined"
                        size="small"
                        name="vat_amount"
                        value={applicationInput.vat_amount}
                        onChange={(e) => {
                          handleChangeInput("vat_amount", e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    rows={2}
                    label="notes"
                    variant="outlined"
                    multiline
                    size="small"
                    name="vat_notes"
                    value={applicationInput.vat_notes}
                    onChange={(e) => {
                      handleChangeInput("vat_notes", e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* ---------------------------amex mid---------------------- */}
              <div className="inner-box">
                {/* <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    select
                    label="Parent Entity Code"
                    name="parent_entity_code"
                    value={applicationInput.parent_entity_code}
                    onChange={(e) => {
                      handleChangeInput("parent_entity_code", e.target.value);
                    }}
                  >
                    {entity_code.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div> */}

                <div className="row mt-4">
                  <div className="col-4 col-lg-3">
                    <p>AMEX MID : </p>
                  </div>
                  <div className="col-8 col-lg-9">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() => handleSelectButton("amex_mid", "true")}
                        size="small"
                        variant={
                          applicationInput.amex_mid === "true"
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => handleSelectButton("amex_mid", "false")}
                        size="small"
                        variant={
                          applicationInput.amex_mid === "false"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>

                {applicationInput.amex_mid === "true" && (
                  <div className="row mt-4">
                    <div className="col-4 col-lg-3">
                      <p>AMEX Type : </p>
                    </div>
                    <div className="col-8 col-lg-9">
                      <div className="d-sm-flex gap-3">
                        <Button
                          onClick={() =>
                            handleSelectButton("amex_type", "Existing AMEX")
                          }
                          size="small"
                          variant={
                            applicationInput.amex_type === "Existing AMEX"
                              ? "contained"
                              : "outlined"
                          }
                          // variant="outlined"
                        >
                          Existing AMEX
                        </Button>
                        <Button
                          onClick={() =>
                            handleSelectButton("amex_type", "New AMEX")
                          }
                          size="small"
                          variant={
                            applicationInput.amex_type === "New AMEX"
                              ? "contained"
                              : "outlined"
                          }
                        >
                          New AMEX
                        </Button>
                      </div>
                      {/* <div className="d-flex gap-3 mb-form">
                        <Form.Check
                          inline
                          label="Existing AMEX"
                          name="group1"
                          type="checkbox"
                          checked={
                            applicationInput.amex_type === "Existing AMEX"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleAmexType("Existing AMEX", e.target.value)
                          }

                          // id={`inline-${type}-1`}
                        />
                        <Form.Check
                          inline
                          label="New AMEX"
                          name="group1"
                          type="checkbox"
                          checked={
                            applicationInput.amex_type === "New AMEX"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleAmexType("New AMEX", e.target.value)
                          }
                        />
                      </div> */}
                    </div>
                  </div>
                )}
                {applicationInput.amex_type === "Existing AMEX" &&
                  applicationInput.amex_mid === "true" && (
                    <div className="mb-form mt-4">
                      <TextField
                        id="outlined-basic"
                        label="AMEX No"
                        variant="outlined"
                        size="small"
                        name="amex_no"
                        value={applicationInput.amex_no}
                        onChange={(e) => {
                          handleChangeInput("amex_no", e.target.value);
                        }}
                      />
                    </div>
                  )}
                {/* {applicationInput.amex_mid === 'no' && 
              // handleAmexMidNo()
              } */}
              </div>
              {/* -----------------------------------------seasonal sale--------------------------- */}
              <div className="inner-box">
                <div className="mb-form">
                  {/* <TextField
                    size="small"
                    id="outlined-basic"
                    select
                    label="Seasonal Sale (%)"
                    name="seasonal_sales"
                    value={applicationInput.seasonal_sales}
                    onChange={(e) => {
                      handleChangeInput("seasonal_sales", e.target.value);
                    }}
                  >
                    {seasonal_sale.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <div className="row">
                    <div className="col-4">
                      <p>Seasonal Sale (%): </p>
                    </div>
                    <div className="col-8">
                      <div className="d-sm-flex gap-3">
                        <Button
                          onClick={() =>
                            handleSelectButton("seasonal_sales", true)
                          }
                          size="small"
                          variant={
                            applicationInput.seasonal_sales === true
                              ? "contained"
                              : "outlined"
                          }
                          // variant="outlined"
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={() =>
                            handleSelectButton("seasonal_sales", false)
                          }
                          size="small"
                          variant={
                            applicationInput.seasonal_sales === false
                              ? "contained"
                              : "outlined"
                          }
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {applicationInput.seasonal_sales === true && (
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-form d-flex align-items-center">
                        <TextField
                          id="outlined-basic"
                          label="Jan Feb Mar (%)"
                          variant="outlined"
                          type="number"
                          size="small"
                          name="jan_to_mar"
                          value={applicationInput.jan_to_mar}
                          onChange={(e) => {
                            handleChangeInput("jan_to_mar", e.target.value);
                          }}
                        />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.418 0.917969L0.917969 13.418L2.58464 15.0846L15.0846 2.58464L13.418 0.917969ZM3.83464 1.33464C3.17159 1.33464 2.53571 1.59803 2.06687 2.06687C1.59803 2.53571 1.33464 3.17159 1.33464 3.83464C1.33464 4.49768 1.59803 5.13356 2.06687 5.6024C2.53571 6.07124 3.17159 6.33464 3.83464 6.33464C4.49768 6.33464 5.13356 6.07124 5.6024 5.6024C6.07124 5.13356 6.33464 4.49768 6.33464 3.83464C6.33464 3.17159 6.07124 2.53571 5.6024 2.06687C5.13356 1.59803 4.49768 1.33464 3.83464 1.33464ZM12.168 9.66797C11.5049 9.66797 10.869 9.93136 10.4002 10.4002C9.93136 10.869 9.66797 11.5049 9.66797 12.168C9.66797 12.831 9.93136 13.4669 10.4002 13.9357C10.869 14.4046 11.5049 14.668 12.168 14.668C12.831 14.668 13.4669 14.4046 13.9357 13.9357C14.4046 13.4669 14.668 12.831 14.668 12.168C14.668 11.5049 14.4046 10.869 13.9357 10.4002C13.4669 9.93136 12.831 9.66797 12.168 9.66797Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      </div>
                      <div className="mb-form d-flex align-items-center">
                        <TextField
                          id="outlined-basic"
                          label="Jul Aug Sep (%)"
                          variant="outlined"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          size="small"
                          name="jul_to_sep"
                          value={applicationInput.jul_to_sep}
                          onChange={(e) => {
                            handleChangeInput("jul_to_sep", e.target.value);
                          }}
                        />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.418 0.917969L0.917969 13.418L2.58464 15.0846L15.0846 2.58464L13.418 0.917969ZM3.83464 1.33464C3.17159 1.33464 2.53571 1.59803 2.06687 2.06687C1.59803 2.53571 1.33464 3.17159 1.33464 3.83464C1.33464 4.49768 1.59803 5.13356 2.06687 5.6024C2.53571 6.07124 3.17159 6.33464 3.83464 6.33464C4.49768 6.33464 5.13356 6.07124 5.6024 5.6024C6.07124 5.13356 6.33464 4.49768 6.33464 3.83464C6.33464 3.17159 6.07124 2.53571 5.6024 2.06687C5.13356 1.59803 4.49768 1.33464 3.83464 1.33464ZM12.168 9.66797C11.5049 9.66797 10.869 9.93136 10.4002 10.4002C9.93136 10.869 9.66797 11.5049 9.66797 12.168C9.66797 12.831 9.93136 13.4669 10.4002 13.9357C10.869 14.4046 11.5049 14.668 12.168 14.668C12.831 14.668 13.4669 14.4046 13.9357 13.9357C14.4046 13.4669 14.668 12.831 14.668 12.168C14.668 11.5049 14.4046 10.869 13.9357 10.4002C13.4669 9.93136 12.831 9.66797 12.168 9.66797Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="me-2 mb-form d-flex align-items-center">
                        <TextField
                          id="outlined-basic"
                          label="Apr May Jun (%)"
                          variant="outlined"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          size="small"
                          name="apr_to_jun"
                          value={applicationInput.apr_to_jun}
                          onChange={(e) => {
                            handleChangeInput("apr_to_jun", e.target.value);
                          }}
                        />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.418 0.917969L0.917969 13.418L2.58464 15.0846L15.0846 2.58464L13.418 0.917969ZM3.83464 1.33464C3.17159 1.33464 2.53571 1.59803 2.06687 2.06687C1.59803 2.53571 1.33464 3.17159 1.33464 3.83464C1.33464 4.49768 1.59803 5.13356 2.06687 5.6024C2.53571 6.07124 3.17159 6.33464 3.83464 6.33464C4.49768 6.33464 5.13356 6.07124 5.6024 5.6024C6.07124 5.13356 6.33464 4.49768 6.33464 3.83464C6.33464 3.17159 6.07124 2.53571 5.6024 2.06687C5.13356 1.59803 4.49768 1.33464 3.83464 1.33464ZM12.168 9.66797C11.5049 9.66797 10.869 9.93136 10.4002 10.4002C9.93136 10.869 9.66797 11.5049 9.66797 12.168C9.66797 12.831 9.93136 13.4669 10.4002 13.9357C10.869 14.4046 11.5049 14.668 12.168 14.668C12.831 14.668 13.4669 14.4046 13.9357 13.9357C14.4046 13.4669 14.668 12.831 14.668 12.168C14.668 11.5049 14.4046 10.869 13.9357 10.4002C13.4669 9.93136 12.831 9.66797 12.168 9.66797Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      </div>

                      <div className=" me-2 mb-form d-flex align-items-center">
                        <TextField
                          id="outlined-basic"
                          label="Oct Nov Dec (%)"
                          variant="outlined"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          size="small"
                          name="oct_to_dec"
                          value={applicationInput.oct_to_dec}
                          onChange={(e) => {
                            handleChangeInput("oct_to_dec", e.target.value);
                          }}
                        />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.418 0.917969L0.917969 13.418L2.58464 15.0846L15.0846 2.58464L13.418 0.917969ZM3.83464 1.33464C3.17159 1.33464 2.53571 1.59803 2.06687 2.06687C1.59803 2.53571 1.33464 3.17159 1.33464 3.83464C1.33464 4.49768 1.59803 5.13356 2.06687 5.6024C2.53571 6.07124 3.17159 6.33464 3.83464 6.33464C4.49768 6.33464 5.13356 6.07124 5.6024 5.6024C6.07124 5.13356 6.33464 4.49768 6.33464 3.83464C6.33464 3.17159 6.07124 2.53571 5.6024 2.06687C5.13356 1.59803 4.49768 1.33464 3.83464 1.33464ZM12.168 9.66797C11.5049 9.66797 10.869 9.93136 10.4002 10.4002C9.93136 10.869 9.66797 11.5049 9.66797 12.168C9.66797 12.831 9.93136 13.4669 10.4002 13.9357C10.869 14.4046 11.5049 14.668 12.168 14.668C12.831 14.668 13.4669 14.4046 13.9357 13.9357C14.4046 13.4669 14.668 12.831 14.668 12.168C14.668 11.5049 14.4046 10.869 13.9357 10.4002C13.4669 9.93136 12.831 9.66797 12.168 9.66797Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* ------------------------------------cashback------------------------ */}
              <div className="inner-box">
                <div className="row mb-form">
                  <div className="col-4">
                    <p>Cashback: </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() => handleSelectButton("cashback", true)}
                        size="small"
                        variant={
                          applicationInput.cashback === true
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => handleSelectButton("cashback", false)}
                        size="small"
                        variant={
                          applicationInput.cashback === false
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>

                {applicationInput.cashback === true && (
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Anticipated average Cashback amount"
                      variant="outlined"
                      size="small"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="avg_cashback_amount"
                      value={applicationInput.avg_cashback_amount}
                      onChange={(e) => {
                        handleChangeInput(
                          "avg_cashback_amount",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              {/* -----------------------------secure by elavon------------------ */}
              <div className="inner-box">
                <div className="row mb-form">
                  <div className="col-4">
                    <p>Secured by Elavon (select one): </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleSelectSecuiredElavon(
                            "secured_by_elavon",
                            "SECURED_PCI"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.secured_by_elavon === "SECURED_PCI"
                            ? "contained"
                            : "outlined"
                        }
                        // variant="outlined"
                      >
                        Secured PCI
                      </Button>
                      <Button
                        onClick={() =>
                          handleSelectSecuiredElavon(
                            "secured_by_elavon",
                            "SECURED_PRO"
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.secured_by_elavon === "SECURED_PRO"
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Secured Pro
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Product (£ per month)"
                    variant="outlined"
                    size="small"
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    name="product_per_month_amt"
                    value={applicationInput.product_per_month_amt}
                    onChange={(e) => {
                      handleChangeInput(
                        "product_per_month_amt",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Non-Compliance (£ per month)"
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    name="non_compliance_per_month"
                    value={
                      applicationInput.secured_by_elavon === "SECURED_PCI"
                        ? applicationInput.non_compliance_per_month
                        : "N/A"
                    }
                    onChange={(e) => {
                      handleChangeInput(
                        "non_compliance_per_month",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Custom Value (£ per month)"
                    variant="outlined"
                    size="small"
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    name="customer_value_per_month"
                    value={
                      applicationInput.secured_by_elavon === "SECURED_PCI"
                        ? applicationInput.customer_value_per_month
                        : "N/A"
                    }
                    onChange={(e) => {
                      handleChangeInput(
                        "customer_value_per_month",
                        e.target.value
                      );
                    }}
                  />
                </div>

                {/* {applicationInput.secured_by_elavon === ""} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
