export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";
export const GET_APPLICATION_DETAILS = "GET_APPLICATION_DETAILS";
export const APPLICATION_PAGINATION = "APPLICATION_PAGINATION"
export const SET_APPLICATION_FALSE = "SET_APPLICATION_FALSE"
export const SET_APPLICATION_INPUT_FALSE = "SET_APPLICATION_INPUT_FALSE"
export const IS_LOAD_APPLICATION = "IS_LOAD_APPLICATION";
export const GET_APPLICATION_INPUT = "GET_APPLICATION_INPUT";
export const AFTER_SUCCESS_APPLICATION = "AFTER_SUCCESS_APPLICATION"
export const SET_APPLICATION_UPDATED = "SET_APPLICATION_UPDATED";
export const IS_LOAD_LEADS = "IS_LOAD_LEADS";
// ---------------------qualify---------------------------
export const AFTER_SUCCESS_APPLICATION_QUALIFY = "AFTER_SUCCESS_APPLICATION_QUALIFY"
export const SET_FALSE_APPLICATION_QUALIFY = "SET_FALSE_APPLICATION_QUALIFY"
export const GET_QUALIFY_APPLICATION = "GET_QUALIFY_APPLICATION";

// ------------------Pagination-------------------------------------
export const PAGINATION_NAME = "PAGINATION_NAME"
export const PAGINATION_lIMIT = "PAGINATION_lIMIT"
export const PAGINATION_NEXT = "PAGINATION_NEXT"
export const PAGINATION_PREVIOUS = "PAGINATION_PREVIOUS"
export const PAGINATION_CHANGE_CURRENT = "PAGINATION_CHANGE_CURRENT"
export const GET_PAGINATION_DATA = "GET_PAGINATION_DATA"
export const PAGINATION_LOADING = "PAGINATION_LOADING";
export const PAGINATION_SUCCESS = "PAGINATION_SUCCESS";
export const PAGINATION_ERROR = "PAGINATION_ERROR";
export const PAGINATION_URL = "PAGINATION_URL";

export const SET_LEADS_UPDATED = "SET_LEADS_UPDATED"

export const IS_ADDRESS_CHECKED = "IS_ADDRESS_CHECKED";
export const BANK_INFO_CHECKED = "BANK_INFO_CHECKED";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_INPUT = "GET_CONTACT_INPUT";

export const GET_QUOTE_OWNER = "GET_QUOTE_OWNER";
export const SET_QUOTE_OWNER = "SET_QUOTE_OWNER";
export const REMOVE_APPLICATION_OWNER = "REMOVE_APPLICATION_OWNER";
export const IS_APPLICATION_OWNER_DELETED = "IS_APPLICATION_OWNER_DELETED";

// ------------------Terminal-------------------------------------

export const GET_TERMINAL_LIST = "GET_TERMINAL_LIST";
export const IS_LOADING_TERMINAL = "IS_LOADING_TERMINAL";
export const SUCCESS_TERMINAL = "SUCCESS_TERMINAL";
export const ERROR_TERMINAL = "ERROR_TERMINAL";
export const ADD_NEW_TERMINAL = "ADDDD_NEW_TERMINAL";
export const DELETE_TERMINAL = "DELETE_TERMINAL";
export const UPDATE_TERMINAL = "UPDATE_TERMINAL";
export const DELETE_POSTDATA = "DELETE_POSTDATA";
export const TERMINAL_NEW_APP = "TERMINAL_NEW_APP";
export const SET_TERMINAL_NEW_APP = "SET_TERMINAL_NEW_APP";
export const TERMINAL_DELETE_NEW_APP = "TERMINAL_DELETE_NEW_APP";
export const TERMINAL_ADD_NEW_APP = "TERMINAL_ADD_NEW_APP";


// ================doc-------------------


export const DELETE_DOC = "DELETE_DOC";
export const SET_NEW_DOC_INPUT = "SET_NEW_DOC_INPUT";
export const ADD_NEW_DOC = "ADD_NEW_DOC";
export const SUCCESS_DOC = "SUCCESS_DOC";
export const SET_DOC_UPDATED = "SET_DOC_UPDATED";

export const IS_SUCCESS_IMAGE = "IS_SUCCESS_IMAGE";

// =======vat==============
export const GET_VAT_LIST = "GET_VAT_LIST";

// -----------------submit to bank-----------------
export const SUBMIT_APPLICATION_TO_BANK = "SUBMIT_APPLICATION_TO_BANK";
export const AFTER_SUBMIT_BANK = "AFTER_SUBMIT_BANK";
export const SET_SUBMIT_FALSE = "SET_SUBMIT_FALSE";
// -----------------sigingng req----------------
export const SEND_SIGNING_REQUEST = "SEND_SIGNING_REQUEST";
export const AFTER_SUCCESS_SIGNING = "AFTER_SUCCESS_SIGNING";
export const SET_SIGINING_FALSE = "SET_SIGINING_FALSE";
export const SET_SIGINING_FALSE_TWO = "SET_SIGINING_FALSE_TWO";
export const AFTER_SUCCESS_REPRESENTATIVE_SIGNING = "AFTER_SUCCESS_REPRESENTATIVE_SIGNING";

// -------------------company house ------------------------

export const GET_COMPANY_HOUSE_LIST = "GET_COMPANY_HOUSE_LIST";
export const GET_COMPANY_HOUSE_DETAILS = "GET_COMPANY_HOUSE_DETAILS";
export const GET_COMPANY_OFFICERS_DETAILS = "GET_COMPANY_OFFICERS_DETAILS";
// ===============product===================================

export const GET_ALL_PRODUCT_LIST = "GET_ALL_PRODUCT_LIST";
export const GET_APPLICATION_PRODUCT = "GET_APPLICATION_PRODUCT";
export const SET_APPLICATION_PRODUCT = "SET_APPLICATION_PRODUCT";
export const REMOVE_APPLICATION_PRODUCT = "REMOVE_APPLICATION_PRODUCT";
export const IS_APPLICATION_PRODUCT_DELETED = "IS_APPLICATION_PRODUCT_DELETED";

// -------------------pdf-----------------------

export const SET_PDF_FALSE = "SET_PDF_FALSE";
export const AFTER_SUCCESS_PDF = "AFTER_SUCCESS_PDF";
export const GET_DOWNLOAD_PDF = "GET_DOWNLOAD_PDF";
export const GET_SITE_VISIT_DOWNLOAD_PDF = "GET_SITE_VISIT_DOWNLOAD_PDF";


