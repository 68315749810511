import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../../components/application.css";
import { useEffect } from "react";
import Leadtab from "./LeadTab";

import PriceQuoteTabs from "../PriceQuote/PriceQuoteTabs";
import { useHistory, useParams } from "react-router-dom";
import ApplicationTabs from "../newApplication/ApplicationTabs";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useDispatch, useSelector } from "react-redux";
import NewLeadTab from "./NewLeadTab";
import {
  SetPricequoteUpdatedData,
  SetLeadUpdatedData,
  GetLeadsnput,
  GetSalesPartnerList,
} from "../../_redux/action/LeadsAction";
import { GetAplicationDetails } from "../newApplication/_redux/action/NewapplicationAction";
import { GetUserList } from "../../../../userManagement/_redux/action/UserAction";
import { BASE_URL } from "../../../../../Const";
const steps = ["Leads", "Price Quote", "New Application"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#1e2553 0%,#1e2553 50%,#1e2553 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#1e2553 0%,#1e2553 50%,#1e2553 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#D9D9D9",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,#1e2553 0%,#1e2553 50%,#1e2553 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,#1e2553 0%,#1e2553 50%,#1e2553 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    ></ColorlibStepIconRoot>
  );
}

export default function LeadStep() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(
    localStorage.getItem("activeStep")
      ? parseInt(localStorage.getItem("activeStep"))
      : 0
  );
  const quote_id = localStorage.getItem("quote_id");
  const application_id = localStorage.getItem("application_id");
  // const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
    const storedActiveStep = parseInt(localStorage.getItem("activeStep"));
    if (!isNaN(storedActiveStep)) {
      setActiveStep(storedActiveStep);
    }
  }, []);

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    if (leadInput.step === 1) {
      setActiveStep(0);
    } else if (leadInput.step === 2) {
      setActiveStep(1);
    } else if (leadInput.step === 3) {
      setActiveStep(2);
    }
  }, []);
  useEffect(() => {
    if (activeStep === 0) {
      dispatch(SetLeadUpdatedData(id));
    } else if (activeStep === 1) {
      dispatch(SetPricequoteUpdatedData(quote_id));
    } else if (activeStep === 2) {
      dispatch(GetAplicationDetails(application_id));
    }
  }, []);
  useEffect(() => {
    dispatch(
      GetLeadsnput(
        "terminal_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "card_terminal"
        )
      )
    );
    dispatch(
      GetLeadsnput(
        "ecommerce_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "ecom"
        )
      )
    );
    dispatch(
      GetLeadsnput(
        "epos_products",
        leadInput?.lead_products?.filter(
          (item) => item?.product_type === "epos"
        )
      )
    );
  }, []);
  useEffect(() => {
    if (leadInput.contact_full_name === null) {
      dispatch(GetLeadsnput("contact_full_name", ""));
    }
    dispatch(GetSalesPartnerList(1));
    dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/?role=2`));
  }, []);
  useEffect(() => {
    // Save the active step to localStorage when it changes
    localStorage.setItem("activeStep", activeStep);
  }, [activeStep]);

  return (
    <>
      <div className="breadcump">
        <p>
          {" "}
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            {" "}
            Home /{" "}
          </span>
          <span
            onClick={() => history.push("/leads")}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            {" "}
            Leads /{" "}
          </span>
          {/* <span style={{ cursor: "not" }}>Add Leads</span> */}
          {leadInput.active_lead === true ? (
            <span style={{ cursor: "not" }}> Leads</span>
          ) : leadInput.active_quote === true ? (
            <span style={{ cursor: "not" }}>Price Quote</span>
          ) : (
            leadInput.active_application === true && (
              <span style={{ cursor: "not" }}>New Application</span>
            )
          )}
        </p>
      </div>
      <div className="box-wrapper">
        <Box sx={{ width: "100%", marginTop: "30px" }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      {...labelProps}
                      StepIconComponent={ColorlibStepIcon}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Stack>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {/* <NewLeadTab next={handleNext} /> */}
                  <Leadtab next={handleNext} />
                </Typography>
              )}
              {activeStep === 1 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <PriceQuoteTabs next={handleNext} />
                </Typography>
              )}
              {activeStep === 2 && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <ApplicationTabs />
                </Typography>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                ></Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "" : ""}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </div>
    </>
  );
}
