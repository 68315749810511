import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import Store from "./redux/store/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client"; // updated import statement

require("./services/axios");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // use createRoot from "react-dom/client"

root.render(
  <Provider store={Store()}>
    <BrowserRouter>
      <App /> <ToastContainer />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();