import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../asstes/icon.bablu/add-file.svg";

import { useEffect, useState } from "react";

import cross from "../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";

import { connectionLength, provider_list } from "../commons/Dropdown";
import Button from "@mui/material/Button";
import {
  GetLeadsnput,
  GetTerminalProductInput,
} from "../../_redux/action/LeadsAction";
import { BASE_URL } from "../../../../../Const";
import { GetAllProductList } from "../newApplication/_redux/action/NewapplicationAction";
import {
  REMOVE_CARD_TERMINAL_PRODUCT,
  REMOVE_LEAD_PRODUCT,
  SET_CARD_TERMINAL_PRODUCT,
  SET_LEAD_PRODUCT,
} from "../../_redux/types/Types";

function CardTypeProduct({ type_name, id, lead_product }) {
  const epos_name = (
    <p>
      Provide EPOS Name <span className="required">*</span>
    </p>
  );

  const dispatch = useDispatch();
  const [elavonProduct, setelavonProduct] = useState([]);

  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  const allProductList = useSelector(
    (state) => state.applicationInfo.allProductList
  );
  useEffect(() => {
    fetch(`${BASE_URL}api/v1/products/product/?provider=0&crm_product_type=0`, {
      method: "GET",
      headers: {
        authorization: `Token ${localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setelavonProduct(data.data.results));
  }, []);

  useEffect(() => {
    dispatch(
      GetAllProductList(
        `${BASE_URL}api/v1/products/product/?provider=1&crm_product_type=0`
      )
    );
   
  }, []);

  const handleAddMore = () => {
    dispatch({ type: SET_CARD_TERMINAL_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_CARD_TERMINAL_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetTerminalProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(GetTerminalProductInput(name, value, index, "terminal_products"));
    if (name === "terminal_provider" && value === 0) {
      dispatch(
        GetTerminalProductInput("product_term", 0, index, "terminal_products")
      );
    } else if (name === "terminal_provider" && value === 1) {
      dispatch(
        GetTerminalProductInput("product_term", 18, index, "terminal_products")
      );
    }
  };

  const handleProductFill = (value, index) => {
    console.log("value", value);
    dispatch(
      GetTerminalProductInput("product", value.id, index, "terminal_products")
    );
    dispatch(
      GetTerminalProductInput(
        "connection_type",
        value?.connection_type[0]?.id,
        index
      )
    );

    dispatch(
      GetTerminalProductInput("terminal_model", value?.model_name, index)
    );
    dispatch(GetTerminalProductInput("price", value?.price, index));
    dispatch(GetTerminalProductInput("lead_id", leadInput?.id, index));
  };
  return (
    <>
      {leadInput?.terminal_products?.length < 1 && (
        <div className="d-flex justify-content-between heading mb-form mt-4 ">
          <button className="fill_add_button" onClick={() => handleAddMore()}>
            <img
              width={14}
              style={{ marginRight: "14px", marginBottom: "3px" }}
              height={14}
              src={addFileIcon}
              alt=""
            />
            ADD PRODUCT
          </button>
        </div>
      )}

      <div
        className="container row select_div"
        style={{ marginBottom: "-15px " }}
      >
        {leadInput?.terminal_products?.map((product, i) => {
          return (
            <>
              {product.is_deleted === false && (
                <div
                  className="col-12 col-md-6 mb-3 text-capitalize"
                  key={product.id}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(250, 250, 250)",
                      borderRadius: "8px",
                      padding: "40px 15px",
                      margin: "15px 0px",
                      border: "0.5px solid rgb(224, 224, 224)",
                    }}
                  >
                    <div
                      // onClick={() => handleremoveDoc(i)}
                      onClick={() => {
                        product.id
                          ? handleremoveProductTwo(i)
                          : handleremoveProduct(i);
                      }}
                      className="cross d-flex"
                      style={{
                        border: "1px solid #FFCDD2",
                        width: "24px",
                        borderRadius: "50%",
                      }}
                    >
                      <img src={cross} alt="" />
                    </div>
                    <>
                      <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Terminal Option: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "MR", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "MR"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              MONTHLY RENTAL
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "terminal_option",
                                  "OUTRIGHT",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.terminal_option === "OUTRIGHT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              OUTRIGHT
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "ET", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "ET"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              ELAVON TERMINAL
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput("terminal_option", "FT", i)
                              }
                              size="small"
                              variant={
                                product.terminal_option === "FT"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              FREE TERMINAL
                            </Button>
                          </>
                        </div>
                      </div>
                      <div className="row mb-form">
                        <div className="col-md-4 col-lg-3">
                          <p className="m-2">Integration Availability: </p>
                        </div>
                        <div className="col-md-8 col-lg-9">
                          <div>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "integration_availability",
                                  "INTEGRATED",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.integration_availability ===
                                "INTEGRATED"
                                  ? "contained"
                                  : "outlined"
                              }
                              // variant="outlined"
                            >
                              INTEGRATED
                            </Button>
                            <Button
                              className="m-2"
                              onClick={() =>
                                handleChangeInput(
                                  "integration_availability",
                                  "STAND ALONE",
                                  i
                                )
                              }
                              size="small"
                              variant={
                                product.integration_availability ===
                                "STAND ALONE"
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              STAND ALONE
                            </Button>
                          </div>
                        </div>
                      </div>
                      {product.integration_availability === "INTEGRATED" && (
                        <div className="mb-form ">
                          <TextField
                            id="outlined-basic"
                            label={epos_name}
                            variant="outlined"
                            size="small"
                            type="text"
                            name="epos_name"
                            value={product.epos_name}
                            onChange={(e) => {
                              handleChangeInput("epos_name", e.target.value, i);
                            }}
                          />
                        </div>
                      )}
                    </>
                    <div className="mb-form">
                      <div className="col-lg-12 mb-4">
                        <TextField
                          size="small"
                          id="outlined-select-currency"
                          select
                          label="Terminal provider"
                          name="terminal_provider"
                          value={product.terminal_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "terminal_provider",
                              e.target.value,
                              i
                            );
                          }}
                        >
                          {provider_list?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="mb-form">
                      {product.terminal_provider === 1 ? (
                        <div className="col-lg-12 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Search product"
                            name="product"
                            value={product?.product}
                            onChange={(e) => {
                              allProductList?.map((item, index) => {
                                if (item.id === e.target.value) {
                                  dispatch(
                                    GetTerminalProductInput(
                                      "connection_type",
                                      item?.connection_type[0]?.id,
                                      i
                                    )
                                  );

                                  dispatch(
                                    GetTerminalProductInput(
                                      "terminal_model",
                                      item?.model_name,
                                      i
                                    )
                                  );
                                  dispatch(
                                    GetTerminalProductInput(
                                      "price",
                                      item?.price,
                                      i
                                    )
                                  );
                                  dispatch(
                                    GetTerminalProductInput(type_name, id, i)
                                  );
                                }
                              });

                              handleChangeInput("product", e.target.value, i);
                            }}
                          >
                            {allProductList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      ) : (
                        product.terminal_provider === 0 && (
                          <div className="col-lg-12 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Search product"
                              name="product"
                              value={product?.product}
                              onChange={(e) => {
                                elavonProduct?.map((item, index) => {
                                  if (item.id === e.target.value) {
                                    dispatch(
                                      GetTerminalProductInput(
                                        "connection_type",
                                        item?.connection_type[0]?.id,
                                        i
                                      )
                                    );

                                    dispatch(
                                      GetTerminalProductInput(
                                        "terminal_model",
                                        item?.model_name,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetTerminalProductInput(
                                        "price",
                                        item?.price,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetTerminalProductInput(type_name, id, i)
                                    );
                                  }
                                });

                                handleChangeInput("product", e.target.value, i);
                              }}
                            >
                              {elavonProduct?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option?.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )
                      )}
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Connection Type"
                            name="connection_type"
                            value={product.connection_type}
                            onChange={(e) =>
                              handleChangeInput(
                                "connection_type",
                                e.target.value,
                                i
                              )
                            }
                          >
                            {product.terminal_provider === 0 &&
                              elavonProduct?.map((option) =>
                                option.id === product.product
                                  ? option.connection_type.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : null
                              )}
                            {product.terminal_provider === 1 &&
                              allProductList?.map((option) =>
                                option.id === product.product
                                  ? option.connection_type.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : null
                              )}
                          </TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            disabled={product.terminal_provider === 0 && true}
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="Contact Length"
                            name="product_term"
                            value={product.product_term}
                            onChange={(e) =>
                              handleChangeInput(
                                "product_term",
                                e.target.value,
                                i
                              )
                            }
                          >
                            {connectionLength?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            inputProps={{ readOnly: true }}
                            id="outlined-select-currency"
                            //   select
                            label="Terminal model"
                            name="terminal_model"
                            value={
                              product.terminal_model
                                ? product.terminal_model
                                : ""
                            }
                            onChange={(e) => {
                              handleChangeInput(
                                "terminal_model",
                                e.target.value,
                                i
                              );
                            }}
                          ></TextField>
                        </div>

                        <div className="col-lg-6 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            label="Monthly price"
                            name="price"
                            InputLabelProps={{ shrink: true }}
                            value={product.price}
                            onChange={(e) =>
                              handleChangeInput("price", e.target.value, i)
                            }
                          />
                        </div>
                        <div className="col-lg-6 ">
                          <TextField
                            size="small"
                            type="number"
                            id="outlined-select-currency"
                            label="Quantity"
                            name="qty"
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{ shrink: true }}
                            value={product.qty}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            error={product.qty < 1}
                            onChange={(e) =>
                              handleChangeInput("qty", e.target.value, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
        {leadInput?.terminal_products?.length > 0 && (
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{ marginBottom: "40px" }}
          >
            <button className="fill_add_button" onClick={() => handleAddMore()}>
              <img
                width={14}
                style={{ marginRight: "14px", marginBottom: "3px" }}
                height={14}
                src={addFileIcon}
                alt=""
              />
              ADD PRODUCT
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default CardTypeProduct;
