import React from "react";
import detailIcon from "../../../../../../asstes/icon.bablu/detail-icon.svg";
import tic from "../../../../../../asstes/icon.bablu/tic.svg";

import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import { SubmitApplicationInput, SubmitNewApplicationInput } from "../_redux/action/NewapplicationAction";

import Files from "../Files";
const Documentedit = ({ documentDetailEdit }) => {
  const dispatch = useDispatch();

  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const handleSubmitAppplication = (data) => {
    if(data.id){
      dispatch(SubmitApplicationInput(data));
    }
    else{
      dispatch(SubmitNewApplicationInput(data));
    }
  };

  return (
    <div className="customar-detail">
      {/* -------------deatil head ------------------ */}
      <div className="customar-detail-head w-100 fees-box">
        <div className="head-first">
          <img src={detailIcon} className="small-img" alt="" />
          <h4 style={{ color: "white" }}>Document</h4>
        </div>
        <div className="button-box">
          <button
            style={{ backgroundColor: "#EB5757", padding: "6px 10px" }}
            className="    custom-btn  flex mx-2 "
            onClick={documentDetailEdit}
          >
            Cancel
          </button>
          <button
            className="   custom-btn  flex "
            onClick={() => {
              handleSubmitAppplication(applicationInput);
              documentDetailEdit();
            }}
          >
            <img
              src={tic}
              width={24}
              height={20}
              style={{ marginRight: "7px" }}
              alt=""
            />
            Done
          </button>
        </div>
      </div>

      <Files />
    </div>
  );
};

export default Documentedit;
