import React, { useEffect,useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Leads.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";

import {

  SetPricequoteUpdatedData,
  GetLeadsnput,
  SetQuoteStatusFalse,
  SetLeadsStatusFalse,
} from "../../../mainLead/_redux/action/LeadsAction";
import OffsetPagination from "../../../mainLead/components/commons/OffsetPagination";
import LoadingCheck from "../../../mainLead/components/commons/LoadingCheck";
import SvgIcon from "../../../mainLead/components/commons/SvgIcon";
import { LeadStatus } from "../../../mainLead/components/commons/LeadStatus";
import {

  SortArrayOfObjects,
} from "../../../mainLead/components/commons/Utils";
import {
  SetSigningStatusFalse,
  SetSigningStatusFalseTwo,
} from "../../../mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { opportunityStage } from "../../../mainLead/components/commons/Dropdown";
import { useReactToPrint } from "react-to-print";
const TableData = ({ check, search, item, options }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [is_checked, set_is_checked] = useState(0);
  const leadInput = useSelector((state) => state.leadInfo.leadInput);
  useEffect(() => {
    set_is_checked(check);
  }, [check]);
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const handleView = (data) => {
    dispatch(SetPricequoteUpdatedData(data));
    dispatch(GetLeadsnput("step", 2));
    history.push(`/viewlead/${data}`);
  };

  useEffect(() => {
    return () => {
      dispatch(SetQuoteStatusFalse());
      dispatch(SetLeadsStatusFalse());
    };
  }, []);
  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);
  const getButtonColor = (status) => {
    switch (status) {
      case 0:
        return "#09afd1";
      case 1:
        return "#198754";
      case 2:
        return "#0cbadd";
      case 3:
        return "#dc3545";
      case 4:
        return "#ab569a";
      case 5:
        return "#17479d";
      case 6:
        return "#32CD32";
      case 7:
        return "#d96a00";
      case 8:
        return "#FFA500";
      case 9:
        return "#ff8007";
      case 10:
        return "#17479d";
      default:
        return "#17479d";
    }
  };
  return (
    <>
      <tr className="lead_border">
        <td class="tbody_checkbox ">
          <div class="d-flex justify-content-around align-items-center">
            <input
              className="lead_checkbox"
              onClick={(e) => set_is_checked(!is_checked)}
              checked={is_checked}
              type="checkbox"
              aria-label=""
            />
            <span style={{ visibility: "hidden" }}>#</span>
          </div>
        </td>
        <td>
          <div className="lead_id_td">
            <p className="lead_table_data_p">{item?.client_id}</p>
          </div>
        </td>

        <td>
          <p className="lead_table_data_p">{item.legal_name}</p>
          {/* <input
            className="lead_table_data_input"
            type="text"
            value={item.legal_name}
          /> */}
        </td>
        <td>
          <p className="lead_table_data_p">{item.trading_name}</p>
          {/* <input
            className="lead_table_data_input"
            type="text"
            value={item.trading_name}
          /> */}
        </td>

        <td
          className=" d-flex justify-content-center"
          style={{ borderBottomWidth: 0 }}
        >
            <button
              style={{
                backgroundColor: getButtonColor(item.opportunity_stage),
                marginLeft: "-8px",
              }}
              className={`btn-big text-center rounded-[4px] my-[3px]`}
            >
            {opportunityStage.map((option) => {
              return (
                <>
                  {option.value === item.opportunity_stage && (
                    <span key={option.value} value={option.value}>
                      {option?.label}
                    </span>
                  )}
                </>
              );
            })}
            </button>
          {/* <p>
            {opportunityStage.map((option) => {
              return (
                <>
                  {option.value === item.opportunity_stage && (
                    <span key={option.value} value={option.value}>
                      {option?.label}
                    </span>
                  )}
                </>
              );
            })}
          </p> */}
          {/* <input
            className="lead_table_data_input"
            type="text"
            value={
              LeadStatus[item.opportunity_status] === undefined
                ? "Warning!!!Add New Status to leadStatus"
                : LeadStatus[item.opportunity_status]
            }
          /> */}
        </td>

        <td>
          <p className="lead_table_data_p">
          {new Date(item?.created_at).getDate()}/{new Date(item?.created_at).getMonth() + 1}/{new Date(item?.created_at).getFullYear()} at {new Date(item?.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
            {/* {new Date(item.created_at).toLocaleDateString("en-US", options)} */}
          </p>
        </td>

        <td>
          <div
            className="d-flex justify-content-center"
            onClick={() => handleView(item.id)}
          >
            <Link to="#" className="view_a">
              View
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};
const LeadWithOffsetPagination = () => {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    if (isLoggedIn === "false") {
      history.push("/");
    }
    localStorage.removeItem("activeStep");
    localStorage.removeItem("selectedTab");
    
    localStorage.removeItem("quote_id");
    localStorage.removeItem("application_id");
    localStorage.removeItem("appId");
    localStorage.removeItem("appItemPage");
    localStorage.removeItem("leadId");
  }, []);
  const [SortingData, setSortingData] = useState({
    ascending: -1,
    sort_by: "",
  });
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [is_all_checked, set_is_all_checked] = useState(0);
  const [FilterInputs, setFilterInputs] = useState({
    lead_id_input: "",
    account_name_input: "",
    trading_name_input: "",
    status_input: "",
    created_on_input: "",
  });

  const handleFilterInput = (e) => {
    const FilterInputName = e.target.name;
    const FilterInputval = e.target.value;
    setFilterInputs((previous_values) => {
      return { ...previous_values, [FilterInputName]: FilterInputval };
    });
  };

  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dispatch = useDispatch();

  // --------------api call-----------------
  const paginationName = "priceQuote";
  const mid_url = "api/v1/quotes/quote/";
  const priceQuoteList = useSelector((state) =>
    state.Pagination.data[paginationName].results != null
      ? state.Pagination.data[paginationName].results
      : []
  );
  const leadsList_url = useSelector((state) =>
    state.Pagination.url[paginationName] !== ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const is_loading = useSelector(
    (state) => state.Pagination.IsLoading[paginationName]
  );
  // const is_loading = useSelector((state) => (state.Pagination.IsLoading != null ? state.IsLoading : true));
  const URL = useSelector((state) =>
    state.Pagination.url[paginationName] != ""
      ? state.Pagination.url[paginationName]
      : ""
  );
  const afterSuccessSigning = useSelector(
    (state) => state.applicationInfo.afterSuccessSigning
  );
  const afterSuccessSigningTwo = useSelector(
    (state) => state.applicationInfo.afterSuccessSigningTwo
  );

  const isLoadLeads = useSelector(
    (state) => state.Pagination.isLoadLeads[paginationName]
  );
  const limit = useSelector((state) => state.Pagination.limit[paginationName]);
  // useEffect(() => {
  //   dispatch(setpaginationName(paginationName));
  //   dispatch(PaginationUrl(`${BASE_URL}${mid_url}?limit=${limit}&offset=0`));
  // }, []);
  // useEffect(() => {
  //   dispatch(GetPaginationData(leadsList_url));
  // }, [leadsList_url]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("is_logged_in") || "false";
    const userData = JSON.parse(localStorage.getItem("userData")) || undefined;
    if (isLoggedIn === "false") {
      history.push("/");
    }
    localStorage.removeItem("activeStep");
    localStorage.removeItem("selectedTab");
    
    localStorage.removeItem("quote_id");
    localStorage.removeItem("application_id");
    localStorage.removeItem("appId");
    localStorage.removeItem("appItemPage");
    localStorage.removeItem("leadId");
  }, []);
  useEffect(() => {
    return () => {
      dispatch(SetLeadsStatusFalse());
    };
  }, []);
  useEffect(() => {
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    dispatch(GetLeadsnput("active_application", false));
  }, []);
  useEffect(() => {
    if (afterSuccessSigning) {
      dispatch(SetSigningStatusFalse());
    }
  }, [afterSuccessSigning]);

  useEffect(() => {
    if (afterSuccessSigningTwo) {
      dispatch(SetSigningStatusFalseTwo());
    }
  }, [afterSuccessSigningTwo]);
  // --------------------------export pdf-----------------------
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#leads-table", theme: "grid" });
    doc.text("lead table", 20, 10);

    doc.save("Leads table.pdf");
  };
  // -print pdf------------
  const cRef = useRef();
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: " Price Quote List",
    bodyClass: "dis",
  });
  const getPrint = () => {
    HandlePrint();
  };
  const FilteredLeadList =
    priceQuoteList !== null &&
    priceQuoteList.length > 0
      ? SortArrayOfObjects(
          priceQuoteList,
          SortingData.ascending,
          SortingData.sort_by
        )
          .filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val.client_id
                ?.toString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return val;
            } else if (
              val.legal_name.toLowerCase().includes(search.toLowerCase())
            ) {
              return val;
            } else if (
              val.trading_name.toLowerCase().includes(search.toLowerCase())
            ) {
              return val;
            }
          })
          .filter((current) => {
            if (
              current.client_id
                ?.toString()
                .toLowerCase()
                .includes(FilterInputs.lead_id_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            const status =
              LeadStatus[current.opportunity_status] === undefined
                ? ""
                : LeadStatus[current.opportunity_status].toLowerCase();
            if (status.includes(FilterInputs.status_input.toLowerCase())) {
              return current;
            }
          })
          // .filter((current) => {
          //   if (current.bank_account_name !== null ? current.bank_account_name.toString().toLowerCase().includes(FilterInputs.account_name_input.toLowerCase()) : "") {
          //     return current;
          //   }
          // })
          .filter((current) => {
            if (
              current?.bank_account_name !== "" 
             .toLowerCase()
                .includes(FilterInputs.account_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            if (
              current.trading_name
                .toLowerCase()
                .includes(FilterInputs.trading_name_input.toLowerCase())
            ) {
              return current;
            }
          })
          .filter((current) => {
            const converted_date = new Date(current.created_at)
              .toLocaleDateString("en-US", options)
              .toLowerCase();
            if (FilterInputs.created_on_input === "") {
              return current;
            } else if (
              converted_date.includes(
                FilterInputs.created_on_input.toLowerCase()
              )
            ) {
              return current;
            }
          })
      : [];
  return (
    <>
      <div className="custom_breadcrumb">
        <p>
          <span
            onClick={() => history.push(`/home`)}
            style={{ color: "#321FDB", cursor: "pointer" }}
          >
            Home /
          </span>
          <span style={{ cursor: "not" }}> Price Quote</span>
        </p>
      </div>

      <div className="padding_lead box-wrapper2">
        <div className="row ">
          <p className="leads_heading">Price Quote Table</p>
          <div className="col-lg-6  col-sm-4 col-md-6 mb-3 ">
            <div className="form-group form-group-lead ">
              <div className="position-relative">
                <input
                  type="text"
                  className=""
                  id="lead_search"
                  placeholder="Search.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.8487 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.8487 16 8 16C3.58172 16 0 12.4183 0 8Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="export_add_container col-lg-6 c0l-sm-6 col-md-6 d-flex mb-3">
            <button className="btn_export" 
            // onClick={HandlePdf}
            onClick={() => getPrint()}
            >
              Export
            </button>
            <button
              className="btn_price_quote d-flex justify-content-evenly align-items-center "
              onClick={() => {
                history.push(`/addlead`);
                dispatch(GetLeadsnput("step", 1));
              }}
            >
              <span className="me-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C8.5523 0 9 0.44772 9 1V7H15C15.5523 7 16 7.4477 16 8C16 8.5523 15.5523 9 15 9H9V15C9 15.5523 8.5523 16 8 16C7.4477 16 7 15.5523 7 15V9H1C0.44772 9 0 8.5523 0 8C0 7.4477 0.44772 7 1 7H7V1C7 0.44772 7.4477 0 8 0Z"
                    fill="white"
                  />
                </svg>
              </span>
              New Price Quote
            </button>
          </div>

          <div className="lead_table_container table-responsive">
            <table ref={cRef} className="table lead_table " id="leads-table">
              <thead className="lead_thead lead_thead1">
                <tr className="application-main-head">
                  <th className="checkBox_size">
                    <div className="head_data_container d-flex justify-content-around ">
                      <input
                        className="lead_checkbox "
                        type="checkbox"
                        aria-label=""
                        checked={is_all_checked}
                        onChange={(e) => {
                          set_is_all_checked(!is_all_checked);
                        }}
                      />
                      <span className="spanHash">#</span>
                    </div>
                  </th>
                  <th className="lead_id_size">
                    <div className="lead_id_text head_data_container">
                      <span className="header_text_margin">Client ID</span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="client_id"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="account_name_size">
                    <div className="head_data_container account_name_text">
                      <span className="header_text_margin">Legal Name</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="legal_name"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className=" trading_name_size ">
                    <div className="head_data_container trading_name_text">
                      <span className="header_text_margin">Trading Name</span>
                      <span className="span_updown">
                        <SvgIcon
                          name="trading_name"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="status_size">
                    <div className="head_data_container  status_text">
                      <span>Status</span>
                    </div>
                  </th>
                  <th className="create_on_size">
                    <div className="head_data_container">
                      <span className="header_text_margin">Create On</span>
                      <span className=" span_updown">
                        <SvgIcon
                          name="created_at"
                          SortingData={SortingData}
                          setSortingData={setSortingData}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="action_size">
                    <div className="action_div">
                      <span class="span_text">Action</span>
                    </div>
                  </th>
                </tr>
              </thead>
              {/* 2nd thead */}
              <thead className="lead_thead ">
                <tr className="">
                  <th className="lead_border_without_right"></th>
                  <th className="lead_id_size lead_border_bottom">
                    <input
                      type="text"
                      name="lead_id_input"
                      onChange={handleFilterInput}
                      className="lead_id_input"
                    />
                  </th>
                  <th className="account_name_size lead_border_bottom">
                    <input
                      type="text"
                      name="account_name_input"
                      onChange={handleFilterInput}
                      className="account_name_input"
                    />
                  </th>

                  <th className="trading_name_size lead_border_bottom">
                    <input
                      type="text"
                      name="trading_name_input"
                      onChange={handleFilterInput}
                      className="trading_name_input"
                    />
                  </th>
                  <th className="status_size lead_border_bottom">
                    <input
                      type="text"
                      name="status_input"
                      onChange={handleFilterInput}
                      className="status_input "
                    />
                  </th>
                  <th className="create_on_size lead_border_bottom">
                    <input
                      type="text"
                      name="created_on_input"
                      onChange={handleFilterInput}
                      className="create_on_input"
                    />
                  </th>

                  <th className="lead_border_without_left"></th>
                </tr>
              </thead>
              <tbody className="">
                <LoadingCheck
                  isLoadLeads={isLoadLeads}
                  is_loading={is_loading}
                  FilteredLeadList={FilteredLeadList}
                />

                {!is_loading &&
                  FilteredLeadList.length > 0 &&
                  FilteredLeadList.map((item, index) => {
                    return (
                      <TableData
                        check={is_all_checked}
                        search={search}
                        isLoadLeads={isLoadLeads}
                        priceQuoteList={priceQuoteList}
                        item={item}
                        options={options}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <OffsetPagination PaginationName={paginationName} URL_MID={mid_url} search={search}/>
      </div>
    </>
  );
};

export default LeadWithOffsetPagination;
