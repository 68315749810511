import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import addFileIcon from "../../../../../asstes/icon.bablu/add-file.svg";


import { useEffect, useState } from "react";

import cross from "../../../../../asstes/icon.bablu/cross.svg";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import "react-toastify/dist/ReactToastify.css";

import Autocomplete from "@mui/material/Autocomplete";
import { connectionLength, provider_list } from "../commons/Dropdown";

import {
  GetPricequoteInput,
  GetQuoteProductInput,
} from "../../_redux/action/LeadsAction";
import { GetAllProductList } from "../newApplication/_redux/action/NewapplicationAction";
import {
  SET_QUOTE_PRODUCT,
  REMOVE_QUOTE_PRODUCT,
} from "../../_redux/types/Types";
import { BASE_URL } from "../../../../../Const";
function QuoteProducts() {
  const dispatch = useDispatch();
  const [elavonProduct, setelavonProduct] = useState([]);

  const priceQuoteInput = useSelector(
    (state) => state.leadInfo.priceQuoteInput
  );
  const allProductList = useSelector(
    (state) => state.applicationInfo.allProductList
  );
  useEffect(() => {
    fetch(`${BASE_URL}api/v1/products/product/?provider=0`, {
      method: "GET",
      headers: {
        authorization: `Token ${localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setelavonProduct(data.data.results));
  }, []);

  useEffect(() => {
    dispatch(
      GetAllProductList(`${BASE_URL}api/v1/products/product/?provider=1`)
    );
  }, []);

  const handleAddMore = () => {
    dispatch({ type: SET_QUOTE_PRODUCT, payload: false });
  };
  const handleremoveProduct = (i) => {
    dispatch({ type: REMOVE_QUOTE_PRODUCT, payload: i });
  };

  const handleremoveProductTwo = (i) => {
    dispatch(GetQuoteProductInput("is_deleted", true, i));
  };

  const handleChangeInput = (name, value, index) => {
    dispatch(GetQuoteProductInput(name, value, index, "quote_products"));
    if (name === "provider" && value === 0) {
      dispatch(
        GetQuoteProductInput("product_term", 0, index, "quote_products")
      );
    } else if (name === "provider" && value === 1) {
      dispatch(
        GetQuoteProductInput("product_term", 18, index, "quote_products")
      );
    }
  };

  return (
    <>
      <div className="form-wrapper mx-0 mx-md-4">
        <div className="d-flex justify-content-between heading mb-form ">
          <div className="d-flex file_text mx-0 mx-md-3">
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                fill="#0D0D0D"
              />
            </svg>
            <p style={{ marginBottom: "10px" }}>Products </p>
          </div>
          {priceQuoteInput?.quote_products?.length < 1 && (
            <div>
              <button
                className="fill_add_button"
                onClick={() => handleAddMore()}
              >
                <img
                  width={14}
                  style={{ marginRight: "14px", marginBottom: "3px" }}
                  height={14}
                  src={addFileIcon}
                  alt=""
                />
                ADD PRODUCT
              </button>
            </div>
          )}
        </div>
        <div
          className="container row select_div"
          style={{ marginBottom: "-15px " }}
        >
          {priceQuoteInput?.quote_products?.map((product, i) => {
            return (
              <>
                {product.is_deleted === false && (
                  <div
                    className="col-12 col-md-6 mb-3 text-capitalize"
                    key={product.id}
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(250, 250, 250)",
                        borderRadius: "8px",
                        padding: "40px 15px",
                        margin: "15px 0px",
                        border: "0.5px solid rgb(224, 224, 224)",
                      }}
                    >
                      <div
                        // onClick={() => handleremoveDoc(i)}
                        onClick={() => {
                          product.id
                            ? handleremoveProductTwo(i)
                            : handleremoveProduct(i);
                        }}
                        className="cross d-flex"
                        style={{
                          border: "1px solid #FFCDD2",
                          width: "24px",
                          borderRadius: "50%",
                        }}
                      >
                        <img src={cross} alt="" />
                      </div>
                      <div className="mb-form">
                        <div className="col-lg-12 mb-4">
                          <TextField
                            size="small"
                            id="outlined-select-currency"
                            select
                            label="provider"
                            name="provider"
                            value={product.provider}
                            onChange={(e) => {
                              handleChangeInput("provider", e.target.value, i);
                           
                            }}
                          >
                            {provider_list?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>

                      <div className="mb-form">
                        {/* {!isLoadLeads &&
                          allProductList &&
                          allProductList !== null &&
                          allProductList.length > 0 && ( */}
                        {product.provider === 1 ? (
                          <div className="col-lg-12 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Search product"
                              name="product"
                              value={product?.product}
                              onChange={(e) => {
                                allProductList?.map((item, index) => {
                                  if (item.id === e.target.value) {
                                    dispatch(
                                      GetQuoteProductInput(
                                        "connection_type",
                                        item?.connection_type[0]?.id,
                                        i
                                      )
                                    );

                                    // dispatch(
                                    //   GetQuoteProductInput(
                                    //     "product_term",
                                    //     item.product_term[0].months,
                                    //     i
                                    //   )
                                    // );
                                    dispatch(
                                      GetQuoteProductInput(
                                        "terminal_model",
                                        item?.model_name,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetQuoteProductInput(
                                        "price",
                                        item?.price,
                                        i
                                      )
                                    );
                                    dispatch(
                                      GetQuoteProductInput(
                                        "quote",
                                        priceQuoteInput?.id,
                                        i
                                      )
                                    );
                                  }
                                });

                                handleChangeInput("product", e.target.value, i);
                              }}
                            >
                              {allProductList?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option?.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        ) : (
                          product.provider === 0 && (
                            <div className="col-lg-12 mb-4">
                              <TextField
                                size="small"
                                id="outlined-select-currency"
                                select
                                label="Search product"
                                name="product"
                                value={product?.product}
                                onChange={(e) => {
                                  elavonProduct?.map((item, index) => {
                                    if (item.id === e.target.value) {
                                      dispatch(
                                        GetQuoteProductInput(
                                          "connection_type",
                                          item?.connection_type[0]?.id,
                                          i
                                        )
                                      );

                                      dispatch(
                                        GetQuoteProductInput(
                                          "terminal_model",
                                          item?.model_name,
                                          i
                                        )
                                      );
                                      dispatch(
                                        GetQuoteProductInput(
                                          "price",
                                          item?.price,
                                          i
                                        )
                                      );
                                      dispatch(
                                        GetQuoteProductInput(
                                          "quote",
                                          priceQuoteInput?.id,
                                          i
                                        )
                                      );
                                    }
                                  });

                                  handleChangeInput(
                                    "product",
                                    e.target.value,
                                    i
                                  );
                                }}
                              >
                                {elavonProduct?.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option?.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          )
                        )}
                      </div>
                      <div className="">
                        <div className="row">
                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Connection Type"
                              name="connection_type"
                              value={product.connection_type}
                              onChange={(e) =>
                                handleChangeInput(
                                  "connection_type",
                                  e.target.value,
                                  i
                                )
                              }
                            >
                              {product.provider === 0 &&
                                elavonProduct?.map((option) =>
                                  option.id === product.product
                                    ? option.connection_type.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))
                                    : null
                                )}
                              {product.provider === 1 &&
                                allProductList?.map((option) =>
                                  option.id === product.product
                                    ? option.connection_type.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))
                                    : null
                                )}
                            </TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              disabled={product.provider === 0 && true}
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="Contact Length"
                              name="product_term"
                              value={product.product_term}
                              onChange={(e) =>
                                handleChangeInput(
                                  "product_term",
                                  e.target.value,
                                  i
                                )
                              }
                            >
                              {/* {allProductList?.map((option) =>
                                    option.id === product.product
                                      ? option.product_term.map((item) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.months}
                                          </MenuItem>
                                        ))
                                      : null
                                  )} */}
                              {connectionLength?.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              //   select
                              label="Terminal model"
                              name="terminal_model"
                              value={
                                product.terminal_model
                                  ? product.terminal_model
                                  : ""
                              }
                              onChange={(e) => {
                                // allProductList?.map(
                                //   (code) =>
                                //     product.product === code.id &&
                                //     dispatch(
                                //       GetApplicationInput(
                                //         "terminal_model",
                                //         code.model_name,
                                //         i
                                //       )
                                //     )
                                // );
                                handleChangeInput(
                                  "terminal_model",
                                  e.target.value,
                                  i
                                );
                              }}
                            ></TextField>
                          </div>

                          <div className="col-lg-6 mb-4">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              label="Monthly price"
                              name="price"
                              InputLabelProps={{ shrink: true }}
                              value={product.price}
                              onChange={(e) =>
                                handleChangeInput("price", e.target.value, i)
                              }
                            />
                          </div>
                          <div className="col-lg-6 ">
                            <TextField
                              size="small"
                              type="number"
                              id="outlined-select-currency"
                              label="Quantity"
                              name="qty"
                              onWheel={(e) => e.target.blur()}
                              InputLabelProps={{ shrink: true }}
                              value={product.qty}
                              InputProps={{
                                inputProps: { min: 1 },
                              }}
                              error={product.qty < 1}
                              onChange={(e) =>
                                handleChangeInput("qty", e.target.value, i)
                              }
                            />
                          </div>
                          {/* <div className="col-lg-6 ">
                            <TextField
                              size="small"
                              id="outlined-select-currency"
                              select
                              label="provider"
                              name="provider"
                              value={product.provider}
                              onChange={(e) =>
                                handleChangeInput("provider", e.target.value, i)
                              }
                            >
                              {provider_list?.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {priceQuoteInput?.quote_products?.length > 0 && (
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <button
                className="fill_add_button"
                onClick={() => handleAddMore()}
              >
                <img
                  width={14}
                  style={{ marginRight: "14px", marginBottom: "3px" }}
                  height={14}
                  src={addFileIcon}
                  alt=""
                />
                ADD PRODUCT
              </button>
            </div>
          )}
        </div>

        {/* <div className="mt-5 d-end">
          <div>
            <button
              className="success-btn"
              onClick={() => {
                handleConvertNewapplication(priceQuoteInput);
              }}
            >
              Convert to new Application
            </button>
          </div>
          <div>
            <button className="cancel-btn">Cancel</button>
          </div>
          <div>
            <button
              className="save-btn"
              onClick={() => {
                handleAddPricequote(userData.id, priceQuoteInput);
              }}
            >
              Save
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default QuoteProducts;
