import React from "react";
import { useSelector } from "react-redux";
import direct_debit from "./images/direct_debit.png";
import elavon_transparent from "./images/elavon_transparent.png";
const Pdf3 = ({ cRef,currentDate }) => {
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  // const currentDate = new Date()
  return (
    <>
      <section className="pdf3_section_container "  >
        <h1>
          Instruction to your Bank or Building Society to pay by Direct Debit
        </h1>
        <div
          className="pdf_row blue no-border signature"
          style={{ padding: "18px" }}
        >
          <table style={{ padding: "10px 20px" }}>
            <tbody>
              <tr>
                <td style={{ width: "60%" }}>
                  <p style={{ fontSize: "14px" }}>
                    <strong>
                      PLEASE COMPLETE SECTIONS 1 - 4 IN BLOCK CAPITALS USING A
                      BALL POINT PEN - DO NOT STAPLE FORMS TOGETHER. PLEASE
                      RETURN WITH YOUR COMPLETED APPLICATION FORM.
                    </strong>
                  </p>
                </td>
                <td style={{ width: "40%", textAlign: "end" }}>
                  <img
                    src={direct_debit}
                    width={125}
                    height="auto"
                    alt="Elavon"
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>
                  <div>
                    <h5>1. Name(s) of Account Holder(s)</h5>
                    <div className="accoutn_holder">
                      <input
                        type="text"
                        value={applicationInput?.bank_account_name}
                        style={{ margin: "0 0 10px 13px" }}
                      />
                      {/* <input type="text" style={{ margin: "0 0 10px 13px" }} /> */}
                    </div>
                  </div>
                  <div>
                    <h5>2. Bank/Building Society account number</h5>
                    <div
                      className="accoutn_bank"
                      style={{ display: "flex", margin: "0 0 10px 13px" }}
                    >
                      {applicationInput.bank_account_no && (
                        <>
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[0]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[1]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[2]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[3]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[4]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[5]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[6]}
                          />
                          <input
                            type="text"
                            value={applicationInput.bank_account_no[7]}
                          />
                        </>
                      )}
                    </div>
                    <div style={{ paddingLeft: "16px" }}>
                      <h5>Branch Sort Code</h5>
                      <div className="accoutn_bank" style={{ display: "flex" }}>
                        {applicationInput?.bank_sort_code && (
                          <>
                            
                        <input type="text" value={applicationInput?.bank_sort_code[0]} />
                        <input type="text" value={applicationInput?.bank_sort_code[1]} />
                        <input type="text" value={applicationInput?.bank_sort_code[2]} />
                        <input type="text" value={applicationInput?.bank_sort_code[3]} />
                        <input type="text" value={applicationInput?.bank_sort_code[4]} />
                        <input type="text" value={applicationInput?.bank_sort_code[5]} />
                          </>
                        )}
                        {/* <input type="text"  />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" />
                        <input type="text" /> */}
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-4">
                        3. Name and full postal address of your Bank or Building
                        Society
                      </h5>
                      <div style={{ paddingLeft: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "73%",
                            marginTop: "-20px",
                          }}
                        >
                          <h5>To: The Manager</h5>
                          <h5>Bank/Building Society</h5>
                        </div>
                        <div className="accoutn_holder">
                          <input type="text" style={{ marginBottom: "10px" }} value={applicationInput?.bank_name} />
                        </div>
                        <div style={{ marginTop: "-10px" }}>
                          <h5>Address</h5>
                          <div className="accoutn_holder">
                            <input
                              type="text"
                              style={{ marginBottom: "10px" }}
                            />
                            <input
                              type="text"
                              style={{ marginBottom: "10px" }}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="accoutn_holder">
                              <input
                                type="text"
                                style={{ marginBottom: "10px" }}
                              />
                            </div>
                            <div
                              className="accoutn_holder"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <label htmlFor>
                                <h5 style={{ marginTop: "8px" }}>Postcode</h5>
                              </label>
                              <input
                                type="text"
                                style={{ marginBottom: "10px", width: "164px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5>4. Signature(s)</h5>
                     
                    </div>
                  </div>
                </td>
                <td style={{ width: "40%", verticalAlign: "top" }}>
                  <div>
                    <h5>Originator’s Identification Number</h5>
                    <div
                      className="number"
                      style={{ display: "flex", gap: "4px" }}
                    >
                      <input type="text" defaultValue={9} />
                      <input type="text" defaultValue={7} />
                      <input type="text" defaultValue={7} />
                      <input type="text" defaultValue={3} />
                      <input type="text" defaultValue={0} />
                      <input type="text" defaultValue={4} />
                    </div>
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <h4>Instruction to your Bank or Building Society</h4>
                    <h5 style={{ lineHeight: "26px" }}>
                      Please pay Elavon Financial Services DAC Direct <br />
                      Debits from the account detailed in this Instruction{" "}
                      <br />
                      subject to the safeguards assured by the Direct Debit{" "}
                      <br />
                      Guarantee. I understand that this Instruction may <br />
                      remain with Elavon Financial Services DAC and, <br />
                      if so, details will be passed electronically to my <br />
                      Bank/Building Society
                    </h5>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Date</h5>
                  
                </td>
                <td style={{ textAlign: "end" }}>
                  <img
                    src={elavon_transparent}
                    width={125}
                    height="auto"
                    alt="Elavon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h4 className="scissors_div">
            <span
              style={{
                position: "relative",
                bottom: "-36px",
                left: "-96px",
                fontSize: "25px",
              }}
            >
              ✂
            </span>{" "}
            Banks and Building Societies may not accept Direct Debit
            Instructions from some types of accounts{" "}
          </h4>
          <h3 className="below_scissor_div " style={{ textAlign: "center" }}>
            This guarantee should be detached and retained by the Payer.
          </h3>
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px 10px 10px 10px",
              margin: "60px 0px 0px 0px",
            }}
          >
            <h5>The Direct Debit Guarantee</h5>
            <ul style={{ paddingLeft: "18px" }}>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                  This Guarantee is offered by all Banks and Building Societies
                  that take part in the Direct Debit Scheme, the efficiency and
                  security of the Scheme is monitored and protected by your own
                  Bank or Building Society.
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                  If the amounts to be paid or the payment dates change Elavon
                  Financial Services DAC will notify you within seven working
                  days in advance of your account being debited or as otherwise
                  agreed.
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                  If an error is made by Elavon Financial Services DAC or your
                  Bank Building Society, you are guaranteed a full and immediate
                  refund from your branch of the amount paid.
                </h5>
              </li>
              <li>
                <h5 style={{ marginBottom: "-13px", lineHeight: "34px" }}>
                  You can cancel a Direct Debit at any time by writing to your
                  Bank or Building Society. Please also send a copy of your
                  letter to us.
                </h5>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div style={{ display: "flex", gap: "4px" }}>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#232C77",
                    color: "#fff",
                    padding: "10px 20px",
                    height: "40px",
                    marginTop: "8px",
                  }}
                >
                  Direct Debit Form
                </button>
                <h5 style={{ marginTop: "14px" }}>
                  Northern Ireland and United Kingdom
                </h5>
              </div>
              <div>
                <img
                  src={direct_debit}
                  width={125}
                  height="auto"
                  alt="Elavon"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="footer">
        <p style={{ color: "#000g" }}>
          <strong>
            Elavon Financial Services DAC <br /> Registered in Ireland with
            Companies Registration Office. The liability of the member is
            limited. <br /> United Kingdom branch registered in England and
            Wales under the number BR022122. <br />
            Elavon Financial Services DAC, trading as Elavon Merchant Services
            is authorised and regulated by the Central Bank of Ireland.
            Authorised by the Prudential Regulation <br /> Authority and with
            deemed variation of permission. Subject to regulation by the
            Financial Conduct Authority and limited regulation by the Prudential
            Regulation Authority.
            <br />
            Details of the Temporary Permissions Regime, which allows EEA-based
            firms to operate in the UK for a limited period while seeking full
            authorisation, are available on the <br /> Financial Conduct
            Authority's website.
          </strong>
        </p>{" "}
        <img src={elavon_transparent} width={125} height="auto" alt="Elavon" />
      </div> */}
      <div id="01778AB9-D499-876C-4C53-2AD99C740AB3" />
    </>
  );
};

export default Pdf3;
