import React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

import ContactInfoBox from "./ContactInfoBox";

import { toast } from "react-toastify";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import { countryCode } from "./CountryCode";
import { useEffect } from "react";

// import Number from "./Number";
import Number from "../addLead/Number";
import { useDispatch, useSelector } from "react-redux";

import {
  GetApplicationInput,
  // GetCompanyHouseList,
  GetCompanyHouseDetails,
  GetCompanyHouseList,
} from "./_redux/action/NewapplicationAction";
import {
  GetCountryList,
  GetIndustryList,
  GetLeadsnput,
  GetSalesPartnerList,
  GetSourceList,
} from "../../_redux/action/LeadsAction";
import Button from "@mui/material/Button";
import {
  internet_service_provider,
  payment_service_provider,
} from "../commons/Dropdown";

import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { BASE_URL } from "../../../../../Const";
import { GetUserList } from "../../../../userManagement/_redux/action/UserAction";
export const legalName = (
  <p>
    Legal Name<span className="required">*</span>
  </p>
);
const ContactDetail = () => {
  const [codeId, setCodeId] = useState("");

  const dispatch = useDispatch();
  const sourceList = useSelector((state) => state.leadInfo.sourceList);
  const IndustryList = useSelector((state) => state.leadInfo.IndustryList);
  const salesPartnerList = useSelector(
    (state) => state.leadInfo.salesPartnerList
  );
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );
  const companyHouseList = useSelector(
    (state) => state.applicationInfo.companyHouseList
  );
  const AcquiringBank = (
    <p>
      Acquiring Bank <span className="required">*</span>
    </p>
  );
  const mobile_number = (
    <p>
      Mobile Number <span className="required">*</span>
    </p>
  );
 
  const TradingName = (
    <p>
      Trading Name<span className="required">*</span>
    </p>
  );

  const lead_source = (
    <p>
     Lead Source <span className="required">*</span>
    </p>
  );
  const values = {
    someDate: "2018-05-24",
  };

  const Application = [
    {
      value: 1,
      label: "New Application",
    },
    {
      value: 2,
      label: "New Ecom Ap",
    },
    {
      value: 3,
      label: "New VT App",
    },
    {
      value: 4,
      label: "Cole",
    },
    {
      value: 5,
      label: "Additional Location",
    },
    {
      value: 6,
      label: "Cole + Additional Location",
    },
    {
      value: 7,
      label: "Additional Location + Ecomm",
    },
    {
      value: 8,
      label: "Cole (Old)",
    },
    {
      value: 9,
      label: "Multiple Outlet",
    },
  ];
  const LegalType = [
    {
      value: "ST",
      label: "Sole Trader",
    },
    {
      value: "CL",
      label: "Club",
    },

    {
      value: "CH",
      label: "Charity",
    },
    {
      value: "TR",
      label: "Trust",
    },
    {
      value: "PART",
      label: "Partnership",
    },
    {
      value: "LLP",
      label: "Limited Liability Partnership",
    },
    {
      value: "L",
      label: "Private Limited Company",
    },
    {
      value: "PL",
      label: "Public Limited Company",
    },
    {
      value: "OT",
      label: "Other",
    },
  ];

  const Acquiring_Bank = [
    {
      value: "0",
      label: "Elavon",
    },
  ];
  const location = useLocation();
  const [addContact, setAddContact] = useState([]);
  const [ConCode, setConCode] = useState([]);
  const countryList = useSelector((state) => state.leadInfo.countryList);
  const isLoadLeads = useSelector((state) => state.leadInfo.isLoadLeads);
  const handleAddInfo = () => {
    setAddContact([...addContact, { id: new Date().getTime() }]);
    toast("Added Data SuccesFully!");
  };
  const handleDeleteInfo = (id) => {
    const filterFile = addContact.filter((file) => file.id !== id);
    setAddContact(filterFile);
    toast("Deleted Data SuccesFully!");
  };

  const company_house_no = (
    <p>
      Company House No<span className="required">*</span>
    </p>
  );

  const email = (
    <p>
      Primary Email<span className="required">*</span>
    </p>
  );
  const ownership = (
    <p>
      Current Ownership scince<span className="required">*</span>
    </p>
  );

  const application_type = (
    <p>
      Application Type<span className="required">*</span>
    </p>
  );
  const dob = (
    <p>
      Date of Birth<span className="required">*</span>
    </p>
  );
  const legal_type = (
    <p>
      Legal Type<span className="required">*</span>
    </p>
  );
  const industry = (
    <p>
      Choose a Industry<span className="required">*</span>
    </p>
  );

  const mcc = (
    <p>
      MCC Code<span className="required">*</span>
    </p>
  );
  // const fill = countryCode.filter((op) => op.code === codeId && op.dial_code);
  // useEffect(() => {
  //   setConCode(fill);
  // }, [codeId]);

  // ------------------------api call----------------------

  useEffect(() => {
    dispatch(GetSourceList(1));
    dispatch(GetIndustryList(1));
    dispatch(GetCountryList(1));

    // dispatch(GetSalesPartnerList(1));
    // dispatch(GetUserList(`${BASE_URL}api/v1/auth/user/?role=2`));
    dispatch(GetLeadsnput("active_lead", false));
    dispatch(GetLeadsnput("active_quote", false));
    if (
      location.pathname.includes("viewlead") ||
      location.pathname.includes("addlead")
    ) {
      dispatch(GetLeadsnput("active_application", true));
    } else {
      dispatch(GetLeadsnput("active_application", false));
    }
  }, []);

  const handleChangeInput = (name, value, index, contact = undefined) => {
    dispatch(GetApplicationInput(name, value));

    if (applicationInput.application_type === 2) {
      dispatch(GetApplicationInput("cole_from", ""));
      dispatch(GetApplicationInput("existing_mid", ""));
    } else if (applicationInput.application_type === 4) {
      dispatch(GetApplicationInput("existing_mid", ""));
      dispatch(GetApplicationInput("security_check", "N/A"));
      dispatch(GetApplicationInput("internet_service_provider", ""));
      dispatch(GetApplicationInput("internet_service_provider", ""));
    } else if (
      applicationInput.application_type === 5 ||
      applicationInput.application_type === 7
    ) {
      dispatch(GetApplicationInput("cole_from", ""));
      dispatch(GetApplicationInput("security_check", "N/A"));
      dispatch(GetApplicationInput("internet_service_provider", ""));
      dispatch(GetApplicationInput("internet_service_provider", ""));
    } else if (applicationInput.application_type === 6) {
      dispatch(GetApplicationInput("security_check", "N/A"));
      dispatch(GetApplicationInput("internet_service_provider", ""));
      dispatch(GetApplicationInput("internet_service_provider", ""));
    }
    if (value === "CH" && name === "legal_type") {
      dispatch(GetApplicationInput("company_house_no", ""));
    }
    if (value === "ST" && name === "legal_type") {
      dispatch(GetApplicationInput("company_house_no", ""));
      dispatch(
        GetApplicationInput(
          "is_director",
          false,
          index,

          "business_owners",
          contact
        )
      );
    }

    if (
      applicationInput.legal_type === "L" ||
      applicationInput.legal_type === "PL" ||
      applicationInput.legal_type === "LLP"
    ) {
      dispatch(GetApplicationInput("charity_number", ""));
    }
  };
  useEffect(() => {
    if (applicationInput.secured_by_elavon === "SECURED_PCI") {
      dispatch(GetApplicationInput("product_per_month_amt", "4.50"));
      dispatch(GetApplicationInput("non_compliance_per_month", "40.00"));
      dispatch(GetApplicationInput("customer_value_per_month", "N/A"));
    }
    if (applicationInput.mmsc === null || applicationInput.mmsc === "") {
      dispatch(GetApplicationInput("mmsc", 0));
    }
  }, []);

  const handleSelectBinCheck = (value) => {
    // setbinCheck(value);
    dispatch(GetApplicationInput("security_bin_check", value));
  };
  const handleSelectVelocityCheck = (value) => {
    // setVelocity(!velocity);
    dispatch(GetApplicationInput("security_velocity_check", value));
  };
  const handleSelectipGeo = (value) => {
    // setIpCheck(!ipCheck);
    dispatch(GetApplicationInput("security_ip_geo_check", value));
  };
  const handleSelectmaxAmount = (value) => {
    // setMaxAmount(!maxAmount);
    dispatch(GetApplicationInput("security_max_amt_check", value));
  };
  const handleSelectother = (value) => {
    // setOther(!other);
    dispatch(GetApplicationInput("security_other_check", value));
  };

  const getCountryIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === applicationInput.mobile_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getCountryTelephoneIndex = () => {
    var index = -1;
    countryList.map((opt) => {
      if (opt.id === applicationInput.phone_code) {
        index = countryList.indexOf(opt);
      }
    });
    return index;
  };

  const getIndustryIndex = () => {
    var index = -1;
    IndustryList.map((opt) => {
      if (opt.id === applicationInput.industry_type) {
        index = IndustryList.indexOf(opt);
      }
    });
    return index;
  };

  var expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <>
      <div className="">
        {isLoadLeads && (
          <Backdrop
            open
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div className="mt-4">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="info-box">
                <div className="d-flex heading mb-4">
                  <svg
                    width="24"
                    height="28"
                    viewBox="0 0 24 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
                      fill="#0D0D0D"
                    />
                  </svg>

                  <p>Business Details </p>
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Client ID"
                    variant="outlined"
                    size="small"
                    name="client_id"
                    InputLabelProps={{ shrink: true }}
                    value={applicationInput?.client_id || "N/A"}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={AcquiringBank}
                    variant="outlined"
                    size="small"
                    select
                    name="acquiring_bank"
                    value={applicationInput.acquiring_bank}
                    onChange={(e) => {
                      handleChangeInput("acquiring_bank", e.target.value);
                    }}
                  >
                    {Acquiring_Bank.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {!isLoadLeads &&
              sourceList &&
              sourceList !== null &&
              sourceList.length > 0 ? (
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={lead_source}
                    variant="outlined"
                    name="lead_source"
                    value={applicationInput.lead_source}
                    onChange={(e) => {
                      handleChangeInput("lead_source", e.target.value);
                    }}
                  >
                    {sourceList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) :
                <div className="mb-form">
              <TextField
                size="small"
                id="outlined-select-currency"
                select
                label="Lead source"
                variant="outlined"
                name="lead_source"
                value={applicationInput.lead_source}
                onChange={(e) => {
                  handleChangeInput("lead_source", e.target.value);
                }}
              >
                {sourceList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>}

                {/* ------application type------------------ */}
                <div className="inner-box">
                  <div className="mb-form">
                    <TextField
                      size="small"
                      id="outlined-select-currency"
                      label={application_type}
                      select
                      name="application_type"
                      value={applicationInput.application_type}
                      onChange={(e) => {
                        handleChangeInput("application_type", e.target.value);
                      }}
                    >
                      {Application.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {applicationInput.application_type === 4 ? (
                    <div className="mb-form">
                      <TextField
                        id="outlined-basic"
                        label="Cole From"
                        variant="outlined"
                        size="small"
                        name="cole_from"
                        value={applicationInput.cole_from}
                        onChange={(e) => {
                          handleChangeInput("cole_from", e.target.value);
                        }}
                      />
                    </div>
                  ) : applicationInput.application_type === 5 ||
                    applicationInput.application_type === 7 ? (
                    <div className="mb-form">
                      <TextField
                        id="outlined-basic"
                        label="Existing Mid"
                        variant="outlined"
                        size="small"
                        name="existing_mid"
                        value={applicationInput.existing_mid}
                        onChange={(e) => {
                          handleChangeInput("existing_mid", e.target.value);
                        }}
                      />
                    </div>
                  ) : applicationInput.application_type === 2 ? (
                    <>
                      <div className="mb-form">
                        <div className="row ">
                          <div className="col-md-12 col-lg-12">
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#333333",
                              }}
                            >
                              Which of the following security checks do you
                              undertake?:{" "}
                            </p>
                          </div>
                          <div className="col-md-12 col-lg-12 mt-4">
                            <div className="d-md-flex gap-3 flex-md-column">
                              <Button
                                onClick={() =>
                                  handleSelectBinCheck(
                                    !applicationInput.security_bin_check
                                  )
                                }
                                size="small"
                                variant={
                                  applicationInput.security_bin_check === true
                                    ? "contained"
                                    : "outlined"
                                }
                                // variant="outlined"
                              >
                                BIN Checks
                              </Button>
                              <Button
                                onClick={() =>
                                  handleSelectVelocityCheck(
                                    !applicationInput.security_velocity_check
                                  )
                                }
                                size="small"
                                variant={
                                  applicationInput.security_velocity_check ===
                                  true
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                Velocity checks
                              </Button>

                              <Button
                                onClick={() =>
                                  handleSelectipGeo(
                                    !applicationInput.security_ip_geo_check
                                  )
                                }
                                size="small"
                                variant={
                                  applicationInput.security_ip_geo_check ===
                                  true
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                IP/GEO checks
                              </Button>
                              <Button
                                onClick={() =>
                                  handleSelectmaxAmount(
                                    !applicationInput.security_max_amt_check
                                  )
                                }
                                size="small"
                                variant={
                                  applicationInput.security_max_amt_check ===
                                  true
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                Maximum Amount checks
                              </Button>
                              <Button
                                onClick={() =>
                                  handleSelectother(
                                    !applicationInput.security_other_check
                                  )
                                }
                                size="small"
                                variant={
                                  applicationInput.security_other_check === true
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                Other
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-form">
                        <TextField
                          id="outlined-basic"
                          label="Payment Service Provider"
                          variant="outlined"
                          size="small"
                          select
                          name="payment_service_provider"
                          value={applicationInput.payment_service_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "payment_service_provider",
                              e.target.value
                            );
                          }}
                        >
                          {payment_service_provider.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="mb-form">
                        <TextField
                          id="outlined-basic"
                          label="Internet Service Provider"
                          variant="outlined"
                          size="small"
                          select
                          name="internet_service_provider"
                          value={applicationInput.internet_service_provider}
                          onChange={(e) => {
                            handleChangeInput(
                              "internet_service_provider",
                              e.target.value
                            );
                          }}
                        >
                          {internet_service_provider.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </>
                  ) : (
                    applicationInput.application_type === 6 && (
                      <>
                        <div className="mb-form">
                          <TextField
                            id="outlined-basic"
                            label="Existing Mid"
                            variant="outlined"
                            size="small"
                            name="existing_mid"
                            value={applicationInput.existing_mid}
                            onChange={(e) => {
                              handleChangeInput("existing_mid", e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-form">
                          <TextField
                            id="outlined-basic"
                            label="Cole From"
                            variant="outlined"
                            size="small"
                            name="cole_from"
                            value={applicationInput.cole_from}
                            onChange={(e) => {
                              handleChangeInput("cole_from", e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    error={
                      new Date().getFullYear() -
                        new Date(applicationInput.dob).getFullYear() <
                        10 &&
                      applicationInput.dob !== "" &&
                      true
                    }
                    label={dob}
                    variant="outlined"
                    type="date"
                    size="small"
                    // defaultValue={values.someDate}
                    InputLabelProps={{ shrink: true }}
                    name="dob"
                    value={applicationInput.dob}
                    onChange={(e) => {
                      handleChangeInput("dob", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={legal_type}
                    name="legal_type"
                    value={applicationInput.legal_type}
                    onChange={(e) => {
                      handleChangeInput("legal_type", e.target.value);
                    }}
                  >
                    {LegalType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {applicationInput.legal_type === "CH" && (
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label="Charity no"
                      variant="outlined"
                      size="small"
                      name="charity_number"
                      value={applicationInput.charity_number}
                      onChange={(e) =>
                        handleChangeInput("charity_number", e.target.value)
                      }
                    />
                  </div>
                )}

                <div className="mb-form">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    options={companyHouseList}
                    autoHighlight
                    inputValue={applicationInput.legal_name}
                    onChange={(event, newValue) => {
                      handleChangeInput(
                        "legal_name",
                        newValue === null ? "" : newValue.title
                      );
                      dispatch(GetCompanyHouseDetails(newValue.title));
                    }}
                    // value={
                    //   companyHouseList?.[applicationInput.legal_name] || null
                    // }
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.title}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          applicationInput?.legal_name?.includes("'")
                            ? true
                            : false
                        }
                        label={legalName}
                        onChange={(e) => {
                          handleChangeInput("legal_name", e.target.value);
                          dispatch(GetCompanyHouseDetails(e.target.value));
                        }}
                        value={
                          companyHouseList?.[applicationInput.legal_name] ||
                          null
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </div>
                {(applicationInput.legal_type === "L" ||
                  applicationInput.legal_type === "LLP" ||
                  applicationInput.legal_type === "PL") && (
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label={company_house_no}
                      variant="outlined"
                      size="small"
                      name="company_house_no"
                      value={applicationInput.company_house_no}
                      onChange={(e) => {
                        handleChangeInput("company_house_no", e.target.value);
                        dispatch(GetCompanyHouseDetails(e.target.value));
                      }}
                    />
                  </div>
                )}
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="In Corporated On"
                    variant="outlined"
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="incorporated_on"
                    value={applicationInput.incorporated_on}
                    onChange={(e) =>
                      handleChangeInput("incorporated_on", e.target.value)
                    }
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={ownership}
                    variant="outlined"
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="current_ownership_since"
                    value={applicationInput.current_ownership_since}
                    onChange={(e) =>
                      handleChangeInput(
                        "current_ownership_since",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label={TradingName}
                    error={
                      applicationInput?.trading_name?.includes("'")
                        ? true
                        : false
                    }
                    variant="outlined"
                    size="small"
                    name="trading_name"
                    value={applicationInput.trading_name}
                    onChange={(e) => {
                      handleChangeInput("trading_name", e.target.value);
                    }}
                  />
                </div>
                {/* <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    label="Secondary Email"
                    variant="outlined"
                    size="small"
                  />
                </div> */}
                {!isLoadLeads &&
                  IndustryList &&
                  IndustryList !== null &&
                  IndustryList.length > 0 && (
                    <>
                      <div className="mb-form">
                        <Autocomplete
                          id="country-select-demo"
                          size="small"
                          options={IndustryList}
                          autoHighlight
                          getOptionLabel={(option) => option.description}
                          value={IndustryList?.[getIndustryIndex()] || null}
                          onChange={(event, newValue) => {
                            IndustryList.map((item) => {
                              if (item.id === newValue.id) {
                                dispatch(
                                  GetApplicationInput("mcc_code", item.mcc_iso)
                                );
                              }
                            });
                            handleChangeInput("industry_type", newValue.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={industry}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="mb-form">
                        <TextField
                          // disabled
                          size="small"
                          id="outlined-select-currency"
                          select
                          label={mcc}
                          name="mcc_code"
                          value={applicationInput.industry_type}
                          onChange={(e) => {
                            IndustryList?.map(
                              (code) =>
                                applicationInput.industry_type === code.id &&
                                dispatch(
                                  GetApplicationInput("mcc_code", code.mcc_iso)
                                )
                            );
                          }}
                          inputProps={{ readOnly: true }}
                        >
                          {IndustryList?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.mcc_iso}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </>
                  )}
                {/* =============Using React Input=========  */}
                <Number
                  text={mobile_number}
                  placeholder={"Mobile Number"}
                  colorBg={"white"}
                  name="mobile"
                  value={applicationInput.mobile}
                  onchangevalue={(e) =>
                    handleChangeInput("mobile", e.target.value)
                  }
                  onchangeFlag={(event, newValue) => {
                    countryList.map((code) => {
                      code.phone_code === newValue.split(" ")[1] &&
                        code.iso2 === newValue.split(" ")[0] &&
                        dispatch(GetApplicationInput("mobile_code", code.id));
                    });
                  }}
                  flag_value={
                    // countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                    countryList?.[getCountryIndex()]?.iso2 +
                      " " +
                      countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                  }
                  flag_name="mobile_code"
                />
                <Number
                  text={"Telephone Number"}
                  placeholder={"Telephone Number"}
                  colorBg={"white"}
                  name="telephone"
                  value={applicationInput.telephone}
                  onchangevalue={(e) =>
                    handleChangeInput("telephone", e.target.value)
                  }
                  onchangeFlag={(event, newValue) => {
                    countryList.map((code) => {
                      code.phone_code === newValue.split(" ")[1] &&
                        code.iso2 === newValue.split(" ")[0] &&
                        dispatch(GetApplicationInput("phone_code", code.id));
                    });
                  }}
                  flag_value={
                    countryList?.[getCountryTelephoneIndex()]?.iso2 +
                      " " +
                      countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                    "GB 44"
                  }
                  flag_name="phone_code"
                />
                {/* <Number
                  text={"Mobile Number"}
                  colorBg={"white"}
                  name="mobile"
                  value={applicationInput.mobile}
                  onchangevalue={(e) =>
                    handleChangeInput("mobile", e.target.value)
                  }
                  onchangeFlag={(event, newValue) => {
                    countryList.map((code) => {
                      code.phone_code === newValue.split(" ")[1] &&
                        code.iso2 === newValue.split(" ")[0] &&
                        dispatch(GetApplicationInput("mobile_code", code.id));
                    });
                  }}
                  flag_value={
                    countryList?.[getCountryIndex()]?.iso2 +
                      " " +
                      countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                  }
                  flag_name="mobile_code"
                /> */}
                {/* <Number
                  text={"Telephone Number"}
                  colorBg={"white"}
                  name="telephone"
                  value={applicationInput.telephone}
                  onchangevalue={(e) =>
                    handleChangeInput("telephone", e.target.value)
                  }
                  onchangeFlag={(event, newValue) => {
                    countryList.map((code) => {
                      code.phone_code === newValue.split(" ")[1] &&
                        code.iso2 === newValue.split(" ")[0] &&
                        dispatch(GetApplicationInput("phone_code", code.id));
                    });
                  }}
                  flag_value={
                    countryList?.[getCountryTelephoneIndex()]?.iso2 +
                      " " +
                      countryList?.[getCountryTelephoneIndex()]?.phone_code ||
                    "GB 44"
                  }
                  flag_name="phone_code"
                /> */}
                {/* =============Using React Input=========  */}

                {/* <div className="mb-form">
                  <TextField
                 
                    id="outlined-basic"
                    label="Contact"
                    variant="outlined"
                    size="small"
                    name="contact"
                    value={applicationInput.contact}
                    onChange={(e) => {
                      handleChangeInput("contact", e.target.value);
                    }}
                  />
                </div> */}
                <div className="mb-form">
                  <TextField
                    type="email"
                    error={
                      !regEmail.test(applicationInput.email) &&
                      applicationInput.email !== "" &&
                      applicationInput.email !== null &&
                      true
                    }
                    id="outlined-basic"
                    label={email}
                    variant="outlined"
                    size="small"
                    name="email"
                    value={applicationInput.email}
                    onChange={(e) => {
                      handleChangeInput("email", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    error={
                      !regEmail.test(applicationInput.secondary_email) &&
                      applicationInput.secondary_email !== "" &&
                      applicationInput.secondary_email !== null &&
                      true
                    }
                    type="email"
                    id="outlined-basic"
                    label="Secondary Email"
                    variant="outlined"
                    size="small"
                    name="secondary_email"
                    value={applicationInput.secondary_email}
                    onChange={(e) => {
                      handleChangeInput("secondary_email", e.target.value);
                    }}
                  />
                </div>
                <div className="mb-form">
                  <TextField
                    id="outlined-basic"
                    error={
                      !regex.test(applicationInput.website) &&
                      applicationInput.website !== "" &&
                      applicationInput.website !== null &&
                      true
                    }
                    label="Website"
                    variant="outlined"
                    size="small"
                    name="website"
                    value={applicationInput.website}
                    onChange={(e) => {
                      handleChangeInput("website", e.target.value);
                    }}
                  />
                </div>
                {/* -------------demo-------------------- */}

                {/* <Number text={"Phone Number"} /> */}
                {/* -----------------demo -----------------*/}
              </div>
            </div>
            {/* nother */}

            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12">
                  <ContactInfoBox
                    handleAddInfo={handleAddInfo}
                    values={values}
                  />
                </div>
                {/* {addContact.map((ad) => (
                  <div key={ad.id} className="col-12 ">
                    <ContactInfoBoxAdd
                      ad={ad}
                      handleDeleteInfo={handleDeleteInfo}
                      values={values}
                    />
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetail;
