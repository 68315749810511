import React from "react";
import { Add } from "@material-ui/icons";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Number from "../addLead/Number";
import { useDispatch, useSelector } from "react-redux";

import {
  ApplicationOwnerDelete,
  GetApplicationInput,
  SetFalseObjectDelete,
} from "./_redux/action/NewapplicationAction";

import { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Autocomplete from "@mui/material/Autocomplete";

import { TitleChoice } from "../commons/Dropdown";

import {
  REMOVE_APPLICATION_OWNER,
  SET_QUOTE_OWNER,
} from "./_redux/types/Types";
import { ImCross } from "react-icons/im";

import Button from "@mui/material/Button";
import {
  GetPostcodeDetails,
  GetPostCodeList,
  GetPostCodeList2,
} from "../PriceQuote/_redux/action/PriceQuoteAction";
import {
  address1,
  city,
  Country,
  Date_birth,
  expeiryDate,
  firstname,
  idNumber,
  issuedate,
  lastName,
  mobile_number,
  nationality,
  PercentofOwnership,
  postCode,
  PrimaryEmail,
  title,
} from "../commons/MendatoryName";
import ContactPostcode from "./ContactPostcode";
const ContactInfoBox = (props) => {
  const dispatch = useDispatch();
  const [openHome, setOpenHome] = useState(false);
  const applicationInput = useSelector(
    (state) => state.applicationInfo.applicationInput
  );

  const countryList = useSelector((state) => state.leadInfo.countryList);
  const postCodeList = useSelector(
    (state) => state.priceQuoteInfo.postCodeList
  );
  const addressDetails = useSelector(
    (state) => state.priceQuoteInfo.addressDetails
  );
  const isApplicationOwnerDeleted = useSelector(
    (state) => state.applicationInfo.isApplicationOwnerDeleted
  );

  useEffect(() => {
    if (
      applicationInput.legal_type === "L" ||
      applicationInput.legal_type === "PL" ||
      applicationInput.legal_type === "LLP" ||
      applicationInput.legal_type === "ST"
    ) {
      dispatch(
        GetApplicationInput(
          "is_main_principal",
          true,
          0,

          "business_owners",
          "contact"
        )
      );
    } else if (applicationInput.legal_type === "PART") {
      for (
        let index = 0;
        index < applicationInput?.business_owners?.length;
        index++
      ) {
        dispatch(
          GetApplicationInput(
            "is_main_principal",
            true,
            index,

            "business_owners",
            "contact"
          )
        );
        console.log("e", index);
      }
    }
  }, []);

  const handleChangeInput = (name, value, ownerIndex) => {
    if (ownerIndex === undefined) {
      dispatch(GetApplicationInput(name, value));
    }
    dispatch(GetApplicationInput(name, value));
  };

  const handleChangeOwnerInput = (name, value, index, contact = undefined) => {
    dispatch(
      GetApplicationInput(name, value, index, "business_owners", contact)
    );
    if (name === "owner_surname") {
      dispatch(
        GetApplicationInput(
          "owner_second_name",
          value,
          index,
          "business_owners",
          contact
        )
      );
    }

    dispatch(
      GetApplicationInput(
        "application",
        applicationInput.id,
        index,
        "business_owners",
        contact
      )
    );
    if (applicationInput.legal_type === "ST") {
      dispatch(
        GetApplicationInput(
          "is_director",
          false,
          index,

          "business_owners",
          contact
        )
      );
    }
    if (
      applicationInput.legal_type === "L" ||
      applicationInput.legal_type === "PL" ||
      applicationInput.legal_type === "LLP" ||
      applicationInput.legal_type === "ST"
    ) {
      if (index === 0) {
        dispatch(
          GetApplicationInput(
            "is_main_principal",
            true,
            index,

            "business_owners",
            contact
          )
        );
      } else {
        dispatch(
          GetApplicationInput(
            "is_main_principal",
            false,
            index,

            "business_owners",
            contact
          )
        );
      }
    } else if (applicationInput.legal_type === "PART") {
      dispatch(
        GetApplicationInput(
          "is_main_principal",
          true,
          index,

          "business_owners",
          contact
        )
      );
    }
  };

  const countryName = () => {
    let country_id = null;
    countryList.map((item) => {
      if (item.name === addressDetails?.CountryName) {
        country_id = item.id;
      }
    });
    return country_id;
  };
  const handleAddMore = () => {
    dispatch({ type: SET_QUOTE_OWNER, payload: false });
  };
  const handleremoveOwner = (index) => {
    dispatch({ type: REMOVE_APPLICATION_OWNER, payload: index });
  };
  const handleDelete = (id) => {
    dispatch(ApplicationOwnerDelete(id, applicationInput.id));
  };
  useEffect(() => {
    if (isApplicationOwnerDeleted) {
      dispatch(SetFalseObjectDelete());
    }
  }, [isApplicationOwnerDeleted]);

  useEffect(() => {
    if (
      applicationInput.legal_type === "L" ||
      applicationInput.legal_type === "PL" ||
      applicationInput.legal_type === "LLP" ||
      applicationInput.legal_type === "ST"
    ) {
      for (
        let index = 0;
        index < applicationInput?.business_owners?.length;
        index++
      ) {
        if (index === 0) {
          dispatch(
            GetApplicationInput(
              "is_main_principal",
              true,
              index,

              "business_owners",
              "contact"
            )
          );
        } else {
          dispatch(
            GetApplicationInput(
              "is_main_principal",
              false,
              index,

              "business_owners",
              "contact"
            )
          );
        }
      }
    } else if (applicationInput.legal_type === "PART") {
      for (
        let index = 0;
        index < applicationInput?.business_owners?.length;
        index++
      ) {
        dispatch(
          GetApplicationInput(
            "is_main_principal",
            true,
            index,

            "business_owners",
            "contact"
          )
        );
        console.log("e", index);
      }
    }
  }, [applicationInput.legal_type]);

  const getCountryIndex = () => {
    var index = -1;
    applicationInput?.business_owners?.map((x, i) => {
      countryList.map((opt) => {
        if (opt.id === x.owner_phone_code) {
          index = countryList.indexOf(opt);
        }
      });
    });
    return index;
  };
  const { values, handleAddInfo } = props;
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <div className="info-box text-capitalize">
      <div class="d-flex justify-content-between  mb-4">
        <div className="d-flex heading mb-4">
          <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3.33464C9.05448 3.33464 6.66667 5.72245 6.66667 8.66797C6.66667 11.6134 9.05448 14.0013 12 14.0013C14.9455 14.0013 17.3333 11.6134 17.3333 8.66797C17.3333 5.72245 14.9455 3.33464 12 3.33464ZM4 8.66797C4 4.24969 7.58172 0.667969 12 0.667969C16.4183 0.667969 20 4.24969 20 8.66797C20 13.0862 16.4183 16.668 12 16.668C7.58172 16.668 4 13.0862 4 8.66797ZM6.66667 22.0013C4.45753 22.0013 2.66667 23.7921 2.66667 26.0013C2.66667 26.7377 2.06971 27.3346 1.33333 27.3346C0.59696 27.3346 0 26.7377 0 26.0013C0 22.3194 2.98477 19.3346 6.66667 19.3346H17.3333C21.0152 19.3346 24 22.3194 24 26.0013C24 26.7377 23.4031 27.3346 22.6667 27.3346C21.9303 27.3346 21.3333 26.7377 21.3333 26.0013C21.3333 23.7921 19.5425 22.0013 17.3333 22.0013H6.66667Z"
              fill="#0D0D0D"
            />
          </svg>
          <p>Contact Details</p>
        </div>

        {applicationInput?.business_owners?.length < 1 && (
          <div className="add-btn2 " onClick={handleAddMore}>
            <Add />
            ADD
          </div>
        )}
      </div>
      {applicationInput?.business_owners?.map((item, index) => {
        return (
          <>
            <div className="inner-box" style={{ backgroundColor: "#FAFAFA" }}>
              <div className="d-flex justify-content-between mb-4">
                <p className="close_container">{index + 1}</p>
                {item?.id ? (
                  <div
                    className="close_container"
                    onClick={() => handleDelete(item.id)}
                  >
                    <ImCross
                      style={{ color: "lightcoral", cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <div
                    className="close_container"
                    onClick={() => handleremoveOwner(index)}
                  >
                    <ImCross
                      style={{ color: "lightcoral", cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="mb-form col-12 col-lg-6  ">
                  <TextField
                    size="small"
                    id="outlined-select-currency"
                    select
                    label={title}
                    name="owner_title"
                    value={item.owner_title}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "owner_title",
                        e.target.value,
                        index
                      )
                    }
                  >
                    {TitleChoice.map((option) => (
                      <MenuItem
                        className="m-2"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="col-12 col-lg-6 mb-4 mb-lg-0 mb-form">
                  <TextField
                    id="outlined-basic"
                    label={firstname}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    name="owner_first_name"
                    value={item.owner_first_name}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "owner_first_name",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>
                <div className="col-12 col-lg-12 mb-form">
                  <TextField
                    id="outlined-basic"
                    label={lastName}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 20 }}
                    name="owner_surname"
                    value={item.owner_surname}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "owner_surname",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>

                <div className="mb-form col-12 col-lg-6 ">
                  <TextField
                    id="outlined-basic"
                    label={Date_birth}
                    variant="outlined"
                    type="date"
                    size="small"
                    error={
                      new Date().getFullYear() -
                        new Date(item.contact_dob).getFullYear() <
                        10 &&
                      item.contact_dob !== "" &&
                      true
                    }
                    // defaultValue={values.someDate}
                    InputLabelProps={{ shrink: true }}
                    name="contact_dob"
                    value={item.contact_dob}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "contact_dob",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>

                <div className=" col-12 col-lg-6 mb-form row">
                  <div className="col-4">
                    <p>
                      Responsible Party : <span className="required">*</span>
                    </p>
                  </div>
                  <div className="col-8">
                    <div className="d-sm-flex gap-3">
                      <Button
                        onClick={() =>
                          handleChangeOwnerInput(
                            "is_responsible_party",
                            true,
                            index
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.business_owners[index]
                            .is_responsible_party === true
                            ? "contained"
                            : "outlined"
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeOwnerInput(
                            "is_responsible_party",
                            false,
                            index
                          )
                        }
                        size="small"
                        variant={
                          applicationInput.business_owners[index]
                            .is_responsible_party === false
                            ? "contained"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="mb-form">
                    <TextField
                      type="email"
                      InputLabelProps={{ shrink: true }}
                      error={
                        !regEmail.test(item.owner_email) &&
                        item.owner_email !== null &&
                        item.owner_email !== "" &&
                        true
                      }
                      id="outlined-basic"
                      label={PrimaryEmail}
                      variant="outlined"
                      size="small"
                      name="owner_email"
                      value={item.owner_email}
                      onChange={(e) => {
                        handleChangeOwnerInput(
                          "owner_email",
                          e.target.value,
                          index
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <Number
                    text={mobile_number}
                    colorBg={"#FAFAFA"}
                    name="owner_phone_no"
                    value={item.owner_phone_no}
                    onchangevalue={(e) =>
                      handleChangeOwnerInput(
                        "owner_phone_no",
                        e.target.value,
                        index
                      )
                    }
                    // -------phone code------------
                    flag_name="owner_phone_code"
                    flag_value={
                      countryList?.[getCountryIndex()]?.iso2 +
                        " " +
                        countryList?.[getCountryIndex()]?.phone_code || "GB 44"
                    }
                    onchangeFlag={(event, newValue) => {
                      countryList?.map(
                        (code) =>
                          //  code.phone_code === newValue &&
                          code.phone_code === newValue.split(" ")[1] &&
                          code.iso2 === newValue.split(" ")[0] &&
                          handleChangeOwnerInput(
                            "owner_phone_code",
                            code.id,
                            index
                          )
                      );
                    }}
                  />
                </div>

                <div className="mb-form col-lg-12">
                  <TextField
                    id="outlined-basic"
                    label={idNumber}
                    variant="outlined"
                    size="small"
                    name="owner_id_num"
                    value={item.owner_id_num || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "owner_id_num",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      id="outlined-basic"
                      label={issuedate}
                      variant="outlined"
                      error={
                        item.owner_issue_date !== "" &&
                        item.owner_issue_date !== null &&
                        (new Date(item.owner_expiry_date).getFullYear() -
                        new Date(item.owner_issue_date).getFullYear()) *
                        12 +
                        (new Date(item.owner_expiry_date).getMonth() -
                          new Date(item.owner_issue_date).getMonth()) <
                        3
                          ? true
                          : false
                      }
                      type="date"
                      size="small"
                      // defaultValue={values.someDate}
                      InputLabelProps={{ shrink: true }}
                      name="owner_issue_date"
                      value={item.owner_issue_date}
                      onChange={(e) =>
                        handleChangeOwnerInput(
                          "owner_issue_date",
                          e.target.value,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-form">
                    <TextField
                      type="date"
                      id="outlined-basic"
                      label={expeiryDate}
                      error={
                        item.owner_expiry_date !== "" &&
                        item.owner_expiry_date !== null &&
                        (new Date(item.owner_expiry_date).getFullYear() -
                        new Date(item.owner_issue_date).getFullYear()) *
                        12 +
                        (new Date(item.owner_expiry_date).getMonth() -
                          new Date(item.owner_issue_date).getMonth()) <
                        3
                          ? true
                          : false
                      }
                      variant="outlined"
                      size="small"
                      // defaultValue={values.someDate}
                      InputLabelProps={{ shrink: true }}
                      name="owner_expiry_date"
                      value={item.owner_expiry_date}
                      onChange={(e) =>
                        handleChangeOwnerInput(
                          "owner_expiry_date",
                          e.target.value,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                <div className="mb-form col-lg-12">
                  <Autocomplete
                    id="country-select-demo"
                    size="small"
                    options={countryList}
                    onChange={(event, newValue) =>
                      handleChangeOwnerInput(
                        "owner_nationality",
                        newValue.id,
                        index
                      )
                    }
                    value={countryList?.[item.owner_nationality - 1] || null}
                    autoHighlight
                    getOptionLabel={(option) => option.nationality}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.nationality} ({option.iso2})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={nationality}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        name="owner_nationality"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* {!isLoadLeads && ownerList && ownerList !== null && contactList !== null && ownerList.length > 0 && ( */}
            <div className="inner-box" style={{ backgroundColor: "#FAFAFA" }}>
              <div className="row" key={item.id}>
                <div className="col-12">
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>
                          Is main principal? :{" "}
                          <span className="required">*</span>{" "}
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_main_principal",
                                true,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_main_principal === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_main_principal",
                                false,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_main_principal === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>
                          Is beneficial Owner? :{" "}
                          <span className="required">*</span>{" "}
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_beneficial_owner",
                                true,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_beneficial_owner === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_beneficial_owner",
                                false,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_beneficial_owner === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                </div>
                <div className="col-md-12">
                  <div className="mb-form">
                    <div className="row">
                      <div className="col-4">
                        <p>
                          Is signatory? : <span className="required">*</span>
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_signatory",
                                true,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_signatory === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_signatory",
                                false,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_signatory === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className=""></div>
                </div>
                <div className="mb-form">
                  {applicationInput.legal_type === "LLP" ||
                  applicationInput.legal_type === "L" ||
                  applicationInput.legal_type === "PL" ||
                  applicationInput.legal_type === "PART" ? (
                    <></>
                  ) : (
                    <div className="row mb-form">
                      <div className="col-4">
                        <p>
                          Is Owner? : <span className="required">*</span>
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput("is_owner", true, index)
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_owner === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput("is_owner", false, index)
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_owner === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {applicationInput.legal_type === "PART" ? (
                    <div className="row mb-form">
                      <div className="col-4">
                        <p>
                          Is Partnership? : <span className="required">*</span>
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_partnership",
                                true,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_partnership === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_partnership",
                                false,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_partnership === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {applicationInput.legal_type !== "ST" && (
                    <div className="row">
                      <div className="col-4">
                        <p>
                          Is director? : <span className="required">*</span>
                        </p>
                      </div>
                      <div className="col-8">
                        <div className="d-sm-flex gap-3">
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput("is_director", true, index)
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_director === true
                                ? "contained"
                                : "outlined"
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() =>
                              handleChangeOwnerInput(
                                "is_director",
                                false,
                                index
                              )
                            }
                            size="small"
                            variant={
                              applicationInput.business_owners[index]
                                .is_director === false
                                ? "contained"
                                : "outlined"
                            }
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 col-lg-12 mb-form ">
                  <TextField
                    id="outlined-basic"
                    label={PercentofOwnership}
                    variant="outlined"
                    size="small"
                    name="ownership_perc"
                    value={item.ownership_perc}
                    onChange={(e) =>
                      handleChangeOwnerInput(
                        "ownership_perc",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>

                {/* <button
                      className="add-btn my-1"
                      onClick={() => handleOwnerUpdate(ownerInput)}
                    >
                      Check KYC
                    </button> */}
                {item.business_owner_contacts?.map((contact, i) => {
                  return (
                    <div className="col-12">
                      <h5>Private Residential Address</h5>
                      <div className="mb-form mt-4">
                        {/* <TextField
                          id="outlined-basic"
                          error={
                            contact?.legal_postcode < 5 &&
                            contact?.legal_postcode !== "" &&
                            true
                          }
                          label={postCode}
                          variant="outlined"
                          size="small"
                          name="zip_code"
                          value={contact.zip_code}
                          onChange={(e) =>
                            handleChangeOwnerInput(
                              "zip_code",
                              e.target.value,
                              index,
                              "business_owner_contacts"
                            )
                          }
                        /> */}
                        {/* <Autocomplete
                          id="country-select-demo"
                          size="small"
                          error={true}
                          open={openHome}
                          options={postCodeList}
                          autoHighlight
                          inputValue={contact.zip_code}
                          onChange={(event, newValue) => {
                            handleChangeOwnerInput(
                              "zip_code",
                              newValue.Text,
                              index,
                              "business_owner_contacts"
                            );
                            if (newValue.Type === "Address") {
                              dispatch(
                                GetPostcodeDetails(
                                  newValue.Id,
                                  "app_owner_postcode",
                                  index,
                                  "business_owner_contacts"
                                )
                              );
                              setOpenHome(false);
                            } else if (newValue.Type === "Postcode") {
                              setOpenHome(true);
                              dispatch(
                                GetPostCodeList2(
                                  newValue.Id,
                                  newValue.Text,
                                  newValue.Description,
                                  i
                                )
                              );
                              dispatch(
                                handleChangeOwnerInput(
                                  "zip_code",
                                  "",
                                  index,
                                  "business_owner_contacts"
                                )
                              );
                            }
                          }}
                          getOptionLabel={(option) => option.Text}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.Text} {option.Description}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                contact?.zip_code?.includes("'") ? true : false
                              }
                              label={postCode}
                              onChange={(e) => {
                                setOpenHome(e.target.value !== "");
                                handleChangeOwnerInput(
                                  "zip_code",
                                  e.target.value,
                                  index,
                                  "business_owner_contacts"
                                );
                                dispatch(GetPostCodeList(e.target.value));
                              }}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        /> */}

                        <ContactPostcode
                          name="zip_code"
                          value={contact.zip_code}
                          details="app_owner_postcode"
                          index={index}
                          business_owner_contacts={"business_owner_contacts"}
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label={address1}
                          variant="outlined"
                          size="small"
                          name="street_line_1"
                          value={contact.street_line_1}
                          onChange={(e) =>
                            handleChangeOwnerInput(
                              "street_line_1",
                              e.target.value,
                              index,
                              "business_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="Address 2"
                          variant="outlined"
                          size="small"
                          name="locality"
                          InputLabelProps={{ shrink: true }}
                          value={contact.locality}
                          onChange={(e) =>
                            handleChangeOwnerInput(
                              "locality",
                              e.target.value,
                              index,
                              "business_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label={city}
                          variant="outlined"
                          size="small"
                          name="city"
                          value={contact.city}
                          onChange={(e) =>
                            handleChangeOwnerInput(
                              "city",
                              e.target.value,
                              index,
                              "business_owner_contacts"
                            )
                          }
                        />
                      </div>
                      <div className="mb-form ">
                        <TextField
                          id="outlined-basic"
                          label="County"
                          variant="outlined"
                          size="small"
                          name="county_code"
                          value={contact.county_code}
                          onChange={(e) =>
                            handleChangeOwnerInput(
                              "county_code",
                              e.target.value,
                              index,
                              "business_owner_contacts"
                            )
                          }
                        />
                      </div>

                      <div className="mb-form">
                        <Autocomplete
                          id="country-select-demo"
                          size="small"
                          options={countryList}
                          onChange={(event, newValue) =>
                            handleChangeOwnerInput(
                              "country_code",
                              newValue.id,
                              index,
                              "business_owner_contacts"
                            )
                          }
                          value={
                            countryList?.[contact.country_code - 1] || null
                          }
                          // value={
                          //   addressDetails
                          //     ? countryList?.[countryName() - 1] || null
                          //     : countryList?.[contact.country_code - 1] || null
                          // }
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.name} ({option.iso2})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={Country}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              name="country_code"
                              //
                            />
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
      {applicationInput?.business_owners?.length > 0 && (
        <div
          style={{ cursor: "pointer" }}
          className="fill_add_button d-flex align-items-center justify-content-center"
          onClick={handleAddMore}
        >
          <Add />
          ADD
        </div>
      )}
    </div>
  );
};

export default ContactInfoBox;
