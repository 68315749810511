import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStatus } from "../../../allAplication/mainLead/components/commons/Dropdown";
import Table from "react-bootstrap/Table";
import {
  GetAplicationDetails,
  GetApplicationList,
} from "../../../allAplication/mainLead/components/newApplication/_redux/action/NewapplicationAction";
import { BsEyeFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { GetLeadsnput } from "../../../allAplication/mainLead/_redux/action/LeadsAction";
const UserApplicationList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const applicationList = useSelector(
    (state) => state.applicationInfo.applicationList
  );
  // const toggleShowAllData = () => {
  //   setAllDataLoaded(!allDataLoaded);
  // };
  const handleNexteClick = (pageNo) => {
    // setCurrentPage(pageNo);
    dispatch(GetApplicationList(applicationList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    // setCurrentPage(pageNo);
    dispatch(GetApplicationList(applicationList?.previous));
  };
  const handleApplicationView = (data) => {
    dispatch(GetAplicationDetails(data));
    history.push(`/viewlead/${data}`);
    dispatch(GetLeadsnput("step", 3));
  };
  return (
    <div className="product-card">
      <div className="product-card-header">
        <h4>New Application List</h4>
      </div>
      <div className="product-card-body">
        <Table className="product-table" responsive>
          <thead>
            <tr>
              <th>Client ID</th>
              <th>Legal Name</th>
              <th>Trading Name</th>
              <th>Application Status</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          {applicationList?.results?.length < 1 && (
            <tr>
              <td colspan="12">
                <div
                  style={{ background: "#17479d1f" }}
                  class="alert alert-success text-center mt-5 mb-5"
                  role="alert"
                >
                  Sorry ! No data found.
                </div>
              </td>
            </tr>
          )}
          <tbody>
            {applicationList?.results
              ?.slice(0, allDataLoaded ? applicationList?.count : 3)
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.client_id}</td>
                  <td>{item.legal_name}</td>
                  <td>{item.trading_name}</td>
                  <td>
                    {ApplicationStatus.map((opt) => {
                      return opt.value === item.application_status && opt.label;
                    })}
                  </td>
                  <td
                    align="center"
                    onClick={() => handleApplicationView(item.id)}
                  >
                    <BsEyeFill className="view_icon" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* {applicationList?.count > 3 && ( */}
        <div className="load-more-button-container gap-2">
          <p>
            Total : <span>{applicationList?.count}</span>
          </p>
          <button
            className={` ${
              applicationList?.previous ? "load-more-button" : "disable-button"
            }`}
            onClick={handlePreviousClick}
          >
            Prev
          </button>
          <button
            className={` ${
              applicationList?.next ? "load-more-button" : "disable-button"
            }`}
            onClick={handleNexteClick}
          >
            Next
          </button>
          {/* <button className="load-more-button" onClick={toggleShowAllData}>
              {allDataLoaded ? "See Less" : "See More"}
            </button> */}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default UserApplicationList;
